import React, { ReactElement } from 'react';
import { withRouter } from 'react-router';
import { RedocStandalone } from 'redoc';
import './Container.scss';

const DocAPI = (): ReactElement => {
  return (
    <RedocStandalone
      specUrl={`${process.env.REACT_APP_URL_API}/v1/documentations/get/intwi`}
    />
  );
};

export default withRouter(DocAPI);
