import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface Permission extends Model {
  subject: string;
  action: string;
  domain: string;
}

export interface PermissionState {
  permissions: Permission[];
  status: {
    listing: boolean;
  };
}

export const PropTypesPermission = PropTypes.shape({
  id: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
});
