import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import { userTypes } from '../../../../states/ducks/users';
import {
  profileActions,
  profileSelectors,
} from '../../../../states/ducks/profiles';
import { FromNowAndAuthorWithDateTooltip, Field } from '../index';

interface DateAndAuthorFieldProps {
  date: string;
  userId: userTypes.User['id'];
  getProfile: Function;
  authorName: string;
  label?: string;
}

/**
 * @param {string} date
 * @param {string} userId
 * @param {Function} getProfile
 * @param {string} authorName
 * @param {string|null} label
 *
 * @return {ReactElement}
 */
const DateAndAuthorField: FunctionComponent<DateAndAuthorFieldProps> = ({
  date,
  userId,
  getProfile,
  authorName,
  label,
}): ReactElement => {
  useEffect((): void => {
    if (getProfile && userId) {
      getProfile(userId);
    }
  }, [getProfile, userId]);

  return (
    <Field
      label={label}
      content={
        <FromNowAndAuthorWithDateTooltip date={date} userName={authorName} />
      }
    />
  );
};

DateAndAuthorField.propTypes = {
  date: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  getProfile: PropTypes.func.isRequired,
  authorName: PropTypes.string.isRequired,
  label: PropTypes.string,
};

interface MapStateToProps {
  authorName: string;
}

interface MapDispatchToProps {
  getProfile: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    userId: userTypes.User['id'];
  },
): MapStateToProps => {
  const profile = profileSelectors.profileFromUserIdSelector(
    state,
    props.userId,
  );

  return {
    authorName: profile ? `${profile.firstName} ${profile.lastName}` : '',
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateAndAuthorField);
