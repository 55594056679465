import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {Location[]} [state=[]] locations
 * @param {AnyAction} action
 * @return {Location[]}
 */
export const locationReducer: Reducer<types.Location[]> = (
  state: types.Location[] = [],
  action: AnyAction,
): types.Location[] => {
  return modelReducer('locations', state, action) as types.Location[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locations', 'LIST_FAILED'):
    case getActionType('locations', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('locations', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locations', 'ADD_FAILED'):
    case getActionType('locations', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('locations', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locations', 'EDIT_FAILED'):
    case getActionType('locations', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('locations', 'EDIT_DESIGNATION_REQUESTED'):
      return true;
    case getActionType('locations', 'EDIT_ORGANIZATIONS_REQUESTED'):
      return true;
    case getActionType('locations', 'EDIT_TYPE_ID_REQUESTED'):
      return true;
    case getActionType('locations', 'EDIT_ADDRESS_REQUESTED'):
      return true;
    case getActionType('locations', 'EDIT_REMARK_REQUESTED'):
    case getActionType('locations', 'ADD_FILE_REQUESTED'):
    case getActionType('locations', 'ADD_GED_FILE_REQUESTED'):
    case getActionType('locations', 'DELETE_FILE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locations', 'DELETE_FAILED'):
    case getActionType('locations', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('locations', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locations', 'GET_FAILED'):
    case getActionType('locations', 'GET_SUCCEEDED'):
      return false;
    case getActionType('locations', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.LocationState> = combineReducers({
  locations: locationReducer,
  status: combineReducers({
    listing: listingReducer,
    adding: addingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
    getting: gettingReducer,
  }),
});

export default reducers;
