import React, { ReactElement, forwardRef } from 'react';
import { Input } from 'antd';
import 'antd/lib/input/style';
import { PasswordProps } from 'antd/lib/input';
import './Input.scss';
import classnames from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Component = (props: PasswordProps, ref: any): ReactElement => {
  const classes = classnames('app-input-password', props.className);
  return <Input.Password {...props} ref={ref} className={classes} />;
};

Component.displayName = 'InputPassword';

/**
 * InputPassword component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const InputPassword = forwardRef<HTMLInputElement, PasswordProps>(Component);

export default InputPassword;
