import { create, get, patch, remove } from '../base';
import { tagTypes } from '../../../states/ducks/tags';

/**
 * @param {Tag} tag
 * @return {Promise<string>}
 */
export const addTag = async (
  tag: Partial<tagTypes.Tag>,
): Promise<tagTypes.Tag['id']> => await create(`/v1/tags/create`, tag);

/**
 * @return {Promise<Tag[]>}
 */
export const listTags = async (): Promise<tagTypes.Tag[]> =>
  await get(`/v1/tags/list`);

/**
 * @param {string} id
 * @return {Tag}
 */
export const getTag = async (id: tagTypes.Tag['id']): Promise<tagTypes.Tag> =>
  await get(`/v1/tags/${id}`);

/**
 * @param {Tag} tag
 * @param {Object} objectsLinked
 * @return {Promise<string>}
 */
export const editTagLink = async (
  tag: Partial<tagTypes.Tag>,
  objectsLinked: Record<string, string[]>,
): Promise<string> => {
  return await patch(`/v1/tags/linkObjects/${tag.id}`, objectsLinked);
};

/**
 * @param {Tag} tag
 * @param {Object} objectsLinked
 * @return {Promise<string>}
 */
export const editTagUnlink = async (
  tag: Partial<tagTypes.Tag>,
  objectsLinked: Record<string, string[]>,
): Promise<string> => {
  return await patch(`/v1/tags/unlinkObjects/${tag.id}`, objectsLinked);
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteTag = async (id: tagTypes.Tag['id']): Promise<string> =>
  await remove(`/v1/tags/remove/${id}`);
