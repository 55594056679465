const errorStatusCode: { [key: number]: string } = {
  400: 'Les ressources sont incorrectes',
  401: "L'accès à la ressource nécessite une autorisation",
  402: "L'accès à la ressource nécessite un paiement",
  403: "L'accès à la ressource est interdit",
  404: "La ressource n'existe pas",
  405: "L'accès à la ressource est interdit",
  406: "L'accès à la ressource est interdit",
  407: "L'accès à la ressource est interdit",
  408: "Impossible d'accéder à la ressource",
  409: 'La ressource existe déjà',
  410: "Impossible d'accéder à la ressource",
  411: "Impossible d'accéder à la ressource",
  412: "Impossible d'accéder à la ressource",
  413: "Impossible d'accéder à la ressource",
  414: "Impossible d'accéder à la ressource",
  415: "Impossible d'accéder à la ressource",
  416: "Impossible d'accéder à la ressource",
  417: "Impossible d'accéder à la ressource",
  418: "Impossible d'accéder à la ressource",
  421: "Impossible d'accéder à la ressource",
  422: "Impossible d'accéder à la ressource",
  423: "Impossible d'accéder à la ressource",
  424: "Impossible d'accéder à la ressource",
  426: "Impossible d'accéder à la ressource",
  428: "Impossible d'accéder à la ressource",
  429: "Impossible d'accéder à la ressource",
  431: "Impossible d'accéder à la ressource",
  451: "Impossible d'accéder à la ressource",
  500: 'Impossible de joindre le serveur',
  501: 'Impossible de joindre le serveur',
  502: 'Impossible de joindre le serveur',
  503: 'Impossible de joindre le serveur',
  504: 'Impossible de joindre le serveur',
  505: 'Impossible de joindre le serveur',
  506: 'Impossible de joindre le serveur',
  507: 'Impossible de joindre le serveur',
  508: 'Impossible de joindre le serveur',
  510: 'Impossible de joindre le serveur',
  511: 'Impossible de joindre le serveur',
};

export default errorStatusCode;
