import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  forwardRef,
} from 'react';
import { CascaderOptionType, CascaderProps } from 'antd/lib/cascader';
import Cascader from './Cascader';
import PropTypes from 'prop-types';

export interface IntwiCascaderItem {
  id: string;
  name: string;
  children?: IntwiCascaderItem[] | null;
}

interface IntwiCascaderComponentProps extends Omit<CascaderProps, 'options'> {
  items: IntwiCascaderItem[];
  fetchItems?: Function;
  loading?: number;
  value?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
}

/**
 * @param {IntwiCascaderComponentProps} props
 * @param {any} ref
 * @return {ReactElement}
 */
const IntwiCascaderComponent: FunctionComponent<IntwiCascaderComponentProps> =
  ({
    items,
    fetchItems = (): void => {
      return;
    },
    loading = 0,
    ...props
  }): ReactElement => {
    useEffect((): void => {
      fetchItems(undefined);
    }, [fetchItems]);

    const filter = (inputValue: string, path: CascaderOptionType[]): boolean =>
      path.some((option: CascaderOptionType): boolean => {
        if (typeof option.label === 'undefined' || option.label === null) {
          return false;
        }
        return (
          String(option.label).toLowerCase().indexOf(inputValue.toLowerCase()) >
          -1
        );
      });

    const formatter = (items: IntwiCascaderItem[]): CascaderOptionType[] => {
      return items.map(
        (organization: IntwiCascaderItem): CascaderOptionType => {
          const option: CascaderOptionType = {
            label: organization.name,
            value: organization.id,
          };

          if (
            typeof organization.children !== 'undefined' &&
            null !== organization.children &&
            organization.children.length > 0
          ) {
            option.children = formatter(organization.children);
          }

          return option;
        },
      );
    };

    if (loading === 1 && items.length === 0) {
      return <div>loading</div>;
    }

    return (
      <Cascader
        size="large"
        options={formatter(items)}
        placeholder="Sélectionnez une organisation"
        showSearch={{ filter }}
        changeOnSelect
        expandTrigger="hover"
        {...props}
      />
    );
  };

IntwiCascaderComponent.propTypes = {
  items: PropTypes.array.isRequired,
  fetchItems: PropTypes.func,
  loading: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func,
  popupPlacement: PropTypes.string,
  ref: PropTypes.any,
};

const IntwiCascader = forwardRef<HTMLInputElement, IntwiCascaderComponentProps>(
  (props, ref): ReactElement => <IntwiCascaderComponent {...props} ref={ref} />,
);

IntwiCascader.displayName = 'IntwiCascader';

export default IntwiCascader;
