import { Layout } from 'antd';
import 'antd/lib/layout/style';
import PropTypes from 'prop-types';
import React, {
  ReactElement,
  FunctionComponent,
  useEffect,
  PropsWithChildren,
} from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  getCurrentUserId,
  unauthentication,
} from '../../../../services/authenticate';
import { fetchGetCurrentUserActionCreator } from '../../../../states/ducks/currentUser/actions';
import {
  currentUserIsGettingSelector,
  currentUserSelector,
} from '../../../../states/ducks/currentUser/selectors';
import {
  CurrentUser,
  CurrentUserState,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import { fetchListIncidentTypesActionCreator } from '../../../../states/ducks/incidenttypes/actions';
import { fetchListLocationTypesActionCreator } from '../../../../states/ducks/locationtypes/actions';
import { fetchListOrganizationsActionCreator } from '../../../../states/ducks/organizations/actions';
import {
  Organization,
  OrganizationsState,
  PropTypesOrganization,
} from '../../../../states/ducks/organizations/types';
import {
  organizationsIsListingSelector,
  organizationsSelector,
} from '../../../../states/ducks/organizations/selectors/base';
import { fetchListPermissionsActionCreator } from '../../../../states/ducks/permissions/actions';
import { fetchGetProfilesByUserIdActionCreator } from '../../../../states/ducks/profiles/actions';
import { profileFromUserIdSelector } from '../../../../states/ducks/profiles/selectors';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import { fetchListRolesActionCreator } from '../../../../states/ducks/roles/actions';
import { State } from '../../../../states/types';
import Sidebar from '../../../containers/misc/Menu';
import Breadcrumb from './breadcrumb/Breadcrumb';
import './Container.scss';
import Header from './header/Header';

type ContainerProps = RouteComponentProps &
  MapStateToProps &
  MapDispatchToProps;

const Container: FunctionComponent<PropsWithChildren<ContainerProps>> = ({
  children,
  currentUserGettingStatus,
  currentUser,
  getCurrentUser,
  getProfile,
  loadOrganizations,
  loadPermissions,
  loadRoles,
  loadLocationTypes,
  loadIncidentTypes,
  organizations,
  organizationsListingStatus,
  profile,
}): ReactElement => {
  useEffect((): void => {
    if (getCurrentUser && getCurrentUserId() && !currentUser) {
      getCurrentUser();
    }
  }, [currentUser, currentUserGettingStatus, getCurrentUser]);

  useEffect((): void => {
    if (getProfile && currentUser && currentUser.id && !profile) {
      getProfile(currentUser.id);
    }
  }, [getProfile, currentUser, profile]);

  useEffect((): void => {
    loadRoles();
  }, [loadRoles]);

  useEffect((): void => {
    loadOrganizations();
  }, [loadOrganizations]);

  useEffect((): void => {
    loadPermissions();
  }, [loadPermissions]);

  useEffect((): void => {
    loadLocationTypes();
  }, [loadLocationTypes]);

  useEffect((): void => {
    loadIncidentTypes();
  }, [loadIncidentTypes]);

  if (currentUserGettingStatus < 0 || organizationsListingStatus < 0) {
    unauthentication(true).then(() => {
      window.location.reload();
    });
  }

  return currentUser && organizations.length > 0 ? (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <Header currentUser={currentUser} />
        <Layout.Content className="app-main-container">
          <Breadcrumb />
          {children || ''}
        </Layout.Content>
      </Layout>
    </Layout>
  ) : (
    <></>
  );
};

Container.displayName = 'Container';

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  currentUserGettingStatus: PropTypes.number.isRequired,
  currentUser: PropTypesCurrentUser,
  getCurrentUser: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  loadOrganizations: PropTypes.func.isRequired,
  loadPermissions: PropTypes.func.isRequired,
  loadRoles: PropTypes.func.isRequired,
  loadLocationTypes: PropTypes.func.isRequired,
  loadIncidentTypes: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypesOrganization.isRequired).isRequired,
  organizationsListingStatus: PropTypes.number.isRequired,
  profile: PropTypesProfile,
};

interface MapStateToProps {
  currentUserGettingStatus: CurrentUserState['status']['getting'];
  currentUser: CurrentUser | null;
  organizations: Organization[];
  organizationsListingStatus: OrganizationsState['status']['listing'];
  profile: Profile | null;
}

interface MapDispatchToProps {
  getCurrentUser: Function;
  getProfile: Function;
  loadOrganizations: Function;
  loadPermissions: Function;
  loadRoles: Function;
  loadLocationTypes: Function;
  loadIncidentTypes: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const currentUser = currentUserSelector(state);

  return {
    currentUserGettingStatus: currentUserIsGettingSelector(state),
    currentUser: Object.keys(currentUser).length > 0 ? currentUser : null,
    organizations: organizationsSelector(state),
    organizationsListingStatus: organizationsIsListingSelector(state),
    profile: currentUser.id
      ? profileFromUserIdSelector(state, currentUser.id)
      : null,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getCurrentUser: fetchGetCurrentUserActionCreator,
  getProfile: fetchGetProfilesByUserIdActionCreator,
  loadOrganizations: fetchListOrganizationsActionCreator,
  loadPermissions: fetchListPermissionsActionCreator,
  loadRoles: fetchListRolesActionCreator,
  loadLocationTypes: fetchListLocationTypesActionCreator,
  loadIncidentTypes: fetchListIncidentTypesActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Container));
