import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Layout, Dropdown } from 'antd';
import 'antd/lib/menu/style';
import './Sidebar.scss';
import { Logo, Button, RestrictedToPermissions } from '../../../base';
import { NavLink } from 'react-router-dom';
import AddUser from '../../modal/user/AddUser';
import ReportIncident from '../../../../containers/modal/incident/ReportIncident';
import OpenRequest from '../../../../containers/modal/request/OpenRequest';
import AddLocation from '../../../../containers/modal/location/AddLocation';
import AddContractor from '../../../../containers/modal/contractor/AddContractor';
import {
  HomeOutlined,
  LineChartOutlined,
  ToolOutlined,
  SolutionOutlined,
  UserOutlined,
  PlusOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  MailOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { State } from '../../../../../states/types';
import { connect } from 'react-redux';
import {
  mainLocationTypeSelector,
  quickAccessLocationTypesSelector,
} from '../../../../../states/ducks/locationtypes/selectors';
import {
  LocationType,
  PropTypesLocationType,
} from '../../../../../states/ducks/locationtypes/types';
// @ts-ignore
import plural from 'pluralize-fr';

interface Props {
  location: {
    pathname: string;
  };
  mainLocationType: LocationType | null;
  quickAccessLocationTypes: LocationType[];
}

const Sidebar: FunctionComponent<Props> = ({ ...props }): ReactElement => {
  const [incidentModalVisible, setIncidentModalVisible] = useState(false);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [contractorModalVisible, setContractorModalVisible] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);

  const initialCollapsedByUser =
    localStorage.getItem('collapsed') !== null
      ? JSON.parse(localStorage.getItem('collapsed') || '{}')
      : false;

  const [siderCollapsed, setSiderCollapsed] = useState(initialCollapsedByUser);

  let selected = ['/'];
  const reg = new RegExp(/([a-z]+)(?:\/[a-z])*/);
  const match = reg.exec(props.location.pathname);
  if (match !== null) {
    selected = [match[1]];
  }

  const menu = (
    <Menu className="app-menu-create-new">
      <Menu.Item
        key="incident"
        onClick={(): void => setIncidentModalVisible(true)}
      >
        <ToolOutlined />
        <span>Incident</span>
      </Menu.Item>
      <Menu.Item
        key="request"
        onClick={(): void => setRequestModalVisible(true)}
      >
        <MailOutlined />
        <span>Demande</span>
      </Menu.Item>
      <Menu.Item
        key="location"
        onClick={(): void => setLocationModalVisible(true)}
      >
        <HomeOutlined />
        <span>
          {props.mainLocationType ? props.mainLocationType.name : 'Lieux'}
        </span>
      </Menu.Item>
      <Menu.Item
        key="contractor"
        onClick={(): void => setContractorModalVisible(true)}
      >
        <SolutionOutlined />
        <span>Prestataire</span>
      </Menu.Item>
      <Menu.Item
        key="utilisateur"
        onClick={(): void => setUserModalVisible(true)}
      >
        <UserOutlined />
        <span>Utilisateur</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Sider
      defaultCollapsed={siderCollapsed}
      breakpoint="lg"
      collapsible
      collapsed={siderCollapsed}
      onCollapse={(collapsed, type): void => {
        if (type === 'clickTrigger') {
          setSiderCollapsed(!siderCollapsed);
          localStorage.setItem('collapsed', (!siderCollapsed).toString());
        }
      }}
      theme="light"
      collapsedWidth={64}
      width={260}
      onBreakpoint={(broken): void => {
        if (broken && (siderCollapsed !== null || siderCollapsed !== false)) {
          setSiderCollapsed(true);
          localStorage.setItem('collapsed', true.toString());
        }
        if (!broken && !siderCollapsed) {
          setSiderCollapsed(null);
          localStorage.removeItem('collapsed');
        }
      }}
    >
      <NavLink to="/">
        <div className="app-logo-container">
          <div>
            <Logo white={true} minified={siderCollapsed} />
          </div>
        </div>
      </NavLink>
      <Dropdown
        overlayClassName="app-create-new-dropdown"
        overlay={menu}
        trigger={['click']}
      >
        <Button shape="round" size="large" className="app-create-new">
          Nouveau
          <div className="icon-btn">
            <PlusOutlined />
          </div>
        </Button>
      </Dropdown>
      <ReportIncident
        visible={incidentModalVisible}
        hideModal={(): void => setIncidentModalVisible(false)}
      />
      <OpenRequest
        visible={requestModalVisible}
        hideModal={(): void => setRequestModalVisible(false)}
      />
      <AddLocation
        visible={locationModalVisible}
        hideModal={(): void => setLocationModalVisible(false)}
      />
      <AddContractor
        visible={contractorModalVisible}
        hideModal={(): void => setContractorModalVisible(false)}
      />
      <AddUser
        visible={userModalVisible}
        hideModal={(): void => setUserModalVisible(false)}
      />
      <Menu
        theme="light"
        defaultSelectedKeys={['/']}
        selectedKeys={selected}
        mode="inline"
        className="app-side-menu"
      >
        <Menu.Item key="dashboard">
          <NavLink to="/dashboard">
            <LineChartOutlined />
            <span>Dashboard</span>
          </NavLink>
        </Menu.Item>
        <RestrictedToPermissions subject={'incident'} action={'list'}>
          <Menu.Item key="incidents">
            <NavLink to="/incidents">
              <ToolOutlined />
              <span>Incidents</span>
            </NavLink>
          </Menu.Item>
        </RestrictedToPermissions>
        <Menu.Item key="requests">
          <NavLink to="/requests">
            <MailOutlined />
            <span>Demandes</span>
          </NavLink>
        </Menu.Item>
        <RestrictedToPermissions subject={'location'} action={'list'}>
          {props.mainLocationType ? (
            <Menu.Item key="main-locations">
              <NavLink to={`/locations/type/${props.mainLocationType.id}`}>
                <HomeOutlined />
                <span>{plural(props.mainLocationType.name)}</span>
              </NavLink>
            </Menu.Item>
          ) : (
            <Menu.Item key="main-locations">
              <NavLink to="/locations">
                <HomeOutlined />
                <span>Lieux</span>
              </NavLink>
            </Menu.Item>
          )}
        </RestrictedToPermissions>
        {props.quickAccessLocationTypes.map((locationType, index) => (
          <RestrictedToPermissions
            subject={'location'}
            action={'list'}
            key={`quick-access-locations-${index}`}
          >
            <Menu.Item>
              <NavLink to={`/locations/type/${locationType.id}`}>
                <HomeOutlined />
                <span>{plural(locationType.name)}</span>
              </NavLink>
            </Menu.Item>
          </RestrictedToPermissions>
        ))}
        <Menu.Item key="contractors">
          <NavLink to="/contractors">
            <SolutionOutlined />
            <span>Prestataires</span>
          </NavLink>
        </Menu.Item>
        <RestrictedToPermissions subject={'user'} action={'list'}>
          <Menu.Item key="users">
            <NavLink to="/users">
              <UserOutlined />
              <span>Utilisateurs</span>
            </NavLink>
          </Menu.Item>
        </RestrictedToPermissions>
        <Menu.Item key="files">
          <NavLink to="/files">
            <FileOutlined />
            <span>Documents</span>
          </NavLink>
        </Menu.Item>
        <RestrictedToPermissions subject={'administration'} action={'access'}>
          <Menu.Item key="administration">
            <NavLink to="/administration">
              <SettingOutlined />
              <span>Administration</span>
            </NavLink>
          </Menu.Item>
        </RestrictedToPermissions>
        <Menu.Item key="help" className="app-help">
          <NavLink to="/help/privacy-policy" target="_blank">
            <QuestionCircleOutlined />
            <span>Aide</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  mainLocationType: PropTypesLocationType,
  quickAccessLocationTypes: PropTypes.arrayOf(PropTypesLocationType.isRequired)
    .isRequired,
};

interface MapStateToProps {
  mainLocationType: LocationType | null;
  quickAccessLocationTypes: LocationType[];
}

const mapStateToProps = (state: State): MapStateToProps => ({
  mainLocationType: mainLocationTypeSelector(state) || null,
  quickAccessLocationTypes: quickAccessLocationTypesSelector(state),
});

export default connect(mapStateToProps)(Sidebar);
