import 'antd/lib/tag/style';
import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement } from 'react';
import { SelectProps } from '../form/Select';
import {
  FilterableSelect,
  RequestStatusTag,
  Select,
  SelectOption,
} from '../index';
import { requestStatuses } from './RequestStatusUtils';

interface Props extends SelectProps {
  status?: string;
}

/**
 * @param {string} status
 * @param {any} props empty props
 * @return {ReactElement} Col element
 */
const RequestStatusSelect: FunctionComponent<Props> = ({
  status,
  ...props
}): ReactElement => {
  const options = Object.keys(requestStatuses).filter(
    (item): boolean => item !== status,
  );
  if (status) {
    options.unshift(status);
  }

  const selectOptions = options.map((status): ReactElement => {
    return (
      <SelectOption key={status} value={status} title={requestStatuses[status]}>
        {<RequestStatusTag status={status} />}
      </SelectOption>
    );
  });

  return props.isFilter ? (
    <FilterableSelect {...props}>{selectOptions}</FilterableSelect>
  ) : (
    <Select {...props}>{selectOptions}</Select>
  );
};

RequestStatusSelect.propTypes = {
  status: PropTypes.string,
  isFilter: PropTypes.bool,
};

export default RequestStatusSelect;
