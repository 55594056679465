import { createSelector } from 'reselect';
import { Location, LocationState } from './types';
import { State } from '../../types';
import { mainLocationTypeSelector } from '../locationtypes/selectors';
import { LocationType } from '../locationtypes/types';
import { currentUserSelector } from '../currentUser/selectors';
import { organizationAndItsDescendantsIdsSelector } from '../organizations/selectors/organizationsSelectors';

/**
 * @param {State} state
 * @return {LocationState}
 */
const locationStateSelector: (state: State) => LocationState = (state) =>
  state.locationsState;

/**
 * @param {State} state
 * @return {Location[]}
 */
export const locationsSelector = createSelector(
  locationStateSelector,
  (locationsState) => locationsState.locations,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const locationsIsGettingSelector = createSelector(
  locationStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const locationsIsListingSelector = createSelector(
  locationStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @param {Location|null}
 */
export const locationFromIdSelector = createSelector(
  locationsSelector,
  (_: State, id: Location['id']) => id,
  (locations, id) => locations.find((location) => location.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Location[]}
 */
export const locationFromMultipleIdsSelector = createSelector(
  locationsSelector,
  (_: State, ids: Location['id'][]) => ids,
  (locations, ids) => locations.filter((location) => ids.includes(location.id)),
);

/**
 * @param {State} state
 * @return {Location[]}
 */
export const mainLocationsSelector = createSelector(
  locationsSelector,
  mainLocationTypeSelector,
  (locations, locationType) =>
    locationType
      ? locations.filter(
          (location) => location.locationTypeId === locationType.id,
        )
      : [],
);

/**
 * @param {State} state
 * @return {Location[]}
 */
export const locationsFromLocationTypeIdSelector = createSelector(
  locationsSelector,
  (_: State, id: LocationType['id']) => id,
  (locations, locationTypeId) =>
    locations.filter((location) => location.locationTypeId === locationTypeId),
);

/**
 * @param {string} id
 * @return {Location[]}
 */
export const rootLocationsSelector = createSelector(
  locationsSelector,
  (locations) =>
    locations.filter((location) => typeof location.parentId === 'undefined'),
);

/**
 * @param {State} state
 * @param {Location} location
 * @return {Location[]}
 */
export const locationChildrenSelector = createSelector(
  locationsSelector,
  (_: State, location: Location) => location,
  (stateLocations, location) =>
    stateLocations.filter(
      (stateLocation) => stateLocation.parentId === location.id,
    ),
);

/**
 * @param {State} state
 * @param {Location} location
 * @return {Location[]}
 */
export const locationDescendantsSelector = (
  state: State,
  location: Location,
): Location[] => {
  const children = locationChildrenSelector(state, location);

  if (0 === children.length) {
    return [];
  }

  const descendants = children
    .map((child) => locationDescendantsSelector(state, child))
    .reduce((a, b) => a.concat(b));

  return children.concat(descendants);
};

/**
 * @param {State} state
 * @param {Location} location
 * @return {Location[]|null}
 */
export const locationParentSelector = createSelector(
  locationsSelector,
  (_: State, location: Location) => location,
  (stateLocations, location) =>
    null !== location.parentId
      ? stateLocations.find(
          (stateLocation) => stateLocation.id === location.parentId,
        ) || null
      : null,
);

/**
 * @param {State} state
 * @param {Location} location
 * @return {Location[]}
 */
export const locationAncestorsSelector = (
  state: State,
  location: Location,
): Location[] => {
  const parent = locationParentSelector(state, location);

  if (null === parent) {
    return [];
  }

  return [parent].concat(locationAncestorsSelector(state, parent));
};

/**
 * @param {State} state
 * @param {Location} location
 * @return {string}
 */
export const locationPathSelector = (
  state: State,
  location: Location,
): string =>
  locationAncestorsSelector(state, location)
    .reverse()
    .concat(location)
    .map((ancestor) => ancestor.name)
    .join(' > ');

/**
 * @param {State} state
 * @param {Location} location
 * @return {Location}
 */
export const locationRootSelector = (
  state: State,
  location: Location,
): Location => {
  const ancestors = locationAncestorsSelector(state, location);

  if (0 === ancestors.length) {
    return location;
  }

  const root = ancestors.find((ancestor) => !ancestor.parentId);

  if ('undefined' === typeof root) {
    throw Error(`"${location.name}" has no root.`);
  }

  return root;
};

/**
 * @param {State} state
 * @param {Location} location
 * @return {Location}
 */
export const locationsTreeSelector = (
  state: State,
  location: Location,
): Location => ({
  ...location,
  children: locationChildrenSelector(state, location).map((child) =>
    locationsTreeSelector(state, child),
  ),
});

/**
 * @param {State} state
 * @return {Function}
 */
export const locationsTreesSelector = (state: State): Location[] =>
  rootLocationsSelector(state).map((location) =>
    locationsTreeSelector(state, location),
  );

/**
 * @param {State} state
 * @param {Location} location
 * @return {string[]}
 */
export const locationAndItsParentsIdsSelector = (
  state: State,
  location: Location,
): Location['id'][] => [location.id].concat(getAncestorIds(state, location));

/**
 * @param {State} state
 * @return {object}
 */
export const locationsAndTheirParentsIdsSelector = (
  state: State,
): Record<string, string[]> => {
  const locations = locationsSelector(state);

  const result: Record<string, string[]> = {};

  locations.forEach((location): void => {
    result[location.id] = locationAndItsParentsIdsSelector(state, location);
  });

  return result;
};

/**
 * @param {State} state
 * @param {Location} location
 * @return {string[]}
 */
export const locationAndItsDescendantsIdsSelector = (
  state: State,
  location: Location,
): Location['id'][] =>
  [location.id].concat(
    getDescendantIds(locationsTreeSelector(state, location)),
  );

/**
 * @param {State} state
 * @return {object}
 */
export const locationsAndTheirDescendantsIdsSelector = (
  state: State,
): Record<string, string[]> => {
  const locations = locationsSelector(state);

  const result: Record<string, string[]> = {};

  locations.forEach((location): void => {
    result[location.id] = locationAndItsDescendantsIdsSelector(state, location);
  });

  return result;
};

/**
 * @param {Location} location
 * @return {string[]}
 */
const getDescendantIds = (location: Location): string[] => {
  if (!location.children) {
    return [];
  }

  return location.children
    .map((child) => child.id)
    .concat(
      location.children
        .map((child) => getDescendantIds(child))
        .reduce((a, b) => a.concat(b), []),
    );
};

/**
 * @param {State} state
 * @param {Location} location
 * @return {string[]}
 */
const getAncestorIds = (state: State, location: Location): string[] => {
  const parent = locationParentSelector(state, location);

  if (null === parent) {
    return [];
  }

  return [parent.id].concat(getAncestorIds(state, parent));
};

/**
 * @param {State} state
 * @param {string} id
 * @return {Location[]}
 */
export const organizationLocationsTreesSelector = createSelector(
  (state) => state,
  rootLocationsSelector,
  organizationAndItsDescendantsIdsSelector,
  (state, locations, organizationIds) =>
    locations
      .filter((location) => organizationIds.includes(location.organizationId))
      .map((location) => locationsTreeSelector(state, location)),
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Location[]}
 */
export const currentUserLocationsTreesSelector = createSelector(
  (state) => state,
  rootLocationsSelector,
  currentUserSelector,
  (state, locations, currentUser) =>
    locations
      .filter((location) =>
        currentUser.visibleOrganizationIds.includes(location.organizationId),
      )
      .map((location) => locationsTreeSelector(state, location)),
);
