import { FetchListByDependencyRequested } from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { listInterventionsByIncidentId } from '../../../services/api';
import { getActionType } from '../common/types';

/**
 * @param {FetchListByDependencyRequested} action
 */
export function* listInterventionByIncidentIdSaga(
  action: FetchListByDependencyRequested<types.Intervention>,
): SagaIterator {
  try {
    const interventions: types.Intervention[] = yield call(
      listInterventionsByIncidentId,
      action.payload,
    );

    yield put(
      actions.fetchListInterventionsSuccessActionCreator(interventions),
    );

    action.meta.resolve(interventions);
  } catch (err) {
    yield put(actions.fetchListInterventionsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* interventionsSagas(): SagaIterator {
  yield takeEvery(
    getActionType('interventions', 'LIST_REQUESTED'),
    listInterventionByIncidentIdSaga,
  );
}
