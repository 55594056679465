import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextArea,
  Drawer,
  Button,
  Row,
  Col,
  notification,
  FormItem,
  Input,
} from '../../../base';
import { requestTypes as types } from '../../../../../states/ducks/requests';
import { Form } from 'antd';
import { Request } from '../../../../../states/ducks/requests/types';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../../states/ducks/currentUser/types';

interface FormOpenRequestProps {
  currentUser?: CurrentUser;
  hideModal: Function;
  visible: boolean;
  openRequest: Function;
}

const FormOpenRequest: FunctionComponent<FormOpenRequestProps> = ({
  currentUser,
  hideModal,
  visible,
  openRequest,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = (values: Partial<Request>): void => {
    setLoading(true);

    const request = {
      ...values,
    };

    request.creatorId = currentUser?.id;
    request.organizationId = currentUser?.organizationIds[0];

    const promise = new Promise<types.Request>((resolve, reject): void =>
      openRequest(request, { resolve, reject }),
    );

    promise
      .then((): void => {
        form.resetFields();
        hideModal();
        const message = `La demande a bien été ouverte`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      })
      .finally((): void => setLoading(false));
  };

  const handleCancel = (): void => {
    form.resetFields();
    hideModal();
  };

  const objectInput = (
    <FormItem
      label="Objet de la demande"
      name="object"
      rules={[
        {
          type: 'string',
          required: true,
          message: "Veuillez renseigner l'objet",
        },
      ]}
    >
      <Input placeholder="Saisir l'objet de la demande" />
    </FormItem>
  );

  const bodyInput = (
    <FormItem
      label="Demande"
      name="body"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner le détail de la demande',
        },
      ]}
    >
      <TextArea
        placeholder="Merci de détailler votre demande"
        autoSize={{ minRows: 4, maxRows: 10 }}
      />
    </FormItem>
  );

  return (
    <Drawer
      title="Nouvelle demande"
      onClose={handleCancel}
      visible={visible}
      footer={
        <>
          <Button htmlType="button" size="large" onClick={handleCancel}>
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            onClick={form.submit}
          >
            Envoyer
          </Button>
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={24}>{objectInput}</Col>
          <Col span={24}>{bodyInput}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};

FormOpenRequest.propTypes = {
  currentUser: PropTypesCurrentUser.isRequired,
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  openRequest: PropTypes.func.isRequired,
};

export default FormOpenRequest;
