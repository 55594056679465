import React, { FunctionComponent, ReactElement } from 'react';
import { DatePicker as DatePickerAnt } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style';
import './RangePicker.scss';
import RangePickerLocale from 'antd/lib/date-picker/locale/fr_FR';

/**
 * RangePicker component
 * @param {boolean} inverted
 * @param {any} props
 * @return {ReactElement}
 */
const RangePicker: FunctionComponent<RangePickerProps> = ({
  ...props
}): ReactElement => {
  const { RangePicker } = DatePickerAnt;
  const containerID = 'no-outside-click-event' + String(new Date().valueOf());

  return (
    <div id={containerID} className="no-outside-click-event">
      <RangePicker
        {...props}
        locale={RangePickerLocale}
        format={'DD/MM/YY'}
        separator="~"
        getPopupContainer={(): HTMLElement => {
          return document.getElementById(containerID) as HTMLElement;
        }}
      />
    </div>
  );
};

export default RangePicker;
