import cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
import { getToken, refreshToken } from '../api';
import { User } from '../../states/ducks/users/types';

export const accessTokenKey = 'accessToken';
export const refreshTokenKey = 'refreshToken';
export const refreshTokenSecretKey = 'refreshTokenSecretKey';

interface LoginResponse {
  refreshToken: string;
  secret: string;
  userId: string;
  expirationAt: string;
}

interface DecodedAccessToken {
  isVirtualAccessToken: boolean | undefined;
  locationIds: string[];
  organizationIds: string[];
  userId: string;
  visibleLocationIds: string[];
  visibleOrganizationIds: string[];
}

/**
 * @export
 * @return {string | undefined}
 */
export function getAccessToken(): string | undefined {
  return cookie.get(accessTokenKey);
}

/**
 * @export
 * @return {DecodedAccessToken | null}
 */
export function getDecodedAccessToken(): DecodedAccessToken | null {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return null;
  }

  const decodedToken = jwt.decode(accessToken);

  if (!decodedToken) {
    return null;
  }

  return decodedToken as DecodedAccessToken;
}

/**
 * @export
 * @return {string|undefined}
 */
export function getCurrentUserId(): string | undefined {
  const decodedAccessToken = getDecodedAccessToken();

  if (null === decodedAccessToken) {
    return '';
  }

  return decodedAccessToken.userId;
}

/**
 * @param {User} user
 * @export
 * @return {User}
 */
export function handleVirtualAccessToken(user: User): User {
  const decodedAccessToken = getDecodedAccessToken();

  if (null === decodedAccessToken) {
    return user;
  }

  if (!decodedAccessToken.isVirtualAccessToken) {
    return user;
  }

  return {
    ...user,
    locationIds: decodedAccessToken.locationIds,
    organizationIds: decodedAccessToken.organizationIds,
    visibleLocationIds: decodedAccessToken.visibleLocationIds,
    visibleOrganizationIds: decodedAccessToken.visibleOrganizationIds,
  };
}

/**
 * @param {LoginResponse} response
 * @export
 * @return {void}
 */
function setAuthCookies(response: LoginResponse): void {
  cookie.set(refreshTokenKey, response.refreshToken, { expires: 1 });
  cookie.set(refreshTokenSecretKey, response.secret, { expires: 1 });
}

/**
 * @param {string} email login
 * @param {string} password login
 * @export
 * @return {Promise<boolean>}
 */
export async function authentication(
  email: string,
  password: string,
): Promise<void | string> {
  return getToken(email, password)
    .then((response: Response): Promise<LoginResponse> => response.json())
    .then((loginResponse): Promise<void> => {
      setAuthCookies(loginResponse);

      return Promise.resolve();
    })
    .catch((err: Error): Promise<string> => Promise.reject(err.message));
}

/**
 * @export
 * @return {Promise<boolean|Error>}
 */
export async function isAuthenticated(): Promise<boolean | Error> {
  if (getAccessToken() !== undefined) {
    return true;
  }

  const token = cookie.get(refreshTokenKey);
  const secret = cookie.get(refreshTokenSecretKey);

  if (token === undefined || secret === undefined) {
    return false;
  }

  return refreshToken(token, secret)
    .then((): Promise<boolean> => Promise.resolve(true))
    .catch((err: Error): Promise<Error> => Promise.reject(err));
}

/**
 * unauthentication of the current user
 * @param {boolean} allowToRefresh
 * @return {boolean}
 */
export function unauthentication(allowToRefresh = false): Promise<boolean> {
  cookie.remove(accessTokenKey, {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
  });

  if (!allowToRefresh) {
    cookie.remove(refreshTokenKey);
    cookie.remove(refreshTokenSecretKey);
  }

  return Promise.resolve(true);
}

export { withAuthenticate } from './hoc';

/**
 * @return {boolean}
 */
export function isConnected(): boolean {
  return getAccessToken() !== undefined;
}

/**
 * @return {boolean}
 */
export function getRefreshAuthenticationInfo(): string[] | false {
  const token = cookie.get(refreshTokenKey);
  const secret = cookie.get(refreshTokenSecretKey);

  return token !== undefined && secret !== undefined ? [token, secret] : false;
}

/**
 * @return {Promise<Response>}
 */
export async function refreshAuthentication(): Promise<Response> {
  const refreshAuthenticationInfo = getRefreshAuthenticationInfo();

  if (!refreshAuthenticationInfo) {
    throw new Error(
      'RefreshAuthentication : no refresh token for current user',
    );
  }

  return refreshToken(
    refreshAuthenticationInfo[0],
    refreshAuthenticationInfo[1],
  );
}
