import React, { FunctionComponent, ReactElement } from 'react';
import { Dropdown as DropdownAnt } from 'antd';
import 'antd/lib/dropdown/style';
import { DropDownProps } from 'antd/lib/dropdown';
import './Dropdown.scss';

/**
 * Dropdown component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Dropdown: FunctionComponent<DropDownProps> = ({
  ...props
}): ReactElement => <DropdownAnt {...props} />;

export default Dropdown;
