import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {Subscription[]} [state=[]] subscriptions
 * @param {AnyAction} action
 * @return {Subscription[]}
 */
export const subscriptionReducer: Reducer<types.Subscription[]> = (
  state: types.Subscription[] = [],
  action: AnyAction,
): types.Subscription[] => {
  if (action.type === getActionType('subscriptions', 'SUBSCRIBE_SUCCEEDED')) {
    action.payload.userId = action.payload.userInformation.userId;
    delete action.payload.userInformation;
  }

  return modelReducer('subscriptions', state, action) as types.Subscription[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const subscribingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('subscriptions', 'SUBSCRIBE_FAILED'):
    case getActionType('subscriptions', 'SUBSCRIBE_SUCCEEDED'):
      return false;
    case getActionType('subscriptions', 'SUBSCRIBE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('subscriptions', 'LIST_FAILED'):
    case getActionType('subscriptions', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('subscriptions', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const unsubscribingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('subscriptions', 'UNSUBSCRIBE_FAILED'):
    case getActionType('subscriptions', 'UNSUBSCRIBE_SUCCEEDED'):
      return false;
    case getActionType('subscriptions', 'UNSUBSCRIBE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.SubscriptionState> = combineReducers({
  subscriptions: subscriptionReducer,
  status: combineReducers({
    subscribing: subscribingReducer,
    listing: listingReducer,
    unsubscribing: unsubscribingReducer,
  }),
});

export default reducers;
