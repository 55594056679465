import React, { FunctionComponent, ReactElement } from 'react';
import { Row as RowAnt } from 'antd';
import 'antd/lib/row/style';
import { RowProps } from 'antd/lib/row';
import PropTypes from 'prop-types';
import './Row.scss';

/**
 * @param {number} gutter
 * @param {any} props
 *
 * @return {ReactElement}
 */
const Row: FunctionComponent<RowProps> = ({
  gutter = 22,
  ...props
}): ReactElement => <RowAnt gutter={gutter} {...props} />;

Row.propTypes = {
  gutter: PropTypes.oneOfType([
    PropTypes.shape({
      xs: PropTypes.number.isRequired,
      sm: PropTypes.number.isRequired,
      md: PropTypes.number.isRequired,
      lg: PropTypes.number.isRequired,
      xl: PropTypes.number.isRequired,
      xxl: PropTypes.number.isRequired,
    }),
    PropTypes.number,
  ]),
};

export default Row;
