import { createSelector } from 'reselect';
import { usersSelector } from './base';
import { currentUserSelector } from '../../currentUser/selectors';

/**
 * @param {State} state
 * @return {User[]}
 */
export const visibleUsersSelector = createSelector(
  usersSelector,
  (state) => currentUserSelector(state),
  (users, currentUser) =>
    users.filter(
      (user) =>
        currentUser.visibleOrganizationIds.filter((id) =>
          user.organizationIds.includes(id),
        ).length > 0,
    ),
);
