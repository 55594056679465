import { createSelector } from 'reselect';
import { HistoryState } from './types';
import { State } from '../../types';
import { Incident } from '../incidents/types';
import { Request } from '../requests/types';

/**
 * @param {State} state
 * @return {HistoryState}
 */
const historyStateSelector: (state: State) => HistoryState = (state) =>
  state.historiesState;

/**
 * @param {State} state
 * @return {History[]}
 */
export const historiesSelector = createSelector(
  historyStateSelector,
  (applicationState) => applicationState.histories,
);

/**
 * @param {State} state
 * @return {History[]}
 */
export const historiesIsListingSelector = createSelector(
  historyStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {History[]}
 */
export const historiesFromIncidentIdSelector = createSelector(
  historiesSelector,
  (_: State, id: Incident['id']) => id,
  (histories, id) =>
    histories.filter(
      (history) => history.objectType === 'incident' && history.objectId === id,
    ),
);

/**
 * @param {State} state
 * @param {string} id
 * @return {History[]}
 */
export const historiesFromRequestIdSelector = createSelector(
  historiesSelector,
  (_: State, id: Request['id']) => id,
  (histories, id) =>
    histories.filter(
      (history) => history.objectType === 'request' && history.objectId === id,
    ),
);
