import {
  incidentTypeActions,
  incidentTypeSelectors,
  incidentTypeTypes,
} from '../../../../states/ducks/incidenttypes';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import PropTypes from 'prop-types';

interface IncidentTypeNameProps {
  incidentType: incidentTypeTypes.IncidentType | null;
  getIncidentType: Function;
}

/**
 * @param {IncidentType} incidentType
 * @param {Function} getIncidentType
 *
 * @return {ReactElement}
 */
const IncidentTypeName: FunctionComponent<IncidentTypeNameProps> = ({
  incidentType,
  getIncidentType,
}): ReactElement => {
  useEffect((): void => {
    if (getIncidentType && !incidentType) {
      getIncidentType();
    }
  }, [getIncidentType, incidentType]);

  return <>{incidentType ? incidentType.name : ''}</>;
};

IncidentTypeName.propTypes = {
  incidentType: incidentTypeTypes.PropTypesIncidentType.isRequired,
  getIncidentType: PropTypes.func.isRequired,
};

interface MapStateToProps {
  incidentType: incidentTypeTypes.IncidentType | null;
}

interface MapDispatchToProps {
  getIncidentType: Function;
}

const mapStateToProps = (
  state: State,
  props: { incidentTypeId: incidentTypeTypes.IncidentType['id'] },
): MapStateToProps => ({
  incidentType: incidentTypeSelectors.incidentTypeFromIdSelector(
    state,
    props.incidentTypeId,
  ),
});

const mapDispatchToProps: MapDispatchToProps = {
  getIncidentType: incidentTypeActions.fetchGetIncidentTypesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentTypeName);
