import React, { FunctionComponent, ReactElement } from 'react';
import { Typography } from 'antd';
import 'antd/lib/typography/style';
import './Title.scss';

import classNames from 'classnames';

import PropTypes from 'prop-types';

interface Props {
  fancy?: boolean;
  sans?: boolean;
  className?: string;
  level?: 1 | 2 | 5 | 3 | 4 | undefined;
}

/**
 * Title component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Title: FunctionComponent<Props> = ({
  fancy = false,
  sans = false,
  ...props
}): ReactElement => {
  const initialClasses = props.className ? props.className : '';
  const classes = classNames({ fancy, sans }, 'app-title', initialClasses);

  return <Typography.Title className={classes} {...props} />;
};

Title.propTypes = {
  className: PropTypes.string,
  fancy: PropTypes.bool,
  sans: PropTypes.bool,
  level: PropTypes.oneOf([1, 2, 3, 4, 5]),
};

export default Title;
