import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  kpiActions,
  kpiSelectors,
  kpiTypes,
} from '../../../../states/ducks/kpis';
import { Area } from '@ant-design/charts';
import RequestStatusUtils from '../../base/request/RequestStatusUtils';

interface RequestsByMonthChartProps {
  requestsOpenedAndClosedByMonth: kpiTypes.Kpi | null;
  countRequestsOpenedAndClosedByMonth: Function;
}

/**
 * RequestsByMonthChart component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const RequestsByMonthChart: FunctionComponent<RequestsByMonthChartProps> = ({
  requestsOpenedAndClosedByMonth,
  countRequestsOpenedAndClosedByMonth,
  ...props
}): ReactElement => {
  useEffect((): void => {
    countRequestsOpenedAndClosedByMonth();
  }, [countRequestsOpenedAndClosedByMonth]);

  const data =
    requestsOpenedAndClosedByMonth && requestsOpenedAndClosedByMonth.value
      ? requestsOpenedAndClosedByMonth.value
      : [];

  const config = {
    data: data,
    xField: 'date',
    yField: 'value',
    seriesField: 'status',
    xAxis: { tickCount: 5 },
    animation: false,
    color: (type): string => RequestStatusUtils.getColor(type.status),
    slider: {
      foregroundStyle: {
        fill: 'grey',
      },
    },
    legend: {
      itemName: {
        formatter: (text: string): string => {
          return RequestStatusUtils.getLabel(text);
        },
      },
    },
    tooltip: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formatter: (datum): any => {
        return {
          name: RequestStatusUtils.getLabel(datum.status),
          value: datum.value,
        };
      },
    },
  };

  return <Area {...config} />;
};

RequestsByMonthChart.propTypes = {
  requestsOpenedAndClosedByMonth: kpiTypes.PropTypesKpi,
  countRequestsOpenedAndClosedByMonth: PropTypes.func.isRequired,
};

interface MapStateToProps {
  requestsOpenedAndClosedByMonth: kpiTypes.Kpi | null;
}

interface MapDispatchToProps {
  countRequestsOpenedAndClosedByMonth: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const requestsOpenedAndClosedByMonth =
    kpiSelectors.requestsOpenedAndClosedByMonthSelector(state);

  return {
    requestsOpenedAndClosedByMonth: requestsOpenedAndClosedByMonth,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  countRequestsOpenedAndClosedByMonth:
    kpiActions.fetchCountRequestsOpenedAndClosedByMonthActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestsByMonthChart);
