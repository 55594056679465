import moment from 'moment';
import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchDeleteFilesActionCreator,
  fetchEditFilesExpireAtActionCreator,
  fetchEditFilesNameActionCreator,
  fetchEditFilesNoteActionCreator,
  fetchGetFilesActionCreator,
} from '../../../../states/ducks/files/actions';
import { fileFromIdSelector } from '../../../../states/ducks/files/selectors';
import { File, PropTypesFile } from '../../../../states/ducks/files/types';
import { State } from '../../../../states/types';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  EditableField,
  Field,
  Input,
  PopoverActions,
  Row,
  Tag,
  TextArea,
  TagsSelect,
} from '../index';
import { fetchGetProfilesByUserIdActionCreator } from '../../../../states/ducks/profiles/actions';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import { profileFromUserIdSelector } from '../../../../states/ducks/profiles/selectors';
import './InfoFile.scss';
import {
  fetchEditLinkActionCreator,
  fetchEditUnlinkActionCreator,
  fetchListTagsActionCreator,
} from '../../../../states/ducks/tags/actions';
import { tagsFromOrganizationSelector } from '../../../../states/ducks/tags/selectors';
import { tagTypes } from '../../../../states/ducks/tags';

interface InfoFileProps {
  deleteFile: Function;
  fileId?: File['id'];
  file: File | null;
  profile: Profile | null;
  getFile: Function;
  getProfile: Function;
  editFileExpireAt: Function;
  editFileName: Function;
  editFileNote: Function;
  tags: tagTypes.Tag[];
  loadTags: Function;
  linkObject: Function;
  unlinkObject: Function;
  hideDrawer: Function;
  visible: boolean;
}

/**
 * @param {Function} deleteFile
 * @param {string} fileId
 * @param {File} file
 * @param {Profile} profile
 * @param {Function} getFile
 * @param {Function} getProfile
 * @param {Function} editFileExpireAt
 * @param {Function} editFileName
 * @param {Function} editFileNote
 * @param {Function} hideDrawer
 * @param {boolean} visible
 *
 * @return {ReactElement}
 */
const InfoFile: FunctionComponent<InfoFileProps> = ({
  deleteFile,
  fileId,
  file,
  profile,
  getFile,
  getProfile,
  editFileExpireAt,
  editFileName,
  editFileNote,
  hideDrawer,
  linkObject,
  unlinkObject,
  tags,
  loadTags,
  visible,
}): ReactElement => {
  useEffect((): void => {
    if (getFile && fileId) {
      getFile(fileId);
    }
  }, [getFile, fileId]);

  useEffect((): void => {
    if (getProfile && file) {
      getProfile(file.userId);
    }
  }, [getProfile, file]);

  useEffect((): void => {
    loadTags();
  }, [loadTags]);

  if (!file) {
    return <></>;
  }

  const nameField = (
    <EditableField
      object={file}
      label={'Nom du document'}
      content={file.name}
      editedFieldsOptions={[
        {
          field: 'name',
          label: 'Nom du document',
          content: <Input />,
          required: true,
          initialValue: file.name,
        },
      ]}
      onSubmit={editFileName}
      successMessage="Le nom a bien été modifié"
    />
  );

  const expireAtField = (
    <EditableField
      object={file}
      label={"Valide jusqu'au"}
      content={
        file.expireAt ? (
          <>
            {moment(file.expireAt).format('DD/MM/YYYY')}
            {moment(file.expireAt).isBefore(moment()) ? (
              <Tag className={'is-expired'}>Expiré</Tag>
            ) : (
              <></>
            )}
          </>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: 'expireAt',
          label: "Valide jusqu'au",
          content: <DatePicker />,
          type: 'object',
          initialValue: moment(file.expireAt),
        },
      ]}
      onSubmit={editFileExpireAt}
      successMessage="La date d'expiration a bien été modifiée"
    />
  );

  const initialTagValue: string[] = [];
  const tagContent: ReactElement[] = tags
    .filter((tag): boolean => {
      if (
        tag.objectsLinked &&
        tag.objectsLinked['files'] &&
        tag.objectsLinked['files'].includes(file?.id)
      ) {
        initialTagValue.push(tag.id);
        return true;
      }

      return false;
    })
    .map((tag) => (
      <Tag key={tag.id} color="green">
        {tag.name}
      </Tag>
    ));

  const tagField = (
    <EditableField
      object={file}
      label={'Tag'}
      content={tagContent.length > 0 ? tagContent : ''}
      editedFieldsOptions={[
        {
          field: 'tagsIdsList',
          label: 'Tag',
          type: 'array',
          content: (
            <TagsSelect
              initialValue={initialTagValue ? initialTagValue : []}
              fileId={file.id}
              placeholder="Sélectionnez des tags"
            />
          ),
          initialValue: initialTagValue,
        },
      ]}
      onSubmit={(file, meta): void => {
        const tagsAdded = tags
          .filter((tag): boolean => {
            if (
              !initialTagValue?.includes(tag.id) &&
              file.tagsIdsList.includes(tag.id)
            ) {
              return true;
            }
            return false;
          })
          .map((tag) => tag);

        const tagsDeleted = tags
          .filter((tag): boolean => {
            if (
              initialTagValue?.includes(tag.id) &&
              !file.tagsIdsList.includes(tag.id)
            ) {
              return true;
            }
            return false;
          })
          .map((tag) => tag);

        if (tagsAdded.length !== 0) {
          linkObject(
            {
              tags: tagsAdded,
              objectsLinked: { files: [file.id] },
            },
            meta,
          );
        }

        if (tagsDeleted.length !== 0) {
          unlinkObject(
            {
              tags: tagsDeleted,
              objectsLinked: { files: [file.id] },
            },
            meta,
          );
        }
      }}
      successMessage="Les tags ont bien été modifiés"
    />
  );

  const noteField = (
    <EditableField
      object={file}
      label={'Note'}
      content={file.note}
      editedFieldsOptions={[
        {
          field: 'note',
          label: 'Note',
          content: <TextArea placeholder="Saisissez un commentaire" />,
          initialValue: file.note,
        },
      ]}
      onSubmit={editFileNote}
      successMessage="La note a bien été modifiée"
    />
  );

  return (
    <Drawer
      title={
        <>
          Document{' '}
          <PopoverActions
            objectId={file.id}
            onDelete={deleteFile}
            deleteConfirmMessage="Supprimer le fichier ?"
            deleteDoneMessage="Le fichier a bien été supprimé"
            download={true}
          />
        </>
      }
      visible={visible}
      onClose={(): void => hideDrawer()}
      footer={
        <Button onClick={(): void => hideDrawer()} type="primary" size="large">
          Fermer
        </Button>
      }
    >
      <Row>
        <Col span={12}>{nameField}</Col>
        <Col span={12}>
          <Field label={'Type du document'} content={file.fileMimeType} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>{tagField}</Col>
      </Row>
      <Row>
        <Col span={12}>
          <Field
            label={'Ajouté par'}
            content={profile ? `${profile.firstName} ${profile.lastName}` : ''}
          />
        </Col>
        <Col span={12}>
          <Field
            label={'Le'}
            content={moment(file.uploadedAt).format('DD/MM/YYYY à H[h]mm')}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>{expireAtField}</Col>
      </Row>
      <Row>
        <Col>{noteField}</Col>
      </Row>
    </Drawer>
  );
};

InfoFile.propTypes = {
  deleteFile: PropTypes.func.isRequired,
  fileId: PropTypes.string,
  // @ts-ignore
  file: PropTypesFile,
  profile: PropTypesProfile,
  getFile: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  editFileExpireAt: PropTypes.func.isRequired,
  editFileName: PropTypes.func.isRequired,
  editFileNote: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(tagTypes.PropTypesTag.isRequired).isRequired,
  loadTags: PropTypes.func.isRequired,
  linkObject: PropTypes.func.isRequired,
  unlinkObject: PropTypes.func.isRequired,
  hideDrawer: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

interface MapStateToProps {
  file: File | null;
  profile: Profile | null;
  tags: tagTypes.Tag[];
}

interface MapDispatchToProps {
  deleteFile: Function;
  getFile: Function;
  getProfile: Function;
  editFileExpireAt: Function;
  editFileName: Function;
  editFileNote: Function;
  loadTags: Function;
  linkObject: Function;
  unlinkObject: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    fileId?: string;
  },
): MapStateToProps => {
  const file = fileFromIdSelector(state, props.fileId || '');

  return {
    file: file,
    profile: file ? profileFromUserIdSelector(state, file.userId) : null,
    tags: tagsFromOrganizationSelector(state),
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  deleteFile: fetchDeleteFilesActionCreator,
  getFile: fetchGetFilesActionCreator,
  getProfile: fetchGetProfilesByUserIdActionCreator,
  editFileExpireAt: fetchEditFilesExpireAtActionCreator,
  editFileName: fetchEditFilesNameActionCreator,
  editFileNote: fetchEditFilesNoteActionCreator,
  loadTags: fetchListTagsActionCreator,
  linkObject: fetchEditLinkActionCreator,
  unlinkObject: fetchEditUnlinkActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoFile);
