import { State } from '../../../states/types';
import { connect } from 'react-redux';
import ContractorInfo from '../../components/layout/contractors/ContractorInfo';
import {
  contractorActions,
  contractorSelectors,
  contractorTypes,
} from '../../../states/ducks/contractors';
import {
  incidentTypeSelectors,
  incidentTypeTypes,
} from '../../../states/ducks/incidenttypes';
import { withRouter } from 'react-router';
import React, { ReactElement } from 'react';
import {
  organizationActions,
  organizationSelectors,
  organizationTypes,
} from '../../../states/ducks/organizations';
import { File } from '../../../states/ducks/files/types';
import { filesFromMultipleIdsSelector } from '../../../states/ducks/files/selectors';
import { chronologicalSortBy } from '../../../utils/SortUtils';
import { fetchGetFilesActionCreator } from '../../../states/ducks/files/actions';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface MapStateToProps {
  contractor: contractorTypes.Contractor | null;
  organization: organizationTypes.Organization | null;
  incidentTypes: incidentTypeTypes.IncidentType[];
  isGettingContractor: boolean;
  files: File[];
  email: contractorTypes.Contractor['email'];
}

interface MapDispatchToProps {
  getContractor: Function;
  getOrganization: Function;
  editContractorName: Function;
  editContractorTwimmLink: Function;
  editContractorIncidentTypes: Function;
  editContractorOrganization: Function;
  deleteContractor: Function;
  getFile: Function;
  addFileContractor: Function;
  addGEDFileContractor: Function;
  deleteFileContractor: Function;
  editContractorEmail: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    contractorId: contractorTypes.Contractor['id'];
  },
): MapStateToProps => {
  const contractor = contractorSelectors.contractorFromIdSelector(
    state,
    props.contractorId,
  );

  const organization = contractor
    ? organizationSelectors.organizationFromIdSelector(
        state,
        contractor.organizationId,
      )
    : null;

  const allFiles =
    contractor && contractor.fileIds
      ? filesFromMultipleIdsSelector(state, contractor.fileIds)
      : [];

  allFiles.sort((a, b) => chronologicalSortBy(a.uploadedAt, b.uploadedAt));

  const files = allFiles.filter(
    (file) => !file.fileMimeType?.includes('image/'),
  );

  return {
    contractor: contractor,
    organization: organization,
    incidentTypes:
      incidentTypeSelectors.incidentTypesFromOrganizationSelector(state),
    isGettingContractor:
      contractorSelectors.contractorsIsGettingSelector(state),
    files: files,
    email: contractor?.email,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getContractor: contractorActions.fetchGetContractorsActionCreator,
  getOrganization: organizationActions.fetchGetOrganizationsActionCreator,
  editContractorName: contractorActions.fetchEditContractorsNameActionCreator,
  editContractorTwimmLink:
    contractorActions.fetchEditContractorsTwimmLinkActionCreator,
  editContractorIncidentTypes:
    contractorActions.fetchEditContractorsIncidentTypesActionCreator,
  editContractorOrganization:
    contractorActions.fetchEditContractorsOrganizationActionCreator,
  deleteContractor: contractorActions.fetchDeleteContractorsActionCreator,
  getFile: fetchGetFilesActionCreator,
  addFileContractor: contractorActions.fetchAddFileContractorsActionCreator,
  addGEDFileContractor:
    contractorActions.fetchAddGEDFileContractorsActionCreator,
  deleteFileContractor:
    contractorActions.fetchDeleteFileContractorsActionCreator,
  editContractorEmail: contractorActions.fetchEditContractorsEmailActionCreator,
};

const ContractorInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorInfo);

export default withRouter(
  (props: Props): ReactElement => (
    <ContractorInfoContainer contractorId={props.match.params.id} />
  ),
);
