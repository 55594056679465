import { connect } from 'react-redux';
import Incident from '../../components/layout/incidents/Incident';
import {
  incidentTypeTypes,
  incidentTypeSelectors,
  incidentTypeActions,
} from '../../../states/ducks/incidenttypes';
import { State } from '../../../states/types';

interface MapStateToProps {
  incidentTypes: incidentTypeTypes.IncidentType[];
}

interface MapDispatchToProps {
  deleteIncidentType: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const incidentTypes =
    incidentTypeSelectors.incidentTypesFromOrganizationSelector(state);
  return {
    incidentTypes,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  deleteIncidentType: incidentTypeActions.fetchDeleteIncidentTypesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Incident);
