import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Button,
  Skeleton,
  Empty,
  Input,
  EditableField,
  DateField,
} from '../../../base';
import { incidentTypeTypes } from '../../../../../states/ducks/incidenttypes';
import { DrawerProps } from 'antd/lib/drawer';
import { useGet } from '../helper';

interface GetIncidentTypeProps extends DrawerProps {
  hideDrawer: Function;
  incidentType: incidentTypeTypes.IncidentType | null;
  getIncidentType: Function;
  incidentTypeId: incidentTypeTypes.IncidentType['id'] | null;
  isFetching: boolean;
  editIncidentTypeDesignation: Function;
}

const GetIncidentType: FunctionComponent<GetIncidentTypeProps> = ({
  hideDrawer,
  visible = false,
  incidentType,
  getIncidentType,
  incidentTypeId,
  isFetching,
  editIncidentTypeDesignation,
}): ReactElement => {
  useGet<incidentTypeTypes.IncidentType>({
    id: incidentTypeId,
    isFetching,
    visible,
    apiGet: getIncidentType,
    object: incidentType,
    description: "la récupération du type d'incident a échoué",
  });

  const designationField = incidentType ? (
    <EditableField
      object={incidentType}
      label="Désignation"
      content={incidentType.name}
      editedFieldsOptions={[
        {
          field: 'name',
          label: 'Désignation',
          content: <Input />,
          required: true,
          initialValue: incidentType.name,
        },
      ]}
      onSubmit={editIncidentTypeDesignation}
      successMessage="Le type d'incident a bien été modifié"
    />
  ) : (
    ''
  );
  return (
    <Drawer
      title="Détail du type d'incident"
      visible={visible}
      onClose={(): void => hideDrawer()}
      footer={
        <Button onClick={(): void => hideDrawer()} type="primary" size="large">
          Fermer
        </Button>
      }
    >
      <Skeleton loading={isFetching}>
        {incidentType !== null ? (
          <>
            {designationField}
            <DateField label="Créé le" date={incidentType.createdAt} />
            <DateField label="Modifié le" date={incidentType.updatedAt} />
          </>
        ) : (
          <Empty />
        )}
      </Skeleton>
    </Drawer>
  );
};

GetIncidentType.propTypes = {
  visible: PropTypes.bool,
  hideDrawer: PropTypes.func.isRequired,
  incidentType: incidentTypeTypes.PropTypesIncidentType,
  incidentTypeId: PropTypes.string,
  getIncidentType: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  editIncidentTypeDesignation: PropTypes.func.isRequired,
};

export default GetIncidentType;
