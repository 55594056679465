import React, { FunctionComponent, ReactElement } from 'react';
import 'antd/lib/icon/style';
import Tooltip from './Tooltip';
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from 'prop-types';

interface Props {
  date: string;
  userName?: string;
}

/**
 * Date tooltip component
 *
 * @param {string} date
 * @param {string} userName
 * @return {ReactElement}
 */
const DateTooltip: FunctionComponent<Props> = ({
  date,
  userName,
}): ReactElement => {
  moment.locale('fr');
  const momentDate = moment(date);
  const formattedDate = momentDate.format('L [à] H[h]mm');
  const title = formattedDate + (userName ? ` par ${userName}` : '');

  return (
    <Tooltip title={title}>
      <span>{momentDate.fromNow()}</span>
    </Tooltip>
  );
};

DateTooltip.propTypes = {
  date: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

export default DateTooltip;
