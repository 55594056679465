import { connect } from 'react-redux';
import AddLocationType from '../../../components/layout/modal/locationtype/AddLocationType';
import { locationTypeActions } from '../../../../states/ducks/locationtypes';
import { State } from '../../../../states/types';
import { Organization } from '../../../../states/ducks/organizations/types';
import { currentUserOrganizationRootSelector } from '../../../../states/ducks/currentUser/selectors';

interface MapStateToProps {
  userOrganization: Organization;
}

interface MapDispatchToProps {
  addLocationType: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  userOrganization: currentUserOrganizationRootSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  addLocationType: locationTypeActions.fetchAddLocationTypesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocationType);
