import { createSelector } from 'reselect';
import { Contractor, ContractorState } from './types';
import { State } from '../../types';
import { Incident } from '../incidents/types';
import { IncidentType } from '../incidenttypes/types';
import {
  organizationAndItsParentsIdsSelector,
  organizationFromIdSelector,
} from '../organizations/selectors/organizationsSelectors';
import {
  currentUserOrganizationRootSelector,
  currentUserSelector,
} from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {ContractorState}
 */
const contractorStateSelector: (state: State) => ContractorState = (state) =>
  state.contractorsState;

/**
 * @param {State} state
 * @return {Contractor[]}
 */
export const contractorsSelector = createSelector(
  contractorStateSelector,
  (applicationState) => applicationState.contractors,
);

/**
 * @param {State} state
 * @return {Contractor[]}
 */
export const contractorsFromOrganizationSelector = createSelector(
  contractorsSelector,
  currentUserOrganizationRootSelector,
  (contractors, organization) =>
    contractors.filter(
      (contractors) => contractors.organizationId === organization.id,
    ),
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const contractorsIsGettingSelector = createSelector(
  contractorStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const contractorsIsListingSelector = createSelector(
  contractorStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Contractor|null}
 */
export const contractorFromIdSelector = createSelector(
  contractorsSelector,
  (_: State, id: Contractor['id']) => id,
  (contractors, id) =>
    contractors.find((contractor) => contractor.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Contractor[]}
 */
export const contractorsFromMultipleIdsSelector = createSelector(
  contractorsSelector,
  (_: State, ids: Contractor['id'][]) => ids,
  (contractors, ids) =>
    contractors.filter((contractor) => ids.includes(contractor.id)),
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Contractor[]}
 */
export const contractorsFromMultipleOrganizationIdsSelector = createSelector(
  contractorsSelector,
  (_: State, ids: Contractor['organizationId'][]) => ids,
  (contractors, ids) =>
    contractors.filter((contractor) => ids.includes(contractor.organizationId)),
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Contractor|null}
 */
export const contractorsFromIncidentTypeIdSelector = createSelector(
  contractorsSelector,
  (_: State, id: IncidentType['id']) => id,
  (contractors, id) =>
    contractors.filter(
      (contractor) =>
        contractor.incidentTypeIds && contractor.incidentTypeIds.includes(id),
    ),
);

/**
 * @param {State} state
 * @param {Incident} incident
 * @return {Contractor[]}
 */
export const contractorsAssignableToIncidentSelector = createSelector(
  (state, incident) =>
    contractorsFromIncidentTypeIdSelector(state, incident.incidentTypeId),
  (state: State, incident: Incident) => {
    const organization = organizationFromIdSelector(
      state,
      incident.organizationId,
    );

    if (null === organization) {
      return [];
    }

    return organizationAndItsParentsIdsSelector(state, organization);
  },
  (contractors, organizationIds) =>
    contractors.filter((contractor) =>
      organizationIds.includes(contractor.organizationId),
    ),
);

/**
 * @param {State} state
 * @return {Contractor[]}
 */
export const visibleContractorsSelector = createSelector(
  contractorsSelector,
  (state) => currentUserSelector(state),
  (contractors, currentUser) =>
    contractors.filter((contractor) =>
      currentUser.visibleOrganizationIds.includes(contractor.organizationId),
    ),
);
