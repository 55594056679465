import React, {
  ReactElement,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  userActions,
  userSelectors,
  userTypes,
} from '../../../../states/ducks/users';
import {
  Title,
  Homepage,
  InputPassword,
  notification,
  Button,
  FormItem,
} from '../../base';
import { Form } from 'antd';
import { User } from '../../../../states/ducks/users/types';
import { RuleObject } from 'antd/lib/form';
import { Store } from 'redux';
import { decode } from 'jsonwebtoken';
import { usersIsGettingSelector } from '../../../../states/ducks/users/selectors/base';

interface Props {
  match: {
    params: {
      token: string;
    };
  };
}

interface ResetPasswordProps {
  userId: userTypes.User['id'] | null;
  user: userTypes.User | null;
  getUser: Function;
  onResetPassword: Function;
  isTokenValid: boolean;
  isGettingUser: boolean;
}

/**
 * @param {string} userId
 * @param {User} user
 * @param {Function} getUser
 * @param {Function} onResetPassword
 * @param {boolean} isTokenValid
 * @param {boolean} isGettingUser
 *
 * @return {boolean}
 */
const ResetPassword: FunctionComponent<ResetPasswordProps> = ({
  userId,
  user,
  getUser,
  onResetPassword,
  isTokenValid,
  isGettingUser,
}): ReactElement => {
  const [hasGotUser, setHasGotUser] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect((): void => {
    if (getUser && !user) {
      getUser(userId);
    }
  }, [getUser, user, userId]);

  useEffect((): void => {
    if (isGettingUser) {
      setHasGotUser(true);
    }
  }, [isGettingUser]);

  if (hasGotUser && !isGettingUser && !user) {
    notification.error({ message: "L'utilisateur n'existe pas" });
    setRedirect(true);
  }

  if (!isTokenValid) {
    notification.error({ message: "Le lien n'est plus valide" });
    setRedirect(true);
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  const passwordInput = (
    <FormItem
      label="Saisissez un nouveau mot de passe"
      name="password"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner un mot de passe',
        },
      ]}
      hasFeedback
    >
      <InputPassword placeholder="Mot de passe" />
    </FormItem>
  );
  const passwordConfirmInput = (
    <FormItem
      label="Confirmation du mot de passe"
      name="passwordConfirm"
      dependencies={['password']}
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez confirmer le mot de passe',
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ getFieldValue }): any => ({
          validator(rule: RuleObject, value: Store): Promise<void> {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }

            return Promise.reject(
              new Error('Les mots de passe ne correspondent pas'),
            );
          },
        }),
      ]}
      hasFeedback
    >
      <InputPassword placeholder="Mot de passe" />
    </FormItem>
  );
  const handleFinish = (values: Partial<User>): void => {
    const promise = new Promise<userTypes.User>((resolve, reject): void =>
      onResetPassword(
        {
          ...user,
          password: values.password,
        },
        { resolve, reject },
      ),
    );

    promise
      .then((): void => {
        notification.success({
          message: 'Mot de passe changé',
        });

        setRedirect(true);
      })
      .catch((): void => {
        notification.error({
          message: "Le mot de passe n'a pu être changé",
        });
      });
  };

  return (
    <Homepage>
      <Title fancy>Réinitialisation du mot de passe</Title>
      <Form onFinish={handleFinish}>
        {passwordInput}
        {passwordConfirmInput}
        <Button
          className="app-btn-submit"
          type="primary"
          size="large"
          htmlType="submit"
        >
          Enregistrer
        </Button>
      </Form>
    </Homepage>
  );
};

ResetPassword.propTypes = {
  userId: PropTypes.string.isRequired,
  user: userTypes.PropTypesUser.isRequired,
  getUser: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  isTokenValid: PropTypes.bool.isRequired,
  isGettingUser: PropTypes.bool.isRequired,
};

interface MapStateToProps {
  userId: userTypes.User['id'] | null;
  user: userTypes.User | null;
  isTokenValid: boolean;
  isGettingUser: boolean;
}

interface MapDispatchToProps {
  getUser: Function;
  onResetPassword: Function;
}

const mapStateToProps = (
  state: State,
  props: { token: string },
): MapStateToProps => {
  const decodedToken = decode(props.token, { json: true });

  const userId = decodedToken && decodedToken.sub ? decodedToken.sub : '';
  const user = userSelectors.userFromIdSelector(state, userId);

  if (user && decodedToken) {
    user.resetPasswordToken = props.token;
  }

  return {
    userId: userId,
    user: user,
    isTokenValid:
      !decodedToken ||
      !decodedToken.exp ||
      Date.now() < decodedToken.exp * 1000,
    isGettingUser: usersIsGettingSelector(state),
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getUser: userActions.fetchGetUsersActionCreator,
  onResetPassword: userActions.fetchResetUsersPasswordActionCreator,
};

const ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);

export default withRouter(
  (props: Props): ReactElement => (
    <ResetPasswordContainer token={props.match.params.token} />
  ),
);
