import { create, get, patch, post, remove } from '../base';
import { userTypes } from '../../../states/ducks/users';

/**
 * @return {Promise<User[]>}
 */
export const listUsers = async (): Promise<userTypes.User[]> =>
  await get(`/v1/users/list`);

/**
 * @param {string} id
 * @return {Promise<User>}
 */
export const getUser = async (
  id: userTypes.User['id'],
): Promise<userTypes.User> => await get(`/v1/users/get/${id}`);

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const addUser = async (
  user: Partial<userTypes.User>,
): Promise<userTypes.User['id']> => {
  const id = await create(`/v1/users/create`, user, {
    409: "L'adresse mail est déjà utilisée",
  });

  await post(`/v1/iam/addRoleForUser/intwi`, {
    userId: id,
    roleId: user.roleId,
  });

  return id;
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserInformation = async (
  user: userTypes.User,
): Promise<string> => {
  const { id, lastName, firstName } = user;

  return await patch(`/v1/users/updateInformation/${id}`, {
    firstName,
    lastName,
  });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserEmail = async (user: userTypes.User): Promise<string> => {
  const { id, email } = user;

  return await patch(`/v1/users/updateEmail/${id}`, {
    email,
  });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserPassword = async (
  user: userTypes.User,
): Promise<string> => {
  const { id, password } = user;

  return await patch(`/v1/users/changePassword/${id}`, {
    password,
  });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const resetUserPassword = async (
  user: userTypes.User,
): Promise<string> => {
  const { id, password, resetPasswordToken } = user;

  return await patch(`/v1/users/changePasswordWithToken/${id}`, {
    password,
    token: resetPasswordToken,
  });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserOrganizations = async (
  user: userTypes.User,
): Promise<string> => {
  const { id, organizationIds } = user;

  return await patch(`/v1/users/changeOrganizations/${id}`, {
    organizationIds,
  });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserVisibleOrganizations = async (
  user: userTypes.User,
): Promise<string> => {
  const { id, visibleOrganizationIds } = user;

  return await patch(`/v1/users/changeVisibleOrganizations/${id}`, {
    visibleOrganizationIds,
  });
};

/**
 * @param {User} user
 * @return {Promise<Response>}
 */
export const editUserAdminOrganizations = async (
  user: userTypes.User,
): Promise<void> => {
  const {
    id,
    locationIds,
    visibleLocationIds,
    organizationIds,
    visibleOrganizationIds,
  } = user;

  if (0 === visibleOrganizationIds.length) {
    return;
  }

  await post('/v1/users/token/generate', {
    locationIds: locationIds || [],
    visibleLocationIds: visibleLocationIds || [],
    organizationIds: organizationIds,
    visibleOrganizationIds: visibleOrganizationIds,
    userId: id,
  });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserLocations = async (
  user: userTypes.User,
): Promise<string> => {
  const { id, locationIds } = user;

  return await patch(`/v1/users/changeLocations/${id}`, { locationIds });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserVisibleLocations = async (
  user: userTypes.User,
): Promise<string> => {
  const { id, visibleLocationIds } = user;

  return await patch(`/v1/users/changeVisibleLocations/${id}`, {
    visibleLocationIds,
  });
};

/**
 * @param {User} user
 * @return {Promise<string>}
 */
export const editUserRole = async (user: userTypes.User): Promise<string[]> =>
  await Promise.all([
    patch(`/v1/users/changeRole/${user.id}`, { roleId: user.roleId }),
    patch(`/v1/iam/changeRoleForUser/intwi/${user.id}`, {
      roleId: user.roleId,
    }),
  ]);

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteUser = async (id: userTypes.User['id']): Promise<string[]> =>
  await Promise.all([
    remove(`/v1/users/delete/${id}`),
    remove(`/v1/iam/deleteRoleForUser/intwi/${id}`),
  ]);

/**
 * @param {string} email
 * @return {Promise<string>}
 */
export const sendPasswordResetLink = async (
  email: userTypes.User['email'],
): Promise<string> =>
  await get(`/v1/users/sendPasswordResetLink/${email}`, undefined, {
    404: 'Adresse mail incorrecte',
  });
