import { State } from './../../../../states/types';
import { connect } from 'react-redux';
import InfoOrganization from '../../../components/layout/modal/organization/InfoOrganization';
import {
  organizationActions,
  organizationTypes,
  organizationSelectors,
} from '../../../../states/ducks/organizations';
import { organizationsIsGettingSelector } from '../../../../states/ducks/organizations/selectors/base';

interface MapDispatchToProps {
  editOrganization: Function;
  getOrganization: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  editOrganization: organizationActions.fetchEditOrganizationsActionCreator,
  getOrganization: organizationActions.fetchGetOrganizationsActionCreator,
};

interface MapStateToProps {
  organization: organizationTypes.Organization | null;
  isFetching: boolean;
}

const mapStateToProps = (
  state: State,
  props: { organizationId: organizationTypes.Organization['id'] },
): MapStateToProps => ({
  organization: organizationSelectors.organizationFromIdSelector(
    state,
    props.organizationId,
  ),
  isFetching: organizationsIsGettingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoOrganization);
