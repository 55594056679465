import React, { FunctionComponent, ReactElement } from 'react';
import { Card as CardAnt } from 'antd';
import 'antd/lib/card/style';
import { CardProps } from 'antd/lib/card';
import './Card.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Card component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Card: FunctionComponent<CardProps> = ({
  className,
  ...props
}): ReactElement => {
  const classes = classnames('app-card', className);
  return <CardAnt {...props} className={classes} />;
};

Card.propTypes = {
  className: PropTypes.string,
};

export default Card;
