import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  Permission,
  PropTypesPermission,
} from '../../../../states/ducks/permissions/types';
import { permissionsBySubjectSelector } from '../../../../states/ducks/permissions/selectors';
import { PropTypesRole, Role } from '../../../../states/ducks/roles/types';
import {
  fetchEditRolesAddPermissionActionCreator,
  fetchEditRolesDeletePermissionActionCreator,
} from '../../../../states/ducks/roles/actions';
import { rolesFromOrganizationSelector } from '../../../../states/ducks/roles/selectors';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import {
  DataWithFilters,
  Empty,
  Filter,
  InputSearch,
  notification,
  Switch,
  Button,
  Text,
} from '../index';
import { getDescription, getSearchPattern } from './descriptions';
import './PermissionsTable.scss';

type PermissionRow = {
  key: string;
  description: string | { main: string; secondary: string };
  searchPattern: string;
  permission?: Permission;
  className?: string;
} & Record<
  string,
  boolean | string | { main: string; secondary: string } | Permission
>;

interface PermissionsTableProps extends TableProps<PermissionRow> {
  permissionsBySubject: { [key: string]: Permission[] };
  roles: Role[];
  addPermissionForRole: Function;
  removePermissionForRole: Function;
}

/**
 * @param {object} permissionsBySubject
 * @param {Role[]} roles
 * @param {Function} addPermissionForRole
 * @param {Function} removePermissionForRole
 * @param {TableProps<Permission>} props
 * @return {ReactElement}
 */
const PermissionsTable: FunctionComponent<PermissionsTableProps> = ({
  permissionsBySubject,
  roles,
  addPermissionForRole,
  removePermissionForRole,
  ...props
}): ReactElement => {
  if (0 === Object.keys(permissionsBySubject).length) {
    return <Empty description={'Aucun droit'} />;
  }

  if (0 === roles.length) {
    return <Empty description={'Aucun rôle'} />;
  }

  const descriptionColumn = {
    title: '',
    dataIndex: 'description',
    width: 300,
    onCell: (record: PermissionRow): object => ({
      record,
    }),
    fixed: 'left' as 'left',
  };

  const roleColumns = roles.map((role) => ({
    title: role.name,
    dataIndex: role.id,
    width: 150,
    role: role,
    onCell: (record: PermissionRow): object => ({
      record,
      role: role,
    }),
  }));

  let dataSource: PermissionRow[] = [];
  Object.entries(permissionsBySubject).forEach(([subject, permissions]) => {
    dataSource.push({
      key: subject,
      description: getDescription(subject, 'title'),
      searchPattern: permissions
        .map((permission) =>
          getSearchPattern(permission.subject, permission.action),
        )
        .join(' '),
      className: 'app-row-section',
    });

    dataSource = dataSource.concat(
      permissions.map((permission) => ({
        key: permission.id,
        description: getDescription(permission.subject, permission.action),
        searchPattern: getSearchPattern(permission.subject, permission.action),
        permission: permission,
        ...Object.assign(
          {},
          ...roleColumns.map((column) => ({
            [column.role.id]:
              'undefined' !==
              typeof column.role.permissions.find(
                (rolePermission) =>
                  permission.subject === rolePermission.subject &&
                  permission.action === rolePermission.action,
              ),
          })),
        ),
      })),
    );
  });

  const cell = ({
    record,
    role,
  }: {
    record: PermissionRow;
    role: Role;
  }): ReactElement => {
    if (!record) {
      return <></>;
    }

    if (!role) {
      return (
        <td className="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last">
          {'object' === typeof record.description ? (
            <>
              <div>{record.description.main}</div>
              <Text className="app-markdown app-blockquote">
                {record.description.secondary}
              </Text>
            </>
          ) : (
            record.description
          )}
        </td>
      );
    }

    if (!record.hasOwnProperty('permission')) {
      let areChecked = true;
      const uncheckedData: PermissionRow[] = [];
      const checkedData: PermissionRow[] = [];

      dataSource.forEach((element): void => {
        if (
          typeof element.permission !== 'undefined' &&
          element.permission.subject === record.key &&
          (!element[role.id] as boolean)
        ) {
          areChecked = false;
          uncheckedData.push(element);
        }

        if (
          typeof element.permission !== 'undefined' &&
          element.permission.subject === record.key &&
          (element[role.id] as boolean)
        ) {
          checkedData.push(element);
        }
      });

      if (!areChecked) {
        return (
          <td className={'app-column-section'}>
            <Button
              type="link"
              onClick={(): void => {
                const permissions: Permission[] = [];
                uncheckedData.map((element): Permission[] => {
                  if (typeof element.permission !== 'undefined') {
                    role.permissions.push(element.permission as Permission);
                    permissions.push(element.permission);
                    return permissions;
                  }
                  return [];
                });

                const promise = new Promise<Role>((resolve, reject): void =>
                  addPermissionForRole(role, permissions, {
                    resolve,
                    reject,
                  }),
                );

                promise
                  .then((): void => {
                    const message = `Les permissions ont bien été éditées`;
                    notification.success({ message });
                  })
                  .catch((message: string): void => {
                    notification.error({ message });
                  });
              }}
            >
              Tout activer
            </Button>
          </td>
        );
      }

      return (
        <td className={'app-column-section'}>
          <Button
            type="link"
            onClick={(): void => {
              const permissions: Permission[] = [];
              checkedData.map((element): Permission[] => {
                if (typeof element.permission !== 'undefined') {
                  permissions.push(element.permission);
                  const elPermission = element.permission;
                  role.permissions = role.permissions.filter(
                    (permission) =>
                      element.permission &&
                      (permission.subject !== elPermission.subject ||
                        permission.action !== elPermission.action),
                  );
                  return permissions;
                }
                return [];
              });

              const promise = new Promise<Role>((resolve, reject): void =>
                removePermissionForRole(role, permissions, {
                  resolve,
                  reject,
                }),
              );

              promise
                .then((): void => {
                  const message = `Les permissions ont bien été éditées`;
                  notification.success({ message });
                })
                .catch((message: string): void => {
                  notification.error({ message });
                });
            }}
          >
            Tout désactiver
          </Button>
        </td>
      );
    }

    return (
      <td className={'app-permission-switch'}>
        <Switch
          checked={record[role.id] as boolean}
          onChange={(checked): void => {
            let promise;
            if (checked) {
              role.permissions.push(record['permission'] as Permission);

              promise = new Promise<Role>((resolve, reject): void =>
                addPermissionForRole(role, record['permission'], {
                  resolve,
                  reject,
                }),
              );
            } else {
              role.permissions = role.permissions.filter(
                (permission) =>
                  record['permission'] &&
                  (permission.subject !== record['permission'].subject ||
                    permission.action !== record['permission'].action),
              );

              promise = new Promise<Role>((resolve, reject): void =>
                removePermissionForRole(role, record['permission'], {
                  resolve,
                  reject,
                }),
              );
            }

            promise
              .then((): void => {
                const message = `La permission a bien été éditée`;
                notification.success({ message });
              })
              .catch((message: string): void => {
                notification.error({ message });
              });
          }}
        />
      </td>
    );
  };

  return (
    <DataWithFilters
      dataSource={dataSource}
      displayComponent={
        <Table
          {...props}
          components={{
            body: {
              cell: cell,
            },
          }}
          columns={[descriptionColumn, ...roleColumns]}
          pagination={false}
          scroll={{ x: 'max-content' }}
          className="app-permissions-table"
          rowClassName={({ className }): string => className || ''}
        />
      }
    >
      <Filter
        component={<InputSearch />}
        filterName={'search'}
        filter={(value: string, record: { [key: string]: string }): boolean => {
          const columns = ['searchPattern'];
          const fields: string[] = [];

          columns.forEach((fieldName: string): void => {
            fields.push(String(record[fieldName] || ''));
          });

          for (const field of fields) {
            if (field.search(new RegExp(value, 'i')) >= 0) {
              return true;
            }
          }

          return false;
        }}
        onChangeFormatValue={(e: ChangeEvent<HTMLInputElement>): string[] => [
          e.currentTarget.value,
        ]}
      />
    </DataWithFilters>
  );
};

PermissionsTable.propTypes = {
  permissionsBySubject: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypesPermission.isRequired).isRequired,
  ).isRequired,
  roles: PropTypes.arrayOf(PropTypesRole.isRequired).isRequired,
  addPermissionForRole: PropTypes.func.isRequired,
  removePermissionForRole: PropTypes.func.isRequired,
};

interface MapStateToProps {
  permissionsBySubject: { [key: string]: Permission[] };
  roles: Role[];
}

interface MapDispatchToProps {
  addPermissionForRole: Function;
  removePermissionForRole: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  permissionsBySubject: permissionsBySubjectSelector(state),
  roles: rolesFromOrganizationSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  addPermissionForRole: fetchEditRolesAddPermissionActionCreator,
  removePermissionForRole: fetchEditRolesDeletePermissionActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsTable);
