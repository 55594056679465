import React, { FunctionComponent, ReactElement } from 'react';
import {
  PropTypesTreeModel,
  TreeModel,
} from '../../../../states/ducks/common/types';
import PropTypes from 'prop-types';
import { Tooltip } from '..';
import { Breadcrumb } from 'antd';
import './TreePath.scss';
import { Link } from 'react-router-dom';
interface TreePathProps {
  objectId: string;
  trees: TreeModel[];
  pathBaseUrl?: string;
}

/**
 * @param {objectId[]} objectId
 * @param {TreeModel[]} trees
 * @return {ReactElement}
 */
const TreePath: FunctionComponent<TreePathProps> = ({
  objectId,
  trees,
  pathBaseUrl,
  ...props
}): ReactElement => {
  const pathNames: string[] = [];
  const getPathItems = (models, id): TreeModel[] | null => {
    if (typeof models != 'undefined') {
      for (let i = 0; i < models.length; i++) {
        if (models[i].id === id) {
          pathNames.push(models[i].name);
          return [models[i]];
        }
        const path = getPathItems(models[i].children, id);
        if (path != null) {
          pathNames.push(models[i].name);
          path.push(models[i]);
          return path;
        }
      }
    }
    return null;
  };

  const pathItems = getPathItems(trees, objectId);

  const breadcrumbItems = (): ReactElement[] | null => {
    if (pathItems !== null && pathItems.length > 1) {
      return pathItems?.reverse().map((item) => {
        if (pathBaseUrl) {
          return (
            <Breadcrumb.Item key={item.id}>
              <Link to={pathBaseUrl + item.id}>{item.name}</Link>
            </Breadcrumb.Item>
          );
        }
        return <Breadcrumb.Item key={item.id}>{item.name}</Breadcrumb.Item>;
      });
    }
    return null;
  };

  return (
    <Tooltip title={pathNames.reverse().join(' / ')} placement="topLeft">
      <Breadcrumb className="path-container">{breadcrumbItems()}</Breadcrumb>
    </Tooltip>
  );
};

TreePath.propTypes = {
  objectId: PropTypes.string.isRequired,
  trees: PropTypes.arrayOf(PropTypesTreeModel.isRequired).isRequired,
  pathBaseUrl: PropTypes.string,
};

export default TreePath;
