import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchListByDependencyRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  subscribe,
  listSubscriptionsByUserId,
  unsubscribe,
} from '../../../services/api';
import { Subscription } from './types';
import { getActionType } from '../common/types';

/**
 * @param {FetchAddRequested} action
 */
export function* subscribeSaga(
  action: FetchAddRequested<types.Subscription>,
): SagaIterator {
  try {
    const id: types.Subscription['id'] = yield call(subscribe, action.payload);

    const subscription = {
      ...action.payload,
      id,
    } as types.Subscription;

    yield put(actions.fetchSubscribeSuccessActionCreator(subscription));

    action.meta.resolve(subscription);
  } catch (err) {
    yield put(actions.fetchSubscribeErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchListByDependencyRequested} action
 */
export function* listSubscriptionsByUserIdSaga(
  action: FetchListByDependencyRequested<Subscription>,
): SagaIterator {
  try {
    const subscriptions: types.Subscription[] = yield call(
      listSubscriptionsByUserId,
      action.payload,
    );

    yield put(
      actions.fetchListSubscriptionsSuccessActionCreator(subscriptions),
    );
  } catch (err) {
    yield put(actions.fetchListSubscriptionsErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* unsubscribeSaga(
  action: FetchDeleteRequested<types.Subscription>,
): SagaIterator {
  try {
    yield call(unsubscribe, action.payload);

    yield put(actions.fetchUnsubscribeSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchUnsubscribeErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* subscriptionsSagas(): SagaIterator {
  yield takeEvery(
    getActionType('subscriptions', 'SUBSCRIBE_REQUESTED'),
    subscribeSaga,
  );
  yield takeEvery(
    getActionType('subscriptions', 'LIST_REQUESTED'),
    listSubscriptionsByUserIdSaga,
  );
  yield takeEvery(
    getActionType('subscriptions', 'UNSUBSCRIBE_REQUESTED'),
    unsubscribeSaga,
  );
}
