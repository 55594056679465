import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteRequest,
  editRequest,
  editRequestCreator,
  editRequestManager,
  editRequestOrganization,
  getRequest,
  listRequests,
  openRequest,
  closeRequest,
  deleteFile,
  addFile,
  removeFileRequest,
  addFilesRequest,
} from '../../../services/api';
import { getCurrentUserId } from '../../../services/authenticate';
import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
  FetchListRequested,
} from '../common/actions';
import { getActionType } from '../common/types';
import * as actions from './actions';
import { Request } from './types';

/**
 * @param {FetchAddRequested} action
 */
export function* openRequestSaga(
  action: FetchAddRequested<Request>,
): SagaIterator {
  try {
    const id: Request['id'] = yield call(openRequest, action.payload);

    const request = {
      ...action.payload,
      id,
    } as Request;

    yield put(actions.fetchOpenRequestsSuccessActionCreator(request));

    action.meta.resolve(request);
  } catch (err) {
    yield put(actions.fetchOpenRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchListRequested} action
 */
export function* fetchRequestSaga(action?: FetchListRequested): SagaIterator {
  try {
    const response = action
      ? yield call(listRequests, action.payload)
      : yield call(listRequests);

    if (action && action.payload.pagination) {
      yield put(
        actions.fetchPaginatedListRequestsSuccessActionCreator(response),
      );
    } else {
      yield put(actions.fetchListRequestsSuccessActionCreator(response));
    }
  } catch (err) {
    yield put(actions.fetchListRequestsErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getRequestSaga(
  action: FetchGetRequested<Request>,
): SagaIterator {
  try {
    const request: Request = yield call(getRequest, action.payload);

    yield put(actions.fetchGetRequestsSuccessActionCreator(request));

    action.meta.resolve(request);
  } catch (err) {
    yield put(actions.fetchGetRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<Request>} action
 */
export function* editRequestCreatorSaga(
  action: FetchEditRequested<Request>,
): SagaIterator {
  try {
    yield call(editRequestCreator, action.payload);

    yield put(actions.fetchEditRequestsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<Request>} action
 */
export function* editRequestSaga(
  action: FetchEditRequested<Request>,
): SagaIterator {
  try {
    yield call(editRequest, action.payload);

    yield put(actions.fetchEditRequestsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<Request>} action
 */
export function* editRequestManagerSaga(
  action: FetchEditRequested<Request>,
): SagaIterator {
  try {
    yield call(editRequestManager, action.payload);

    yield put(actions.fetchEditRequestsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<Request>} action
 */
export function* editRequestOrganizationSaga(
  action: FetchEditRequested<Request>,
): SagaIterator {
  try {
    yield call(editRequestOrganization, action.payload);

    yield put(actions.fetchEditRequestsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* closeRequestSaga(
  action: FetchEditRequested<Request>,
): SagaIterator {
  try {
    yield call(closeRequest, action.payload);

    yield put(actions.fetchEditRequestsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addFileRequestSaga(
  action: actions.FetchAddFileRequested,
): SagaIterator {
  try {
    const request = action.payload.request;
    const file = action.payload.file;

    const fileId = yield call(addFile, {
      file: file,
      organizationId: request.organizationId || '',
      userId: getCurrentUserId() || '',
      objectId: request.id || '',
      objectType: 'request',
    });

    yield call(addFilesRequest, request, [fileId]);

    if (!request.fileIds) {
      request.fileIds = [];
    }
    request.fileIds.push(fileId);

    yield put(
      actions.fetchEditRequestsSuccessActionCreator(action.payload.request),
    );

    action.meta.resolve(action.payload.file);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(action.payload.file);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addGEDFileRequestSaga(
  action: actions.FetchAddGEDFileRequested,
): SagaIterator {
  try {
    const request = action.payload.request;
    const fileIds = action.payload.fileIds;

    yield call(addFilesRequest, request, fileIds);

    if (!request.fileIds) {
      request.fileIds = [];
    }

    request.fileIds = request.fileIds.concat(fileIds);

    yield put(
      actions.fetchEditRequestsSuccessActionCreator(action.payload.request),
    );

    action.meta.resolve(action.payload.fileIds);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(action.payload.fileIds);
  }
}

/**
 * @param {FetchDeleteFileRequested} action
 */
export function* deleteFileRequestSaga(
  action: actions.FetchDeleteFileRequested,
): SagaIterator {
  try {
    const fileId = action.payload.fileId;
    const request = action.payload.request;

    yield call(deleteFile, fileId);

    yield call(removeFileRequest, request, fileId);

    if (!request.fileIds) {
      request.fileIds = [];
    }
    request.fileIds = request.fileIds.filter((id) => id !== fileId);

    yield put(
      actions.fetchEditRequestsSuccessActionCreator(action.payload.request),
    );

    action.meta.resolve(action.payload.request);
  } catch (err) {
    yield put(actions.fetchEditRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteRequestSaga(
  action: FetchDeleteRequested<Request>,
): SagaIterator {
  try {
    if (!Array.isArray(action.payload)) {
      yield call(deleteRequest, action.payload);
      yield put(
        actions.fetchDeleteRequestsSuccessActionCreator(action.payload),
      );
    }

    if (Array.isArray(action.payload)) {
      yield all(action.payload.map((id) => call(deleteRequest, id)));
      yield all(
        action.payload.map((id) =>
          put(actions.fetchDeleteRequestsSuccessActionCreator(id)),
        ),
      );
    }

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteRequestsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* requestsSagas(): SagaIterator {
  yield takeEvery(getActionType('requests', 'OPEN_REQUESTED'), openRequestSaga);
  yield takeEvery(
    getActionType('requests', 'LIST_REQUESTED'),
    fetchRequestSaga,
  );
  yield takeEvery(getActionType('requests', 'GET_REQUESTED'), getRequestSaga);
  yield takeEvery(getActionType('requests', 'EDIT_REQUESTED'), editRequestSaga);
  yield takeEvery(
    getActionType('requests', 'EDIT_CREATOR_REQUESTED'),
    editRequestCreatorSaga,
  );
  yield takeEvery(
    getActionType('requests', 'EDIT_MANAGER_REQUESTED'),
    editRequestManagerSaga,
  );
  yield takeEvery(
    getActionType('requests', 'EDIT_ORGANIZATIONS_REQUESTED'),
    editRequestOrganizationSaga,
  );
  yield takeEvery(
    getActionType('requests', 'CLOSE_REQUESTED'),
    closeRequestSaga,
  );
  yield takeEvery(
    getActionType('requests', 'ADD_FILE_REQUESTED'),
    addFileRequestSaga,
  );
  yield takeEvery(
    getActionType('requests', 'ADD_GED_FILE_REQUESTED'),
    addGEDFileRequestSaga,
  );
  yield takeEvery(
    getActionType('requests', 'DELETE_FILE_REQUESTED'),
    deleteFileRequestSaga,
  );
  yield takeEvery(
    getActionType('requests', 'DELETE_REQUESTED'),
    deleteRequestSaga,
  );
}
