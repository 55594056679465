import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Button,
  Skeleton,
  Empty,
  Input,
  EditableField,
} from '../../../base';
import { organizationTypes } from '../../../../../states/ducks/organizations';
import { useGet } from '../helper';

interface FormInfoOrgaProps {
  hideDrawer: Function;
  visible: boolean;
  organization: organizationTypes.Organization | null;
  getOrganization: Function;
  organizationId: organizationTypes.Organization['id'] | null;
  isFetching: boolean;
  editOrganization: Function;
}

const InfoOrganization: FunctionComponent<FormInfoOrgaProps> = ({
  hideDrawer,
  visible,
  organization,
  getOrganization,
  organizationId,
  isFetching,
  editOrganization,
}): ReactElement => {
  const onClose = (): void => hideDrawer();

  useGet<organizationTypes.Organization>({
    id: organizationId,
    isFetching,
    visible,
    apiGet: getOrganization,
    object: organization,
    description: "la récupération de l'organisation a échoué",
  });

  const designationField = organization ? (
    <EditableField
      object={organization}
      label="Nom"
      content={organization.name}
      editedFieldsOptions={{
        field: 'name',
        label: 'Nom',
        content: <Input />,
        required: true,
        initialValue: organization.name,
        colSize: 12,
      }}
      onSubmit={editOrganization}
      successMessage="La désignation a bien été modifiée"
      restrictedToPermissions={
        !organization.parentId
          ? {
              action: 'admin',
              subject: 'admin',
            }
          : undefined
      }
    />
  ) : (
    ''
  );

  return (
    <Drawer
      title="Détails de l'organisation"
      visible={visible}
      onClose={onClose}
      footer={
        <Button onClick={onClose} type="primary" size="large">
          Fermer
        </Button>
      }
    >
      <Skeleton loading={isFetching}>
        {organization !== null ? <>{designationField}</> : <Empty />}
      </Skeleton>
    </Drawer>
  );
};

InfoOrganization.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideDrawer: PropTypes.func.isRequired,
  organization: organizationTypes.PropTypesOrganization,
  organizationId: PropTypes.string,
  getOrganization: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  editOrganization: PropTypes.func.isRequired,
};

export default InfoOrganization;
