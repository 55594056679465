import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import { Pagination } from '../../../services/search/search';
import { flatiItems, rehydrate } from '../../helper';
import { modelReducer } from '../common/reducers';
import { getActionType } from '../common/types';
import { File, FileState } from './types';

/**
 * @param {File[]} state
 * @param {AnyAction} action
 * @return {File[]}
 */
export const fileReducer: Reducer<File[]> = (
  state: File[] = [],
  action: AnyAction,
): File[] => {
  if (action.type === getActionType('files', 'LIST_PAGINATED_SUCCEEDED')) {
    return rehydrate<File>(state, flatiItems<File>(action.payload.result));
  }

  return modelReducer('files', state, action) as File[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('files', 'ADD_FAILED'):
    case getActionType('files', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('files', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('files', 'LIST_FAILED'):
    case getActionType('files', 'LIST_SUCCEEDED'):
    case getActionType('files', 'LIST_PAGINATED_SUCCEEDED'):
      return false;
    case getActionType('files', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {Pagination} state
 * @param {Action} action
 * @return {boolean}
 */
export const paginationReducer: Reducer<Pagination | null> = (
  state = null,
  action: AnyAction,
): Pagination | null => {
  switch (action.type) {
    case getActionType('files', 'LIST_PAGINATED_SUCCEEDED'):
      return action.payload.pagination;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('files', 'GET_FAILED'):
    case getActionType('files', 'GET_SUCCEEDED'):
      return false;
    case getActionType('files', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('files', 'EDIT_FAILED'):
    case getActionType('files', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('files', 'EDIT_EXPIRE_AT_REQUESTED'):
    case getActionType('files', 'EDIT_NAME_REQUESTED'):
    case getActionType('files', 'EDIT_NOTE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('files', 'DELETE_FAILED'):
    case getActionType('files', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('files', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<FileState> = combineReducers({
  files: fileReducer,
  pagination: paginationReducer,
  status: combineReducers({
    adding: addingReducer,
    listing: listingReducer,
    getting: gettingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
  }),
});

export default reducers;
