import { createSelector } from 'reselect';
import { File, FileState } from './types';
import { State } from '../../types';
import { currentUserSelector } from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {FileState}
 */
const fileStateSelector: (state: State) => FileState = (state) =>
  state.filesState;

/**
 * @param {State} state
 * @return {File[]}
 */
export const filesSelector = createSelector(
  fileStateSelector,
  (applicationState) => applicationState.files,
);

/**
 * @param {State} state
 * @return {Pagination}
 */
export const filesPaginationSelector = createSelector(
  fileStateSelector,
  (applicationState) => applicationState.pagination,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {File|null}
 */
export const fileFromIdSelector = createSelector(
  filesSelector,
  (_: State, id: File['id']) => id,
  (files, id) => files.find((file) => file.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {File[]}
 */
export const filesFromMultipleIdsSelector = createSelector(
  filesSelector,
  (_: State, ids: File['id'][]) => ids,
  (files, ids) => files.filter((file) => ids.includes(file.id)),
);

/**
 * @param {State} state
 * @return {Incident[]}
 */
export const visibleFilesSelector = createSelector(
  filesSelector,
  (state) => currentUserSelector(state),
  (files, currentUser) =>
    files.filter((file) =>
      currentUser.visibleOrganizationIds.includes(file.organizationId),
    ),
);
