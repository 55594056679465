import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import GetIncidentType from '../../../components/layout/modal/incidenttype/GetIncidentType';
import {
  incidentTypeActions,
  incidentTypeSelectors,
  incidentTypeTypes,
} from '../../../../states/ducks/incidenttypes';

interface MapStateToProps {
  incidentType: incidentTypeTypes.IncidentType | null;
  isFetching: boolean;
}

interface MapDispatchToProps {
  getIncidentType: Function;
  editIncidentTypeDesignation: Function;
}

const mapStateToProps = (
  state: State,
  props: { incidentTypeId: incidentTypeTypes.IncidentType['id'] },
): MapStateToProps => ({
  incidentType: incidentTypeSelectors.incidentTypeFromIdSelector(
    state,
    props.incidentTypeId,
  ),
  isFetching: incidentTypeSelectors.incidentTypesIsGettingSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  getIncidentType: incidentTypeActions.fetchGetIncidentTypesActionCreator,
  editIncidentTypeDesignation:
    incidentTypeActions.fetchEditIncidentTypesDesignationActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(GetIncidentType);
