import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { listPermissions } from '../../../services/api';
import { getActionType } from '../common/types';
import * as actions from './actions';
import { Permission } from './types';

/**
 * @yields {SagaIterator}
 */
export function* fetchPermissionSaga(): SagaIterator {
  try {
    const permissions: Permission[] = yield call(listPermissions);

    yield put(actions.fetchListPermissionsSuccessActionCreator(permissions));
  } catch (err) {
    yield put(actions.fetchListPermissionsErrorActionCreator(err.message));
  }
}

/**
 * @yields {SagaIterator}
 */
export function* permissionsSagas(): SagaIterator {
  yield takeEvery(
    getActionType('permissions', 'LIST_REQUESTED'),
    fetchPermissionSaga,
  );
}
