import { post } from './base';

/**
 * @param {string} email
 * @param {string} password
 * @return {Promise<Response>}
 */
export const getToken = async (
  email: string,
  password: string,
): Promise<Response> =>
  await post(
    '/v1/users/login',
    {
      email,
      password,
    },
    {
      404: 'Identifiant ou mot de passe incorrect',
    },
  );

/**
 * @param {string} token
 * @param {string} secret
 * @return {Promise<Response>}
 */
export const refreshToken = async (
  token: string,
  secret: string,
): Promise<Response> =>
  await post('/v1/users/token/refresh', {
    refreshToken: token,
    secret,
  });
