import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextArea,
  Drawer,
  FormItem,
  Input,
  Row,
  Col,
  notification,
  Button,
  PlacesAutocompleteInput,
  OrganizationsTreeSelect,
  LocationTypesSelect,
} from '../../../base';
import { locationTypeTypes } from '../../../../../states/ducks/locationtypes';
import { Form } from 'antd';
import {
  LocationType,
  PropTypesLocationType,
} from '../../../../../states/ducks/locationtypes/types';
import {
  Location,
  PropTypesLocation,
} from '../../../../../states/ducks/locations/types';

interface FormAddLocationProps {
  hideModal: Function;
  visible: boolean;
  addLocation: Function;
  parentLocation?: Location | null;
  locationTypes: locationTypeTypes.LocationType[];
  parentLocationStructure?: Location | null;
  mainLocationType: LocationType | null;
}

const FormAddLocation: FunctionComponent<FormAddLocationProps> = ({
  hideModal,
  visible,
  addLocation,
  parentLocation,
  locationTypes,
  parentLocationStructure,
  mainLocationType,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const mainLocationTypeId = mainLocationType ? mainLocationType.id : '';

  const handleFinish = (values: Partial<Location>): void => {
    setLoading(true);

    const location = {
      ...values,
    };

    if (parentLocation && parentLocationStructure === null) {
      location.parentId = parentLocation.id;
      location.organizationId = parentLocation.organizationId;
    } else if (parentLocation && parentLocationStructure) {
      location.parentId = parentLocationStructure.id;
      location.organizationId = parentLocationStructure.organizationId;
    } else {
      location.locationTypeId = mainLocationTypeId;
    }

    const promise = new Promise<Location>((resolve, reject): void =>
      addLocation(location, { resolve, reject }),
    );

    promise
      .then((location: Location): void => {
        form.resetFields();
        hideModal();
        const message = `${location.name} a bien été ajoutée`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      })
      .finally((): void => setLoading(false));
  };

  const handleCancel = (): void => {
    form.resetFields();
    hideModal();
  };

  const nameInput = (
    <FormItem
      label="Nom"
      name="name"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner le nom',
        },
      ]}
    >
      <Input placeholder="Saisissez un nom" />
    </FormItem>
  );

  const codeInput = (
    <FormItem
      label="Code"
      name="code"
      rules={[
        {
          required: true,
          type: 'string',
          message: 'Veuillez renseigner le code',
        },
      ]}
    >
      <Input autoComplete="off" placeholder="Saisissez un code" />
    </FormItem>
  );

  const selectOrgaOrTypeInput =
    typeof parentLocation !== 'undefined' ? (
      <FormItem
        label="Type de lieu"
        name="locationTypeId"
        rules={[
          {
            required: true,
            message: 'Veuillez sélectionner un type de lieu',
          },
        ]}
      >
        <LocationTypesSelect />
      </FormItem>
    ) : (
      <FormItem
        label="Organisation"
        name="organizationId"
        rules={[
          {
            required: true,
            message: 'Veuillez sélectionner une organisation',
          },
        ]}
      >
        <OrganizationsTreeSelect placeholder="Sélectionnez une organisation" />
      </FormItem>
    );

  const address1Input = (
    <FormItem
      label="Adresse"
      name={['addressComponents', 'formattedAddress']}
      rules={[
        {
          type: 'string',
        },
      ]}
    >
      <PlacesAutocompleteInput placeholder="Saisissez une adresse (n°, voie, cp, ville...)" />
    </FormItem>
  );

  const address2Input = (
    <FormItem
      name={['addressComponents', 'additionalAddress']}
      rules={[
        {
          type: 'string',
        },
      ]}
    >
      <Input placeholder="Saisissez un complément d'adresse" />
    </FormItem>
  );

  const remarkInput = (
    <FormItem
      label="Commentaire"
      name="remark"
      rules={[
        {
          type: 'string',
        },
      ]}
    >
      <TextArea placeholder="Saisissez un commentaire" />
    </FormItem>
  );

  const title =
    typeof parentLocation !== 'undefined' || !mainLocationType
      ? `Ajout d’un lieu`
      : `Ajout d'un(e) ${mainLocationType.name.toLowerCase()}`;

  return (
    <Drawer
      title={title}
      onClose={handleCancel}
      visible={visible}
      {...props}
      footer={
        <>
          <Button htmlType="button" size="large" onClick={handleCancel}>
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            onClick={form.submit}
          >
            Ajouter
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={12}>{nameInput}</Col>
          <Col span={12}>{codeInput}</Col>
          <Col span={12}>{selectOrgaOrTypeInput}</Col>
          <Col span={24}>
            {address1Input}
            {address2Input}
          </Col>
          <Col span={24}>{remarkInput}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};

FormAddLocation.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  addLocation: PropTypes.func.isRequired,
  parentLocation: PropTypesLocation,
  locationTypes: PropTypes.arrayOf(
    locationTypeTypes.PropTypesLocationType.isRequired,
  ).isRequired,
  parentLocationStructure: PropTypesLocation,
  mainLocationType: PropTypesLocationType,
};

export default FormAddLocation;
