import { createSelector } from 'reselect';
import { State } from '../../types';
import { Message, MessageState } from './types';

/**
 * @param {State} state
 * @return {MessageState}
 */
const messageStateSelector: (state: State) => MessageState = (state) =>
  state.messagesState;

/**
 * @param {State} state
 * @return {Message[]}
 */
export const messagesSelector = createSelector(
  messageStateSelector,
  (applicationState) => applicationState.messages,
);

/**
 * @param {State} state
 * @return {Pagination}
 */
export const messagesPaginationSelector = createSelector(
  messageStateSelector,
  (applicationState) => applicationState.pagination,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const messagesIsAddingSelector = createSelector(
  messageStateSelector,
  (applicationState) => applicationState.status.adding,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const messagesIsDeletingSelector = createSelector(
  messageStateSelector,
  (applicationState) => applicationState.status.deleting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const messagesIsEditingSelector = createSelector(
  messageStateSelector,
  (applicationState) => applicationState.status.editing,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const messagesIsGettingSelector = createSelector(
  messageStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const messagesIsListingSelector = createSelector(
  messageStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Message|null}
 */
export const messageFromIdSelector = createSelector(
  messagesSelector,
  (_: State, id: Message['id'] | null) => id,
  (messages, id) => messages.find((message) => message.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Message[]}
 */
export const messagesFromMultipleIdsSelector = createSelector(
  messagesSelector,
  (_: State, ids: Message['id'][]) => ids,
  (messages, ids) => messages.filter((message) => ids.includes(message.id)),
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Message[]|null}
 */
export const messagesFromIncidentIdSelector = createSelector(
  messagesSelector,
  (_: State, id: Message['id'] | null) => id,
  (messages, id) =>
    messages.filter(
      (message) => message.objectType === 'incident' && message.objectId === id,
    ),
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Message[]|null}
 */
export const messagesFromRequestIdSelector = createSelector(
  messagesSelector,
  (_: State, id: Message['id'] | null) => id,
  (messages, id) =>
    messages.filter(
      (message) => message.objectType === 'request' && message.objectId === id,
    ),
);
