import { createSelector } from 'reselect';
import { State } from '../../../types';
import { Organization } from '../types';
import { organizationsSelector } from './base';

/**
 * @param {State} state
 * @param {string} id
 * @return {Organization|null}
 */
export const organizationFromIdSelector = createSelector(
  organizationsSelector,
  (_: State, id: Organization['id']) => id,
  (organizations, id) =>
    organizations.find((organization) => organization.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Organization[]}
 */
export const organizationFromMultipleIdsSelector = createSelector(
  organizationsSelector,
  (_: State, ids: Organization['id'][]) => ids,
  (organizations, ids) =>
    organizations.filter((organization) => ids.includes(organization.id)),
);

/**
 * @param {string} id
 * @return {Organization[]}
 */
export const mainOrganizationsSelector = createSelector(
  organizationsSelector,
  (organizations) =>
    organizations.filter(
      (organization) => typeof organization.parentId === 'undefined',
    ),
);

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {Organization[]}
 */
export const organizationChildrenSelector = createSelector(
  organizationsSelector,
  (_: State, organization: Organization) => organization,
  (stateOrganizations, organization) =>
    stateOrganizations.filter(
      (stateOrganization) => stateOrganization.parentId === organization.id,
    ),
);

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {Organization[]}
 */
export const organizationDescendantsSelector = (
  state: State,
  organization: Organization,
): Organization[] => {
  const children = organizationChildrenSelector(state, organization);

  if (0 === children.length) {
    return [];
  }

  return children.concat(
    children
      .map((child) => organizationDescendantsSelector(state, child))
      .reduce((a, b) => a.concat(b)),
  );
};

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {Organization|null}
 */
export const organizationParentSelector = createSelector(
  organizationsSelector,
  (_: State, organization: Organization) => organization,
  (stateOrganizations, organization) => {
    if (!organization.parentId) {
      return null;
    }

    return (
      stateOrganizations.find(
        (stateOrganization) => stateOrganization.id === organization.parentId,
      ) || null
    );
  },
);

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {Organization[]}
 */
export const organizationAncestorsSelector = (
  state: State,
  organization: Organization,
): Organization[] => {
  const parent = organizationParentSelector(state, organization);

  if (null === parent) {
    return [];
  }

  return [parent].concat(organizationAncestorsSelector(state, parent));
};

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {string}
 */
export const organizationPathSelector = (
  state: State,
  organization: Organization,
): string =>
  organizationAncestorsSelector(state, organization)
    .reverse()
    .concat(organization)
    .map((ancestor) => ancestor.name)
    .join(' > ');

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {Organization}
 */
export const organizationRootSelector = (
  state: State,
  organization: Organization,
): Organization => {
  const ancestors = organizationAncestorsSelector(state, organization);

  if (0 === ancestors.length) {
    return organization;
  }

  const root = ancestors.find((ancestor) => !ancestor.parentId);

  if ('undefined' === typeof root) {
    throw Error(`"${organization.name}" has no root.`);
  }

  return root;
};

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {Organization}
 */
export const organizationsTreeSelector = (
  state: State,
  organization: Organization,
): Organization => ({
  ...organization,
  children: organizationChildrenSelector(state, organization).map((child) =>
    organizationsTreeSelector(state, child),
  ),
});

/**
 * @param {State} state
 * @return {Organization[]}
 */
export const organizationsTreesSelector = (state: State): Organization[] =>
  mainOrganizationsSelector(state).map((organization) =>
    organizationsTreeSelector(state, organization),
  );

/**
 * @param {State} state
 * @param {string} id
 * @return {object}
 */
export const descendantsIdByOrganizationId = (
  state: State,
  id: Organization['id'] | null = null,
): Record<string, string[]> => {
  const organization =
    id !== null ? organizationFromIdSelector(state, id) : null;

  const organizations = organization
    ? organizationsTreeSelector(state, organization).children || []
    : organizationsTreesSelector(state);

  const descendantsIdByOrganizationId: Record<string, string[]> = {};

  organizations.forEach((organization) => {
    descendantsIdByOrganizationId[organization.id] =
      getDescendantIds(organization);
  });

  return descendantsIdByOrganizationId;
};

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {string[]}
 */
export const organizationAndItsParentsIdsSelector = (
  state: State,
  organization: Organization,
): Organization['id'][] =>
  [organization.id].concat(getAncestorIds(state, organization));

/**
 * @param {State} state
 * @return {object}
 */
export const organizationsAndTheirParentsIdsSelector = (
  state: State,
): Record<string, string[]> => {
  const organizations = organizationsSelector(state);

  const result: Record<string, string[]> = {};

  organizations.forEach((organization): void => {
    result[organization.id] = organizationAndItsParentsIdsSelector(
      state,
      organization,
    );
  });

  return result;
};

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {string[]}
 */
export const organizationAndItsDescendantsIdsSelector = (
  state: State,
  organization: Organization,
): Organization['id'][] =>
  [organization.id].concat(
    getDescendantIds(organizationsTreeSelector(state, organization)),
  );

/**
 * @param {State} state
 * @return {object}
 */
export const organizationsAndTheirDescendantsIdsSelector = (
  state: State,
): Record<string, string[]> => {
  const organizations = organizationsSelector(state);

  const result: Record<string, string[]> = {};

  organizations.forEach((organization): void => {
    result[organization.id] = organizationAndItsDescendantsIdsSelector(
      state,
      organization,
    );
  });

  return result;
};

/**
 * @param {Organization} organization
 * @return {string[]}
 */
export const getDescendantIds = (organization: Organization): string[] => {
  if (!organization.children) {
    return [];
  }

  return organization.children
    .map((child) => child.id)
    .concat(
      organization.children
        .map((child) => getDescendantIds(child))
        .reduce((a, b) => a.concat(b), []),
    );
};

/**
 * @param {State} state
 * @param {Organization} organization
 * @return {string[]}
 */
const getAncestorIds = (state: State, organization: Organization): string[] => {
  const parent = organizationParentSelector(state, organization);

  if (null === parent) {
    return [];
  }

  return [parent.id].concat(getAncestorIds(state, parent));
};
