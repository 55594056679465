import { connect } from 'react-redux';
import { State } from '../../../states/types';
import {
  contractorActions,
  contractorTypes,
  contractorSelectors,
} from '../../../states/ducks/contractors';
import {
  incidentTypeSelectors,
  incidentTypeTypes,
} from '../../../states/ducks/incidenttypes';
import { organizationSelectors } from '../../../states/ducks/organizations';
import Contractors from '../../components/layout/contractors/Contractors';

interface MapStateToProps {
  contractors: contractorTypes.Contractor[];
  incidentTypes: incidentTypeTypes.IncidentType[];
  loading: boolean;
  descendantsIdByOrganizationId: Record<string, string[]>;
}

interface MapDispatchToProps {
  loadContractors: Function;
  deleteContractor: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  contractors: contractorSelectors.visibleContractorsSelector(state),
  incidentTypes:
    incidentTypeSelectors.incidentTypesFromOrganizationSelector(state),
  loading: contractorSelectors.contractorsIsListingSelector(state),
  descendantsIdByOrganizationId:
    organizationSelectors.descendantsIdByOrganizationId(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  loadContractors: contractorActions.fetchListContractorsActionCreator,
  deleteContractor: contractorActions.fetchDeleteContractorsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractors);
