import React, {
  ReactElement,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { Text } from '../index';
import './Homepage.scss';

/**
 * @param {ReactNode} children
 *
 * @return {ReactElement}
 */
const Homepage: FunctionComponent<PropsWithChildren<ReactNode>> = (
  children,
): ReactElement => (
  <div className="container">
    {/* left block / hidden > 768px */}
    <div className="image-container left-block"></div>

    {/* right block */}
    <div className="homepage-container">
      {/* empty div useful with justify-content: space-between
                to center utility block and set the footer at the bottom */}
      <div></div>
      <div className="utility">
        <div className="homepage-header">
          <img
            className="logo"
            src="/icons/logo-full-light.svg"
            alt="logo Intwi"
          />
          <Text className="baseline">
            Administrez l&apos;ensemble
            <br />
            de vos actifs immobiliers
          </Text>
        </div>

        <div className="slider">
          <div className="slider-container">
            <div className="item active">
              <div className="content">{children.children}</div>
            </div>
          </div>
        </div>
      </div>

      {/* visible < 768px */}
      <div className="image-container right-block"></div>

      <div className="homepage-footer">
        <img
          className="logo"
          src="/icons/logo-twipi-light.svg"
          alt="logo Twipi group"
        />
      </div>
    </div>
  </div>
);
Homepage.defaultProps = {
  children: '',
};

Homepage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default Homepage;
