import React, { ReactElement } from 'react';
import 'antd/lib/list/style';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

const ItemRender = (
  current: number,
  type: string,
  originalElement: ReactElement,
): ReactElement => {
  if (type === 'prev') {
    return (
      <div className="ant-pagination-item-link">
        <CaretLeftOutlined />
      </div>
    );
  }
  if (type === 'next') {
    return (
      <div className="ant-pagination-item-link">
        <CaretRightOutlined />
      </div>
    );
  }
  return <div>{originalElement}</div>;
};

export default ItemRender;
