import React, { FunctionComponent, ReactElement } from 'react';
import 'antd/lib/tag/style';
import Tag from '../tag/Tag';
import './RoleTag.scss';
import { roleSelectors, roleTypes } from '../../../../states/ducks/roles';
import { TagProps } from 'antd/lib/tag';
import { CrownOutlined } from '@ant-design/icons';
import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

interface RoleTagProps extends Omit<TagProps, 'role'> {
  roleId: roleTypes.Role['id'];
  role: roleTypes.Role | null;
}

/**
 * @param {Role} userRole
 * @param {TagProps} tagProps
 * @return {ReactElement}
 */
const RoleTag: FunctionComponent<RoleTagProps> = ({
  roleId,
  role,
  ...tagProps
}): ReactElement => {
  if (!role) {
    return <></>;
  }

  tagProps.className = [tagProps.className || '', 'role', role.style].join(' ');

  return (
    <Tag
      icon={role.style === 'gold' ? <CrownOutlined /> : null}
      color={role.style}
      {...tagProps}
    >
      {role.name}
    </Tag>
  );
};

RoleTag.propTypes = {
  roleId: PropTypes.string.isRequired,
  role: roleTypes.PropTypesRole,
};

interface MapStateToProps {
  role: roleTypes.Role | null;
}

const mapStateToProps = (
  state: State,
  props: { roleId: roleTypes.Role['id'] },
): MapStateToProps => ({
  role: roleSelectors.roleFromIdSelector(state, props.roleId),
});

export default connect(mapStateToProps)(RoleTag);
