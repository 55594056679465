import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Message,
  PropTypesMessage,
} from '../../../../states/ducks/messages/types';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import { State } from '../../../../states/types';
import { chronologicalSortBy } from '../../../../utils/SortUtils';
import { Empty, Message as MessageComponent } from '../index';
import './Messages.scss';

interface MessagesProps {
  deleteConfirmationMessage?: string;
  deleteSuccessMessage?: string;
  editSuccessMessage?: string;
  emptyMessage?: React.ReactNode;
  loadProfiles: Function;
  messages: Message[];
  objectType?: string;
  onRef?: Function;
  profiles?: profileTypes.Profile[];
}

/**
 * @param {string} deleteConfirmationMessage
 * @param {string} deleteSuccessMessage
 * @param {string} editSuccessMessage
 * @param {Node} emptyMessage
 * @param {Function} loadProfiles
 * @param {Message[]} messages
 * @param {string} objectType
 * @param {Function} onRef
 * @param {Profile[]} profiles
 *
 * @return {ReactElement}
 */
const Messages: FunctionComponent<MessagesProps> = ({
  deleteConfirmationMessage,
  deleteSuccessMessage,
  editSuccessMessage,
  emptyMessage,
  loadProfiles,
  messages,
  objectType,
  onRef,
  profiles,
}): ReactElement => {
  useEffect((): void => {
    if (loadProfiles && !profiles) {
      loadProfiles();
    }
  }, [loadProfiles, profiles]);

  messages = messages.sort((a, b): number =>
    chronologicalSortBy(a.createdAt, b.createdAt),
  );

  return (
    <div
      className={'app-message-list'}
      ref={(el): void => {
        if (onRef) {
          onRef(el);
        }
      }}
    >
      {messages.length > 0 ? (
        messages.map(
          (message: Message): ReactElement => (
            <MessageComponent
              key={message.id}
              message={message}
              deleteConfirmationMessage={deleteConfirmationMessage}
              deleteSuccessMessage={deleteSuccessMessage}
              editSuccessMessage={editSuccessMessage}
              objectType={objectType}
            />
          ),
        )
      ) : (
        <Empty description={emptyMessage} />
      )}
    </div>
  );
};

Messages.propTypes = {
  deleteConfirmationMessage: PropTypes.string,
  deleteSuccessMessage: PropTypes.string,
  editSuccessMessage: PropTypes.string,
  emptyMessage: PropTypes.node,
  loadProfiles: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypesMessage.isRequired).isRequired,
  objectType: PropTypes.string,
  onRef: PropTypes.func,
  profiles: PropTypes.arrayOf(profileTypes.PropTypesProfile.isRequired),
};

Messages.defaultProps = {
  emptyMessage: 'Aucun message',
};

interface MapStateToProps {
  profiles: profileTypes.Profile[];
}

interface MapDispatchToProps {
  loadProfiles: Function;
}

const mapStateToProps = (
  state: State,
  props: { messages: Message[] },
): MapStateToProps => ({
  profiles: profileSelectors.profileFromMultipleUserIdsSelector(
    state,
    props.messages.map((message: Message): Message['id'] => message.id),
  ),
});

const mapDispatchToProps: MapDispatchToProps = {
  loadProfiles: profileActions.fetchListProfilesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
