import React, { ReactElement, forwardRef } from 'react';
import { Input as InputAnt } from 'antd';
import 'antd/lib/input/style';
import { InputProps } from 'antd/lib/input';
import './Input.scss';
import classnames from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Component = (props: InputProps, ref: any): ReactElement => {
  const classes = classnames('app-input', props.className);
  return <InputAnt {...props} ref={ref} className={classes} />;
};

Component.displayName = 'Input';

/**
 * Input component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Input = forwardRef<HTMLInputElement, InputProps>(Component);

export default Input;
