import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import * as actions from './actions';
import * as userActions from '../users/actions';
import * as types from './types';

import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  listProfiles,
  addProfile,
  editProfileInformation,
  editProfileEmail,
  editProfileOrganizations,
  setProfileAvatar,
  deleteProfile,
  getProfile,
  getProfileByUserId,
  deleteUser,
  addFile,
  deleteFile,
} from '../../../services/api';
import { getActionType } from '../common/types';

/**
 * @yields {SagaIterator}
 */
export function* fetchProfileSaga(): SagaIterator {
  try {
    const profiles: types.Profile[] = yield call(listProfiles);

    yield put(actions.fetchListProfilesSuccessActionCreator(profiles));
  } catch (err) {
    yield put(actions.fetchListProfilesErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchAddRequested} action
 */
export function* addProfileSaga(
  action: FetchAddRequested<types.Profile>,
): SagaIterator {
  try {
    const id: types.Profile['id'] = yield call(addProfile, action.payload);

    const profile = {
      ...action.payload,
      id,
    } as types.Profile;

    yield put(actions.fetchAddProfilesSuccessActionCreator(profile));

    action.meta.resolve(profile);
  } catch (err) {
    yield put(actions.fetchAddProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editProfileInformationSaga(
  action: FetchEditRequested<types.Profile>,
): SagaIterator {
  try {
    yield call(editProfileInformation, action.payload);

    yield put(actions.fetchEditProfilesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editProfileEmailSaga(
  action: FetchEditRequested<types.Profile>,
): SagaIterator {
  try {
    yield call(editProfileEmail, action.payload);

    yield put(actions.fetchEditProfilesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editProfileOrganizationsSaga(
  action: FetchEditRequested<types.Profile>,
): SagaIterator {
  try {
    yield call(editProfileOrganizations, action.payload);

    yield put(actions.fetchEditProfilesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editProfileAdminOrganizationsSaga(
  action: FetchEditRequested<types.Profile>,
): SagaIterator {
  try {
    yield put(actions.fetchEditProfilesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchAddAvatarRequested} action
 */
export function* addProfileAvatarSaga(
  action: actions.FetchAddAvatarRequested,
): SagaIterator {
  try {
    const profile = action.payload.profile as types.Profile;

    profile.avatarId = yield call(addFile, {
      file: action.payload.file,
      objectId: profile.id,
      objectType: 'profile',
      organizationId:
        profile.organizationIds.length > 0 ? profile.organizationIds[0] : '',
      userId: profile.userId,
    });

    yield call(setProfileAvatar, profile);

    yield put(actions.fetchEditProfilesSuccessActionCreator(profile));

    action.meta.resolve(action.payload.file);
  } catch (err) {
    yield put(actions.fetchEditProfilesErrorActionCreator(err.message));

    action.meta.reject(action.payload.file);
  }
}

/**
 * @param {FetchDeleteAvatarRequested} action
 */
export function* deleteProfileAvatarSaga(
  action: actions.FetchDeleteAvatarRequested,
): SagaIterator {
  try {
    const fileId = action.payload.fileId;
    const profile = action.payload.profile;

    yield call(deleteFile, fileId);

    profile.avatarId = undefined;

    yield call(setProfileAvatar, profile);

    yield put(
      actions.fetchEditProfilesSuccessActionCreator(action.payload.profile),
    );

    action.meta.resolve(action.payload.profile);
  } catch (err) {
    yield put(actions.fetchEditProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteProfileSaga(
  action: FetchDeleteRequested<types.Profile>,
): SagaIterator {
  try {
    const profile = yield call(getProfile, action.payload);

    yield call(deleteProfile, action.payload);

    yield put(actions.fetchDeleteProfilesSuccessActionCreator(action.payload));

    yield call(deleteUser, profile.userId);

    yield put(userActions.fetchDeleteUsersSuccessActionCreator(profile.userId));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getProfileSaga(
  action: FetchGetRequested<types.Profile>,
): SagaIterator {
  try {
    const profile: types.Profile = yield call(getProfile, action.payload);

    yield put(actions.fetchGetProfilesSuccessActionCreator(profile));

    action.meta.resolve(profile);
  } catch (err) {
    yield put(actions.fetchGetProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getProfileByUserIdSaga(
  action: FetchGetRequested<types.Profile>,
): SagaIterator {
  try {
    const profile: types.Profile = yield call(
      getProfileByUserId,
      action.payload,
    );

    yield put(actions.fetchGetProfilesSuccessActionCreator(profile));

    action.meta.resolve(profile);
  } catch (err) {
    yield put(actions.fetchGetProfilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* profilesSagas(): SagaIterator {
  yield takeEvery(
    getActionType('profiles', 'LIST_REQUESTED'),
    fetchProfileSaga,
  );
  yield takeEvery(getActionType('profiles', 'ADD_REQUESTED'), addProfileSaga);
  yield takeEvery(
    getActionType('profiles', 'EDIT_INFORMATION_REQUESTED'),
    editProfileInformationSaga,
  );
  yield takeEvery(
    getActionType('profiles', 'EDIT_EMAIL_REQUESTED'),
    editProfileEmailSaga,
  );
  yield takeEvery(
    getActionType('profiles', 'EDIT_ORGANIZATIONS_REQUESTED'),
    editProfileOrganizationsSaga,
  );
  yield takeEvery(
    getActionType('profiles', 'EDIT_ADMIN_ORGANIZATIONS_REQUESTED'),
    editProfileAdminOrganizationsSaga,
  );
  yield takeEvery(
    getActionType('profiles', 'ADD_AVATAR_REQUESTED'),
    addProfileAvatarSaga,
  );
  yield takeEvery(
    getActionType('profiles', 'DELETE_AVATAR_REQUESTED'),
    deleteProfileAvatarSaga,
  );
  yield takeEvery(
    getActionType('profiles', 'DELETE_REQUESTED'),
    deleteProfileSaga,
  );
  yield takeEvery(getActionType('profiles', 'GET_REQUESTED'), getProfileSaga);
  yield takeEvery(
    getActionType('profiles', 'GET_BY_USERID_REQUESTED'),
    getProfileByUserIdSaga,
  );
}
