import { create, get, paginatedGet, patch, remove } from '../base';
import { Request } from '../../../states/ducks/requests/types';
import {
  getSearchUrlParameters,
  PaginatedResult,
  Search,
} from '../../search/search';

/**
 * @param {Request} request
 * @return {Promise<string>}
 */
export const openRequest = async (
  request: Partial<Request>,
): Promise<Request['id']> => await create(`/v1/requests/open`, request);

/**
 * @param {Search} search
 * @return {Promise<Request[]>}
 */
export const listRequests = async (
  search?: Search,
): Promise<PaginatedResult | Request[]> => {
  const path = `/v1/requests/list${getSearchUrlParameters(
    search || { filters: [] },
  )}`;

  return search && search.pagination
    ? await paginatedGet(path)
    : await get(path);
};

/**
 * @param {string} id
 * @return {Promise<Request>}
 */
export const getRequest = async (id: Request['id']): Promise<Request> =>
  await get(`/v1/requests/get/${id}`);

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteRequest = async (id: Request['id']): Promise<string> =>
  await remove(`/v1/requests/delete/${id}`);

/**
 * @param {Request} request
 * @return {Promise<string>}
 */
export const editRequest = async (
  request: Partial<Request>,
): Promise<string> => {
  const { id, body, object, openedAt } = request;

  return await patch(`/v1/requests/edit/${id}`, {
    body,
    object,
    openedAt,
  });
};

/**
 * @param {Request} request
 * @return {Promise<string>}
 */
export const editRequestCreator = async (
  request: Partial<Request>,
): Promise<string> => {
  const { id, creatorId } = request;

  return await patch(`/v1/requests/editCreator/${id}`, {
    creatorId,
  });
};

/**
 * @param {Request} request
 * @return {Promise<string>}
 */
export const editRequestManager = async (
  request: Partial<Request>,
): Promise<string> => {
  const { id, managerId } = request;

  return await patch(`/v1/requests/assign/${id}`, {
    managerId,
  });
};

/**
 * @param {Request} request
 * @return {Promise<string>}
 */
export const editRequestOrganization = async (
  request: Partial<Request>,
): Promise<string> => {
  const { id, organizationId } = request;

  return await patch(`/v1/requests/changeOrganization/${id}`, {
    organizationId,
  });
};

/**
 * @param {Request} request
 * @return {Promise<string>}
 */
export const closeRequest = async (
  request: Partial<Request>,
): Promise<string> => {
  const { id } = request;

  return await patch(`/v1/requests/close/${id}`);
};

/**
 * @param {Request} request
 * @param {string[]} fileIds
 * @return {Promise<string>}
 */
export const addFilesRequest = async (
  request: Partial<Request>,
  fileIds: string[],
): Promise<string> => {
  return await patch(`/v1/requests/addFiles/${request.id}`, {
    fileIds,
  });
};

/**
 * @param {Request} request
 * @param {string} fileId
 * @return {Promise<string>}
 */
export const removeFileRequest = async (
  request: Partial<Request>,
  fileId: string,
): Promise<string> => {
  const { id } = request;

  return await patch(`/v1/requests/removeFile/${id}`, {
    fileId,
  });
};
