import { create, get, patch, remove } from '../base';
import * as types from '../../../states/ducks/profiles/types';
import { userTypes } from '../../../states/ducks/users';
import { profileTypes } from '../../../states/ducks/profiles';

/**
 * @return {Promise<Profile[]>}
 */
export const listProfiles = async (): Promise<profileTypes.Profile[]> =>
  await get(`/v1/profiles/list?limit=unlimited`);

/**
 * @param {string} id
 * @return {Promise<Profile>}
 */
export const getProfile = async (
  id: profileTypes.Profile['id'],
): Promise<profileTypes.Profile> => await get(`/v1/profiles/get/${id}`);

/**
 * @param {string} id
 * @return {Promise<Profile>}
 */
export const getProfileByUserId = async (
  id: userTypes.User['id'],
): Promise<profileTypes.Profile> => await get(`/v1/profiles/getByUserId/${id}`);

/**
 * @param {Profile} profile
 * @return {Promise<string>}
 */
export const addProfile = async (
  profile: Partial<profileTypes.Profile>,
): Promise<profileTypes.Profile['id']> =>
  await create(`/v1/profiles/create`, profile);

/**
 * @param {Profile} profile
 * @return {Promise<string>}
 */
export const editProfileInformation = async (
  profile: profileTypes.Profile,
): Promise<string> => {
  const { id, lastName, firstName, phoneNumber, address, address2 } = profile;

  return await patch(`/v1/profiles/updateInformation/${id}`, {
    firstName,
    lastName,
    phoneNumber,
    address,
    address2,
  });
};

/**
 * @param {Profile} profile
 * @return {Promise<string>}
 */
export const editProfileEmail = async (
  profile: profileTypes.Profile,
): Promise<string> => {
  const { id, email } = profile;

  return await patch(`/v1/profiles/updateEmail/${id}`, {
    email,
  });
};

/**
 * @param {Profile} profile
 * @return {Promise<string>}
 */
export const editProfileOrganizations = async (
  profile: profileTypes.Profile,
): Promise<string> => {
  const { id, organizationIds } = profile;

  return await patch(`/v1/profiles/changeOrganizations/${id}`, {
    organizationIds,
  });
};

/**
 * @param {Profile} profile
 * @return {Promise<string>}
 */
export const setProfileAvatar = async (
  profile: profileTypes.Profile,
): Promise<string> => {
  const { id, avatarId } = profile;

  return await patch(`/v1/profiles/changeAvatar/${id}`, {
    avatarId,
  });
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteProfile = async (id: types.Profile['id']): Promise<string> =>
  await remove(`/v1/profiles/delete/${id}`);
