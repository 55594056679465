import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import { userTypes } from '../../../../states/ducks/users';
import PropTypes from 'prop-types';
import { PopoverProps } from 'antd/lib/popover';
import { Popover, UserCard } from '../index';
import 'antd/lib/popover/style';
import './Popover.scss';

interface ProfilePopoverProps extends PopoverProps {
  userId: userTypes.User['id'];
  profile: profileTypes.Profile | null;
  getProfile: Function;
}

/**
 * @param {string} userId
 * @param {Profile} profile
 * @param {Function} getProfile
 * @param {ProfilePopoverProps} props
 *
 * @return {ReactElement}
 */
const ProfilePopover: FunctionComponent<ProfilePopoverProps> = ({
  userId,
  profile,
  getProfile,
  ...popoverProps
}): ReactElement => {
  useEffect((): void => {
    if (getProfile && userId && !profile) {
      getProfile(userId);
    }
  }, [getProfile, profile, userId]);

  if (!profile) {
    return <>{'Anonyme'}</>;
  }

  return (
    <Popover
      overlayClassName="app-popover-user-card"
      content={<UserCard profileId={profile.id} />}
      {...popoverProps}
    >
      <span>
        {profile.firstName} {profile.lastName}
      </span>
    </Popover>
  );
};

ProfilePopover.propTypes = {
  userId: PropTypes.string.isRequired,
  profile: profileTypes.PropTypesProfile,
  getProfile: PropTypes.func.isRequired,
};

interface MapStateToProps {
  profile: profileTypes.Profile | null;
}

interface MapDispatchToProps {
  getProfile: Function;
}

const mapStateToProps = (
  state: State,
  props: { userId: userTypes.User['id'] },
): MapStateToProps => ({
  profile: profileSelectors.profileFromUserIdSelector(state, props.userId),
});

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePopover);
