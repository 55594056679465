import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {Profile[]} [state=[]] profiles
 * @param {AnyAction} action
 * @return {Profile[]}
 */
export const profileReducer: Reducer<types.Profile[]> = (
  state: types.Profile[] = [],
  action: AnyAction,
): types.Profile[] => {
  return modelReducer('profiles', state, action) as types.Profile[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('profiles', 'LIST_FAILED'):
    case getActionType('profiles', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('profiles', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('profiles', 'ADD_FAILED'):
    case getActionType('profiles', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('profiles', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('profiles', 'EDIT_FAILED'):
    case getActionType('profiles', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('profiles', 'EDIT_INFORMATION_REQUESTED'):
      return true;
    case getActionType('profiles', 'EDIT_EMAIL_REQUESTED'):
      return true;
    case getActionType('profiles', 'EDIT_ORGANIZATIONS_REQUESTED'):
      return true;
    case getActionType('profiles', 'EDIT_ADMIN_ORGANIZATIONS_REQUESTED'):
      return true;
    case getActionType('profiles', 'ADD_AVATAR_REQUESTED'):
      return true;
    case getActionType('profiles', 'DELETE_AVATAR_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('profiles', 'DELETE_FAILED'):
    case getActionType('profiles', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('profiles', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('profiles', 'GET_FAILED'):
    case getActionType('profiles', 'GET_SUCCEEDED'):
      return false;
    case getActionType('profiles', 'GET_REQUESTED'):
    case getActionType('profiles', 'GET_BY_USERID_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.ProfileState> = combineReducers({
  profiles: profileReducer,
  status: combineReducers({
    listing: listingReducer,
    adding: addingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
    getting: gettingReducer,
  }),
});

export default reducers;
