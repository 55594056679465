import { createBrowserHistory } from 'history';
import React, { FunctionComponent, ReactElement } from 'react';
import { Redirect } from 'react-router';
import { Switch, Router, RouteProps } from 'react-router-dom';
// TODO Group import with a pages folder
import Files from './components/layout/file/Files';
import Dashboard from './containers/layout/Dashboard';
import Incidents from './containers/layout/Incidents';
import IncidentInfo from './containers/layout/IncidentInfo';
import Requests from './containers/layout/Requests';
import RequestInfo from './containers/layout/RequestInfo';
import Locations from './containers/layout/Locations';
import LocationInfo from './containers/layout/LocationInfo';
import Users from './containers/layout/Users';
import UserInfo from './containers/layout/UserInfo';
import Nomatch from './components/layout/nomatch/Nomach';
import Administration from './components/layout/administration/Administration';
import Organization from './containers/layout/Organization';
import Location from './containers/layout/Location';
import LocationTypeInfo from './containers/layout/LocationTypeInfo';
import Incident from './containers/layout/Incident';
import User from './containers/layout/User';
import Contractors from './containers/layout/Contractors';
import ContractorInfo from './containers/layout/ContractorInfo';
import PrivacyPolicy from './components/layout/help/PrivacyPolicy';
import Login from './components/layout/login/Login';
import ResetAsking from './components/layout/login/ResetAsking';
import ResetPassword from './components/layout/login/ResetPassword';
import DocAPI from './components/layout/help/DocAPI';
import AuthenticatedRoute from './components/base/route/AuthenticatedRoute';
import HelpRoute from './components/base/route/HelpRoute';
import UnauthenticatedRoute from './components/base/route/UnauthenticatedRoute';
import './App.scss';

const defaultRoute = '/dashboard';

const unauthenticatedPathComponentMap = {
  '/login': Login,
  '/resetAsking': ResetAsking,
  '/resetPassword/:token': ResetPassword,
};

const authenticatedPathComponentMap = {
  '/administration': Administration,
  '/dashboard': Dashboard,
  '/administration/incident': Incident,
  '/administration/location': Location,
  '/administration/location/:id': LocationTypeInfo,
  '/administration/organization': Organization,
  '/administration/user': User,
  '/contractors': Contractors,
  '/contractors/:id': ContractorInfo,
  '/files': Files,
  '/incidents': Incidents,
  '/incidents/:id': IncidentInfo,
  '/locations': Locations,
  '/locations/:id': LocationInfo,
  '/locations/type/:id': Locations,
  '/locations/type/:id/:id': LocationInfo,
  '/myaccount': UserInfo,
  '/requests': Requests,
  '/requests/:id': RequestInfo,
  '/users': Users,
};

const helpPathComponentMap = {
  '/help/doc-api': DocAPI,
  '/help/privacy-policy': PrivacyPolicy,
};

const getRoutes = (
  pathComponentMap: Record<string, RouteProps['component']>,
  RouteComponent: FunctionComponent<RouteProps>,
): JSX.Element[] =>
  Object.entries(pathComponentMap).map(([path, component]) => (
    <RouteComponent exact path={path} component={component} key={path} />
  ));

const App: FunctionComponent = (): ReactElement => (
  <Router history={createBrowserHistory()}>
    <Switch>
      <Redirect exact path="/" to={defaultRoute} />
      {getRoutes(unauthenticatedPathComponentMap, UnauthenticatedRoute)}
      {getRoutes(authenticatedPathComponentMap, AuthenticatedRoute)}
      {getRoutes(helpPathComponentMap, HelpRoute)}
      <AuthenticatedRoute component={Nomatch} />
    </Switch>
  </Router>
);

export default App;
