import { State } from './types';
import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers,
  Store,
  Middleware,
} from 'redux';
import * as reducers from './ducks';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootSaga from './sagas';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function | undefined;
  }
}

declare const process: {
  env: Record<string, {}>;
};

/**
 * @param {State} initialState
 * @return {Store}
 */
export default function configureStore(initialState?: State): Store {
  const rootReducer = combineReducers<State>(reducers);
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

  const middlewares: Middleware[] = [thunk, sagaMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(
      createLogger({
        collapsed: true,
      }),
    );
  }

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store: Store = createStore(
    rootReducer,
    initialState,
    enhancer,
  ) as Store<State>;

  sagaMiddleware.run(rootSaga);

  return store;
}
