import { ColumnProps } from 'antd/lib/table';
import PropTypes from 'prop-types';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Pagination } from '../../../../services/search/search';
import {
  fetchDeleteFilesActionCreator,
  fetchListFilesActionCreator,
} from '../../../../states/ducks/files/actions';
import {
  filesPaginationSelector,
  visibleFilesSelector,
} from '../../../../states/ducks/files/selectors';
import { File, PropTypesFile } from '../../../../states/ducks/files/types';
import { State } from '../../../../states/types';
import {
  DateTooltip,
  FilterableTable,
  IncidentProfileName,
  InputSearch,
  OrganizationsTreeSelect,
  PageHeader,
  PopoverActions,
  ProfilesSelect,
  RangePicker,
  Select,
  SelectOption,
} from '../../base';
import {
  FilterableSearch,
  initialSearch,
} from '../../base/table/FilterableTable';
import InfoFile from '../../base/upload/InfoFile';
import { organizationSelectors } from '../../../../states/ducks/organizations';
import moment, { Moment } from 'moment';
import { Checkbox } from 'antd';
import { fetchListProfilesActionCreator } from '../../../../states/ducks/profiles/actions';
import FileTypeUtils from '../../base/file/FileTypeUtils';

interface FilesProps {
  deleteFile: Function;
  descendantsIdByOrganizationId: Record<string, string[]>;
  loadFiles: Function;
  loadProfiles: Function;
  files: File[];
  total: Pagination['total'];
}

interface TableFile {
  action: object;
  id: File['id'];
  key: File['id'];
  name: File['name'];
  uploadedAt: File['uploadedAt'];
  userId: File['userId'];
}

/**
 * @param {Function} deleteFile
 * @param {Record<string, string[]>} descendantsIdByOrganizationId
 * @param {Function} loadFiles
 * @param {Function} loadProfiles
 * @param {File[]} files
 * @param {number} total
 *
 * @return {ReactElement}
 */
const Files: FunctionComponent<FilesProps> = ({
  deleteFile,
  descendantsIdByOrganizationId,
  loadFiles,
  loadProfiles,
  files,
  total,
}): ReactElement => {
  const initialFilesSearch: FilterableSearch = {
    ...initialSearch,
    filters: [
      {
        key: 'expireAt',
        field: 'expireAt',
        operator: '>=',
        value: moment().format(),
        includeNull: true,
      },
    ],
  };

  const [search, setSearch] = useState(initialFilesSearch);
  const [fileIdDisplayed, setFileIdDisplayed] = useState(
    undefined as string | undefined,
  );

  useEffect((): void => {
    if (loadFiles) {
      loadFiles(search);
    }
  }, [loadFiles, search]);

  useEffect((): void => {
    loadProfiles();
  }, [loadProfiles]);

  const profileName = (userId: string): ReactElement => (
    <IncidentProfileName userId={userId} />
  );

  const dateToolTip = (date: string): ReactElement => (
    <DateTooltip date={date} />
  );

  const columns: ColumnProps<TableFile>[] = [
    {
      key: 'icon',
      dataIndex: 'icon',
      className: 'icon',
      width: '50px',
    },
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'userId',
      title: 'Ajouté par',
      dataIndex: 'userId',
      render: profileName,
    },
    {
      key: 'uploadedAt',
      title: 'Ajouté le',
      dataIndex: 'uploadedAt',
      render: dateToolTip,
      sorter: true,
    },
    {
      key: 'operation',
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableFile): ReactElement => {
        return (
          <PopoverActions
            objectId={record.id}
            onDelete={deleteFile}
            deleteConfirmMessage="Supprimer le fichier ?"
            deleteDoneMessage="Le fichier a bien été supprimé"
            size="small"
          />
        );
      },
      width: '5%',
    },
  ];

  const datas = files.map((file) => {
    return {
      id: file.id,
      key: file.id,
      icon: FileTypeUtils.getIcon(file.fileExtension),
      name: file.name,
      uploadedAt: file.uploadedAt,
      userId: file.userId,
      action: {
        id: file.id,
      },
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: TableFile): any => ({
    onClick: (): void => setFileIdDisplayed(record.id),
  });

  return (
    <>
      <PageHeader title="Documents" />
      <FilterableTable
        initialSearch={initialFilesSearch}
        onDelete={deleteFile}
        deleteConfirmationMessage="Supprimer les documents sélectionnés ?"
        deleteSuccessMessage="Les documents ont bien été supprimés"
        filters={[
          {
            display: (
              <InputSearch size="large" placeholder="Rechercher par nom" />
            ),
            field: 'name',
            key: 'search',
            operator: 'LIKE',
            onChange: (e: ChangeEvent<HTMLInputElement>): string[] => [
              e.currentTarget.value,
            ],
            size: 12,
          },
          {
            display: (
              <Select size="large" mode={'multiple'} placeholder={'Type'}>
                <SelectOption key={'pdf'} value={'application/pdf'}>
                  PDF
                </SelectOption>
                <SelectOption key={'image'} value={'image/'}>
                  Image
                </SelectOption>
                <SelectOption key={'csv'} value={'text/csv'}>
                  CSV
                </SelectOption>
              </Select>
            ),
            field: 'fileMimeType',
            key: 'type',
            operator: 'LIKE',
          },
          {
            display: (
              <OrganizationsTreeSelect
                size="large"
                placeholder="Organisation"
              />
            ),
            field: 'organizationId',
            key: 'organization',
            operator: 'IN',
            onChange: (value: string): string[] => [
              value,
              ...(descendantsIdByOrganizationId[value] || []),
            ],
          },
        ]}
        extra={[
          {
            display: <ProfilesSelect isFilter placeholder={'Ajouté par'} />,
            field: 'userId',
            key: 'user',
            operator: 'IN',
          },
          {
            display: (
              <RangePicker allowClear={false} placeholder={['Début', 'Fin']} />
            ),
            field: 'uploadedAt',
            key: 'uploadedAt',
            operator: 'BETWEEN',
            onChange: (values: Moment[]): string[] =>
              values.map((value) => value.format()),
            onUpdate: (values: string[]): Moment[] =>
              values.map((value) => moment(value)),
          },
          {
            display: (
              <Checkbox
                checked={
                  search.filters.find((filter) => filter.key === 'expireAt') ===
                  undefined
                }
              >
                Afficher les documents expirés
              </Checkbox>
            ),
            field: 'expireAt',
            key: 'expireAt',
            operator: '>=',
            includeNull: true,
            onChange: (e: ChangeEvent<HTMLInputElement>): string =>
              e.target.checked ? '' : moment().format(),
            size: 24,
          },
        ]}
        onSearchChange={setSearch}
        search={search}
        tableProps={{
          columns: columns,
          dataSource: datas,
          onRow: onRow,
          pagination: {
            total: total,
          },
        }}
      />
      <InfoFile
        visible={fileIdDisplayed !== undefined}
        hideDrawer={(): void => setFileIdDisplayed(undefined)}
        fileId={fileIdDisplayed}
      />
    </>
  );
};

Files.propTypes = {
  deleteFile: PropTypes.func.isRequired,
  descendantsIdByOrganizationId: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  loadFiles: PropTypes.func.isRequired,
  loadProfiles: PropTypes.func.isRequired,
  // @ts-ignore
  files: PropTypes.arrayOf(PropTypesFile.isRequired).isRequired,
  total: PropTypes.number.isRequired,
};

interface MapStateToProps {
  descendantsIdByOrganizationId: Record<string, string[]>;
  files: File[];
  total: Pagination['total'];
}

interface MapDispatchToProps {
  deleteFile: Function;
  loadFiles: Function;
  loadProfiles: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const files = visibleFilesSelector(state);

  const total = filesPaginationSelector(state)?.total || 0;

  const descendantsIdByOrganizationId =
    organizationSelectors.descendantsIdByOrganizationId(state);

  return {
    descendantsIdByOrganizationId,
    files,
    total,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  deleteFile: fetchDeleteFilesActionCreator,
  loadFiles: fetchListFilesActionCreator,
  loadProfiles: fetchListProfilesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);
