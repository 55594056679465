import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { incidentTypes } from '../incidents';
import { getActionType } from '../common/types';

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchListByDependencyRequested}
 */
export const fetchListInterventionsByIncidentIdActionCreator = createAction(
  getActionType('interventions', 'LIST_REQUESTED'),
  (id: incidentTypes.Incident['id']): incidentTypes.Incident['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Intervention[]} interventions
 * @return {FetchListSucceeded}
 */
export const fetchListInterventionsSuccessActionCreator = createAction(
  getActionType('interventions', 'LIST_SUCCEEDED'),
  (interventions: types.Intervention[]): types.Intervention[] => interventions,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListInterventionsErrorActionCreator = createAction(
  getActionType('interventions', 'LIST_FAILED'),
  (message: string): string => message,
);
