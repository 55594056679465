import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Row,
  Button,
  FormItem,
  Input,
  Col,
  Switch,
  notification,
  OrganizationsTreeSelect,
  IncidentTypesTransfer,
} from '../../../base';
import { contractorTypes } from '../../../../../states/ducks/contractors';
import { Form } from 'antd';
import { Contractor } from '../../../../../states/ducks/contractors/types';

interface FormAddLocationProps {
  hideModal: Function;
  visible: boolean;
  addContractor: Function;
}

const FormAddLocation: FunctionComponent<FormAddLocationProps> = ({
  hideModal,
  visible,
  addContractor,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [useTwimm, setUseTwimm] = useState(false);

  const handleFinish = (values: Partial<Contractor>): void => {
    setLoading(true);

    const contractor = {
      ...values,
    };

    const promise = new Promise<contractorTypes.Contractor>(
      (resolve, reject): void => addContractor(contractor, { resolve, reject }),
    );

    promise
      .then((contractor: contractorTypes.Contractor): void => {
        form.resetFields();
        hideModal();
        const message = `${contractor.name} a bien été ajoutée`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      })
      .finally((): void => setLoading(false));
  };

  const handleCancel = (): void => {
    form.resetFields();
    hideModal();
  };

  const nameInput = (
    <FormItem
      label="Nom"
      name="name"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner le nom',
        },
      ]}
    >
      <Input placeholder="Saisissez un nom" />
    </FormItem>
  );

  const organizationInput = (
    <FormItem
      label="Organisation"
      name="organizationId"
      rules={[
        {
          required: true,
          message: 'Veuillez sélectionner une organisation',
        },
      ]}
    >
      <OrganizationsTreeSelect placeholder="Sélectionnez une organisation" />
    </FormItem>
  );

  const useTwimmInput = (
    <FormItem
      label="Utilise Twimm"
      name="useTwimm"
      initialValue={false}
      rules={[
        {
          type: 'boolean',
          required: true,
        },
      ]}
    >
      <Switch
        onChange={(isChecked: boolean): void => {
          form.resetFields(['instance', 'sendInterventionRequest']);
          setUseTwimm(isChecked);
        }}
      />
    </FormItem>
  );

  const instanceInput = (
    <FormItem
      label="Instance"
      name="instance"
      rules={[
        {
          type: 'string',
          message: "Veuillez renseigner l'instance",
        },
      ]}
    >
      <Input placeholder="Saisissez un nom d'instance" />
    </FormItem>
  );

  const sendInterventionRequestInput = (
    <FormItem
      label="
                Créer des demandes d'interventions chez le prestataire
                à l'acceptation d'un incident
            "
      name="sendInterventionRequest"
      valuePropName="checked"
      rules={[
        {
          type: 'boolean',
        },
      ]}
    >
      <Switch />
    </FormItem>
  );

  const incidentTypesInput = (
    <FormItem
      label="Type d'incident pris en charge"
      name="incidentTypeIds"
      initialValue={[]}
      rules={[
        {
          type: 'array',
        },
      ]}
    >
      <IncidentTypesTransfer
        onChange={(values: string[]): void =>
          form.setFieldsValue({
            incidentTypeIds: values,
          })
        }
      />
    </FormItem>
  );

  const emailInput = (
    <FormItem
      label="Email"
      name="email"
      rules={[
        {
          type: 'email',
          required: true,

          message: 'Veuillez renseigner un mail valide',
        },
      ]}
    >
      <Input placeholder="Saisissez un mail" />
    </FormItem>
  );

  return (
    <Drawer
      title={'Nouveau prestataire'}
      onClose={handleCancel}
      visible={visible}
      footer={
        <>
          <Button htmlType="button" onClick={handleCancel}>
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={form.submit}
          >
            Ajouter
          </Button>
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={24}>{nameInput}</Col>
        </Row>
        <Row>
          <Col span={24}>{organizationInput}</Col>
        </Row>
        <Row>
          <Col span={12}>{useTwimmInput}</Col>
          <Col span={12} style={!useTwimm ? { display: 'none' } : undefined}>
            {instanceInput}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {useTwimm ? sendInterventionRequestInput : emailInput}
          </Col>
        </Row>
        <Row>
          <Col span={24}>{incidentTypesInput}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};

FormAddLocation.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  addContractor: PropTypes.func.isRequired,
};

export default FormAddLocation;
