import { createSelector } from 'reselect';
import { State } from '../../types';
import { Request, RequestState } from './types';
import {
  currentUserOrganizationRootSelector,
  currentUserSelector,
} from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {RequestState}
 */
const requestStateSelector: (state: State) => RequestState = (state) =>
  state.requestsState;

/**
 * @param {State} state
 * @return {Request[]}
 */
export const requestsSelector = createSelector(
  requestStateSelector,
  (applicationState) => applicationState.requests,
);

/**
 * @param {State} state
 * @return {Request[]}
 */
export const requestsFromOrganizationSelector = createSelector(
  requestsSelector,
  currentUserOrganizationRootSelector,
  (requests, organization) =>
    requests.filter((requests) => requests.organizationId === organization.id),
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const requestsIsOpeningSelector = createSelector(
  requestStateSelector,
  (applicationState) => applicationState.status.opening,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const requestsIsListingSelector = createSelector(
  requestStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const requestsIsGettingSelector = createSelector(
  requestStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const requestsIsEditingSelector = createSelector(
  requestStateSelector,
  (applicationState) => applicationState.status.editing,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const requestsIsDeletingSelector = createSelector(
  requestStateSelector,
  (applicationState) => applicationState.status.deleting,
);

/**
 * @param {State} state
 * @return {Pagination}
 */
export const requestsPaginationSelector = createSelector(
  requestStateSelector,
  (applicationState) => applicationState.pagination,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Request|null}
 */
export const requestFromIdSelector = createSelector(
  requestsSelector,
  (_: State, id: Request['id']) => id,
  (requests, id) => requests.find((request) => request.id === id) || null,
);

/**
 * @param {State} state
 * @return {Request[]}
 */
export const visibleRequestsSelector = createSelector(
  requestsSelector,
  (state) => currentUserSelector(state),
  (requests, currentUser) =>
    requests.filter((request) =>
      currentUser.visibleOrganizationIds.includes(request.organizationId),
    ),
);
