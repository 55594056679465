import React, { ReactElement, FunctionComponent } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import 'antd/lib/layout/style';
import './Container.scss';
import { withRouter } from 'react-router';
import Header from './header/Header';

const Container: FunctionComponent = ({ children }): ReactElement => {
  return (
    <Layout>
      <Header />
      <div className="help-main-wrapper">
        <Layout.Content className="help-main-container">
          {children || ''}
        </Layout.Content>
      </div>
    </Layout>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default withRouter(Container);
