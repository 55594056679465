import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { getActionType } from '../common/types';

/**
 * @return {FetchListRequested}
 */
export const fetchListUsersActionCreator = createAction(
  getActionType('users', 'LIST_REQUESTED'),
);

/**
 * @param {User[]} users
 * @return {FetchListSucceeded}
 */
export const fetchListUsersSuccessActionCreator = createAction(
  getActionType('users', 'LIST_SUCCEEDED'),
  (users: types.User[]): types.User[] => users,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListUsersErrorActionCreator = createAction(
  getActionType('users', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {User} user
 * @return {FetchAddRequested}
 */
export const fetchAddUsersActionCreator = createAction(
  getActionType('users', 'ADD_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @return {FetchAddSucceeded}
 */
export const fetchAddUsersSuccessActionCreator = createAction(
  getActionType('users', 'ADD_SUCCEEDED'),
  (user: types.User): types.User => user,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddUsersErrorActionCreator = createAction(
  getActionType('users', 'ADD_FAILED'),
  (message: string): string => message,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersInformationActionCreator = createAction(
  getActionType('users', 'EDIT_INFORMATION_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersEmailActionCreator = createAction(
  getActionType('users', 'EDIT_EMAIL_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersPasswordActionCreator = createAction(
  getActionType('users', 'EDIT_PASSWORD_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchResetUsersPasswordActionCreator = createAction(
  getActionType('users', 'RESET_PASSWORD_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersOrganizationsActionCreator = createAction(
  getActionType('users', 'EDIT_ORGANIZATIONS_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersVisibleOrganizationsActionCreator = createAction(
  getActionType('users', 'EDIT_VISIBLE_ORGANIZATIONS_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersAdminOrganizationsActionCreator = createAction(
  getActionType('users', 'EDIT_ADMIN_ORGANIZATIONS_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersLocationsActionCreator = createAction(
  getActionType('users', 'EDIT_LOCATIONS_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersVisibleLocationsActionCreator = createAction(
  getActionType('users', 'EDIT_VISIBLE_LOCATIONS_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditUsersRoleActionCreator = createAction(
  getActionType('users', 'EDIT_ROLE_REQUESTED'),
  (user: types.User): types.User => user,
  (user, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @return {FetchEditSucceeded}
 */
export const fetchEditUsersSuccessActionCreator = createAction(
  getActionType('users', 'EDIT_SUCCEEDED'),
  (user: types.User): types.User => user,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditUsersErrorActionCreator = createAction(
  getActionType('users', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetUsersActionCreator = createAction(
  getActionType('users', 'GET_REQUESTED'),
  (id: types.User['id']): types.User['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {User} user
 * @return {FetchGetSucceeded}
 */
export const fetchGetUsersSuccessActionCreator = createAction(
  getActionType('users', 'GET_SUCCEEDED'),
  (user: types.User): types.User => user,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetUsersErrorActionCreator = createAction(
  getActionType('users', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteUsersSuccessActionCreator = createAction(
  getActionType('users', 'DELETE_SUCCEEDED'),
  (id: types.User['id']): types.User['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteUsersErrorActionCreator = createAction(
  getActionType('users', 'DELETE_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} email
 * @param {Meta} meta
 * @return {FetchAskResetUserPasswordRequested}
 */
export const fetchAskResetUserPasswordActionCreator = createAction(
  getActionType('users', 'ASK_RESET_USER_PASSWORD_REQUESTED'),
  (email: types.User['email']): types.User['email'] => email,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {boolean} response
 * @return {FetchAskResetUserPasswordSucceeded}
 */
export const fetchAskResetUserPasswordSuccessActionCreator = createAction(
  getActionType('users', 'ASK_RESET_USER_PASSWORD_SUCCEEDED'),
  (response: boolean): boolean => response,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAskResetUserPasswordErrorActionCreator = createAction(
  getActionType('users', 'ASK_RESET_USER_PASSWORD_FAILED'),
  (message: string): string => message,
);

export type FetchAskResetUserPasswordRequested = ReturnType<
  typeof fetchAskResetUserPasswordActionCreator
>;

export type FetchAskResetUserPasswordSucceeded = ReturnType<
  typeof fetchAskResetUserPasswordSuccessActionCreator
>;
