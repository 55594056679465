import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import { getBase64 } from '../../../../utils/FileUtils';
import { Modal, RestrictedToPermissions } from '../index';
import {
  PropTypesRestrictedToPermissions,
  RestrictedToPermissionsProps,
} from '../permissions/RestrictedToPermissions';
import UploadListField, { UploadListFieldProps } from './UploadListField';

export interface UploadPicturesListFieldProps extends UploadListFieldProps {
  restrictedToPermissions?: RestrictedToPermissionsProps;
}

const UploadPicturesListField: FunctionComponent<UploadPicturesListFieldProps> =
  ({ restrictedToPermissions, ...props }): ReactElement => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const handlePreview = async (file: UploadFile): Promise<void> => {
      if (file.preview) {
        setPreviewImage(file.preview);
        setPreviewVisible(true);

        return;
      }

      if (file.originFileObj) {
        const base64 = await getBase64(file.originFileObj);
        if (base64 && typeof base64 === 'string') {
          setPreviewImage(base64);
          setPreviewVisible(true);

          return;
        }
      }
    };

    const UploadPicturesListField = (
      <UploadListField
        addButton={
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Ajouter</div>
          </div>
        }
        listType="picture-card"
        onPreview={handlePreview}
        accept="image/*"
        itemRender={(originItem): ReactElement => originItem}
        showUploadList={{
          downloadIcon: <InfoCircleOutlined title={'Voir plus'} />,
          showDownloadIcon: true,
        }}
        {...props}
      />
    );

    return (
      <>
        {restrictedToPermissions ? (
          <RestrictedToPermissions
            {...restrictedToPermissions}
            unauthorizedText={
              <UploadListField
                listType="picture-card"
                onPreview={handlePreview}
                disabled={true}
                {...props}
              />
            }
          >
            {UploadPicturesListField}
          </RestrictedToPermissions>
        ) : (
          UploadPicturesListField
        )}
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={(): void => setPreviewVisible(false)}
        >
          <img alt="incident" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  };

UploadPicturesListField.propTypes = {
  restrictedToPermissions: PropTypesRestrictedToPermissions,
};

export default UploadPicturesListField;
