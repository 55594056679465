import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchGetProfilesActionCreator } from '../../../../states/ducks/profiles/actions';
import { profileFromUserIdSelector } from '../../../../states/ducks/profiles/selectors';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import { User } from '../../../../states/ducks/users/types';
import { State } from '../../../../states/types';
import { Avatar } from '../index';
import './ProfileAvatarAndNameFromUserId.scss';

interface ProfileAvatarAndNameFromUserIdProps {
  getProfile: Function;
  profile: Profile | null;
  userId: User['id'];
}

/**
 * @param {Function} getProfile
 * @param {Profile} profile
 * @param {string} userId
 *
 * @return {ReactElement}
 */
const ProfileAvatarAndNameFromUserId: FunctionComponent<ProfileAvatarAndNameFromUserIdProps> =
  ({ getProfile, profile, userId }): ReactElement => {
    useEffect((): void => {
      if (getProfile && !profile && userId) {
        getProfile(userId);
      }
    }, [getProfile, profile, userId]);

    return profile ? (
      <>
        <Avatar className={'avatar-near-name'} profileId={profile.id} />
        {profile.firstName + ' ' + profile.lastName}
      </>
    ) : (
      <></>
    );
  };

ProfileAvatarAndNameFromUserId.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypesProfile,
  userId: PropTypes.string.isRequired,
};

interface MapStateToProps {
  profile: Profile | null;
}

interface MapDispatchToProps {
  getProfile: Function;
}

const mapStateToProps = (
  state: State,
  props: { userId: User['id'] },
): MapStateToProps => ({
  profile: profileFromUserIdSelector(state, props.userId),
});

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: fetchGetProfilesActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileAvatarAndNameFromUserId);
