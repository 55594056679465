import React, { FunctionComponent, ReactElement } from 'react';
import { List } from 'antd';
import 'antd/lib/list/style';
import { ListItemProps } from 'antd/lib/list';
import classNames from 'classnames';

import PropTypes from 'prop-types';

interface Props extends ListItemProps {
  className?: string;
}

/**
 * List.Item component
 * @param {any} props empty props
 * @return {ReactElement}
 */

const ListItem: FunctionComponent<Props> = ({ ...props }): ReactElement => {
  const initialClasses = props.className || '';
  const classes = classNames('app-list-item', initialClasses);
  return <List.Item className={classes} {...props} />;
};

ListItem.propTypes = {
  className: PropTypes.string,
};

export default ListItem;
