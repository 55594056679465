import React, { ReactElement } from 'react';
import { Table as TableAnt } from 'antd';
import 'antd/lib/table/style';
import { TableProps } from 'antd/lib/table';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Table.scss';
import ItemRender from '../pagination/ItemRender';
import { Empty } from '../../base';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { DEFAULT_PAGE_SIZE } from '../../../../services/search/search';

/**
 * Table component
 * @param {string} className
 * @param {string} locale
 * @param {any} props
 * @return {ReactElement}
 */
function Table<T extends object>({
  className,
  locale,
  ...props
}: TableProps<T>): ReactElement {
  const classes = classnames('app-table', className);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomExpandIcon = (props: any): ReactElement => {
    let icon;
    if (props.expanded) {
      icon = <CaretDownOutlined />;
    } else if (!props.record.children) {
      icon = '';
    } else {
      icon = <CaretRightOutlined />;
    }
    return (
      <span
        onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
          e.stopPropagation();
          props.onExpand(props.record, e);
        }}
        className="expand-row-icon"
      >
        {icon}
      </span>
    );
  };

  return (
    <TableAnt<T>
      {...props}
      locale={{
        emptyText: <Empty>{locale ? locale.emptyText : ''}</Empty>,
      }}
      className={classes}
      pagination={{
        hideOnSinglePage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        showLessItems: true,
        itemRender: ItemRender,
        ...props.pagination,
      }}
      expandIcon={CustomExpandIcon}
    />
  );
}

Table.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.object,
};

export default Table;
