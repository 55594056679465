/* eslint-disable no-console */

declare const process: {
  env: Record<string, {}>;
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

interface Config {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

/**
 * register
 *
 * @param {string} swUrl
 * @param {Config} [config]
 */
function registerValidSW(swUrl: string, config?: Config): void {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration): void => {
      registration.onupdatefound = (): void => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = (): void => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log(
                'New content is available and ' +
                  'will be used when all tabs for this page ' +
                  'are closed. See https://bit.ly/CRA-PWA.',
              );

              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('Content is cached for offline use.');

              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error): void => {
      console.error('Error during service worker registration:', error);
    });
}

/**
 * @param {string} swUrl
 * @param {Config} [config]
 */
function checkValidServiceWorker(swUrl: string, config?: Config): void {
  fetch(swUrl)
    .then((response): void => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then((registration): void => {
          registration.unregister().then((): void => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch((): void => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    });
}

/**
 * @export
 * @param {Config} [config] config
 * @return {void}
 */
export function register(config?: Config): void {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(
      (process as { env: { [key: string]: string } }).env.PUBLIC_URL,
      window.location.href,
    );
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', (): void => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // eslint-disable-next-line
        // @typescript-eslint/no-use-before-define
        checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready.then((): void => {
          console.log(
            'This web app is being served' +
              'cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA',
          );
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

/**
 * @export
 */
export function unregister(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration): void => {
      registration.unregister();
    });
  }
}
