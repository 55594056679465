import React, { FunctionComponent, ReactElement } from 'react';
import { DatePicker as DatePickerAnt } from 'antd';
import { DatePickerProps as DatePickerAntProps } from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style';
import './DatePicker.scss';
import DatePickerLocale from 'antd/lib/date-picker/locale/fr_FR';
import PropTypes from 'prop-types';
import moment from 'moment';

export type DatePickerProps = DatePickerAntProps & {
  inverted?: boolean;
  onlyPast?: boolean;
};

/**
 * DatePicker component
 * @param {boolean} inverted
 * @param {boolean} onlyPast
 * @param {any} props
 * @return {ReactElement}
 */
const DatePicker: FunctionComponent<DatePickerProps> = ({
  inverted,
  onlyPast,
  ...props
}): ReactElement => {
  const containerID = 'no-outside-click-event' + String(new Date().valueOf());

  return (
    <div
      id={containerID}
      className={'no-outside-click-event ' + inverted ? 'inverted ' : ''}
    >
      <DatePickerAnt
        {...props}
        picker={'date'}
        allowClear={false}
        locale={DatePickerLocale}
        format={'D/MM/YYYY'}
        showToday={false}
        disabledDate={(current): boolean => {
          if (!onlyPast) {
            return false;
          }

          return (
            current !== undefined &&
            current !== null &&
            current > moment().endOf('day')
          );
        }}
        getPopupContainer={(): HTMLElement => {
          return document.getElementById(containerID) as HTMLElement;
        }}
      />
    </div>
  );
};

DatePicker.defaultProps = {
  onlyPast: false,
};

DatePicker.propTypes = {
  inverted: PropTypes.bool,
  onlyPast: PropTypes.bool,
};

export default DatePicker;
