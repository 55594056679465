import React, { FunctionComponent, ReactElement } from 'react';
import { TreeSelect as TreeSelectAnt } from 'antd';
import 'antd/lib/tree-select/style';
import { TreeSelectProps as TreeSelectAntProps } from 'antd/lib/tree-select';
import {
  PropTypesTreeModel,
  TreeModel,
} from '../../../../states/ducks/common/types';
import './TreeSelect.scss';
import './SelectDropdown.scss';
import PropTypes from 'prop-types';

const { TreeNode } = TreeSelectAnt;

export interface TreeSelectAntWithLimitProps<T> extends TreeSelectAntProps<T> {
  limit?: number;
}

export interface TreeSelectProps
  extends TreeSelectAntWithLimitProps<string | string[]> {
  trees: TreeModel[];
  emptyMessage?: string;
}

/**
 * @param {TreeModel[]} trees
 * @param {string} emptyMessage
 * @param {number} limit
 * @param {TreeSelectProps} props
 * @return {ReactElement}
 */
const TreeSelect: FunctionComponent<TreeSelectProps> = ({
  trees,
  emptyMessage,
  limit,
  ...props
}): ReactElement => {
  const getTreeNodes = (models: TreeModel[]): ReactElement[] =>
    models.map((model) => {
      if (model.children) {
        return (
          <TreeNode title={model.name} key={model.id} value={model.id}>
            {getTreeNodes(model.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={emptyMessage} key={model.id} value={model.id} />;
    });

  const containerID = 'no-outside-click-event' + String(new Date().valueOf());

  return (
    <div id={containerID} className="no-outside-click-event">
      <TreeSelectAnt
        {...props}
        showSearch
        treeDefaultExpandedKeys={([] as string[]).concat(props.value || '')}
        treeNodeFilterProp="title"
        filterTreeNode={true}
        getPopupContainer={(): HTMLElement => {
          return document.getElementById(containerID) as HTMLElement;
        }}
        dropdownStyle={{ maxHeight: 275, overflow: 'auto' }}
        maxTagCount={2}
        value={
          limit && Array.isArray(props.value) && props.value.length > limit
            ? props.value.slice(props.value.length - limit)
            : props.value
        }
      >
        {getTreeNodes(trees || [])}
      </TreeSelectAnt>
    </div>
  );
};

TreeSelect.defaultProps = {
  emptyMessage: 'Aucun élément',
};

TreeSelect.propTypes = {
  trees: PropTypes.arrayOf(PropTypesTreeModel.isRequired).isRequired,
  emptyMessage: PropTypes.string,
  limit: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ]),
};

export default TreeSelect;
