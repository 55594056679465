import PropTypes from 'prop-types';
import { TreeModel } from '../common/types';

export type Organization = TreeModel;

export interface OrganizationsState {
  organizations: Organization[];
  status: {
    listing: number;
    adding: boolean;
    editing: boolean;
    deleting: boolean;
    getting: boolean;
  };
}

export const PropTypesOrganization = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  children: PropTypes.array,
  parents: PropTypes.array,
});
