import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  DataWithFilters,
  Filter,
  InputSearch,
  Table,
  Avatar,
  MultipleSelect,
  SelectOption,
  notification,
  PopoverActions,
  Row,
  Col,
  Tag,
  RoleTag,
} from '../../base';
import {
  profileActions,
  profileSelectors,
} from '../../../../states/ducks/profiles';
import PropTypes from 'prop-types';
import { userActions, userSelectors } from '../../../../states/ducks/users';
import InfoUser from '../modal/user/InfoUser';
import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import {
  Location,
  PropTypesLocation,
} from '../../../../states/ducks/locations/types';
import { ColumnProps } from 'antd/lib/table';
import { alphabeticalSortBy } from '../../../../utils/SortUtils';
import { roleSelectors } from '../../../../states/ducks/roles';
import { PropTypesRole, Role } from '../../../../states/ducks/roles/types';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import { User, PropTypesUser } from '../../../../states/ducks/users/types';
import './LocationUsers.scss';
import {
  locationActions,
  locationSelectors,
} from '../../../../states/ducks/locations';
import { locationTypeFromIdSelector } from '../../../../states/ducks/locationtypes/selectors';
import {
  LocationType,
  PropTypesLocationType,
} from '../../../../states/ducks/locationtypes/types';
import { CustomTagProps } from 'rc-select/lib/interface/generator';

interface Props {
  location: Location;
  profilesAssigned: Profile[];
  profilesAssignable: Profile[];
  usersAssignedToLocation: User[];
  usersAssignableToLocation: User[];
  roles: Role[];
  locations: Location[];
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  locationType: LocationType | null;
  loadProfiles: Function;
  loadUsers: Function;
  loadLocations: Function;
  editUserLocations: Function;
  editUserVisibleLocations: Function;
}

interface TableProfiles {
  id: string;
  key: string;
  name: string;
  role: string;
  locationName: string;
  action: {
    id: string;
    name: string;
  };
}

const LocationUsers: FunctionComponent<Props> = ({
  location,
  profilesAssigned,
  profilesAssignable,
  usersAssignedToLocation,
  usersAssignableToLocation,
  roles,
  locations,
  locationsAndTheirDescendantsIdsSelector,
  locationType,
  loadProfiles,
  loadUsers,
  loadLocations,
  editUserLocations,
  editUserVisibleLocations,
}): ReactElement => {
  const [selectedProfileId, setSelectedProfileId] = useState('');

  const locationTypeName = locationType
    ? locationType.name.toLowerCase()
    : 'lieu';

  useEffect((): void => {
    loadProfiles();
  }, [loadProfiles]);

  useEffect((): void => {
    loadUsers();
  }, [loadUsers]);

  useEffect((): void => {
    loadLocations();
  }, [loadLocations]);

  const columns: ColumnProps<TableProfiles>[] = [
    {
      key: 'name',
      title: 'Utilisateur',
      dataIndex: 'name',
      sorter: (a: TableProfiles, b: TableProfiles): number =>
        alphabeticalSortBy(a.name, b.name),
      // eslint-disable-next-line react/display-name
      render: (name: string, record: TableProfiles): ReactElement => (
        <>
          <Avatar profileId={record.id} />
          {name}
        </>
      ),
    },
    {
      key: 'role',
      title: 'Rôle',
      dataIndex: 'role',
      sorter: (a: TableProfiles, b: TableProfiles): number =>
        alphabeticalSortBy(a.role, b.role),
    },
    {
      key: 'locationName',
      title: 'Rattaché à',
      dataIndex: 'locationName',
      sorter: (a: TableProfiles, b: TableProfiles): number =>
        alphabeticalSortBy(a.locationName, b.locationName),
    },
    {
      key: 'operation',
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableProfiles): ReactElement => {
        return (
          <PopoverActions
            objectId={record.id}
            onDelete={(
              userId: string,
              { resolve, reject }: { resolve: Function; reject: Function },
            ): void => {
              const user = usersAssignedToLocation.find(
                (user) => user.id === userId,
              );

              if (!user || !user.locationIds || !user.visibleLocationIds) {
                return;
              }

              user.locationIds = user.locationIds.filter(
                (id) => id !== location.id,
              );
              user.visibleLocationIds = user.visibleLocationIds.filter(
                (id) =>
                  !locationsAndTheirDescendantsIdsSelector[
                    location.id
                  ].includes(id),
              );
              editUserLocations(user);
              editUserVisibleLocations(user, { resolve, reject });
            }}
            deleteConfirmMessage={`Voulez retirer ${record.name} du ${locationTypeName} : “${location.name}” ?”`}
            deleteDoneMessage={`L'utilisateur à bien été détaché du ${locationTypeName}`}
            size="small"
            deleteText="Retirer"
          />
        );
      },
    },
  ];

  const data = profilesAssigned.map((profile: Profile): TableProfiles => {
    const user = usersAssignedToLocation.find(
      (user: User): boolean => user.id === profile.userId || false,
    );

    const role = user
      ? roles.find((role) => role.id === user.roleId)
      : undefined;

    const location = user
      ? locations.find((location) => user.locationIds?.includes(location.id))
      : undefined;

    return {
      id: profile.id,
      key: profile.id,
      name: profile.firstName + ' ' + profile.lastName,
      role: role ? role.name : '',
      locationName: location
        ? location.code
          ? `[${location.code}] ${location.name}`
          : location.name
        : '',
      action: {
        id: profile.userId,
        name: profile.firstName + ' ' + profile.lastName,
      },
    };
  });

  const tagRender = (props: CustomTagProps): ReactElement => {
    // eslint-disable-next-line react/prop-types
    const { value, closable, onClose } = props;

    const user = usersAssignableToLocation.find(
      (user: User): boolean => user.id === value || false,
    );

    return (
      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {user ? user.firstName + ' ' + user.lastName : ''}
      </Tag>
    );
  };

  return (
    <>
      <DataWithFilters
        dataSource={data}
        displayComponent={
          <Table
            className="app-location-users"
            columns={columns}
            onRow={(row): object => ({
              onClick: (): void => setSelectedProfileId(row.id),
            })}
          />
        }
        rightBarSize={16}
        rightBar={
          <MultipleSelect
            filterOption={(input, option): boolean =>
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Ajouter des occupants"
            tagRender={tagRender}
            onClick={(userIds: string[]): void => {
              const promise = new Promise<void>((resolve): void => {
                userIds.forEach((userId) => {
                  const user = usersAssignableToLocation.find(
                    (user) => user.id === userId,
                  );

                  if (!user) {
                    return;
                  }

                  user.locationIds = [location.id].concat(
                    user.locationIds || [],
                  );
                  user.visibleLocationIds = [
                    ...locationsAndTheirDescendantsIdsSelector[location.id],
                  ].concat(user.visibleLocationIds || []);
                  editUserLocations(user);
                  editUserVisibleLocations(user);
                });

                resolve();
              });

              promise
                .then((): void => {
                  notification.success({
                    message: `Le(s) utilisateur(s) sont rattaché(s) au ${locationTypeName}.`,
                  });
                })
                .catch((): void => {
                  notification.error({
                    message: `Une erreur est survenue.`,
                  });
                });
            }}
          >
            {profilesAssignable.map((profile) => {
              const user = usersAssignableToLocation.find(
                (user: User): boolean => user.id === profile.userId || false,
              );

              const role = user
                ? roles.find((role) => role.id === user.roleId)
                : undefined;

              return (
                <SelectOption
                  key={profile.userId}
                  title={profile.firstName + ' ' + profile.lastName}
                  value={profile.userId}
                >
                  <Row align="middle" style={{ flexFlow: 'nowrap' }}>
                    <Col>
                      <Avatar profileId={profile.id} />
                    </Col>
                    <Col>{profile.firstName + ' ' + profile.lastName}</Col>
                    <Col>{profile.email}</Col>
                    <Col>{role ? <RoleTag roleId={role.id} /> : ''}</Col>
                  </Row>
                </SelectOption>
              );
            })}
          </MultipleSelect>
        }
      >
        <Filter
          component={<InputSearch placeholder="Rechercher un utilisateur" />}
          filterName={'search'}
          filter={(
            value: string,
            record: { [key: string]: string },
          ): boolean => {
            const columns = ['name', 'role'];
            const fields: string[] = [];

            columns.forEach((fieldName: string): void => {
              fields.push(String(record[fieldName] || ''));
            });

            for (const field of fields) {
              if (field.search(new RegExp(value, 'i')) >= 0) {
                return true;
              }
            }

            return false;
          }}
          onChangeFormatValue={(e: ChangeEvent<HTMLInputElement>): string[] => [
            e.currentTarget.value,
          ]}
        />
      </DataWithFilters>
      <InfoUser
        visible={'' !== selectedProfileId}
        hideDrawer={(): void => setSelectedProfileId('')}
        profileId={selectedProfileId || ''}
      />
    </>
  );
};

LocationUsers.propTypes = {
  location: PropTypesLocation.isRequired,
  profilesAssigned: PropTypes.arrayOf(PropTypesProfile.isRequired).isRequired,
  profilesAssignable: PropTypes.arrayOf(PropTypesProfile.isRequired).isRequired,
  usersAssignedToLocation: PropTypes.arrayOf(PropTypesUser.isRequired)
    .isRequired,
  usersAssignableToLocation: PropTypes.arrayOf(PropTypesUser.isRequired)
    .isRequired,
  roles: PropTypes.arrayOf(PropTypesRole.isRequired).isRequired,
  locations: PropTypes.arrayOf(PropTypesLocation.isRequired).isRequired,
  locationsAndTheirDescendantsIdsSelector: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  locationType: PropTypesLocationType,
  loadProfiles: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  editUserLocations: PropTypes.func.isRequired,
  editUserVisibleLocations: PropTypes.func.isRequired,
};

interface MapStateToProps {
  profilesAssigned: Profile[];
  profilesAssignable: Profile[];
  usersAssignedToLocation: User[];
  usersAssignableToLocation: User[];
  roles: Role[];
  locations: Location[];
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  locationType: LocationType | null;
}

interface MapDispatchToProps {
  loadProfiles: Function;
  loadUsers: Function;
  loadLocations: Function;
  editUserLocations: Function;
  editUserVisibleLocations: Function;
}

const mapStateToProps = (
  state: State,
  props: { location: Location },
): MapStateToProps => {
  const usersAssignedToLocation = userSelectors.usersAssignedToLocationSelector(
    state,
    props.location,
  );

  const usersAssignableToLocation =
    userSelectors.usersAssignableToLocationSelector(state, props.location);

  const profilesAssigned = profileSelectors.profileFromMultipleUserIdsSelector(
    state,
    usersAssignedToLocation.map((user) => user.id),
  );

  const profilesAssignable =
    profileSelectors.profileFromMultipleUserIdsSelector(
      state,
      usersAssignableToLocation.map((user) => user.id),
    );

  const roles = roleSelectors.rolesFromOrganizationSelector(state);

  const locations = locationSelectors.locationsSelector(state);

  const locationsAndTheirDescendantsIdsSelector =
    locationSelectors.locationsAndTheirDescendantsIdsSelector(state);

  const locationType =
    props.location && props.location.locationTypeId
      ? locationTypeFromIdSelector(state, props.location.locationTypeId)
      : null;

  return {
    profilesAssigned: profilesAssigned,
    profilesAssignable: profilesAssignable,
    usersAssignedToLocation: usersAssignedToLocation,
    usersAssignableToLocation: usersAssignableToLocation,
    roles: roles,
    locations: locations,
    locationsAndTheirDescendantsIdsSelector,
    locationType,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  loadProfiles: profileActions.fetchListProfilesActionCreator,
  loadUsers: userActions.fetchListUsersActionCreator,
  loadLocations: locationActions.fetchListLocationsActionCreator,
  editUserLocations: userActions.fetchEditUsersLocationsActionCreator,
  editUserVisibleLocations:
    userActions.fetchEditUsersVisibleLocationsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationUsers);
