import { createSelector } from 'reselect';
import { State } from '../../types';
import { SubscriptionState } from './types';
import { User } from '../users/types';
import { currentUserSelector } from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {SubscriptionState}
 */
const subscriptionStateSelector: (state: State) => SubscriptionState = (
  state,
) => state.subscriptionsState;

/**
 * @param {State} state
 * @return {Subscription[]}
 */
export const subscriptionsSelector = createSelector(
  subscriptionStateSelector,
  (applicationState) => applicationState.subscriptions,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const subscriptionsIsListingSelector = createSelector(
  subscriptionStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string}
 * @return {Subscription[]}
 */
export const subscriptionsFromUserIdSelector = createSelector(
  subscriptionsSelector,
  (_: State, id: User['id']) => id,
  (subscriptions, id) =>
    subscriptions.filter((subscription) => subscription.userId === id),
);

/**
 * @param {State} state
 * @param {string}
 * @return {Subscription[]}
 */
export const subscriptionsFromCurrentUserIdSelector = createSelector(
  subscriptionsSelector,
  currentUserSelector,
  (subscriptions, currentUser) =>
    subscriptions.filter(
      (subscription) => subscription.userId === currentUser.id,
    ),
);
