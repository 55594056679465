import { createSelector } from 'reselect';
import { Intervention, InterventionState } from './types';
import { State } from '../../types';

/**
 * @param {State} state
 * @return {InterventionState}
 */
const interventionStateSelector: (state: State) => InterventionState = (
  state,
) => state.interventionsState;

/**
 * @param {State} state
 * @return {Intervention[]}
 */
export const interventionsSelector = createSelector(
  interventionStateSelector,
  (applicationState) => applicationState.interventions,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const interventionsIsListingSelector = createSelector(
  interventionStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Intervention[]}
 */
export const interventionFromIncidentIdSelector = createSelector(
  interventionsSelector,
  (_: State, id: Intervention['id']): Intervention['id'] => id,
  (interventions, id) =>
    interventions.filter((intervention) => intervention.incidentId === id),
);
