import { create, get, patch, remove } from '../base';
import { incidentTypeTypes } from '../../../states/ducks/incidenttypes';

/**
 * @param {IncidentType} incidentType
 * @return {Promise<string>}
 */
export const addIncidentType = async (
  incidentType: Partial<incidentTypeTypes.IncidentType>,
): Promise<incidentTypeTypes.IncidentType['id']> =>
  await create(`/v1/incidents/incidenttypes/create`, incidentType);

/**
 * @return {Promise<IncidentType[]>}
 */
export const listIncidentTypes = async (): Promise<
  incidentTypeTypes.IncidentType[]
> => await get(`/v1/incidents/incidenttypes/list`);

/**
 * @param {string} id
 * @return {Promise<IncidentType>}
 */
export const getIncidentType = async (
  id: incidentTypeTypes.IncidentType['id'],
): Promise<incidentTypeTypes.IncidentType> =>
  await get(`/v1/incidents/incidenttypes/get/${id}`);

/**
 * @param {IncidentType} incidentType
 * @return {Promise<string>}
 */
export const editIncidentTypeDesignation = async (
  incidentType: incidentTypeTypes.IncidentType,
): Promise<string> => {
  const { id, name } = incidentType;

  return await patch(`/v1/incidents/incidenttypes/changeDesignation/${id}`, {
    name,
  });
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteIncidentType = async (
  id: incidentTypeTypes.IncidentType['id'],
): Promise<string> => await remove(`/v1/incidents/incidenttypes/delete/${id}`);
