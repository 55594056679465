import { createAction } from 'redux-actions';
import * as types from './types';
import { getActionType } from '../common/types';
import { Kpi } from './types';

/**
 * @return {FetchGetRequested}
 */
export const fetchCountIncidentsByStatusActionCreator = createAction(
  getActionType('kpis', 'GET_INCIDENTS_BY_STATUS_REQUESTED'),
);

/**
 * @return {FetchGetRequested}
 */
export const fetchCountIncidentsReportedAndResolvedByMonthActionCreator =
  createAction(getActionType('kpis', 'GET_INCIDENTS_BY_MONTH_REQUESTED'));

/**
 * @return {FetchGetRequested}
 */
export const fetchCountIncidentsByMainLocationsActionCreator = createAction(
  getActionType('kpis', 'GET_INCIDENTS_BY_MAIN_LOCATIONS_REQUESTED'),
);

/**
 * @return {FetchGetRequested}
 */
export const fetchCountRequestsByManagerActionCreator = createAction(
  getActionType('kpis', 'GET_REQUESTS_BY_MANAGER_REQUESTED'),
);

/**
 * @return {FetchGetRequested}
 */
export const fetchCountRequestsOpenedAndClosedByMonthActionCreator =
  createAction(getActionType('kpis', 'GET_REQUESTS_BY_MONTH_REQUESTED'));

/**
 * @param {Incident} kpi
 * @return {FetchGetSucceeded}
 */
export const fetchCountSuccessActionCreator = createAction(
  getActionType('kpis', 'GET_SUCCEEDED'),
  (kpi: types.Kpi): types.Kpi => kpi,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchCountErrorActionCreator = createAction(
  getActionType('kpis', 'GET_FAILED'),
  (message: string): string => message,
);
