import React, { ReactElement } from 'react';
import {
  Row,
  Col,
  Card,
  CardMeta,
  PageHeader,
  RestrictedToPermissions,
} from '../../base';
import { NavLink } from 'react-router-dom';
import './Administration.scss';
import {
  ApartmentOutlined,
  HomeOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';

const Administration = (): ReactElement => {
  const description =
    'Gérez vos organisations, invitez des utilisateurs à les rejoindre...';
  return (
    <RestrictedToPermissions subject={'administration'} action={'access'}>
      <PageHeader title="Administration" />
      <Row className="app-administration-container">
        <Col lg={8} md={12} xs={24}>
          <NavLink to="/administration/organization">
            <Card
              className="app-administration-card"
              hoverable
              cover={<ApartmentOutlined />}
            >
              <CardMeta title="Organisations" description={description} />
            </Card>
          </NavLink>
        </Col>
        <Col lg={8} md={12} xs={24}>
          <NavLink to="/administration/location">
            <Card
              className="app-administration-card"
              hoverable
              cover={<HomeOutlined />}
            >
              <CardMeta
                title="Lieux"
                description="Gérez les types de lieu..."
              />
            </Card>
          </NavLink>
        </Col>
        <Col lg={8} md={12} xs={24}>
          <NavLink to="/administration/incident">
            <Card
              className="app-administration-card"
              hoverable
              cover={<ToolOutlined />}
            >
              <CardMeta
                title="Incidents"
                description="Gérez les types d'incident..."
              />
            </Card>
          </NavLink>
        </Col>
        <Col lg={8} md={12} xs={24}>
          <NavLink to="/administration/user">
            <Card
              className="app-administration-card"
              hoverable
              cover={<UserOutlined />}
            >
              <CardMeta
                title="Utilisateurs"
                description="Gérez vos utilisateurs, leurs rôles..."
              />
            </Card>
          </NavLink>
        </Col>
      </Row>
    </RestrictedToPermissions>
  );
};

export default Administration;
