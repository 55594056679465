import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { UploadFile } from 'antd/lib/upload/interface';
import { userTypes } from '../users';
import { getActionType } from '../common/types';

export interface ProfileFormData extends types.Profile {
  file: UploadFile;
}

/**
 * @return {FetchListRequested}
 */
export const fetchListProfilesActionCreator = createAction(
  getActionType('profiles', 'LIST_REQUESTED'),
);

/**
 * @param {Profile[]} profiles
 * @return {FetchListSucceeded}
 */
export const fetchListProfilesSuccessActionCreator = createAction(
  getActionType('profiles', 'LIST_SUCCEEDED'),
  (profiles: types.Profile[]): types.Profile[] => profiles,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListProfilesErrorActionCreator = createAction(
  getActionType('profiles', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Profile} profile
 * @return {FetchAddRequested}
 */
export const fetchAddProfilesActionCreator = createAction(
  getActionType('profiles', 'ADD_REQUESTED'),
  (profile: ProfileFormData): ProfileFormData => profile,
  (profile, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Profile} profile
 * @return {FetchAddSucceeded}
 */
export const fetchAddProfilesSuccessActionCreator = createAction(
  getActionType('profiles', 'ADD_SUCCEEDED'),
  (profile: types.Profile): types.Profile => profile,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddProfilesErrorActionCreator = createAction(
  getActionType('profiles', 'ADD_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Profile} profile
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditProfilesInformationActionCreator = createAction(
  getActionType('profiles', 'EDIT_INFORMATION_REQUESTED'),
  (profile: types.Profile): types.Profile => profile,
  (profile, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Profile} profile
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditProfilesEmailActionCreator = createAction(
  getActionType('profiles', 'EDIT_EMAIL_REQUESTED'),
  (profile: types.Profile): types.Profile => profile,
  (profile, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Profile} profile
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditProfilesOrganizationsActionCreator = createAction(
  getActionType('profiles', 'EDIT_ORGANIZATIONS_REQUESTED'),
  (profile: types.Profile): types.Profile => profile,
  (profile, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Profile} profile
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditProfilesAdminOrganizationsActionCreator = createAction(
  getActionType('profiles', 'EDIT_ADMIN_ORGANIZATIONS_REQUESTED'),
  (profile: types.Profile): types.Profile => profile,
  (profile, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Profile} profile
 * @return {FetchEditSucceeded}
 */
export const fetchEditProfilesSuccessActionCreator = createAction(
  getActionType('profiles', 'EDIT_SUCCEEDED'),
  (profile: types.Profile): types.Profile => profile,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditProfilesErrorActionCreator = createAction(
  getActionType('profiles', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {File} file
 * @param {Profile} profile
 * @param {Meta} meta
 * @return {FetchAddAvatarRequested}
 */
export const fetchAddProfilesAvatarActionCreator = createAction(
  getActionType('profiles', 'ADD_AVATAR_REQUESTED'),
  (payload: {
    file: UploadFile;
    profile: types.Profile;
  }): {
    file: UploadFile;
    profile: Partial<ProfileFormData>;
  } => payload,
  (profile, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} fileId
 * @param {Profile} profile
 * @param {Meta} meta
 * @return {FetchDeleteAvatarRequested}
 */
export const fetchDeleteProfilesAvatarActionCreator = createAction(
  getActionType('profiles', 'DELETE_AVATAR_REQUESTED'),
  (payload: {
    fileId: string;
    profile: types.Profile;
  }): {
    fileId: string;
    profile: types.Profile;
  } => payload,
  (profile, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetProfilesActionCreator = createAction(
  getActionType('profiles', 'GET_REQUESTED'),
  (id: types.Profile['id']): types.Profile['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetProfilesByUserIdActionCreator = createAction(
  getActionType('profiles', 'GET_BY_USERID_REQUESTED'),
  (id: userTypes.User['id']): userTypes.User['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Profile} profile
 * @return {FetchGetSucceeded}
 */
export const fetchGetProfilesSuccessActionCreator = createAction(
  getActionType('profiles', 'GET_SUCCEEDED'),
  (profile: types.Profile): types.Profile => profile,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetProfilesErrorActionCreator = createAction(
  getActionType('profiles', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteProfilesActionCreator = createAction(
  getActionType('profiles', 'DELETE_REQUESTED'),
  (id: types.Profile['id']): types.Profile['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Profile} profile
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteProfilesSuccessActionCreator = createAction(
  getActionType('profiles', 'DELETE_SUCCEEDED'),
  (id: types.Profile['id']): types.Profile['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteProfilesErrorActionCreator = createAction(
  getActionType('profiles', 'DELETE_FAILED'),
  (message: string): string => message,
);

export type FetchAddAvatarRequested = ReturnType<
  typeof fetchAddProfilesAvatarActionCreator
>;

export type FetchDeleteAvatarRequested = ReturnType<
  typeof fetchDeleteProfilesAvatarActionCreator
>;
