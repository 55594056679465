import React, { ReactElement, forwardRef } from 'react';
import { Cascader as CascaderAnt } from 'antd';
import 'antd/lib/cascader/style';
import { CascaderProps } from 'antd/lib/cascader';
import './Cascader.scss';
import classnames from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Component = (props: CascaderProps, ref: any): ReactElement => {
  const classes = classnames('app-cascader', props.className);
  return <CascaderAnt size="large" {...props} ref={ref} className={classes} />;
};

Component.displayName = 'Cascader';

/**
 * Input component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Cascader = forwardRef<HTMLInputElement, CascaderProps>(Component);

export default Cascader;
