import { connect } from 'react-redux';
import AddOrganization from '../../../components/layout/modal/organization/AddOrganizations';

import { organizationActions } from '../../../../states/ducks/organizations';

interface MapDispatchToProps {
  addOrganization: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  addOrganization: organizationActions.fetchAddOrganizationsActionCreator,
};

export default connect(null, mapDispatchToProps)(AddOrganization);
