import { connect } from 'react-redux';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../states/ducks/profiles';
import {
  requestTypes,
  requestSelectors,
  requestActions,
} from '../../../states/ducks/requests';
import { Pagination } from '../../../services/search/search';
import { Profile } from '../../../states/ducks/profiles/types';
import { Request } from '../../../states/ducks/requests/types';
import { State } from '../../../states/types';
import Requests from '../../components/layout/requests/Requests';
import { organizationSelectors } from '../../../states/ducks/organizations';

interface MapStateToProps {
  descendantsIdByOrganizationId: Record<string, string[]>;
  profilesFromResults: profileTypes.Profile[];
  requests: requestTypes.Request[];
  total: Pagination['total'];
}

interface MapDispatchToProps {
  deleteRequest: Function;
  loadProfiles: Function;
  loadRequests: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const requests = requestSelectors.visibleRequestsSelector(state);

  const total = requestSelectors.requestsPaginationSelector(state)?.total || 0;

  const profilesId = requests
    .map((request: Request): Profile['id'] => request.creatorId)
    .concat(
      requests.map(
        (request: Request): Profile['id'] => request.managerId || '',
      ),
    );

  const profilesFromResults =
    profileSelectors.profileFromMultipleUserIdsSelector(state, profilesId);

  const descendantsIdByOrganizationId =
    organizationSelectors.descendantsIdByOrganizationId(state);

  return {
    descendantsIdByOrganizationId,
    profilesFromResults,
    requests,
    total,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  deleteRequest: requestActions.fetchDeleteRequestsActionCreator,
  loadProfiles: profileActions.fetchListProfilesActionCreator,
  loadRequests: requestActions.fetchListRequestsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
