import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import PropTypes from 'prop-types';
import {
  locationActions,
  locationSelectors,
  locationTypes,
} from '../../../../states/ducks/locations';
import {
  Location,
  PropTypesLocation,
} from '../../../../states/ducks/locations/types';
import { fetchListLocationsActionCreator } from '../../../../states/ducks/locations/actions';
import {
  currentUserLocationsTreesSelector,
  organizationLocationsTreesSelector,
} from '../../../../states/ducks/locations/selectors';
import { Organization } from '../../../../states/ducks/organizations/types';
import { TreePath } from '../../base';
import './IncidentLocationName.scss';

interface IncidentLocationNameProps {
  location: locationTypes.Location | null;
  getLocation: Function;
  locations: Location[];
  loadLocations: Function;
  pathVisible?: boolean;
}

/**
 * @param {Location} location
 * @param {Function} getLocation
 *
 * @return {ReactElement}
 */
const IncidentLocationName: FunctionComponent<IncidentLocationNameProps> = ({
  location,
  getLocation,
  locations,
  loadLocations,
  pathVisible = true,
}): ReactElement => {
  useEffect((): void => {
    if (getLocation && !location) {
      getLocation();
    }
  }, [getLocation, location]);

  useEffect((): void => {
    loadLocations();
  }, [loadLocations]);

  return location ? (
    <>
      {pathVisible ? (
        <TreePath objectId={location.id} trees={locations} />
      ) : null}
      <div>{location.name}</div>
    </>
  ) : (
    <span className="empty-value">Lieu introuvable</span>
  );
};

IncidentLocationName.propTypes = {
  location: locationTypes.PropTypesLocation.isRequired,
  getLocation: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypesLocation.isRequired).isRequired,
  loadLocations: PropTypes.func.isRequired,
  pathVisible: PropTypes.bool,
};

interface MapStateToProps {
  location: locationTypes.Location | null;
  locations: Location[];
}

interface MapDispatchToProps {
  getLocation: Function;
  loadLocations: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    locationId: locationTypes.Location['id'];
    organization?: Organization | null;
  },
): MapStateToProps => ({
  location: locationSelectors.locationFromIdSelector(state, props.locationId),
  locations: props.organization
    ? organizationLocationsTreesSelector(state, props.organization)
    : currentUserLocationsTreesSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  getLocation: locationActions.fetchGetLocationsActionCreator,
  loadLocations: fetchListLocationsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentLocationName);
