import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  contractorActions,
  contractorSelectors,
  contractorTypes,
} from '../../../../states/ducks/contractors';
import { FilterableSelect, Select, SelectOption } from '../index';
import { SelectProps } from './Select';

interface ContractorsSelectProps extends SelectProps {
  contractors: contractorTypes.Contractor[] | null;
  loadContractors: Function;
}

/**
 * @param {Contractor[]|null} contractors
 * @param {Function} loadContractors
 * @param {SelectProps} props
 *
 * @return {ReactElement}
 */
const ContractorsSelect: FunctionComponent<ContractorsSelectProps> = ({
  contractors,
  loadContractors,
  ...props
}): ReactElement => {
  useEffect((): void => {
    loadContractors();
  }, [loadContractors]);

  const selectOptions = contractors ? (
    contractors.map(
      (contractor, i): ReactElement => (
        <SelectOption value={contractor.id} key={i} title={contractor.name}>
          {contractor.name}
        </SelectOption>
      ),
    )
  ) : (
    <></>
  );

  return props.isFilter ? (
    <FilterableSelect {...props}>{selectOptions}</FilterableSelect>
  ) : (
    <Select {...props}>{selectOptions}</Select>
  );
};

ContractorsSelect.propTypes = {
  contractors: PropTypes.arrayOf(
    contractorTypes.PropTypesContractor.isRequired,
  ),
  loadContractors: PropTypes.func.isRequired,
  isFilter: PropTypes.bool,
};

interface MapStateToProps {
  contractors: contractorTypes.Contractor[] | null;
}

interface MapDispatchToProps {
  loadContractors: Function;
}

const mapStateToProps = (
  state: State,
  props: { contractors?: contractorTypes.Contractor[] },
): MapStateToProps => {
  const contractors = props.contractors
    ? props.contractors
    : contractorSelectors.visibleContractorsSelector(state);

  return {
    contractors: contractors,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  loadContractors: contractorActions.fetchListContractorsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorsSelect);
