import { SelectProps, SelectValue } from 'antd/lib/select';
import 'antd/lib/select/style';
import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { locationTypeTypes } from '../../../../states/ducks/locationtypes';
import { locationTypesFromOrganizationSelector } from '../../../../states/ducks/locationtypes/selectors';
import { LocationType } from '../../../../states/ducks/locationtypes/types';
import { State } from '../../../../states/types';
import { Select, SelectOption } from '../index';

type LocationTypesSelectProps = SelectProps<SelectValue> & MapStateToProps;

/**
 * @param {LocationType[]} locationTypes
 * @param {SelectProps<SelectValue>} props
 * @return {ReactElement}
 */
const LocationTypesSelect: FunctionComponent<LocationTypesSelectProps> = ({
  locationTypes,
  ...props
}): ReactElement => {
  const selectOptions = locationTypes.map(
    (locationType, i): ReactElement => (
      <SelectOption value={locationType.id} key={i}>
        {locationType.name}
      </SelectOption>
    ),
  );

  return <Select {...props}>{selectOptions}</Select>;
};

LocationTypesSelect.propTypes = {
  locationTypes: PropTypes.arrayOf(
    locationTypeTypes.PropTypesLocationType.isRequired,
  ).isRequired,
};

interface MapStateToProps {
  locationTypes: LocationType[];
}

const mapStateToProps = (state: State): MapStateToProps => ({
  locationTypes: locationTypesFromOrganizationSelector(state),
});

export default connect(mapStateToProps)(LocationTypesSelect);
