import { State } from '../../../states/types';
import { connect } from 'react-redux';
import IncidentInfo from '../../components/layout/incidents/IncidentInfo';
import {
  incidentActions,
  incidentSelectors,
  incidentTypes,
} from '../../../states/ducks/incidents';
import {
  locationActions,
  locationSelectors,
  locationTypes,
} from '../../../states/ducks/locations';
import { withRouter } from 'react-router';
import React, { ReactElement } from 'react';
import {
  incidentTypeActions,
  incidentTypeTypes,
  incidentTypeSelectors,
} from '../../../states/ducks/incidenttypes';
import { messagesPaginationSelector } from '../../../states/ducks/messages/selectors';
import { Pagination } from '../../../services/search/search';
import { fetchGetFilesActionCreator } from '../../../states/ducks/files/actions';
import { File } from '../../../states/ducks/files/types';
import { filesFromMultipleIdsSelector } from '../../../states/ducks/files/selectors';
import { chronologicalSortBy } from '../../../utils/SortUtils';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface MapStateToProps {
  files: File[];
  pictures: File[];
  incident: incidentTypes.Incident | null;
  isGettingIncident: boolean;
  location: locationTypes.Location | null;
  incidentType: incidentTypeTypes.IncidentType | null;
  totalComments: Pagination['total'];
}

interface MapDispatchToProps {
  getFile: Function;
  getIncident: Function;
  getLocation: Function;
  getIncidentType: Function;
  retypeIncident: Function;
  remarkIncident: Function;
  relocateIncident: Function;
  changeReportingIncident: Function;
  changeServiceOrderNumberIncident: Function;
  deleteIncident: Function;
  addFileIncident: Function;
  addGEDFileIncident: Function;
  deleteFileIncident: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    incidentId: incidentTypes.Incident['id'];
  },
): MapStateToProps => {
  const incident = incidentSelectors.incidentFromIdSelector(
    state,
    props.incidentId,
  );

  const location = incident
    ? locationSelectors.locationFromIdSelector(state, incident.locationId)
    : null;

  const incidentType = incident
    ? incidentTypeSelectors.incidentTypeFromIdSelector(
        state,
        incident.incidentTypeId,
      )
    : null;

  const totalComments = messagesPaginationSelector(state)?.total || 0;

  const allFiles =
    incident && incident.fileIds
      ? filesFromMultipleIdsSelector(state, incident.fileIds)
      : [];

  allFiles.sort((a, b) => chronologicalSortBy(a.uploadedAt, b.uploadedAt));

  const files = allFiles.filter(
    (file) => !file.fileMimeType?.includes('image/'),
  );

  const pictures = allFiles.filter((file) =>
    file.fileMimeType?.includes('image/'),
  );

  return {
    files: files,
    pictures: pictures,
    incident: incident,
    isGettingIncident: incidentSelectors.incidentsIsGettingSelector(state),
    location: location,
    incidentType: incidentType,
    totalComments: totalComments,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getFile: fetchGetFilesActionCreator,
  getIncident: incidentActions.fetchGetIncidentsActionCreator,
  getLocation: locationActions.fetchGetLocationsActionCreator,
  getIncidentType: incidentTypeActions.fetchGetIncidentTypesActionCreator,
  retypeIncident: incidentActions.fetchRetypeIncidentsActionCreator,
  remarkIncident: incidentActions.fetchRemarkIncidentsActionCreator,
  relocateIncident: incidentActions.fetchRelocateIncidentsActionCreator,
  changeReportingIncident:
    incidentActions.fetchChangeReportingIncidentsActionCreator,
  changeServiceOrderNumberIncident:
    incidentActions.fetchChangeServiceOrderNumberIncidentsActionCreator,
  deleteIncident: incidentActions.fetchDeleteIncidentsActionCreator,
  addFileIncident: incidentActions.fetchAddFileIncidentsActionCreator,
  addGEDFileIncident: incidentActions.fetchAddGEDFileIncidentsActionCreator,
  deleteFileIncident: incidentActions.fetchDeleteFileIncidentsActionCreator,
};

const IncidentInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentInfo);

export default withRouter(
  (props: Props): ReactElement => (
    <IncidentInfoContainer incidentId={props.match.params.id} />
  ),
);
