import React, { FunctionComponent, ReactElement } from 'react';
import { Divider as DividerAnt } from 'antd';
import 'antd/lib/divider/style';
import { DividerProps } from 'antd/lib/divider';
import './Divider.scss';

/**
 * Divider component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Divider: FunctionComponent<DividerProps> = ({
  ...props
}): ReactElement => <DividerAnt {...props} />;

export default Divider;
