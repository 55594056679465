import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import { TreeSelect } from '../index';
import { TreeSelectAntWithLimitProps } from './TreeSelect';
import 'antd/lib/tree-select/style';
import {
  Location,
  PropTypesLocation,
} from '../../../../states/ducks/locations/types';
import { fetchListLocationsActionCreator } from '../../../../states/ducks/locations/actions';
import {
  currentUserLocationsTreesSelector,
  organizationLocationsTreesSelector,
} from '../../../../states/ducks/locations/selectors';
import { Organization } from '../../../../states/ducks/organizations/types';

interface LocationsTreeSelectProps
  extends TreeSelectAntWithLimitProps<string | string[]> {
  locations: Location[];
  loadLocations: Function;
}

/**
 * @param {Location[]} locations
 * @param {Function} loadLocations
 * @param {TreeSelectAntWithLimitProps<string | string[]>} props
 * @return {ReactElement}
 */
const LocationsTreeSelect: FunctionComponent<LocationsTreeSelectProps> = ({
  locations,
  loadLocations,
  ...props
}): ReactElement => {
  useEffect((): void => {
    loadLocations();
  }, [loadLocations]);

  return <TreeSelect trees={locations} {...props} />;
};

LocationsTreeSelect.propTypes = {
  locations: PropTypes.arrayOf(PropTypesLocation.isRequired).isRequired,
  loadLocations: PropTypes.func.isRequired,
};

interface MapStateToProps {
  locations: Location[];
}

interface MapDispatchToProps {
  loadLocations: Function;
}

const mapStateToProps = (
  state: State,
  props: { organization?: Organization | null },
): MapStateToProps => ({
  locations: props.organization
    ? organizationLocationsTreesSelector(state, props.organization)
    : currentUserLocationsTreesSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  loadLocations: fetchListLocationsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationsTreeSelect);
