import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface History extends Model {
  userId: string;
  date: string;
  objectType: string;
  objectId: string;
  event: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to: { [key: string]: any };
}

export interface HistoryState {
  histories: History[];
  status: {
    listing: boolean;
  };
}

export const PropTypesHistory = PropTypes.shape({
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
});
