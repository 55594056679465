import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getFile,
  editFileExpireAt,
  editFileName,
  editNote,
  deleteFile,
  listFiles,
} from '../../../services/api';
import {
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
  FetchListRequested,
} from '../common/actions';
import { getActionType } from '../common/types';
import * as actions from './actions';
import { File } from './types';

/**
 * @param {FetchGetRequested} action
 */
export function* getFileSaga(action: FetchGetRequested<File>): SagaIterator {
  try {
    const file: File = yield call(getFile, action.payload);

    yield put(actions.fetchGetFilesSuccessActionCreator(file));

    action.meta.resolve(file);
  } catch (err) {
    yield put(actions.fetchGetFilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchListRequested} action
 */
export function* fetchFileSaga(action?: FetchListRequested): SagaIterator {
  try {
    const response = action
      ? yield call(listFiles, action.payload)
      : yield call(listFiles);

    if (action && action.payload.pagination) {
      yield put(actions.fetchPaginatedListFilesSuccessActionCreator(response));
    } else {
      yield put(actions.fetchListFilesSuccessActionCreator(response));
    }
  } catch (err) {
    yield put(actions.fetchListFilesErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editFileExpireAtSaga(
  action: FetchEditRequested<File>,
): SagaIterator {
  try {
    yield call(editFileExpireAt, action.payload);

    yield put(actions.fetchEditFilesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditFilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editFileNameSaga(
  action: FetchEditRequested<File>,
): SagaIterator {
  try {
    yield call(editFileName, action.payload);

    yield put(actions.fetchEditFilesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditFilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editNoteSaga(action: FetchEditRequested<File>): SagaIterator {
  try {
    yield call(editNote, action.payload);

    yield put(actions.fetchEditFilesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditFilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteFileSaga(
  action: FetchDeleteRequested<File>,
): SagaIterator {
  try {
    if (!Array.isArray(action.payload)) {
      yield call(deleteFile, action.payload);
      yield put(actions.fetchDeleteFilesSuccessActionCreator(action.payload));
    }

    if (Array.isArray(action.payload)) {
      yield all(action.payload.map((id) => call(deleteFile, id)));
      yield all(
        action.payload.map((id) =>
          put(actions.fetchDeleteFilesSuccessActionCreator(id)),
        ),
      );
    }

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteFilesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* filesSagas(): SagaIterator {
  yield takeEvery(getActionType('files', 'GET_REQUESTED'), getFileSaga);
  yield takeEvery(getActionType('files', 'LIST_REQUESTED'), fetchFileSaga);
  yield takeEvery(
    getActionType('files', 'EDIT_EXPIRE_AT_REQUESTED'),
    editFileExpireAtSaga,
  );
  yield takeEvery(
    getActionType('files', 'EDIT_NAME_REQUESTED'),
    editFileNameSaga,
  );
  yield takeEvery(getActionType('files', 'EDIT_NOTE_REQUESTED'), editNoteSaga);
  yield takeEvery(getActionType('files', 'DELETE_REQUESTED'), deleteFileSaga);
}
