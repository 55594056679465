import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import './Change.scss';
import {
  IncidentLocationName,
  IncidentStatusTag,
  IncidentTypeName,
  IncidentProfileName,
  RequestOrganizationName,
} from '../index';
import moment from 'moment';

interface ChangeProps {
  field: string;
  before: string | string[];
  after: string | string[];
}

const DEFAULT_EMPTY_MESSAGE = '{pas de valeur}';

const displayValue = (field: string, value: string): ReactElement => {
  if (typeof value === 'undefined' || value === null) {
    return <span>{DEFAULT_EMPTY_MESSAGE}</span>;
  }

  const momentValue = moment(value, moment.ISO_8601);
  if (momentValue.isValid()) {
    return <span>{momentValue.format('L [à] H[h]mm')}</span>;
  }

  if (field === 'incident-incidentTypeId') {
    return <IncidentTypeName incidentTypeId={value} />;
  }

  if (field === 'incident-locationId') {
    return <IncidentLocationName locationId={value} />;
  }

  if (
    ['incident-userId', 'request-creatorId', 'request-managerId'].includes(
      field,
    )
  ) {
    return <IncidentProfileName userId={value} />;
  }

  if (field === 'incident-status') {
    return <IncidentStatusTag status={value} />;
  }

  if (field === 'request-organizationId') {
    return <RequestOrganizationName organizationId={value} />;
  }

  return <span>{value}</span>;
};

/**
 * @param {string} field
 * @param {string|string[]} before
 * @param {string|string[]} after
 *
 * @return {ReactElement}
 */
const Change: FunctionComponent<ChangeProps> = ({
  field,
  before,
  after,
}): ReactElement => {
  if (Array.isArray(before) || Array.isArray(after)) {
    return <></>;
  }

  return (
    <div className={'app-change'}>
      {displayValue(field, before)}
      <i className={'app-change-arrow'} />
      {displayValue(field, after)}
    </div>
  );
};

Change.propTypes = {
  field: PropTypes.string.isRequired,
  before: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired,
};

export default Change;
