import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  listOrganizations,
  listAdminOrganizations,
  addRootOrganization,
  addNodeOrganization,
  editOrganization,
  deleteOrganization,
  getOrganization,
} from '../../../services/api';
import { getActionType } from '../common/types';

/**
 * @yields {SagaIterator}
 */
export function* fetchOrganizationSaga(): SagaIterator {
  try {
    const organizations: types.Organization[] = yield call(listOrganizations);

    yield put(
      actions.fetchListOrganizationsSuccessActionCreator(organizations),
    );
  } catch (err) {
    yield put(actions.fetchListOrganizationsErrorActionCreator(err.message));
  }
}

/**
 * @yields {SagaIterator}
 */
export function* fetchAdminOrganizationSaga(): SagaIterator {
  try {
    const organizations: types.Organization[] = yield call(
      listAdminOrganizations,
    );

    yield put(
      actions.fetchListOrganizationsSuccessActionCreator(organizations),
    );
  } catch (err) {
    yield put(actions.fetchListOrganizationsErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchAddRequested<Organization>} action
 */
export function* addOrganizationSaga(
  action: FetchAddRequested<types.Organization>,
): SagaIterator {
  try {
    const id: types.Organization['id'] = yield call(
      action.payload.parentId ? addNodeOrganization : addRootOrganization,
      action.payload,
    );

    const organization = {
      ...action.payload,
      id,
    } as types.Organization;

    yield put(actions.fetchAddOrganizationsSuccessActionCreator(organization));

    action.meta.resolve(organization);
  } catch (err) {
    yield put(actions.fetchAddOrganizationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<Organization>} action
 */
export function* editOrganizationSaga(
  action: FetchEditRequested<types.Organization>,
): SagaIterator {
  try {
    yield call(editOrganization, action.payload);

    yield put(
      actions.fetchEditOrganizationsSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditOrganizationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested<Organization>} action
 */
export function* deleteOrganizationSaga(
  action: FetchDeleteRequested<types.Organization>,
): SagaIterator {
  try {
    yield call(deleteOrganization, action.payload);

    yield put(
      actions.fetchDeleteOrganizationsSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteOrganizationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchGetRequested<Organization>} action
 */
export function* getOrganizationSaga(
  action: FetchGetRequested<types.Organization>,
): SagaIterator {
  try {
    const organization: types.Organization = yield call(
      getOrganization,
      action.payload,
    );

    yield put(actions.fetchGetOrganizationsSuccessActionCreator(organization));

    action.meta.resolve(organization);
  } catch (err) {
    yield put(actions.fetchGetOrganizationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* organizationsSagas(): SagaIterator {
  yield takeEvery(
    getActionType('organizations', 'LIST_REQUESTED'),
    fetchOrganizationSaga,
  );
  yield takeEvery(
    getActionType('organizations', 'LIST_ADMIN_REQUESTED'),
    fetchAdminOrganizationSaga,
  );
  yield takeEvery(
    getActionType('organizations', 'ADD_REQUESTED'),
    addOrganizationSaga,
  );
  yield takeEvery(
    getActionType('organizations', 'EDIT_REQUESTED'),
    editOrganizationSaga,
  );
  yield takeEvery(
    getActionType('organizations', 'DELETE_REQUESTED'),
    deleteOrganizationSaga,
  );
  yield takeEvery(
    getActionType('organizations', 'GET_REQUESTED'),
    getOrganizationSaga,
  );
}
