import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {Kpi[]} [state=[]] kpis
 * @param {AnyAction} action
 * @return {Kpi[]}
 */
export const kpiReducer: Reducer<types.Kpi[]> = (
  state: types.Kpi[] = [],
  action: AnyAction,
): types.Kpi[] => {
  return modelReducer('kpis', state, action) as types.Kpi[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('kpis', 'GET_FAILED'):
    case getActionType('kpis', 'GET_SUCCEEDED'):
      return false;
    case getActionType('kpis', 'GET_INCIDENTS_BY_STATUS_REQUESTED'):
    case getActionType('kpis', 'GET_INCIDENT_BY_MONTH_REQUESTED'):
    case getActionType('kpis', 'GET_INCIDENT_BY_MAIN_LOCATIONS_REQUESTED'):
    case getActionType('kpis', 'GET_REQUESTS_BY_MANAGER_REQUESTED'):
    case getActionType('kpis', 'GET_REQUESTS_BY_MONTH_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.KpiState> = combineReducers({
  kpis: kpiReducer,
  status: combineReducers({
    getting: gettingReducer,
  }),
});

export default reducers;
