import { ColumnProps } from 'antd/lib/table';
import PropTypes from 'prop-types';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Pagination } from '../../../../services/search/search';
import { fetchListFilesActionCreator } from '../../../../states/ducks/files/actions';
import {
  filesPaginationSelector,
  visibleFilesSelector,
} from '../../../../states/ducks/files/selectors';
import { File, PropTypesFile } from '../../../../states/ducks/files/types';
import { State } from '../../../../states/types';
import {
  DateTooltip,
  FilterableTable,
  IncidentProfileName,
  InputSearch,
  OrganizationsTreeSelect,
  ProfilesSelect,
  RangePicker,
  Select,
  SelectOption,
  Modal,
  notification,
} from '../../base';
import { ModalProps } from 'antd/lib/modal';
import {
  FilterableSearch,
  initialSearch,
} from '../../base/table/FilterableTable';
import { organizationSelectors } from '../../../../states/ducks/organizations';
import moment, { Moment } from 'moment';
import { Checkbox } from 'antd';
import { fetchListProfilesActionCreator } from '../../../../states/ducks/profiles/actions';
import { UploadFile } from 'antd/lib/upload/interface';

interface GetGEDProps extends ModalProps {
  hideModal: Function;
  descendantsIdByOrganizationId: Record<string, string[]>;
  loadFiles: Function;
  loadProfiles: Function;
  files: File[];
  total: Pagination['total'];
  onGEDUpload?: Function;
  fileListUploaded?: UploadFile[];
}

interface TableFile {
  action: object;
  id: File['id'];
  key: File['id'];
  name: File['name'];
  uploadedAt: File['uploadedAt'];
  userId: File['userId'];
}

const GetGED: FunctionComponent<GetGEDProps> = ({
  hideModal,
  descendantsIdByOrganizationId,
  loadFiles,
  loadProfiles,
  files,
  total,
  visible = false,
  onGEDUpload,
  fileListUploaded,
}): ReactElement => {
  const handleCancel = (): void => {
    hideModal();
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const initialFilesSearch: FilterableSearch = {
    ...initialSearch,
    filters: [
      {
        key: 'expireAt',
        field: 'expireAt',
        operator: '>=',
        value: moment().format(),
        includeNull: true,
      },
    ],
  };

  const [search, setSearch] = useState(initialFilesSearch);

  useEffect((): void => {
    if (loadFiles) {
      loadFiles(search);
    }
  }, [loadFiles, search]);

  useEffect((): void => {
    loadProfiles();
  }, [loadProfiles]);

  const profileName = (userId: string): ReactElement => (
    <IncidentProfileName userId={userId} />
  );

  const dateToolTip = (date: string): ReactElement => (
    <DateTooltip date={date} />
  );

  const columns: ColumnProps<TableFile>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'userId',
      title: 'Ajouté par',
      dataIndex: 'userId',
      render: profileName,
    },
    {
      key: 'uploadedAt',
      title: 'Ajouté le',
      dataIndex: 'uploadedAt',
      render: dateToolTip,
      sorter: true,
    },
  ];

  const fileIdsUploaded = fileListUploaded?.map((file) => file.uid);
  const datas = files
    .map((file) => {
      return {
        id: file.id,
        key: file.id,
        name: file.name,
        uploadedAt: file.uploadedAt,
        userId: file.userId,
        fileMimeType: file.fileMimeType,
        action: {
          id: file.id,
        },
      };
    })
    .filter(
      (file) =>
        !fileIdsUploaded?.includes(file.id) &&
        !file.fileMimeType?.includes('image/'),
    );

  const handleOk = (): void => {
    if (onGEDUpload && selectedRowKeys.length > 0) {
      setConfirmLoading(true);
      const promise = new Promise<void>((resolve, reject): void =>
        onGEDUpload(selectedRowKeys, { resolve, reject }),
      );

      promise
        .then((): void => {
          const message = `Les documents ont bien été ajoutés`;
          notification.success({ message });
          hideModal();
          setConfirmLoading(false);
        })
        .catch((message: string): void => {
          notification.error({ message });
        });
    }
  };

  return (
    <Modal
      title={'GED Intwi'}
      visible={visible}
      onCancel={handleCancel}
      okText={'importer'}
      onOk={handleOk}
      width={1000}
      confirmLoading={confirmLoading}
      okButtonProps={{ disabled: selectedRowKeys.length !== 0 ? false : true }}
    >
      <FilterableTable
        initialSearch={initialFilesSearch}
        onSelectedRowKeysChange={(e): void => setSelectedRowKeys(e)}
        filters={[
          {
            display: <InputSearch placeholder="Rechercher par nom" />,
            field: 'name',
            key: 'search',
            operator: 'LIKE',
            onChange: (e: ChangeEvent<HTMLInputElement>): string[] => [
              e.currentTarget.value,
            ],
            size: 12,
          },
          {
            display: (
              <Select mode={'multiple'} placeholder={'Type'}>
                <SelectOption key={'pdf'} value={'application/pdf'}>
                  PDF
                </SelectOption>
                <SelectOption key={'image'} value={'image/'}>
                  Image
                </SelectOption>
                <SelectOption key={'csv'} value={'text/csv'}>
                  CSV
                </SelectOption>
              </Select>
            ),
            field: 'fileMimeType',
            key: 'type',
            operator: 'LIKE',
          },
          {
            display: <OrganizationsTreeSelect placeholder="Organisation" />,
            field: 'organizationId',
            key: 'organization',
            operator: 'IN',
            onChange: (value: string): string[] => [
              value,
              ...(descendantsIdByOrganizationId[value] || []),
            ],
          },
          {
            display: <ProfilesSelect isFilter placeholder={'Ajouté par'} />,
            field: 'userId',
            key: 'user',
            operator: 'IN',
          },
          {
            display: (
              <RangePicker allowClear={false} placeholder={['Début', 'Fin']} />
            ),
            field: 'uploadedAt',
            key: 'uploadedAt',
            operator: 'BETWEEN',
            onChange: (values: Moment[]): string[] =>
              values.map((value) => value.format()),
            onUpdate: (values: string[]): Moment[] =>
              values.map((value) => moment(value)),
          },
        ]}
        extra={[
          {
            display: (
              <Checkbox
                checked={
                  search.filters.find((filter) => filter.key === 'expireAt') ===
                  undefined
                }
              >
                Afficher les documents expirés
              </Checkbox>
            ),
            field: 'expireAt',
            key: 'expireAt',
            operator: '>=',
            includeNull: true,
            onChange: (e: ChangeEvent<HTMLInputElement>): string =>
              e.target.checked ? '' : moment().format(),
            size: 24,
          },
        ]}
        onSearchChange={setSearch}
        search={search}
        tableProps={{
          columns: columns,
          dataSource: datas,
          pagination: {
            total: total,
          },
        }}
      />
    </Modal>
  );
};

GetGED.propTypes = {
  visible: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  descendantsIdByOrganizationId: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  loadFiles: PropTypes.func.isRequired,
  loadProfiles: PropTypes.func.isRequired,
  // @ts-ignore
  files: PropTypes.arrayOf(PropTypesFile.isRequired).isRequired,
  total: PropTypes.number.isRequired,
  onGEDUpload: PropTypes.func,
  fileListUploaded: PropTypes.arrayOf(PropTypes.any.isRequired),
};

interface MapStateToProps {
  descendantsIdByOrganizationId: Record<string, string[]>;
  files: File[];
  total: Pagination['total'];
}

interface MapDispatchToProps {
  loadFiles: Function;
  loadProfiles: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const files = visibleFilesSelector(state);

  const total = filesPaginationSelector(state)?.total || 0;

  const descendantsIdByOrganizationId =
    organizationSelectors.descendantsIdByOrganizationId(state);

  return {
    descendantsIdByOrganizationId,
    files,
    total,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  loadFiles: fetchListFilesActionCreator,
  loadProfiles: fetchListProfilesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(GetGED);
