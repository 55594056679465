import { create, get, patch, post, remove } from '../base';
import { roleTypes } from '../../../states/ducks/roles';
import { Role } from '../../../states/ducks/roles/types';
import { Permission } from '../../../states/ducks/permissions/types';
import { User } from '../../../states/ducks/users/types';

/**
 * @param {Role} role
 * @return {Promise<string>}
 */
export const addRole = async (
  role: Partial<roleTypes.Role>,
): Promise<roleTypes.Role['id']> => await create(`/v1/iam/roles/add`, role);

/**
 * @return {Promise<Role[]>}
 */
export const listRoles = async (): Promise<roleTypes.Role[]> =>
  await get(`/v1/iam/roles/list`);

/**
 * @param {string} id
 * @return {Promise<Role>}
 */
export const getRole = async (
  id: roleTypes.Role['id'],
): Promise<roleTypes.Role> => await get(`/v1/iam/roles/get/${id}`);

/**
 * @param {Role} role
 * @return {Promise<Role>}
 */
export const getPermissionsForRole = async (
  role: roleTypes.Role,
): Promise<roleTypes.Role> =>
  await get(`/v1/iam/getPermissionsForRole/intwi/${role.id}`);

/**
 * @param {User} user
 * @return {Promise<Role>}
 */
export const getPermissionsForUser = async (user: User): Promise<User> =>
  await get(`/v1/iam/getPermissionsForUser/intwi/${user.id}`);

/**
 * @param {Role} role
 * @return {Promise<string>}
 */
export const editRoleName = async (role: roleTypes.Role): Promise<string> => {
  const { id, name } = role;

  return await patch(`/v1/iam/roles/rename/${id}`, {
    name,
  });
};

/**
 * @param {Role} role
 * @return {Promise<string>}
 */
export const editRoleDescription = async (
  role: roleTypes.Role,
): Promise<string> => {
  const { id, description } = role;

  return await patch(`/v1/iam/roles/changeDescription/${id}`, {
    description,
  });
};

/**
 * @param {Role} role
 * @return {Promise<string>}
 */
export const editRoleStyle = async (role: roleTypes.Role): Promise<string> => {
  const { id, style } = role;

  return await patch(`/v1/iam/roles/changeStyle/${id}`, {
    style,
  });
};

/**
 * @param {object} payload
 * @return {Promise<string>}
 */
export const editRoleAddPermission = async (payload: {
  role: Role;
  permission: Permission;
}): Promise<Response> =>
  await post(`/v1/iam/addPermissionForRole/intwi`, {
    roleId: payload.role.id,
    subject: payload.permission.subject,
    action: payload.permission.action,
  });

/**
 * @param {object} payload
 * @return {Promise<string>}
 */
export const editRoleRemovePermission = async (payload: {
  role: Role;
  permission: Permission;
}): Promise<string> =>
  await remove(
    `/v1/iam/deletePermissionForRole/intwi/${payload.role.id}/${payload.permission.subject}/${payload.permission.action}`,
  );

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteRole = async (id: roleTypes.Role['id']): Promise<string> =>
  await remove(`/v1/iam/roles/delete/${id}`);
