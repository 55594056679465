import { connect } from 'react-redux';
import Incidents from '../../components/layout/incidents/Incidents';
import {
  incidentTypes,
  incidentSelectors,
  incidentActions,
} from '../../../states/ducks/incidents';
import { State } from '../../../states/types';
import * as locationTypes from '../../../states/ducks/locations/types';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../states/ducks/profiles';
import {
  locationActions,
  locationSelectors,
} from '../../../states/ducks/locations';
import {
  incidentTypeSelectors,
  incidentTypeTypes,
} from '../../../states/ducks/incidenttypes';
import {
  organizationSelectors,
  organizationTypes,
} from '../../../states/ducks/organizations';
import { Pagination } from '../../../services/search/search';
import { userActions } from '../../../states/ducks/users';

interface MapStateToProps {
  incidents: incidentTypes.Incident[];
  locationsFromResults: locationTypes.Location[];
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  descendantsIdByOrganizationId: Record<string, string[]>;
  profiles: profileTypes.Profile[];
  profilesFromResults: profileTypes.Profile[];
  incidentTypes: incidentTypeTypes.IncidentType[];
  organizationsFromResults: organizationTypes.Organization[];
  total: Pagination['total'];
}

interface MapDispatchToProps {
  loadIncidents: Function;
  loadLocations: Function;
  loadUsers: Function;
  loadProfiles: Function;
  deleteIncident: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const incidents = incidentSelectors.visibleIncidentsSelector(state);
  const locationIds = incidents
    .filter((incident: incidentTypes.Incident): boolean => {
      return (
        typeof incident.locationId !== 'undefined' &&
        incident.locationId.length > 0
      );
    })
    .map(
      (incident: incidentTypes.Incident): locationTypes.Location['id'] =>
        incident.locationId || '',
    );

  const locationsFromResults =
    locationSelectors.locationFromMultipleIdsSelector(state, locationIds);

  const organizationIds = incidents
    .filter((incident: incidentTypes.Incident): boolean => {
      return (
        typeof incident.organizationId !== 'undefined' &&
        incident.organizationId.length > 0
      );
    })
    .map(
      (
        incident: incidentTypes.Incident,
      ): organizationTypes.Organization['id'] => incident.organizationId || '',
    );

  const organizationsFromResults =
    organizationSelectors.organizationFromMultipleIdsSelector(
      state,
      organizationIds,
    );

  const locationsAndTheirDescendantsIdsSelector =
    locationSelectors.locationsAndTheirDescendantsIdsSelector(state);
  const profilesId = incidents
    .filter((incident: incidentTypes.Incident): boolean => {
      return (
        typeof incident.userId !== 'undefined' && incident.userId.length > 0
      );
    })
    .map(
      (incident: incidentTypes.Incident): profileTypes.Profile['id'] =>
        incident.userId || '',
    );

  const profilesFromResults =
    profileSelectors.profileFromMultipleUserIdsSelector(state, profilesId);

  const profiles = profileSelectors.visibleProfilesSelector(state);

  const incidentTypesId = incidents
    .filter((incident: incidentTypes.Incident): boolean => {
      return (
        typeof incident.incidentTypeId !== 'undefined' &&
        incident.incidentTypeId.length > 0
      );
    })
    .map(
      (
        incident: incidentTypes.Incident,
      ): incidentTypeTypes.IncidentType['id'] => incident.incidentTypeId || '',
    );

  const incidentTypes =
    incidentTypeSelectors.incidentTypesFromMultipleIdsSelector(
      state,
      incidentTypesId,
    );

  const descendantsIdByOrganizationId =
    organizationSelectors.descendantsIdByOrganizationId(state);

  const total =
    incidentSelectors.incidentsPaginationSelector(state)?.total || 0;

  return {
    incidents,
    locationsFromResults,
    locationsAndTheirDescendantsIdsSelector,
    descendantsIdByOrganizationId,
    profiles,
    profilesFromResults,
    incidentTypes,
    organizationsFromResults,
    total,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  loadIncidents: incidentActions.fetchListIncidentsActionCreator,
  loadLocations: locationActions.fetchListLocationsActionCreator,
  loadUsers: userActions.fetchListUsersActionCreator,
  loadProfiles: profileActions.fetchListProfilesActionCreator,
  deleteIncident: incidentActions.fetchDeleteIncidentsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
