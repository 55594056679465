import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import { getActionType } from '../common/types';
import { CurrentUser } from './types';

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetCurrentUserActionCreator = createAction(
  getActionType('currentUser', 'GET_REQUESTED'),
  null,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {CurrentUser} currentUser
 * @return {FetchGetSucceeded}
 */
export const fetchGetCurrentUserSuccessActionCreator = createAction(
  getActionType('currentUser', 'GET_SUCCEEDED'),
  (currentUser: CurrentUser): CurrentUser => currentUser,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetCurrentUserErrorActionCreator = createAction(
  getActionType('currentUser', 'GET_FAILED'),
  (message: string): string => message,
);
