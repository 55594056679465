import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import PropTypes from 'prop-types';
import {
  incidentActions,
  incidentSelectors,
  incidentTypes,
} from '../../../../states/ducks/incidents';
import {
  incidentTypeActions,
  incidentTypeSelectors,
  incidentTypeTypes,
} from '../../../../states/ducks/incidenttypes';
import { Alert, Col, Form, notification, Row } from 'antd';
import { Button, ContractorsSelect, Modal } from '../index';
import 'antd/lib/alert/style';
import './IncidentAcceptationAlert.scss';
import {
  contractorActions,
  contractorSelectors,
  contractorTypes,
} from '../../../../states/ducks/contractors';
import { Contractor } from '../../../../states/ducks/contractors/types';
import { SelectValue } from 'antd/lib/select';
import { InfoCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

interface IncidentAcceptationAlertProps {
  incidentId: incidentTypes.Incident['id'];
  incident: incidentTypes.Incident | null;
  incidentType: incidentTypeTypes.IncidentType | null;
  assignableContractors: contractorTypes.Contractor[];
  getIncident: Function;
  getIncidentType: Function;
  loadContractors: Function;
  acceptIncident: Function;
  rejectIncident: Function;
}

/**
 * @param {string} incidentId
 * @param {Incident} incident
 * @param {IncidentType} incidentType
 * @param {Contractor[]} assignableContractors
 * @param {Function} getIncident
 * @param {Function} getIncidentType
 * @param {Function} loadContractors
 * @param {Function} acceptIncident
 * @param {Function} rejectIncident
 *
 * @return {ReactElement}
 */
const IncidentAcceptationAlert: FunctionComponent<IncidentAcceptationAlertProps> =
  ({
    incidentId,
    incident,
    incidentType,
    assignableContractors,
    getIncident,
    getIncidentType,
    loadContractors,
    acceptIncident,
    rejectIncident,
  }): ReactElement => {
    const [form] = Form.useForm();
    const [contractorChoiceModalVisible, setContractorChoiceModalVisible] =
      useState(false);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);

    useEffect((): void => {
      if (getIncident && !incident) {
        getIncident(incidentId);
      }
    }, [getIncident, incidentId, incident]);

    useEffect((): void => {
      if (getIncidentType && incident && !incidentType) {
        getIncidentType(incident.incidentTypeId);
      }
    }, [getIncidentType, incident, incidentType]);

    useEffect((): void => {
      loadContractors();
    }, [loadContractors]);

    if (!incident || incident.status !== 'reported') {
      return <></>;
    }

    const accept = (): void => {
      incident.status = 'ongoing';
      acceptIncident(incident);

      notification.success({
        message: 'Incident accepté',
      });
    };

    const reject = (): void => {
      incident.status = 'rejected';
      rejectIncident(incident);

      notification.success({
        message: 'Incident refusé',
      });
    };

    return (
      <>
        <Alert
          className="incident-acceptation-alert"
          message={
            <>
              <Row>
                <Col>
                  L’incident est en attente d’acceptation, que souhaitez-vous
                  faire ?
                </Col>
                <Col>
                  <Button
                    onClick={(): void => {
                      setRejectModalVisible(true);
                    }}
                  >
                    Refuser
                  </Button>
                  <Button
                    type="primary"
                    onClick={(): void => {
                      if (assignableContractors.length === 0) {
                        accept();
                      }

                      if (assignableContractors.length === 1) {
                        incident.contractorId = assignableContractors[0].id;
                        accept();
                      }

                      if (assignableContractors.length > 1) {
                        setContractorChoiceModalVisible(true);
                      }
                    }}
                  >
                    Accepter
                  </Button>
                </Col>
              </Row>
            </>
          }
          type="success"
          icon={<InfoCircleFilled />}
          showIcon
        />
        <Modal
          visible={contractorChoiceModalVisible}
          okText={'Affecter'}
          closable={false}
          title={"A quel prestataire souhaitez-vous affecter l'incident ?"}
          onCancel={(): void => setContractorChoiceModalVisible(false)}
          onOk={(): void => {
            form.submit();
          }}
        >
          <Form
            form={form}
            onFinish={(): void => {
              accept();
            }}
          >
            <Form.Item
              label={'Prestataire'}
              name={'contractor'}
              rules={[
                {
                  required: true,
                  message: 'Veuillez renseigner le prestataire',
                },
              ]}
            >
              <ContractorsSelect
                placeholder={'Choisir un prestataire'}
                contractors={assignableContractors}
                onSelect={(contractorId: SelectValue): void => {
                  incident.contractorId =
                    typeof contractorId === 'string' ? contractorId : '';
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={rejectModalVisible}
          okText={'Refuser'}
          closable={false}
          title={'Merci de renseigner le motif de refus pour cet incident :'}
          onCancel={(): void => setRejectModalVisible(false)}
          onOk={(): void => {
            form.submit();
          }}
        >
          <Form
            form={form}
            onFinish={(): void => {
              reject();
            }}
          >
            <Form.Item
              name={'rejectReason'}
              rules={[
                {
                  required: true,
                  message: 'Motif obligatoire !',
                },
              ]}
            >
              <TextArea
                placeholder={'Motif du refus ...'}
                onChange={(event): void => {
                  incident.rejectReason = event.target.value;
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  };

IncidentAcceptationAlert.propTypes = {
  incidentId: PropTypes.string.isRequired,
  incident: incidentTypes.PropTypesIncident,
  incidentType: incidentTypeTypes.PropTypesIncidentType,
  assignableContractors: PropTypes.arrayOf(
    contractorTypes.PropTypesContractor.isRequired,
  ).isRequired,
  getIncident: PropTypes.func.isRequired,
  getIncidentType: PropTypes.func.isRequired,
  loadContractors: PropTypes.func.isRequired,
  acceptIncident: PropTypes.func.isRequired,
  rejectIncident: PropTypes.func.isRequired,
};

interface MapStateToProps {
  incident: incidentTypes.Incident | null;
  incidentType: incidentTypeTypes.IncidentType | null;
  assignableContractors: contractorTypes.Contractor[];
}

interface MapDispatchToProps {
  getIncident: Function;
  getIncidentType: Function;
  loadContractors: Function;
  acceptIncident: Function;
  rejectIncident: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    incidentId: incidentTypes.Incident['id'];
  },
): MapStateToProps => {
  const incident = incidentSelectors.incidentFromIdSelector(
    state,
    props.incidentId,
  );

  const incidentType = incidentTypeSelectors.incidentTypeFromIdSelector(
    state,
    incident ? incident.incidentTypeId : '',
  );

  let assignableContractors: Contractor[] = [];
  if (incident) {
    assignableContractors =
      contractorSelectors.contractorsAssignableToIncidentSelector(
        state,
        incident,
      );
  }

  return {
    incident: incident,
    incidentType: incidentType,
    assignableContractors: assignableContractors,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getIncident: incidentActions.fetchGetIncidentsActionCreator,
  getIncidentType: incidentTypeActions.fetchGetIncidentTypesActionCreator,
  loadContractors: contractorActions.fetchListContractorsActionCreator,
  acceptIncident: incidentActions.fetchAcceptIncidentsActionCreator,
  rejectIncident: incidentActions.fetchRejectIncidentsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentAcceptationAlert);
