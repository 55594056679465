import { connect } from 'react-redux';
import { contractorActions } from '../../../../states/ducks/contractors';
import AddContractor from '../../../components/layout/modal/contractor/AddContractor';

interface MapDispatchToProps {
  addContractor: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  addContractor: contractorActions.fetchAddContractorsActionCreator,
};

export default connect(null, mapDispatchToProps)(AddContractor);
