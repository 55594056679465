import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  listLocations,
  addLocation,
  editLocationDesignation,
  editLocationOrganization,
  editLocationTypeId,
  editLocationAddress,
  editLocationRemark,
  deleteLocation,
  getLocation,
  deleteFile,
  addFile,
  removeFileLocation,
  addFilesLocation,
} from '../../../services/api';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { AddressComponentsInGeocoderResult } from './types';
import { camelCase } from 'change-case';
import { getActionType } from '../common/types';
import { getCurrentUserId } from '../../../services/authenticate';

/**
 * @param {types.Location} location
 * @return {Promise<void>}
 */
function addAddressComponentsToLocation(
  location: types.Location,
): Promise<void> | undefined {
  if (
    !location.addressComponents ||
    !location.addressComponents.formattedAddress
  ) {
    return;
  }

  return geocodeByAddress(location.addressComponents.formattedAddress)
    .then((result): Promise<google.maps.LatLngLiteral> => {
      if (result.length > 0 && location.addressComponents !== undefined) {
        result[0].address_components.forEach((component) => {
          AddressComponentsInGeocoderResult.filter((propertyName) =>
            component.types
              .map((type) => camelCase(type))
              .includes(propertyName),
          ).forEach((propertyName) => {
            if (!location.addressComponents) {
              return;
            }
            location.addressComponents[propertyName] = component.short_name;
          });
        });

        return getLatLng(result[0]);
      }

      throw new Error();
    })
    .then(({ lat, lng }): void => {
      if (!location.addressComponents) {
        return;
      }

      location.addressComponents.latitude = lat;
      location.addressComponents.longitude = lng;
    })
    .catch();
}

/**
 * @yields {SagaIterator}
 */
export function* fetchLocationSaga(): SagaIterator {
  try {
    const locations: types.Location[] = yield call(listLocations);

    yield put(actions.fetchListLocationsSuccessActionCreator(locations));
  } catch (err) {
    yield put(actions.fetchListLocationsErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchAddRequested<types.Location>} action
 */
export function* addLocationSaga(
  action: FetchAddRequested<types.Location>,
): SagaIterator {
  try {
    if (
      action.payload.addressComponents &&
      action.payload.addressComponents.formattedAddress
    ) {
      yield call(addAddressComponentsToLocation, action.payload);
    }

    const id: types.Location['id'] = yield call(addLocation, action.payload);

    const location = {
      ...action.payload,
      id,
    } as types.Location;

    yield put(actions.fetchAddLocationsSuccessActionCreator(location));

    action.meta.resolve(location);
  } catch (err) {
    yield put(actions.fetchAddLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<types.Location>} action
 */
export function* editLocationDesignationSaga(
  action: FetchEditRequested<types.Location>,
): SagaIterator {
  try {
    yield call(editLocationDesignation, action.payload);

    yield put(actions.fetchEditLocationsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<types.Location>} action
 */
export function* editLocationOrganizationSaga(
  action: FetchEditRequested<types.Location>,
): SagaIterator {
  try {
    yield call(editLocationOrganization, action.payload);

    yield put(actions.fetchEditLocationsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<types.Location>} action
 */
export function* editLocationTypeIdSaga(
  action: FetchEditRequested<types.Location>,
): SagaIterator {
  try {
    yield call(editLocationTypeId, action.payload);

    yield put(actions.fetchEditLocationsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<types.Location>} action
 */
export function* editLocationAddressSaga(
  action: FetchEditRequested<types.Location>,
): SagaIterator {
  try {
    if (
      action.payload.addressComponents &&
      action.payload.addressComponents.formattedAddress
    ) {
      yield call(addAddressComponentsToLocation, action.payload);
    }

    yield call(editLocationAddress, action.payload);

    yield put(actions.fetchEditLocationsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested<types.Location>} action
 */
export function* editLocationRemarkSaga(
  action: FetchEditRequested<types.Location>,
): SagaIterator {
  try {
    yield call(editLocationRemark, action.payload);

    yield put(actions.fetchEditLocationsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addFileLocationSaga(
  action: actions.FetchAddFileRequested,
): SagaIterator {
  try {
    const location = action.payload.location;
    const file = action.payload.file;

    const fileId = yield call(addFile, {
      file: file,
      organizationId: location.organizationId || '',
      userId: getCurrentUserId() || '',
      objectId: location.id || '',
      objectType: 'location',
    });

    yield call(addFilesLocation, location, [fileId]);

    if (!location.fileIds) {
      location.fileIds = [];
    }
    location.fileIds.push(fileId);

    yield put(
      actions.fetchEditLocationsSuccessActionCreator(action.payload.location),
    );

    action.meta.resolve(action.payload.file);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(action.payload.file);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addGEDFileLocationSaga(
  action: actions.FetchAddGEDFileRequested,
): SagaIterator {
  try {
    const location = action.payload.location;
    const fileIds = action.payload.fileIds;

    yield call(addFilesLocation, location, fileIds);

    if (!location.fileIds) {
      location.fileIds = [];
    }

    location.fileIds = location.fileIds.concat(fileIds);

    yield put(
      actions.fetchEditLocationsSuccessActionCreator(action.payload.location),
    );

    action.meta.resolve(action.payload.fileIds);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(action.payload.fileIds);
  }
}

/**
 * @param {FetchDeleteFileRequested} action
 */
export function* deleteFileLocationSaga(
  action: actions.FetchDeleteFileRequested,
): SagaIterator {
  try {
    const fileId = action.payload.fileId;
    const location = action.payload.location;

    yield call(deleteFile, fileId);

    yield call(removeFileLocation, location, fileId);

    if (!location.fileIds) {
      location.fileIds = [];
    }
    location.fileIds = location.fileIds.filter((id) => id !== fileId);

    yield put(
      actions.fetchEditLocationsSuccessActionCreator(action.payload.location),
    );

    action.meta.resolve(action.payload.location);
  } catch (err) {
    yield put(actions.fetchEditLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested<types.Location>} action
 */
export function* deleteLocationSaga(
  action: FetchDeleteRequested<types.Location>,
): SagaIterator {
  try {
    yield call(deleteLocation, action.payload);

    yield put(actions.fetchDeleteLocationsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchGetRequested<types.Location>} action
 */
export function* getLocationSaga(
  action: FetchGetRequested<types.Location>,
): SagaIterator {
  try {
    const location: types.Location = yield call(getLocation, action.payload);

    yield put(actions.fetchGetLocationsSuccessActionCreator(location));

    action.meta.resolve(location);
  } catch (err) {
    yield put(actions.fetchGetLocationsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* locationsSagas(): SagaIterator {
  yield takeEvery(
    getActionType('locations', 'LIST_REQUESTED'),
    fetchLocationSaga,
  );
  yield takeEvery(getActionType('locations', 'ADD_REQUESTED'), addLocationSaga);
  yield takeEvery(
    getActionType('locations', 'EDIT_DESIGNATION_REQUESTED'),
    editLocationDesignationSaga,
  );
  yield takeEvery(
    getActionType('locations', 'EDIT_ORGANIZATIONS_REQUESTED'),
    editLocationOrganizationSaga,
  );
  yield takeEvery(
    getActionType('locations', 'EDIT_TYPE_ID_REQUESTED'),
    editLocationTypeIdSaga,
  );
  yield takeEvery(
    getActionType('locations', 'EDIT_ADDRESS_REQUESTED'),
    editLocationAddressSaga,
  );
  yield takeEvery(
    getActionType('locations', 'EDIT_REMARK_REQUESTED'),
    editLocationRemarkSaga,
  );
  yield takeEvery(
    getActionType('locations', 'ADD_GED_FILE_REQUESTED'),
    addGEDFileLocationSaga,
  );
  yield takeEvery(
    getActionType('locations', 'DELETE_FILE_REQUESTED'),
    deleteFileLocationSaga,
  );
  yield takeEvery(
    getActionType('locations', 'DELETE_REQUESTED'),
    deleteLocationSaga,
  );
  yield takeEvery(getActionType('locations', 'GET_REQUESTED'), getLocationSaga);
}
