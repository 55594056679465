import { Condition, Subscription } from '../states/ducks/subscriptions/types';
import { User } from '../states/ducks/users/types';

export type AvailableSubscription = {
  notificationKeys: string[];
  label: string;
  conditions: Condition[];
};

export const getAvailableSubscriptionsByUser = (
  user: User,
): AvailableSubscription[] => [
  {
    notificationKeys: ['incidentReportedMail'],
    label: 'Être informé lorsqu’un incident est signalé',
    conditions: [
      {
        field:
          user.visibleLocationIds && user.visibleLocationIds.length > 0
            ? 'locationId'
            : 'organizationId',
        operator: 'IN',
        value:
          user.visibleLocationIds && user.visibleLocationIds.length > 0
            ? user.visibleLocationIds
            : user.visibleOrganizationIds,
      },
      {
        field: 'userId',
        operator: '!=',
        value: user.id,
      },
    ],
  },
  {
    notificationKeys: ['requestOpenedMail'],
    label: 'Être notifié lors d’une nouvelle demande',
    conditions: [
      {
        field: 'organizationId',
        operator: 'IN',
        value: user.visibleOrganizationIds,
      },
      {
        field: 'creatorId',
        operator: '!=',
        value: user.id,
      },
    ],
  },
  {
    notificationKeys: ['messagePostedMail'],
    label: "Être notifié lorsqu'une réponse est postée sur une demande",
    conditions: [
      {
        field: 'organizationId',
        operator: 'IN',
        value: user.visibleOrganizationIds,
      },
      {
        field: 'creatorId',
        operator: '!=',
        value: user.id,
      },
    ],
  },
];

export const getSubscriptionIdOfAnAvailableSubscription = (
  availableSubscription: AvailableSubscription,
  subscriptions: Subscription[],
): string | undefined =>
  subscriptions.find(
    (subscription): boolean =>
      JSON.stringify(subscription.notificationKeys) ===
      JSON.stringify(availableSubscription.notificationKeys),
  )?.id;
