import { UploadFile } from 'antd/lib/upload/interface';
import PropTypes from 'prop-types';
import { Model } from '../common/types';
import { Pagination } from '../../../services/search/search';

export interface Incident extends Model {
  number: string;
  status: string;
  locationId: string;
  incidentTypeId: string;
  organizationId: string;
  userId: string;
  reportedAt: string;
  createdAt: string;
  updatedAt: string;
  remark?: string | null;
  contractorId?: string | null;
  rejectReason?: string | null;
  acknowledge?: boolean | null;
  serviceOrderNumber?: string | null;
  approveUserIds?: string[] | null;
  fileIds?: string[] | null;
  files?: UploadFile[] | null;
  pictures?: UploadFile[] | null;
}

export interface IncidentState {
  incidents: Incident[];
  pagination: Pagination | null;
  status: {
    reporting: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
  };
}

export const PropTypesIncident = PropTypes.shape({
  id: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  incidentTypeId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  reportedAt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  remark: PropTypes.string,
  contractorId: PropTypes.string,
  rejectReason: PropTypes.string,
  acknowledge: PropTypes.bool,
  serviceOrderNumber: PropTypes.string,
  approveUserIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  fileIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  files: PropTypes.arrayOf(PropTypes.any.isRequired),
  pictures: PropTypes.arrayOf(PropTypes.any.isRequired),
});
