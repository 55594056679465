import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Redirect } from 'react-router';
import { userTypes } from '../../../../states/ducks/users';
import { profileTypes } from '../../../../states/ducks/profiles';
import { organizationTypes } from '../../../../states/ducks/organizations';
import { roleTypes } from '../../../../states/ducks/roles';
import PropTypes from 'prop-types';
import {
  Card,
  PageHeader,
  Skeleton,
  Row,
  Col,
  EditableAvatar,
  FormItem,
  EditableField,
  Field,
  Input,
  InputPassword,
  OrganizationsTreeSelect,
  Subscriptions,
  RoleTag,
} from '../../base';
import passwordsComparison from '../../base/field/PasswordsComparison';
import { Profile } from '../../../../states/ducks/profiles/types';
import { User } from '../../../../states/ducks/users/types';
import { Meta } from '../../../../states/ducks/common/actions';

interface UserInfoProps {
  userId: userTypes.User['id'];
  user: userTypes.User | null;
  profile: profileTypes.Profile | null;
  organization: organizationTypes.Organization | null;
  role: roleTypes.Role | null;
  isFetchingUser: boolean;
  isFetchingProfile: boolean;
  getUser: Function;
  getProfile: Function;
  getOrganization: Function;
  editUserInformation: Function;
  editUserEmail: Function;
  editUserPassword: Function;
  editUserOrganizations: Function;
  editProfileInformation: Function;
  editProfileEmail: Function;
  editProfileOrganizations: Function;
}

const UserInfo: FunctionComponent<UserInfoProps> = ({
  userId,
  user,
  profile,
  organization,
  role,
  isFetchingUser,
  isFetchingProfile,
  getUser,
  getProfile,
  getOrganization,
  editUserInformation,
  editUserEmail,
  editUserPassword,
  editUserOrganizations,
  editProfileInformation,
  editProfileEmail,
  editProfileOrganizations,
}): ReactElement => {
  const [activeTab, setActiveTab] = useState('user-info-tab-1');
  const [hasGotUser, setHasGotUser] = useState(false);
  const [hasGotProfile, setHasGotProfile] = useState(false);
  const { compareToFirstPassword, validateToNextPassword } =
    passwordsComparison();

  useEffect((): void => {
    getUser(userId);
  }, [getUser, userId]);

  useEffect((): void => {
    if (isFetchingUser) {
      setHasGotUser(true);
    }
  }, [isFetchingUser]);

  useEffect((): void => {
    if (isFetchingProfile) {
      setHasGotProfile(true);
    }
  }, [isFetchingProfile]);

  useEffect((): void => {
    if (getProfile && userId) {
      getProfile(userId);
    }
  }, [getProfile, userId]);

  useEffect((): void => {
    if (user && user.organizationIds.length > 0) {
      getOrganization(user.organizationIds[0]);
    }
  }, [getOrganization, user]);

  if (
    hasGotUser &&
    !isFetchingUser &&
    !user &&
    hasGotProfile &&
    !isFetchingProfile &&
    !profile
  ) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  const avatarField = profile ? (
    <FormItem label={'Photo de profil'} required>
      <EditableAvatar size={120} profileId={profile.id} />
    </FormItem>
  ) : (
    <></>
  );
  const fullNameField = profile ? (
    <EditableField
      object={[profile, user]}
      label="Nom"
      content={`${profile.firstName} ${profile.lastName}`}
      editedFieldsOptions={[
        {
          field: 'firstName',
          label: 'Prénom',
          content: <Input placeholder="Saisissez un prénom" />,
          required: true,
          initialValue: profile.firstName,
          colSize: 12,
        },
        {
          field: 'lastName',
          label: 'Nom',
          content: <Input placeholder="Saisissez un nom" />,
          required: true,
          initialValue: profile.lastName,
          colSize: 12,
        },
      ]}
      onSubmit={[editProfileInformation, editUserInformation]}
      successMessage="Le nom a bien été modifié"
    />
  ) : (
    ''
  );
  const emailField =
    profile && user ? (
      <EditableField
        object={[profile, user]}
        label="Email"
        content={user.email}
        editedFieldsOptions={[
          {
            field: 'email',
            label: 'Email',
            content: <Input placeholder="Saisissez un e-mail" />,
            required: true,
            initialValue: profile.email,
            colSize: 24,
            type: 'email',
            invalidMessage: "L'e-mail n'est pas valide",
          },
        ]}
        onSubmit={[editProfileEmail, editUserEmail]}
        successMessage="L'email a bien été modifié"
      />
    ) : (
      ''
    );
  const passwordField = user ? (
    <EditableField
      object={user}
      label="Mot de passe"
      content={`************`}
      editedFieldsOptions={[
        {
          field: 'password',
          label: 'Mot de passe',
          content: <InputPassword placeholder="Saisissez un mot de passe" />,
          required: true,
          colSize: 12,
          validator: validateToNextPassword,
        },
        {
          field: 'passwordConfirm',
          label: 'Confirmation',
          content: <InputPassword placeholder="Confirmez le mot de passe" />,
          required: true,
          colSize: 12,
          validator: compareToFirstPassword,
        },
      ]}
      onSubmit={editUserPassword}
      successMessage="Le mot de passe a bien été modifié"
    />
  ) : (
    ''
  );
  const roleField = user ? (
    <Field label="Rôle" content={role ? <RoleTag roleId={role.id} /> : ''} />
  ) : (
    ''
  );
  const organizationField = profile ? (
    <EditableField
      object={[profile, user]}
      label="Organisation"
      content={organization ? organization.name : ''}
      editedFieldsOptions={[
        {
          field: 'organizationId',
          label: 'Organisation',
          content: <OrganizationsTreeSelect />,
          required: true,
          initialValue: profile.organizationIds,
          colSize: 24,
        },
      ]}
      onSubmit={[
        (values: Profile & { organizationId: string }, meta: Meta): void => {
          values.organizationIds = [values.organizationId];
          editProfileOrganizations(values);
          meta.resolve();
        },
        (values: User & { organizationId: string }, meta: Meta): void => {
          values.organizationIds = [values.organizationId];
          editUserOrganizations(values);
          meta.resolve();
        },
      ]}
      successMessage="L'organisation a bien été modifiée"
    />
  ) : (
    ''
  );
  const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  const phoneField = profile ? (
    <EditableField
      object={profile}
      label="Téléphone"
      content={profile.phoneNumber || ''}
      editedFieldsOptions={[
        {
          field: 'phoneNumber',
          label: 'Téléphone',
          content: <Input placeholder="Saisissez un numéro" />,
          initialValue: profile.phoneNumber,
          colSize: 24,
          invalidMessage: 'Numéro de téléphone incorrect',
          pattern: new RegExp(regex),
        },
      ]}
      onSubmit={editProfileInformation}
      successMessage="Le numéro a bien été modifié"
    />
  ) : (
    ''
  );
  const addressField = profile ? (
    <EditableField
      object={profile}
      label="Adresse"
      content={
        profile.address || profile.address2 ? (
          <>
            {profile.address}
            <br />
            {profile.address2}
          </>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: 'address',
          label: 'Adresse',
          content: (
            <Input placeholder="Saisissez une adresse (n°, voie, cp, ville...)" />
          ),
          initialValue: profile.address,
        },
        {
          field: 'address2',
          label: 'Complément',
          content: <Input placeholder="Saisissez un complément d'adresse" />,
          initialValue: profile.address2,
        },
      ]}
      onSubmit={editProfileInformation}
      successMessage="L'adresse a bien été modifiée"
    />
  ) : (
    ''
  );

  const contentList = {
    'user-info-tab-1': (
      <>
        <Row>
          <Col span={8}>{avatarField}</Col>
          <Col span={8} offset={4}>
            <Row>
              <Col span={24}>{organizationField}</Col>
            </Row>
            <Row>
              <Col span={24}>{roleField}</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={8}>{fullNameField}</Col>
          <Col span={8} offset={4}>
            {passwordField}
          </Col>
        </Row>
        <Row>
          <Col span={8}>{emailField}</Col>
        </Row>
        <Row>
          <Col span={8}>{phoneField}</Col>
        </Row>
        <Row>
          <Col span={8}>{addressField}</Col>
        </Row>
      </>
    ),
    'user-info-tab-2': (
      <Row>
        <Col span={24}>
          <Subscriptions />
        </Col>
      </Row>
    ),
  };

  return (
    <Skeleton loading={isFetchingUser && isFetchingProfile}>
      <PageHeader title="Paramètres du compte" />
      <Card
        className="section"
        tabList={[
          {
            key: 'user-info-tab-1',
            tab: 'Informations générales',
          },
          {
            key: 'user-info-tab-2',
            tab: 'Notifications',
          },
        ]}
        activeTabKey={activeTab}
        onTabChange={(key): void => setActiveTab(key)}
      >
        {
          // @ts-ignore
          contentList[activeTab]
        }
      </Card>
    </Skeleton>
  );
};

UserInfo.propTypes = {
  userId: PropTypes.string.isRequired,
  user: userTypes.PropTypesUser,
  profile: profileTypes.PropTypesProfile,
  organization: organizationTypes.PropTypesOrganization,
  role: roleTypes.PropTypesRole,
  isFetchingUser: PropTypes.bool.isRequired,
  isFetchingProfile: PropTypes.bool.isRequired,
  getUser: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  editUserInformation: PropTypes.func.isRequired,
  editUserEmail: PropTypes.func.isRequired,
  editUserPassword: PropTypes.func.isRequired,
  editUserOrganizations: PropTypes.func.isRequired,
  editProfileInformation: PropTypes.func.isRequired,
  editProfileEmail: PropTypes.func.isRequired,
  editProfileOrganizations: PropTypes.func.isRequired,
};

export default UserInfo;
