import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  addLocationType,
  listLocationTypes,
  getLocationType,
  editLocationTypeDesignation,
  editMainLocationType,
  editQuickAccessLocationType,
  deleteLocationType,
} from '../../../services/api';
import { getActionType } from '../common/types';

/**
 * @param {FetchAddRequested} action
 */
export function* addLocationTypeSaga(
  action: FetchAddRequested<types.LocationType>,
): SagaIterator {
  try {
    const id: types.LocationType['id'] = yield call(
      addLocationType,
      action.payload,
    );

    const locationType = {
      ...action.payload,
      id,
    } as types.LocationType;

    yield put(actions.fetchAddLocationTypesSuccessActionCreator(locationType));

    action.meta.resolve(locationType);
  } catch (err) {
    yield put(actions.fetchAddLocationTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* fetchLocationTypeSaga(): SagaIterator {
  try {
    const locationTypes: types.LocationType[] = yield call(listLocationTypes);

    yield put(
      actions.fetchListLocationTypesSuccessActionCreator(locationTypes),
    );
  } catch (err) {
    yield put(actions.fetchListLocationTypesErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getLocationTypeSaga(
  action: FetchGetRequested<types.LocationType>,
): SagaIterator {
  try {
    const locationType: types.LocationType = yield call(
      getLocationType,
      action.payload,
    );

    yield put(actions.fetchGetLocationTypesSuccessActionCreator(locationType));

    action.meta.resolve(locationType);
  } catch (err) {
    yield put(actions.fetchGetLocationTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editLocationTypeDesignationSaga(
  action: FetchEditRequested<types.LocationType>,
): SagaIterator {
  try {
    yield call(editLocationTypeDesignation, action.payload);

    yield put(
      actions.fetchEditLocationTypesSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editMainLocationTypeSaga(
  action: FetchEditRequested<types.LocationType>,
): SagaIterator {
  try {
    yield call(editMainLocationType, action.payload);

    yield put(
      actions.fetchEditLocationTypesSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editQuickAccessLocationTypeSaga(
  action: FetchEditRequested<types.LocationType>,
): SagaIterator {
  try {
    yield call(editQuickAccessLocationType, action.payload);

    yield put(
      actions.fetchEditLocationTypesSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditLocationTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteLocationTypeSaga(
  action: FetchDeleteRequested<types.LocationType>,
): SagaIterator {
  try {
    yield call(deleteLocationType, action.payload);

    yield put(
      actions.fetchDeleteLocationTypesSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteLocationTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* locationTypesSagas(): SagaIterator {
  yield takeEvery(
    getActionType('locationtypes', 'ADD_REQUESTED'),
    addLocationTypeSaga,
  );
  yield takeEvery(
    getActionType('locationtypes', 'LIST_REQUESTED'),
    fetchLocationTypeSaga,
  );
  yield takeEvery(
    getActionType('locationtypes', 'GET_REQUESTED'),
    getLocationTypeSaga,
  );
  yield takeEvery(
    getActionType('locationtypes', 'EDIT_DESIGNATION_REQUESTED'),
    editLocationTypeDesignationSaga,
  );
  yield takeEvery(
    getActionType('locationtypes', 'EDIT_MAIN_REQUESTED'),
    editMainLocationTypeSaga,
  );
  yield takeEvery(
    getActionType('locationtypes', 'EDIT_QUICK_ACCESS_REQUESTED'),
    editQuickAccessLocationTypeSaga,
  );
  yield takeEvery(
    getActionType('locationtypes', 'DELETE_REQUESTED'),
    deleteLocationTypeSaga,
  );
}
