import { Table } from 'antd';
import PropTypes from 'prop-types';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import { fetchListSubscriptionsActionCreator } from '../../../../states/ducks/subscriptions/actions';
import {
  subscriptionsFromCurrentUserIdSelector,
  subscriptionsFromUserIdSelector,
} from '../../../../states/ducks/subscriptions/selectors';
import {
  PropTypesSubscription,
  Subscription,
} from '../../../../states/ducks/subscriptions/types';
import { State } from '../../../../states/types';
import { userFromIdSelector } from '../../../../states/ducks/users/selectors/usersSelectors';
import { PropTypesUser, User } from '../../../../states/ducks/users/types';
import {
  AvailableSubscription,
  getAvailableSubscriptionsByUser,
  getSubscriptionIdOfAnAvailableSubscription,
} from '../../../../utils/SubscriptionUtils';
import { DataWithFilters, Filter, InputSearch } from '../index';
import './Subscriptions.scss';
import SubscriptionSwitch from './SubscriptionSwitch';

export interface SubscriptionData extends AvailableSubscription {
  id?: Subscription['id'];
}

interface Props {
  loadSubscriptions: Function;
  subscriptions: Subscription[];
  user: CurrentUser | User | null;
}
/**
 * @param {Function} loadSubscriptions
 * @param {Subscription[]} subscriptions
 * @param {User} user
 *
 * @return {ReactElement}
 */

const Subscriptions: FunctionComponent<Props> = ({
  loadSubscriptions,
  subscriptions,
  user,
}): ReactElement => {
  useEffect((): void => {
    if (loadSubscriptions && user) {
      loadSubscriptions(user.id);
    }
  }, [loadSubscriptions, user]);

  if (!user) {
    return <></>;
  }

  const dataSource: SubscriptionData[] = getAvailableSubscriptionsByUser(
    user,
  ).map((availableSubscription) => ({
    ...availableSubscription,
    id: getSubscriptionIdOfAnAvailableSubscription(
      availableSubscription,
      subscriptions,
    ),
  }));

  const subscriptionSwitch = (
    text: string,
    record: SubscriptionData,
  ): ReactElement => (
    <SubscriptionSwitch subscription={record} userId={user.id} />
  );

  const columns = [
    {
      title: '',
      dataIndex: 'label',
      width: '50%',
    },
    {
      title: 'Notification par mail',
      dataIndex: 'mail',
      width: '15%',
      render: subscriptionSwitch,
    },
    {
      title: '',
      dataIndex: '',
      width: '35%',
    },
  ];

  return (
    <DataWithFilters
      dataSource={dataSource}
      displayComponent={
        <Table
          columns={columns}
          pagination={false}
          className="app-subscriptions-table"
        />
      }
    >
      <Filter
        component={<InputSearch />}
        filterName={'search'}
        filter={(value: string, record: { [key: string]: string }): boolean => {
          const columns = ['label'];
          const fields: string[] = [];

          columns.forEach((fieldName: string): void => {
            fields.push(String(record[fieldName] || ''));
          });

          for (const field of fields) {
            if (field.search(new RegExp(value, 'i')) >= 0) {
              return true;
            }
          }

          return false;
        }}
        onChangeFormatValue={(e: ChangeEvent<HTMLInputElement>): string[] => [
          e.currentTarget.value,
        ]}
      />
    </DataWithFilters>
  );
};

Subscriptions.propTypes = {
  loadSubscriptions: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypesSubscription.isRequired).isRequired,
  user: PropTypes.oneOfType([
    PropTypesCurrentUser.isRequired,
    PropTypesUser.isRequired,
  ]),
};

Subscriptions.displayName = 'Subscriptions';

interface MapStateToProps {
  subscriptions: Subscription[];
  user: CurrentUser | User | null;
}

interface MapDispatchToProps {
  loadSubscriptions: Function;
}

const mapStateToProps = (
  state: State,
  props: { userId?: User['id'] },
): MapStateToProps => ({
  subscriptions: props.userId
    ? subscriptionsFromUserIdSelector(state, props.userId)
    : subscriptionsFromCurrentUserIdSelector(state),
  user: props.userId
    ? userFromIdSelector(state, props.userId)
    : currentUserSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  loadSubscriptions: fetchListSubscriptionsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
