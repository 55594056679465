import React, { FunctionComponent, ReactElement } from 'react';
import { Tabs as TabsAnt } from 'antd';
import 'antd/lib/tabs/style';
import { TabPaneProps } from 'antd/lib/tabs';

/**
 * @param {any} props empty props
 * @return {ReactElement}
 */
const TabPane: FunctionComponent<TabPaneProps> = ({
  ...props
}): ReactElement => <TabsAnt.TabPane {...props} />;

TabPane.propTypes = {};

export default TabPane;
