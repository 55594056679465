import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Button,
  Skeleton,
  Empty,
  EditableField,
  Input,
  TextArea,
  LocationTypesSelect,
} from '../../../base';
import { organizationTypes } from '../../../../../states/ducks/organizations';
import { useGet } from '../helper';
import { locationTypes } from '../../../../../states/ducks/locations';
import { locationTypeTypes } from '../../../../../states/ducks/locationtypes';
import { EyeOutlined } from '@ant-design/icons';

interface PropsInfoLocation {
  visible: boolean;
  hideDrawer: Function;
  organization: organizationTypes.Organization | null;
  location: locationTypes.Location | null;
  getLocation: Function;
  getOrganization: Function;
  locationTypes: locationTypeTypes.LocationType[];
  locationId: string | null;
  isFetchingLocation: boolean;
  isFetchingOrganization: boolean;
  useLocationReducer: Function;
  editLocationDesignation: Function;
  editLocationOrganization: Function;
  editLocationTypeId: Function;
  editLocationAddress: Function;
  editLocationRemark: Function;
  redirectToLocation: Function;
}

const InfoLocation: FunctionComponent<PropsInfoLocation> = ({
  hideDrawer,
  visible,
  organization,
  getLocation,
  getOrganization,
  locationTypes,
  locationId,
  location,
  isFetchingLocation,
  isFetchingOrganization,
  useLocationReducer,
  editLocationDesignation,
  editLocationOrganization,
  editLocationTypeId,
  editLocationAddress,
  editLocationRemark,
  redirectToLocation,
}): ReactElement => {
  const onClose = (): void => hideDrawer();

  useGet({
    id: locationId,
    isFetching: isFetchingLocation,
    visible,
    apiGet: getLocation,
    object: location,
    description: 'la récupération du lieu a échoué',
  });

  const designationField = location ? (
    <EditableField
      object={location}
      label="Désignation"
      content={
        location.code ? `[${location.code}] ${location.name}` : location.name
      }
      editedFieldsOptions={[
        {
          field: 'name',
          label: 'Nom',
          content: <Input />,
          required: true,
          initialValue: location.name,
          colSize: 12,
        },
        {
          field: 'code',
          label: 'Code',
          content: <Input />,
          required: true,
          initialValue: location.code,
          colSize: 12,
        },
      ]}
      onSubmit={editLocationDesignation}
      successMessage="La désignation a bien été modifiée"
    />
  ) : (
    ''
  );
  let locationTypeName = '';
  for (let index = 0; index < locationTypes.length; index++) {
    if (location && locationTypes[index].id === location.locationTypeId) {
      locationTypeName = locationTypes[index].name;
      break;
    }
  }
  const locationTypeValue =
    location !== null && typeof location.locationTypeId !== 'undefined'
      ? locationTypeName
      : '';
  const locationTypeField = location ? (
    <EditableField
      object={location}
      label="Type de lieu"
      content={locationTypeName}
      editedFieldsOptions={{
        field: 'locationTypeId',
        label: 'Type de lieu',
        content: <LocationTypesSelect />,
        initialValue: locationTypeValue,
        required: true,
      }}
      onSubmit={editLocationTypeId}
      successMessage="Le lieu a bien été modifié"
    />
  ) : (
    ''
  );
  const addressField = location ? (
    <EditableField
      object={location}
      label="Adresse"
      content={
        location.addressComponents?.formattedAddress ? (
          <>
            {location.addressComponents?.formattedAddress}
            <br />
            {location.addressComponents?.additionalAddress}
          </>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: ['addressComponents', 'formattedAddress'],
          label: 'Adresse',
          content: (
            <Input placeholder="Saisissez une adresse (n°, voie, cp, ville...)" />
          ),
          initialValue: location.addressComponents?.formattedAddress,
        },
        {
          field: ['addressComponents', 'additionalAddress'],
          label: 'Complément',
          content: <Input placeholder="Saisissez un complément d'adresse" />,
          initialValue: location.addressComponents?.additionalAddress,
        },
      ]}
      onSubmit={editLocationAddress}
      successMessage="L'adresse a bien été modifiée"
    />
  ) : (
    ''
  );
  const remarkField = location ? (
    <EditableField
      object={location}
      label="Commentaire"
      content={location.remark || undefined}
      editedFieldsOptions={{
        field: 'remark',
        label: 'Commentaire',
        content: <TextArea placeholder="Saisissez un commentaire" />,
        initialValue: location.remark,
      }}
      onSubmit={editLocationRemark}
      successMessage="Le commentaire a bien été modifié"
    />
  ) : (
    ''
  );
  const locationRedirection =
    location !== null ? (
      <Button
        type="link"
        onClick={(): void => {
          redirectToLocation(location.id);
        }}
      >
        <EyeOutlined /> Voir la fiche du lieu
      </Button>
    ) : (
      ''
    );

  return (
    <Drawer
      title="Détails du lieu"
      visible={visible}
      onClose={onClose}
      footer={
        <>
          {locationRedirection}
          <Button onClick={onClose} type="primary" size="large">
            Fermer
          </Button>
        </>
      }
    >
      <Skeleton loading={isFetchingLocation && isFetchingOrganization}>
        {location !== null ? (
          <>
            {designationField}
            {locationTypeField}
            {addressField}
            {remarkField}
          </>
        ) : (
          <Empty />
        )}
      </Skeleton>
    </Drawer>
  );
};

InfoLocation.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideDrawer: PropTypes.func.isRequired,
  location: locationTypes.PropTypesLocation,
  organization: organizationTypes.PropTypesOrganization,
  locationId: PropTypes.string,
  getLocation: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  locationTypes: PropTypes.arrayOf(
    locationTypeTypes.PropTypesLocationType.isRequired,
  ).isRequired,
  isFetchingLocation: PropTypes.bool.isRequired,
  isFetchingOrganization: PropTypes.bool.isRequired,
  useLocationReducer: PropTypes.func.isRequired,
  editLocationDesignation: PropTypes.func.isRequired,
  editLocationOrganization: PropTypes.func.isRequired,
  editLocationTypeId: PropTypes.func.isRequired,
  editLocationAddress: PropTypes.func.isRequired,
  editLocationRemark: PropTypes.func.isRequired,
  redirectToLocation: PropTypes.func.isRequired,
};

export default InfoLocation;
