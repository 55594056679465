import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { getActionType } from '../common/types';
import { Permission } from '../permissions/types';

/**
 * @param {Role} role
 * @param {Meta} meta
 * @return {FetchAddRequested}
 */
export const fetchAddRolesActionCreator = createAction(
  getActionType('roles', 'ADD_REQUESTED'),
  (role: types.Role): types.Role => role,
  (role, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Role} role
 * @return {FetchAddSucceeded}
 */
export const fetchAddRolesSuccessActionCreator = createAction(
  getActionType('roles', 'ADD_SUCCEEDED'),
  (role: types.Role): types.Role => role,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddRolesErrorActionCreator = createAction(
  getActionType('roles', 'ADD_FAILED'),
  (message: string): string => message,
);

/**
 * @return {FetchListRequested}
 */
export const fetchListRolesActionCreator = createAction(
  getActionType('roles', 'LIST_REQUESTED'),
);

/**
 * @param {Role[]} roles
 * @return {FetchListSucceeded}
 */
export const fetchListRolesSuccessActionCreator = createAction(
  getActionType('roles', 'LIST_SUCCEEDED'),
  (roles: types.Role[]): types.Role[] => roles,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListRolesErrorActionCreator = createAction(
  getActionType('roles', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Role} role
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetRolesActionCreator = createAction(
  getActionType('roles', 'GET_REQUESTED'),
  (id: types.Role['id']): types.Role['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Role} role
 * @return {FetchGetSucceeded}
 */
export const fetchGetRolesSuccessActionCreator = createAction(
  getActionType('roles', 'GET_SUCCEEDED'),
  (role: types.Role): types.Role => role,
);

/**
 * @param {string}message
 * @return {FetchFailed}
 */
export const fetchGetRolesErrorActionCreator = createAction(
  getActionType('roles', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Role} role
 * @return {FetchEditSucceeded}
 */
export const fetchEditRolesSuccessActionCreator = createAction(
  getActionType('roles', 'EDIT_SUCCEEDED'),
  (role: types.Role): types.Role => role,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditRolesErrorActionCreator = createAction(
  getActionType('roles', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Role} role
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRolesNameActionCreator = createAction(
  getActionType('roles', 'EDIT_NAME_REQUESTED'),
  (role: types.Role): types.Role => role,
  (role, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Role} role
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRolesDescriptionActionCreator = createAction(
  getActionType('roles', 'EDIT_DESCRIPTION_REQUESTED'),
  (role: types.Role): types.Role => role,
  (role, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Role} role
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRolesStyleActionCreator = createAction(
  getActionType('roles', 'EDIT_STYLE_REQUESTED'),
  (role: types.Role): types.Role => role,
  (role, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Role} role
 * @param {Permission} permission
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRolesAddPermissionActionCreator = createAction(
  getActionType('roles', 'EDIT_ADD_PERMISSION_REQUESTED'),
  (
    role: types.Role,
    permission: Permission,
  ): {
    role: types.Role;
    permission: Permission;
  } => ({ role, permission }),
  (role, permission, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Role} role
 * @param {Permission} permission
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRolesDeletePermissionActionCreator = createAction(
  getActionType('roles', 'EDIT_REMOVE_PERMISSION_REQUESTED'),
  (
    role: types.Role,
    permission: Permission,
  ): {
    role: types.Role;
    permission: Permission;
  } => ({ role, permission }),
  (role, permission, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteRolesActionCreator = createAction(
  getActionType('roles', 'DELETE_REQUESTED'),
  (id: types.Role['id']): types.Role['id'] => id,
  (role, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteRolesSuccessActionCreator = createAction(
  getActionType('roles', 'DELETE_SUCCEEDED'),
  (id: types.Role['id']): types.Role['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteRolesErrorActionCreator = createAction(
  getActionType('roles', 'DELETE_FAILED'),
  (message: string): string => message,
);
