import React, { ReactElement } from 'react';
import { Anchor, Affix, Divider } from 'antd';
import { withRouter } from 'react-router';
import './Container.scss';

const PrivacyPolicy = (): ReactElement => {
  return (
    <div className="privacy-policy">
      <section className="section">
        <h1>Politique de Confidentialité / RGPD Conditions Générales TOOTWI</h1>
        <article>
          <section className="markdown">
            <Affix className="toc-affix" offsetTop={100}>
              <Anchor className="toc" offsetTop={80}>
                <Anchor.Link href="#introduction" title="Introduction" />
                <Anchor.Link
                  href="#definition"
                  title="Définition des données personnelles"
                />
                <Anchor.Link
                  href="#why"
                  title="À quelle fin collectons-nous vos données personnelles ?"
                />
                <Anchor.Link
                  href="#how"
                  title="Comment collectons nous vos données personnelles ?"
                />
                <Anchor.Link
                  href="#traitement"
                  title="Traitement de vos données"
                />
                <Anchor.Link
                  href="#who"
                  title="Qui est le responsable du traitement ?"
                />
                <Anchor.Link
                  href="#conservation"
                  title="Conservation des données"
                />
                <Anchor.Link href="#securite" title="Sécurité" />
                <Anchor.Link href="#host" title="Hébergement" />
                <Anchor.Link href="#cookies" title="Utilisation des cookies" />
                <Anchor.Link
                  href="#date"
                  title="Entrée en vigueur de cette politique"
                />
                <Anchor.Link href="#consentement" title="Consentement" />
              </Anchor>
            </Affix>
            <h2 id="introduction">Introduction</h2>
            <p>
              Cette politique de confidentialité et des cookies dite « Politique
              » décrit comment Tootwi collecte, utilise et partage les données
              personnelles lors de l’utilisation de son application dite l’«
              Application ».
            </p>
            <p>
              Tootwi attache une grande importance à la protection et à la
              confidentialité de la vie privée de ses utilisateurs et plus
              particulièrement de leurs données à caractère personnel.
            </p>
            <p>
              Dans ce cadre et conformément à la législation relative protection
              des données, la présente Politique de confidentialité vous
              permettra de comprendre quelles données à caractère personnel nous
              nous collectons, ce que nous en faisons et la façon dont vous
              pouvez protéger votre vie privée dans le cadre des services que
              que nous fournissons.
            </p>

            <Divider />

            <h2 id="definition">Définition des données personnelles</h2>
            <p>
              Tootwi collecte des données personnelles, qui selon le Règlement
              Général sur la Protection des Données de 2018 et toutes les
              législations lui succédant, inclut toute information, qui, seule
              ou combinée avec d’autres informations, vous identifie en tant
              qu’individu, incluant par exemple, votre nom, adresse postale,
              adresse courriel et numéro de téléphone.
            </p>

            <Divider />

            <h2 id="why">
              À quelle fin collectons-nous vos données personnelles ?
            </h2>
            <p>
              Nous collectons et traitons vos données personnelles en accord
              avec les lois en vigueur sur la protection des données et de la
              vie privée. Nous avons besoin de certaines données personnelles
              afin de vous fournir un accès à notre Application.
            </p>
            <p>
              Les données obligatoires sont : votre nom, prénom et adresse mail.
              <br />
              Les données non obligatoires sont : votre photo de profil, votre
              téléphone et votre adresse.
            </p>
            <p>
              Votre consentement nous fournit le prérequis légal au traitement
              de vos données. Vous conservez le droit de revenir à tout moment
              sur votre consentement. Si vous n’êtes pas d’accord avec l’usage
              que nous faisons de vos données personnelles, tel que décrit dans
              cette Politique, nous vous prions de ne pas utiliser notre
              Application.
            </p>

            <Divider />

            <h2 id="how">Comment collectons nous vos données personnelles ?</h2>
            <p>Nous collectons des données lorsque :</p>
            <ul>
              <li>
                Vous créez un compte pour utiliser l’Application, incluant :
                nom, prénom, courriel, mais aussi photo, téléphone et adresse.
              </li>
              <li>Vous postez un commentaire sur l’Application.</li>
              <li>
                Vous communiquez avec nous au téléphone, courriel, messagerie
                instantanée ou autre.
              </li>
            </ul>

            <Divider />

            <h2 id="traitement">Traitement de vos données</h2>
            <p>Tootwi applique des principes respectueux de vos données :</p>
            <ul>
              <li>
                Vos données personnelles ne feront jamais l’objet de cessions,
                locations ou échanges au bénéfice de tiers.
              </li>
              <li>
                Vos données ne sont collectées que pour des finalités précises
                qui ont toutes pour objectif commun de vous permettre d’utiliser
                en toute confiance nos services.
              </li>
              <li>
                Vous communiquez avec nous au téléphone, courriel, messagerie
                instantanée ou autre. <br />
                Nous ne collectons que les données nécessaires à la gestion des
                inscriptions, la création de comptes utilisateurs ainsi que la
                fourniture de services conformément aux termes du contrat.
              </li>
              <li>
                Nous ne procédons à aucun démarchage par téléphone ni par
                courrier.
              </li>
              <li>
                Nous n’adressons aucune sollicitation commerciale par courriel
                sans votre accord.
              </li>
              <li>
                Nous vous laissons gérer vos données en toute liberté grâce à
                votre compte utilisateur
              </li>
              <li>
                Nous veillons à respecter vos droits et à répondre à toutes vos
                questions sur vos données.
              </li>
            </ul>
            <p>
              Pour toute information sur la protection des données personnelles,
              vous pouvez également consulter le site de la Commission nationale
              Informatique et Libertés : www.cnil.fr
            </p>

            <Divider />

            <h2 id="who">Qui est le responsable du traitement ?</h2>
            <p>
              Twipi Group SAS 512 422 239 <br />
              R.C.S Nanterre
            </p>
            <p>
              28 quai Gallieni – 92150 SURESNES <br />
              01 40 93 99 50
            </p>

            <Divider />

            <h2 id="conservation">Conservation des données</h2>
            <p>
              Vos données relatives à votre compte utilisateur sont conservées
              pendant toute la durée de votre inscription à nos services. <br />
              A la fermeture de votre compte, nous procédons à un archivage
              après avoir supprimé les données qui ne sont plus nécessaires.
            </p>

            <Divider />

            <h2 id="securite">Sécurité</h2>
            <p>
              Nous avons pris toutes les précautions nécessaires, ainsi que les
              mesures organisationnelles et techniques appropriées pour
              préserver la sécurité, l’intégrité et la confidentialité de vos
              données à caractère personnel.
            </p>

            <Divider />

            <h2 id="host">Hébergement</h2>
            <p>
              Vos données sont conservées et stockées pendant toute la durée de
              leur traitement sur les serveurs de la société Google situés dans
              l’Union européenne. <br />
              Vos données ne feront l’objet d’aucun transfert en dehors de
              l’Union européenne dans le cadre de l’utilisation des services que
              nous vous proposons.
            </p>

            <Divider />

            <h2 id="cookies">Utilisation des cookies</h2>
            <p>
              Les cookies sont des fichiers textes, souvent cryptés, stockés
              dans votre smartphone et votre navigateur. Ils sont créés lorsque
              votre smartphone ou navigateur charge une application ou un site
              internet donné : l’application ou le site envoie des informations
              à votre navigateur, qui créé alors un fichier texte. Chaque fois
              que vous revenez sur la même application ou le même site, votre
              smartphone ou votre navigateur récupère ce fichier et le lui
              envoie.
            </p>
            <p>
              On peut distinguer différents types de cookies, qui n’ont pas les
              mêmes finalités :
            </p>
            <ul>
              <li>
                Les cookies techniques sont utilisés tout au long de votre
                navigation, afin de la faciliter et d’exécuter certaines
                fonctions. Un cookie technique peut par exemple être utilisé
                pour mémoriser vos réponses renseignées dans un formulaire ou
                encore vos préférences s’agissant de la langue ou de la
                présentation d’un site internet, lorsque de telles options sont
                disponibles.
                <br />
                <strong>
                  Nous utilisons des cookies techniques pour l’Application.
                </strong>
              </li>
              <li>
                Les cookies des réseaux sociaux peuvent être créés par les
                plateformes sociales pour permettre aux concepteurs
                d’applications et de sites internet de partager leur contenu sur
                lesdites plateformes. Ces cookies peuvent notamment être
                utilisés par les plateformes sociales pour tracer la navigation
                des utilisateurs sur l’application concernée ou le site internet
                concerné, qu’ils utilisent ou non ces cookies.
                <br />
                <strong>
                  Nous n’utilisons pas de cookies des réseaux sociaux.
                </strong>
                <br />
                Si nous décidons de le faire ultérieurement, nous vous
                demanderons votre accord avant de les déposer. Vous aurez en
                outre la possibilité de vous informer sur leur nature, de les
                accepter ou de les refuser.
              </li>
              <li>
                Les cookies publicitaires peuvent être créés non seulement par
                le site internet sur lequel vous naviguez, mais également par
                d’autres sites internet diffusant des publicités, annonces,
                widgets ou autres éléments sur la page affichée. Ces cookies
                peuvent notamment être utilisés pour effectuer de la publicité
                ciblée, c’est-à-dire de la publicité déterminée en fonction de
                votre navigation. <br />
                <strong>
                  Nous n’utilisons pas non plus de cookies publicitaires.
                </strong>
                <br />
                Si nous décidons de le faire ultérieurement, nous vous
                demanderons votre accord avant de les déposer. Vous aurez en
                outre la possibilité de vous informer sur leur nature, de les
                accepter ou de les refuser.
              </li>
            </ul>

            <Divider />

            <h2 id="date">Entrée en vigueur de cette politique</h2>
            <p>
              Cette Politique de Confidentialité entre en vigueur à compter de
              la mise en ligne de notre Application. <br />
              Nous nous réservons le droit de la modifier à tout moment en
              totalité ou en partie. Vous serez alors informés par un message à
              l’ouverture de l’Application.
            </p>

            <Divider />

            <h2 id="consentement">Consentement</h2>
            <p>
              En acceptant cette politique de confidentialité, vous choisissez
              de communiquer vos données à caractère personnel, vous donnez
              expressément votre consentement pour la collecte et l’utilisation
              de celles-ci conformément à la législation en vigueur et à ce qui
              est énoncé dans la présente politique. <br />
              Votre utilisation de l’Application vaudra reconnaissance et
              acceptation de cette politique. A défaut et si cette Politique ne
              vous convient pas, vous ne devrez plus accéder à l’Application.
            </p>
          </section>
        </article>
      </section>
    </div>
  );
};

export default withRouter(PrivacyPolicy);
