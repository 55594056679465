import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { InputProps } from 'antd/lib/input';
import PlacesAutocomplete from 'react-places-autocomplete';
// @ts-ignore
import Script from 'react-load-script';
import { Input } from '../index';
import './PlacesAutocompleteInput.scss';
import { EnvironmentOutlined } from '@ant-design/icons';

/**
 * @param {any} props
 * @return {ReactElement}
 */
const PlacesAutocompleteInput: FunctionComponent<InputProps> = ({
  ...props
}): ReactElement => {
  const [apiLoaded, setApiLoaded] = useState(false);
  const [value, setValue] = useState('');

  useEffect((): void => {
    setValue(typeof props.value === 'string' ? props.value : '');
  }, [props.value]);

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`}
        onLoad={(): void => setApiLoaded(true)}
      />
      {apiLoaded ? (
        <PlacesAutocomplete
          value={value}
          onChange={(value): void => setValue(value)}
          searchOptions={{
            types: ['address'],
          }}
          onSelect={(address): void => {
            setValue(address);

            if (props.onChange) {
              // @ts-ignore
              props.onChange(address);
            }
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }): ReactElement => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: props.placeholder,
                  className: 'location-search-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>En attente de Google ...</div>}
                {suggestions.map((suggestion): ReactElement => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';

                  const text = suggestion.formattedSuggestion;

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <EnvironmentOutlined />
                      <span className={'main-text'}>{text.mainText}</span>
                      <span className={'secondary-text'}>
                        , {text.secondaryText}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <></>
      )}
    </>
  );
};

PlacesAutocompleteInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PlacesAutocompleteInput;
