export interface Filter {
  field: string | string[];
  key: string;
  value?: string | string[];
  operator?: '=' | '!=' | '>=' | '<=' | 'IN' | 'LIKE' | 'BETWEEN';
  includeNull?: boolean;
}

export interface Pagination {
  current: number;
  pageSize: number;
  total?: number;
}

export const DEFAULT_PAGE_SIZE = 20;

export interface Sort {
  field: string;
  order: 'asc' | 'desc';
}

export interface Search {
  filters: Filter[];
  pagination?: Pagination;
  sort?: Sort;
}

export interface PaginatedResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  pagination: Pagination;
}

/**
 * @param {Search} search
 * @return {string}
 */
export const getSearchUrlParameters = (search: Search): string => {
  if (0 === search.filters.length && !search.pagination && !search.sort) {
    return '';
  }

  let parameters: string[] = [];
  if (search.filters.length > 0) {
    parameters = parameters.concat(
      search.filters.map((filter) => {
        filter.operator = filter.operator || '=';

        let searchUrlParameters = '';
        if (filter.includeNull) {
          searchUrlParameters = `${filter.field}=[null]||`;
        }

        switch (filter.operator) {
          case 'BETWEEN':
            if (!Array.isArray(filter.value) || 2 !== filter.value.length) {
              return searchUrlParameters;
            }

            return (
              searchUrlParameters +
              `${filter.field}>=${filter.value[0]}&${filter.field}<=${filter.value[1]}`
            );
          case 'LIKE':
            if (Array.isArray(filter.field)) {
              return filter.field
                .map((field) => `${field}=*${filter.value}*`)
                .join('||');
            }

            if (Array.isArray(filter.value)) {
              return (
                searchUrlParameters +
                filter.value
                  .map((value) => `${filter.field}=*${value}*`)
                  .join('||')
              );
            }

            return searchUrlParameters + `${filter.field}=*${filter.value}*`;
          case 'IN':
            if (!Array.isArray(filter.value)) {
              return searchUrlParameters;
            }

            return (
              searchUrlParameters +
              filter.value
                .map((value) => `${filter.field}[]=${value}`)
                .join('&')
            );
          default:
            return (
              searchUrlParameters +
              `${filter.field}${filter.operator}${filter.value}`
            );
        }
      }),
    );
  }

  if (search.sort) {
    parameters.push(`order_by=${search.sort.field},${search.sort.order}`);
  }

  if (search.pagination) {
    let startIndex;
    let endIndex;
    if (1 === search.pagination.current) {
      startIndex = 0;
      endIndex = search.pagination.pageSize - 1;
    } else {
      startIndex = (search.pagination.current - 1) * search.pagination.pageSize;
      endIndex = startIndex + search.pagination.pageSize - 1;
    }
    parameters.push(`range=${startIndex}-${endIndex}`);
  }

  parameters = parameters.filter((parameter) => '' !== parameter);

  if (0 === parameters.length) {
    return '';
  }

  return `?${parameters.join('&')}`;
};
