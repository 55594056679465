import React, { FunctionComponent, ReactElement } from 'react';
import { Tabs as TabsAnt } from 'antd';
import 'antd/lib/tabs/style';
import { TabsProps } from 'antd/lib/tabs';
import './Tabs.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Tabs component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Tabs: FunctionComponent<TabsProps> = ({ ...props }): ReactElement => {
  const classes = classnames('app-tabs', props.className);
  return <TabsAnt {...props} className={classes} />;
};

Tabs.propTypes = {
  className: PropTypes.string,
};

export default Tabs;
