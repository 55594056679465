import { createSelector } from 'reselect';
import { State } from '../../types';
import { Incident, IncidentState } from './types';
import {
  currentUserOrganizationRootSelector,
  currentUserSelector,
} from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {IncidentState}
 */
const incidentStateSelector: (state: State) => IncidentState = (state) =>
  state.incidentsState;

/**
 * @param {State} state
 * @return {Incident[]}
 */
export const incidentsSelector = createSelector(
  incidentStateSelector,
  (applicationState) => applicationState.incidents,
);

/**
 * @param {State} state
 * @return {Incident[]}
 */
export const incidentsFromOrganizationSelector = createSelector(
  incidentsSelector,
  currentUserOrganizationRootSelector,
  (incidents, organization) =>
    incidents.filter(
      (incidents) => incidents.organizationId === organization.id,
    ),
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const incidentsIsGettingSelector = createSelector(
  incidentStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {Pagination}
 */
export const incidentsPaginationSelector = createSelector(
  incidentStateSelector,
  (applicationState) => applicationState.pagination,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Incident|null}
 */
export const incidentFromIdSelector = createSelector(
  incidentsSelector,
  (_: State, id: Incident['id']) => id,
  (incidents, id) => incidents.find((incident) => incident.id === id) || null,
);

/**
 * @param {State} state
 * @return {Incident[]}
 */
export const visibleIncidentsSelector = createSelector(
  incidentsSelector,
  (state) => currentUserSelector(state),
  (incidents, currentUser) =>
    incidents.filter((incident) =>
      currentUser.visibleOrganizationIds.includes(incident.organizationId),
    ),
);
