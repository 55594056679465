import { notification } from 'antd';
import 'antd/lib/notification/style';

/**
 * @param {any} props empty props
 * @return {ReactElement} Col element
 */
notification.config({});

export default notification;
