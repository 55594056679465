import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { getActionType } from '../common/types';

/**
 * @return {FetchListHistoriesRequested}
 */
export const fetchListHistoriesActionCreator = createAction(
  getActionType('histories', 'LIST_REQUESTED'),
  (
    objectType: string,
    objectId: string,
  ): {
    objectType: string;
    objectId: string;
  } => ({ objectType, objectId }),
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {History[]}
 * @return {FetchListSucceeded}
 */
export const fetchListHistoriesSuccessActionCreator = createAction(
  getActionType('histories', 'LIST_SUCCEEDED'),
  (histories: types.History[]): types.History[] => histories,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListHistoriesErrorActionCreator = createAction(
  getActionType('histories', 'LIST_FAILED'),
  (message: string): string => message,
);

export type FetchListHistoriesRequested = ReturnType<
  typeof fetchListHistoriesActionCreator
>;
