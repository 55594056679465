import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import { Pagination } from '../../../services/search/search';
import { flatiItems, rehydrate } from '../../helper';
import { modelReducer } from '../common/reducers';
import { getActionType } from '../common/types';
import { Request, RequestState } from './types';

/**
 * @param {Request[]} [state=[]] requests
 * @param {AnyAction} action
 * @return {Request[]}
 */
export const requestReducer: Reducer<Request[]> = (
  state: Request[] = [],
  action: AnyAction,
): Request[] => {
  if (action.type === getActionType('requests', 'OPEN_SUCCEEDED')) {
    action.payload.status = 'open';
  }

  if (action.type === getActionType('requests', 'LIST_PAGINATED_SUCCEEDED')) {
    return rehydrate<Request>([], flatiItems<Request>(action.payload.result));
  }

  return modelReducer('requests', state, action) as Request[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const openingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('requests', 'OPEN_FAILED'):
    case getActionType('requests', 'OPEN_SUCCEEDED'):
      return false;
    case getActionType('requests', 'OPEN_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('requests', 'LIST_FAILED'):
    case getActionType('requests', 'LIST_SUCCEEDED'):
    case getActionType('requests', 'LIST_PAGINATED_SUCCEEDED'):
      return false;
    case getActionType('requests', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {Pagination} state
 * @param {Action} action
 * @return {boolean}
 */
export const paginationReducer: Reducer<Pagination | null> = (
  state = null,
  action: AnyAction,
): Pagination | null => {
  switch (action.type) {
    case getActionType('requests', 'LIST_PAGINATED_SUCCEEDED'):
      return action.payload.pagination;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('requests', 'GET_FAILED'):
    case getActionType('requests', 'GET_SUCCEEDED'):
      return false;
    case getActionType('requests', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('requests', 'EDIT_FAILED'):
    case getActionType('requests', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('requests', 'EDIT_REQUESTED'):
    case getActionType('requests', 'EDIT_CREATOR_REQUESTED'):
    case getActionType('requests', 'EDIT_MANAGER_REQUESTED'):
    case getActionType('requests', 'EDIT_ORGANIZATIONS_REQUESTED'):
    case getActionType('requests', 'CLOSE_REQUESTED'):
    case getActionType('requests', 'ADD_FILE_REQUESTED'):
    case getActionType('requests', 'ADD_GED_FILE_REQUESTED'):
    case getActionType('requests', 'DELETE_FILE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('requests', 'DELETE_FAILED'):
    case getActionType('requests', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('requests', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<RequestState> = combineReducers({
  requests: requestReducer,
  pagination: paginationReducer,
  status: combineReducers({
    opening: openingReducer,
    listing: listingReducer,
    getting: gettingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
  }),
});

export default reducers;
