import { createSelector } from 'reselect';
import { LocationType, LocationTypeState } from './types';
import { State } from '../../types';
import { currentUserOrganizationRootSelector } from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {LocationTypeState}
 */
const locationTypeStateSelector: (state: State) => LocationTypeState = (
  state,
) => state.locationTypesState;

/**
 * @param {State} state
 * @return {LocationType[]}
 */
export const locationTypesSelector = createSelector(
  locationTypeStateSelector,
  (applicationState) => applicationState.locationTypes,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const locationTypesIsGettingSelector = createSelector(
  locationTypeStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const locationTypesIsListingSelector = createSelector(
  locationTypeStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @param {LocationType|null}
 */
export const locationTypeFromIdSelector = createSelector(
  locationTypesSelector,
  (_: State, id: LocationType['id']) => id,
  (locationTypes, id) =>
    locationTypes.find((locationType) => locationType.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {LocationType[]}
 */
export const locationTypeFromMultipleIdsSelector = createSelector(
  locationTypesSelector,
  (_: State, ids: LocationType['id'][]) => ids,
  (locationTypes, ids) =>
    locationTypes.filter((locationType) => ids.includes(locationType.id)),
);

/**
 * @param {State} state
 * @return {LocationType[]}
 */
export const locationTypesFromOrganizationSelector = createSelector(
  locationTypesSelector,
  currentUserOrganizationRootSelector,
  (locationTypes, organization) =>
    locationTypes.filter(
      (locationTypes) => locationTypes.organizationId === organization.id,
    ),
);

/**
 * @param {State} state
 * @param {LocationType|null}
 */
export const mainLocationTypeSelector = createSelector(
  locationTypesFromOrganizationSelector,
  (locationTypes) => locationTypes.find((locationType) => locationType.isMain),
);

/**
 * @param {State} state
 * @param {LocationType[]}
 */
export const quickAccessLocationTypesSelector = createSelector(
  locationTypesFromOrganizationSelector,
  (locationTypes) =>
    locationTypes.filter((locationType) => locationType.isQuickAccess),
);
