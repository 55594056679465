import { ReloadOutlined, SendOutlined } from '@ant-design/icons';
import { Comment } from 'antd';
import PropTypes from 'prop-types';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Pagination } from '../../../../services/search/search';
import {
  fetchAddMessagesActionCreator,
  fetchListMessagesActionCreator,
} from '../../../../states/ducks/messages/actions';
import {
  messagesFromIncidentIdSelector,
  messagesFromRequestIdSelector,
  messagesIsListingSelector,
  messagesPaginationSelector,
} from '../../../../states/ducks/messages/selectors';
import {
  Message,
  PropTypesMessage,
} from '../../../../states/ducks/messages/types';
import { State } from '../../../../states/types';
import {
  Button,
  Messages,
  notification,
  CurrentUserAvatar,
  TextArea,
  Divider,
} from '../index';
import './MessageHandler.scss';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import { Affix } from 'antd';

interface MessageHandlerProps {
  addMessage: Function;
  addPlaceholder?: string;
  addSuccessMessage?: string;
  currentUser?: CurrentUser;
  deleteConfirmationMessage?: string;
  deleteSuccessMessage?: string;
  displayNumber?: number;
  editSuccessMessage?: string;
  emptyMessage?: React.ReactNode;
  listingErrorMessage?: string;
  loadMessages: Function;
  messages: Message[];
  messagesListingStatus: number;
  objectId: string;
  objectType: string;
  total: Pagination['total'];
}

/**
 * @param {Function} addMessage
 * @param {string} addPlaceholder
 * @param {string} addSuccessMessage
 * @param {CurrentUser} currentUser
 * @param {string} deleteConfirmationMessage
 * @param {string} deleteSuccessMessage
 * @param {number} displayNumber
 * @param {string} editSuccessMessage
 * @param {Node} emptyMessage
 * @param {string} listingErrorMessage
 * @param {Function} loadMessages
 * @param {Message[]} messages
 * @param {number} messagesListingStatus
 * @param {string} objectId
 * @param {string} objectType
 * @param {number} total
 *
 * @return {ReactElement}
 */
const MessageHandler: FunctionComponent<MessageHandlerProps> = ({
  addMessage,
  addPlaceholder,
  addSuccessMessage,
  currentUser,
  deleteConfirmationMessage,
  deleteSuccessMessage,
  displayNumber,
  editSuccessMessage,
  emptyMessage,
  listingErrorMessage,
  loadMessages,
  messages,
  messagesListingStatus,
  objectId,
  objectType,
  total,
}): ReactElement => {
  const [currentDisplayNumber, setCurrentDisplayNumber] =
    useState(displayNumber);
  const [message, setMessage] = useState('');
  const [messagesEnd, setMessagesEnd] = useState(null as HTMLDivElement | null);
  const messageEdit = useRef(Object.create(HTMLTextAreaElement.prototype, {}));

  useEffect((): void => {
    if (loadMessages && objectType && objectId && displayNumber) {
      loadMessages(objectType, objectId, 0, displayNumber - 1);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    if (messagesEnd) {
      messagesEnd.scrollTo({ top: messagesEnd.scrollHeight });
    }
  }, [messagesEnd]);

  if (!displayNumber) {
    return <></>;
  }

  const onCancel = (): void => {
    setMessage('');
    messageEdit.current.blur();
  };

  const onPost = (): void => {
    if (!message) {
      return;
    }

    const newMessage = {
      content: message,
      objectId: objectId,
      objectType: objectType,
      organizationId: currentUser?.organizationIds[0],
      userId: currentUser?.id,
    };

    const promise = new Promise<Message>((resolve, reject): void =>
      addMessage(newMessage, { resolve, reject }),
    );

    promise
      .then((): void => {
        notification.success({ message: addSuccessMessage });

        if (messagesEnd) {
          messagesEnd.scrollTo({ top: messagesEnd.scrollHeight });
        }
      })
      .catch((message: string): void => {
        notification.error({ message });
      });

    onCancel();
  };

  if (-1 === messagesListingStatus) {
    emptyMessage = (
      <div className={'app-message-error'}>
        <div>{listingErrorMessage}</div>
        <Button
          onClick={(): void => {
            if (!loadMessages) {
              return;
            }

            loadMessages(objectType, objectId);
          }}
          type="link"
        >
          Réessayer
          <ReloadOutlined />
        </Button>
      </div>
    );
  }

  return (
    <div className="section app-message-handler">
      <Affix>
        <Comment
          avatar={<CurrentUserAvatar size={'large'} />}
          content={
            <>
              <TextArea
                className="comment-textarea-post"
                size="large"
                ref={messageEdit}
                autoSize={{ minRows: 1, maxRows: 4 }}
                placeholder={addPlaceholder}
                value={message}
                onChange={(e): void => setMessage(e.target.value)}
              />
              <Button
                type="primary"
                size="large"
                icon={<SendOutlined />}
                onClick={onPost}
              >
                <span className="text">Envoyer</span>
              </Button>
            </>
          }
          className={'app-message-writer'}
        />
      </Affix>

      <Messages
        messages={
          currentDisplayNumber
            ? messages.reverse().slice(0, currentDisplayNumber)
            : messages.reverse()
        }
        deleteConfirmationMessage={deleteConfirmationMessage}
        deleteSuccessMessage={deleteSuccessMessage}
        editSuccessMessage={editSuccessMessage}
        emptyMessage={emptyMessage}
        objectType={objectType}
        onRef={setMessagesEnd}
      />

      {currentDisplayNumber &&
      currentDisplayNumber < (total || messages.length) ? (
        <>
          <Divider></Divider>
          <Button
            className="app-btn-submit"
            type="primary"
            size="large"
            onClick={(): void => {
              const newDisplayNumber = currentDisplayNumber + displayNumber;
              loadMessages(
                objectType,
                objectId,
                currentDisplayNumber,
                newDisplayNumber - 1,
              );
              setCurrentDisplayNumber(newDisplayNumber);
            }}
          >
            Afficher plus
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

MessageHandler.displayName = 'MessageHandler';

MessageHandler.propTypes = {
  addMessage: PropTypes.func.isRequired,
  addPlaceholder: PropTypes.string,
  addSuccessMessage: PropTypes.string,
  currentUser: PropTypesCurrentUser.isRequired,
  deleteConfirmationMessage: PropTypes.string,
  deleteSuccessMessage: PropTypes.string,
  displayNumber: PropTypes.number,
  editSuccessMessage: PropTypes.string,
  emptyMessage: PropTypes.node,
  listingErrorMessage: PropTypes.string,
  loadMessages: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypesMessage.isRequired).isRequired,
  messagesListingStatus: PropTypes.number.isRequired,
  objectId: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

MessageHandler.defaultProps = {
  addPlaceholder: 'Votre message...',
  addSuccessMessage: 'Le message a bien été ajoutée',
  displayNumber: 5,
  listingErrorMessage:
    "Une erreur s'est produite lors du chargement des messages",
};

interface MapStateToProps {
  currentUser: CurrentUser;
  messages: Message[];
  messagesListingStatus: number;
  objectType: string;
  objectId: string;
  total: Pagination['total'];
}

interface MapDispatchToProps {
  addMessage: Function;
  loadMessages: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    objectId: string;
    objectType: string;
  },
): MapStateToProps => {
  let messages: Message[] = [];
  if (props.objectType === 'incident') {
    messages = messagesFromIncidentIdSelector(state, props.objectId);
  }
  if (props.objectType === 'request') {
    messages = messagesFromRequestIdSelector(state, props.objectId);
  }

  return {
    currentUser: currentUserSelector(state),
    objectType: props.objectType,
    objectId: props.objectId,
    messages: messages,
    messagesListingStatus: messagesIsListingSelector(state),
    total: messagesPaginationSelector(state)?.total || 0,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  addMessage: fetchAddMessagesActionCreator,
  loadMessages: fetchListMessagesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageHandler);
