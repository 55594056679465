import {
  File,
  FileAddingPayload,
  FileResponse,
} from '../../../states/ducks/files/types';
import {
  getSearchUrlParameters,
  PaginatedResult,
  Search,
} from '../../search/search';
import { apiCall, create, get, paginatedGet, patch, remove } from '../base';

/**
 * @param {FileAddingPayload} payload
 * @return {Promise<string>}
 */
export const addFile = async (payload: FileAddingPayload): Promise<string> => {
  const id = await create(`v1/files/create`, payload);

  const formData = new FormData();
  formData.append('fileId', id);
  formData.append('file', payload.file.originFileObj);

  await apiCall(`v1/files/upload`, `POST`, formData);

  return id;
};

/**
 * @param {Search} search
 * @return {Promise<File[]>}
 */
export const listFiles = async (
  search?: Search,
): Promise<PaginatedResult | File[]> => {
  const path = `/v1/files/list${getSearchUrlParameters(
    search || { filters: [] },
  )}`;

  return search && search.pagination
    ? await paginatedGet(path)
    : await get(path);
};

/**
 * @param {string} id
 * @return {Promise<File>}
 */
export const getFile = async (id: string): Promise<File> => {
  const response: FileResponse = await get(`/v1/files/get/${id}`);

  return {
    ...response,
    downloadUrl: getDownloadPath(id),
    originFileObj: {
      ...new global.File([], response.name),
      uid: response.id,
      lastModifiedDate: new Date(response.updatedAt),
    },
    previewUrl: getPreviewPath(id),
    status: 'done',
    thumbUrl: getThumbPath(id),
  };
};

/**
 * @param {File} file
 * @return {Promise<string>}
 */
export const editFileExpireAt = async (file: File): Promise<string> => {
  const { id, expireAt } = file;

  return await patch(`/v1/files/changeExpireAt/${id}`, {
    expireAt,
  });
};

/**
 * @param {File} file
 * @return {Promise<string>}
 */
export const editFileName = async (file: File): Promise<string> => {
  const { id, name } = file;

  return await patch(`/v1/files/rename/${id}`, {
    name,
  });
};

/**
 * @param {File} file
 * @return {Promise<string>}
 */
export const editNote = async (file: File): Promise<string> => {
  const { id, note } = file;

  return await patch(`/v1/files/changeNote/${id}`, {
    note,
  });
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteFile = async (id: string): Promise<string> =>
  await remove(`v1/files/delete/${id}`);

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const getDownloadPath = (id: string): string =>
  `${process.env.REACT_APP_URL_API}/v1/files/download/${id}`;

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const getPreviewPath = (id: string): string =>
  `${process.env.REACT_APP_URL_API}/v1/files/preview/${id}`;

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const getThumbPath = (id: string): string =>
  `${process.env.REACT_APP_URL_API}/v1/files/thumbnail/preview/${id}`;
