import React, { FunctionComponent, ReactElement } from 'react';
import { Modal as ModalAnt } from 'antd';
import 'antd/lib/modal/style';
import { ModalProps } from 'antd/lib/modal';
import PropTypes from 'prop-types';
import './Modal.scss';

/**
 * Modal component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Modal: FunctionComponent<ModalProps> = ({
  cancelText = 'Annuler',
  okText = 'Ok',
  destroyOnClose = true,
  ...props
}): ReactElement => (
  <ModalAnt
    destroyOnClose={destroyOnClose}
    cancelText={cancelText}
    okText={okText}
    {...props}
  />
);

Modal.propTypes = {
  cancelText: PropTypes.node,
  okText: PropTypes.node,
  destroyOnClose: PropTypes.bool,
};

export default Modal;
