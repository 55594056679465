import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';
import { CurrentUser, CurrentUserState } from './types';

/**
 * @param {CurrentUser | undefined} state
 * @param {AnyAction} action
 * @return {CurrentUser | undefined}
 */
export const currentUserReducer: Reducer<CurrentUser> = (
  state: CurrentUser = {} as CurrentUser,
  action: AnyAction,
): CurrentUser => {
  if (action.type === getActionType('organizations', 'ADD_SUCCEEDED')) {
    if (action.payload.parentId) {
      state.visibleOrganizationIds.push(action.payload.id);
    }
  }

  const currentUserReduced = modelReducer(
    'currentUser',
    Object.keys(state).length > 0 ? [state] : [],
    action,
    true,
  );

  return currentUserReduced.length > 0
    ? (currentUserReduced[0] as CurrentUser)
    : ({} as CurrentUser);
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<number> = (
  state = 0,
  action: Action<string>,
): number => {
  switch (action.type) {
    case getActionType('currentUser', 'GET_REQUESTED'):
      return 1;
    case getActionType('currentUser', 'GET_FAILED'):
      return -1;
    case getActionType('currentUser', 'GET_SUCCEEDED'):
      return 2;
    default:
      return 0;
  }
};

const reducers: Reducer<CurrentUserState> = combineReducers({
  currentUser: currentUserReducer,
  status: combineReducers({
    getting: gettingReducer,
  }),
});

export default reducers;
