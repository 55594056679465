import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  countIncidentsByStatus,
  countIncidentsReportedAndResolvedByMonth,
  countIncidentsByMainLocations,
  countRequestsByManager,
  countRequestsOpenedAndClosedByMonth,
} from '../../../services/api';
import { getActionType } from '../common/types';

/**
 * @param {FetchGetRequested} action
 */
export function* countIncidentsByStatusSaga(): SagaIterator {
  try {
    const kpi: types.Kpi = yield call(countIncidentsByStatus);
    yield put(actions.fetchCountSuccessActionCreator(kpi));
  } catch (err) {
    yield put(actions.fetchCountErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* countIncidentsByMainLocationsSaga(): SagaIterator {
  try {
    const kpi: types.Kpi = yield call(countIncidentsByMainLocations);
    yield put(actions.fetchCountSuccessActionCreator(kpi));
  } catch (err) {
    yield put(actions.fetchCountErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* countIncidentsReportedAndResolvedByMonthSaga(): SagaIterator {
  try {
    const kpi: types.Kpi = yield call(countIncidentsReportedAndResolvedByMonth);
    yield put(actions.fetchCountSuccessActionCreator(kpi));
  } catch (err) {
    yield put(actions.fetchCountErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* countRequestsByManagerSaga(): SagaIterator {
  try {
    const kpi: types.Kpi = yield call(countRequestsByManager);
    yield put(actions.fetchCountSuccessActionCreator(kpi));
  } catch (err) {
    yield put(actions.fetchCountErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* countRequestsOpenedAndClosedByMonthSaga(): SagaIterator {
  try {
    const kpi: types.Kpi = yield call(countRequestsOpenedAndClosedByMonth);
    yield put(actions.fetchCountSuccessActionCreator(kpi));
  } catch (err) {
    yield put(actions.fetchCountErrorActionCreator(err.message));
  }
}

/**
 * @yields {SagaIterator}
 */
export function* kpisSagas(): SagaIterator {
  yield takeEvery(
    getActionType('kpis', 'GET_INCIDENTS_BY_STATUS_REQUESTED'),
    countIncidentsByStatusSaga,
  );
  yield takeEvery(
    getActionType('kpis', 'GET_INCIDENTS_BY_MONTH_REQUESTED'),
    countIncidentsReportedAndResolvedByMonthSaga,
  );
  yield takeEvery(
    getActionType('kpis', 'GET_INCIDENTS_BY_MAIN_LOCATIONS_REQUESTED'),
    countIncidentsByMainLocationsSaga,
  );
  yield takeEvery(
    getActionType('kpis', 'GET_REQUESTS_BY_MANAGER_REQUESTED'),
    countRequestsByManagerSaga,
  );
  yield takeEvery(
    getActionType('kpis', 'GET_REQUESTS_BY_MONTH_REQUESTED'),
    countRequestsOpenedAndClosedByMonthSaga,
  );
}
