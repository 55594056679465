import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Input,
  Row,
  Col,
  notification,
  Button,
  FormItem,
  RoleStyleSelect,
  RolesSelect,
} from '../../../base';
import { roleTypes } from '../../../../../states/ducks/roles';
import { Form } from 'antd';
import { Role } from '../../../../../states/ducks/roles/types';
import { Organization } from '../../../../../states/ducks/organizations/types';
import { organizationTypes } from '../../../../../states/ducks/organizations';
import { Permission } from '../../../../../states/ducks/permissions/types';

interface RoleValue extends Role {
  copyRoleId: string;
}

interface FormAddRoleProps {
  hideModal: Function;
  visible: boolean;
  addRole: Function;
  userOrganization: Organization;
  roles: roleTypes.Role[];
}

const FormAddRole: FunctionComponent<FormAddRoleProps> = ({
  hideModal,
  visible,
  addRole,
  userOrganization,
  roles,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = (values: Partial<RoleValue>): void => {
    setLoading(true);

    const role = {
      ...values,
      permissions: [] as Permission[],
    };

    roles.filter((element): Permission[] | null => {
      if (role.copyRoleId === element.id) {
        return (role.permissions = element.permissions);
      }
      return null;
    });

    role.organizationId = userOrganization.id;

    const promise = new Promise<roleTypes.Role>((resolve, reject): void =>
      addRole(role, { resolve, reject }),
    );

    promise
      .then((role: roleTypes.Role): void => {
        form.resetFields();
        hideModal();
        const message = `${role.name} a bien été ajoutée`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      })
      .finally((): void => setLoading(false));
  };

  const nameInput = (
    <FormItem
      label="Nom"
      name="name"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner le nom',
        },
      ]}
    >
      <Input placeholder="Saisissez un nom" />
    </FormItem>
  );

  const copyInput = (
    <FormItem
      label="Copier à partir de"
      name="copyRoleId"
      rules={[
        {
          type: 'string',
        },
      ]}
    >
      <RolesSelect placeholder="Sélectionner un rôle" />
    </FormItem>
  );

  const descriptionInput = (
    <FormItem
      label="Description"
      name="description"
      rules={[
        {
          type: 'string',
          required: false,
          message: 'Veuillez renseigner la description',
        },
      ]}
    >
      <Input placeholder="Saisissez une description" />
    </FormItem>
  );

  const selectStyleInput = (
    <FormItem
      label="Style"
      name="style"
      initialValue="green"
      rules={[
        {
          type: 'string',
        },
      ]}
    >
      <RoleStyleSelect placeholder="Sélectionnez une couleur" />
    </FormItem>
  );

  return (
    <Drawer
      title="Création d’un rôle"
      onClose={(): void => hideModal()}
      visible={visible}
      footer={
        <>
          <Button
            htmlType="button"
            size="large"
            onClick={(): void => hideModal()}
          >
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            onClick={(): void => form.submit()}
          >
            Créer
          </Button>
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={24}>{nameInput}</Col>
          <Col span={24}>{copyInput}</Col>
          <Col span={24}>{descriptionInput}</Col>
          <Col span={24}>{selectStyleInput}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};

FormAddRole.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  addRole: PropTypes.func.isRequired,
  userOrganization: organizationTypes.PropTypesOrganization.isRequired,
  roles: PropTypes.arrayOf(roleTypes.PropTypesRole.isRequired).isRequired,
};

export default FormAddRole;
