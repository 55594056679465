import { create, get, paginatedGet, patch, remove } from '../base';
import { incidentTypes } from '../../../states/ducks/incidents';
import {
  getSearchUrlParameters,
  PaginatedResult,
  Search,
} from '../../search/search';

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const reportIncident = async (
  incident: Partial<incidentTypes.Incident>,
): Promise<incidentTypes.Incident['id']> =>
  await create(`/v1/incidents/report`, incident);

/**
 * @param {Search} search
 * @return {Promise<Incident[]>}
 */
export const listIncidents = async (
  search?: Search,
): Promise<PaginatedResult | incidentTypes.Incident[]> => {
  const path = `/v1/incidents/list${getSearchUrlParameters(
    search || { filters: [] },
  )}`;

  return search && search.pagination
    ? await paginatedGet(path)
    : await get(path);
};

/**
 * @param {string} id
 * @return {Promise<Incident>}
 */
export const getIncident = async (
  id: incidentTypes.Incident['id'],
): Promise<incidentTypes.Incident> => await get(`/v1/incidents/get/${id}`);

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const acceptIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id, contractorId } = incident;

  return await patch(`/v1/incidents/accept/${id}`, {
    contractorId,
  });
};

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const rejectIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id, rejectReason } = incident;

  return await patch(`/v1/incidents/reject/${id}`, {
    reason: rejectReason,
  });
};

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const resolveIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id } = incident;

  return await patch(`/v1/incidents/resolve/${id}`);
};

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const retypeIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id, incidentTypeId } = incident;

  return await patch(`/v1/incidents/retype/${id}`, {
    incidentTypeId,
  });
};

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const remarkIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id, remark } = incident;

  return await patch(`/v1/incidents/remark/${id}`, {
    remark,
  });
};

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const relocateIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id, locationId } = incident;

  return await patch(`/v1/incidents/relocate/${id}`, {
    locationId,
  });
};

/**
 * @param {Incident} incident
 * @param {string[]} fileIds
 * @return {Promise<string>}
 */
export const addFilesIncident = async (
  incident: incidentTypes.Incident,
  fileIds: string[],
): Promise<string> => {
  return await patch(`/v1/incidents/addFiles/${incident.id}`, {
    fileIds,
  });
};

/**
 * @param {Incident} incident
 * @param {string} fileId
 * @return {Promise<string>}
 */
export const removeFileIncident = async (
  incident: incidentTypes.Incident,
  fileId: string,
): Promise<string> => {
  const { id } = incident;

  return await patch(`/v1/incidents/removeFile/${id}`, {
    fileId,
  });
};

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const changeReportingIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id, reportedAt, userId } = incident;

  return await patch(`/v1/incidents/changeReporting/${id}`, {
    reportedAt,
    userId,
  });
};

/**
 * @param {Incident} incident
 * @return {Promise<string>}
 */
export const changeServiceOrderNumberIncident = async (
  incident: incidentTypes.Incident,
): Promise<string> => {
  const { id, serviceOrderNumber } = incident;

  return await patch(`/v1/incidents/changeServiceOrderNumber/${id}`, {
    serviceOrderNumber,
  });
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteIncident = async (
  id: incidentTypes.Incident['id'],
): Promise<string> => await remove(`/v1/incidents/delete/${id}`);
