import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import PropTypes from 'prop-types';
import { userTypes } from '../../../../states/ducks/users';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';

interface IncidentProfileNameProps {
  userId: userTypes.User['id'];
  profile: profileTypes.Profile | null;
  getProfile: Function;
}

/**
 * @param {string} userId
 * @param {Profile} profile
 * @param {Function} getProfile
 *
 * @return {ReactElement}
 */
const IncidentProfileName: FunctionComponent<IncidentProfileNameProps> = ({
  userId,
  profile,
  getProfile,
}): ReactElement => {
  useEffect((): void => {
    if (getProfile && !profile) {
      getProfile(userId);
    }
  }, [getProfile, profile, userId]);

  return (
    <>{profile ? `${profile.firstName} ${profile.lastName}` : 'Anonyme'}</>
  );
};

IncidentProfileName.propTypes = {
  userId: PropTypes.string.isRequired,
  profile: profileTypes.PropTypesProfile.isRequired,
  getProfile: PropTypes.func.isRequired,
};

interface MapStateToProps {
  profile: profileTypes.Profile | null;
}

interface MapDispatchToProps {
  getProfile: Function;
}

const mapStateToProps = (
  state: State,
  props: { userId: userTypes.User['id'] },
): MapStateToProps => ({
  profile: profileSelectors.profileFromUserIdSelector(state, props.userId),
});

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentProfileName);
