import React, { FunctionComponent, ReactElement } from 'react';
import { TimePicker as TimePickerAnt } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker';
import 'antd/lib/time-picker/style';
import './TimePicker.scss';
import moment from 'moment';

/**
 * TimePicker component
 * @param {TimePickerProps} props
 * @return {ReactElement}
 */
const TimePicker: FunctionComponent<TimePickerProps> = ({
  ...props
}): ReactElement => {
  const containerID = 'no-outside-click-event' + String(new Date().valueOf());

  // eslint-disable-next-line react/prop-types
  const isToday = props.value && props.value.isSame(new Date(), 'day');

  return (
    <div id={containerID} className="no-outside-click-event">
      <TimePickerAnt
        {...props}
        allowClear={false}
        format={'HH:mm'}
        disabledHours={(): number[] => {
          if (!isToday) {
            return [];
          }

          const hours: number[] = [];
          for (let i = 24; i > moment().hour(); i--) {
            hours.push(i);
          }
          return hours;
        }}
        disabledMinutes={(): number[] => []}
        getPopupContainer={(): HTMLElement => {
          return document.getElementById(containerID) as HTMLElement;
        }}
      />
    </div>
  );
};

export default TimePicker;
