import { create, get, patch, remove } from '../base';
import { organizationTypes } from '../../../states/ducks/organizations';

/**
 * @return {Promise<Organization[]>}
 */
export const listOrganizations = async (): Promise<
  organizationTypes.Organization[]
> => await get(`/v1/organizations/list`);

/**
 * @return {Promise<Organization[]>}
 */
export const listAdminOrganizations = async (): Promise<
  organizationTypes.Organization[]
> => {
  const headers = new Headers();
  headers.append('admin', 'true');

  return await get(`/v1/organizations/list`, undefined, {}, { headers });
};

/**
 * @param {string} id
 * @return {Promise<Organization>}
 */
export const getOrganization = async (
  id: organizationTypes.Organization['id'],
): Promise<organizationTypes.Organization> =>
  await get(`/v1/organizations/get/${id}`);

/**
 * @param {Organization} organization
 * @return {Promise<string>}
 */
export const addRootOrganization = async (
  organization: Partial<organizationTypes.Organization>,
): Promise<organizationTypes.Organization['id']> =>
  await create(`/v1/organizations/create`, organization);

/**
 * @param {Organization} organization
 * @return {Promise<string>}
 */
export const addNodeOrganization = async (
  organization: Partial<organizationTypes.Organization>,
): Promise<organizationTypes.Organization['id']> =>
  await create(`/v1/organizations/addUnit`, organization);

/**
 * @param {Organization} organization
 * @return {Promise<string>}
 * // TODO bad name
 */
export const editOrganization = async (
  organization: organizationTypes.Organization,
): Promise<string> => {
  const { id, name } = organization;

  return await patch(`/v1/organizations/changeIdentity/${id}`, { name });
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteOrganization = async (
  id: organizationTypes.Organization['id'],
): Promise<string> => await remove(`/v1/organizations/remove/${id}`);
