import { descriptions as fr } from './fr';

export const descriptions: {
  [key: string]: {
    [key: string]: string | { main: string; secondary: string };
  };
} = fr;

export const getDescription = (
  subject: string,
  action: string,
): string | { main: string; secondary: string } => {
  if (!descriptions.hasOwnProperty(subject)) {
    return '';
  }

  if (!descriptions[subject].hasOwnProperty(action)) {
    return '';
  }

  return descriptions[subject][action];
};

export const getSearchPattern = (subject: string, action: string): string => {
  const description = getDescription(subject, action);

  return 'object' === typeof description
    ? description.main + ' ' + description.secondary
    : description;
};
