import { createSelector } from 'reselect';
import { State } from '../../../types';
import { UserState } from '../types';

/**
 * @param {State} state
 * @return {UserState}
 */
const userStateSelector: (state: State) => UserState = (state) =>
  state.usersState;

/**
 * @param {State} state
 * @return {User[]}
 */
export const usersSelector = createSelector(
  userStateSelector,
  (applicationState) => applicationState.users,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const usersIsAddingSelector = createSelector(
  userStateSelector,
  (applicationState) => applicationState.status.adding,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const usersIsEditingSelector = createSelector(
  userStateSelector,
  (applicationState) => applicationState.status.editing,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const usersIsGettingSelector = createSelector(
  userStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const usersIsListingSelector = createSelector(
  userStateSelector,
  (applicationState) => applicationState.status.listing,
);
