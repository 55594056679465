import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import PropTypes from 'prop-types';
import {
  historyActions,
  historySelectors,
} from '../../../../states/ducks/histories';
import {
  profileActions,
  profileSelectors,
} from '../../../../states/ducks/profiles';
import {
  incidentActions,
  incidentSelectors,
  incidentTypes,
} from '../../../../states/ducks/incidents';
import {
  interventionActions,
  interventionSelectors,
  interventionTypes,
} from '../../../../states/ducks/interventions';
import GetInterventionsTimeline from '../../layout/modal/intervention/GetInterventionsTimeline';
import IncidentStatusUtils from './IncidentStatusUtils';
import moment from 'moment';
import { Steps } from 'antd';
import 'antd/lib/steps/style';
import './IncidentStatusTimeline.scss';
import { Button, Divider } from '../../base';

const { Step } = Steps;
const acceptedTimelineStatus = ['reported', 'ongoing', 'resolved'];
const rejectedTimelineStatus = ['reported', 'rejected'];
const profilePlaceholder = 'Anonyme';
const dateFormat = 'L [à] H[h]mm';

interface IncidentStatusTimelineProps {
  incidentId: incidentTypes.Incident['id'];
  incident: incidentTypes.Incident | null;
  steps: object;
  getIncident: Function;
  loadHistories: Function;
  loadProfiles: Function;
  listInterventionsByIncidentId: Function;
  interventions: interventionTypes.Intervention[] | null;
}

/**
 * @param {string} incidentId
 * @param {Incident} incident
 * @param {object} steps
 * @param {Function} getIncident
 * @param {Function} loadHistories
 * @param {Function} loadProfiles
 * @param {Function} listInterventionsByIncidentId
 * @param {Interventions[]} interventions
 *
 * @return {ReactElement}
 */
const IncidentStatusTimeline: FunctionComponent<IncidentStatusTimelineProps> =
  ({
    incidentId,
    incident,
    steps,
    getIncident,
    loadHistories,
    loadProfiles,
    listInterventionsByIncidentId,
    interventions,
  }): ReactElement => {
    const [modalInterventionVisible, setModalInterventionVisible] =
      useState(false);

    useEffect((): void => {
      if (getIncident && !incident) {
        getIncident(incidentId);
      }
    }, [getIncident, incidentId, incident]);

    useEffect((): void => {
      if (listInterventionsByIncidentId && incident) {
        listInterventionsByIncidentId(incident.id);
      }
    }, [listInterventionsByIncidentId, incident]);

    useEffect((): void => {
      if (loadHistories && incident) {
        loadHistories('incident', incident.id);
      }
    }, [loadHistories, incident]);

    useEffect((): void => {
      if (loadProfiles) {
        loadProfiles();
      }
    }, [loadProfiles, incident]);

    if (!incident || !Object.keys(steps).includes(incident.status)) {
      return <></>;
    }

    const stepDetail = (profileName: string, date: string): ReactElement => (
      <>
        Par {profileName}
        <br />
        le {date}
        <br />
      </>
    );
    const interventionLink = (): ReactElement => {
      return interventions !== null && interventions.length !== 0 ? (
        <div className="intervention-button-container">
          <Divider />
          <Button
            type="primary"
            size="large"
            onClick={(): void => {
              setModalInterventionVisible(true);
            }}
          >
            {interventions !== null && interventions.length === 1
              ? "Voir l'intervention"
              : 'Voir les interventions'}
          </Button>
        </div>
      ) : (
        <></>
      );
    };

    const stepElements = Object.entries(steps).map(
      ([status, step]): ReactElement => (
        <Step
          key={`timeline-${incidentId}-${status}`}
          className={`${status}`}
          title={IncidentStatusUtils.getLabel(status)}
          description={
            <>
              {step['profileName'] && step['date']
                ? stepDetail(step['profileName'], step['date'])
                : ''}
            </>
          }
        />
      ),
    );

    return (
      <>
        <>
          <Steps
            current={Object.keys(steps).indexOf(incident.status)}
            className={'incident-status-timeline'}
          >
            {stepElements}
          </Steps>
          {interventionLink()}
        </>
        <GetInterventionsTimeline
          interventions={interventions}
          visible={modalInterventionVisible}
          hideModal={(): void => {
            setModalInterventionVisible(false);
          }}
        />
      </>
    );
  };

IncidentStatusTimeline.propTypes = {
  incidentId: PropTypes.string.isRequired,
  incident: incidentTypes.PropTypesIncident,
  steps: PropTypes.object.isRequired,
  getIncident: PropTypes.func.isRequired,
  loadHistories: PropTypes.func.isRequired,
  loadProfiles: PropTypes.func.isRequired,
  listInterventionsByIncidentId: PropTypes.func.isRequired,
  interventions: PropTypes.arrayOf(
    interventionTypes.PropTypesIntervention.isRequired,
  ),
};

interface MapStateToProps {
  incident: incidentTypes.Incident | null;
  steps: object;
  interventions: interventionTypes.Intervention[] | null;
}

interface MapDispatchToProps {
  getIncident: Function;
  loadHistories: Function;
  loadProfiles: Function;
  listInterventionsByIncidentId: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    incidentId: incidentTypes.Incident['id'];
  },
): MapStateToProps => {
  const incident = incidentSelectors.incidentFromIdSelector(
    state,
    props.incidentId,
  );

  if (!incident) {
    return {
      incident: null,
      steps: {},
      interventions: null,
    };
  }

  const interventions =
    interventionSelectors.interventionFromIncidentIdSelector(
      state,
      incident.id,
    );
  const timelineStatus =
    incident.status === 'rejected'
      ? rejectedTimelineStatus
      : acceptedTimelineStatus;
  const steps: { [key: string]: object } = {};
  timelineStatus.forEach((status): void => {
    steps[status] = { profileName: '', date: '' };
  });

  const incidentHistories =
    historySelectors.historiesFromIncidentIdSelector(state, incident.id) || [];

  const timelineStatusHistories = incidentHistories.filter(
    (history): boolean =>
      // eslint-disable-next-line no-prototype-builtins
      history.to.hasOwnProperty('status') &&
      timelineStatus.includes(history.to['status']),
  );

  const stepsData: { [key: string]: string }[] = [];
  if (timelineStatus.includes('reported')) {
    stepsData.push({
      status: 'reported',
      userId: incident.userId,
      unformattedDate: incident.reportedAt,
    });
  }
  for (const history of timelineStatusHistories) {
    stepsData.push({
      status: history.to['status'],
      userId: history.userId,
      unformattedDate: history.date,
    });
  }

  stepsData.forEach((stepData): void => {
    const profile = profileSelectors.profileFromUserIdSelector(
      state,
      stepData.userId,
    );

    steps[stepData.status] = {
      profileName: profile
        ? `${profile.firstName} ${profile.lastName}`
        : profilePlaceholder,
      date: moment(stepData.unformattedDate).format(dateFormat),
    };
  });

  return {
    incident: incident,
    steps: steps,
    interventions: interventions,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getIncident: incidentActions.fetchGetIncidentsActionCreator,
  loadHistories: historyActions.fetchListHistoriesActionCreator,
  loadProfiles: profileActions.fetchListProfilesActionCreator,
  listInterventionsByIncidentId:
    interventionActions.fetchListInterventionsByIncidentIdActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentStatusTimeline);
