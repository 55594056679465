import { UploadFile } from 'antd/lib/upload/interface';
import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import { getActionType } from '../common/types';
import { Contractor } from './types';

/**
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchAddRequested}
 */
export const fetchAddContractorsActionCreator = createAction(
  getActionType('contractors', 'ADD_REQUESTED'),
  (contractor: Contractor) => contractor,
  (contractor, meta = defaultMeta) => meta,
);

/**
 * @param {Contractor} contractor
 * @return {FetchAddSucceeded}
 */
export const fetchAddContractorsSuccessActionCreator = createAction(
  getActionType('contractors', 'ADD_SUCCEEDED'),
  (contractor: Contractor) => contractor,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddContractorsErrorActionCreator = createAction(
  getActionType('contractors', 'ADD_FAILED'),
  (message: string) => message,
);

/**
 * @return {FetchListRequested}
 */
export const fetchListContractorsActionCreator = createAction(
  getActionType('contractors', 'LIST_REQUESTED'),
);

/**
 * @param {Contractor[]} contractors
 * @return {FetchListSucceeded}
 */
export const fetchListContractorsSuccessActionCreator = createAction(
  getActionType('contractors', 'LIST_SUCCEEDED'),
  (contractors: Contractor[]) => contractors,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListContractorsErrorActionCreator = createAction(
  getActionType('contractors', 'LIST_FAILED'),
  (message: string) => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetContractorsActionCreator = createAction(
  getActionType('contractors', 'GET_REQUESTED'),
  (id: Contractor['id']) => id,
  (_, meta = defaultMeta) => meta,
);

/**
 * @param {Contractor} contractor
 * @return {FetchGetSucceeded}
 */
export const fetchGetContractorsSuccessActionCreator = createAction(
  getActionType('contractors', 'GET_SUCCEEDED'),
  (contractor: Contractor) => contractor,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetContractorsErrorActionCreator = createAction(
  getActionType('contractors', 'GET_FAILED'),
  (message: string) => message,
);

/**
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditContractorsNameActionCreator = createAction(
  getActionType('contractors', 'EDIT_NAME_REQUESTED'),
  (contractor: Contractor) => contractor,
  (contractor, meta = defaultMeta) => meta,
);

/**
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditContractorsEmailActionCreator = createAction(
  getActionType('contractors', 'EDIT_EMAIL_REQUESTED'),
  (contractor: Contractor) => contractor,
  (contractor, meta = defaultMeta) => meta,
);

/**
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditContractorsTwimmLinkActionCreator = createAction(
  getActionType('contractors', 'EDIT_TWIMM_LINK_REQUESTED'),
  (contractor: Contractor) => contractor,
  (contractor, meta = defaultMeta) => meta,
);

/**
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditContractorsIncidentTypesActionCreator = createAction(
  getActionType('contractors', 'EDIT_INCIDENT_TYPES_REQUESTED'),
  (contractor: Contractor) => contractor,
  (contractor, meta = defaultMeta) => meta,
);

/**
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditContractorsOrganizationActionCreator = createAction(
  getActionType('contractors', 'EDIT_ORGANIZATIONS_REQUESTED'),
  (contractor: Contractor) => contractor,
  (contractor, meta = defaultMeta) => meta,
);

/**
 * @param {Contractor} contractor
 * @return {FetchEditSucceeded}
 */
export const fetchEditContractorsSuccessActionCreator = createAction(
  getActionType('contractors', 'EDIT_SUCCEEDED'),
  (contractor: Contractor) => contractor,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditContractorsErrorActionCreator = createAction(
  getActionType('contractors', 'EDIT_FAILED'),
  (message: string) => message,
);

/**
 * @param {File} file
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchAddFileRequested}
 */
export const fetchAddFileContractorsActionCreator = createAction(
  getActionType('contractors', 'ADD_FILE_REQUESTED'),
  (payload: {
    file: UploadFile;
    contractor: Contractor;
  }): {
    file: UploadFile;
    contractor: Contractor;
  } => payload,
  (contractor, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {File} file
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchAddGEDFileRequested}
 */
export const fetchAddGEDFileContractorsActionCreator = createAction(
  getActionType('contractors', 'ADD_GED_FILE_REQUESTED'),
  (payload: {
    fileIds: string[];
    contractor: Contractor;
  }): {
    fileIds: string[];
    contractor: Contractor;
  } => payload,
  (contractor, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} fileId
 * @param {Contractor} contractor
 * @param {Meta} meta
 * @return {FetchDeleteFileRequested}
 */
export const fetchDeleteFileContractorsActionCreator = createAction(
  getActionType('contractors', 'DELETE_FILE_REQUESTED'),
  (payload: {
    fileId: string;
    contractor: Contractor;
  }): {
    fileId: string;
    contractor: Contractor;
  } => payload,
  (contractor, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteContractorsActionCreator = createAction(
  getActionType('contractors', 'DELETE_REQUESTED'),
  (id: Contractor['id']) => id,
  (contractor, meta = defaultMeta) => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteContractorsSuccessActionCreator = createAction(
  getActionType('contractors', 'DELETE_SUCCEEDED'),
  (id: Contractor['id']) => id,
);

/**
 * @return {FetchFailed}
 */
export const fetchDeleteContractorsErrorActionCreator = createAction(
  getActionType('contractors', 'DELETE_FAILED'),
  (message: string) => message,
);

export type FetchAddFileRequested = ReturnType<
  typeof fetchAddFileContractorsActionCreator
>;

export type FetchAddGEDFileRequested = ReturnType<
  typeof fetchAddGEDFileContractorsActionCreator
>;

export type FetchDeleteFileRequested = ReturnType<
  typeof fetchDeleteFileContractorsActionCreator
>;
