import { connect } from 'react-redux';
import AddIncidentType from '../../../components/layout/modal/incidenttype/AddIncidentType';
import { incidentTypeActions } from '../../../../states/ducks/incidenttypes';
import { State } from '../../../../states/types';
import { Organization } from '../../../../states/ducks/organizations/types';
import { currentUserOrganizationRootSelector } from '../../../../states/ducks/currentUser/selectors';

interface MapStateToProps {
  userOrganization: Organization;
}

interface MapDispatchToProps {
  addIncidentType: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  userOrganization: currentUserOrganizationRootSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  addIncidentType: incidentTypeActions.fetchAddIncidentTypesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIncidentType);
