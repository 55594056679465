import { ActionMeta } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getUser, getPermissionsForUser } from '../../../services/api';
import {
  getCurrentUserId,
  handleVirtualAccessToken,
} from '../../../services/authenticate';
import { Meta } from '../common/actions';
import { getActionType } from '../common/types';
import * as actions from './actions';

/**
 * @param {FetchGetRequested} action
 */
export function* getCurrentUserSaga(
  action: ActionMeta<null, Meta>,
): SagaIterator {
  try {
    let user = yield call(getUser, getCurrentUserId() || '');

    if (user) {
      user.permissions = yield call(getPermissionsForUser, user);
    }

    user = handleVirtualAccessToken(user);

    yield put(actions.fetchGetCurrentUserSuccessActionCreator(user));

    action.meta.resolve(user);
  } catch (err) {
    yield put(actions.fetchGetCurrentUserErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* currentUserSagas(): SagaIterator {
  yield takeEvery(
    getActionType('currentUser', 'GET_REQUESTED'),
    getCurrentUserSaga,
  );
}
