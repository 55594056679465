import { create, get, remove, patch, paginatedGet } from '../base';
import { Message } from '../../../states/ducks/messages/types';
import { PaginatedResult } from '../../search/search';

/**
 * @param {Message} message
 * @return {Promise<string>}
 */
export const addMessage = async (
  message: Partial<Message>,
): Promise<Message['id']> => await create(`/v1/messages/post`, message);

/**
 * @param {Message} message
 * @return {Promise<string>}
 */
export const replyToMessage = async (
  message: Partial<Message>,
): Promise<Message['id']> =>
  await create(`/v1/messages/reply/${message.parentId}`, message);

/**
 * @param {object} payload
 * @return {Promise<Message[]>}
 */
export const listMessages = async (payload: {
  objectType: string;
  objectId: string;
  start?: number;
  end?: number;
}): Promise<PaginatedResult | Message[]> => {
  let path = `/v1/messages/getDiscussion/${payload.objectType}/${payload.objectId}`;

  if (
    typeof payload.start !== 'undefined' &&
    typeof payload.end !== 'undefined'
  ) {
    path += `?range=${payload.start}-${payload.end}&order_by=createdAt,desc`;

    return await paginatedGet(path);
  }

  return await get(path);
};

/**
 * @param {string} id
 * @return {Promise<Message>}
 */
export const getMessage = async (id: Message['id']): Promise<Message> =>
  await get(`/v1/messages/get/${id}`);

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteMessage = async (id: Message['id']): Promise<string> =>
  await remove(`/v1/messages/delete/${id}`);

/**
 * @param {string} id
 * @param {string} content
 * @return {Promise<string>}
 */
export const editMessage = async (
  id: Message['id'],
  content: Message['content'],
): Promise<string> => {
  return await patch(`/v1/messages/edit/${id}`, {
    content,
  });
};
