import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface Contractor extends Model {
  name: string;
  organizationId: string;
  fileIds?: string[] | null;
  createdAt: string;
  updatedAt: string;
  useTwimm?: boolean | null;
  email?: string | null;
  instance?: string | null;
  sendInterventionRequest?: boolean | null;
  incidentTypeIds?: string[] | null;
}

export interface ContractorState {
  contractors: Contractor[];
  status: {
    adding: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

export const PropTypesContractor = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  fileIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  useTwimm: PropTypes.bool,
  email: PropTypes.string,
  instance: PropTypes.string,
  sendInterventionRequest: PropTypes.bool,
  incidentTypeIds: PropTypes.arrayOf(PropTypes.string.isRequired),
});
