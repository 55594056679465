import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Button, Card, Col, Row } from '../index';
import PropTypes from 'prop-types';
import { FilterProps } from './Filter';
import './DataWithFilters.scss';
import { ReloadOutlined } from '@ant-design/icons';
import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import { Drawer, Input } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

type DataWithFiltersType = FunctionComponent<
  PropsWithChildren<DataWithFiltersProps>
>;

type FilterType = React.FunctionComponentElement<FilterProps>;

interface DisplayDataProps {
  dataSource: object[];
}

interface DataWithFiltersProps {
  dataSource: object[];
  displayComponent: React.FunctionComponentElement<DisplayDataProps>;
  displayInCard?: boolean;
  children?: FilterType | FilterType[];
  rightBar?: ReactElement;
  rightBarSize?: number;
}

/**
 * DEPRECATED, USE FILTERABLETABLE
 *
 * @param {any} props
 * @return {ReactElement}
 */
const DataWithFilters: DataWithFiltersType = ({ ...props }): ReactElement => {
  const { lg } = useBreakpoint();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const showDrawer = (): void => setVisibleDrawer(true);
  const onClose = (): void => setVisibleDrawer(false);

  const childrenAsArray = props.children
    ? Array.prototype.concat.apply([], [props.children])
    : [];
  let filters: { [key: string]: FilterType } = {};
  childrenAsArray.forEach((child): void => {
    if (!child.props.filter) {
      throw new Error('DataWithFilters can only have Filter child');
    }

    filters = {
      ...filters,
      [child.props.filterName]: child,
    };
  });

  const initialFilterValues: Record<string, string[]> = {};
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [dataSource, setDataSource] = useState(props.dataSource);

  useEffect((): void => {
    setDataSource(
      props.dataSource.filter((element): unknown => {
        for (const [key, value] of Object.entries(filterValues)) {
          if (!filters[key].props.filter(value, element)) {
            return false;
          }
        }

        return true;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSource, filterValues]);

  const onChange = (filterName: string, values: string[]): void => {
    if (Object.values(values).join().length === 0) {
      // eslint-disable-next-line no-unused-vars
      const { [filterName]: deletedKey, ...rest } = filterValues;
      setFilterValues(rest);

      return;
    }

    setFilterValues({
      ...filterValues,
      [filterName]: values,
    });
  };

  const onReset = (): void => {
    setFilterValues(initialFilterValues);
  };

  const displayComponent = React.cloneElement(props.displayComponent, {
    dataSource: dataSource,
  });

  const getFilterNode = (filter, index): ReactNode => {
    return React.cloneElement(filter, {
      filterValue: filterValues[filter.props.filterName],
      onChange: (values: string[]): void => {
        values = filter.props.onChangeFormatValue
          ? filter.props.onChangeFormatValue(values)
          : values;

        onChange(filter.props.filterName, values);
      },
    });
  };

  return (
    <>
      <Row className={'filters-container'}>
        <Col flex={1}>
          {lg ? (
            <Input.Group className={'main-filters-container'} compact>
              {Object.values(filters).map(getFilterNode)}
            </Input.Group>
          ) : (
            <Button onClick={showDrawer}>
              <FilterOutlined /> Filtres
            </Button>
          )}
        </Col>
        {props.rightBar != null ? (
          <Col
            lg={props.rightBarSize != null ? props.rightBarSize : 12}
            span={24}
            style={{ marginTop: !lg ? '8px' : 0 }}
          >
            <Input.Group className={'main-filters-container rightbar'} compact>
              {props.rightBar}
            </Input.Group>
          </Col>
        ) : null}
      </Row>
      {Object.values(filterValues).length > 0 ? (
        <Row justify="center" className={'reset-container'}>
          <Col>
            <Button onClick={onReset} type="link">
              Réinitialiser
              <ReloadOutlined />
            </Button>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {props.displayInCard ? <Card>{displayComponent}</Card> : displayComponent}
      <Drawer
        className="filters-drawer"
        title="Filtres"
        visible={visibleDrawer}
        closable
        onClose={(): void => onClose()}
        footer={
          <>
            <Button onClick={onReset} className="reset-button" size="large">
              <ReloadOutlined />
              Réinitialiser
            </Button>
            <Button
              onClick={onClose}
              className="reset-button"
              type="primary"
              size="large"
            >
              <EyeOutlined />
              Voir
            </Button>
          </>
        }
      >
        {Object.values(filters).map(getFilterNode)}
      </Drawer>
    </>
  );
};

DataWithFilters.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  displayComponent: PropTypes.oneOfType([
    PropTypes.any.isRequired,
    PropTypes.any.isRequired,
  ]).isRequired,
  displayInCard: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.any,
  ]),
  rightBar: PropTypes.any,
  rightBarSize: PropTypes.number,
};

export default DataWithFilters;
