import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider } from '../../../base';
import moment from 'moment';
import { Timeline } from 'antd';
import 'antd/lib/timeline/style';
import { ModalProps } from 'antd/lib/modal';
import { interventionTypes } from '../../../../../states/ducks/interventions';
import './GetInterventionsTimeline.scss';

interface GetInterventionsTimelineProps extends ModalProps {
  interventions: interventionTypes.Intervention[] | null;
  hideModal: Function;
}

type InterventionStep = 'schedule' | 'handle' | 'finish';

const interventionSteps = {
  schedule: {
    label: 'Planifié',
    status: 'scheduled',
  },
  handle: {
    label: 'Pris en charge',
    status: 'handled',
  },
  finish: {
    label: 'Terminé',
    status: 'finished',
  },
};

const dateFormat = 'L [à] H[h]mm';

const GetInterventionsTimeline: FunctionComponent<GetInterventionsTimelineProps> =
  ({ interventions, hideModal, visible = false }): ReactElement => {
    const timelineSteps: InterventionStep[] = ['schedule', 'handle', 'finish'];
    const handleCancel = (): void => {
      hideModal();
    };

    const externalNumber = interventions?.length ? interventions[0].numero : '';

    const timelineElements = (
      intervention: interventionTypes.Intervention,
    ): object => {
      let isStepValidated = false;

      const items: { [key in InterventionStep]: { actionDate?: string } } = {
        schedule: { actionDate: '' },
        handle: { actionDate: '' },
        finish: { actionDate: '' },
      };

      timelineSteps.forEach((step): void => {
        if (!intervention || intervention[step] === undefined) {
          return;
        }

        items[step] = {
          actionDate: intervention[step]?.actionDate,
        };
      });

      return Object.entries(items).map(([step, item]): ReactElement => {
        if (
          isStepValidated ||
          interventionSteps[step as InterventionStep]['status'] ===
            intervention.status
        ) {
          isStepValidated = true;
        }

        return (
          <Timeline.Item
            key={`timeline-${intervention.id}-${step}`}
            className={
              isStepValidated &&
              interventionSteps[step as InterventionStep]['status'] !==
                intervention.status
                ? 'wait'
                : ''
            }
          >
            <div className="title">
              {step in interventionSteps
                ? interventionSteps[step as InterventionStep]['label']
                : ''}
            </div>
            <div className="description">
              {item['actionDate']
                ? moment(item['actionDate']).format(dateFormat)
                : ''}
            </div>
            <div className="report">
              {step === 'finish' && intervention.report ? (
                `Bilan : ${intervention.report}`
              ) : (
                <></>
              )}
            </div>
          </Timeline.Item>
        );
      });
    };

    const timelines =
      interventions !== null
        ? interventions.map(
            (
              intervention: interventionTypes.Intervention,
              index: number,
            ): ReactElement => {
              return (
                <>
                  <Divider orientation="left">
                    Intervention{' '}
                    {interventions.length > 1 ? ` n° ${index + 1}` : ''}
                  </Divider>
                  <Timeline key="" className={'incident-intervention-timeline'}>
                    {timelineElements(intervention)}
                  </Timeline>
                </>
              );
            },
          )
        : null;

    const title =
      (interventions !== null && interventions.length === 1
        ? "Détail de l'intervention"
        : 'Détail des interventions') +
      (externalNumber ? ` (numéro externe : ${externalNumber})` : '');

    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        {timelines}
      </Modal>
    );
  };

GetInterventionsTimeline.propTypes = {
  interventions: PropTypes.arrayOf(
    interventionTypes.PropTypesIntervention.isRequired,
  ).isRequired,
  visible: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
};

export default GetInterventionsTimeline;
