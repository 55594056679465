import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  EditableField,
  DateField,
  Label,
  Text,
  Switch,
  notification,
  PageHeader,
  PopoverActions,
  Card,
  Row,
  Col,
  Tooltip,
  RestrictedToPermissions,
} from '../../base';
import { locationTypeTypes } from '../../../../states/ducks/locationtypes';
import { InfoCircleFilled, WarningOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import moment from 'moment';
import { Redirect } from 'react-router';

interface LocationTypeInfoProps {
  locationType: locationTypeTypes.LocationType | null;
  mainLocationType: locationTypeTypes.LocationType | null;
  getLocationType: Function;
  locationTypeId: locationTypeTypes.LocationType['id'];
  isGettingLocationType: boolean;
  editLocationTypeDesignation: Function;
  editMainLocationType: Function;
  editQuickAccessLocationType: Function;
  deleteLocationType: Function;
}

const LocationTypeInfo: FunctionComponent<LocationTypeInfoProps> = ({
  locationType,
  mainLocationType,
  getLocationType,
  locationTypeId,
  isGettingLocationType,
  editLocationTypeDesignation,
  editMainLocationType,
  editQuickAccessLocationType,
  deleteLocationType,
}): ReactElement => {
  const [hasGotLocationType, setHasGotLocationType] = useState(false);
  const [isMainModalVisible, setIsMainModalVisible] = useState(false);
  const [isQuickAccessModalVisible, setIsQuickAccessModalVisible] =
    useState(false);

  useEffect((): void => {
    if (isGettingLocationType) {
      setHasGotLocationType(true);
    }
  }, [isGettingLocationType]);

  useEffect((): void => {
    getLocationType(locationTypeId);
  }, [getLocationType, locationTypeId]);

  if (
    !locationTypeId ||
    (hasGotLocationType && !isGettingLocationType && !locationType)
  ) {
    return (
      <Redirect
        to={{
          pathname: '/administration/location',
        }}
      />
    );
  }

  const designationField = locationType ? (
    <EditableField
      object={locationType}
      label="Désignation"
      content={locationType.name}
      editedFieldsOptions={[
        {
          field: 'name',
          label: 'Désignation',
          content: <Input />,
          required: true,
          initialValue: locationType.name,
        },
      ]}
      onSubmit={editLocationTypeDesignation}
      successMessage="Le type de lieu a bien été modifié"
    />
  ) : (
    ''
  );

  const { confirm } = Modal;
  const isMainField = locationType ? (
    <>
      <Label>
        Type de lieu principal
        <Tooltip title="Voir plus d'informations">
          <InfoCircleFilled
            onClick={(): void => {
              setIsMainModalVisible(true);
            }}
          />
        </Tooltip>
        <Modal
          title="Type de lieu principal"
          visible={isMainModalVisible}
          okText="Fermer"
          onOk={(): void => {
            setIsMainModalVisible(false);
          }}
          cancelText={<></>}
          closable={false}
        >
          <p>
            Ce type de lieu sera celui avec lequel vous allez le plus
            travaillez, en définissant un type de lieu comme principal les pages
            suivantes seront impactées :
          </p>
          <ul>
            <li>La liste des lieux principaux (accessible depuis le menu)</li>
            <li>{`La création d'un lieu (depuis le bouton "Nouveau")`}</li>
            <li>
              Différents affichages (Localisation/sélecteur de localisation...)
            </li>
          </ul>
          <p>{`Vous ne pouvez avoir qu'un seul type de lieu comme "Principal".`}</p>
        </Modal>
      </Label>
      <Text className="app-description">
        <RestrictedToPermissions
          subject={'administration'}
          action={'changeMainLocationType'}
          unauthorizedText={
            <Switch checked={locationType.isMain} disabled={true} />
          }
        >
          <Switch
            checked={locationType.isMain}
            disabled={locationType.isMain}
            onChange={(isChecked: boolean): void => {
              confirm({
                className: 'app-modal-delete',
                title: `"${mainLocationType?.name}" est actuellement votre type de lieu principal. Êtes-vous sûr de vouloir définir "${locationType.name}" comme votre nouveau type de lieu principal ?`,
                okText: 'Confirmer',
                okType: 'primary',
                cancelText: 'Annuler',
                cancelButtonProps: {
                  danger: true,
                },
                icon: <WarningOutlined />,
                onOk(): void {
                  const promise = new Promise<void>((resolve, reject): void => {
                    editMainLocationType(
                      {
                        id: locationType.id,
                        isMain: isChecked,
                      },
                      { resolve, reject },
                    );
                  });

                  promise
                    .then((): void => {
                      notification.success({
                        message: `"${locationType.name}" est à présent le type de lieu principal`,
                      });
                    })
                    .catch((): void => {
                      notification.error({
                        message: 'La mise à jour a échoué',
                      });
                    });
                },
              });
            }}
          />
        </RestrictedToPermissions>
      </Text>
    </>
  ) : (
    ''
  );

  const isQuickAccess = locationType ? (
    <>
      <Label>
        Accès rapide
        <Tooltip title="Voir plus d'informations">
          <InfoCircleFilled
            onClick={(): void => {
              setIsQuickAccessModalVisible(true);
            }}
          />
        </Tooltip>
        <Modal
          title="Accès rapide"
          visible={isQuickAccessModalVisible}
          okText="Fermer"
          onOk={(): void => {
            setIsQuickAccessModalVisible(false);
          }}
          cancelText={<></>}
          closable={false}
        >
          <p>
            Ajoute dans le menu latéral un accès rapide à la liste des lieux de
            ce type.
          </p>
        </Modal>
      </Label>
      <Text className="app-description">
        <Switch
          checked={locationType.isMain || locationType.isQuickAccess}
          disabled={locationType.isMain}
          onChange={(isChecked: boolean): void => {
            const promise = new Promise<void>((resolve, reject): void => {
              editQuickAccessLocationType(
                {
                  id: locationType.id,
                  isQuickAccess: isChecked,
                },
                { resolve, reject },
              );
            });

            promise
              .then((): void => {
                notification.success({
                  message: `"${locationType.name}" est à présent en accès rapide`,
                });
              })
              .catch((): void => {
                notification.error({
                  message: 'La mise à jour a échoué',
                });
              });
          }}
        />
      </Text>
    </>
  ) : (
    ''
  );

  return (
    <RestrictedToPermissions subject={'administration'} action={'access'}>
      <PageHeader
        title={locationType ? locationType.name : ''}
        subTitle={
          locationType
            ? 'Mis à jour le ' +
              moment(locationType.updatedAt).format('L [à] H[h]mm')
            : ''
        }
        extra={[
          <PopoverActions
            key="contractor-info-action-1"
            objectId={locationTypeId}
            onDelete={
              locationTypeId === mainLocationType?.id
                ? (): void => {
                    notification.error({
                      message:
                        'Impossible de supprimer un type de lieu défini comme “Principal”. Merci de définir un autre type de lieu principal avant de supprimer celui-ci.',
                    });
                  }
                : deleteLocationType
            }
            deleteConfirmMessage="Supprimer le type de lieu ?"
            deleteDoneMessage="Le type de lieu a bien été supprimé"
          />,
        ]}
      />
      <Card
        className="section"
        tabList={[
          {
            key: 'general',
            tab: 'Général',
          },
        ]}
        activeTabKey={'general'}
      >
        <Row>
          <Col span={12}>
            <Row>
              <Col span={24}>{designationField}</Col>
            </Row>
            <Row>
              <Col span={24}>{isMainField}</Col>
            </Row>
            <Row>
              <Col span={24}>{isQuickAccess}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <DateField
                  label="Créé"
                  date={locationType ? locationType.createdAt : ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </RestrictedToPermissions>
  );
};

LocationTypeInfo.propTypes = {
  locationType: locationTypeTypes.PropTypesLocationType,
  mainLocationType: locationTypeTypes.PropTypesLocationType,
  locationTypeId: PropTypes.string.isRequired,
  getLocationType: PropTypes.func.isRequired,
  isGettingLocationType: PropTypes.bool.isRequired,
  editLocationTypeDesignation: PropTypes.func.isRequired,
  editMainLocationType: PropTypes.func.isRequired,
  editQuickAccessLocationType: PropTypes.func.isRequired,
  deleteLocationType: PropTypes.func.isRequired,
};

export default LocationTypeInfo;
