import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';
import { Tag, TagState } from './types';

/**
 * @param {Tag[]} state
 * @param {AnyAction} action
 * @return {Tag[]}
 */
export const tagReducer: Reducer<Tag[]> = (
  state: Tag[] = [],
  action: AnyAction,
): Tag[] => {
  return modelReducer('tags', state, action) as Tag[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('tags', 'ADD_FAILED'):
    case getActionType('tags', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('tags', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('tags', 'LIST_FAILED'):
    case getActionType('tags', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('tags', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('tags', 'GET_FAILED'):
    case getActionType('tags', 'GET_SUCCEEDED'):
      return false;
    case getActionType('tags', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('tags', 'EDIT_FAILED'):
    case getActionType('tags', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('tags', 'EDIT_LINK_REQUESTED'):
      return true;
    case getActionType('tags', 'EDIT_UNLINK_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('tags', 'DELETE_FAILED'):
    case getActionType('tags', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('tags', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<TagState> = combineReducers({
  tags: tagReducer,
  status: combineReducers({
    adding: addingReducer,
    listing: listingReducer,
    getting: gettingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
  }),
});

export default reducers;
