import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  PageHeader,
  IncidentStatusTag,
  DateTooltip,
  PopoverActions,
  IncidentStatusSelect,
  InputSearch,
  IncidentTypesSelect,
  LocationsTreeSelect,
  OrganizationsTreeSelect,
  RangePicker,
  ProfilesSelect,
  IncidentLocationName,
  RestrictedToPermissions,
  FilterableTable,
  ReactedUsers,
} from '../../base';
import PropTypes from 'prop-types';
import { ColumnProps } from 'antd/lib/table';
import { incidentTypes } from '../../../../states/ducks/incidents';
import { locationTypes } from '../../../../states/ducks/locations';
import { profileTypes } from '../../../../states/ducks/profiles';
import { incidentTypeTypes } from '../../../../states/ducks/incidenttypes';
import { organizationTypes } from '../../../../states/ducks/organizations';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  FilterableSearch,
  initialSearch,
} from '../../base/table/FilterableTable';
import moment, { Moment } from 'moment';
import { Pagination, Filter } from '../../../../services/search/search';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

type StateType = {
  status: string;
  locationId: string;
};

interface Props extends RouteComponentProps {
  incidents: incidentTypes.Incident[];
  locationsFromResults: locationTypes.Location[];
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  descendantsIdByOrganizationId: Record<string, string[]>;
  profiles: profileTypes.Profile[];
  profilesFromResults: profileTypes.Profile[];
  incidentTypes: incidentTypeTypes.IncidentType[];
  organizationsFromResults: organizationTypes.Organization[];
  loadIncidents: Function;
  loadLocations: Function;
  loadUsers: Function;
  loadProfiles: Function;
  deleteIncident: Function;
  total: Pagination['total'];
  location: {
    pathname: string;
    search: string;
    state: StateType;
    hash: string;
  };
}

interface TableIncident {
  id: incidentTypes.Incident['id'];
  key: incidentTypes.Incident['id'];
  status: incidentTypes.Incident['status'];
  number: incidentTypes.Incident['number'];
  remark: incidentTypes.Incident['remark'];
  incidentTypeId: incidentTypeTypes.IncidentType['id'];
  incidentTypeName: incidentTypeTypes.IncidentType['name'];
  organizationId: organizationTypes.Organization['id'];
  organizationName: organizationTypes.Organization['name'];
  locationId: locationTypes.Location['id'];
  locationName: locationTypes.Location['name'];
  userId: incidentTypes.Incident['userId'];
  profileName: string;
  reportedAt: incidentTypes.Incident['reportedAt'];
  approveUserIds: incidentTypes.Incident['approveUserIds'];
  action: object;
}

const incidentStatusTag = (text: string): ReactElement => (
  <IncidentStatusTag status={text} />
);
const locationName = (text: string, record: TableIncident): ReactElement => (
  <IncidentLocationName locationId={record.locationId} />
);
const dateToolTip = (date: string, record: TableIncident): ReactElement => (
  <DateTooltip date={date} userName={record.profileName} />
);
const Incidents: FunctionComponent<Props> = ({
  incidents,
  locationsFromResults,
  locationsAndTheirDescendantsIdsSelector,
  descendantsIdByOrganizationId,
  profiles,
  profilesFromResults,
  incidentTypes,
  organizationsFromResults,
  loadIncidents,
  loadLocations,
  loadUsers,
  loadProfiles,
  deleteIncident,
  total,
  ...props
}): ReactElement => {
  const getFilters = (): Filter[] => {
    const filters: Filter[] = [];
    if (props.location.state && props.location.state.status) {
      filters.push({
        field: 'status',
        key: 'status',
        operator: 'IN',
        value: [props.location.state.status],
      });
    }

    if (props.location.state && props.location.state.locationId) {
      filters.push({
        field: 'locationId',
        key: 'location',
        operator: 'IN',
        value:
          locationsAndTheirDescendantsIdsSelector[
            props.location.state.locationId
          ],
      });
    }

    return filters;
  };

  const initialIncidentsSearch: FilterableSearch = {
    ...initialSearch,
    sort: {
      field: 'reportedAt',
      order: 'desc',
    },
    filters: getFilters(),
  };

  const [search, setSearch] = useState(initialIncidentsSearch);

  useEffect((): void => {
    loadIncidents(search);
  }, [loadIncidents, search]);

  useEffect((): void => {
    loadLocations();
  }, [loadLocations]);

  useEffect((): void => {
    if (loadUsers) {
      loadUsers();
    }
  }, [loadUsers]);

  useEffect((): void => {
    loadProfiles();
  }, [loadProfiles]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: TableIncident): any => ({
    onClick: (): void => {
      props.history.push(`/incidents/${record.id}`);
    },
  });

  const datas = incidents.map(
    (incident: incidentTypes.Incident): TableIncident => {
      const organization = organizationsFromResults.find(
        (organization: organizationTypes.Organization): boolean =>
          organization.id === incident.organizationId || false,
      );

      const location = locationsFromResults.find(
        (location: locationTypes.Location): boolean =>
          location.id === incident.locationId || false,
      );

      const profile = profilesFromResults.find(
        (profile: profileTypes.Profile): boolean =>
          profile.userId === incident.userId || false,
      );

      const incidentType = incidentTypes.find(
        (incidentType: incidentTypeTypes.IncidentType): boolean =>
          incidentType.id === incident.incidentTypeId || false,
      );

      return {
        id: incident.id,
        key: incident.id,
        status: incident.status,
        number: incident.number,
        remark: incident.remark,
        incidentTypeId: incidentType ? incidentType.id : '',
        incidentTypeName: incidentType ? incidentType.name : '',
        organizationId: organization ? organization.id : '',
        organizationName: organization ? organization.name : '',
        locationId: location ? location.id : '',
        locationName: location ? location.name : '',
        userId: profile ? profile.userId : '',
        profileName: profile ? profile.firstName + ' ' + profile.lastName : '',
        reportedAt: incident.reportedAt,
        approveUserIds: incident.approveUserIds,
        action: {
          id: incident.id,
          incidentTypeName: incidentType ? incidentType.name : '',
        },
      };
    },
  );

  const columns: ColumnProps<TableIncident>[] = [
    {
      key: 'number',
      title: 'N°',
      dataIndex: 'number',
      sorter: true,
      width: '7%',
    },
    {
      key: 'status',
      title: 'Statut',
      dataIndex: 'status',
      render: incidentStatusTag,
      sorter: true,
      width: '8%',
    },
    {
      key: 'locationName',
      title: 'Lieu',
      dataIndex: 'locationName',
      render: locationName,
      width: '14%',
    },
    {
      key: 'incidentTypeName',
      title: 'Type',
      dataIndex: 'incidentTypeName',
      width: '10%',
    },
    {
      key: 'reportedAt',
      title: 'Signalé',
      dataIndex: 'reportedAt',
      defaultSortOrder: 'descend',
      render: dateToolTip,
      sorter: true,
      width: '10%',
    },
    {
      key: 'approveUserIds',
      title: 'Approuvé par',
      dataIndex: 'approveUserIds',
      // eslint-disable-next-line react/display-name
      render: (approveUserIds): ReactElement => {
        return approveUserIds ? (
          <ReactedUsers approveUserIds={approveUserIds} />
        ) : (
          <></>
        );
      },
      width: '14%',
    },
    {
      key: 'remark',
      title: 'Précisions',
      dataIndex: 'remark',
      ellipsis: true,
    },
    {
      key: 'operation',
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableIncident): ReactElement => {
        return (
          <RestrictedToPermissions subject={'incident'} action={'edit'}>
            <PopoverActions
              objectId={record.id}
              onDelete={deleteIncident}
              deleteConfirmMessage="Supprimer l'incident ?"
              deleteDoneMessage="L'incident a bien été supprimé"
              size="small"
            />
          </RestrictedToPermissions>
        );
      },
      width: '5%',
    },
  ];

  return (
    <RestrictedToPermissions subject={'incident'} action={'list'}>
      <PageHeader title="Incidents" />
      <FilterableTable<TableIncident>
        initialSearch={initialIncidentsSearch}
        onDelete={deleteIncident}
        deleteConfirmationMessage="Supprimer les incidents sélectionnés ?"
        deleteSuccessMessage="Les incidents ont bien été supprimés"
        filters={[
          {
            display: (
              <InputSearch
                size="large"
                placeholder="Rechercher par numéro, précisions, ..."
              />
            ),
            field: ['remark', 'number'],
            key: 'search',
            operator: 'LIKE',
            onChange: (e: ChangeEvent<HTMLInputElement>): string[] => [
              e.currentTarget.value,
            ],
          },
          {
            display: (
              <OrganizationsTreeSelect
                size="large"
                placeholder="Organisation"
              />
            ),
            field: 'organizationId',
            key: 'organization',
            operator: 'IN',
            onChange: (value: string): string[] => [
              value,
              ...(descendantsIdByOrganizationId[value] || []),
            ],
          },
          {
            display: (
              <LocationsTreeSelect size="large" placeholder="Localisation" />
            ),
            field: 'locationId',
            key: 'location',
            operator: 'IN',
            onChange: (value: string): string[] =>
              locationsAndTheirDescendantsIdsSelector[value] || [],
          },
        ]}
        extra={[
          {
            display: (
              <RangePicker
                allowClear={false}
                placeholder={['Début', 'Fin']}
                size="middle"
              />
            ),
            field: 'reportedAt',
            key: 'reportedAt',
            operator: 'BETWEEN',
            onChange: (values: Moment[]): string[] =>
              values.map((value) => value.format()),
            onUpdate: (values: string[]): Moment[] =>
              values.map((value) => moment(value)),
          },
          {
            display: (
              <IncidentTypesSelect
                isFilter
                placeholder={'Type'}
                size="middle"
              />
            ),
            field: 'incidentTypeId',
            key: 'incidentType',
            operator: 'IN',
          },
          {
            display: (
              <IncidentStatusSelect
                isFilter
                placeholder={'Statut'}
                size="middle"
              />
            ),
            field: 'status',
            key: 'status',
            operator: 'IN',
          },
          {
            display: <ProfilesSelect isFilter placeholder={'Auteur'} />,
            field: 'userId',
            key: 'user',
            operator: 'IN',
          },
        ]}
        onSearchChange={setSearch}
        search={search}
        tableProps={{
          columns: columns,
          dataSource: datas,
          onRow: onRow,
          pagination: {
            total: total,
          },
        }}
      />
    </RestrictedToPermissions>
  );
};

Incidents.propTypes = {
  incidents: PropTypes.arrayOf(incidentTypes.PropTypesIncident.isRequired)
    .isRequired,
  locationsFromResults: PropTypes.arrayOf(
    locationTypes.PropTypesLocation.isRequired,
  ).isRequired,
  locationsAndTheirDescendantsIdsSelector: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  descendantsIdByOrganizationId: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  profiles: PropTypes.arrayOf(profileTypes.PropTypesProfile.isRequired)
    .isRequired,
  profilesFromResults: PropTypes.arrayOf(
    profileTypes.PropTypesProfile.isRequired,
  ).isRequired,
  incidentTypes: PropTypes.arrayOf(
    incidentTypeTypes.PropTypesIncidentType.isRequired,
  ).isRequired,
  organizationsFromResults: PropTypes.arrayOf(
    organizationTypes.PropTypesOrganization.isRequired,
  ).isRequired,
  loadIncidents: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadProfiles: PropTypes.func.isRequired,
  deleteIncident: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  location: PropTypes.any,
  history: PropTypes.exact(historyPropTypes).isRequired,
};

export default withRouter(Incidents);
