import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface IncidentType extends Model {
  name: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
}

/**
 * main state of application
 */

export interface IncidentTypeState {
  incidentTypes: IncidentType[];
  status: {
    adding: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

export const PropTypesIncidentType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});
