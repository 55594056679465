import React, { FunctionComponent, ReactElement } from 'react';
import { connect, DispatchProp } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import { TreeSelect } from '../index';
import { TreeSelectAntWithLimitProps } from './TreeSelect';
import 'antd/lib/tree-select/style';
import {
  Organization,
  PropTypesOrganization,
} from '../../../../states/ducks/organizations/types';
import { currentUserVisibleOrganizationTreesSelector } from '../../../../states/ducks/currentUser/selectors';
import { AnyAction } from 'redux';

interface OrganizationsTreeSelectProps
  extends TreeSelectAntWithLimitProps<string | string[]> {
  organizations: Organization[];
}

/**
 * @param {Organization[]} organizations
 * @param {DispatchProp<AnyAction>} dispatch
 * @param {TreeSelectAntWithLimitProps<string | string[]>} props
 * @return {ReactElement}
 */
const OrganizationsTreeSelect: FunctionComponent<
  OrganizationsTreeSelectProps & DispatchProp<AnyAction>
> = ({ organizations, dispatch, ...props }): ReactElement => {
  return <TreeSelect trees={organizations} {...props} />;
};

OrganizationsTreeSelect.propTypes = {
  organizations: PropTypes.arrayOf(PropTypesOrganization.isRequired).isRequired,
  dispatch: PropTypes.any,
};

interface MapStateToProps {
  organizations: Organization[];
}

const mapStateToProps = (state: State): MapStateToProps => ({
  organizations: currentUserVisibleOrganizationTreesSelector(state),
});

export default connect(mapStateToProps)(OrganizationsTreeSelect);
