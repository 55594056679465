import {
  FetchAddRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  listUsers,
  addUser,
  editUserInformation,
  editUserEmail,
  editUserPassword,
  getUser,
  sendPasswordResetLink,
  editUserOrganizations,
  editUserVisibleOrganizations,
  editUserAdminOrganizations,
  editUserLocations,
  editUserRole,
  resetUserPassword,
  editUserVisibleLocations,
  getPermissionsForUser,
} from '../../../services/api';
import { getActionType } from '../common/types';
import { User } from './types';

/**
 * trigger the fetch then add the users
 */
export function* fetchUserSaga(): SagaIterator {
  try {
    const users: types.User[] = yield call(listUsers);

    yield put(actions.fetchListUsersSuccessActionCreator(users));
  } catch (err) {
    yield put(actions.fetchListUsersErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchAddRequested} action
 */
export function* addUserSaga(
  action: FetchAddRequested<types.User>,
): SagaIterator {
  try {
    const id: types.User['id'] = yield call(addUser, action.payload);

    const user = {
      ...action.payload,
      id,
    } as types.User;

    yield put(actions.fetchAddUsersSuccessActionCreator(user));

    action.meta.resolve(user);
  } catch (err) {
    yield put(actions.fetchAddUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserInformationSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(editUserInformation, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserEmailSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(editUserEmail, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserPasswordSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(editUserPassword, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* resetUserPasswordSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(resetUserPassword, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserOrganizationsSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    if (action.payload.locationIds) {
      const user: User = yield call(getUser, action.payload.id);
      if (user.organizationIds !== action.payload.organizationIds) {
        action.payload.locationIds = [];
        yield call(editUserLocations, action.payload);
      }
    }

    yield call(editUserOrganizations, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserVisibleOrganizationsSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(editUserVisibleOrganizations, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserAdminOrganizationsSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(editUserAdminOrganizations, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserLocationsSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    if (!action.payload.locationIds) {
      return;
    }

    yield call(editUserLocations, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserVisibleLocationsSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(editUserVisibleLocations, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editUserRoleSaga(
  action: FetchEditRequested<types.User>,
): SagaIterator {
  try {
    yield call(editUserRole, action.payload);

    yield put(actions.fetchEditUsersSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getUserSaga(
  action: FetchGetRequested<types.User>,
): SagaIterator {
  const user: types.User = yield call(getUser, action.payload);

  try {
    user.permissions = yield call(getPermissionsForUser, user);

    yield put(actions.fetchGetUsersSuccessActionCreator(user));

    action.meta.resolve(user);
  } catch (err) {
    yield put(actions.fetchGetUsersErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchAskResetUserPasswordRequested} action
 */
export function* sendPasswordResetLinkSaga(
  action: actions.FetchAskResetUserPasswordRequested,
): SagaIterator {
  try {
    const response: boolean = yield call(sendPasswordResetLink, action.payload);

    yield put(actions.fetchAskResetUserPasswordSuccessActionCreator(response));

    action.meta.resolve(response);
  } catch (err) {
    yield put(actions.fetchAskResetUserPasswordErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* usersSagas(): SagaIterator {
  yield takeEvery(getActionType('users', 'LIST_REQUESTED'), fetchUserSaga);
  yield takeEvery(getActionType('users', 'ADD_REQUESTED'), addUserSaga);
  yield takeEvery(
    getActionType('users', 'EDIT_INFORMATION_REQUESTED'),
    editUserInformationSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_EMAIL_REQUESTED'),
    editUserEmailSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_PASSWORD_REQUESTED'),
    editUserPasswordSaga,
  );
  yield takeEvery(
    getActionType('users', 'RESET_PASSWORD_REQUESTED'),
    resetUserPasswordSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_ORGANIZATIONS_REQUESTED'),
    editUserOrganizationsSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_VISIBLE_ORGANIZATIONS_REQUESTED'),
    editUserVisibleOrganizationsSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_ADMIN_ORGANIZATIONS_REQUESTED'),
    editUserAdminOrganizationsSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_LOCATIONS_REQUESTED'),
    editUserLocationsSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_VISIBLE_LOCATIONS_REQUESTED'),
    editUserVisibleLocationsSaga,
  );
  yield takeEvery(
    getActionType('users', 'EDIT_ROLE_REQUESTED'),
    editUserRoleSaga,
  );
  yield takeEvery(getActionType('users', 'GET_REQUESTED'), getUserSaga);
  yield takeEvery(
    getActionType('users', 'ASK_RESET_USER_PASSWORD_REQUESTED'),
    sendPasswordResetLinkSaga,
  );
}
