import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { getActionType } from '../common/types';

/**
 * @return {FetchListRequested}
 */
export const fetchListOrganizationsActionCreator = createAction(
  getActionType('organizations', 'LIST_REQUESTED'),
  (): void => {
    return;
  },
  (meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @return {FetchListRequested}
 */
export const fetchListAdminOrganizationsActionCreator = createAction(
  getActionType('organizations', 'LIST_ADMIN_REQUESTED'),
  (): void => {
    return;
  },
  (meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Organization[]} organizations
 * @return {FetchListSucceeded}
 */
export const fetchListOrganizationsSuccessActionCreator = createAction(
  getActionType('organizations', 'LIST_SUCCEEDED'),
  (organizations: types.Organization[]): types.Organization[] => organizations,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListOrganizationsErrorActionCreator = createAction(
  getActionType('organizations', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Organization} organization
 * @return {FetchAddRequested}
 */
export const fetchAddOrganizationsActionCreator = createAction(
  getActionType('organizations', 'ADD_REQUESTED'),
  (organization: types.Organization): types.Organization => organization,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Organization} organization
 * @return {FetchAddSucceeded}
 */
export const fetchAddOrganizationsSuccessActionCreator = createAction(
  getActionType('organizations', 'ADD_SUCCEEDED'),
  (organization: types.Organization): types.Organization => organization,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddOrganizationsErrorActionCreator = createAction(
  getActionType('organizations', 'ADD_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Organization} organization
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditOrganizationsActionCreator = createAction(
  getActionType('organizations', 'EDIT_REQUESTED'),
  (organization: types.Organization): types.Organization => organization,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Organization} organization
 * @return {FetchEditSucceeded}
 */
export const fetchEditOrganizationsSuccessActionCreator = createAction(
  getActionType('organizations', 'EDIT_SUCCEEDED'),
  (organization: types.Organization): types.Organization => organization,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditOrganizationsErrorActionCreator = createAction(
  getActionType('organizations', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetOrganizationsActionCreator = createAction(
  getActionType('organizations', 'GET_REQUESTED'),
  (id: types.Organization['id']): types.Organization['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Organization} organization
 * @return {FetchGetSucceeded}
 */
export const fetchGetOrganizationsSuccessActionCreator = createAction(
  getActionType('organizations', 'GET_SUCCEEDED'),
  (organization: types.Organization): types.Organization => organization,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetOrganizationsErrorActionCreator = createAction(
  getActionType('organizations', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteOrganizationsActionCreator = createAction(
  getActionType('organizations', 'DELETE_REQUESTED'),
  (id: types.Organization['id']): types.Organization['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteOrganizationsSuccessActionCreator = createAction(
  getActionType('organizations', 'DELETE_SUCCEEDED'),
  (id: types.Organization['id']): types.Organization['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteOrganizationsErrorActionCreator = createAction(
  getActionType('organizations', 'DELETE_FAILED'),
  (message: string): string => message,
);
