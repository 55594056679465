import { UploadFile } from 'antd/lib/upload/interface';
import { File } from '../states/ducks/files/types';

export const getBase64 = (file: Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => resolve(reader.result);
    reader.onerror = (error): void => reject(error);
  });
};

export const convertFileToUploadFile = (file: File): UploadFile => {
  return {
    ...file,
    preview: file.previewUrl,
    size: file.fileSize,
    type: file.fileMimeType,
    uid: file.id,
    url: file.downloadUrl,
  };
};
