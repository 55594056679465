import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextArea,
  Drawer,
  Button,
  Row,
  Col,
  notification,
  IncidentTypesSelect,
  LocationsTreeSelect,
  UploadPicturesListField,
  FormItem,
} from '../../../base';
import { incidentTypes as types } from '../../../../../states/ducks/incidents';
import { Form } from 'antd';
import { Incident } from '../../../../../states/ducks/incidents/types';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../../states/ducks/currentUser/types';

interface FormReportIncidentProps {
  currentUser?: CurrentUser;
  hideModal: Function;
  visible: boolean;
  reportIncident: Function;
}

const FormReportIncident: FunctionComponent<FormReportIncidentProps> = ({
  currentUser,
  hideModal,
  visible,
  reportIncident,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = (values: Partial<Incident>): void => {
    setLoading(true);

    const incident = {
      ...values,
    };

    incident.userId = currentUser?.id;

    const promise = new Promise<types.Incident>((resolve, reject): void =>
      reportIncident(incident, { resolve, reject }),
    );

    promise
      .then((): void => {
        form.resetFields();
        hideModal();
        const message = `L'incident a bien été signalé`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      })
      .finally((): void => setLoading(false));
  };

  const handleCancel = (): void => {
    form.resetFields();
    hideModal();
  };

  const selectLocationInput = (
    <FormItem
      label="Localisation"
      name="locationId"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez sélectionner un lieu',
        },
      ]}
    >
      <LocationsTreeSelect placeholder="Sélectionnez un lieu" />
    </FormItem>
  );
  const selectIncidentTypeInput = (
    <FormItem
      label="Type d'incident"
      name="incidentTypeId"
      rules={[
        {
          type: 'string',
          required: true,
          message: "Veuillez sélectionner un type d'incident",
        },
      ]}
    >
      <IncidentTypesSelect placeholder="Sélectionnez un type d'incident" />
    </FormItem>
  );
  const remarkInput = (
    <FormItem label="Précisions" name="remark">
      <TextArea placeholder="Saisissez des précisions" />
    </FormItem>
  );
  const photoInput = (
    <UploadPicturesListField label="Photos" field="pictures" />
  );
  return (
    <Drawer
      title="Signaler un incident"
      onClose={handleCancel}
      visible={visible}
      footer={
        <>
          <Button htmlType="button" size="large" onClick={handleCancel}>
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            onClick={form.submit}
          >
            Ajouter
          </Button>
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={12}>{selectLocationInput}</Col>
          <Col span={12}>{selectIncidentTypeInput}</Col>
          <Col span={24}>{remarkInput}</Col>
          <Col span={24}>{photoInput}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};

FormReportIncident.propTypes = {
  currentUser: PropTypesCurrentUser.isRequired,
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  reportIncident: PropTypes.func.isRequired,
};

export default FormReportIncident;
