import { UserOutlined } from '@ant-design/icons';
import { Avatar as AvatarAnt } from 'antd';
import { AvatarProps as AvatarAntProps } from 'antd/lib/avatar';
import 'antd/lib/avatar/style';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { getThumbPath } from '../../../../services/api';
import { profileActions } from '../../../../states/ducks/profiles';
import {
  profileFromIdSelector,
  profileFromUserIdSelector,
} from '../../../../states/ducks/profiles/selectors';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import { User } from '../../../../states/ducks/users/types';
import { State } from '../../../../states/types';
import './Avatar.scss';

export interface AvatarProps extends AvatarAntProps {
  getProfile?: Function;
  getProfileByUserId?: Function;
  profile?: Profile | null;
  profileId?: Profile['id'];
  userId?: User['id'];
}

/**
 * @param {Function} getProfile
 * @param {Function} getProfileByUserId
 * @param {Profile} profile
 * @param {string} profileId
 * @param {string} userId
 * @param {Props} props
 *
 * @return {ReactElement}
 */
const Avatar: FunctionComponent<AvatarProps> = ({
  getProfile,
  getProfileByUserId,
  profile,
  profileId,
  userId,
  ...props
}): ReactElement => {
  useEffect((): void => {
    if (getProfile && profileId && !profile) {
      getProfile(profileId);
    }

    if (getProfileByUserId && userId && !profile) {
      getProfileByUserId(userId);
    }
  }, [getProfile, getProfileByUserId, profile, profileId, userId]);

  props.className = classNames(
    profile ? 'user' : '',
    'app-avatar',
    props.className || '',
  );

  return (
    <AvatarAnt
      icon={!profile ? <UserOutlined /> : ''}
      src={profile && profile.avatarId ? getThumbPath(profile.avatarId) : ''}
      {...props}
    >
      {profile
        ? profile.firstName.charAt(0).toUpperCase() +
          profile.lastName.charAt(0).toUpperCase()
        : ''}
    </AvatarAnt>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  getProfile: PropTypes.func.isRequired,
  getProfileByUserId: PropTypes.func.isRequired,
  profile: PropTypesProfile,
  profileId: PropTypes.string,
  userId: PropTypes.string,
};

interface MapStateToProps {
  profile: Profile | null;
}

interface MapDispatchToProps {
  getProfile: Function;
  getProfileByUserId: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    profileId?: Profile['id'];
    userId?: User['id'];
  },
): MapStateToProps => {
  let profile: Profile | null = null;

  if (props.profileId) {
    profile = profileFromIdSelector(state, props.profileId);
  }

  if (props.userId) {
    profile = profileFromUserIdSelector(state, props.userId);
  }

  return { profile: profile };
};

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesActionCreator,
  getProfileByUserId: profileActions.fetchGetProfilesByUserIdActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
