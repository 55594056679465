import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}
/**
 * Label component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Label: FunctionComponent<Props> = ({
  children,
  className = '',
}): ReactElement => {
  const classes = classNames(className, 'app-label');
  return <Text className={classes}>{children}</Text>;
};

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  className: PropTypes.string,
};

export default Label;
