import PropTypes from 'prop-types';

export interface Model {
  id: string;
}

export interface TreeModel extends Model {
  name: string;
  parentId?: string | null;
  parents?: TreeModel[] | null;
  children?: TreeModel[] | null;
}

export const PropTypesTreeModel = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  children: PropTypes.array,
  parents: PropTypes.array,
});

export interface ModelState {
  status: {
    adding: boolean;
    listing: number;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

/**
 * @param {string} concept
 * @param {string} action
 * @return {string}
 */
export function getActionType(concept: string, action: string): string {
  return `app/${concept}/${action}`;
}

/**
 * @param {string} actionType
 * @return {string}
 */
export function getActionTypeAttributes(actionType: string): {
  concept: string;
  operation: string;
  state: string;
} | null {
  const attributes = actionType.match(/app\/(.*)\/([A-Z]+)[A-Z_]*_([A-Z]+)/);

  if (null === attributes) {
    return null;
  }

  return {
    concept: attributes[1],
    operation: attributes[2],
    state: attributes[3],
  };
}
