import { TransferItem, TransferProps } from 'antd/lib/transfer';
import 'antd/lib/transfer/style';
import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { incidentTypesFromOrganizationSelector } from '../../../../states/ducks/incidenttypes/selectors';
import {
  IncidentType,
  PropTypesIncidentType,
} from '../../../../states/ducks/incidenttypes/types';
import { State } from '../../../../states/types';
import { Transfer } from '../index';

type IncidentTypesTransferProps = Omit<
  TransferProps<TransferItem>,
  'dataSource' | 'listStyle'
> &
  MapStateToProps;

/**
 * @param {IncidentType[]} incidentTypes
 * @param {TransferProps<TransferItem>} props
 * @return {ReactElement}
 */
const IncidentTypesTransfer: FunctionComponent<IncidentTypesTransferProps> = ({
  incidentTypes,
  ...props
}): ReactElement => {
  return (
    <Transfer
      dataSource={incidentTypes.map(
        (incidentType): TransferItem => ({
          key: incidentType.id,
          title: incidentType.name,
        }),
      )}
      targetKeys={[]}
      listStyle={{}}
      {...props}
    />
  );
};

IncidentTypesTransfer.propTypes = {
  incidentTypes: PropTypes.arrayOf(PropTypesIncidentType.isRequired).isRequired,
};

interface MapStateToProps {
  incidentTypes: IncidentType[];
}

const mapStateToProps = (state: State): MapStateToProps => ({
  incidentTypes: incidentTypesFromOrganizationSelector(state),
});

export default connect(mapStateToProps)(IncidentTypesTransfer);
