import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
  FetchListRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  reportIncident,
  listIncidents,
  getIncident,
  acceptIncident,
  rejectIncident,
  resolveIncident,
  retypeIncident,
  remarkIncident,
  relocateIncident,
  addFilesIncident,
  changeReportingIncident,
  changeServiceOrderNumberIncident,
  deleteIncident,
  getLocation,
  removeFileIncident,
  deleteFile,
  addFile,
} from '../../../services/api';
import { notification } from '../../../views/components/base';
import { getActionType } from '../common/types';
import { Location } from '../locations/types';
import { Incident } from './types';
import { getCurrentUserId } from '../../../services/authenticate';

/**
 * @param {FetchAddRequested} action
 */
export function* reportIncidentSaga(
  action: FetchAddRequested<Incident>,
): SagaIterator {
  try {
    if (!action.payload.organizationId) {
      const location: Location = yield call(
        getLocation,
        action.payload.locationId,
      );
      action.payload.organizationId = location.organizationId;
    }

    const id: types.Incident['id'] = yield call(reportIncident, action.payload);

    const incident = {
      ...action.payload,
      id,
    } as types.Incident;

    if (incident.pictures && incident.pictures.length > 0) {
      try {
        const pictureIds = yield all(
          incident.pictures.map((picture) =>
            call(addFile, {
              file: picture,
              organizationId: incident.organizationId,
              userId: incident.userId,
              objectId: incident.id,
              objectType: 'incident',
            }),
          ),
        );

        yield call(addFilesIncident, incident, pictureIds);
      } catch (err) {
        notification.error({
          message: "Vos photos n'ont pas été correctement importées",
        });
      }
    }

    yield put(actions.fetchReportIncidentsSuccessActionCreator(incident));

    action.meta.resolve(incident);
  } catch (err) {
    yield put(actions.fetchReportIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchListRequested} action
 */
export function* fetchIncidentSaga(action?: FetchListRequested): SagaIterator {
  try {
    const response = action
      ? yield call(listIncidents, action.payload)
      : yield call(listIncidents);

    if (action && action.payload.pagination) {
      yield put(
        actions.fetchPaginatedListIncidentsSuccessActionCreator(response),
      );
    } else {
      yield put(actions.fetchListIncidentsSuccessActionCreator(response));
    }
  } catch (err) {
    yield put(actions.fetchListIncidentsErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getIncidentSaga(
  action: FetchGetRequested<types.Incident>,
): SagaIterator {
  try {
    const incident: types.Incident = yield call(getIncident, action.payload);

    yield put(actions.fetchGetIncidentsSuccessActionCreator(incident));

    action.meta.resolve(incident);
  } catch (err) {
    yield put(actions.fetchGetIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* acceptIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(acceptIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* rejectIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(rejectIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* resolveIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(resolveIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* retypeIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(retypeIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* remarkIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(remarkIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* relocateIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(relocateIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addFileIncidentSaga(
  action: actions.FetchAddFileRequested,
): SagaIterator {
  try {
    const incident = action.payload.incident;
    const file = action.payload.file;

    const fileId = yield call(addFile, {
      file: file,
      organizationId: incident.organizationId || '',
      userId: getCurrentUserId() || '',
      objectId: incident.id || '',
      objectType: 'incident',
    });

    yield call(addFilesIncident, incident, [fileId]);

    if (!incident.fileIds) {
      incident.fileIds = [];
    }
    incident.fileIds.push(fileId);

    yield put(
      actions.fetchEditIncidentsSuccessActionCreator(action.payload.incident),
    );

    action.meta.resolve(action.payload.file);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(action.payload.file);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addGEDFileIncidentSaga(
  action: actions.FetchAddGEDFileRequested,
): SagaIterator {
  try {
    const incident = action.payload.incident;
    const fileIds = action.payload.fileIds;

    yield call(addFilesIncident, incident, fileIds);

    if (!incident.fileIds) {
      incident.fileIds = [];
    }

    incident.fileIds = incident.fileIds.concat(fileIds);

    yield put(
      actions.fetchEditIncidentsSuccessActionCreator(action.payload.incident),
    );

    action.meta.resolve(action.payload.fileIds);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(action.payload.fileIds);
  }
}

/**
 * @param {FetchDeleteFileRequested} action
 */
export function* deleteFileIncidentSaga(
  action: actions.FetchDeleteFileRequested,
): SagaIterator {
  try {
    const fileId = action.payload.fileId;
    const incident = action.payload.incident;

    yield call(deleteFile, fileId);

    yield call(removeFileIncident, incident, fileId);

    if (!incident.fileIds) {
      incident.fileIds = [];
    }
    incident.fileIds = incident.fileIds.filter((id) => id !== fileId);

    yield put(
      actions.fetchEditIncidentsSuccessActionCreator(action.payload.incident),
    );

    action.meta.resolve(action.payload.incident);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* changeReportingIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(changeReportingIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* changeServiceOrderNumberIncidentSaga(
  action: FetchEditRequested<types.Incident>,
): SagaIterator {
  try {
    yield call(changeServiceOrderNumberIncident, action.payload);

    yield put(actions.fetchEditIncidentsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteIncidentSaga(
  action: FetchDeleteRequested<types.Incident>,
): SagaIterator {
  try {
    if (!Array.isArray(action.payload)) {
      yield call(deleteIncident, action.payload);
      yield put(
        actions.fetchDeleteIncidentsSuccessActionCreator(action.payload),
      );
    }

    if (Array.isArray(action.payload)) {
      yield all(action.payload.map((id) => call(deleteIncident, id)));
      yield all(
        action.payload.map((id) =>
          put(actions.fetchDeleteIncidentsSuccessActionCreator(id)),
        ),
      );
    }

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteIncidentsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* incidentsSagas(): SagaIterator {
  yield takeEvery(
    getActionType('incidents', 'REPORT_REQUESTED'),
    reportIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'LIST_REQUESTED'),
    fetchIncidentSaga,
  );
  yield takeEvery(getActionType('incidents', 'GET_REQUESTED'), getIncidentSaga);
  yield takeEvery(
    getActionType('incidents', 'ACCEPT_REQUESTED'),
    acceptIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'REJECT_REQUESTED'),
    rejectIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'RESOLVE_REQUESTED'),
    resolveIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'RETYPE_REQUESTED'),
    retypeIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'REMARK_REQUESTED'),
    remarkIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'RELOCATE_REQUESTED'),
    relocateIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'ADD_FILE_REQUESTED'),
    addFileIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'ADD_GED_FILE_REQUESTED'),
    addGEDFileIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'DELETE_FILE_REQUESTED'),
    deleteFileIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'CHANGE_REPORTING_REQUESTED'),
    changeReportingIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'CHANGE_SERVICE_ORDER_NUMBER_REQUESTED'),
    changeServiceOrderNumberIncidentSaga,
  );
  yield takeEvery(
    getActionType('incidents', 'DELETE_REQUESTED'),
    deleteIncidentSaga,
  );
}
