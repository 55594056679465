import React, { FunctionComponent, ReactElement } from 'react';
import { Tree as TreeAnt } from 'antd';
import 'antd/lib/tree/style';
import { TreeProps } from 'antd/lib/tree';

/**
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Tree: FunctionComponent<TreeProps> = ({ ...props }): ReactElement => (
  <TreeAnt {...props} />
);

Tree.propTypes = {};

export default Tree;
