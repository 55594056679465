import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  PageHeader,
  Card,
  Row,
  Col,
  PopoverActions,
  EditableField,
  Input,
  Transfer,
  Switch,
  Text,
  Label,
  DateField,
  notification,
  OrganizationsTreeSelect,
  RestrictedToPermissions,
} from '../../base';
import { contractorTypes as types } from '../../../../states/ducks/contractors';
import { incidentTypeTypes } from '../../../../states/ducks/incidenttypes';
import { organizationTypes } from '../../../../states/ducks/organizations';
import { TransferItem } from 'antd/lib/transfer';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { Redirect } from 'react-router';
import { convertFileToUploadFile } from '../../../../utils/FileUtils';
import UploadListField from '../../base/upload/UploadListField';
import { File } from '../../../../states/ducks/files/types';

interface ContractorInfoProps {
  contractorId: types.Contractor['id'];
  contractor: types.Contractor | null;
  organization: organizationTypes.Organization | null;
  getContractor: Function;
  getOrganization: Function;
  editContractorName: Function;
  editContractorTwimmLink: Function;
  editContractorIncidentTypes: Function;
  editContractorOrganization: Function;
  deleteContractor: Function;
  incidentTypes: incidentTypeTypes.IncidentType[];
  isGettingContractor: boolean;
  files: File[];
  getFile: Function;
  addFileContractor: Function;
  addGEDFileContractor: Function;
  deleteFileContractor: Function;
  editContractorEmail: Function;
}

const ContractorInfo: FunctionComponent<ContractorInfoProps> = ({
  contractorId,
  contractor,
  organization,
  getContractor,
  getOrganization,
  editContractorName,
  editContractorTwimmLink,
  editContractorIncidentTypes,
  editContractorOrganization,
  deleteContractor,
  incidentTypes,
  isGettingContractor,
  files,
  getFile,
  addFileContractor,
  addGEDFileContractor,
  deleteFileContractor,
  editContractorEmail,
}): ReactElement => {
  const [hasGotContractor, setHasGotContractor] = useState(false);
  const [useTwimm, setUseTwimm] = useState(false);
  const [sendInterventionRequest, setSendInterventionRequest] = useState(false);
  const [reloadFilesNeeded, setReloadFilesNeeded] = useState(false);

  useEffect((): void => {
    if (isGettingContractor) {
      setHasGotContractor(true);
    }
  }, [isGettingContractor]);

  useEffect((): void => {
    getContractor(contractorId);
  }, [getContractor, contractorId]);

  useEffect((): void => {
    if (contractor) {
      getOrganization(contractor.organizationId);
    }
  }, [getOrganization, contractor]);

  useEffect((): void => {
    if (contractor) {
      setUseTwimm(contractor.useTwimm || false);
      setSendInterventionRequest(contractor.sendInterventionRequest || false);
    }
  }, [contractor]);

  // TODO: set files in another state to avoid conflicts with files in the GED modal
  useEffect((): void => {
    if (reloadFilesNeeded) {
      if (contractor && contractor.fileIds && contractor.fileIds.length > 0) {
        contractor.fileIds.forEach((id) => getFile(id));
      }
      setReloadFilesNeeded(false);
    }
  }, [getFile, contractor, reloadFilesNeeded]);

  if (
    !contractorId ||
    (hasGotContractor && !isGettingContractor && !contractor)
  ) {
    return (
      <Redirect
        to={{
          pathname: '/contractors/',
        }}
      />
    );
  }

  const nameField = contractor ? (
    <EditableField
      object={contractor}
      label="Nom"
      content={contractor.name}
      editedFieldsOptions={[
        {
          field: 'name',
          label: 'Nom',
          content: <Input />,
          required: true,
          initialValue: contractor.name,
        },
      ]}
      onSubmit={editContractorName}
      successMessage="Le nom a bien été modifié"
      restrictedToPermissions={{
        subject: 'contractor',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const organizationField = contractor ? (
    <EditableField
      object={contractor}
      label="Organisation"
      content={organization ? organization.name : ''}
      editedFieldsOptions={{
        field: 'organizationId',
        label: 'Organisation',
        content: <OrganizationsTreeSelect />,
        initialValue: contractor.organizationId,
        required: true,
      }}
      onSubmit={editContractorOrganization}
      successMessage="L'organisation a bien été modifiée"
      restrictedToPermissions={{
        subject: 'contractor',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const renderIncidentTypeList = (item: types.Contractor): ReactElement =>
    item.incidentTypeIds ? (
      <ul className="app-contractors-incidenttypes-list">
        {item.incidentTypeIds.map((id: string): ReactElement => {
          const incidentType = incidentTypes
            .filter((incidentType): boolean => incidentType.id === id)
            .pop();

          return <li key={id}>{incidentType ? incidentType.name : ''}</li>;
        })}
      </ul>
    ) : (
      <Text className="app-contractors-incidenttypes-none">Aucun</Text>
    );

  const validateIncidentTypesField = (
    rule: object,
    value: string[],
    callback: Function,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: any,
  ): void => {
    form.setFieldsValue({ incidentTypeIds: value });
    callback();
  };

  const incidentTypesField =
    contractor && incidentTypes ? (
      <EditableField
        object={contractor}
        label="Type d'incident pris en charge"
        content={renderIncidentTypeList(contractor)}
        editedFieldsOptions={{
          field: 'incidentTypeIds',
          label: "Type d'incident pris en charge",
          type: 'array',
          initialValue: [],
          content: (
            <Transfer
              dataSource={incidentTypes.map(
                (incidentType): TransferItem => ({
                  key: incidentType.id,
                  title: incidentType.name,
                }),
              )}
              targetKeys={contractor.incidentTypeIds || undefined}
              listStyle={{}}
            />
          ),
          validator: validateIncidentTypesField,
        }}
        onSubmit={editContractorIncidentTypes}
        successMessage="Le type d'incident a bien été modifié"
        restrictedToPermissions={{
          subject: 'contractor',
          action: 'edit',
        }}
      />
    ) : (
      ''
    );
  const useTwimmInput = (
    <RestrictedToPermissions subject={'admin'} action={'admin'}>
      <Label>Utilise Twimm</Label>
      <Text className="app-description">
        <Switch
          checked={useTwimm}
          onChange={(isChecked: boolean): void => {
            const promise = new Promise<void>((resolve, reject): void => {
              editContractorTwimmLink(
                {
                  id: contractor ? contractor.id : null,
                  instance: contractor?.instance,
                  sendInterventionRequest,
                  useTwimm: isChecked,
                },
                { resolve, reject },
              );
            });

            promise
              .then((): void => {
                setUseTwimm(isChecked);
                notification.success({
                  message: 'La liaison avec Twimm est mise à jour',
                });
              })
              .catch((): void => {
                notification.error({
                  message: 'La mise à jour à échouée',
                });
              });
          }}
        />
      </Text>
    </RestrictedToPermissions>
  );

  const instanceField = contractor ? (
    <EditableField
      object={contractor}
      label="Instance"
      content={contractor.instance || undefined}
      editedFieldsOptions={[
        {
          field: 'instance',
          label: 'Instance',
          content: <Input />,
          required: true,
          initialValue: contractor.instance,
        },
      ]}
      onSubmit={editContractorTwimmLink}
      successMessage="L'instance a bien été modifiée"
      restrictedToPermissions={{
        subject: 'admin',
        action: 'admin',
      }}
    />
  ) : (
    ''
  );
  const sendInterventionRequestInput = (
    <>
      <Label>
        Créer des demandes d&apos;interventions chez le prestataire à
        l&apos;acceptation d&apos;un incident
      </Label>
      <Text className="app-description">
        <RestrictedToPermissions
          subject={'admin'}
          action={'admin'}
          unauthorizedText={
            <Switch checked={sendInterventionRequest} disabled={true} />
          }
        >
          <Switch
            checked={sendInterventionRequest}
            onChange={(isChecked: boolean): void => {
              const promise = new Promise<void>((resolve, reject): void => {
                editContractorTwimmLink(
                  {
                    id: contractor ? contractor.id : null,
                    instance: contractor?.instance,
                    sendInterventionRequest: isChecked,
                    useTwimm,
                  },
                  { resolve, reject },
                );
              });

              promise
                .then((): void => {
                  setSendInterventionRequest(isChecked);
                  notification.success({
                    message: 'La liaison avec Twimm est mise à jour',
                  });
                })
                .catch((): void => {
                  notification.error({
                    message: 'La mise à jour à échouée',
                  });
                });
            }}
          />
        </RestrictedToPermissions>
      </Text>
    </>
  );
  const filesField = contractor ? (
    <UploadListField
      displayLimit={6}
      fileList={files.map((file) => convertFileToUploadFile(file))}
      label="Documents"
      onGEDUpload={(fileIds, meta): void =>
        addGEDFileContractor(
          {
            fileIds: fileIds,
            contractor: contractor,
          },
          meta,
        )
      }
      onGEDClose={(): void => setReloadFilesNeeded(true)}
      onUpload={(file, meta): void =>
        addFileContractor(
          {
            file: file,
            contractor: contractor,
          },
          meta,
        )
      }
      onDelete={(file, meta): void => {
        deleteFileContractor(
          {
            fileId: file.uid,
            contractor: contractor,
          },
          meta,
        );
      }}
    />
  ) : (
    ''
  );

  const emailField = contractor ? (
    <EditableField
      object={contractor}
      label="Email"
      content={contractor.email}
      editedFieldsOptions={[
        {
          field: 'email',
          label: 'Email',
          content: <Input placeholder="Saisissez un mail" />,
          required: true,
          initialValue: contractor.email,
          type: 'email',
        },
      ]}
      onSubmit={editContractorEmail}
      successMessage="L'adresse mail a bien été modifiée"
      restrictedToPermissions={{
        subject: 'contractor',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  moment.locale('fr');
  const subTitle = contractor
    ? 'Mis à jour le ' + moment(contractor.updatedAt).format('L [à] H[h]mm')
    : '';
  return (
    <>
      <PageHeader
        title={contractor ? contractor.name : ''}
        subTitle={subTitle}
        extra={[
          <RestrictedToPermissions
            subject={'contractor'}
            action={'edit'}
            key="contractor-info-action-1"
          >
            <PopoverActions
              objectId={contractorId}
              onDelete={deleteContractor}
              deleteConfirmMessage="Supprimer le prestataire ?"
              deleteDoneMessage="Le prestataire a bien été supprimé"
            />
          </RestrictedToPermissions>,
        ]}
      />
      <Card className="section">
        <Row>
          <Col span={12}>
            <Row>
              <Col span={24}>{nameField}</Col>
            </Row>
            <Row>
              <Col span={24}>{organizationField}</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DateField
                  label="Créé"
                  date={contractor ? contractor.createdAt : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>{incidentTypesField}</Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row>
              <Col span={12}>{useTwimmInput}</Col>
              <Col
                span={12}
                style={!useTwimm ? { display: 'none' } : undefined}
              >
                {instanceField}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {useTwimm ? sendInterventionRequestInput : emailField}
              </Col>
            </Row>
            <Row>
              <Col span={24}>{filesField}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

ContractorInfo.propTypes = {
  contractorId: PropTypes.string.isRequired,
  contractor: types.PropTypesContractor,
  organization: organizationTypes.PropTypesOrganization,
  getContractor: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  editContractorName: PropTypes.func.isRequired,
  editContractorTwimmLink: PropTypes.func.isRequired,
  editContractorIncidentTypes: PropTypes.func.isRequired,
  editContractorOrganization: PropTypes.func.isRequired,
  deleteContractor: PropTypes.func.isRequired,
  incidentTypes: PropTypes.arrayOf(
    incidentTypeTypes.PropTypesIncidentType.isRequired,
  ).isRequired,
  isGettingContractor: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  getFile: PropTypes.func.isRequired,
  addFileContractor: PropTypes.func.isRequired,
  addGEDFileContractor: PropTypes.func.isRequired,
  deleteFileContractor: PropTypes.func.isRequired,
  editContractorEmail: PropTypes.func.isRequired,
};

export default ContractorInfo;
