import { Organization } from './types';
import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {Organization[]} [state=[]] organizations
 * @param {AnyAction} action
 * @return {Organization[]}
 */
export const organizationReducer: Reducer<types.Organization[]> = (
  state: types.Organization[] = [],
  action: AnyAction,
): types.Organization[] => {
  return modelReducer('organizations', state, action) as types.Organization[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<number> = (
  state = 0,
  action: Action<string>,
): number => {
  switch (action.type) {
    case getActionType('organizations', 'LIST_REQUESTED'):
    case getActionType('organizations', 'LIST_ADMIN_REQUESTED'):
      return 1;
    case getActionType('organizations', 'LIST_FAILED'):
      return -1;
    case getActionType('organizations', 'LIST_SUCCEEDED'):
      return 2;
    default:
      return 0;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('organizations', 'ADD_FAILED'):
    case getActionType('organizations', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('organizations', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('organizations', 'EDIT_FAILED'):
    case getActionType('organizations', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('organizations', 'EDIT_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('organizations', 'DELETE_FAILED'):
    case getActionType('organizations', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('organizations', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('organizations', 'GET_FAILED'):
    case getActionType('organizations', 'GET_SUCCEEDED'):
      return false;
    case getActionType('organizations', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.OrganizationsState> = combineReducers({
  organizations: organizationReducer,
  status: combineReducers({
    listing: listingReducer,
    adding: addingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
    getting: gettingReducer,
  }),
});

export default reducers;
