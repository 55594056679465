import React, { ReactElement, FunctionComponent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Authenticate } from '../../../../services/authenticate/types';
import { withAuthenticate } from '../../../../services/authenticate';
import {
  Button,
  Input,
  InputPassword,
  FormItem,
  Title,
  notification,
  Homepage,
} from '../../base';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { User } from '../../../../states/ducks/users/types';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

interface LoginProps extends RouteComponentProps {
  authenticate: Authenticate;
}

/**
 * @param {Authenticate} authenticate
 * @param {RouteComponentProps} props
 *
 * @return {boolean}
 */
const Login: FunctionComponent<LoginProps> = ({
  authenticate,
  ...props
}): ReactElement => {
  const [loading, setLoading] = useState(false);

  const handleFinish = (values: Partial<User>): void => {
    setLoading(true);
    authenticate
      .authentication(values.email, values.password)
      .then((): void => {
        notification.success({
          message: `Connexion réussie`,
        });
        props.history.push('/');
      })
      .catch((message: string): void => {
        notification.error({
          message: `Échec de connexion`,
          description: message,
        });
      })
      .finally((): void => setLoading(false));
  };

  const emailInput = (
    <FormItem
      label="Email"
      name="email"
      rules={[
        {
          type: 'email',
          message: "L'e-mail n'est pas valide",
        },
        {
          required: true,
          message: 'Insérez votre e-mail',
        },
      ]}
    >
      <Input autoComplete="off" placeholder="Saisissez un e-mail" />
    </FormItem>
  );

  const passwordInput = (
    <FormItem
      label="Mot de passe"
      name="password"
      rules={[
        {
          required: true,
          message: 'Insérez votre mot de passe',
        },
      ]}
    >
      <InputPassword placeholder="Saisissez un mot de passe" />
    </FormItem>
  );

  const loginForm = (
    <>
      <Title fancy>Connexion</Title>
      <Form onFinish={handleFinish}>
        {emailInput}
        {passwordInput}
        <Button
          className="app-btn-submit"
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
        >
          C&apos;est parti !
        </Button>
        <div className="reset-link">
          <Button
            type="link"
            onClick={(): void => props.history.push('/resetAsking')}
          >
            Mot de passe oublié
            <ArrowRightOutlined />
          </Button>
        </div>
      </Form>
    </>
  );

  return <Homepage>{loginForm}</Homepage>;
};

Login.propTypes = {
  authenticate: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    authentication: PropTypes.func.isRequired,
    unauthentication: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.exact(historyPropTypes).isRequired,
};

export default withRouter(withAuthenticate(Login));
