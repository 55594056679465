import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { getActionType } from '../common/types';

/**
 * @param {IncidentType} incidentType
 * @return {FetchAddRequested}
 */
export const fetchAddIncidentTypesActionCreator = createAction(
  getActionType('incidenttypes', 'ADD_REQUESTED'),
  (incidentType: types.IncidentType): types.IncidentType => incidentType,
  (incidentType, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {IncidentType} incidentType
 * @return {FetchAddSucceeded}
 */
export const fetchAddIncidentTypesSuccessActionCreator = createAction(
  getActionType('incidenttypes', 'ADD_SUCCEEDED'),
  (incidentType: types.IncidentType): types.IncidentType => incidentType,
);

/**
 * @param {string}
 * @return {FetchFailed}
 */
export const fetchAddIncidentTypesErrorActionCreator = createAction(
  getActionType('incidenttypes', 'ADD_FAILED'),
  (message: string): string => message,
);

/**
 * @return {FetchListRequested}
 */
export const fetchListIncidentTypesActionCreator = createAction(
  getActionType('incidenttypes', 'LIST_REQUESTED'),
);

/**
 * @param {IncidentType[]} incidentTypes
 * @return {FetchListSucceeded}
 */
export const fetchListIncidentTypesSuccessActionCreator = createAction(
  getActionType('incidenttypes', 'LIST_SUCCEEDED'),
  (incidentTypes: types.IncidentType[]): types.IncidentType[] => incidentTypes,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListIncidentTypesErrorActionCreator = createAction(
  getActionType('incidenttypes', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetIncidentTypesActionCreator = createAction(
  getActionType('incidenttypes', 'GET_REQUESTED'),
  (id: types.IncidentType['id']): types.IncidentType['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {IncidentType} incidentType
 * @return {FetchGetSucceeded}
 */
export const fetchGetIncidentTypesSuccessActionCreator = createAction(
  getActionType('incidenttypes', 'GET_SUCCEEDED'),
  (incidentType: types.IncidentType): types.IncidentType => incidentType,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetIncidentTypesErrorActionCreator = createAction(
  getActionType('incidenttypes', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {IncidentType} incidentType
 * @return {FetchEditSucceeded}
 */
export const fetchEditIncidentTypesSuccessActionCreator = createAction(
  getActionType('incidenttypes', 'EDIT_SUCCEEDED'),
  (incidentType: types.IncidentType): types.IncidentType => incidentType,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditIncidentTypesErrorActionCreator = createAction(
  getActionType('incidenttypes', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {IncidentType} incidentType
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditIncidentTypesDesignationActionCreator = createAction(
  getActionType('incidenttypes', 'EDIT_DESIGNATION_REQUESTED'),
  (incidentType: types.IncidentType): types.IncidentType => incidentType,
  (incidentType, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteIncidentTypesActionCreator = createAction(
  getActionType('incidenttypes', 'DELETE_REQUESTED'),
  (id: types.IncidentType['id']): types.IncidentType['id'] => id,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteIncidentTypesSuccessActionCreator = createAction(
  getActionType('incidenttypes', 'DELETE_SUCCEEDED'),
  (id: types.IncidentType['id']): types.IncidentType['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteIncidentTypesErrorActionCreator = createAction(
  getActionType('incidenttypes', 'DELETE_FAILED'),
  (message: string): string => message,
);
