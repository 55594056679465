import { connect } from 'react-redux';
import Profiles from '../../components/layout/users/Users';
import {
  profileActions,
  profileTypes,
  profileSelectors,
} from '../../../states/ducks/profiles';
import { userActions, userTypes } from '../../../states/ducks/users';
import { State } from '../../../states/types';
import { visibleUsersSelector } from '../../../states/ducks/users/selectors/currentUserSelectors';

interface MapStateToProps {
  profiles: profileTypes.Profile[];
  users: userTypes.User[];
  loading: boolean;
}

interface MapDispatchToProps {
  loadProfiles: Function;
  loadUsers: Function;
  deleteProfile: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  profiles: profileSelectors.visibleProfilesSelector(state),
  users: visibleUsersSelector(state),
  loading: profileSelectors.profilesIsListingSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  loadProfiles: profileActions.fetchListProfilesActionCreator,
  loadUsers: userActions.fetchListUsersActionCreator,
  deleteProfile: profileActions.fetchDeleteProfilesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profiles);
