import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { listHistories } from '../../../services/api';
import { FetchListHistoriesRequested } from './actions';
import { getActionType } from '../common/types';

/**
 * @param {FetchListHistoriesRequested} action
 */
export function* fetchHistorySaga(
  action: FetchListHistoriesRequested,
): SagaIterator {
  try {
    const histories: types.History[] = yield call(
      listHistories,
      action.payload,
    );

    yield put(actions.fetchListHistoriesSuccessActionCreator(histories));
  } catch (err) {
    yield put(actions.fetchListHistoriesErrorActionCreator(err.message));
  }
}

/**
 * @yields {SagaIterator}
 */
export function* historiesSagas(): SagaIterator {
  yield takeEvery(
    getActionType('histories', 'LIST_REQUESTED'),
    fetchHistorySaga,
  );
}
