import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Input,
  Row,
  Col,
  notification,
  Button,
  FormItem,
} from '../../../base';
import { incidentTypeTypes } from '../../../../../states/ducks/incidenttypes';
import { Form } from 'antd';
import { IncidentType } from '../../../../../states/ducks/incidenttypes/types';
import { Organization } from '../../../../../states/ducks/organizations/types';
import { organizationTypes } from '../../../../../states/ducks/organizations';

interface FormAddIncidentTypeProps {
  hideModal: Function;
  visible: boolean;
  addIncidentType: Function;
  userOrganization: Organization;
}

const FormAddIncidentType: FunctionComponent<FormAddIncidentTypeProps> = ({
  hideModal,
  visible,
  addIncidentType,
  userOrganization,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = (values: Partial<IncidentType>): void => {
    setLoading(true);

    const incidentType = {
      ...values,
    };

    incidentType.organizationId = userOrganization.id;

    const promise = new Promise<incidentTypeTypes.IncidentType>(
      (resolve, reject): void =>
        addIncidentType(incidentType, { resolve, reject }),
    );

    promise
      .then((incidentType: incidentTypeTypes.IncidentType): void => {
        form.resetFields();
        hideModal();
        const message = `${incidentType.name} a bien été ajoutée`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      })
      .finally((): void => setLoading(false));
  };

  const nameInput = (
    <FormItem
      label="Nom"
      name="name"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner le nom',
        },
      ]}
    >
      <Input placeholder="Saisissez un nom" />
    </FormItem>
  );
  return (
    <Drawer
      title="Création d’un type d'incident"
      onClose={(): void => hideModal()}
      visible={visible}
      footer={
        <>
          <Button
            htmlType="button"
            size="large"
            onClick={(): void => hideModal()}
          >
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            onClick={form.submit}
          >
            Créer
          </Button>
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={24}>{nameInput}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};

FormAddIncidentType.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  addIncidentType: PropTypes.func.isRequired,
  userOrganization: organizationTypes.PropTypesOrganization.isRequired,
};

export default FormAddIncidentType;
