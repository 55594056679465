import { connect } from 'react-redux';
import AddLocation from '../../../components/layout/modal/location/AddLocation';

import { locationActions } from '../../../../states/ducks/locations';
import {
  locationTypeSelectors,
  locationTypeTypes,
} from '../../../../states/ducks/locationtypes';
import { State } from '../../../../states/types';
import { mainLocationTypeSelector } from '../../../../states/ducks/locationtypes/selectors';
import { LocationType } from '../../../../states/ducks/locationtypes/types';

interface MapStateToProps {
  locationTypes: locationTypeTypes.LocationType[];
  mainLocationType: LocationType | null;
}

interface MapDispatchToProps {
  addLocation: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  locationTypes:
    locationTypeSelectors.locationTypesFromOrganizationSelector(state),
  mainLocationType: mainLocationTypeSelector(state) || null,
});

const mapDispatchToProps: MapDispatchToProps = {
  addLocation: locationActions.fetchAddLocationsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
