import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {IncidentType[]} [state=[]] incident types
 * @param {AnyAction} action
 * @return {IncidentType[]}
 */
export const incidentTypeReducer: Reducer<types.IncidentType[]> = (
  state: types.IncidentType[] = [],
  action: AnyAction,
): types.IncidentType[] => {
  return modelReducer('incidenttypes', state, action) as types.IncidentType[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidenttypes', 'ADD_FAILED'):
    case getActionType('incidenttypes', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('incidenttypes', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidenttypes', 'LIST_FAILED'):
    case getActionType('incidenttypes', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('incidenttypes', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidenttypes', 'GET_FAILED'):
    case getActionType('incidenttypes', 'GET_SUCCEEDED'):
      return false;
    case getActionType('incidenttypes', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidenttypes', 'EDIT_FAILED'):
    case getActionType('incidenttypes', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('incidenttypes', 'EDIT_DESIGNATION_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidenttypes', 'DELETE_FAILED'):
    case getActionType('incidenttypes', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('incidenttypes', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.IncidentTypeState> = combineReducers({
  incidentTypes: incidentTypeReducer,
  status: combineReducers({
    adding: addingReducer,
    listing: listingReducer,
    getting: gettingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
  }),
});

export default reducers;
