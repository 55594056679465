import { connect } from 'react-redux';
import Locations from '../../components/layout/location/Locations';
import {
  locationActions,
  locationTypes,
  locationSelectors,
} from '../../../states/ducks/locations';
import { State } from '../../../states/types';
import {
  organizationSelectors,
  organizationTypes,
} from '../../../states/ducks/organizations';
import { locationTypeFromIdSelector } from '../../../states/ducks/locationtypes/selectors';
import { withRouter } from 'react-router';
import React, { ReactElement } from 'react';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface MapStateToProps {
  locations: locationTypes.Location[];
  loading: boolean;
  organizationsFromResults: organizationTypes.Organization[];
  descendantsIdByOrganizationId: Record<string, string[]>;
  locationTypeName: string;
}

interface MapDispatchToProps {
  loadLocations: Function;
  deleteLocation: Function;
}

const mapStateToProps = (
  state: State,
  props: { locationTypeId?: string },
): MapStateToProps => {
  const locations = props.locationTypeId
    ? locationSelectors.locationsFromLocationTypeIdSelector(
        state,
        props.locationTypeId,
      )
    : locationSelectors.locationsSelector(state);

  const organizationIds = locations
    .filter((location: locationTypes.Location): boolean => {
      return (
        typeof location.organizationId !== 'undefined' &&
        location.organizationId.length > 0
      );
    })
    .map(
      (
        location: locationTypes.Location,
      ): organizationTypes.Organization['id'] => location.organizationId || '',
    );

  const organizationsFromResults =
    organizationSelectors.organizationFromMultipleIdsSelector(
      state,
      organizationIds,
    );

  const descendantsIdByOrganizationId =
    organizationSelectors.descendantsIdByOrganizationId(state);

  let locationTypeName = 'lieu';
  if (props.locationTypeId) {
    const locationType = locationTypeFromIdSelector(
      state,
      props.locationTypeId,
    );

    if (locationType) {
      locationTypeName = locationType.name.toLowerCase();
    }
  }

  return {
    locations,
    loading: locationSelectors.locationsIsListingSelector(state),
    organizationsFromResults,
    descendantsIdByOrganizationId,
    locationTypeName,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  loadLocations: locationActions.fetchListLocationsActionCreator,
  deleteLocation: locationActions.fetchDeleteLocationsActionCreator,
};

const LocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Locations);

export default withRouter(
  (props: Props): ReactElement => (
    <LocationsContainer locationTypeId={props.match.params.id} />
  ),
);
