import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import {
  Button,
  Col,
  Field,
  FormItem,
  notification,
  RestrictedToPermissions,
  Row,
} from '../index';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import {
  PropTypesRestrictedToPermissions,
  RestrictedToPermissionsProps,
} from '../permissions/RestrictedToPermissions';

interface EditedFieldOptions {
  label?: string;
  field: string | string[];
  content: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  type?: string;
  required?: boolean;
  invalidMessage?: string;
  colSize?: number;
  pattern?: RegExp;
  validator?: Function;
}

interface EditableFieldProps {
  object: object | object[];
  label?: string;
  content?: ReactNode;
  editedFieldsOptions: EditedFieldOptions | EditedFieldOptions[];
  onSubmit: Function | Record<string, Function> | Function[];
  emptyMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  confirmTitle?: string;
  confirmCondition?: Function;
  restrictedToPermissions?: RestrictedToPermissionsProps;
}

const EditableField: FunctionComponent<EditableFieldProps> = ({
  object,
  label,
  content,
  editedFieldsOptions,
  emptyMessage,
  successMessage,
  errorMessage,
  confirmTitle,
  confirmCondition,
  restrictedToPermissions,
  ...props
}): ReactElement => {
  const [isEdited, setIsEdited] = useState(false);

  editedFieldsOptions = Array.isArray(editedFieldsOptions)
    ? editedFieldsOptions
    : [editedFieldsOptions];

  const onFinish = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
  ): void => {
    if (confirmCondition && !confirmCondition(values)) {
      onSubmit(values);
      return;
    }

    const { confirm } = Modal;
    confirm({
      className: 'app-modal-delete',
      title: confirmTitle,
      okText: 'Continuer',
      okType: 'danger',
      cancelText: 'Annuler',
      icon: <WarningOutlined />,
      onOk: (): void => {
        onSubmit(values);
      },
    });
  };

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
  ): void => {
    setIsEdited(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promise = new Promise<any>((resolve, reject): void => {
      const propsOnSubmit = props.onSubmit;
      const propsObject = object;
      let onSubmitElts: Function[] = [];
      let ObjectElts: object[] = [];
      let firstValue = '';

      const cleanObject = (propsObject: object | object[]): void => {
        ObjectElts.push(propsObject);

        if (propsObject instanceof Array) {
          ObjectElts = propsObject;
        }
      };

      const cleanOnSubmit = (
        propsOnSubmit: Function | { [key: string]: Function } | Function[],
      ): void => {
        if (typeof propsOnSubmit === 'function') {
          onSubmitElts.push(propsOnSubmit);
        }

        if (propsOnSubmit instanceof Array) {
          onSubmitElts = propsOnSubmit;
        }

        Object.values(values).forEach((element): void => {
          if (typeof element === 'string' && firstValue === '') {
            firstValue = element;
          }
        });
        const tmpValues = Object.values(propsOnSubmit);
        let i = 0;
        Object.keys(propsOnSubmit).forEach((element): void => {
          if (element === firstValue) {
            onSubmitElts.push(tmpValues[i]);
          }
          i++;
        });
      };

      const loadSubmit = (
        propsOnSubmit: Function,
        propsObject: object,
      ): void => {
        propsOnSubmit({ ...propsObject, ...values }, { resolve, reject });
      };

      cleanObject(propsObject);
      cleanOnSubmit(propsOnSubmit);

      if (Array.isArray(onSubmitElts) && Array.isArray(ObjectElts)) {
        for (let index = 0; index < ObjectElts.length; index++) {
          loadSubmit(onSubmitElts[index], ObjectElts[index]);
        }
        return;
      }
    });

    promise
      .then((): void => {
        notification.success({
          message: successMessage,
        });
      })
      .catch((): void => {
        notification.error({
          message: errorMessage,
        });
      });
  };

  const field = (
    <Field
      label={label}
      content={content}
      onClick={(): void => setIsEdited(true)}
      className="editable-container"
      emptyMessage={emptyMessage}
      icon={<EditOutlined className="edit-icon" />}
    />
  );

  const editedField = (
    <OutsideClickHandler onOutsideClick={(): void => setIsEdited(false)}>
      <Form className="app-form" onFinish={confirmTitle ? onFinish : onSubmit}>
        <Row>
          {editedFieldsOptions.map(
            (fieldOptions: EditedFieldOptions): ReactNode => (
              <Col
                key={fieldOptions.label}
                lg={fieldOptions.colSize || 24}
                md={fieldOptions.colSize || 24}
                xs={24}
              >
                <FormItem
                  label={fieldOptions.label}
                  name={fieldOptions.field}
                  initialValue={fieldOptions.initialValue}
                  rules={[
                    {
                      // @ts-ignore
                      type: fieldOptions.type || 'string',
                      required: fieldOptions.required,
                      message: fieldOptions.invalidMessage,
                      pattern: fieldOptions.pattern,
                    },
                  ]}
                >
                  {fieldOptions.content}
                </FormItem>
              </Col>
            ),
          )}
        </Row>
        <Row>
          <Col span={24} className="fields-validation-container">
            <Button
              onClick={(): void => setIsEdited(false)}
              icon={<CloseOutlined />}
              size="small"
            />
            <Button
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              size="small"
            />
          </Col>
        </Row>
      </Form>
    </OutsideClickHandler>
  );

  const display = !isEdited ? field : editedField;

  return restrictedToPermissions ? (
    <RestrictedToPermissions
      {...restrictedToPermissions}
      unauthorizedText={<Field label={label} content={content} />}
    >
      {display}
    </RestrictedToPermissions>
  ) : (
    display
  );
};

EditableField.defaultProps = {
  successMessage: 'La modification a réussie',
  errorMessage: 'La modification a échouée',
};

EditableField.propTypes = {
  object: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  label: PropTypes.string,
  editedFieldsOptions: PropTypes.oneOfType([
    PropTypes.any.isRequired,
    PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  ]).isRequired,
  onSubmit: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  ]).isRequired,
  emptyMessage: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmCondition: PropTypes.func,
  restrictedToPermissions: PropTypesRestrictedToPermissions,
};

export default EditableField;
