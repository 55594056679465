import PropTypes from 'prop-types';
import { Model } from '../common/types';
import { Pagination } from '../../../services/search/search';

export interface Request extends Model {
  body: string;
  createdAt: string;
  creatorId: string;
  fileIds?: string[] | null;
  managerId?: string | null;
  number: string;
  object: string;
  openedAt: string;
  organizationId: string;
  status: string;
  updatedAt: string;
}

export interface RequestState {
  requests: Request[];
  pagination: Pagination | null;
  status: {
    opening: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

export const PropTypesRequest = PropTypes.shape({
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  creatorId: PropTypes.string.isRequired,
  fileIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  managerId: PropTypes.string,
  number: PropTypes.string.isRequired,
  object: PropTypes.string.isRequired,
  openedAt: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});
