import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import 'antd/lib/drawer/style';
import './Drawer.scss';

import PropTypes from 'prop-types';

interface Props {
  children: ReactNode;
}

/**
 * Title component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const DrawerFooter: FunctionComponent<Props> = ({ children }): ReactElement => {
  return <div className="app-drawer-footer">{children}</div>;
};

DrawerFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default DrawerFooter;
