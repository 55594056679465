import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {LocationType[]} [state=[]] location types
 * @param {AnyAction} action
 * @return {LocationType[]}
 */
export const locationTypeReducer: Reducer<types.LocationType[]> = (
  state: types.LocationType[] = [],
  action: AnyAction,
): types.LocationType[] => {
  return modelReducer('locationtypes', state, action) as types.LocationType[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locationtypes', 'ADD_FAILED'):
    case getActionType('locationtypes', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('locationtypes', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locationtypes', 'LIST_FAILED'):
    case getActionType('locationtypes', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('locationtypes', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locationtypes', 'GET_FAILED'):
    case getActionType('locationtypes', 'GET_SUCCEEDED'):
      return false;
    case getActionType('locationtypes', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locationtypes', 'EDIT_FAILED'):
    case getActionType('locationtypes', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('locationtypes', 'EDIT_DESIGNATION_REQUESTED'):
    case getActionType('locationtypes', 'EDIT_MAIN_REQUESTED'):
    case getActionType('locationtypes', 'EDIT_QUICK_ACCESS_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('locationtypes', 'DELETE_FAILED'):
    case getActionType('locationtypes', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('locationtypes', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.LocationTypeState> = combineReducers({
  locationTypes: locationTypeReducer,
  status: combineReducers({
    adding: addingReducer,
    listing: listingReducer,
    getting: gettingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
  }),
});

export default reducers;
