import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { FormItem } from '../index';
import UploadList, { UploadListProps } from './UploadList';

export interface UploadListFieldProps extends UploadListProps {
  field?: string;
  label: ReactNode;
  onGEDUpload?: Function;
  // reload files after closing GED modal
  onGEDClose?: Function;
}

const UploadListField: FunctionComponent<UploadListFieldProps> = ({
  field,
  label,
  ...props
}): ReactElement => (
  <FormItem
    label={label}
    name={field}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getValueFromEvent={(e: any): any => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    }}
  >
    <UploadList
      {...props}
      onGEDUpload={props.onGEDUpload}
      onGEDClose={props.onGEDClose}
    />
  </FormItem>
);

UploadListField.propTypes = {
  field: PropTypes.string,
  label: PropTypes.node.isRequired,
  onGEDUpload: PropTypes.func,
  onGEDClose: PropTypes.func,
};

UploadList.displayName = 'UploadListField';

export default UploadListField;
