import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface Profile extends Model {
  firstName: string;
  lastName: string;
  email: string;
  organizationIds: string[];
  userId: string;
  address?: string | null;
  address2?: string | null;
  phoneNumber?: string | null;
  avatarId?: string | null;
}

export interface ProfileState {
  profiles: Profile[];
  status: {
    listing: boolean;
    adding: boolean;
    editing: boolean;
    deleting: boolean;
    getting: boolean;
  };
}

export const PropTypesProfile = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  organizationIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  userId: PropTypes.string.isRequired,
  address: PropTypes.string,
  address2: PropTypes.string,
  phoneNumber: PropTypes.string,
  avatarId: PropTypes.string,
});
