import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Label, Text } from '../index';
import PropTypes from 'prop-types';
import './Field.scss';

interface FieldProps {
  label?: ReactNode;
  content?: ReactNode;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  emptyMessage?: string;
  icon?: ReactElement;
}

const DEFAULT_EMPTY_MESSAGE = 'Vide';

/**
 * @param {string} label
 * @param {string|ReactElement} content
 * @param {function|null} onClick
 * @param {string|null} className
 * @param {string|null} emptyMessage
 * @param {ReactElement|null} icon
 * @return {ReactElement}
 */
const Field: FunctionComponent<FieldProps> = ({
  label,
  content,
  onClick,
  className,
  emptyMessage,
  icon,
}): ReactElement => {
  const emptyContent = emptyMessage || DEFAULT_EMPTY_MESSAGE;
  const classNames = [
    className || '',
    !content ? 'app-none' : '',
    'app-description',
  ].join(' ');

  return (
    <>
      {label ? <Label>{label}</Label> : ''}
      <Text className={classNames} onClick={onClick || undefined}>
        <div className="data-container">{content || emptyContent}</div>
        <div className="icon-container">{icon}</div>
      </Text>
    </>
  );
};

Field.propTypes = {
  label: PropTypes.node,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  emptyMessage: PropTypes.string,
  icon: PropTypes.element,
};

export default Field;
