import moment from 'moment';

/**
 * @param {number|undefined} a
 * @param {number|undefined} b
 * @return {number}
 */
export const numericalSortBy = (
  a: number | undefined,
  b: number | undefined,
): number => {
  if (a === undefined) {
    return -1;
  }

  if (b === undefined) {
    return 1;
  }

  return a - b;
};

/**
 * @param {string|undefined} a
 * @param {string|undefined} b
 * @return {number}
 */
export const alphabeticalSortBy = (
  a: string | undefined,
  b: string | undefined,
): number => {
  if (a === undefined) {
    return -1;
  }

  if (b === undefined) {
    return 1;
  }

  return a.localeCompare(b);
};

/**
 * @param {string|undefined} a
 * @param {string|undefined} b
 * @return {number}
 */
export const chronologicalSortBy = (
  a: string | Date | undefined,
  b: string | Date | undefined,
): number => {
  if (a === undefined) {
    return -1;
  }

  if (b === undefined) {
    return 1;
  }

  return moment(b).diff(a);
};
