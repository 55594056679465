import { createAction } from 'redux-actions';
import { getActionType } from '../common/types';
import { Permission } from './types';

/**
 * @return {FetchListRequested}
 */
export const fetchListPermissionsActionCreator = createAction(
  getActionType('permissions', 'LIST_REQUESTED'),
);

/**
 * @param {Permission[]} permissions
 * @return {FetchListSucceeded}
 */
export const fetchListPermissionsSuccessActionCreator = createAction(
  getActionType('permissions', 'LIST_SUCCEEDED'),
  (permissions: Permission[]) => permissions,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListPermissionsErrorActionCreator = createAction(
  getActionType('permissions', 'LIST_FAILED'),
  (message: string) => message,
);
