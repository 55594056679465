import { create, get, patch, remove } from '../base';
import { locationTypeTypes } from '../../../states/ducks/locationtypes';

/**
 * @param {LocationType} locationType
 * @return {Promise<string>}
 */
export const addLocationType = async (
  locationType: Partial<locationTypeTypes.LocationType>,
): Promise<locationTypeTypes.LocationType['id']> =>
  await create(`/v1/locations/locationtypes/create`, locationType);

/**
 * @return {Promise<LocationType[]>}
 */
export const listLocationTypes = async (): Promise<
  locationTypeTypes.LocationType[]
> => await get(`/v1/locations/locationtypes/list`);

/**
 * @param {string} id
 * @return {Promise<LocationType>}
 */
export const getLocationType = async (
  id: locationTypeTypes.LocationType['id'],
): Promise<locationTypeTypes.LocationType> =>
  await get(`/v1/locations/locationtypes/get/${id}`);

/**
 * @param {LocationType} locationType
 * @return {Promise<string>}
 */
export const editLocationTypeDesignation = async (
  locationType: locationTypeTypes.LocationType,
): Promise<string> => {
  const { id, name } = locationType;

  return await patch(`/v1/locations/locationtypes/changeDesignation/${id}`, {
    name,
  });
};

/**
 * @param {LocationType} locationType
 * @return {Promise<string>}
 */
export const editMainLocationType = async (
  locationType: locationTypeTypes.LocationType,
): Promise<string> =>
  await patch(`/v1/locations/locationtypes/changeMain/${locationType.id}`);

/**
 * @param {LocationType} locationType
 * @return {Promise<string>}
 */
export const editQuickAccessLocationType = async (
  locationType: locationTypeTypes.LocationType,
): Promise<string> =>
  await patch(
    `/v1/locations/locationtypes/changeQuickAccess/${locationType.id}`,
    {
      isQuickAccess: locationType.isQuickAccess,
    },
  );

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteLocationType = async (
  id: locationTypeTypes.LocationType['id'],
): Promise<string> => await remove(`/v1/locations/locationtypes/delete/${id}`);
