import errorStatusCodes from './errorStatusCodes';
import { getAccessToken } from '../authenticate';
import { PaginatedResult } from '../search/search';

/**
 * @param {string} path
 * @param {string} method
 * @param {object} body
 * @param {object} errorsByStatusCode
 * @param {RequestInit} requestInit
 * @return {Promise<Response>}
 */
export const apiCall = async (
  path: string,
  method?: string,
  body?: object,
  errorsByStatusCode?: { [key: number]: string },
  requestInit?: RequestInit,
): Promise<Response> => {
  requestInit = requestInit || {};
  requestInit.method = method || 'GET';
  requestInit.headers = requestInit.headers || new Headers();
  errorsByStatusCode = { ...errorStatusCodes, ...errorsByStatusCode };

  if (!(requestInit.headers instanceof Headers)) {
    throw new Error('Malformed headers');
  }

  if (body) {
    requestInit.body = body instanceof FormData ? body : JSON.stringify(body);
  }

  if (body instanceof FormData) {
    requestInit.headers.append('Accept', 'application/json');
  }

  if (
    (method === 'POST' || method === 'PATCH') &&
    !(body instanceof FormData)
  ) {
    requestInit.headers.append('Content-Type', 'application/json');
  }

  const token = getAccessToken();
  requestInit.credentials = 'include';
  if (token) {
    requestInit.headers.append('Authorization', `Bearer ${token}`);
  }

  const Url: URL = new URL(path, process.env.REACT_APP_URL_API);

  const response = await fetch(Url.href, requestInit).catch(
    (error: Error): Response => {
      console.error(error.message);

      throw new Error("Aucune réponse de l'API");
    },
  );

  if (errorsByStatusCode.hasOwnProperty(response.status)) {
    throw new Error(errorsByStatusCode[response.status]);
  }

  return response;
};

/**
 * @param {string} path
 * @param {object} body
 * @param {object} errorsByStatusCode
 * @param {RequestInit} requestInit
 * @return {Promise<Response>}
 */
export const post = async (
  path: string,
  body?: object,
  errorsByStatusCode?: { [key: number]: string },
  requestInit?: RequestInit,
): Promise<Response> =>
  await apiCall(path, 'POST', body, errorsByStatusCode, requestInit);

/**
 * @param {string} path
 * @param {object} body
 * @param {object} errorsByStatusCode
 * @param {RequestInit} requestInit
 * @return {Promise<string>}
 */
export const create = async (
  path: string,
  body?: object,
  errorsByStatusCode?: { [key: number]: string },
  requestInit?: RequestInit,
): Promise<string> => {
  const response = await post(path, body, errorsByStatusCode, requestInit);

  const regex = new RegExp(/([\w-]+)\/?$/);
  const matches = regex.exec(response.headers.get('Location') || '');
  if (matches) {
    return matches[1];
  }

  throw new Error('Une erreur est survenue, veuillez recharger la page');
};

/**
 * @param {string} path
 * @param {object} body
 * @param {object} errorsByStatusCode
 * @param {RequestInit} requestInit
 * @return {Promise<string>}
 */
export const patch = async (
  path: string,
  body?: object,
  errorsByStatusCode?: { [key: number]: string },
  requestInit?: RequestInit,
): Promise<string> => {
  const response = await apiCall(
    path,
    'PATCH',
    body,
    errorsByStatusCode,
    requestInit,
  );

  return response.text();
};

/**
 * @param {string} path
 * @param {object} body
 * @param {object} errorsByStatusCode
 * @param {RequestInit} requestInit
 * @return {Promise<any>}
 */
export const get = async (
  path: string,
  body?: object,
  errorsByStatusCode?: { [key: number]: string },
  requestInit?: RequestInit,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await apiCall(
    path,
    'GET',
    body,
    errorsByStatusCode,
    requestInit,
  );

  return response.json();
};

/**
 * @param {string} path
 * @param {object} body
 * @param {object} errorsByStatusCode
 * @param {RequestInit} requestInit
 * @return {Promise<any>}
 */
export const paginatedGet = async (
  path: string,
  body?: object,
  errorsByStatusCode?: { [key: number]: string },
  requestInit?: RequestInit,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<PaginatedResult> => {
  const response = await apiCall(
    path,
    'GET',
    body,
    errorsByStatusCode,
    requestInit,
  );

  const contentRange = /([0-9]+)-([0-9]+)\/([0-9]+)/g.exec(
    response.headers.get('Content-Range') || '',
  );

  let current = 1;
  let pageSize = 1;
  if (contentRange && contentRange.length >= 3) {
    const startIndex = parseInt(contentRange[1]);
    const endIndex = parseInt(contentRange[2]);
    pageSize = endIndex - startIndex + 1;
    current = (endIndex + 1) / pageSize;
  }

  return response.json().then((json) => ({
    result: json,
    pagination: {
      current: current,
      pageSize: pageSize,
      total:
        contentRange && contentRange.length >= 4
          ? parseInt(contentRange[3])
          : undefined,
    },
  }));
};

/**
 * @param {string} path
 * @param {object} body
 * @param {object} errorsByStatusCode
 * @param {RequestInit} requestInit
 * @return {Promise<string>}
 */
export const remove = async (
  path: string,
  body?: object,
  errorsByStatusCode?: { [key: number]: string },
  requestInit?: RequestInit,
): Promise<string> => {
  const response = await apiCall(
    path,
    'DELETE',
    body,
    errorsByStatusCode,
    requestInit,
  );

  return response.text();
};
