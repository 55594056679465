import listToTree from 'list-to-tree-lite';
import { createSelector } from 'reselect';
import { State } from '../../types';
import { organizationRootSelector } from '../organizations/selectors/organizationsSelectors';
import { CurrentUserState } from './types';
import { organizationsSelector } from '../organizations/selectors/base';

/**
 * @param {State} state
 * @return {CurrentUserState}
 */
const currentUserStateSelector: (state: State) => CurrentUserState = (state) =>
  state.currentUserState;

/**
 * @param {State} state
 * @return {CurrentUser}
 */
export const currentUserSelector = createSelector(
  currentUserStateSelector,
  (applicationState) => applicationState.currentUser,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const currentUserIsGettingSelector = createSelector(
  currentUserStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {Organization[]}
 */
export const currentUserOrganizationsSelector = createSelector(
  organizationsSelector,
  currentUserSelector,
  (organizations, currentUser) =>
    organizations.filter((organization) =>
      currentUser.organizationIds.includes(organization.id),
    ),
);

/**
 * @param {State} state
 * @return {Organization}
 */
export const currentUserOrganizationRootSelector = createSelector(
  (state) => state,
  currentUserOrganizationsSelector,
  (state, organizations) => organizationRootSelector(state, organizations[0]),
);

/**
 * @param {State} state
 * @return {Organization[]}
 */
export const currentUserVisibleOrganizationsSelector = createSelector(
  organizationsSelector,
  currentUserSelector,
  (organizations, currentUser) =>
    organizations.filter((organization) =>
      currentUser.visibleOrganizationIds.includes(organization.id),
    ),
);

/**
 * @param {State} state
 * @return {Organization[]}
 */
export const currentUserVisibleOrganizationTreesSelector = createSelector(
  currentUserVisibleOrganizationsSelector,
  (organizations) =>
    listToTree(organizations, {
      parentKey: 'parentId',
    }),
);
