import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import { getActionType } from '../common/types';
import { Tag } from './types';

/**
 * @param {Tag} tag
 * @param {Meta} meta
 * @return {FetchAddRequested}
 */
export const fetchAddTagsActionCreator = createAction(
  getActionType('tags', 'ADD_REQUESTED'),
  (tag: Tag) => tag,
  (tag, meta = defaultMeta) => meta,
);

/**
 * @param {Tag} tag
 * @return {FetchAddSucceeded}
 */
export const fetchAddTagsSuccessActionCreator = createAction(
  getActionType('tags', 'ADD_SUCCEEDED'),
  (tag: Tag) => tag,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddTagsErrorActionCreator = createAction(
  getActionType('tags', 'ADD_FAILED'),
  (message: string) => message,
);

/**
 * @return {FetchListRequested}
 */
export const fetchListTagsActionCreator = createAction(
  getActionType('tags', 'LIST_REQUESTED'),
);

/**
 * @param {Tag[]} tags
 * @return {FetchListSucceeded}
 */
export const fetchListTagsSuccessActionCreator = createAction(
  getActionType('tags', 'LIST_SUCCEEDED'),
  (tags: Tag[]) => tags,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListTagsErrorActionCreator = createAction(
  getActionType('tags', 'LIST_FAILED'),
  (message: string) => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetTagsActionCreator = createAction(
  getActionType('tags', 'GET_REQUESTED'),
  (id: Tag['id']) => id,
  (_, meta = defaultMeta) => meta,
);

/**
 * @param {Tag} tag
 * @return {FetchGetSucceeded}
 */
export const fetchGetTagsSuccessActionCreator = createAction(
  getActionType('tags', 'GET_SUCCEEDED'),
  (tag: Tag) => tag,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetTagsErrorActionCreator = createAction(
  getActionType('tags', 'GET_FAILED'),
  (message: string) => message,
);

/**
 * @param {Tag[]} tags
 * @param {Object} objectsLinked
 * @param {Meta} meta
 * @return {FetchEditLinkRequested}
 */
export const fetchEditLinkActionCreator = createAction(
  getActionType('tags', 'EDIT_LINK_REQUESTED'),
  (payload: {
    tags: Tag[];
    objectsLinked: Record<string, string[]>;
  }): {
    tags: Tag[];
    objectsLinked: Record<string, string[]>;
  } => payload,
  (tags, meta: Meta = defaultMeta) => meta,
);

/**
 * @param {Tag[]} tags
 * @param {Object} objectsLinked
 * @param {Meta} meta
 * @return {FetchEditUnlinkRequested}
 */
export const fetchEditUnlinkActionCreator = createAction(
  getActionType('tags', 'EDIT_UNLINK_REQUESTED'),
  (payload: {
    tags: Tag[];
    objectsLinked: Record<string, string[]>;
  }): {
    tags: Tag[];
    objectsLinked: Record<string, string[]>;
  } => payload,
  (tags, meta: Meta = defaultMeta) => meta,
);

/**
 * @param {Tag} tag
 * @return {FetchEditSucceeded}
 */
export const fetchEditTagsSuccessActionCreator = createAction(
  getActionType('tags', 'EDIT_SUCCEEDED'),
  (tag: Tag) => tag,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditTagsErrorActionCreator = createAction(
  getActionType('tags', 'EDIT_FAILED'),
  (message: string) => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteTagsActionCreator = createAction(
  getActionType('tags', 'DELETE_REQUESTED'),
  (id: Tag['id']) => id,
  (tag, meta = defaultMeta) => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteTagsSuccessActionCreator = createAction(
  getActionType('tags', 'DELETE_SUCCEEDED'),
  (id: Tag['id']) => id,
);

/**
 * @return {FetchFailed}
 */
export const fetchDeleteTagsErrorActionCreator = createAction(
  getActionType('tags', 'DELETE_FAILED'),
  (message: string) => message,
);

export type FetchEditLinkRequested = ReturnType<
  typeof fetchEditLinkActionCreator
>;

export type FetchEditUnlinkRequested = ReturnType<
  typeof fetchEditUnlinkActionCreator
>;
