import React, { ReactElement } from 'react';
import { Layout, Menu } from 'antd';
import { Logo } from '../../../base';
import PropTypes from 'prop-types';
import 'antd/lib/layout/style';
import 'antd/lib/menu/style';
import { RouteComponentProps, withRouter, StaticContext } from 'react-router';
import { NavLink } from 'react-router-dom';
import './Header.scss';

interface Props {
  location: {
    pathname: string;
  };
}

const Header = (props: Props): ReactElement => {
  let selected = ['/'];
  const reg = new RegExp(/(?:\/[a-z]+\/+)(([a-z]|-)+)*/);
  const match = reg.exec(props.location.pathname);
  if (match !== null) {
    selected = [match[1]];
  }

  return (
    <Layout.Header
      className="help-header"
      style={{ position: 'fixed', zIndex: 1, width: '100%' }}
    >
      <div className="logo-container">
        <NavLink to="/">
          <Logo white={true} />
        </NavLink>
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['faq']}
        selectedKeys={selected}
      >
        <Menu.Item key="doc-api">
          <NavLink to="/help/doc-api">
            <span>Documentation API</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="privacy-policy">
          <NavLink to="/help/privacy-policy">
            <span>Politique de confidentialité</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </Layout.Header>
  );
};

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: RouteComponentProps<any, StaticContext, any>): ReactElement => (
    <Header {...props} />
  ),
);
