import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';
import { Select, Button } from '../index';
import 'antd/lib/select/style';
import { SelectProps as SelectAntProps, SelectValue } from 'antd/lib/select';
import './Select.scss';
import './MultipleSelect.scss';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
export interface SelectProps
  extends Omit<SelectAntProps<SelectValue>, 'onClick'> {
  isFilter?: boolean;
  onClick?: Function;
}

/**
 * @param {SelectProps} props
 * @return {ReactElement}
 */
const MultipleSelect: FunctionComponent<PropsWithChildren<SelectProps>> = ({
  onClick,
  ...props
}): ReactElement => {
  const [value, setValue] = useState([] as SelectValue);

  return (
    <Input.Group className="app-multiple-selector" compact>
      <Select
        style={{ width: '100%' }}
        value={value}
        onChange={(value): void => setValue(value)}
        {...props}
        mode="multiple"
        allowClear
        maxTagCount={'responsive' as const}
      />
      <Button
        icon={<PlusOutlined />}
        size="middle"
        type="primary"
        onClick={(): void => {
          if (!onClick || (Array.isArray(value) && 0 === value.length)) {
            return;
          }

          onClick(value);
          setValue([]);
        }}
      >
        Ajouter
      </Button>
    </Input.Group>
  );
};

MultipleSelect.propTypes = {
  onClick: PropTypes.func,
};

export default MultipleSelect;
