import { create, get, remove } from '../base';
import { subscriptionTypes } from '../../../states/ducks/subscriptions';
import { userTypes } from '../../../states/ducks/users';

/**
 * @param {Subscription} subscription
 * @return {Promise<string>}
 */
export const subscribe = async (
  subscription: Partial<subscriptionTypes.Subscription>,
): Promise<subscriptionTypes.Subscription['id']> =>
  await create(`/v1/notifications/subscribe`, subscription);

/**
 * @param {string} id
 * @return {Promise<Subscription[]>}
 */
export const listSubscriptionsByUserId = async (
  id: userTypes.User['id'],
): Promise<subscriptionTypes.Subscription[]> =>
  await get(`/v1/notifications/listSubscriptions/${id}`);

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const unsubscribe = async (
  id: subscriptionTypes.Subscription['id'],
): Promise<string> => await remove(`/v1/notifications/unsubscribe/${id}`);
