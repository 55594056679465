import React, { ReactElement } from 'react';
import { List as ListAnt } from 'antd';
import 'antd/lib/list/style';
import { ListProps } from 'antd/lib/list';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ItemRender from '../pagination/ItemRender';
import './List.scss';

/**
 * List component
 * @param {any} props empty props
 * @return {ReactElement}
 */
function List<T extends object>({
  className,
  locale,
  pagination,
  ...props
}: ListProps<T>): ReactElement {
  const classes = classnames('app-list', className);
  const itemsNbr = pagination ? pagination.pageSize : 20;
  return (
    <ListAnt<T>
      {...props}
      locale={{
        emptyText: 'Aucune donnée',
        ...locale,
      }}
      className={classes}
      pagination={{
        hideOnSinglePage: true,
        pageSize: itemsNbr,
        showLessItems: true,
        itemRender: ItemRender,
      }}
    />
  );
}

List.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.object,
  pagination: PropTypes.object,
};

export default List;
