import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import { TreeSelect } from '../index';
import { TreeSelectAntWithLimitProps } from './TreeSelect';
import 'antd/lib/tree-select/style';
import {
  Organization,
  PropTypesOrganization,
} from '../../../../states/ducks/organizations/types';
import { fetchListAdminOrganizationsActionCreator } from '../../../../states/ducks/organizations/actions';
import { organizationsTreesSelector } from '../../../../states/ducks/organizations/selectors/organizationsSelectors';

interface OrganizationsAdminTreeSelectProps
  extends TreeSelectAntWithLimitProps<string | string[]> {
  organizations: Organization[];
  loadOrganizations: Function;
}

/**
 * @param {Organization[]} filteredOrganizations
 * @param {Function} loadOrganizations
 * @param {TreeSelectAntWithLimitProps<string | string[]>} props
 * @return {ReactElement}
 */
const OrganizationsAdminTreeSelect: FunctionComponent<OrganizationsAdminTreeSelectProps> =
  ({ organizations, loadOrganizations, ...props }): ReactElement => {
    useEffect((): void => {
      loadOrganizations();
    }, [loadOrganizations]);

    return <TreeSelect trees={organizations} {...props} />;
  };

OrganizationsAdminTreeSelect.propTypes = {
  organizations: PropTypes.arrayOf(PropTypesOrganization.isRequired).isRequired,
  loadOrganizations: PropTypes.func.isRequired,
};

interface MapStateToProps {
  organizations: Organization[];
}

interface MapDispatchToProps {
  loadOrganizations: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  organizations: organizationsTreesSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  loadOrganizations: fetchListAdminOrganizationsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationsAdminTreeSelect);
