import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale-provider/fr_FR';
import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore from './states/store';
import App from './views/App';
import { validateMessages } from './views/i18n/Validation/fr';
import './views/App.scss';

const reduxStore = configureStore();

if (process.env.REACT_APP_ENV !== 'production') {
  document.title += ` - ${process.env.REACT_APP_ENV}`;
}

ReactDOM.render(
  <ConfigProvider locale={frFR} form={{ validateMessages: validateMessages }}>
    <ReduxProvider store={reduxStore}>
      <App />
    </ReduxProvider>
  </ConfigProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
