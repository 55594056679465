import { get } from '../base';
import { kpiTypes } from '../../../states/ducks/kpis';
import faker from 'faker';

/**
 * @return {Promise<Kpi>}
 */
export const countIncidentsByStatus = async (): Promise<kpiTypes.Kpi> => {
  return await get(`/v1/dashboards/kpi/countIncidentsByStatus/`).then(
    (results) => ({
      id: faker.datatype.uuid(),
      value: results,
      name: 'incidentsByStatus',
    }),
  );
};

/**
 * @return {Promise<Kpi>}
 */
export const countIncidentsReportedAndResolvedByMonth =
  async (): Promise<kpiTypes.Kpi> => {
    return await get(
      `/v1/dashboards/kpi/countIncidentsReportedAndResolvedByMonth/`,
    ).then((results) => ({
      id: faker.datatype.uuid(),
      value: results,
      name: 'incidentsReportedAndResolvedByMonth',
    }));
  };

/**
 * @return {Promise<Kpi>}
 */
export const countIncidentsByMainLocations =
  async (): Promise<kpiTypes.Kpi> => {
    return await get(`/v1/dashboards/kpi/countIncidentsByMainLocations/`).then(
      (results) => ({
        id: faker.datatype.uuid(),
        value: results,
        name: 'incidentsByMainLocations',
      }),
    );
  };

/**
 * @return {Promise<Kpi>}
 */
export const countRequestsByManager = async (): Promise<kpiTypes.Kpi> => {
  return await get(`/v1/dashboards/kpi/countRequestsByManager/`).then(
    (results) => ({
      id: faker.datatype.uuid(),
      value: results,
      name: 'requestsByManager',
    }),
  );
};

/**
 * @return {Promise<Kpi>}
 */
export const countRequestsOpenedAndClosedByMonth =
  async (): Promise<kpiTypes.Kpi> => {
    return await get(
      `/v1/dashboards/kpi/countRequestsOpenedAndClosedByMonth/`,
    ).then((results) => ({
      id: faker.datatype.uuid(),
      value: results,
      name: 'requestsOpenedAndClosedByMonth',
    }));
  };
