import { createSelector } from 'reselect';
import { State } from '../../../types';
import { OrganizationsState } from '../types';

/**
 * @param {State} state
 * @return {OrganizationsState}
 */
const organizationStateSelector: (state: State) => OrganizationsState = (
  state,
) => state.organizationsState;

/**
 * @param {State} state
 * @return {Organization[]}
 */
export const organizationsSelector = createSelector(
  organizationStateSelector,
  (organizationsState) => organizationsState.organizations,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const organizationsIsAddingSelector = createSelector(
  organizationStateSelector,
  (organizationsState) => organizationsState.status.adding,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const organizationsIsEditingSelector = createSelector(
  organizationStateSelector,
  (organizationsState) => organizationsState.status.editing,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const organizationsIsGettingSelector = createSelector(
  organizationStateSelector,
  (organizationsState) => organizationsState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const organizationsIsListingSelector = createSelector(
  organizationStateSelector,
  (organizationsState) => organizationsState.status.listing,
);
