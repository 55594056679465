import React, { FunctionComponent, ReactElement } from 'react';
import Tag from '../tag/Tag';
import 'antd/lib/tag/style';
import { Select, SelectOption } from '../index';
import { SelectProps } from '../form/Select';
import PropTypes from 'prop-types';
import { CustomTagProps } from 'rc-select/lib/interface/generator';

interface Props extends SelectProps {
  defaultValue?: string;
}

/**
 * Incident Status Select component
 * @param {string} status
 * @param {any} props empty props
 * @return {ReactElement} Col element
 */
const RoleStyleSelect: FunctionComponent<Props> = ({
  ...props
}): ReactElement => {
  const options = ['magenta', 'volcano', 'green', 'cyan', 'blue', 'purple'];

  const selectOptions = options.map((color): ReactElement => {
    return (
      <SelectOption key={color} value={color}>
        <Tag color={color}>{color}</Tag>
      </SelectOption>
    );
  });

  const tagRender = (props: CustomTagProps): ReactElement => {
    // eslint-disable-next-line react/prop-types
    const { label, value } = props;
    // @ts-ignore
    return <Tag color={value}>{label}</Tag>;
  };

  return (
    <Select
      {...props}
      tagRender={tagRender}
      defaultValue={
        props.defaultValue === undefined ? 'green' : props.defaultValue
      }
    >
      {selectOptions}
    </Select>
  );
};

RoleStyleSelect.propTypes = {
  defaultValue: PropTypes.string,
};

export default RoleStyleSelect;
