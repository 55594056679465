import React, { FunctionComponent, ReactElement } from 'react';
import { DateTooltip, Field } from '../index';
import PropTypes from 'prop-types';

interface DateFieldProps {
  label?: string;
  date: string;
}

/**
 * @param {string|null} label
 * @param {string} date
 * @return {ReactElement}
 */
const DateField: FunctionComponent<DateFieldProps> = ({
  label,
  date,
}): ReactElement => (
  <Field label={label} content={<DateTooltip date={date} />} />
);

DateField.propTypes = {
  label: PropTypes.string,
  date: PropTypes.string.isRequired,
};

export default DateField;
