import React, { ReactElement, forwardRef } from 'react';
import { Input as InputAnt } from 'antd';
import 'antd/lib/input/style';
import { TextAreaProps } from 'antd/lib/input';
import classnames from 'classnames';

/**
 * @param {TextAreaProps} props
 * @param {any} ref
 * @return {ReactElement}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TextArea = (props: TextAreaProps, ref: any): ReactElement => {
  const classes = classnames('app-input', props.className);

  return <InputAnt.TextArea {...props} ref={ref} className={classes} />;
};

TextArea.displayName = 'TextArea';

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(TextArea);
