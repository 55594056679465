import { descriptions as fr } from './fr';
import { historyTypes } from '../../../../../states/ducks/histories';

export const descriptions: { [key: string]: { [key: string]: string } } = fr;

export const getDescription = (history: historyTypes.History): string => {
  // eslint-disable-next-line no-prototype-builtins
  if (!descriptions.hasOwnProperty(history.objectType)) {
    return '';
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!descriptions[history.objectType].hasOwnProperty(history.event)) {
    return '';
  }

  let description = descriptions[history.objectType][history.event];

  const variables = description.match(/{(\w)*-(\w)*}/g);
  if (variables) {
    variables.forEach((variable: string): void => {
      const [variableType, variableName] = variable
        .slice(1, variable.length - 1)
        .split('-');
      let value = history.to[variableName];

      switch (variableType) {
        case 'count':
          value = value ? value.length : 0;
      }

      description = description.replace(variable, value);
    });
  }

  return description;
};
