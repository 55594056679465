import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface Intervention extends Model {
  incidentId: string;
  numero: string;
  report: string;
  status: string;
  schedule: {
    date: string;
    actionDate: string;
  };
  handle: {
    date: string;
    actionDate: string;
  };
  start: {
    date: string;
    actionDate: string;
  };
  finish: {
    date: string;
    actionDate: string;
  };
}

export interface InterventionState {
  interventions: Intervention[];
  status: {
    listing: boolean;
  };
}

export const PropTypesIntervention = PropTypes.shape({
  id: PropTypes.string.isRequired,
  incidentId: PropTypes.string.isRequired,
  numero: PropTypes.string.isRequired,
  report: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  schedule: PropTypes.shape({
    date: PropTypes.string.isRequired,
    actionDate: PropTypes.string.isRequired,
  }).isRequired,
  handle: PropTypes.shape({
    date: PropTypes.string.isRequired,
    actionDate: PropTypes.string.isRequired,
  }).isRequired,
  start: PropTypes.shape({
    date: PropTypes.string.isRequired,
    actionDate: PropTypes.string.isRequired,
  }).isRequired,
  finish: PropTypes.shape({
    date: PropTypes.string.isRequired,
    actionDate: PropTypes.string.isRequired,
  }).isRequired,
});
