import { PropTypesUser, User } from '../users/types';

export type CurrentUser = User;

export interface CurrentUserState {
  currentUser: CurrentUser;
  status: {
    getting: number;
  };
}

export const PropTypesCurrentUser = PropTypesUser;
