import React, {
  FunctionComponent,
  ReactElement,
  useReducer,
  useState,
} from 'react';
import {
  PageHeader,
  Button,
  Card,
  Table,
  DateTooltip,
  PopoverActions,
  RestrictedToPermissions,
} from '../../base';
import AddIncidentType from '../../../containers/modal/incident/AddIncidentType';
import GetIncidentType from '../../../containers/modal/incident/GetIncidentType';
import { ColumnProps } from 'antd/lib/table';
import { incidentTypeTypes } from '../../../../states/ducks/incidenttypes';
import PropTypes from 'prop-types';
import {
  alphabeticalSortBy,
  chronologicalSortBy,
} from '../../../../utils/SortUtils';
import { PlusOutlined } from '@ant-design/icons';

const initialState = {
  modalAddIncidentVisible: false,
  modalGetIncidentVisible: false,
  incidentTypeId: null as null | incidentTypeTypes.IncidentType['id'],
};

type ReducerState = typeof initialState;

interface ReducerAction {
  type: 'VISIBLE_ADD_INCIDENTTYPE' | 'VISIBLE_GET_INCIDENTTYPE' | 'HIDDEN';
  payload?: {
    incidentTypeId: incidentTypeTypes.IncidentType['id'] | null;
  };
}

/**
 * state reducer
 * @param {ReducerState} state
 * @param {ReducerAction} action
 * @return {ReducerState}
 */
function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  const { incidentTypeId = null } = action.payload ? action.payload : {};

  switch (action.type) {
    case 'VISIBLE_ADD_INCIDENTTYPE':
      return {
        ...state,
        modalAddIncidentVisible: true,
      };
    case 'VISIBLE_GET_INCIDENTTYPE':
      return {
        ...state,
        modalGetIncidentVisible: true,
        incidentTypeId,
      };
    case 'HIDDEN':
      return {
        ...state,
        modalAddIncidentVisible: false,
        modalGetIncidentVisible: false,
      };
    default:
      throw new Error();
  }
}

interface Props {
  incidentTypes: incidentTypeTypes.IncidentType[];
  deleteIncidentType: Function;
}

interface TableIncidentType {
  id: incidentTypeTypes.IncidentType['id'];
  key: incidentTypeTypes.IncidentType['id'];
  name: incidentTypeTypes.IncidentType['name'];
  createdAt: incidentTypeTypes.IncidentType['createdAt'];
  updatedAt: incidentTypeTypes.IncidentType['updatedAt'];
  action: object;
}

const dateToolTip = (date: string): ReactElement => <DateTooltip date={date} />;
interface UseSidebar {
  showAddIncidentTypeModal: Function;
  showGetIncidentTypeModal: Function;
  hideModal: Function;
  state: ReducerState;
}

const useSidebar = (): UseSidebar => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showAddIncidentTypeModal = (): void => {
    dispatch({ type: 'VISIBLE_ADD_INCIDENTTYPE' });
  };

  const showGetIncidentTypeModal = (id: string): void => {
    dispatch({
      type: 'VISIBLE_GET_INCIDENTTYPE',
      payload: { incidentTypeId: id },
    });
  };

  const hideModal = (): void => {
    dispatch({ type: 'HIDDEN' });
  };

  return {
    showAddIncidentTypeModal,
    showGetIncidentTypeModal,
    hideModal,
    state,
  };
};

const Incident: FunctionComponent<Props> = ({
  incidentTypes,
  deleteIncidentType,
}): ReactElement => {
  const {
    state,
    showAddIncidentTypeModal,
    showGetIncidentTypeModal,
    hideModal,
  } = useSidebar();

  const [activeTab, setActiveTab] = useState('type');

  const datas = incidentTypes.map(
    (incidentType: incidentTypeTypes.IncidentType): TableIncidentType => {
      return {
        id: incidentType.id,
        key: incidentType.id,
        name: incidentType.name,
        createdAt: incidentType.createdAt,
        updatedAt: incidentType.updatedAt,
        action: {
          id: incidentType.id,
        },
      };
    },
  );

  const columns: ColumnProps<TableIncidentType>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a: TableIncidentType, b: TableIncidentType): number =>
        alphabeticalSortBy(a.name, b.name),
    },
    {
      key: 'createdAt',
      title: 'Créé',
      dataIndex: 'createdAt',
      render: dateToolTip,
      sorter: (a: TableIncidentType, b: TableIncidentType): number =>
        chronologicalSortBy(a.createdAt, b.createdAt),
    },
    {
      key: 'updatedAt',
      title: 'Modifié',
      dataIndex: 'updatedAt',
      render: dateToolTip,
      sorter: (a: TableIncidentType, b: TableIncidentType): number =>
        chronologicalSortBy(a.updatedAt, b.updatedAt),
    },
    {
      key: 'operation',
      title: (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={(): void => showAddIncidentTypeModal()}
        >
          Créer
        </Button>
      ),
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableIncidentType): ReactElement => {
        return (
          <PopoverActions
            objectId={record.id}
            onDelete={deleteIncidentType}
            deleteConfirmMessage="Supprimer le type d'incident ?"
            deleteDoneMessage="Le type d'incident a bien été supprimé"
            size="small"
          />
        );
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: TableIncidentType): any => ({
    onClick: (): void => {
      showGetIncidentTypeModal(record.id);
    },
  });

  const tabList = [
    {
      key: 'type',
      tab: "Type d'incident",
    },
  ];

  const contentList = {
    type: (
      <Table<TableIncidentType>
        columns={columns}
        dataSource={datas}
        onRow={onRow}
      />
    ),
  };

  return (
    <RestrictedToPermissions subject={'administration'} action={'access'}>
      <PageHeader title="Incidents" />
      <Card
        className="section"
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={(key): void => setActiveTab(key)}
      >
        {
          // @ts-ignore
          contentList[activeTab]
        }
      </Card>
      <AddIncidentType
        visible={state.modalAddIncidentVisible}
        hideModal={hideModal}
      />
      <GetIncidentType
        visible={state.modalGetIncidentVisible}
        hideDrawer={hideModal}
        incidentTypeId={state.incidentTypeId || ''}
      />
    </RestrictedToPermissions>
  );
};

Incident.propTypes = {
  incidentTypes: PropTypes.arrayOf(
    incidentTypeTypes.PropTypesIncidentType.isRequired,
  ).isRequired,
  deleteIncidentType: PropTypes.func.isRequired,
};

export default Incident;
