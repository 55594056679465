import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Redirect } from 'react-router';
import {
  IncidentStatusTag,
  Card,
  Row,
  Col,
  IncidentTypesSelect,
  TextArea,
  DateAndAuthorField,
  EditableField,
  LocationsTreeSelect,
  DatePicker,
  TimePicker,
  PopoverActions,
  UploadPicturesListField,
  MessageHandler,
  Histories,
  IncidentStatusTimeline,
  IncidentAcceptationAlert,
  Skeleton,
  ProfilesSelect,
  IncidentLocationName,
  PageHeader,
  Field,
  IncidentContractorName,
  IncidentRejectAlert,
  notification,
  Tooltip,
  Input,
  RestrictedToPermissions,
  ReactedUsers,
} from '../../base';
import { incidentTypes as types } from '../../../../states/ducks/incidents';
import { locationTypes } from '../../../../states/ducks/locations';
import { incidentTypeTypes } from '../../../../states/ducks/incidenttypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { CheckCircleFilled } from '@ant-design/icons';
import { Pagination } from '../../../../services/search/search';
import UploadListField from '../../base/upload/UploadListField';
import { File } from '../../../../states/ducks/files/types';
import { convertFileToUploadFile } from '../../../../utils/FileUtils';

interface IncidentInfoProps {
  incidentId: types.Incident['id'];
  incident: types.Incident | null;
  isGettingIncident: boolean;
  location: locationTypes.Location | null;
  incidentType: incidentTypeTypes.IncidentType | null;
  files: File[];
  pictures: File[];
  getFile: Function;
  getIncident: Function;
  getLocation: Function;
  getIncidentType: Function;
  retypeIncident: Function;
  remarkIncident: Function;
  relocateIncident: Function;
  changeReportingIncident: Function;
  changeServiceOrderNumberIncident: Function;
  deleteIncident: Function;
  addFileIncident: Function;
  addGEDFileIncident: Function;
  deleteFileIncident: Function;
  totalComments: Pagination['total'];
}

const IncidentInfo: FunctionComponent<IncidentInfoProps> = ({
  incidentId,
  incident,
  isGettingIncident,
  location,
  incidentType,
  files,
  pictures,
  getFile,
  getIncident,
  getLocation,
  getIncidentType,
  retypeIncident,
  remarkIncident,
  relocateIncident,
  changeReportingIncident,
  changeServiceOrderNumberIncident,
  deleteIncident,
  addFileIncident,
  addGEDFileIncident,
  deleteFileIncident,
  totalComments,
}): ReactElement => {
  const [activeTab, setActiveTab] = useState('comments');
  const [hasGotIncident, setHasGotIncident] = useState(false);
  const [reloadFilesNeeded, setReloadFilesNeeded] = useState(false);

  useEffect((): void => {
    if (isGettingIncident) {
      setHasGotIncident(true);
    }
  }, [isGettingIncident]);

  useEffect((): void => {
    if (incidentId) {
      getIncident(incidentId);
    }
  }, [getIncident, incidentId]);

  useEffect((): void => {
    if (incident && incident.locationId) {
      getLocation(incident.locationId);
    }
  }, [getLocation, incident]);

  useEffect((): void => {
    if (incident && incident.incidentTypeId) {
      getIncidentType(incident.incidentTypeId);
    }
  }, [getIncidentType, incident]);

  useEffect((): void => {
    if (incident && incident.fileIds && incident.fileIds.length > 0) {
      incident.fileIds.forEach((id) => getFile(id));
    }
  }, [getFile, incident]);

  // TODO: set files in another state to avoid conflicts with files in the GED modal
  useEffect((): void => {
    if (reloadFilesNeeded) {
      if (incident && incident.fileIds && incident.fileIds.length > 0) {
        incident.fileIds.forEach((id) => getFile(id));
      }
      setReloadFilesNeeded(false);
    }
  }, [getFile, incident, reloadFilesNeeded]);

  if (!incidentId || (hasGotIncident && !isGettingIncident && !incident)) {
    return (
      <Redirect
        to={{
          pathname: '/incidents/',
        }}
      />
    );
  }

  const ifNotAcknowledged = (incidentFunction: Function): Function => {
    if (incident && incident.acknowledge) {
      return (): void => {
        notification.error({
          message:
            'L’intervention a déjà été générée chez le prestataire, impossible de modifier ce champs.',
        });
      };
    }

    return incidentFunction;
  };

  const incidentStatus = incident ? (
    <IncidentStatusTag status={incident.status} />
  ) : (
    <></>
  );
  const incidentTypeField =
    incident && incidentType ? (
      <EditableField
        object={incident}
        label="Type de l'incident"
        content={incidentType.name}
        editedFieldsOptions={{
          field: 'incidentTypeId',
          label: "Type de l'incident",
          content: (
            <IncidentTypesSelect placeholder="Sélectionnez un type d'incident" />
          ),
          initialValue: incident.incidentTypeId,
          required: true,
          invalidMessage: 'Sélectionnez un type d&apos;incident',
        }}
        onSubmit={ifNotAcknowledged(retypeIncident)}
        successMessage="Le type d'incident a bien été modifié"
        restrictedToPermissions={{
          subject: 'incident',
          action: 'edit',
        }}
      />
    ) : (
      ''
    );
  const locationField = incident ? (
    <EditableField
      object={incident}
      label="Localisation"
      content={<IncidentLocationName locationId={incident.locationId} />}
      editedFieldsOptions={{
        field: 'locationId',
        label: 'Localisation',
        content: <LocationsTreeSelect placeholder="Sélectionnez un lieu" />,
        initialValue: location ? incident.locationId : '',
        required: true,
        invalidMessage: 'Sélectionnez une localisation',
      }}
      onSubmit={ifNotAcknowledged(relocateIncident)}
      successMessage="La localisation a bien été modifiée"
      restrictedToPermissions={{
        subject: 'incident',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );
  const serviceOrderNumberField = incident ? (
    <EditableField
      object={incident}
      label="Numéro OS"
      content={incident.serviceOrderNumber || ''}
      editedFieldsOptions={{
        field: 'serviceOrderNumber',
        label: 'Numéro OS',
        content: <Input />,
        initialValue: incident.serviceOrderNumber || '',
      }}
      onSubmit={changeServiceOrderNumberIncident}
      successMessage="Le numéro OS a bien été modifié"
      restrictedToPermissions={{
        subject: 'incident',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );
  const remarkField = incident ? (
    <EditableField
      object={incident}
      label="Précisions"
      content={incident.remark || undefined}
      editedFieldsOptions={{
        field: 'remark',
        label: 'Précisions',
        content: <TextArea placeholder="Saisissez des précisions" />,
        initialValue: incident.remark,
      }}
      onSubmit={remarkIncident}
      successMessage="Les précisions ont bien été modifiées"
      restrictedToPermissions={{
        subject: 'incident',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );
  const reportedAtField = incident ? (
    <EditableField
      object={incident}
      label="Signalé"
      content={
        <DateAndAuthorField
          date={incident.reportedAt}
          userId={incident.userId}
        />
      }
      editedFieldsOptions={[
        {
          field: 'reportedAt',
          label: 'Date du signalement',
          content: <DatePicker onlyPast={true} inverted />,
          required: true,
          type: 'object',
          initialValue: moment(incident.reportedAt),
          colSize: 13,
        },
        {
          field: 'reportedAt',
          label: 'Heure du signalement',
          content: <TimePicker />,
          required: true,
          type: 'object',
          initialValue: moment(incident.reportedAt),
          colSize: 11,
        },
        {
          field: 'userId',
          label: 'Rapporteur',
          content: <ProfilesSelect placeholder={'Rapporteur'} />,
          required: true,
          initialValue: incident.userId,
          colSize: 11,
        },
      ]}
      onSubmit={changeReportingIncident}
      successMessage="Le signalement a bien été modifié"
      restrictedToPermissions={{
        subject: 'incident',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );
  const photosField = incident ? (
    <UploadPicturesListField
      fileList={pictures.map((file) => convertFileToUploadFile(file))}
      label="Photos"
      limit={6}
      onUpload={(file, meta): void =>
        addFileIncident(
          {
            file: file,
            incident: incident,
          },
          meta,
        )
      }
      onDelete={(file, meta): void => {
        deleteFileIncident(
          {
            fileId: file.uid,
            incident: incident,
          },
          meta,
        );
      }}
      restrictedToPermissions={{
        subject: 'incident',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );
  const filesField = incident ? (
    <UploadListField
      displayLimit={6}
      excludedMimeTypePatternMessage={'Ce champ ne peut contenir des images'}
      excludeMimeTypePattern={'image/'}
      fileList={files.map((file) => convertFileToUploadFile(file))}
      label="Documents"
      onGEDUpload={(fileIds, meta): void =>
        addGEDFileIncident(
          {
            fileIds: fileIds,
            incident: incident,
          },
          meta,
        )
      }
      onGEDClose={(): void => setReloadFilesNeeded(true)}
      onUpload={(file, meta): void =>
        addFileIncident(
          {
            file: file,
            incident: incident,
          },
          meta,
        )
      }
      onDelete={(file, meta): void => {
        deleteFileIncident(
          {
            fileId: file.uid,
            incident: incident,
          },
          meta,
        );
      }}
    />
  ) : (
    ''
  );
  const contractorField =
    incident && incident.contractorId ? (
      <Field
        label={
          <>
            Prestataire
            {incident && incident.acknowledge ? (
              <Tooltip title="Incident réceptionné par le prestataire">
                <CheckCircleFilled />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        }
        content={
          <IncidentContractorName contractorId={incident.contractorId} />
        }
      />
    ) : (
      ''
    );

  const approvedByUserIdField =
    incident && incident.approveUserIds ? (
      <Field
        label={'Approuvé par'}
        content={<ReactedUsers approveUserIds={incident.approveUserIds} />}
      />
    ) : (
      ''
    );

  moment.locale('fr');
  const subTitle = incident
    ? 'Mis à jour le ' + moment(incident.updatedAt).format('L [à] H[h]mm')
    : '';

  const tabList = [
    {
      key: 'comments',
      tab: `Commentaires (${totalComments})`,
    },
    {
      key: 'histories',
      tab: 'Historique',
    },
  ];

  const contentList = {
    comments: (
      <MessageHandler
        objectType={'incident'}
        objectId={incidentId}
        addPlaceholder={'Votre commentaire...'}
        addSuccessMessage={'Le commentaire a bien été ajouté'}
        deleteConfirmationMessage={'Supprimer le commentaire ?'}
        deleteSuccessMessage={'Le commentaire a bien été supprimé'}
        editSuccessMessage={'Le commentaire a bien été édité'}
        emptyMessage={'Aucun commentaire'}
        listingErrorMessage={
          "Une erreur s'est produite lors du chargement des commentaires"
        }
      />
    ),
    histories: <Histories objectType={'incident'} object={incident} />,
  };

  return (
    <Skeleton loading={isGettingIncident}>
      <PageHeader
        title={incident ? `Incident n°${incident.number}` : ''}
        subTitle={subTitle}
        tags={incidentStatus}
        extra={[
          <RestrictedToPermissions
            subject={'incident'}
            action={'edit'}
            key="incident-info-action-1"
          >
            <PopoverActions
              objectId={incidentId}
              onDelete={deleteIncident}
              deleteConfirmMessage="Supprimer l'incident ?"
              deleteDoneMessage="L'incident a bien été supprimé"
            />
          </RestrictedToPermissions>,
        ]}
      >
        <IncidentRejectAlert incidentId={incidentId} />
        <RestrictedToPermissions subject={'incident'} action={'acceptOrReject'}>
          <Row>
            <Col>
              <IncidentAcceptationAlert incidentId={incidentId} />
            </Col>
          </Row>
        </RestrictedToPermissions>
      </PageHeader>

      <Row className="mt-40">
        <Col span={24}>
          <Card className="section" title="Timeline">
            <Row>
              <Col span={24}>
                <IncidentStatusTimeline incidentId={incidentId} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="mt-40">
        <Col span={24}>
          <Card className="section" title="Informations">
            <Row>
              <Col lg={24} xl={16}>
                <Row>
                  <Col span={24} md={12}>
                    {incidentTypeField}
                    {locationField}
                    {approvedByUserIdField}
                    {contractorField}
                  </Col>
                  <Col span={24} md={12}>
                    {remarkField}
                    {reportedAtField}
                    {serviceOrderNumberField}
                  </Col>
                </Row>
              </Col>
              <Col lg={24} xl={8}>
                {photosField}
                {filesField}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card
        className="section"
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={(key): void => setActiveTab(key)}
      >
        {
          // @ts-ignore
          contentList[activeTab]
        }
      </Card>
    </Skeleton>
  );
};

IncidentInfo.propTypes = {
  incidentId: PropTypes.string.isRequired,
  incident: types.PropTypesIncident,
  isGettingIncident: PropTypes.bool.isRequired,
  location: locationTypes.PropTypesLocation,
  incidentType: incidentTypeTypes.PropTypesIncidentType,
  files: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  pictures: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  getFile: PropTypes.func.isRequired,
  getIncident: PropTypes.func.isRequired,
  getLocation: PropTypes.func.isRequired,
  getIncidentType: PropTypes.func.isRequired,
  retypeIncident: PropTypes.func.isRequired,
  remarkIncident: PropTypes.func.isRequired,
  relocateIncident: PropTypes.func.isRequired,
  changeReportingIncident: PropTypes.func.isRequired,
  changeServiceOrderNumberIncident: PropTypes.func.isRequired,
  deleteIncident: PropTypes.func.isRequired,
  addFileIncident: PropTypes.func.isRequired,
  addGEDFileIncident: PropTypes.func.isRequired,
  deleteFileIncident: PropTypes.func.isRequired,
  totalComments: PropTypes.number.isRequired,
};

export default IncidentInfo;
