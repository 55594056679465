import React, { FunctionComponent, ReactElement } from 'react';
import { Popover as PopoverAnt } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import 'antd/lib/popover/style';
import './Popover.scss';

/**
 * @param {PopoverProps} props
 *
 * @return {ReactElement}
 */
const Popover: FunctionComponent<PopoverProps> = ({
  ...props
}): ReactElement => <PopoverAnt {...props} />;

export default Popover;
