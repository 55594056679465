export const requestStatuses: { [key: string]: string } = {
  opened: 'Ouverte',
  closed: 'Fermée',
};

// Used to set the colors in dashboard charts
const requestStatusColor: { [key: string]: string } = {
  opened: '#36EFBA',
  closed: '#ff3434',
};

/**
 * @param {O} obj
 * @param {string} key
 * @return {boolean}
 */
function hasKey<O>(obj: O, key: string): boolean {
  return key in obj;
}

/**
 * IncidentStatusUtils
 */
export default class RequestStatusUtils {
  /**
   * @param {string} status
   * @return {string}
   */
  static getLabel(status: string): string {
    if (!hasKey(requestStatuses, status)) {
      throw new Error('Invalid status');
    }

    return requestStatuses[status];
  }

  /**
   * @param {string} status
   * @return {string}
   */
  static getColor(status: string): string {
    if (!hasKey(requestStatusColor, status)) {
      throw new Error('Invalid status');
    }

    return requestStatusColor[status];
  }
}
