import React, { FunctionComponent, ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  Button,
  Select,
  SelectOption,
  Tag,
  Input,
  notification,
} from '../index';
import { SelectProps } from './Select';
import { RouteComponentProps, withRouter } from 'react-router';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import 'antd/lib/tag/style';
import { PlusOutlined } from '@ant-design/icons';
import './TagsSelect.scss';
import {
  tagTypes,
  tagSelectors,
  tagActions,
} from '../../../../states/ducks/tags';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import { DeleteOutlined } from '@ant-design/icons';

interface TagsSelectProps extends SelectProps, RouteComponentProps {
  tags: tagTypes.Tag[];
  fileId: string;
  addTag: Function;
  deleteTag: Function;
  currentUser: CurrentUser | null;
  initialValue?: string[];
}

/**
 * @param {object[]} roles
 * @param {SelectProps&RouteComponentProps} props
 *
 * @return {ReactElement}
 */
const TagsSelect: FunctionComponent<TagsSelectProps> = ({
  fileId,
  addTag,
  deleteTag,
  currentUser,
  tags,
  initialValue,
  ...props
}): ReactElement => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const initialSelectedItems: string[] = initialValue || [];
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  const selectOptions = tags
    .filter((tag) => !selectedItems.includes(tag.id))
    .map((tag): ReactElement => {
      return (
        <SelectOption key={tag.id} value={tag.id}>
          <Tag
            color="green"
            closable={true}
            closeIcon={<DeleteOutlined />}
            onClose={(): void => handleOnClose(tag.id)}
          >
            {tag.name}
          </Tag>
        </SelectOption>
      );
    });

  const tagRender = (props: CustomTagProps): ReactElement => {
    // eslint-disable-next-line react/prop-types
    const { value, closable, onClose } = props;
    const tag = tags.find((tag) => tag.id === value || null);
    const onPreventMouseDown = (event): void => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="green"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={(e): void => {
          setSelectedItems(selectedItems.filter((id) => value !== id));
          onClose();
        }}
        style={{ marginRight: 3 }}
      >
        {tag?.name}
      </Tag>
    );
  };

  const handleSelectChange = (value): void =>
    setSelectedItems(selectedItems.concat(value));

  const handleInputChange = (e): void => setInputValue(e.target.value);

  const handleEditInputConfirm = (): void => {
    if (inputValue === '') return setInputVisible(false);

    const tag = {
      organizationId: currentUser?.organizationIds[0],
      userId: currentUser?.id,
      name: inputValue,
    };

    const promise = new Promise<tagTypes.Tag>((resolve, reject): void =>
      addTag(tag, { resolve, reject }),
    );

    promise
      .then((value): void => {
        setInputValue('');
        setInputVisible(false);
        // eslint-disable-next-line react/prop-types
        setSelectedItems(selectedItems.concat(value.id));
        const message = `Le tag a bien été ajouté`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      });
  };

  const handleOnClose = (TagId): void => {
    const promise = new Promise<tagTypes.Tag>((resolve, reject): void =>
      deleteTag(TagId, { resolve, reject }),
    );

    promise
      .then((): void => {
        setInputValue('');
        setInputVisible(false);
        const message = `Le tag a bien été supprimé`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      });
  };

  return (
    <>
      <Select
        {...props}
        mode="multiple"
        tagRender={tagRender}
        value={selectedItems}
        onSelect={handleSelectChange}
        className="dropdown-in-flow"
        dropdownClassName="tags-dropdown"
        dropdownRender={(menu): ReactElement => {
          return (
            <>
              {menu}
              <div className="tag-input-container">
                {inputVisible && (
                  <>
                    <Input
                      type="text"
                      size="small"
                      className="tag-input"
                      value={inputValue}
                      onChange={handleInputChange}
                      autoFocus
                    />
                    <Button
                      size="small"
                      type="primary"
                      onClick={(): void => handleEditInputConfirm()}
                    >
                      <PlusOutlined />
                    </Button>
                  </>
                )}
                {!inputVisible && (
                  <Tag
                    className="site-tag-plus"
                    onClick={(): void => setInputVisible(true)}
                  >
                    <PlusOutlined /> Nouveau tag
                  </Tag>
                )}
              </div>
            </>
          );
        }}
      >
        {selectOptions}
      </Select>
    </>
  );
};

TagsSelect.propTypes = {
  tags: PropTypes.arrayOf(tagTypes.PropTypesTag.isRequired).isRequired,
  fileId: PropTypes.string.isRequired,
  addTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  currentUser: PropTypesCurrentUser,
  initialValue: PropTypes.array,
};

interface MapStateToProps {
  tags: tagTypes.Tag[];
  currentUser: CurrentUser | null;
}

interface MapDispatchToProps {
  addTag: Function;
  deleteTag: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const currentUser = currentUserSelector(state);

  return {
    tags: tagSelectors.tagsFromOrganizationSelector(state),
    currentUser: Object.keys(currentUser).length > 0 ? currentUser : null,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  addTag: tagActions.fetchAddTagsActionCreator,
  deleteTag: tagActions.fetchDeleteTagsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TagsSelect));
