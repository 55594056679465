import PropTypes from 'prop-types';
import { Coords } from 'google-map-react';
import { TreeModel } from '../common/types';

export interface Location extends TreeModel {
  organizationId: string;
  code: string;
  fileIds?: string[] | null;
  remark?: string | null;
  locationTypeId?: string | null;
  addressComponents?: AddressComponents | null;
  parents?: Location[] | null; // TODO Remove by make TreeModel generic
  children?: Location[] | null; // TODO Remove by make TreeModel generic
}

export interface AddressComponents {
  streetNumber?: string | null;
  route?: string | null;
  locality?: string | null;
  administrativeAreaLevel2?: string | null;
  administrativeAreaLevel1?: string | null;
  country?: string | null;
  postalCode?: string | null;
  formattedAddress?: string | null;
  additionalAddress?: string | null;
  latitude?: Coords['lat'] | null;
  longitude?: Coords['lng'] | null;
}

export const AddressComponentsInGeocoderResult: (keyof Omit<
  AddressComponents,
  'latitude' | 'longitude'
>)[] = [
  'streetNumber',
  'route',
  'locality',
  'administrativeAreaLevel2',
  'administrativeAreaLevel1',
  'country',
  'postalCode',
];

/**
 * main state of application
 */

export interface LocationState {
  locations: Location[];
  status: {
    listing: boolean;
    adding: boolean;
    editing: boolean;
    deleting: boolean;
    getting: boolean;
  };
}

export const PropTypesLocation = PropTypes.shape({
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  fileIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  parentId: PropTypes.string,
  remark: PropTypes.string,
  locationTypeId: PropTypes.string,
  children: PropTypes.array,
  parents: PropTypes.array,
  addressComponents: PropTypes.shape({
    streetNumber: PropTypes.string,
    route: PropTypes.string,
    locality: PropTypes.string,
    administrativeAreaLevel2: PropTypes.string,
    administrativeAreaLevel1: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    formattedAddress: PropTypes.string,
    additionalAddress: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
});
