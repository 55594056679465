import React, { FunctionComponent, ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { profileFromUserIdSelector } from '../../../../states/ducks/profiles/selectors';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import { roleFromIdSelector } from '../../../../states/ducks/roles/selectors';
import { userFromIdSelector } from '../../../../states/ducks/users/selectors/usersSelectors';
import { User } from '../../../../states/ducks/users/types';
import { State } from '../../../../states/types';
import { Avatar, Button, Modal, Popover, UserCard } from '../../base';
import './ReactedUsers.scss';
import { List } from 'antd';

type ProfileWithRole = Profile & { role?: string };

interface ReactedUsersProps {
  displayedNumber?: number;
  profiles: ProfileWithRole[];
}

/**
 * @param {number} displayedNumber
 * @param {Profile[]} profiles
 *
 * @return {ReactElement}
 */
const ReactedUsers: FunctionComponent<ReactedUsersProps> = ({
  displayedNumber,
  profiles,
}): ReactElement => {
  const [modalVisible, setModalVisible] = useState(false);

  if (!displayedNumber) {
    return <></>;
  }

  const displayedProfiles = profiles.slice(0, displayedNumber);

  const undisplayedProfilesCount = profiles.length - displayedNumber;

  return (
    <>
      {displayedProfiles.map((profile) => (
        <Popover key={profile.id} content={<UserCard profileId={profile.id} />}>
          <Avatar profileId={profile.id} />
        </Popover>
      ))}
      {undisplayedProfilesCount > 0 ? (
        <Button
          onClick={(event): void => {
            event.stopPropagation();
            setModalVisible(true);
          }}
          type={'link'}
        >
          +{undisplayedProfilesCount}
        </Button>
      ) : (
        <></>
      )}
      <Modal
        cancelText={<></>}
        visible={modalVisible}
        closable={false}
        okText={'Fermer'}
        onOk={(event): void => {
          event.stopPropagation();
          setModalVisible(false);
        }}
      >
        <List
          itemLayout="horizontal"
          dataSource={profiles}
          renderItem={(profile): ReactElement => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar profileId={profile.id} />}
                title={`${profile.firstName} ${profile.lastName}`}
                description={profile.role}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

ReactedUsers.defaultProps = {
  displayedNumber: 3,
};

ReactedUsers.propTypes = {
  displayedNumber: PropTypes.number,
  profiles: PropTypes.arrayOf(PropTypesProfile.isRequired).isRequired,
};

interface MapStateToProps {
  profiles: ProfileWithRole[];
}

const mapStateToProps = (
  state: State,
  props: {
    approveUserIds: User['id'][];
    displayedNumber?: number;
  },
): MapStateToProps => {
  const profiles: ProfileWithRole[] = [];

  props.approveUserIds.forEach((userId) => {
    const user = userFromIdSelector(state, userId);

    if (!user) {
      return;
    }

    const profile = profileFromUserIdSelector(state, userId);

    if (!profile) {
      return;
    }

    const role = user.roleId ? roleFromIdSelector(state, user.roleId) : null;
    if (role) {
      // @ts-ignore
      profile.role = role.name;
    }

    profiles.push(profile);
  });

  return {
    profiles: profiles,
  };
};

export default connect(mapStateToProps)(ReactedUsers);
