import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Comment, Modal } from 'antd';
import 'antd/lib/message/style';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {
  fetchDeleteMessagesActionCreator,
  fetchEditMessagesActionCreator,
} from '../../../../states/ducks/messages/actions';
import {
  Message as MessageType,
  PropTypesMessage,
} from '../../../../states/ducks/messages/types';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import { State } from '../../../../states/types';
import {
  Avatar,
  Button,
  notification,
  TextArea,
  ProfilePopover,
  RestrictedToPermissions,
} from '../index';
import './Message.scss';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';

interface MessageProps {
  changeMessage: Function;
  currentUser?: CurrentUser;
  deleteConfirmationMessage?: string;
  deleteMessage: Function;
  deleteSuccessMessage?: string;
  editSuccessMessage?: string;
  getProfile: Function;
  message: MessageType;
  objectType?: string;
  profile?: profileTypes.Profile | null;
}

/**
 * @param {Function} changeMessage
 * @param {CurrentUser} currentUser
 * @param {string} deleteConfirmationMessage
 * @param {Function} deleteMessage
 * @param {string} deleteSuccessMessage
 * @param {string} editSuccessMessage
 * @param {Function} getProfile
 * @param {Message} message
 * @param {string} objectType
 * @param {Profile} profile
 *
 * @return {ReactElement}
 */
const Message: FunctionComponent<MessageProps> = ({
  changeMessage,
  currentUser,
  deleteConfirmationMessage,
  deleteMessage,
  deleteSuccessMessage,
  editSuccessMessage,
  getProfile,
  message,
  objectType,
  profile,
}): ReactElement => {
  const [isEdited, setIsEdited] = useState(false);
  const [newMessage, setNewMessage] = useState(message.content);

  useEffect((): void => {
    if (getProfile && !profile) {
      getProfile(message.userId);
    }
  }, [getProfile, profile, message]);

  const onEdit = (): void => {
    setIsEdited(true);
  };

  const onCancel = (): void => {
    setIsEdited(false);
    setNewMessage(message.content);
  };

  const { confirm } = Modal;
  const onDelete = (): void => {
    confirm({
      className: 'app-modal-delete',
      title: deleteConfirmationMessage,
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      icon: <WarningOutlined />,
      onOk(): void {
        const promise = new Promise<MessageType>((resolve, reject): void =>
          deleteMessage(message.id, { resolve, reject }),
        );

        promise
          .then((): void => {
            notification.success({ message: deleteSuccessMessage });
          })
          .catch((message: string): void => {
            notification.error({ message });
          });
      },
      onCancel(): void {
        setNewMessage(message.content);
      },
    });
  };

  const onSubmit = (): void => {
    setIsEdited(false);

    if (!newMessage) {
      onDelete();

      return;
    }

    message.content = newMessage;

    const promise = new Promise<MessageType>((resolve, reject): void =>
      changeMessage(message, { resolve, reject }),
    );

    promise
      .then((): void => {
        notification.success({ message: editSuccessMessage });
        setNewMessage(message.content);
      })
      .catch((message: string): void => {
        notification.error({ message });
      });
  };

  const actions = isEdited
    ? [
        <Button
          key="message-edit-submit"
          type="primary"
          onClick={onSubmit}
          icon={<CheckOutlined />}
          size="small"
        />,
        <Button
          key="message-edit-cancel"
          icon={<CloseOutlined />}
          size="small"
          onClick={onCancel}
        />,
      ]
    : [
        <span key="message-edit" onClick={onEdit}>
          Modifier
        </span>,
        <span key="message-delete" onClick={onDelete}>
          Supprimer
        </span>,
      ];

  const actionsWithPermissions =
    message.userId === currentUser?.id || objectType === 'request'
      ? actions
      : [
          <RestrictedToPermissions
            subject={'incident'}
            action={'deleteMessage'}
            key="message-delete"
          >
            <span onClick={onDelete}>Supprimer</span>
          </RestrictedToPermissions>,
        ];

  return (
    <Comment
      actions={actionsWithPermissions ? actionsWithPermissions : undefined}
      author={<ProfilePopover userId={message.userId} />}
      avatar={<Avatar userId={message.userId} size={'large'} />}
      content={
        isEdited ? (
          <TextArea
            autoSize={true}
            value={newMessage}
            onChange={(e): void => setNewMessage(e.target.value)}
          >
            {message.content}
          </TextArea>
        ) : (
          <p>{message.content}</p>
        )
      }
      datetime={
        <span className="date">
          {moment(message.createdAt).format('DD/MM/YYYY à H[h]mm')}
        </span>
      }
      className={isEdited ? 'edited' : ''}
    />
  );
};

Message.displayName = 'Message';

Message.propTypes = {
  changeMessage: PropTypes.func.isRequired,
  currentUser: PropTypesCurrentUser.isRequired,
  deleteConfirmationMessage: PropTypes.string,
  deleteMessage: PropTypes.func.isRequired,
  deleteSuccessMessage: PropTypes.string,
  editSuccessMessage: PropTypes.string,
  getProfile: PropTypes.func.isRequired,
  message: PropTypesMessage.isRequired,
  objectType: PropTypes.string,
  profile: profileTypes.PropTypesProfile,
};

Message.defaultProps = {
  deleteConfirmationMessage: 'Supprimer le message ?',
  deleteSuccessMessage: 'Le message a bien été supprimé',
  editSuccessMessage: 'Le message a bien été édité',
};

interface MapStateToProps {
  currentUser: CurrentUser;
  profile: profileTypes.Profile | null;
}

interface MapDispatchToProps {
  changeMessage: Function;
  deleteMessage: Function;
  getProfile: Function;
}

const mapStateToProps = (
  state: State,
  props: { message: MessageType },
): MapStateToProps => ({
  currentUser: currentUserSelector(state),
  profile: profileSelectors.profileFromUserIdSelector(
    state,
    props.message.userId,
  ),
});

const mapDispatchToProps: MapDispatchToProps = {
  changeMessage: fetchEditMessagesActionCreator,
  deleteMessage: fetchDeleteMessagesActionCreator,
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
