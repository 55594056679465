import { UploadFile } from 'antd/lib/upload/interface';
import { createAction } from 'redux-actions';
import { PaginatedResult, Search } from '../../../services/search/search';
import { defaultMeta, Meta } from '../common/actions';
import { getActionType } from '../common/types';
import { Request } from './types';

/**
 * @param {Request} request
 * @return {FetchAddRequested}
 */
export const fetchOpenRequestsActionCreator = createAction(
  getActionType('requests', 'OPEN_REQUESTED'),
  (request: Request): Request => request,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Request} request
 * @return {FetchAddSucceeded}
 */
export const fetchOpenRequestsSuccessActionCreator = createAction(
  getActionType('requests', 'OPEN_SUCCEEDED'),
  (request: Request): Request => request,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchOpenRequestsErrorActionCreator = createAction(
  getActionType('requests', 'OPEN_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Search} search
 * @param {Meta} meta
 * @return {FetchListRequested}
 */
export const fetchListRequestsActionCreator = createAction(
  getActionType('requests', 'LIST_REQUESTED'),
  (search?: Search): Search | undefined => search,
  (_, meta?: Meta): Meta | undefined => meta,
);

/**
 * @param {Request[]} requests
 * @return {FetchListSucceeded}
 */
export const fetchListRequestsSuccessActionCreator = createAction(
  getActionType('requests', 'LIST_SUCCEEDED'),
  (requests: Request[]): Request[] => requests,
);

/**
 * @param {PaginatedResult} paginatedResult
 * @return {FetchPaginatedListSucceeded}
 */
export const fetchPaginatedListRequestsSuccessActionCreator = createAction(
  getActionType('requests', 'LIST_PAGINATED_SUCCEEDED'),
  (paginatedResult: PaginatedResult): PaginatedResult => paginatedResult,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListRequestsErrorActionCreator = createAction(
  getActionType('requests', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetRequestsActionCreator = createAction(
  getActionType('requests', 'GET_REQUESTED'),
  (id: Request['id']): Request['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Request} request
 * @return {FetchGetSucceeded}
 */
export const fetchGetRequestsSuccessActionCreator = createAction(
  getActionType('requests', 'GET_SUCCEEDED'),
  (request: Request): Request => request,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetRequestsErrorActionCreator = createAction(
  getActionType('requests', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Request} request
 * @return {FetchEditSucceeded}
 */
export const fetchEditRequestsSuccessActionCreator = createAction(
  getActionType('requests', 'EDIT_SUCCEEDED'),
  (request: Request): Request => request,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditRequestsErrorActionCreator = createAction(
  getActionType('requests', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRequestsActionCreator = createAction(
  getActionType('requests', 'EDIT_REQUESTED'),
  (request: Request): Request => request,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRequestsCreatorActionCreator = createAction(
  getActionType('requests', 'EDIT_CREATOR_REQUESTED'),
  (request: Request): Request => request,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRequestsManagerActionCreator = createAction(
  getActionType('requests', 'EDIT_MANAGER_REQUESTED'),
  (request: Request): Request => request,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditRequestsOrganizationActionCreator = createAction(
  getActionType('requests', 'EDIT_ORGANIZATIONS_REQUESTED'),
  (request: Request): Request => request,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchCloseRequestsActionCreator = createAction(
  getActionType('requests', 'CLOSE_REQUESTED'),
  (request: Request): Request => request,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {File} file
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchAddFileRequested}
 */
export const fetchAddFileRequestsActionCreator = createAction(
  getActionType('requests', 'ADD_FILE_REQUESTED'),
  (payload: {
    file: UploadFile;
    request: Request;
  }): {
    file: UploadFile;
    request: Request;
  } => payload,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {File} file
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchAddGEDFileRequested}
 */
export const fetchAddGEDFileRequestsActionCreator = createAction(
  getActionType('requests', 'ADD_GED_FILE_REQUESTED'),
  (payload: {
    fileIds: string[];
    request: Request;
  }): {
    fileIds: string[];
    request: Request;
  } => payload,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} fileId
 * @param {Request} request
 * @param {Meta} meta
 * @return {FetchDeleteFileRequested}
 */
export const fetchDeleteFileRequestsActionCreator = createAction(
  getActionType('requests', 'DELETE_FILE_REQUESTED'),
  (payload: {
    fileId: string;
    request: Request;
  }): {
    fileId: string;
    request: Request;
  } => payload,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteRequestsActionCreator = createAction(
  getActionType('requests', 'DELETE_REQUESTED'),
  (id: Request['id']): Request['id'] => id,
  (request, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteRequestsSuccessActionCreator = createAction(
  getActionType('requests', 'DELETE_SUCCEEDED'),
  (id: Request['id']): Request['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteRequestsErrorActionCreator = createAction(
  getActionType('requests', 'DELETE_FAILED'),
  (message: string): string => message,
);

export type FetchAddFileRequested = ReturnType<
  typeof fetchAddFileRequestsActionCreator
>;

export type FetchAddGEDFileRequested = ReturnType<
  typeof fetchAddGEDFileRequestsActionCreator
>;

export type FetchDeleteFileRequested = ReturnType<
  typeof fetchDeleteFileRequestsActionCreator
>;
