import React, { FunctionComponent, ReactElement, useState } from 'react';
import { Button, notification } from '../index';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import './MultipleActionsButton.scss';
import PropTypes from 'prop-types';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';

interface MultipleActionsButtonProps extends ModalProps {
  visible?: boolean;
  objectIds: string[];
  onDelete?: Function;
  afterDelete?: Function;
  deleteConfirmationMessage?: string;
  deleteSuccessMessage?: string;
}

/**
 * MultipleActionsButton component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const MultipleActionsButton: FunctionComponent<MultipleActionsButtonProps> = ({
  visible,
  objectIds,
  onDelete,
  afterDelete,
  deleteConfirmationMessage,
  deleteSuccessMessage,
  ...props
}): ReactElement => {
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false);

  const showDeleteConfirm = (): void => {
    confirm({
      className: 'app-modal-delete',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      icon: <WarningOutlined />,
      maskClosable: true,
      onOk(): void {
        if (onDelete && afterDelete) {
          setLoading(true);
          const promise = new Promise<void>((resolve, reject): void =>
            onDelete(objectIds, { resolve, reject }),
          );
          promise
            .then((): void => {
              notification.success({
                message: deleteSuccessMessage,
              });
              afterDelete();
              setLoading(false);
            })
            .catch((message: string): void => {
              notification.error({ message });
              setLoading(false);
            });
        }
      },
      ...props,
    });
  };

  return visible ? (
    <div className="container-multiple-actions-button">
      <div className="multiple-actions-button">
        {onDelete ? (
          <Button
            type="primary"
            shape="round"
            icon={<DeleteOutlined />}
            onClick={(): void => showDeleteConfirm()}
            loading={loading}
          >
            Supprimer
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

MultipleActionsButton.defaultProps = {
  deleteConfirmationMessage: 'Supprimer les éléments sélectionnés ?',
  deleteSuccessMessage: 'Les éléments ont bien été supprimés',
};

MultipleActionsButton.propTypes = {
  visible: PropTypes.bool,
  objectIds: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  afterDelete: PropTypes.func,
  deleteConfirmationMessage: PropTypes.string,
  deleteSuccessMessage: PropTypes.string,
};

export default MultipleActionsButton;
