import { createAction } from 'redux-actions';
import { PaginatedResult, Search } from '../../../services/search/search';
import { defaultMeta, Meta } from '../common/actions';
import { getActionType } from '../common/types';
import { File } from './types';

/**
 * @param {File} file
 * @param {Meta} meta
 * @return {FetchAddRequested}
 */
export const fetchAddFilesActionCreator = createAction(
  getActionType('files', 'ADD_REQUESTED'),
  (file: File) => file,
  (file, meta = defaultMeta) => meta,
);

/**
 * @param {File} file
 * @return {FetchAddSucceeded}
 */
export const fetchAddFilesSuccessActionCreator = createAction(
  getActionType('files', 'ADD_SUCCEEDED'),
  (file: File) => file,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddFilesErrorActionCreator = createAction(
  getActionType('files', 'ADD_FAILED'),
  (message: string) => message,
);

/**
 * @param {Search} search
 * @param {Meta} meta
 * @return {FetchListRequested}
 */
export const fetchListFilesActionCreator = createAction(
  getActionType('files', 'LIST_REQUESTED'),
  (search?: Search): Search | undefined => search,
  (_, meta?: Meta): Meta | undefined => meta,
);

/**
 * @param {File[]} files
 * @return {FetchListSucceeded}
 */
export const fetchListFilesSuccessActionCreator = createAction(
  getActionType('files', 'LIST_SUCCEEDED'),
  (files: File[]): File[] => files,
);

/**
 * @param {PaginatedResult} paginatedResult
 * @return {FetchPaginatedListSucceeded}
 */
export const fetchPaginatedListFilesSuccessActionCreator = createAction(
  getActionType('files', 'LIST_PAGINATED_SUCCEEDED'),
  (paginatedResult: PaginatedResult): PaginatedResult => paginatedResult,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListFilesErrorActionCreator = createAction(
  getActionType('files', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetFilesActionCreator = createAction(
  getActionType('files', 'GET_REQUESTED'),
  (id: File['id']) => id,
  (_, meta = defaultMeta) => meta,
);

/**
 * @param {File} file
 * @return {FetchGetSucceeded}
 */
export const fetchGetFilesSuccessActionCreator = createAction(
  getActionType('files', 'GET_SUCCEEDED'),
  (file: File) => file,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetFilesErrorActionCreator = createAction(
  getActionType('files', 'GET_FAILED'),
  (message: string) => message,
);

/**
 * @param {File} file
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditFilesExpireAtActionCreator = createAction(
  getActionType('files', 'EDIT_EXPIRE_AT_REQUESTED'),
  (file: File) => file,
  (file, meta = defaultMeta) => meta,
);

/**
 * @param {File} file
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditFilesNameActionCreator = createAction(
  getActionType('files', 'EDIT_NAME_REQUESTED'),
  (file: File) => file,
  (file, meta = defaultMeta) => meta,
);

/**
 * @param {File} file
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditFilesNoteActionCreator = createAction(
  getActionType('files', 'EDIT_NOTE_REQUESTED'),
  (file: File) => file,
  (file, meta = defaultMeta) => meta,
);

/**
 * @param {File} file
 * @return {FetchEditSucceeded}
 */
export const fetchEditFilesSuccessActionCreator = createAction(
  getActionType('files', 'EDIT_SUCCEEDED'),
  (file: File) => file,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditFilesErrorActionCreator = createAction(
  getActionType('files', 'EDIT_FAILED'),
  (message: string) => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteFilesActionCreator = createAction(
  getActionType('files', 'DELETE_REQUESTED'),
  (id: File['id']) => id,
  (file, meta = defaultMeta) => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteFilesSuccessActionCreator = createAction(
  getActionType('files', 'DELETE_SUCCEEDED'),
  (id: File['id']) => id,
);

/**
 * @return {FetchFailed}
 */
export const fetchDeleteFilesErrorActionCreator = createAction(
  getActionType('files', 'DELETE_FAILED'),
  (message: string) => message,
);
