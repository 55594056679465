import { connect } from 'react-redux';
import Organization from '../../components/layout/organization/Organization';
import {
  organizationActions,
  organizationTypes,
} from '../../../states/ducks/organizations';
import {
  userActions,
  userSelectors,
  userTypes,
} from '../../../states/ducks/users';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../states/ducks/profiles';
import { logoutActionCreator } from '../../../states/ducks/common/actions';
import { State } from '../../../states/types';
import { organizationsIsListingSelector } from '../../../states/ducks/organizations/selectors/base';
import { withAuthenticate } from '../../../services/authenticate';
import {
  currentUserSelector,
  currentUserVisibleOrganizationTreesSelector,
} from '../../../states/ducks/currentUser/selectors';

interface MapStateToProps {
  organizations: organizationTypes.Organization[];
  loading: number;
  userId: userTypes.User['id'];
  user: userTypes.User | null;
  profile: profileTypes.Profile | null;
}

interface MapDispatchToProps {
  deleteOrganization: Function;
  getUser: Function;
  getProfile: Function;
  logout: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const userId = currentUserSelector(state).id;
  const user = userSelectors.userFromIdSelector(state, userId);
  const profile = profileSelectors.profileFromUserIdSelector(state, userId);

  return {
    organizations: currentUserVisibleOrganizationTreesSelector(state),
    loading: organizationsIsListingSelector(state),
    userId: userId,
    user: user,
    profile: profile,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  deleteOrganization: organizationActions.fetchDeleteOrganizationsActionCreator,
  getUser: userActions.fetchGetUsersActionCreator,
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
  logout: logoutActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAuthenticate(Organization));
