import React, {
  ReactElement,
  useEffect,
  FunctionComponent,
  useReducer,
  useState,
} from 'react';
import { Skeleton, PageHeader, Card, PopoverActions } from '../../base';
import { locationTypes } from '../../../../states/ducks/locations';
import PropTypes from 'prop-types';
import { organizationTypes } from '../../../../states/ducks/organizations';
import { locationTypeTypes } from '../../../../states/ducks/locationtypes';
import LocationInfoStructure from './LocationInfoStructure';
import LocationInfoGeneral from './LocationInfoGeneral';
import './LocationInfo.scss';
import { Redirect } from 'react-router';
import LocationUsers from './LocationUsers';
import {
  LocationType,
  PropTypesLocationType,
} from '../../../../states/ducks/locationtypes/types';
import { File } from '../../../../states/ducks/files/types';

export interface PropsLocationInfo {
  locationId: locationTypes.Location['id'];
  getLocation: Function;
  getOrganization: Function;
  location: locationTypes.Location | null;
  editLocationDesignation: Function;
  editLocationOrganization: Function;
  editLocationTypeId: Function;
  editLocationAddress: Function;
  editLocationRemark: Function;
  organizationLoading: boolean;
  locationLoading: boolean;
  organization: organizationTypes.Organization | null;
  locations: locationTypes.Location[];
  loadLocations: Function;
  locationTypes: locationTypeTypes.LocationType[];
  deleteLocation: Function;
  isGettingLocation: boolean;
  locationType: LocationType | null;
  files: File[];
  getFile: Function;
  addFileLocation: Function;
  addGEDFileLocation: Function;
  deleteFileLocation: Function;
}

const initialState = {
  modalAddLocationVisible: false,
  modalInfoLocationVisible: false,
  location: null as null | locationTypes.Location,
  locationId: null as null | locationTypes.Location['id'],
  link: false,
};

type locationReducerState = typeof initialState;

interface LocationReducerAction {
  type:
    | 'VISIBLE_MODAL_ADD_LOCATION'
    | 'VISIBLE_MODAL_INFO_LOCATION'
    | 'HIDDEN_MODAL'
    | 'GO_TO_LOCATION';
  payload?: {
    location?: null | locationTypes.Location;
    locationId?: null | locationTypes.Location['id'];
  };
}

const reducer = (
  state: locationReducerState,
  action: LocationReducerAction,
): locationReducerState => {
  const { location = null, locationId = null } = action.payload
    ? action.payload
    : {};
  switch (action.type) {
    case 'VISIBLE_MODAL_ADD_LOCATION':
      return {
        ...state,
        modalAddLocationVisible: true,
        location,
      };
    case 'VISIBLE_MODAL_INFO_LOCATION':
      return {
        ...state,
        modalInfoLocationVisible: true,
        locationId,
      };
    case 'HIDDEN_MODAL':
      return {
        ...state,
        modalAddLocationVisible: false,
        modalInfoLocationVisible: false,
      };
    case 'GO_TO_LOCATION':
      return {
        ...state,
        locationId,
        link: true,
      };
    default:
      throw new Error();
  }
};

/**
 * @return {object}
 */
function useLocationReducer(): {
  showAddLocationModal: Function;
  showInfoLocationModal: Function;
  hideLocationModal: Function;
  redirectToLocation: Function;
  state: locationReducerState;
} {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showAddLocationModal = (location: locationTypes.Location): void => {
    dispatch({
      type: 'VISIBLE_MODAL_ADD_LOCATION',
      payload: { location },
    });
  };

  const showInfoLocationModal = (
    locationId: locationTypes.Location['id'],
  ): void => {
    dispatch({
      type: 'VISIBLE_MODAL_INFO_LOCATION',
      payload: { locationId },
    });
  };

  const hideLocationModal = (): void => {
    dispatch({ type: 'HIDDEN_MODAL' });
  };

  const redirectToLocation = (
    locationId: locationTypes.Location['id'],
  ): void => {
    dispatch({ type: 'GO_TO_LOCATION', payload: { locationId } });
  };

  return {
    showAddLocationModal,
    showInfoLocationModal,
    hideLocationModal,
    state,
    redirectToLocation,
  };
}

const LocationInfo: FunctionComponent<PropsLocationInfo> = ({
  locationId,
  location,
  getLocation,
  editLocationDesignation,
  editLocationOrganization,
  editLocationTypeId,
  editLocationAddress,
  editLocationRemark,
  getOrganization,
  organizationLoading,
  locationLoading,
  organization,
  locations,
  loadLocations,
  locationTypes,
  deleteLocation,
  isGettingLocation,
  locationType,
  files,
  getFile,
  addFileLocation,
  addGEDFileLocation,
  deleteFileLocation,
}): ReactElement => {
  const [activeTab, setActiveTab] = useState('general');
  const [hasGotLocation, setHasGotLocation] = useState(false);
  const locationTypeName = locationType
    ? locationType.name.toLowerCase()
    : 'lieu';

  useEffect((): void => {
    if (isGettingLocation) {
      setHasGotLocation(true);
    }
  }, [isGettingLocation]);

  useEffect((): void => {
    getLocation(locationId);
  }, [getLocation, locationId]);

  useEffect((): void => {
    if (location) {
      getOrganization(location.organizationId);
    }
  }, [getOrganization, location]);

  useEffect((): void => {
    loadLocations();
  }, [loadLocations]);

  if (!locationId || (hasGotLocation && !isGettingLocation && !location)) {
    return (
      <Redirect
        to={{
          pathname: '/locations/',
        }}
      />
    );
  }

  const tabList = [
    {
      key: 'general',
      tab: 'Général',
    },
    {
      key: 'structure',
      tab: 'Structure',
    },
    {
      key: 'occupier',
      tab: 'Occupants',
    },
  ];

  const contentList = {
    general: (
      <LocationInfoGeneral
        location={location}
        locationLoading={locationLoading}
        organization={organization}
        organizationLoading={organizationLoading}
        useLocationReducer={useLocationReducer}
        editLocationDesignation={editLocationDesignation}
        editLocationOrganization={editLocationOrganization}
        editLocationTypeId={editLocationTypeId}
        editLocationAddress={editLocationAddress}
        editLocationRemark={editLocationRemark}
        files={files}
        getFile={getFile}
        addFileLocation={addFileLocation}
        addGEDFileLocation={addGEDFileLocation}
        deleteFileLocation={deleteFileLocation}
      />
    ),
    structure: (
      <LocationInfoStructure
        useLocationReducer={useLocationReducer}
        locations={locations}
        location={location}
        locationTypes={locationTypes}
        deleteLocation={deleteLocation}
      />
    ),
    occupier: location ? <LocationUsers location={location} /> : <></>,
  };

  return (
    <>
      <Skeleton
        loading={locationLoading && location === null}
        paragraph={{
          rows: 0,
        }}
      >
        <PageHeader
          title={
            location
              ? `${
                  locationTypeName.charAt(0).toUpperCase() +
                  locationTypeName.slice(1)
                } : ${location.name}`
              : ''
          }
          extra={[
            <PopoverActions
              key="location-info-action-1"
              objectId={locationId}
              onDelete={deleteLocation}
              deleteConfirmMessage={
                `Supprimer le ${locationTypeName} ? ` +
                '(Attention les lieux rattachés ' +
                'à la structure seront supprimés)'
              }
              deleteDoneMessage={`Le ${locationTypeName} a bien été supprimé`}
            />,
          ]}
        />
      </Skeleton>
      <Card
        className="section"
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={(key): void => setActiveTab(key)}
      >
        {
          // @ts-ignore
          contentList[activeTab]
        }
      </Card>
    </>
  );
};

LocationInfo.propTypes = {
  locationId: PropTypes.string.isRequired,
  getLocation: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  location: locationTypes.PropTypesLocation,
  editLocationDesignation: PropTypes.func.isRequired,
  editLocationOrganization: PropTypes.func.isRequired,
  editLocationTypeId: PropTypes.func.isRequired,
  editLocationAddress: PropTypes.func.isRequired,
  editLocationRemark: PropTypes.func.isRequired,
  organizationLoading: PropTypes.bool.isRequired,
  locationLoading: PropTypes.bool.isRequired,
  organization: organizationTypes.PropTypesOrganization,
  locations: PropTypes.arrayOf(locationTypes.PropTypesLocation.isRequired)
    .isRequired,
  loadLocations: PropTypes.func.isRequired,
  locationTypes: PropTypes.arrayOf(
    locationTypeTypes.PropTypesLocationType.isRequired,
  ).isRequired,
  locationType: PropTypesLocationType,
  deleteLocation: PropTypes.func.isRequired,
  isGettingLocation: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  getFile: PropTypes.func.isRequired,
  addFileLocation: PropTypes.func.isRequired,
  addGEDFileLocation: PropTypes.func.isRequired,
  deleteFileLocation: PropTypes.func.isRequired,
};

export default LocationInfo;
