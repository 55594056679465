import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import GetRole from '../../../components/layout/modal/role/GetRole';
import {
  roleActions,
  roleSelectors,
  roleTypes,
} from '../../../../states/ducks/roles';

interface MapStateToProps {
  role: roleTypes.Role | null;
  isFetching: boolean;
}

interface MapDispatchToProps {
  editRoleName: Function;
  editRoleDescription: Function;
  editRoleStyle: Function;
}

const mapStateToProps = (
  state: State,
  props: { roleId: roleTypes.Role['id'] },
): MapStateToProps => {
  return {
    role: roleSelectors.roleFromIdSelector(state, props.roleId),
    isFetching: roleSelectors.rolesIsGettingSelector(state),
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  editRoleName: roleActions.fetchEditRolesNameActionCreator,
  editRoleDescription: roleActions.fetchEditRolesDescriptionActionCreator,
  editRoleStyle: roleActions.fetchEditRolesStyleActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(GetRole);
