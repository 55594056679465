import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import PropTypes from 'prop-types';
import {
  contractorActions,
  contractorSelectors,
  contractorTypes,
} from '../../../../states/ducks/contractors';

interface IncidentContractorNameProps {
  contractorId: contractorTypes.Contractor['id'];
  contractor: contractorTypes.Contractor | null;
  getContractor: Function;
}

/**
 * @param {string} contractorId
 * @param {Contractor} contractor
 * @param {Function} getContractor
 *
 * @return {ReactElement}
 */
const IncidentContractorName: FunctionComponent<IncidentContractorNameProps> =
  ({ contractorId, contractor, getContractor }): ReactElement => {
    useEffect((): void => {
      if (getContractor && !contractor) {
        getContractor(contractorId);
      }
    }, [getContractor, contractor, contractorId]);

    return <>{contractor ? contractor.name : ''}</>;
  };

IncidentContractorName.propTypes = {
  contractorId: PropTypes.string.isRequired,
  contractor: contractorTypes.PropTypesContractor.isRequired,
  getContractor: PropTypes.func.isRequired,
};

interface MapStateToProps {
  contractor: contractorTypes.Contractor | null;
}

interface MapDispatchToProps {
  getContractor: Function;
}

const mapStateToProps = (
  state: State,
  props: { contractorId: contractorTypes.Contractor['id'] },
): MapStateToProps => ({
  contractor: contractorSelectors.contractorFromIdSelector(
    state,
    props.contractorId,
  ),
});

const mapDispatchToProps: MapDispatchToProps = {
  getContractor: contractorActions.fetchGetContractorsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentContractorName);
