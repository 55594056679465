/**
 * @return {object}
 */
function passwordsComparison(): {
  compareToFirstPassword: Function;
  validateToNextPassword: Function;
} {
  const compareToFirstPassword = (
    rule: object,
    value: string,
    callback: Function,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: any,
  ): void => {
    if (!value) {
      return callback('Veuillez confirmer le mot de passe');
    }
    if (value !== form.getFieldValue('password')) {
      return callback('Les mots de passe ne correspondent pas');
    }
    callback();
  };

  const validateToNextPassword = (
    rule: object,
    value: string,
    callback: Function,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: any,
  ): void => {
    const confirmPassword = form.getFieldValue('passwordConfirm');
    if (!value) {
      return callback('Veuillez renseigner un mot de passe');
    }
    if (value && confirmPassword !== undefined) {
      form.validateFields(['passwordConfirm']);
      callback();
    }
    callback();
  };

  return {
    compareToFirstPassword,
    validateToNextPassword,
  };
}

export default passwordsComparison;
