import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  kpiActions,
  kpiSelectors,
  kpiTypes,
} from '../../../../states/ducks/kpis';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import { Pie } from '@ant-design/charts';
import { useHistory } from 'react-router-dom';

interface RequestsByManagerChartProps {
  requestsByManager: kpiTypes.Kpi | null;
  countRequestsByManager: Function;
  profilesFromResults: Profile[] | null;
  loadProfiles: Function;
}

/**
 * RequestsByManagerChart component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const RequestsByManagerChart: FunctionComponent<RequestsByManagerChartProps> =
  ({
    requestsByManager,
    countRequestsByManager,
    profilesFromResults,
    loadProfiles,
    ...props
  }): ReactElement => {
    const history = useHistory();

    useEffect((): void => {
      countRequestsByManager();
    }, [countRequestsByManager]);

    useEffect((): void => {
      loadProfiles();
    }, [loadProfiles]);

    const data =
      requestsByManager && requestsByManager.value
        ? requestsByManager.value.map((request) => {
            const managerProfile = profilesFromResults
              ? profilesFromResults.find(
                  (profile) => profile.userId === request.managerId,
                )
              : null;

            return {
              managerId: request.managerId,
              managerName: managerProfile
                ? `${managerProfile.firstName + ' ' + managerProfile.lastName}`
                : 'Non affectée',
              value: request.value,
            };
          })
        : [];

    const config = {
      appendPadding: 10,
      data: data,
      angleField: 'value',
      colorField: 'managerName',
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v): string => {
            return v;
          },
        },
      },
      interactions: [{ type: 'pie-statistic-active' }],
      label: {
        type: 'inner',
        offset: '-50%',
        style: { textAlign: 'center' },
        autoRotate: false,
        content: '{value}',
      },
      legend: {
        pageNavigator: {
          marker: {
            style: {
              size: 8,
            },
          },
        },
      },
    };

    return (
      <Pie
        {...config}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onReady={(plot: any): void => {
          plot.on('element:click', (evt) => {
            history.push({
              pathname: '/requests',
              state: { managerId: evt.data.data.managerId },
            });
          });
        }}
      />
    );
  };

RequestsByManagerChart.propTypes = {
  requestsByManager: kpiTypes.PropTypesKpi,
  countRequestsByManager: PropTypes.func.isRequired,
  profilesFromResults: PropTypes.arrayOf(PropTypesProfile.isRequired),
  loadProfiles: PropTypes.func.isRequired,
};

interface MapStateToProps {
  requestsByManager: kpiTypes.Kpi | null;
  profilesFromResults: profileTypes.Profile[] | null;
}

interface MapDispatchToProps {
  countRequestsByManager: Function;
  loadProfiles: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const requestsByManager = kpiSelectors.requestsByManagerSelector(state);

  const profilesId =
    requestsByManager && requestsByManager.value
      ? requestsByManager.value.map(
          (request): Profile['id'] => request.managerId,
        )
      : null;

  const profilesFromResults = profilesId
    ? profileSelectors.profileFromMultipleUserIdsSelector(state, profilesId)
    : null;

  return {
    requestsByManager: requestsByManager,
    profilesFromResults: profilesFromResults,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  countRequestsByManager: kpiActions.fetchCountRequestsByManagerActionCreator,
  loadProfiles: profileActions.fetchListProfilesActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestsByManagerChart);
