import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardMeta, Avatar, RoleTag } from '..';
import classNames from 'classnames';
import './UserCard.scss';
import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import {
  organizationTypes,
  organizationSelectors,
} from '../../../../states/ducks/organizations';
import {
  TeamOutlined,
  PhoneOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  userActions,
  userSelectors,
  userTypes,
} from '../../../../states/ducks/users';

interface Props {
  className?: string;
  profileId?: profileTypes.Profile['id'];
  profile?: profileTypes.Profile | null;
  getProfile?: Function;
  user?: userTypes.User | null;
  getUser?: Function;
  organization: organizationTypes.Organization | null;
}

/**
 * UserCard component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const UserCard: FunctionComponent<Props> = ({
  className,
  profileId,
  profile,
  getProfile,
  user,
  getUser,
  organization,
}): ReactElement => {
  const classes = classNames('app-user-card-popover', className);

  useEffect((): void => {
    if (getProfile && profileId && !profile) {
      getProfile(profileId);
    }
  }, [getProfile, profile, profileId]);

  useEffect((): void => {
    if (getUser && profile && !user) {
      getUser(profile.userId);
    }
  }, [getUser, profile, user]);

  const description = (): ReactElement => {
    const email =
      profile && organization ? (
        <div>
          <TeamOutlined />
          {organization.name}
        </div>
      ) : (
        ''
      );
    const phoneNumber = profile ? (
      <div>
        <PhoneOutlined />
        {profile.phoneNumber}
      </div>
    ) : (
      ''
    );
    return (
      <>
        {email}
        {phoneNumber}
      </>
    );
  };

  return (
    <Card className={classes}>
      {profile ? (
        <CardMeta
          avatar={<Avatar profileId={profile.id} size={75} />}
          title={
            profile ? (
              <>
                {profile.firstName} {profile.lastName}
                {user && user.roleId ? <RoleTag roleId={user.roleId} /> : ''}
              </>
            ) : (
              ''
            )
          }
          description={description()}
        />
      ) : (
        <CardMeta
          avatar={
            <Avatar
              className="avatar-error"
              icon={<WarningOutlined />}
              size="large"
            />
          }
          description={
            <>
              Erreur lors de la récupération <br />
              du profil de l’utilisateur
            </>
          }
        />
      )}
    </Card>
  );
};

UserCard.propTypes = {
  className: PropTypes.string,
  profileId: PropTypes.string.isRequired,
  profile: profileTypes.PropTypesProfile,
  getProfile: PropTypes.func,
  user: userTypes.PropTypesUser,
  getUser: PropTypes.func,
  organization: organizationTypes.PropTypesOrganization,
};

interface MapStateToProps {
  profile: profileTypes.Profile | null;
  user: userTypes.User | null;
  organization: organizationTypes.Organization | null;
}

interface MapDispatchToProps {
  getProfile: Function;
  getUser: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    profileId: profileTypes.Profile['id'];
  },
): MapStateToProps => {
  const profile = profileSelectors.profileFromIdSelector(
    state,
    props.profileId,
  );

  const user = profile
    ? userSelectors.userFromIdSelector(state, profile.userId)
    : null;

  const organization =
    profile !== null && profile.organizationIds.length > 0
      ? organizationSelectors.organizationFromIdSelector(
          state,
          profile.organizationIds[0],
        )
      : null;

  return {
    profile,
    user,
    organization,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesActionCreator,
  getUser: userActions.fetchGetUsersActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
