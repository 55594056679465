import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import PropTypes from 'prop-types';
import {
  organizationActions,
  organizationSelectors,
  organizationTypes,
} from '../../../../states/ducks/organizations';

interface RequestOrganizationNameProps {
  organization: organizationTypes.Organization | null;
  getOrganization: Function;
}

/**
 * @param {Organization} organization
 * @param {Function} getOrganization
 *
 * @return {ReactElement}
 */
const RequestOrganizationName: FunctionComponent<RequestOrganizationNameProps> =
  ({ organization, getOrganization }): ReactElement => {
    useEffect((): void => {
      if (getOrganization && !organization) {
        getOrganization();
      }
    }, [getOrganization, organization]);

    return organization ? <>{organization.name}</> : <></>;
  };

RequestOrganizationName.propTypes = {
  organization: organizationTypes.PropTypesOrganization.isRequired,
  getOrganization: PropTypes.func.isRequired,
};

interface MapStateToProps {
  organization: organizationTypes.Organization | null;
}

interface MapDispatchToProps {
  getOrganization: Function;
}

const mapStateToProps = (
  state: State,
  props: { organizationId: organizationTypes.Organization['id'] },
): MapStateToProps => ({
  organization: organizationSelectors.organizationFromIdSelector(
    state,
    props.organizationId,
  ),
});

const mapDispatchToProps: MapDispatchToProps = {
  getOrganization: organizationActions.fetchGetOrganizationsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestOrganizationName);
