import React, { FunctionComponent, ReactElement } from 'react';
import { Typography } from 'antd';
import 'antd/lib/typography/style';
import './Text.scss';

import classNames from 'classnames';

import PropTypes from 'prop-types';

interface Props {
  light?: boolean;
  regular?: boolean;
  medium?: boolean;
  bold?: boolean;
  italic?: boolean;
  highlight?: boolean;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  type?: 'secondary' | 'warning' | 'danger';
}

/**
 * Text component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Text: FunctionComponent<Props> = ({
  light = false,
  regular = false,
  medium = false,
  bold = false,
  italic = false,
  highlight = false,
  ...props
}): ReactElement => {
  const initialClasses = props.className ? props.className : '';
  const classes = classNames(
    {
      light,
      regular,
      medium,
      bold,
      italic,
      highlight,
    },
    'app-text',
    initialClasses,
  );

  return <Typography.Text className={classes} type={props.type} {...props} />;
};

Text.propTypes = {
  className: PropTypes.string,
  light: PropTypes.bool,
  regular: PropTypes.bool,
  medium: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  highlight: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['secondary', 'warning', 'danger']),
};

export default Text;
