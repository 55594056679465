import { Upload as UploadAnt } from 'antd';
import { UploadProps as UploadAntProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import 'antd/lib/upload/style';
import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { Meta } from '../../../../states/ducks/common/actions';
import { Button, notification } from '../index';
import './Upload.scss';
import { UploadOutlined } from '@ant-design/icons';

export interface UploadProps extends UploadAntProps {
  addButton?: ReactNode;
  afterUpload?: Function;
  errorMessage?: string;
  excludedMimeTypePatternMessage?: string;
  excludeMimeTypePattern?: string;
  onUpload?: (file: UploadFile, meta: Meta) => void;
  successMessage?: string;
}

/**
 * @param {ReactNode} addButton
 * @param {Function} afterUpload
 * @param {string} errorMessage
 * @param {string} excludedMimeTypePatternMessage
 * @param {string} excludeMimeTypePattern
 * @param {Function} onUpload
 * @param {string} successMessage
 * @param {UploadAntProps} props
 * @return {ReactElement}
 */
const Upload: FunctionComponent<UploadProps> = ({
  addButton,
  afterUpload,
  errorMessage,
  excludedMimeTypePatternMessage,
  excludeMimeTypePattern,
  onUpload,
  successMessage,
  ...props
}): ReactElement => {
  const onChange = ({
    fileList,
    file,
  }: {
    fileList: UploadFile[];
    file: UploadFile;
  }): void => {
    if (file.status === 'removed' || !onUpload) {
      return;
    }

    if (file.status === 'uploading') {
      if (excludeMimeTypePattern) {
        if (file.type.includes(excludeMimeTypePattern)) {
          notification.error({
            message: excludedMimeTypePatternMessage,
          });

          return;
        }
      }

      const promise = new Promise<void>((resolve, reject): void =>
        onUpload(file, { resolve, reject }),
      );

      promise
        .then((): void => {
          notification.success({
            message: successMessage,
          });
        })
        .catch((): void => {
          notification.error({
            message: errorMessage,
          });
        })
        .finally((): void => {
          if (afterUpload) {
            afterUpload();
          }
        });
    }
  };

  return (
    <UploadAnt
      customRequest={({ onSuccess }: RcCustomRequestOptions): void => {
        setTimeout(() => {
          if (onSuccess) {
            onSuccess({}, new XMLHttpRequest());
          }
        }, 0);
      }}
      onChange={onChange}
      showUploadList={false}
      {...props}
    >
      {props.disabled ? <></> : addButton}
    </UploadAnt>
  );
};

Upload.defaultProps = {
  addButton: (
    <Button type="primary" icon={<UploadOutlined />}>
      Uploader
    </Button>
  ),
  errorMessage: "Le fichier n'a pu être ajouté",
  excludedMimeTypePatternMessage: "Le fichier n'est pas du bon type",
  successMessage: 'Le fichier a été ajouté',
};

Upload.propTypes = {
  addButton: PropTypes.node,
  afterUpload: PropTypes.func,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  excludedMimeTypePatternMessage: PropTypes.string,
  excludeMimeTypePattern: PropTypes.string,
  onUpload: PropTypes.func,
  successMessage: PropTypes.string.isRequired,
};

Upload.displayName = 'Upload';

export default Upload;
