import { get } from '../base';
import { interventionTypes } from '../../../states/ducks/interventions';
import { incidentTypes } from '../../../states/ducks/incidents';

/**
 * @param {string} id
 * @return {Promise<Intervention>}
 */
export const listInterventionsByIncidentId = async (
  id: incidentTypes.Incident['id'],
): Promise<interventionTypes.Intervention[]> =>
  await get(`/v1/interventions/getByIncidentId/${id}`);
