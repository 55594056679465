import React, { FunctionComponent, ReactElement } from 'react';
import { Radio } from 'antd';
import 'antd/lib/radio/style';
import { RadioGroupProps } from 'antd/lib/radio';

/**
 * RadioGroup component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  ...props
}): ReactElement => {
  return <Radio.Group {...props} />;
};

export default RadioGroup;
