import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  addTag,
  listTags,
  getTag,
  editTagLink,
  editTagUnlink,
  deleteTag,
} from '../../../services/api';
import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchGetRequested,
} from '../common/actions';
import { getActionType } from '../common/types';
import * as actions from './actions';
import { Tag } from './types';

/**
 * @param {FetchAddRequested} action
 */
export function* addTagSaga(action: FetchAddRequested<Tag>): SagaIterator {
  try {
    const id: Tag['id'] = yield call(addTag, action.payload);

    const tag = {
      ...action.payload,
      id,
    } as Tag;

    yield put(actions.fetchAddTagsSuccessActionCreator(tag));

    action.meta.resolve(tag);
  } catch (err) {
    yield put(actions.fetchAddTagsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* fetchTagSaga(): SagaIterator {
  try {
    const tags: Tag[] = yield call(listTags);

    yield put(actions.fetchListTagsSuccessActionCreator(tags));
  } catch (err) {
    yield put(actions.fetchListTagsErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getTagSaga(action: FetchGetRequested<Tag>): SagaIterator {
  try {
    const tag: Tag = yield call(getTag, action.payload);

    yield put(actions.fetchGetTagsSuccessActionCreator(tag));

    action.meta.resolve(tag);
  } catch (err) {
    yield put(actions.fetchGetTagsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditLinkRequested} action
 */
export function* editTagLinkSaga(
  action: actions.FetchEditLinkRequested,
): SagaIterator {
  try {
    const tags = action.payload.tags;
    const objectsLinked = action.payload.objectsLinked;

    yield all(tags.map((tag) => call(editTagLink, tag, objectsLinked)));
    yield all(
      tags.map((tag) => {
        if (!tag.objectsLinked) tag.objectsLinked = {};

        const objectKey = Object.keys(objectsLinked)[0];
        const objectValue = Object.values(objectsLinked)[0];

        if (tag.objectsLinked && !tag.objectsLinked[objectKey]) {
          tag.objectsLinked[objectKey] = [];
        }

        tag.objectsLinked[objectKey] =
          tag.objectsLinked[objectKey].concat(objectValue);

        return put(actions.fetchEditTagsSuccessActionCreator(tag));
      }),
    );

    action.meta.resolve(action.payload.tags);
  } catch (err) {
    yield put(actions.fetchEditTagsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditUnlinkRequested} action
 */
export function* editTagUnlinkSaga(
  action: actions.FetchEditUnlinkRequested,
): SagaIterator {
  try {
    const tags = action.payload.tags;
    const objectsLinked = action.payload.objectsLinked;

    yield all(tags.map((tag) => call(editTagUnlink, tag, objectsLinked)));
    yield all(
      tags.map((tag) => {
        const objectKey = Object.keys(objectsLinked)[0];
        const objectValue = Object.values(objectsLinked)[0];

        if (tag?.objectsLinked?.[objectKey]) {
          tag.objectsLinked[objectKey] = tag?.objectsLinked?.[objectKey].filter(
            (id) => id === objectValue,
          );
        }

        return put(actions.fetchEditTagsSuccessActionCreator(tag));
      }),
    );

    action.meta.resolve(action.payload.tags);
  } catch (err) {
    yield put(actions.fetchEditTagsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteTagSaga(
  action: FetchDeleteRequested<Tag>,
): SagaIterator {
  try {
    yield call(deleteTag, action.payload);

    yield put(actions.fetchDeleteTagsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteTagsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* tagsSagas(): SagaIterator {
  yield takeEvery(getActionType('tags', 'ADD_REQUESTED'), addTagSaga);
  yield takeEvery(getActionType('tags', 'LIST_REQUESTED'), fetchTagSaga);
  yield takeEvery(getActionType('tags', 'GET_REQUESTED'), getTagSaga);
  yield takeEvery(
    getActionType('tags', 'EDIT_LINK_REQUESTED'),
    editTagLinkSaga,
  );
  yield takeEvery(
    getActionType('tags', 'EDIT_UNLINK_REQUESTED'),
    editTagUnlinkSaga,
  );
  yield takeEvery(getActionType('tags', 'DELETE_REQUESTED'), deleteTagSaga);
}
