import { get } from '../base';
import { historyTypes } from '../../../states/ducks/histories';

/**
 * @param {object} payload
 * @return {Promise<History[]>}
 */
export const listHistories = async (payload: {
  objectType: string;
  objectId: string;
}): Promise<historyTypes.History[]> =>
  await get(`/v1/histories/${payload.objectType}/${payload.objectId}`);
