import React, { FunctionComponent, ReactElement } from 'react';
import { Redirect } from 'react-router';
import { Route, RouteProps } from 'react-router-dom';
import { isConnected } from '../../../../services/authenticate';

const UnauthenticatedRoute: FunctionComponent<RouteProps> = (
  props,
): ReactElement => {
  if (isConnected()) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  return <Route {...props} />;
};

UnauthenticatedRoute.propTypes = {};

export default UnauthenticatedRoute;
