import { create, get, patch, remove } from '../base';
import { locationTypes } from '../../../states/ducks/locations';

/**
 * @return {Promise<Location[]>}
 */
export const listLocations = async (): Promise<locationTypes.Location[]> =>
  await get(`/v1/locations/list?limit=unlimited`);

/**
 * @param {string} id
 * @return {Promise<Location>}
 */
export const getLocation = async (
  id: locationTypes.Location['id'],
): Promise<locationTypes.Location> => await get(`/v1/locations/get/${id}`);

/**
 * @param {Location} location
 * @return {Promise<string>}
 */
export const addLocation = async (
  location: Partial<locationTypes.Location>,
): Promise<locationTypes.Location['id']> =>
  await create(`/v1/locations/create`, location);

/**
 * @param {Location} location
 * @return {Promise<string>}
 */
export const editLocationDesignation = async (
  location: locationTypes.Location,
): Promise<string> => {
  const { id, name, code } = location;

  return await patch(`/v1/locations/changeDesignation/${id}`, { name, code });
};

/**
 * @param {Location} location
 * @return {Promise<string>}
 */
export const editLocationOrganization = async (
  location: locationTypes.Location,
): Promise<string> => {
  const { id, organizationId } = location;

  return await patch(`/v1/locations/changeOrganization/${id}`, {
    organizationId,
  });
};

/**
 * @param {Location} location
 * @return {Promise<string>}
 */
export const editLocationTypeId = async (
  location: locationTypes.Location,
): Promise<string> => {
  const { id, locationTypeId } = location;

  return await patch(`/v1/locations/retype/${id}`, {
    locationTypeId,
  });
};

/**
 * @param {Location} location
 * @return {Promise<string>}
 */
export const editLocationAddress = async (
  location: locationTypes.Location,
): Promise<string | void> => {
  const { id, addressComponents } = location;

  if (!addressComponents) {
    return;
  }

  return await patch(`/v1/locations/relocate/${id}`, addressComponents);
};

/**
 * @param {Location} location
 * @return {Promise<string>}
 */
export const editLocationRemark = async (
  location: locationTypes.Location,
): Promise<string> => {
  const { id, remark } = location;

  return await patch(`/v1/locations/updateRemark/${id}`, {
    remark,
  });
};

/**
 * @param {Location} location
 * @param {string[]} fileIds
 * @return {Promise<string>}
 */
export const addFilesLocation = async (
  location: locationTypes.Location,
  fileIds: string[],
): Promise<string> => {
  return await patch(`/v1/locations/addFiles/${location.id}`, {
    fileIds,
  });
};

/**
 * @param {Location} location
 * @param {string} fileId
 * @return {Promise<string>}
 */
export const removeFileLocation = async (
  location: locationTypes.Location,
  fileId: string,
): Promise<string> => {
  const { id } = location;

  return await patch(`/v1/locations/removeFile/${id}`, {
    fileId,
  });
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteLocation = async (
  id: locationTypes.Location['id'],
): Promise<string> => await remove(`/v1/locations/delete/${id}`);
