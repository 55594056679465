import React, { ReactElement, forwardRef } from 'react';
import { Input } from 'antd';
import 'antd/lib/input/style';
import { SearchProps } from 'antd/lib/input';
import './Input.scss';
import classnames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Component = (props: SearchProps, ref: any): ReactElement => {
  const classes = classnames('app-input-search', props.className);
  return (
    <Input
      {...props}
      ref={ref}
      className={classes}
      suffix={<SearchOutlined />}
    />
  );
};

Component.displayName = 'InputSearch';

/**
 * InputSearch component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const InputSearch = forwardRef<HTMLInputElement, SearchProps>(Component);

export default InputSearch;
