import {
  CameraOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { AvatarProps } from 'antd/lib/avatar';
import PropTypes from 'prop-types';
import './EditableAvatar.scss';
import { Avatar, Dropdown, Text, Upload, notification } from '../index';
import { Menu, Spin } from 'antd';
import { Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import { Meta } from '../../../../states/ducks/common/actions';

interface Props extends AvatarProps {
  profileId: profileTypes.Profile['id'] | null;
  profile?: profileTypes.Profile | null;
  getProfile?: Function;
  addProfileAvatar: Function;
  deleteProfileAvatar: Function;
}

/**
 * @param {string} profileId
 * @param {Profile} profile
 * @param {Function} getProfile
 * @param {Function} addProfileAvatar
 * @param {Function} deleteProfileAvatar
 * @param {Props} props
 *
 * @return {ReactElement}
 */
const EditableAvatar: FunctionComponent<Props> = ({
  profileId,
  profile,
  getProfile,
  addProfileAvatar,
  deleteProfileAvatar,
  ...props
}): ReactElement => {
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const { confirm } = Modal;
  const classes = classNames('app-editable-avatar', props.className);

  useEffect((): void => {
    if (getProfile && profileId && !profile) {
      getProfile(profileId);
    }
  }, [getProfile, profile, profileId]);

  const handleClickDelete = (): void => {
    confirm({
      className: 'app-modal-delete',
      title: 'Supprimer la photo de profil ?',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      icon: <WarningOutlined />,
      onOk(): void {
        const promise = new Promise<void>((resolve, reject): void => {
          deleteProfileAvatar(
            {
              profile: profile ? profile : null,
              fileId: profile ? profile.avatarId : null,
            },
            { resolve, reject },
          );
        });

        promise
          .then((): void => {
            notification.success({
              message: 'La photo a été supprimée',
            });
          })
          .catch((): void => {
            notification.error({
              message: "La photo n'a pu être supprimée",
            });
          });
      },
    });
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="add">
        <Upload
          accept="image/*"
          addButton={
            profile && typeof profile.avatarId === 'undefined'
              ? 'Ajouter une photo'
              : 'Modifier la photo'
          }
          afterUpload={(): void => {
            setLoading(false);
          }}
          beforeUpload={(): boolean => {
            setLoading(true);
            return true;
          }}
          errorMessage="La photo n'a pu être ajoutée"
          onUpload={(file: UploadFile, meta: Meta): void =>
            addProfileAvatar(
              {
                profile: profile ? profile : null,
                file: file,
              },
              meta,
            )
          }
          successMessage="La photo a été ajoutée"
        />
      </Menu.Item>
      {profile && typeof profile.avatarId !== 'undefined' ? (
        <Menu.Item key="delete">
          <Text type="danger" onClick={handleClickDelete}>
            Supprimer la photo
          </Text>
        </Menu.Item>
      ) : (
        ''
      )}
    </Menu>
  );

  return (
    <div
      className="app-editable-avatar-container"
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
    >
      <Dropdown
        overlayClassName="app-popover-avatar"
        placement="bottomCenter"
        overlay={dropdownMenu}
      >
        <div className="avatar-item">
          <div className="avatar-hover" style={{ opacity: hover ? 1 : 0 }}>
            <CameraOutlined />
          </div>
          <div className="avatar-loading" style={{ opacity: loading ? 1 : 0 }}>
            <Spin />
          </div>
          <Avatar
            className={classes}
            icon={<UserOutlined />}
            profileId={profile?.id}
            {...props}
          />
        </div>
      </Dropdown>
    </div>
  );
};

EditableAvatar.propTypes = {
  profileId: PropTypes.string.isRequired,
  profile: profileTypes.PropTypesProfile,
  getProfile: PropTypes.func,
  addProfileAvatar: PropTypes.func.isRequired,
  deleteProfileAvatar: PropTypes.func.isRequired,
  className: PropTypes.string,
};

interface MapStateToProps {
  profile: profileTypes.Profile | null;
}

interface MapDispatchToProps {
  getProfile: Function;
  addProfileAvatar: Function;
  deleteProfileAvatar: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    profileId: profileTypes.Profile['id'];
  },
): MapStateToProps => ({
  profile: profileSelectors.profileFromIdSelector(state, props.profileId),
});

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesActionCreator,
  addProfileAvatar: profileActions.fetchAddProfilesAvatarActionCreator,
  deleteProfileAvatar: profileActions.fetchDeleteProfilesAvatarActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableAvatar);
