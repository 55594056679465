import { ColumnProps } from 'antd/lib/table';
import PropTypes from 'prop-types';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Pagination } from '../../../../services/search/search';
import {
  Profile,
  PropTypesProfile,
} from '../../../../states/ducks/profiles/types';
import {
  PropTypesRequest,
  Request,
} from '../../../../states/ducks/requests/types';
import {
  Avatar,
  DateTooltip,
  FilterableTable,
  InputSearch,
  OrganizationsTreeSelect,
  PageHeader,
  Popover,
  PopoverActions,
  ProfilesSelect,
  RangePicker,
  RequestStatusSelect,
  RequestStatusTag,
  UserCard,
} from '../../base';
import {
  FilterableSearch,
  initialSearch,
} from '../../base/table/FilterableTable';
import moment, { Moment } from 'moment';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

type StateType = {
  managerId: string;
};

interface Props extends RouteComponentProps {
  deleteRequest: Function;
  descendantsIdByOrganizationId: Record<string, string[]>;
  loadProfiles: Function;
  loadRequests: Function;
  profilesFromResults: Profile[];
  requests: Request[];
  total: Pagination['total'];
  location: {
    pathname: string;
    search: string;
    state: StateType;
    hash: string;
  };
}

interface TableRequest {
  action: object;
  creatorId: Request['creatorId'];
  id: Request['id'];
  key: Request['id'];
  managerProfileId: Profile['id'];
  managerId: Request['managerId'];
  number: Request['number'];
  object: Request['object'];
  openedAt: Request['openedAt'];
  status: Request['status'];
  userProfileId: Profile['id'];
}

const requestStatusTag = (text: string): ReactElement => (
  <RequestStatusTag status={text} />
);

const dateToolTip = (date: string): ReactElement => <DateTooltip date={date} />;

const Requests: FunctionComponent<Props> = ({
  deleteRequest,
  descendantsIdByOrganizationId,
  loadProfiles,
  loadRequests,
  profilesFromResults,
  requests,
  total,
  location,
  ...props
}): ReactElement => {
  const initialRequestsSearch: FilterableSearch = {
    ...initialSearch,
    sort: {
      field: 'reportedAt',
      order: 'desc',
    },
    filters:
      location.state && location.state.managerId
        ? [
            {
              field: 'managerId',
              key: 'manager',
              operator: 'IN',
              value: [location.state.managerId],
            },
          ]
        : [],
  };

  const [search, setSearch] = useState(initialRequestsSearch);

  useEffect((): void => {
    loadRequests(search);
  }, [loadRequests, search]);

  useEffect((): void => {
    loadProfiles();
  }, [loadProfiles]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: TableRequest): any => ({
    onClick: (): void => props.history.push(`/requests/${record.id}`),
  });

  const datas = requests.map((request: Request): TableRequest => {
    const creatorProfile = profilesFromResults.find(
      (profile) => profile.userId === request.creatorId,
    );

    const managerProfile = profilesFromResults.find(
      (profile) => profile.userId === request.managerId,
    );

    return {
      action: {
        id: request.id,
      },
      creatorId: request.creatorId,
      id: request.id,
      key: request.id,
      managerProfileId: managerProfile ? managerProfile.id : '',
      managerId: request.managerId,
      number: request.number,
      object: request.object,
      openedAt: request.openedAt,
      status: request.status,
      userProfileId: creatorProfile ? creatorProfile.id : '',
    };
  });

  const columns: ColumnProps<TableRequest>[] = [
    {
      key: 'number',
      title: 'N°',
      dataIndex: 'number',
      sorter: true,
      width: '5%',
    },
    {
      key: 'status',
      title: 'Statut',
      dataIndex: 'status',
      render: requestStatusTag,
      sorter: true,
      width: '8%',
    },
    {
      key: 'object',
      title: 'Objet',
      dataIndex: 'object',
      sorter: true,
      ellipsis: true,
    },
    {
      key: 'openedAt',
      title: 'Date de la demande',
      dataIndex: 'openedAt',
      render: dateToolTip,
      sorter: true,
      width: '15%',
    },
    {
      key: 'creatorId',
      title: 'Créateur',
      dataIndex: 'creatorId',
      // eslint-disable-next-line react/display-name
      render: (name: string, record: TableRequest): ReactElement => (
        <Popover content={<UserCard profileId={record.userProfileId} />}>
          <Avatar profileId={record.userProfileId} />
        </Popover>
      ),
      width: '10%',
    },
    {
      key: 'managerId',
      title: 'Responsable',
      dataIndex: 'managerId',
      // eslint-disable-next-line react/display-name
      render: (name: string, record: TableRequest): ReactElement =>
        record.managerProfileId ? (
          <Popover content={<UserCard profileId={record.managerProfileId} />}>
            <Avatar profileId={record.managerProfileId} />
          </Popover>
        ) : (
          <></>
        ),
      width: '10%',
    },
    {
      key: 'operation',
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableRequest): ReactElement => {
        return (
          <PopoverActions
            objectId={record.id}
            onDelete={deleteRequest}
            deleteConfirmMessage="Supprimer la demande ?"
            deleteDoneMessage="La demande a bien été supprimé"
            size="small"
          />
        );
      },
      width: '5%',
    },
  ];

  return (
    <>
      <PageHeader title="Demandes" />
      <FilterableTable<TableRequest>
        initialSearch={initialRequestsSearch}
        onDelete={deleteRequest}
        deleteConfirmationMessage="Supprimer les demandes sélectionnées ?"
        deleteSuccessMessage="Les demandes ont bien été supprimées"
        filters={[
          {
            display: (
              <InputSearch
                size="large"
                placeholder="Rechercher par numéro, sujet, créateur, ..."
              />
            ),
            field: 'object',
            key: 'search',
            operator: 'LIKE',
            onChange: (e: ChangeEvent<HTMLInputElement>): string[] => [
              e.currentTarget.value,
            ],
            size: 9,
          },
          {
            display: (
              <OrganizationsTreeSelect
                size="large"
                placeholder="Organisation"
              />
            ),
            field: 'organizationId',
            key: 'organization',
            operator: 'IN',
            onChange: (value: string): string[] => [
              value,
              ...(descendantsIdByOrganizationId[value] || []),
            ],
          },
          {
            display: (
              <RequestStatusSelect
                size="large"
                isFilter
                placeholder={'Statut'}
              />
            ),
            field: 'status',
            key: 'status',
            operator: 'IN',
          },
        ]}
        extra={[
          {
            display: (
              <RangePicker allowClear={false} placeholder={['Début', 'Fin']} />
            ),
            field: 'openedAt',
            key: 'openedAt',
            operator: 'BETWEEN',
            onChange: (values: Moment[]): string[] =>
              values.map((value) => value.format()),
            onUpdate: (values: string[]): Moment[] =>
              values.map((value) => moment(value)),
          },
          {
            display: <ProfilesSelect isFilter placeholder={'Créateur'} />,
            field: 'creatorId',
            key: 'creator',
            operator: 'IN',
          },
          {
            display: <ProfilesSelect isFilter placeholder={'Responsable'} />,
            field: 'managerId',
            key: 'manager',
            operator: 'IN',
          },
        ]}
        onSearchChange={setSearch}
        search={search}
        tableProps={{
          columns: columns,
          dataSource: datas,
          onRow: onRow,
          pagination: {
            total: total,
          },
        }}
      />
    </>
  );
};

Requests.propTypes = {
  deleteRequest: PropTypes.func.isRequired,
  descendantsIdByOrganizationId: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  loadProfiles: PropTypes.func.isRequired,
  loadRequests: PropTypes.func.isRequired,
  profilesFromResults: PropTypes.arrayOf(PropTypesProfile.isRequired)
    .isRequired,
  requests: PropTypes.arrayOf(PropTypesRequest.isRequired).isRequired,
  total: PropTypes.number.isRequired,
  history: PropTypes.exact(historyPropTypes).isRequired,
  location: PropTypes.any,
};

export default withRouter(Requests);
