import React, { ReactElement, FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { userActions } from '../../../../states/ducks/users';
import {
  Button,
  Input,
  FormItem,
  Title,
  notification,
  Homepage,
} from '../../base';
import {
  CheckOutlined,
  RedoOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { Form } from 'antd';
import { User } from '../../../../states/ducks/users/types';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

interface ResetAskingProps extends RouteComponentProps {
  onAskResetPassword: Function;
}

/**
 * @param {Function} onAskResetPassword
 * @param {RouteComponentProps} props
 *
 * @return {boolean}
 */
const ResetAsking: FunctionComponent<ResetAskingProps> = ({
  onAskResetPassword,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [resetSent, setResetSent] = useState(false);

  const handleFinish = (values: Partial<User>): void => {
    const promiseAskResetPassword = new Promise<boolean>(
      (resolve, reject): void =>
        onAskResetPassword(values.email, { resolve, reject }),
    );

    promiseAskResetPassword
      .then((): void => {
        notification.success({
          message: `Un mail de réinitialisation de 
                            mot de passe vous à été envoyé`,
        });
        setResetSent(true);
      })
      .catch((message: string): void => {
        notification.error({
          message: `Erreur`,
          description: message,
        });
        setResetSent(false);
        form.setFieldsValue({
          email: {
            value: values.email,
            errors: [new Error(`Adresse mail incorrecte`)],
          },
        });
      });
  };

  const emailResetInput = (
    <FormItem
      style={resetSent ? { display: 'none' } : {}}
      label="Adresse mail"
      name="email"
      rules={[
        {
          type: 'email',
          message: "L'e-mail n'est pas valide",
        },
        {
          required: true,
          message: 'Adresse mail requise',
        },
      ]}
    >
      <Input placeholder="Saisissez un e-mail" />
    </FormItem>
  );

  const resetForm = (
    <>
      <Title fancy>Réinitialisation</Title>
      <>
        Merci de saisir votre adresse mail pour passer à la réinitialisation de
        votre mot de passe.
      </>
      <Form form={form} onFinish={handleFinish}>
        {emailResetInput}
        {!resetSent ? (
          <Button
            className="app-btn-submit"
            type="primary"
            size="large"
            htmlType="submit"
          >
            Demander la réinitialisation
          </Button>
        ) : (
          <>
            <CheckOutlined /> Un mail contenant un lien de réinitialisation vous
            à été envoyé à l’adresse suivante : {form.getFieldValue('email')}
          </>
        )}

        <div className="reset-link back">
          <Button
            type="link"
            onClick={(): void => props.history.push('/login')}
          >
            <ArrowLeftOutlined />
            Se connecter
          </Button>
          {resetSent ? (
            <Button type="link" htmlType="submit">
              Renvoyer le mail
              <RedoOutlined />
            </Button>
          ) : null}
        </div>
      </Form>
    </>
  );

  return <Homepage>{resetForm}</Homepage>;
};

ResetAsking.propTypes = {
  onAskResetPassword: PropTypes.func.isRequired,
  history: PropTypes.exact(historyPropTypes).isRequired,
};

interface MapDispatchToProps {
  onAskResetPassword: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  onAskResetPassword: userActions.fetchAskResetUserPasswordActionCreator,
};

export default connect(null, mapDispatchToProps)(withRouter(ResetAsking));
