import PropTypes from 'prop-types';
import { RcFile } from 'antd/lib/upload';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import { Pagination } from '../../../services/search/search';
import { Model } from '../common/types';

export interface FileAddingPayload {
  file: UploadFile;
  objectId: string;
  objectType: string;
  organizationId: string;
  userId: string;
}

export interface FileResponse extends Model {
  createdAt: string;
  expireAt?: string | null;
  fileExtension: string;
  fileMimeType: string;
  fileSize: number;
  name: string;
  note?: string | null;
  objectId: string;
  objectType: string;
  organizationId: string;
  updatedAt: string;
  uploadedAt: string;
  userId: string;
  tagIds?: string[] | null;
}

export interface File extends FileResponse {
  downloadUrl: string;
  originFileObj: RcFile;
  previewUrl: string;
  status: UploadFileStatus;
  thumbUrl: string;
}

export interface FileState {
  files: File[];
  pagination: Pagination | null;
  status: {
    adding: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

export const PropTypesFile = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
  expireAt: PropTypes.string,
  fileExtension: PropTypes.string.isRequired,
  fileMimeType: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  objectId: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  originFileObj: PropTypes.shape({
    lastModifiedDate: PropTypes.instanceOf(Date).isRequired,
    uid: PropTypes.string.isRequired,
    lastModified: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    arrayBuffer: PropTypes.func.isRequired,
    slice: PropTypes.func.isRequired,
    stream: PropTypes.func.isRequired,
    text: PropTypes.func.isRequired,
  }).isRequired,
  previewUrl: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['error', 'success', 'done', 'uploading', 'removed'])
    .isRequired,
  thumbUrl: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  uploadedAt: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  tagIds: PropTypes.arrayOf(PropTypes.string.isRequired),
});
