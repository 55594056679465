import { all } from 'redux-saga/effects';
import { messagesSagas } from './ducks/messages';
import { contractorsSagas } from './ducks/contractors';
import { currentUserSagas } from './ducks/currentUser';
import { filesSagas } from './ducks/files';
import { historiesSagas } from './ducks/histories';
import { incidentsSagas } from './ducks/incidents';
import { incidentTypesSagas } from './ducks/incidenttypes';
import { interventionsSagas } from './ducks/interventions';
import { kpisSagas } from './ducks/kpis';
import { locationsSagas } from './ducks/locations';
import { locationTypesSagas } from './ducks/locationtypes';
import { organizationsSagas } from './ducks/organizations';
import { permissionsSagas } from './ducks/permissions';
import { profilesSagas } from './ducks/profiles';
import { requestsSagas } from './ducks/requests';
import { tagsSagas } from './ducks/tags';
import { rolesSagas } from './ducks/roles';
import { subscriptionsSagas } from './ducks/subscriptions';
import { usersSagas } from './ducks/users';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * get all saga
 */
export default function* rootSaga(): any {
  yield all([
    messagesSagas(),
    contractorsSagas(),
    currentUserSagas(),
    filesSagas(),
    historiesSagas(),
    incidentsSagas(),
    incidentTypesSagas(),
    interventionsSagas(),
    kpisSagas(),
    locationsSagas(),
    locationTypesSagas(),
    organizationsSagas(),
    permissionsSagas(),
    profilesSagas(),
    requestsSagas(),
    tagsSagas(),
    rolesSagas(),
    subscriptionsSagas(),
    usersSagas(),
  ]);
}
