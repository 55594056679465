import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface Tag extends Model {
  name: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  objectsLinked?: object | null;
}

export interface TagState {
  tags: Tag[];
  status: {
    adding: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

export const PropTypesTag = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  objectsLinked: PropTypes.object,
});
