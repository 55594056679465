import React, { FunctionComponent, ReactElement } from 'react';
import 'antd/lib/tag/style';
import Tag from '../tag/Tag';
import PropTypes from 'prop-types';
import './RequestStatusTag.scss';
import { requestStatuses } from './RequestStatusUtils';

interface RequestStatusTagProps {
  status: string;
}

/**
 * @param {status} string
 * @return {ReactElement}
 */
const RequestStatusTag: FunctionComponent<RequestStatusTagProps> = ({
  status,
}): ReactElement => (
  <Tag className={`request-status ${status}`}>{requestStatuses[status]}</Tag>
);

RequestStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default RequestStatusTag;
