import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import {
  PageHeader,
  Button,
  Card,
  Table,
  DateTooltip,
  PopoverActions,
  RestrictedToPermissions,
} from '../../base';
import AddLocationType from '../../../containers/modal/locationtype/AddLocationType';
import { ColumnProps } from 'antd/lib/table';
import { locationTypeTypes } from '../../../../states/ducks/locationtypes';
import PropTypes from 'prop-types';
import {
  alphabeticalSortBy,
  chronologicalSortBy,
} from '../../../../utils/SortUtils';
import { PlusOutlined, StarFilled } from '@ant-design/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import './Location.scss';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

interface Props extends RouteComponentProps {
  locationTypes: locationTypeTypes.LocationType[];
  deleteLocationType: Function;
}

interface TableLocationType {
  id: locationTypeTypes.LocationType['id'];
  key: locationTypeTypes.LocationType['id'];
  name: locationTypeTypes.LocationType['name'];
  isMain: locationTypeTypes.LocationType['isMain'];
  createdAt: locationTypeTypes.LocationType['createdAt'];
  updatedAt: locationTypeTypes.LocationType['updatedAt'];
  action: object;
}

const name = (name: string, record: TableLocationType): ReactElement => (
  <>
    {name}
    {record.isMain ? <StarFilled /> : <></>}
  </>
);
const dateToolTip = (date: string): ReactElement => <DateTooltip date={date} />;

const Location: FunctionComponent<Props> = ({
  locationTypes,
  deleteLocationType,
  ...props
}): ReactElement => {
  const [showAddLocationTypeModal, setShowAddLocationTypeModal] =
    useState(false);
  const [activeTab, setActiveTab] = useState('type');

  const datas = locationTypes.map(
    (locationType: locationTypeTypes.LocationType): TableLocationType => {
      return {
        id: locationType.id,
        key: locationType.id,
        name: locationType.name,
        isMain: locationType.isMain,
        createdAt: locationType.createdAt,
        updatedAt: locationType.updatedAt,
        action: {
          id: locationType.id,
        },
      };
    },
  );

  const columns: ColumnProps<TableLocationType>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      render: name,
      sorter: (a: TableLocationType, b: TableLocationType): number =>
        alphabeticalSortBy(a.name, b.name),
    },
    {
      key: 'createdAt',
      title: 'Créé',
      dataIndex: 'createdAt',
      render: dateToolTip,
      sorter: (a: TableLocationType, b: TableLocationType): number =>
        chronologicalSortBy(a.createdAt, b.createdAt),
    },
    {
      key: 'updatedAt',
      title: 'Modifié',
      dataIndex: 'updatedAt',
      render: dateToolTip,
      sorter: (a: TableLocationType, b: TableLocationType): number =>
        chronologicalSortBy(a.updatedAt, b.updatedAt),
    },
    {
      key: 'operation',
      title: (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={(): void => setShowAddLocationTypeModal(true)}
        >
          Créer
        </Button>
      ),
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableLocationType): ReactElement => {
        return (
          <PopoverActions
            objectId={record.id}
            onDelete={deleteLocationType}
            deleteConfirmMessage="Supprimer le type de lieu ?"
            deleteDoneMessage="Le type de lieu a bien été supprimé"
            size="small"
          />
        );
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: TableLocationType): any => ({
    onClick: (): void => {
      props.history.push(`/administration/location/${record.id}`);
    },
  });

  const tabList = [
    {
      key: 'type',
      tab: 'Type de lieu',
    },
  ];

  const contentList: { [key: string]: ReactNode } = {
    type: (
      <Table<TableLocationType>
        columns={columns}
        dataSource={datas}
        onRow={onRow}
      />
    ),
  };

  return (
    <RestrictedToPermissions subject={'administration'} action={'access'}>
      <PageHeader title="Lieux" />
      <Card
        className="section"
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={(key): void => setActiveTab(key)}
      >
        {contentList[activeTab]}
      </Card>
      <AddLocationType
        visible={showAddLocationTypeModal}
        hideModal={(): void => setShowAddLocationTypeModal(false)}
      />
    </RestrictedToPermissions>
  );
};

Location.propTypes = {
  locationTypes: PropTypes.arrayOf(
    locationTypeTypes.PropTypesLocationType.isRequired,
  ).isRequired,
  deleteLocationType: PropTypes.func.isRequired,
  history: PropTypes.exact(historyPropTypes).isRequired,
};

export default withRouter(Location);
