import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { Select as SelectAnt } from 'antd';
import 'antd/lib/select/style';
import { SelectProps as SelectAntProps, SelectValue } from 'antd/lib/select';
import './Select.scss';
import './SelectDropdown.scss';

export interface SelectProps extends SelectAntProps<SelectValue> {
  isFilter?: boolean;
}

/**
 * @param {SelectProps} props
 * @return {ReactElement}
 */
const Select: FunctionComponent<PropsWithChildren<SelectProps>> = ({
  ...props
}): ReactElement => {
  const containerID = 'no-outside-click-event' + String(new Date().valueOf());

  return (
    <div id={containerID} className="no-outside-click-event">
      <SelectAnt
        {...props}
        getPopupContainer={(): HTMLElement => {
          return document.getElementById(containerID) as HTMLElement;
        }}
        dropdownStyle={{
          maxHeight: 275,
          overflow: 'auto',
        }}
      />
    </div>
  );
};

export default Select;
