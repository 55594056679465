import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as BreadcrumbBase } from '../../../base';
import { Breadcrumb as BreadcrumbAnt } from 'antd';
import { Link } from 'react-router-dom';
import './Breadcrumb.scss';
import { HomeOutlined } from '@ant-design/icons';
import {
  LocationType,
  PropTypesLocationType,
} from '../../../../../states/ducks/locationtypes/types';
import { State } from '../../../../../states/types';
import { connect } from 'react-redux';
// @ts-ignore
import plural from 'pluralize-fr';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';
import { locationFromIdSelector } from '../../../../../states/ducks/locations/selectors';
import {
  Location,
  PropTypesLocation,
} from '../../../../../states/ducks/locations/types';
import { locationTypeFromIdSelector } from '../../../../../states/ducks/locationtypes/selectors';

interface BreadcrumbProps extends RouteComponentProps {
  currentLocation?: Location | null;
  currentLocationType?: LocationType | null;
}

const breadcrumbNameMap: { [key: string]: string } = {
  '/administration': 'Administration',
  '/administration/incident': 'Incidents',
  '/administration/location': 'Lieux',
  '/administration/location/*uuid*': "Détail d'un type de lieu",
  '/administration/organization': 'Organisation',
  '/administration/user': 'Utilisateurs',
  '/contractors': 'Prestataires',
  '/contractors/*uuid*': "Détail d'un prestataire",
  '/files': 'Documents',
  '/help': 'Aide',
  '/incidents': 'Incidents',
  '/incidents/*uuid*': "Détail d'un incident",
  '/locations': 'Lieux',
  '/locations/type': 'Type',
  '/locations/type/*uuid*': 'Lieu',
  '/locations/type/*uuid*/*uuid*': "Détail d'un lieu",
  '/locations/*uuid*': "Détail d'un lieu",
  '/myaccount': 'Paramètres du compte',
  '/requests': 'Demandes',
  '/requests/*uuid*': "Détail d'une demande",
  '/users': 'Utilisateurs',
};

/**
 * @param {O} obj
 * @param {string} key
 * @return {boolean}
 */
function hasKey<O>(obj: O, key: string): boolean {
  return key in obj;
}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  currentLocationType,
  ...props
}): ReactElement => {
  const pathSnippets = props.location.pathname
    .split('/')
    .filter((i): string => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index): ReactElement => {
    let url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    url = url.replace(/\/.{8}-.{4}-.{4}-.{4}-.{12}/g, '/*uuid*');

    let breadcrumbText = hasKey(breadcrumbNameMap, url)
      ? breadcrumbNameMap[url]
      : '';

    if (currentLocationType) {
      if (url === '/locations/type/*uuid*') {
        breadcrumbText = `${plural(currentLocationType.name)}`;
      }

      if (
        url === '/locations/*uuid*' ||
        url === '/locations/type/*uuid*/*uuid*'
      ) {
        breadcrumbText = `Détail d'un(e) ${currentLocationType.name.toLowerCase()}`;
      }
    }

    return (
      <BreadcrumbAnt.Item key={url}>
        <Link to={url}>{breadcrumbText}</Link>
      </BreadcrumbAnt.Item>
    );
  });

  const breadcrumbItems = [
    <BreadcrumbAnt.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </BreadcrumbAnt.Item>,
  ].concat(extraBreadcrumbItems);

  const url = props.location.pathname.replace(
    /\/.{8}-.{4}-.{4}-.{4}-.{12}/g,
    '/*uuid*',
  );

  return hasKey(breadcrumbNameMap, url) ? (
    <BreadcrumbBase className="app-breadcrumb">
      {breadcrumbItems}
    </BreadcrumbBase>
  ) : (
    <></>
  );
};

Breadcrumb.propTypes = {
  location: PropTypes.any.isRequired,
  currentLocation: PropTypesLocation,
  currentLocationType: PropTypesLocationType,
};

interface MapStateToProps {
  currentLocation?: Location | null;
  currentLocationType?: LocationType | null;
}

const mapStateToProps = (
  state: State,
  props: RouteComponentProps,
): MapStateToProps => {
  let location;
  let locationType;

  if (
    ['/locations/:id', '/locations/type/:id/:id'].includes(props.match.path)
  ) {
    location = locationFromIdSelector(
      state,
      (props.match.params as Location).id,
    );

    if (location && location.locationTypeId) {
      locationType = locationTypeFromIdSelector(state, location.locationTypeId);
    }
  }

  if ('/locations/type/:id' === props.match.path) {
    locationType = locationTypeFromIdSelector(
      state,
      (props.match.params as LocationType).id,
    );
  }

  return {
    currentLocation: location,
    currentLocationType: locationType,
  };
};

const BreadcrumbContainer = connect(mapStateToProps, null)(Breadcrumb);

export default withRouter(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: RouteComponentProps<any, StaticContext, any>): ReactElement => (
    <BreadcrumbContainer {...props} />
  ),
);
