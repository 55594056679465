import React, { FunctionComponent, ReactElement } from 'react';
import { Col as ColAnt } from 'antd';
import 'antd/lib/col/style';
import { ColProps } from 'antd/lib/col';

/**
 * @param {any} props
 * @return {ReactElement}
 */
const Col: FunctionComponent<ColProps> = ({ ...props }): ReactElement => (
  <ColAnt {...props} />
);

Col.propTypes = {};

export default Col;
