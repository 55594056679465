import React, {
  ReactElement,
  FunctionComponent,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import './Organization.scss';
import {
  PageHeader,
  Card,
  Skeleton,
  Table,
  Button,
  RestrictedToPermissions,
  PopoverActions,
} from '../../base';
import { organizationTypes } from '../../../../states/ducks/organizations';
import { userTypes } from '../../../../states/ducks/users';
import { profileTypes } from '../../../../states/ducks/profiles';
import { Authenticate } from '../../../../services/authenticate/types';
import { RouteComponentProps } from 'react-router';
import AddOrganizationDrawer from '../../../containers/modal/organization/AddOrganization';
import InfoOrganizationDrawer from '../../../containers/modal/organization/InfoOrganization';
import { ColumnProps } from 'antd/lib/table';
import { alphabeticalSortBy } from '../../../../utils/SortUtils';
import { PlusOutlined } from '@ant-design/icons';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

interface OrganizationsProps extends RouteComponentProps {
  organizations: organizationTypes.Organization[];
  loading: number;
  deleteOrganization: Function;
  userId: userTypes.User['id'];
  user: userTypes.User | null;
  profile: profileTypes.Profile | null;
  getUser: Function;
  getProfile: Function;
  authenticate: Authenticate;
  logout: Function;
}

interface TableOrganization {
  id: organizationTypes.Organization['id'];
  key: organizationTypes.Organization['id'];
  name: organizationTypes.Organization['name'];
  children?: organizationTypes.Organization['children'];
}

const initialState = {
  visibleDrawerAdd: false,
  visibleDrawerInfo: false,
  organizationId: null as null | organizationTypes.Organization['id'],
};

type OrganizationReducerState = typeof initialState;

interface OrganizationReducerAction {
  type: 'VISIBLE_DRAWER_ADD' | 'VISIBLE_DRAWER_INFO' | 'HIDDEN';
  payload?: {
    organizationId?: null | organizationTypes.Organization['id'];
  };
}

const reducer = (
  state: OrganizationReducerState,
  action: OrganizationReducerAction,
): OrganizationReducerState => {
  const { organizationId = null } = action.payload ? action.payload : {};

  switch (action.type) {
    case 'VISIBLE_DRAWER_ADD':
      return {
        ...state,
        visibleDrawerAdd: true,
        organizationId,
      };
    case 'VISIBLE_DRAWER_INFO':
      return {
        ...state,
        visibleDrawerInfo: true,
        organizationId,
      };
    case 'HIDDEN':
      return {
        ...state,
        visibleDrawerAdd: false,
        visibleDrawerInfo: false,
      };
    default:
      throw new Error();
  }
};

/**
 * @return {object}
 */
function useOrganizationReducer(): {
  showDrawerAdd: Function;
  showDrawerInfo: Function;
  hideDrawer: Function;
  state: OrganizationReducerState;
} {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showDrawerAdd = (
    organizationId: organizationTypes.Organization['id'],
  ): void => {
    dispatch({
      type: 'VISIBLE_DRAWER_ADD',
      payload: { organizationId },
    });
  };

  const showDrawerInfo = (
    organizationId: organizationTypes.Organization['id'],
  ): void => {
    dispatch({
      type: 'VISIBLE_DRAWER_INFO',
      payload: { organizationId },
    });
  };

  const hideDrawer = (): void => {
    dispatch({
      type: 'HIDDEN',
      payload: { organizationId: null },
    });
  };

  return {
    showDrawerAdd,
    showDrawerInfo,
    hideDrawer,
    state,
  };
}

const Organizations: FunctionComponent<OrganizationsProps> = ({
  organizations,
  loading,
  deleteOrganization,
  userId,
  user,
  profile,
  getUser,
  getProfile,
  logout,
  ...props
}): ReactElement => {
  const { showDrawerAdd, showDrawerInfo, hideDrawer, state } =
    useOrganizationReducer();
  let parentOrganizationId = '';

  useEffect((): void => {
    getUser(userId);
  }, [getUser, userId]);

  useEffect((): void => {
    if (getProfile && userId) {
      getProfile(userId);
    }
  }, [getProfile, userId]);

  const loop = (
    Organizations: organizationTypes.Organization[],
  ): TableOrganization[] =>
    Organizations.map(
      (organization: organizationTypes.Organization): TableOrganization => {
        const { children } = organization;

        if (children && children.length !== 0) {
          loop(children);
          // delete the icon if no children
          children
            .filter(
              (child: organizationTypes.Organization): boolean =>
                typeof child.children !== 'undefined' &&
                null !== child.children &&
                child.children.length === 0,
            )
            .map(
              (child: organizationTypes.Organization): boolean =>
                delete child.children,
            );

          parentOrganizationId = organization.id;

          return {
            id: organization.id,
            key: organization.id,
            name: organization.name,
            children: organization.children,
          };
        }
        // first level
        return {
          id: organization.id,
          key: organization.id,
          name: organization.name,
        };
      },
    );

  const action = (
    record: TableOrganization,
    organizations: organizationTypes.Organization,
  ): ReactElement => {
    return (
      <>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="small"
          onClick={(e): void => {
            e.stopPropagation();
            showDrawerAdd(organizations.id);
          }}
        />
        {organizations.name !== 'Twipi Group' ? (
          <RestrictedToPermissions
            subject={'admin'}
            action={'admin'}
            key="organization-delete"
          >
            <PopoverActions
              objectId={organizations.id}
              onDelete={deleteOrganization}
              deleteConfirmMessage="Attention ! Supprimer une organisation entraine
              la suppression de TOUTES les données se trouvant rattachées à cette dernière.
              Merci de confirmer cette action avec le responsable en charge du client.
              Êtes-vous sûr de vouloir continuer ?"
              deleteDoneMessage="L'organisation' a bien été supprimée"
              size="small"
              redirect={(): void | null =>
                organizations.id === parentOrganizationId
                  ? props.authenticate
                      .unauthentication()
                      .then(() => setTimeout(() => logout(), 2000))
                      .then(() => props.history.push('/login'))
                  : null
              }
            />
          </RestrictedToPermissions>
        ) : (
          <></>
        )}
      </>
    );
  };

  const columns: ColumnProps<TableOrganization>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a: TableOrganization, b: TableOrganization): number =>
        alphabeticalSortBy(a.name, b.name),
    },
    {
      key: 'operation',
      title: (
        <RestrictedToPermissions
          subject={'admin'}
          action={'admin'}
          key="organization-add"
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={(e): void => {
              e.stopPropagation();
              showDrawerAdd();
            }}
          >
            Nouvelle organisation
          </Button>
        </RestrictedToPermissions>
      ),
      dataIndex: 'action',
      className: 'action',
      render: action,
    },
  ];

  return (
    <RestrictedToPermissions subject={'administration'} action={'access'}>
      <PageHeader title="Organisations" />
      <Card className="section">
        <Skeleton
          className="app-organizations-skeleton"
          loading={loading === 1 && !organizations.length}
          active
          title={false}
          paragraph={{
            rows: 5,
            width: '100%',
          }}
        >
          <Table<TableOrganization>
            className="app-organizations-table"
            columns={columns}
            dataSource={loop(organizations)}
            rowKey="id"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onRow={(record): any => ({
              onClick: (): void => {
                showDrawerInfo(record.id);
              },
            })}
          />
        </Skeleton>
      </Card>
      <AddOrganizationDrawer
        visible={state.visibleDrawerAdd}
        hideDrawer={hideDrawer}
        parentId={state.organizationId}
      />
      <InfoOrganizationDrawer
        visible={state.visibleDrawerInfo}
        hideDrawer={hideDrawer}
        organizationId={state.organizationId || ''}
      />
    </RestrictedToPermissions>
  );
};

Organizations.propTypes = {
  organizations: PropTypes.arrayOf(
    organizationTypes.PropTypesOrganization.isRequired,
  ).isRequired,
  loading: PropTypes.number.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  user: userTypes.PropTypesUser,
  profile: profileTypes.PropTypesProfile,
  getUser: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  authenticate: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    authentication: PropTypes.func.isRequired,
    unauthentication: PropTypes.func.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  history: PropTypes.exact(historyPropTypes).isRequired,
};

export default Organizations;
