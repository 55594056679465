import { createSelector } from 'reselect';
import { State } from '../../../types';
import { User } from '../types';
import { usersSelector } from './base';
import { locationAndItsDescendantsIdsSelector } from '../../locations/selectors';
import { Organization } from '../../organizations/types';
import {
  organizationAndItsDescendantsIdsSelector,
  organizationFromIdSelector,
  organizationRootSelector,
} from '../../organizations/selectors/organizationsSelectors';
import { Location } from '../../locations/types';

/**
 * @param {State} state
 * @param {string} id
 * @return {User|null}
 */
export const userFromIdSelector = createSelector(
  usersSelector,
  (_: State, id: User['id']) => id,
  (users, id) => users.find((user) => user.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {User[]}
 */
export const userFromMultipleIdsSelector = createSelector(
  usersSelector,
  (_: State, ids: User['id'][]) => ids,
  (users, ids) => users.filter((user: User) => ids.includes(user.id)),
);

/**
 * @param {State} state
 * @return {User[]}
 */
export const visibleUsersFromOrganizationSelector = createSelector(
  usersSelector,
  (state: State, organization: Organization) =>
    organizationAndItsDescendantsIdsSelector(state, organization),
  (users, organizationIds) =>
    users.filter(
      (user) =>
        organizationIds.filter((id) => user.organizationIds.includes(id))
          .length > 0,
    ),
);

/**
 * @param {State} state
 * @return {User[]}
 */
export const visibleUsersFromLocationSelector = createSelector(
  (state: State) => state,
  (state: State, location: Location) => {
    const organization = organizationFromIdSelector(
      state,
      location.organizationId,
    );

    if (!organization) {
      return;
    }

    return organizationRootSelector(state, organization);
  },
  (state, organization) =>
    organization
      ? visibleUsersFromOrganizationSelector(state, organization)
      : [],
);

/**
 * @param {State} state
 * @return {User[]}
 */
export const usersAssignedToLocationSelector = createSelector(
  usersSelector,
  locationAndItsDescendantsIdsSelector,
  (users, locationIds) =>
    users.filter(
      (user) =>
        user.locationIds &&
        user.locationIds.filter((value) => locationIds.includes(value)).length >
          0,
    ),
);

/**
 * @param {State} state
 * @return {User[]}
 */
export const usersAssignableToLocationSelector = createSelector(
  visibleUsersFromLocationSelector,
  usersAssignedToLocationSelector,
  (users, usersAssignedToLocation) =>
    users.filter((user) => !usersAssignedToLocation.includes(user)),
);
