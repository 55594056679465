import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';
import { Permission, PermissionState } from './types';

/**
 * @param {Permission[]} state
 * @param {Action} action
 * @return {Permission[]}
 */
export const permissionReducer: Reducer<Permission[]> = (
  state: Permission[] = [],
  action: AnyAction,
) => modelReducer('permissions', state, action) as Permission[];

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
) => {
  switch (action.type) {
    case getActionType('permissions', 'LIST_FAILED'):
    case getActionType('permissions', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('permissions', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<PermissionState> = combineReducers({
  permissions: permissionReducer,
  status: combineReducers({
    listing: listingReducer,
  }),
});

export default reducers;
