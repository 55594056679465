import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { historyTypes } from '../../../../states/ducks/histories';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import { Avatar, Change, ProfilePopover } from '../index';
import { Comment as CommentAnt } from 'antd';
import 'antd/lib/comment/style';
import moment from 'moment';
import { getDescription } from './descriptions';

interface HistoryProps {
  history: historyTypes.History;
  profile?: profileTypes.Profile | null;
  getProfile?: Function;
}

/**
 * @param {History} history
 * @param {Profile} profile
 * @param {Function} getProfile
 *
 * @return {ReactElement}
 */
const History: FunctionComponent<HistoryProps> = ({
  history,
  profile,
  getProfile,
}): ReactElement => {
  useEffect((): void => {
    if (getProfile && !profile) {
      getProfile(history.userId);
    }
  }, [getProfile, profile, history]);

  if (!getDescription(history)) {
    return <></>;
  }

  return (
    <CommentAnt
      author={<ProfilePopover userId={history.userId} />}
      avatar={<Avatar userId={history.userId} size={'large'} />}
      content={
        <>
          {Object.entries(history.from).map(
            ([key, value]): ReactElement => (
              <Change
                key={`${history.id}-${key}`}
                field={`${history.objectType}-${key}`}
                before={value}
                after={history.to[key]}
              />
            ),
          )}
        </>
      }
      datetime={
        <>
          <span className="event">{getDescription(history)}</span>
          <span className="date">
            {moment(history.date).format('DD/MM/YYYY à H[h]mm')}
          </span>
        </>
      }
      className={'app-history'}
    />
  );
};

History.propTypes = {
  history: historyTypes.PropTypesHistory.isRequired,
  profile: profileTypes.PropTypesProfile,
  getProfile: PropTypes.func,
};

interface MapStateToProps {
  profile: profileTypes.Profile | null;
}

interface MapDispatchToProps {
  getProfile: Function;
}

const mapStateToProps = (
  state: State,
  props: { history: historyTypes.History },
): MapStateToProps => ({
  profile: profileSelectors.profileFromUserIdSelector(
    state,
    props.history.userId,
  ),
});

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
