import { connect } from 'react-redux';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import { CurrentUser } from '../../../../states/ducks/currentUser/types';
import { incidentActions } from '../../../../states/ducks/incidents';
import { State } from '../../../../states/types';
import ReportIncident from '../../../components/layout/modal/incident/ReportIncident';

interface MapStateToProps {
  currentUser: CurrentUser;
}

interface MapDispatchToProps {
  reportIncident: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  reportIncident: incidentActions.fetchReportIncidentsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportIncident);
