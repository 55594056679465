import React, { FunctionComponent, ReactElement } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import HelpContainer from '../../layout/help/Container';

const HelpRoute: FunctionComponent<RouteProps> = (props): ReactElement => (
  <HelpContainer>
    <Route {...props} />
  </HelpContainer>
);

HelpRoute.propTypes = {};

export default HelpRoute;
