import { AnyAction } from 'redux';
import { flatiItems, rehydrate } from '../../helper';
import { getActionTypeAttributes, Model } from './types';

/**
 * @param {string} concept
 * @param {Model[]} state
 * @param {AnyAction} action
 * @param {boolean} resetOnLogout
 * @return {Model[]}
 */
export const modelReducer = (
  concept: string,
  state: Model[] = [],
  action: AnyAction,
  resetOnLogout = true,
): Model[] => {
  const actionTypeAttributes = getActionTypeAttributes(action.type);

  if (null === actionTypeAttributes) {
    return state;
  }

  if (
    resetOnLogout &&
    actionTypeAttributes.concept === 'auth' &&
    actionTypeAttributes.operation === 'LOGOUT'
  ) {
    return [];
  }

  if (
    actionTypeAttributes.concept !== concept ||
    actionTypeAttributes.state !== 'SUCCEEDED'
  ) {
    return state;
  }

  if (
    ['ADD', 'REPORT', 'OPEN', 'SUBSCRIBE', 'LIST', 'GET', 'EDIT'].includes(
      actionTypeAttributes.operation,
    )
  ) {
    return rehydrate<Model>(
      state,
      flatiItems<Model>([].concat(action.payload)),
    );
  }

  if (['DELETE', 'UNSUBSCRIBE'].includes(actionTypeAttributes.operation)) {
    return state.filter((model): boolean => model.id !== action.payload);
  }

  return state;
};
