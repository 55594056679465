import React, {
  ReactElement,
  FunctionComponent,
  ChangeEvent,
  useState,
} from 'react';
import { Button, Table, InputSearch, Text, PopoverActions } from '../../base';
import { locationTypes } from '../../../../states/ducks/locations';
import AddLocation from '../../../containers/modal/location/AddLocation';
import InfoLocation from '../../../containers/modal/location/InfoLocation';
import PropTypes from 'prop-types';
import { locationTypeTypes } from '../../../../states/ducks/locationtypes';
import { ColumnProps } from 'antd/lib/table';
import { Redirect } from 'react-router-dom';
import { alphabeticalSortBy } from '../../../../utils/SortUtils';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';

export interface PropsLocationInfoStructure {
  useLocationReducer: Function;
  locations: locationTypes.Location[];
  location: locationTypes.Location | null;
  locationTypes: locationTypeTypes.LocationType[];
  deleteLocation: Function;
}

interface TableLocation {
  code: locationTypes.Location['code'];
  id: locationTypes.Location['id'];
  key: locationTypes.Location['id'];
  name: locationTypes.Location['name'];
  locationTypeName: locationTypeTypes.LocationType['name'];
  organizationId: locationTypes.Location['organizationId'];
  children?: locationTypes.Location['children'];
}

const LocationInfoStructure: FunctionComponent<PropsLocationInfoStructure> = ({
  useLocationReducer,
  locations,
  location,
  locationTypes,
  deleteLocation,
}): ReactElement => {
  const {
    showAddLocationModal,
    showInfoLocationModal,
    hideLocationModal,
    state,
    redirectToLocation,
  } = useLocationReducer();

  const initialSearch: {
    value: string;
    expandedKeys: string[];
  } = {
    value: '',
    expandedKeys: [],
  };
  const [search, setSearch] = useState(initialSearch);

  const loopLocations = (
    locations: locationTypes.Location[],
  ): TableLocation[] =>
    locations.map((location: locationTypes.Location): TableLocation => {
      let locationTypeName = '';
      for (let index = 0; index < locationTypes.length; index++) {
        if (locationTypes[index].id === location.locationTypeId) {
          locationTypeName = locationTypes[index].name;
          break;
        }
      }

      const { children } = location;

      if (children && children.length !== 0) {
        const child = loopLocations(children);

        children
          .filter(
            (child: locationTypes.Location): boolean =>
              typeof child.children !== 'undefined' &&
              null !== child.children &&
              child.children.length === 0,
          )
          .map(
            (child: locationTypes.Location): boolean => delete child.children,
          );

        return {
          code: location.code,
          id: location.id,
          key: location.id,
          name: location.name,
          locationTypeName: locationTypeName,
          organizationId: location.organizationId,
          children: child,
        };
      }

      return {
        code: location.code,
        id: location.id,
        key: location.id,
        name: location.name,
        locationTypeName: locationTypeName,
        organizationId: location.organizationId,
      };
    });

  if (state.link) {
    return (
      <Redirect
        to={{
          pathname: `/locations/${state.locationId}`,
        }}
      />
    );
  }

  const renderName = (record: TableLocation): ReactElement => {
    const pattern = new RegExp('(' + search.value + ')', 'i');
    const splitted = record.name.split(pattern);

    const loop = (tab: string[]): ReactElement => {
      if (tab.length === 0) {
        return <></>;
      }
      const notHighlightText = tab.shift();
      const highlightText = tab.shift();
      return (
        <span>
          {notHighlightText}
          <Text highlight>{highlightText}</Text>
          {loop(tab)}
        </span>
      );
    };
    return loop(splitted);
  };

  const renderAction = (
    record: TableLocation,
    location: TableLocation,
  ): ReactElement => {
    return (
      <>
        <Button
          icon={<EyeOutlined />}
          size="small"
          onClick={(e): void => {
            e.stopPropagation();
            redirectToLocation(location.id);
          }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="small"
          onClick={(e): void => {
            e.stopPropagation();
            showAddLocationModal(location);
          }}
        />
        <PopoverActions
          objectId={location.id}
          onDelete={deleteLocation}
          deleteConfirmMessage={
            `Supprimer le ${location.locationTypeName} ? ` +
            '(Attention les lieux rattachés ' +
            'à la structure seront supprimés)'
          }
          deleteDoneMessage={`Le ${location.locationTypeName} a bien été supprimé`}
          size="small"
        />
      </>
    );
  };

  const columns: ColumnProps<TableLocation>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a: TableLocation, b: TableLocation): number =>
        alphabeticalSortBy(a.name, b.name),
      render: (text: string, record: TableLocation): ReactElement =>
        renderName(record),
    },
    {
      key: 'locationTypeName',
      title: 'Type',
      dataIndex: 'locationTypeName',
      sorter: (a: TableLocation, b: TableLocation): number =>
        alphabeticalSortBy(a.locationTypeName, b.locationTypeName),
    },
    {
      key: 'operation',
      title: (
        <Button
          type="primary"
          onClick={(): void => showAddLocationModal()}
          icon={<PlusOutlined />}
        >
          Créer
        </Button>
      ),
      dataIndex: 'action',
      className: 'action',
      render: renderAction,
    },
  ];

  const emptyText = (
    <Button type="link" onClick={(): void => showAddLocationModal()}>
      Créer un lieu
    </Button>
  );

  const generateList = (
    locations: ItemLocation[],
    dataList: ItemLocation[],
    parentsList: { [key: string]: string },
    parentId = '0',
  ): ItemLocation[] => {
    for (let i = 0; i < locations.length; i++) {
      const node = locations[i];
      const { id, name } = node;
      dataList.push({ id, name });
      parentsList[id] = parentId;
      if (node.children) {
        generateList(node.children, dataList, parentsList, id);
      }
    }
    return dataList;
  };

  const dataList: ItemLocation[] = [];
  const parentsList: { [key: string]: string } = { '0': '0' };
  generateList(locations, dataList, parentsList);

  type ItemLocation = Pick<locationTypes.Location, 'name' | 'id' | 'children'>;

  const getParents = (
    parentsList: { [key: string]: string },
    locationId: string,
    nestedParents: string[] = [],
  ): string[] => {
    if (typeof parentsList[locationId] === undefined) {
      return nestedParents;
    }
    nestedParents.push(parentsList[locationId]);
    if (locationId !== '0') {
      getParents(parentsList, parentsList[locationId], nestedParents);
    }
    return nestedParents;
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    let expandedKeys: string[] = [];

    dataList.map((location: ItemLocation): string[] => {
      if (location.name.search(new RegExp(value, 'i')) >= 0) {
        const parentsFound = getParents(parentsList, location.id);
        expandedKeys = expandedKeys.concat(
          parentsFound.filter((item): boolean => item !== '0'),
        );
      }
      return expandedKeys;
    });

    setSearch({
      ...search,
      value: event.target.value,
      expandedKeys: expandedKeys,
    });
  };

  const onExpand = (expanded: boolean, record: TableLocation): void => {
    let expandedKeys = search.expandedKeys;

    if (expanded) {
      expandedKeys.push(record.id);
    }

    if (!expanded && Object.values(expandedKeys).includes(record.id)) {
      expandedKeys = expandedKeys.filter((item): boolean => item !== record.id);
    }

    setSearch({
      ...search,
      expandedKeys: expandedKeys,
    });
  };

  return (
    <>
      <InputSearch
        placeholder={'Rechercher'}
        value={search.value}
        onChange={onChange}
        className={'filter'}
      />

      <Table<TableLocation>
        className="app-structure-container"
        columns={columns}
        dataSource={loopLocations(locations)}
        rowKey="id"
        locale={{ emptyText }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onRow={(record): any => ({
          onClick: (): void => {
            showInfoLocationModal(record.id);
          },
        })}
        onExpand={onExpand}
        expandedRowKeys={search.expandedKeys}
      />
      <AddLocation
        visible={state.modalAddLocationVisible}
        hideModal={hideLocationModal}
        parentLocation={location}
        parentLocationStructure={state.location}
      />
      <InfoLocation
        visible={state.modalInfoLocationVisible}
        hideDrawer={hideLocationModal}
        locationId={state.locationId}
        useLocationReducer={useLocationReducer}
        redirectToLocation={redirectToLocation}
      />
    </>
  );
};

LocationInfoStructure.propTypes = {
  useLocationReducer: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(locationTypes.PropTypesLocation.isRequired)
    .isRequired,
  location: locationTypes.PropTypesLocation,
  locationTypes: PropTypes.arrayOf(
    locationTypeTypes.PropTypesLocationType.isRequired,
  ).isRequired,
  deleteLocation: PropTypes.func.isRequired,
};

export default LocationInfoStructure;
