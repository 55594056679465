import { connect } from 'react-redux';
import AddRole from '../../../components/layout/modal/role/AddRole';
import {
  roleTypes,
  roleSelectors,
  roleActions,
} from '../../../../states/ducks/roles';
import { State } from '../../../../states/types';
import { Organization } from '../../../../states/ducks/organizations/types';
import { currentUserOrganizationRootSelector } from '../../../../states/ducks/currentUser/selectors';

interface MapStateToProps {
  userOrganization: Organization;
  roles: roleTypes.Role[];
}

interface MapDispatchToProps {
  addRole: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  userOrganization: currentUserOrganizationRootSelector(state),
  roles: roleSelectors.rolesFromOrganizationSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  addRole: roleActions.fetchAddRolesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
