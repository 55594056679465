import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {User[]} [state=[]] users
 * @param {AnyAction} action
 * @return {User[]}
 */
export const userReducer: Reducer<types.User[]> = (
  state: types.User[] = [],
  action: AnyAction,
): types.User[] => {
  return modelReducer('users', state, action, false) as types.User[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('users', 'LIST_FAILED'):
    case getActionType('users', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('users', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('users', 'ADD_FAILED'):
    case getActionType('users', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('users', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('users', 'EDIT_FAILED'):
    case getActionType('users', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('users', 'EDIT_INFORMATION_REQUESTED'):
    case getActionType('users', 'EDIT_EMAIL_REQUESTED'):
    case getActionType('users', 'EDIT_PASSWORD_REQUESTED'):
    case getActionType('users', 'RESET_PASSWORD_REQUESTED'):
    case getActionType('users', 'EDIT_ORGANIZATIONS_REQUESTED'):
    case getActionType('users', 'EDIT_VISIBLE_ORGANIZATIONS_REQUESTED'):
    case getActionType('users', 'EDIT_ADMIN_ORGANIZATIONS_REQUESTED'):
    case getActionType('users', 'EDIT_LOCATIONS_REQUESTED'):
    case getActionType('users', 'EDIT_VISIBLE_LOCATIONS_REQUESTED'):
    case getActionType('users', 'EDIT_ROLE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('users', 'GET_FAILED'):
    case getActionType('users', 'GET_SUCCEEDED'):
      return false;
    case getActionType('users', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.UserState> = combineReducers({
  users: userReducer,
  status: combineReducers({
    listing: listingReducer,
    adding: addingReducer,
    editing: editingReducer,
    getting: gettingReducer,
  }),
});

export default reducers;
