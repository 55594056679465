import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { getActionType } from '../common/types';

/**
 * @param {LocationType} locationType
 * @return {FetchAddRequested}
 */
export const fetchAddLocationTypesActionCreator = createAction(
  getActionType('locationtypes', 'ADD_REQUESTED'),
  (locationType: types.LocationType): types.LocationType => locationType,
  (locationType, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {LocationType} locationType
 * @return {FetchAddSucceeded}
 */
export const fetchAddLocationTypesSuccessActionCreator = createAction(
  getActionType('locationtypes', 'ADD_SUCCEEDED'),
  (locationType: types.LocationType): types.LocationType => locationType,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddLocationTypesErrorActionCreator = createAction(
  getActionType('locationtypes', 'ADD_FAILED'),
  (message: string): string => message,
);

/**
 * @return {FetchListRequested}
 */
export const fetchListLocationTypesActionCreator = createAction(
  getActionType('locationtypes', 'LIST_REQUESTED'),
);

/**
 * @param {LocationType[]} locationTypes
 * @return {FetchListSucceeded}
 */
export const fetchListLocationTypesSuccessActionCreator = createAction(
  getActionType('locationtypes', 'LIST_SUCCEEDED'),
  (locationTypes: types.LocationType[]): types.LocationType[] => locationTypes,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListLocationTypesErrorActionCreator = createAction(
  getActionType('locationtypes', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetLocationTypesActionCreator = createAction(
  getActionType('locationtypes', 'GET_REQUESTED'),
  (id: types.LocationType['id']): types.LocationType['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {LocationType} locationType
 * @return {FetchGetSucceeded}
 */
export const fetchGetLocationTypesSuccessActionCreator = createAction(
  getActionType('locationtypes', 'GET_SUCCEEDED'),
  (locationType: types.LocationType): types.LocationType => locationType,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetLocationTypesErrorActionCreator = createAction(
  getActionType('locationtypes', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {LocationType} locationType
 * @return {FetchEditSucceeded}
 */
export const fetchEditLocationTypesSuccessActionCreator = createAction(
  getActionType('locationtypes', 'EDIT_SUCCEEDED'),
  (locationType: types.LocationType): types.LocationType => locationType,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditLocationTypesErrorActionCreator = createAction(
  getActionType('locationtypes', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {LocationType} locationType
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditLocationTypesDesignationActionCreator = createAction(
  getActionType('locationtypes', 'EDIT_DESIGNATION_REQUESTED'),
  (locationType: types.LocationType): types.LocationType => locationType,
  (locationType, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {LocationType} locationType
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditMainLocationTypesActionCreator = createAction(
  getActionType('locationtypes', 'EDIT_MAIN_REQUESTED'),
  (locationType: types.LocationType): types.LocationType => locationType,
  (locationType, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {LocationType} locationType
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditQuickAccessLocationTypesActionCreator = createAction(
  getActionType('locationtypes', 'EDIT_QUICK_ACCESS_REQUESTED'),
  (locationType: types.LocationType): types.LocationType => locationType,
  (locationType, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteLocationTypesActionCreator = createAction(
  getActionType('locationtypes', 'DELETE_REQUESTED'),
  (id: types.LocationType['id']): types.LocationType['id'] => id,
  (locationType, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteLocationTypesSuccessActionCreator = createAction(
  getActionType('locationtypes', 'DELETE_SUCCEEDED'),
  (id: types.LocationType['id']): types.LocationType['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteLocationTypesErrorActionCreator = createAction(
  getActionType('locationtypes', 'DELETE_FAILED'),
  (message: string): string => message,
);
