import PropTypes from 'prop-types';
import { Model } from '../common/types';

interface KpiIncidentByStatus {
  status: string;
  value: number;
}

interface KpiIncidentsReportedAndResolvedByMonth {
  date: string;
  value: number;
  status: string;
}

interface KpiIncidentsByMainLocations {
  locationId: string;
  value: number;
}

export interface KpiRequestsByManager {
  managerId: string;
  value: number;
}

interface KpiRequestsOpenedAndClosedByMonth {
  date: string;
  value: number;
  status: string;
}

type KPIValues =
  | KpiIncidentByStatus[]
  | KpiIncidentsReportedAndResolvedByMonth[]
  | KpiIncidentsByMainLocations[]
  | KpiRequestsByManager[]
  | KpiRequestsOpenedAndClosedByMonth[];

export interface Kpi extends Model {
  name?: string | null;
  type?: string | null;
  value?: KPIValues;
}

export interface KpiState {
  kpis: Kpi[];
  status: {
    getting: boolean;
  };
}

export const PropTypesKpi = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
});
