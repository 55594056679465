import React, { FunctionComponent, ReactElement } from 'react';
import { connect, DispatchProp } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  profileSelectors,
  profileTypes,
} from '../../../../states/ducks/profiles';
import { FilterableSelect, Select, SelectOption } from '../index';
import { SelectProps } from './Select';
import { AnyAction } from 'redux';

interface ProfilesSelectProps extends SelectProps {
  profiles: profileTypes.Profile[] | null;
}

/**
 * @param {object[]} profiles
 * @param {DispatchProp<AnyAction>} dispatch
 * @param {ProfilesSelectProps} props
 *
 * @return {ReactElement}
 */
const ProfilesSelect: FunctionComponent<
  ProfilesSelectProps & DispatchProp<AnyAction>
> = ({ profiles, dispatch, ...props }): ReactElement => {
  const selectOptions = profiles
    ? profiles.map(
        (profile): ReactElement => (
          <SelectOption
            key={profile.userId}
            value={profile.userId}
            title={`${profile.firstName} ${profile.lastName}`}
          >
            {`${profile.firstName} ${profile.lastName}`}
          </SelectOption>
        ),
      )
    : [];

  return props.isFilter ? (
    <FilterableSelect {...props}>{selectOptions}</FilterableSelect>
  ) : (
    <Select {...props}>{selectOptions}</Select>
  );
};

ProfilesSelect.propTypes = {
  dispatch: PropTypes.any,
  profiles: PropTypes.arrayOf(profileTypes.PropTypesProfile.isRequired),
  isFilter: PropTypes.bool,
};

interface MapStateToProps {
  profiles: profileTypes.Profile[] | null;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  profiles: profileSelectors.visibleProfilesSelector(state),
});

export default connect(mapStateToProps)(ProfilesSelect);
