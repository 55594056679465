import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';
import { Contractor, ContractorState } from './types';

/**
 * @param {Contractor[]} state
 * @param {AnyAction} action
 * @return {Contractor[]}
 */
export const contractorReducer: Reducer<Contractor[]> = (
  state: Contractor[] = [],
  action: AnyAction,
): Contractor[] => {
  return modelReducer('contractors', state, action) as Contractor[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('contractors', 'ADD_FAILED'):
    case getActionType('contractors', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('contractors', 'ADD_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('contractors', 'LIST_FAILED'):
    case getActionType('contractors', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('contractors', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('contractors', 'GET_FAILED'):
    case getActionType('contractors', 'GET_SUCCEEDED'):
      return false;
    case getActionType('contractors', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('contractors', 'EDIT_FAILED'):
    case getActionType('contractors', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('contractors', 'EDIT_NAME_REQUESTED'):
    case getActionType('contractors', 'EDIT_TWIMM_LINK_REQUESTED'):
    case getActionType('contractors', 'EDIT_INCIDENT_TYPES_REQUESTED'):
    case getActionType('contractors', 'EDIT_ORGANIZATIONS_REQUESTED'):
    case getActionType('contractors', 'ADD_FILE_REQUESTED'):
    case getActionType('contractors', 'ADD_GED_FILE_REQUESTED'):
    case getActionType('contractors', 'DELETE_FILE_REQUESTED'):
    case getActionType('contractors', 'EDIT_EMAIL_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('contractors', 'DELETE_FAILED'):
    case getActionType('contractors', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('contractors', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<ContractorState> = combineReducers({
  contractors: contractorReducer,
  status: combineReducers({
    adding: addingReducer,
    listing: listingReducer,
    getting: gettingReducer,
    editing: editingReducer,
    deleting: deletingReducer,
  }),
});

export default reducers;
