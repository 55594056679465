import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  kpiActions,
  kpiSelectors,
  kpiTypes,
} from '../../../../states/ducks/kpis';
import {
  locationActions,
  locationSelectors,
  locationTypes,
} from '../../../../states/ducks/locations';
import {
  Location,
  PropTypesLocation,
} from '../../../../states/ducks/locations/types';
import { Bar } from '@ant-design/charts';
import { useHistory } from 'react-router-dom';

interface IncidentsByMainLocationsChartProps {
  incidentsByMainLocations: kpiTypes.Kpi | null;
  countIncidentsByMainLocations: Function;
  locationsFromResults: Location[] | null;
  loadLocations: Function;
}

/**
 * IncidentsByMainLocationsChart component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const IncidentsByMainLocationsChart: FunctionComponent<IncidentsByMainLocationsChartProps> =
  ({
    incidentsByMainLocations,
    countIncidentsByMainLocations,
    locationsFromResults,
    loadLocations,
    ...props
  }): ReactElement => {
    const history = useHistory();

    useEffect((): void => {
      countIncidentsByMainLocations();
    }, [countIncidentsByMainLocations]);

    useEffect((): void => {
      loadLocations();
    }, [loadLocations]);

    const data =
      incidentsByMainLocations && incidentsByMainLocations.value
        ? incidentsByMainLocations.value.map((incident) => {
            const location = locationsFromResults
              ? locationsFromResults.find(
                  (location) => location.id === incident.locationId,
                )
              : null;

            return {
              locationId: incident.locationId,
              locationName: location ? location.name : '',
              value: incident.value,
            };
          })
        : [];

    const config = {
      data: data,
      xField: 'value',
      yField: 'locationName',
      yAxis: { label: { autoRotate: false } },
      scrollbar: { type: 'vertical' as const },
      tooltip: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (datum): any => {
          return { name: 'incidents', value: datum.value };
        },
      },
    };

    return (
      <Bar
        {...config}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onReady={(plot: any): void => {
          plot.on('element:click', (evt) => {
            history.push({
              pathname: '/incidents',
              state: { locationId: evt.data.data.locationId },
            });
          });
        }}
      />
    );
  };

IncidentsByMainLocationsChart.propTypes = {
  incidentsByMainLocations: kpiTypes.PropTypesKpi,
  countIncidentsByMainLocations: PropTypes.func.isRequired,
  locationsFromResults: PropTypes.arrayOf(PropTypesLocation.isRequired),
  loadLocations: PropTypes.func.isRequired,
};

interface MapStateToProps {
  incidentsByMainLocations: kpiTypes.Kpi | null;
  locationsFromResults: locationTypes.Location[] | null;
}

interface MapDispatchToProps {
  countIncidentsByMainLocations: Function;
  loadLocations: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const incidentsByMainLocations =
    kpiSelectors.incidentsByMainLocationsSelector(state);

  const locationsId =
    incidentsByMainLocations && incidentsByMainLocations.value
      ? incidentsByMainLocations.value.map(
          (incident): Location['id'] => incident.locationId,
        )
      : null;

  const locationsFromResults = locationsId
    ? locationSelectors.locationFromMultipleIdsSelector(state, locationsId)
    : null;

  return {
    incidentsByMainLocations: incidentsByMainLocations,
    locationsFromResults: locationsFromResults,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  countIncidentsByMainLocations:
    kpiActions.fetchCountIncidentsByMainLocationsActionCreator,
  loadLocations: locationActions.fetchListLocationsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentsByMainLocationsChart);
