import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import {
  getRefreshAuthenticationInfo,
  isConnected,
  refreshAuthentication,
  unauthentication,
  withAuthenticate,
} from '../../../../services/authenticate';
import { Authenticate } from '../../../../services/authenticate/types';
import Container from '../../layout/container/Container';

export interface AuthenticatedRouteProps extends RouteProps {
  authenticate: Authenticate;
}

const AuthenticatedRoute: FunctionComponent<AuthenticatedRouteProps> = (
  props,
): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRefresh] = useState(false);

  const refreshComponentAfterDelay = (): NodeJS.Timeout =>
    setTimeout(() => {
      setRefresh(true);
      setTimeout(() => setRefresh(false), 1000);
    }, 1000);

  if (isConnected()) {
    return (
      <Container>
        <Route {...props} />
      </Container>
    );
  }

  if (getRefreshAuthenticationInfo()) {
    refreshAuthentication()
      .then(refreshComponentAfterDelay)
      .catch((): void => {
        unauthentication().then(refreshComponentAfterDelay);
      });

    return <></>;
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};

AuthenticatedRoute.propTypes = {
  authenticate: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    authentication: PropTypes.func.isRequired,
    unauthentication: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAuthenticate(AuthenticatedRoute);
