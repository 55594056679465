import { createSelector } from 'reselect';
import { IncidentType, IncidentTypeState } from './types';
import { State } from '../../types';
import { currentUserOrganizationRootSelector } from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {IncidentTypeState}
 */
const incidentTypeStateSelector: (state: State) => IncidentTypeState = (
  state,
) => state.incidentTypesState;

/**
 * @param {State} state
 * @return {IncidentType[]}
 */
export const incidentTypesSelector = createSelector(
  incidentTypeStateSelector,
  (applicationState) => applicationState.incidentTypes,
);

/**
 * @param {State} state
 * @return {IncidentType[]}
 */
export const incidentTypesFromOrganizationSelector = createSelector(
  incidentTypesSelector,
  currentUserOrganizationRootSelector,
  (incidentTypes, organization) =>
    incidentTypes.filter(
      (incidentTypes) => incidentTypes.organizationId === organization.id,
    ),
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const incidentTypesIsGettingSelector = createSelector(
  incidentTypeStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {IncidentType|null}
 */
export const incidentTypeFromIdSelector = createSelector(
  incidentTypesSelector,
  (_: State, id: IncidentType['id']) => id,
  (incidentTypes, id) =>
    incidentTypes.find((incidentType) => incidentType.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {IncidentType[]}
 */
export const incidentTypesFromMultipleIdsSelector = createSelector(
  incidentTypesSelector,
  (_: State, ids: IncidentType['id'][]) => ids,
  (incidentTypes, ids) =>
    incidentTypes.filter((incidentType) => ids.includes(incidentType.id)),
);
