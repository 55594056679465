import { connect } from 'react-redux';
import { CurrentUser } from '../../../../states/ducks/currentUser/types';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import { requestActions } from '../../../../states/ducks/requests';
import { State } from '../../../../states/types';
import OpenRequest from '../../../components/layout/modal/request/OpenRequest';

interface MapStateToProps {
  currentUser: CurrentUser;
}

interface MapDispatchToProps {
  openRequest: Function;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  currentUser: currentUserSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  openRequest: requestActions.fetchOpenRequestsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenRequest);
