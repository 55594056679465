import React, { FunctionComponent, ReactElement } from 'react';
import { Drawer as DrawerAnt } from 'antd';
import 'antd/lib/drawer/style';
import { DrawerProps } from 'antd/lib/drawer';

/**
 * Drawer component
 * @param {any} props
 * @return {ReactElement}
 */
const Drawer: FunctionComponent<DrawerProps> = ({ ...props }): ReactElement => (
  <DrawerAnt destroyOnClose={true} closable={false} {...props} />
);

export default Drawer;
