import React, { FunctionComponent, ReactElement } from 'react';
import { Switch as SwitchAnt } from 'antd';
import 'antd/lib/switch/style';
import { SwitchProps } from 'antd/lib/switch';

/**
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Switch: FunctionComponent<SwitchProps> = ({ ...props }): ReactElement => (
  <SwitchAnt {...props} />
);

export default Switch;
