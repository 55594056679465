import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  kpiActions,
  kpiSelectors,
  kpiTypes,
} from '../../../../states/ducks/kpis';
import { Pie } from '@ant-design/charts';
import IncidentStatusUtils from '../../base/incident/IncidentStatusUtils';
import { useHistory } from 'react-router-dom';

interface IncidentsByStatusChartProps {
  incidentsByStatus: kpiTypes.Kpi | null;
  countIncidentsByStatus: Function;
}

/**
 * IncidentsByStatusChart component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const IncidentsByStatusChart: FunctionComponent<IncidentsByStatusChartProps> =
  ({ incidentsByStatus, countIncidentsByStatus, ...props }): ReactElement => {
    const history = useHistory();

    useEffect((): void => {
      countIncidentsByStatus();
    }, [countIncidentsByStatus]);

    const data =
      incidentsByStatus && incidentsByStatus.value
        ? incidentsByStatus.value
        : [];

    const config = {
      appendPadding: 10,
      data: data,
      angleField: 'value',
      colorField: 'status',
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v): string => {
            return v;
          },
        },
      },
      statistic: {
        title: {
          formatter: (data): string => {
            if (data === undefined) return 'Total';
            return IncidentStatusUtils.getLabel(data.status);
          },
        },
      },
      interactions: [{ type: 'pie-statistic-active' }],
      label: {
        type: 'inner',
        offset: '-50%',
        style: { textAlign: 'center' },
        autoRotate: false,
        content: '{value}',
      },
      legend: {
        itemName: {
          formatter: (text: string): string => {
            return IncidentStatusUtils.getLabel(text);
          },
        },
      },
      color: (type): string => IncidentStatusUtils.getColor(type.status),
    };

    return (
      <Pie
        {...config}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onReady={(plot: any): void => {
          plot.on('element:click', (evt) => {
            history.push({
              pathname: '/incidents',
              state: { status: evt.data.data.status },
            });
          });
        }}
      />
    );
  };

IncidentsByStatusChart.propTypes = {
  incidentsByStatus: kpiTypes.PropTypesKpi,
  countIncidentsByStatus: PropTypes.func.isRequired,
};

interface MapStateToProps {
  incidentsByStatus: kpiTypes.Kpi | null;
}

interface MapDispatchToProps {
  countIncidentsByStatus: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const incidentsByStatus = kpiSelectors.incidentsByStatusSelector(state);

  return {
    incidentsByStatus: incidentsByStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  countIncidentsByStatus: kpiActions.fetchCountIncidentsByStatusActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentsByStatusChart);
