import { connect } from 'react-redux';
import Location from '../../components/layout/location/Location';
import {
  locationTypeTypes,
  locationTypeSelectors,
  locationTypeActions,
} from '../../../states/ducks/locationtypes';
import { State } from '../../../states/types';

interface MapStateToProps {
  locationTypes: locationTypeTypes.LocationType[];
}

interface MapDispatchToProps {
  deleteLocationType: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const locationTypes =
    locationTypeSelectors.locationTypesFromOrganizationSelector(state);
  return {
    locationTypes,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  deleteLocationType: locationTypeActions.fetchDeleteLocationTypesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
