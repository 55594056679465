import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
} from 'react';
import {
  Card,
  Col,
  DataWithFilters,
  Empty,
  Filter,
  InputSearch,
  List,
  ListItem,
  Row,
  Skeleton,
  PageHeader,
  Text,
  PopoverActions,
  IncidentTypesSelect,
  OrganizationsTreeSelect,
  RestrictedToPermissions,
} from '../../base';
import { RouteComponentProps, withRouter } from 'react-router';
import { contractorTypes } from '../../../../states/ducks/contractors';
import { incidentTypeTypes } from '../../../../states/ducks/incidenttypes';
import PropTypes from 'prop-types';
import './Contractors.scss';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

interface Props extends RouteComponentProps {
  contractors: contractorTypes.Contractor[];
  incidentTypes: incidentTypeTypes.IncidentType[];
  loading: boolean;
  loadContractors: Function;
  deleteContractor: Function;
  descendantsIdByOrganizationId: Record<string, string[]>;
}

interface ListContractors {
  id: contractorTypes.Contractor['id'];
  key: contractorTypes.Contractor['id'];
  name: contractorTypes.Contractor['name'];
  organizationId: contractorTypes.Contractor['organizationId'];
  incidentTypeIds: contractorTypes.Contractor['incidentTypeIds'];
  email: contractorTypes.Contractor['email'];
}

const Contractors: FunctionComponent<Props> = ({
  contractors,
  incidentTypes,
  loading,
  loadContractors,
  deleteContractor,
  descendantsIdByOrganizationId,
  ...props
}): ReactElement => {
  useEffect((): void => {
    loadContractors();
  }, [loadContractors]);

  const listData = contractors.map(
    (contractor: contractorTypes.Contractor): ListContractors => {
      return {
        id: contractor.id,
        key: contractor.id,
        name: contractor.name,
        organizationId: contractor.organizationId,
        incidentTypeIds: contractor.incidentTypeIds,
        email: contractor.email,
      };
    },
  );

  const renderIncidentTypeList = (item: ListContractors): ReactElement =>
    item.incidentTypeIds ? (
      <ul className="app-contractors-incidenttypes-list">
        {item.incidentTypeIds.map((id): ReactElement => {
          const incidentType = incidentTypes
            .filter((incidentType): boolean => incidentType.id === id)
            .pop();

          return <li key={id}>{incidentType ? incidentType.name : ''}</li>;
        })}
      </ul>
    ) : (
      <Text className="app-contractors-incidenttypes-none">Aucun</Text>
    );

  const renderItem = (item: ListContractors): ReactElement => (
    <ListItem className="app-contractors-list-item">
      <Card
        className="app-contractors-card"
        hoverable
        onClick={(): void => props.history.push(`/contractors/${item.id}`)}
      >
        <Row>
          <Col span={10}>
            <Text bold>{item.name}</Text>
          </Col>
          <Col span={10}>
            <Text bold>{"Type d'incident pris en charge"}</Text>
            {renderIncidentTypeList(item)}
          </Col>
          <Col span={4} className="actions">
            <RestrictedToPermissions subject={'contractor'} action={'edit'}>
              <PopoverActions
                objectId={item.id}
                onDelete={deleteContractor}
                deleteConfirmMessage="Supprimer le prestataire ?"
                deleteDoneMessage="Le prestataire a bien été supprimé"
              />
            </RestrictedToPermissions>
          </Col>
        </Row>
      </Card>
    </ListItem>
  );

  return (
    <>
      <PageHeader title="Prestataires" />
      <Skeleton
        className="app-contractors-skeleton"
        loading={loading && !contractors.length}
        active
        title={false}
        avatar={true}
        paragraph={{
          rows: 4,
          width: '100%',
        }}
      >
        <DataWithFilters
          dataSource={listData}
          displayComponent={
            <List<ListContractors>
              className="app-contractors-list"
              grid={{
                xxl: 3,
                xl: 2,
                lg: 2,
                xs: 1,
                gutter: 16,
              }}
              pagination={{ pageSize: 15 }}
              locale={{ emptyText: <Empty /> }}
              renderItem={renderItem}
            />
          }
        >
          <Filter
            component={
              <InputSearch size="large" placeholder="Rechercher ..." />
            }
            filterName={'search'}
            filter={(
              value: string,
              record: { [key: string]: string },
            ): boolean => {
              const columns = ['name'];
              const fields: string[] = [];

              columns.forEach((fieldName: string): void => {
                fields.push(String(record[fieldName] || ''));
              });

              for (const field of fields) {
                if (field.search(new RegExp(value, 'i')) >= 0) {
                  return true;
                }
              }

              return false;
            }}
            onChangeFormatValue={(
              e: ChangeEvent<HTMLInputElement>,
            ): string[] => [e.currentTarget.value]}
          />
          <Filter
            component={
              <OrganizationsTreeSelect
                size="large"
                placeholder="Organisation"
              />
            }
            filterName={'organization'}
            filter={(value: string[], record: ListContractors): boolean =>
              value.includes(record.organizationId)
            }
            onChangeFormatValue={(value: string): string[] => [
              value,
              ...(descendantsIdByOrganizationId[value] || []),
            ]}
          />
          <Filter
            component={
              <IncidentTypesSelect size="large" isFilter placeholder={'Type'} />
            }
            filterName={'incidentType'}
            filter={(values: string[], record: ListContractors): boolean => {
              const incidentTypeIds = record.incidentTypeIds;

              if (!incidentTypeIds) {
                return false;
              }

              return values.some((value): boolean =>
                incidentTypeIds.includes(value),
              );
            }}
          />
        </DataWithFilters>
      </Skeleton>
    </>
  );
};

Contractors.propTypes = {
  contractors: PropTypes.arrayOf(contractorTypes.PropTypesContractor.isRequired)
    .isRequired,
  incidentTypes: PropTypes.arrayOf(
    incidentTypeTypes.PropTypesIncidentType.isRequired,
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  loadContractors: PropTypes.func.isRequired,
  deleteContractor: PropTypes.func.isRequired,
  descendantsIdByOrganizationId: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  history: PropTypes.exact(historyPropTypes).isRequired,
};

export default withRouter(Contractors);
