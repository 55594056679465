import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  historyActions,
  historySelectors,
  historyTypes,
} from '../../../../states/ducks/histories';
import { History, Empty } from '../index';
import './Histories.scss';

interface HistoriesProps {
  objectType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
  histories: historyTypes.History[];
  loadHistories?: Function;
  title?: string;
}

/**
 * @param {string} objectType
 * @param {any} object
 * @param {History[]} histories
 * @param {Function} loadHistories
 * @param {string} title
 *
 * @return {ReactElement}
 */
const Histories: FunctionComponent<HistoriesProps> = ({
  objectType,
  object,
  histories,
  loadHistories,
  title,
}): ReactElement => {
  useEffect((): void => {
    if (loadHistories && object) {
      loadHistories(objectType, object.id);
    }
  }, [loadHistories, objectType, object]);

  return (
    <div className={'section app-histories'}>
      {histories.length > 0 ? (
        histories.map(
          (history): ReactElement => (
            <History key={history.id} history={history} />
          ),
        )
      ) : (
        <Empty description={'Aucun historique'} />
      )}
    </div>
  );
};

Histories.propTypes = {
  objectType: PropTypes.string.isRequired,
  object: PropTypes.any,
  histories: PropTypes.arrayOf(historyTypes.PropTypesHistory.isRequired)
    .isRequired,
  loadHistories: PropTypes.func,
  title: PropTypes.string,
};

interface MapStateToProps {
  objectType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
  histories: historyTypes.History[];
}

interface MapDispatchToProps {
  loadHistories: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    objectType: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object: any;
  },
): MapStateToProps => {
  let histories: historyTypes.History[] = [];
  if (props.object && props.objectType === 'incident') {
    histories = historySelectors.historiesFromIncidentIdSelector(
      state,
      props.object.id,
    );
  }

  if (props.object && props.objectType === 'request') {
    histories = historySelectors.historiesFromRequestIdSelector(
      state,
      props.object.id,
    );
  }

  return {
    objectType: props.objectType,
    object: props.object,
    histories: histories,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  loadHistories: historyActions.fetchListHistoriesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
