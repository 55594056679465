import { create, get, patch, remove } from '../base';
import { contractorTypes } from '../../../states/ducks/contractors';

/**
 * @param {Contractor} contractor
 * @return {Promise<string>}
 */
export const addContractor = async (
  contractor: Partial<contractorTypes.Contractor>,
): Promise<contractorTypes.Contractor['id']> =>
  await create(`/v1/contractors/create`, contractor);

/**
 * @return {Promise<Contractor[]>}
 */
export const listContractors = async (): Promise<
  contractorTypes.Contractor[]
> => await get(`/v1/contractors/list`);

/**
 * @param {string} id
 * @return {Contractor}
 */
export const getContractor = async (
  id: contractorTypes.Contractor['id'],
): Promise<contractorTypes.Contractor> => await get(`/v1/contractors/${id}`);

/**
 * @param {Contractor} contractor
 * @return {Promise<string>}
 */
export const editContractorName = async (
  contractor: Partial<contractorTypes.Contractor>,
): Promise<string> => {
  const { id, name } = contractor;

  return await patch(`/v1/contractors/rename/${id}`, {
    name,
  });
};

/**
 * @param {Contractor} contractor
 * @return {Promise<string>}
 */
export const editContractorEmail = async (
  contractor: Partial<contractorTypes.Contractor>,
): Promise<string> => {
  const { id, email } = contractor;

  return await patch(`/v1/contractors/changeEmail/${id}`, {
    email,
  });
};

/**
 * @param {Contractor} contractor
 * @return {Promise<string>}
 */
export const editContractorTwimmLink = async (
  contractor: Partial<contractorTypes.Contractor>,
): Promise<string> => {
  const { id, useTwimm, instance, sendInterventionRequest } = contractor;
  return await patch(`/v1/contractors/updateTwimmLink/${id}`, {
    useTwimm,
    instance,
    sendInterventionRequest,
  });
};

/**
 * @param {Contractor} contractor
 * @return {Promise<string>}
 */
export const editContractorIncidentTypes = async (
  contractor: Partial<contractorTypes.Contractor>,
): Promise<string> => {
  const { id, incidentTypeIds } = contractor;

  return await patch(`/v1/contractors/changeIncidentTypesHandled/${id}`, {
    incidentTypeIds,
  });
};

/**
 * @param {Contractor} contractor
 * @return {Promise<string>}
 */
export const editContractorOrganization = async (
  contractor: Partial<contractorTypes.Contractor>,
): Promise<string> => {
  const { id, organizationId } = contractor;

  return await patch(`/v1/contractors/changeOrganization/${id}`, {
    organizationId,
  });
};

/**
 * @param {string} id
 * @return {Promise<string>}
 */
export const deleteContractor = async (
  id: contractorTypes.Contractor['id'],
): Promise<string> => await remove(`/v1/contractors/delete/${id}`);

/**
 * @param {Contractor} contractor
 * @param {string[]} fileIds
 * @return {Promise<string>}
 */
export const addFilesContractor = async (
  contractor: Partial<contractorTypes.Contractor>,
  fileIds: string[],
): Promise<string> => {
  return await patch(`/v1/contractors/addFiles/${contractor.id}`, {
    fileIds,
  });
};

/**
 * @param {Contractor} contractor
 * @param {string} fileId
 * @return {Promise<string>}
 */
export const removeFileContractor = async (
  contractor: Partial<contractorTypes.Contractor>,
  fileId: string,
): Promise<string> => {
  const { id } = contractor;

  return await patch(`/v1/contractors/removeFile/${id}`, {
    fileId,
  });
};
