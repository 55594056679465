import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';

import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  addIncidentType,
  listIncidentTypes,
  getIncidentType,
  editIncidentTypeDesignation,
  deleteIncidentType,
} from '../../../services/api';
import { getActionType } from '../common/types';

/**
 * @param {FetchAddRequested} action
 */
export function* addIncidentTypeSaga(
  action: FetchAddRequested<types.IncidentType>,
): SagaIterator {
  try {
    const id: types.IncidentType['id'] = yield call(
      addIncidentType,
      action.payload,
    );

    const incidentType = {
      ...action.payload,
      id,
    } as types.IncidentType;

    yield put(actions.fetchAddIncidentTypesSuccessActionCreator(incidentType));

    action.meta.resolve(incidentType);
  } catch (err) {
    yield put(actions.fetchAddIncidentTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* fetchIncidentTypeSaga(): SagaIterator {
  try {
    const incidentTypes: types.IncidentType[] = yield call(listIncidentTypes);

    yield put(
      actions.fetchListIncidentTypesSuccessActionCreator(incidentTypes),
    );
  } catch (err) {
    yield put(actions.fetchListIncidentTypesErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getIncidentTypeSaga(
  action: FetchGetRequested<types.IncidentType>,
): SagaIterator {
  try {
    const incidentType: types.IncidentType = yield call(
      getIncidentType,
      action.payload,
    );

    yield put(actions.fetchGetIncidentTypesSuccessActionCreator(incidentType));

    action.meta.resolve(incidentType);
  } catch (err) {
    yield put(actions.fetchGetIncidentTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editIncidentTypeDesignationSaga(
  action: FetchEditRequested<types.IncidentType>,
): SagaIterator {
  try {
    yield call(editIncidentTypeDesignation, action.payload);

    yield put(
      actions.fetchEditIncidentTypesSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditIncidentTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteIncidentTypeSaga(
  action: FetchDeleteRequested<types.IncidentType>,
): SagaIterator {
  try {
    yield call(deleteIncidentType, action.payload);

    yield put(
      actions.fetchDeleteIncidentTypesSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteIncidentTypesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* incidentTypesSagas(): SagaIterator {
  yield takeEvery(
    getActionType('incidenttypes', 'ADD_REQUESTED'),
    addIncidentTypeSaga,
  );
  yield takeEvery(
    getActionType('incidenttypes', 'LIST_REQUESTED'),
    fetchIncidentTypeSaga,
  );
  yield takeEvery(
    getActionType('incidenttypes', 'GET_REQUESTED'),
    getIncidentTypeSaga,
  );
  yield takeEvery(
    getActionType('incidenttypes', 'EDIT_DESIGNATION_REQUESTED'),
    editIncidentTypeDesignationSaga,
  );
  yield takeEvery(
    getActionType('incidenttypes', 'DELETE_REQUESTED'),
    deleteIncidentTypeSaga,
  );
}
