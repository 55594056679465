import React, { FunctionComponent, ReactElement } from 'react';
import { PageHeader, Card, Row, Col, Title } from '../../base';
import IncidentsByStatusChart from './IncidentsByStatusChart';
import IncidentsByMonthChart from './IncidentsByMonthChart';
import IncidentsByMainLocationsChart from './IncidentsByMainLocationsChart';
import RequestsByMonthChart from './RequestsByMonthChart';
import RequestsByManagerChart from './RequestsByManagerChart';
import { withRouter } from 'react-router';

const Dashboard: FunctionComponent = (): ReactElement => {
  return (
    <>
      <PageHeader title="Dashboard" />
      <Title level={5} className="card-title">
        Incidents
      </Title>
      <Row gutter={[16, 16]} className="mt-40">
        <Col xl={8} lg={12} md={24}>
          <Card title="Répartition par statut" className="dashboard">
            <IncidentsByStatusChart />
          </Card>
        </Col>
        <Col xl={8} lg={12} md={24}>
          <Card title="Incidents signalés / résolus" className="dashboard">
            <IncidentsByMonthChart />
          </Card>
        </Col>
        <Col xl={8} md={24}>
          <Card
            title='Nombre d’incidents par "nom du type location principal"'
            className="dashboard"
          >
            <IncidentsByMainLocationsChart />
          </Card>
        </Col>
      </Row>
      <Title level={5} className="card-title">
        Demandes
      </Title>
      <Row gutter={[16, 16]} className="mt-40">
        <Col xl={16} lg={12} md={24}>
          <Card title="Demandes ouvertes / fermées" className="dashboard">
            <RequestsByMonthChart />
          </Card>
        </Col>
        <Col xl={8} lg={12} md={24}>
          <Card title="Répartition par responsable" className="dashboard">
            <RequestsByManagerChart />
          </Card>
        </Col>
      </Row>
    </>
  );
};

Dashboard.propTypes = {};

export default withRouter(Dashboard);
