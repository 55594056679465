import { State } from '../../../states/types';
import { connect } from 'react-redux';
import LocationTypeInfo from '../../components/layout/location/LocationTypeInfo';
import {
  locationTypeActions,
  locationTypeSelectors,
  locationTypeTypes,
} from '../../../states/ducks/locationtypes';
import React, { ReactElement } from 'react';
import { withRouter } from 'react-router';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface MapStateToProps {
  locationType: locationTypeTypes.LocationType | null;
  mainLocationType: locationTypeTypes.LocationType | null;
  isGettingLocationType: boolean;
}

interface MapDispatchToProps {
  getLocationType: Function;
  editLocationTypeDesignation: Function;
  editMainLocationType: Function;
  editQuickAccessLocationType: Function;
  deleteLocationType: Function;
}

const mapStateToProps = (
  state: State,
  props: { locationTypeId: locationTypeTypes.LocationType['id'] },
): MapStateToProps => {
  return {
    locationType: locationTypeSelectors.locationTypeFromIdSelector(
      state,
      props.locationTypeId,
    ),
    mainLocationType:
      locationTypeSelectors.mainLocationTypeSelector(state) || null,
    isGettingLocationType:
      locationTypeSelectors.locationTypesIsGettingSelector(state),
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getLocationType: locationTypeActions.fetchGetLocationTypesActionCreator,
  editLocationTypeDesignation:
    locationTypeActions.fetchEditLocationTypesDesignationActionCreator,
  editMainLocationType:
    locationTypeActions.fetchEditMainLocationTypesActionCreator,
  editQuickAccessLocationType:
    locationTypeActions.fetchEditQuickAccessLocationTypesActionCreator,
  deleteLocationType: locationTypeActions.fetchDeleteLocationTypesActionCreator,
};

const LocationTypeInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationTypeInfo);

export default withRouter(
  (props: Props): ReactElement => (
    <LocationTypeInfoContainer locationTypeId={props.match.params.id} />
  ),
);
