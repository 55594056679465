import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  requestActions,
  requestSelectors,
} from '../../../states/ducks/requests';
import { Request } from '../../../states/ducks/requests/types';
import { State } from '../../../states/types';
import RequestInfo from '../../components/layout/requests/RequestInfo';
import { File } from '../../../states/ducks/files/types';
import { fetchGetFilesActionCreator } from '../../../states/ducks/files/actions';
import { chronologicalSortBy } from '../../../utils/SortUtils';
import { filesFromMultipleIdsSelector } from '../../../states/ducks/files/selectors';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface MapStateToProps {
  isGettingRequest: boolean;
  request: Request | null;
  files: File[];
}

interface MapDispatchToProps {
  closeRequest: Function;
  deleteRequest: Function;
  editRequest: Function;
  editCreatorRequest: Function;
  editManagerRequest: Function;
  editOrganizationRequest: Function;
  getRequest: Function;
  getFile: Function;
  addFileRequest: Function;
  addGEDFileRequest: Function;
  deleteFileRequest: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    requestId: Request['id'];
  },
): MapStateToProps => {
  const request = requestSelectors.requestFromIdSelector(
    state,
    props.requestId,
  );

  const allFiles =
    request && request.fileIds
      ? filesFromMultipleIdsSelector(state, request.fileIds)
      : [];

  allFiles.sort((a, b) => chronologicalSortBy(a.uploadedAt, b.uploadedAt));

  const files = allFiles.filter(
    (file) => !file.fileMimeType?.includes('image/'),
  );

  return {
    isGettingRequest: requestSelectors.requestsIsGettingSelector(state),
    request: request,
    files: files,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  closeRequest: requestActions.fetchCloseRequestsActionCreator,
  deleteRequest: requestActions.fetchDeleteRequestsActionCreator,
  editRequest: requestActions.fetchEditRequestsActionCreator,
  editCreatorRequest: requestActions.fetchEditRequestsCreatorActionCreator,
  editManagerRequest: requestActions.fetchEditRequestsManagerActionCreator,
  editOrganizationRequest:
    requestActions.fetchEditRequestsOrganizationActionCreator,
  getRequest: requestActions.fetchGetRequestsActionCreator,
  getFile: fetchGetFilesActionCreator,
  addFileRequest: requestActions.fetchAddFileRequestsActionCreator,
  addGEDFileRequest: requestActions.fetchAddGEDFileRequestsActionCreator,
  deleteFileRequest: requestActions.fetchDeleteFileRequestsActionCreator,
};

const RequestInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestInfo);

export default withRouter(
  (props: Props): ReactElement => (
    <RequestInfoContainer requestId={props.match.params.id} />
  ),
);
