import { connect } from 'react-redux';
import User from '../../components/layout/users/User';
import {
  roleTypes,
  roleSelectors,
  roleActions,
} from '../../../states/ducks/roles';
import { State } from '../../../states/types';

interface MapStateToProps {
  roles: roleTypes.Role[];
}

interface MapDispatchToProps {
  deleteRole: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const roles = roleSelectors.rolesFromOrganizationSelector(state);
  return {
    roles,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  deleteRole: roleActions.fetchDeleteRolesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
