import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { userTypes } from '../users';
import { getActionType } from '../common/types';

/**
 * @param {Subscription} subscription
 * @return {FetchAddRequested}
 */
export const fetchSubscribeActionCreator = createAction(
  getActionType('subscriptions', 'SUBSCRIBE_REQUESTED'),
  (subscription: types.Subscription): types.Subscription => subscription,
  (subscription, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Subscription} subscription
 * @return {FetchAddSucceeded}
 */
export const fetchSubscribeSuccessActionCreator = createAction(
  getActionType('subscriptions', 'SUBSCRIBE_SUCCEEDED'),
  (subscription: types.Subscription): types.Subscription => subscription,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchSubscribeErrorActionCreator = createAction(
  getActionType('subscriptions', 'SUBSCRIBE_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @return {FetchListByDependencyRequested}
 */
export const fetchListSubscriptionsActionCreator = createAction(
  getActionType('subscriptions', 'LIST_REQUESTED'),
  (id: userTypes.User['id']): userTypes.User['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Subscription[]} subscriptions
 * @return {FetchListSucceeded}
 */
export const fetchListSubscriptionsSuccessActionCreator = createAction(
  getActionType('subscriptions', 'LIST_SUCCEEDED'),
  (subscriptions: types.Subscription[]): types.Subscription[] => subscriptions,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListSubscriptionsErrorActionCreator = createAction(
  getActionType('subscriptions', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @return {FetchDeleteRequested}
 */
export const fetchUnsubscribeActionCreator = createAction(
  getActionType('subscriptions', 'UNSUBSCRIBE_REQUESTED'),
  (id: types.Subscription['id']): types.Subscription['id'] => id,
  (subscription, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchUnsubscribeSuccessActionCreator = createAction(
  getActionType('subscriptions', 'UNSUBSCRIBE_SUCCEEDED'),
  (id: types.Subscription['id']): types.Subscription['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchUnsubscribeErrorActionCreator = createAction(
  getActionType('subscriptions', 'UNSUBSCRIBE_FAILED'),
  (message: string): string => message,
);
