import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import * as actions from './actions';
import * as types from './types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  addRole,
  listRoles,
  getRole,
  deleteRole,
  editRoleName,
  editRoleDescription,
  editRoleStyle,
  editRoleAddPermission,
  editRoleRemovePermission,
  getPermissionsForRole,
} from '../../../services/api';
import { getActionType } from '../common/types';
import { Permission } from '../permissions/types';

/**
 * @param {FetchAddRequested} action
 * @yields {SagaIterator}
 */
export function* addRoleSaga(
  action: FetchAddRequested<types.Role>,
): SagaIterator {
  try {
    const id: types.Role['id'] = yield call(addRole, action.payload);

    const role = {
      ...action.payload,
      id,
    } as types.Role;

    if (role.permissions) {
      for (let i = 0; i < role.permissions.length; i++) {
        const payload = {
          role: role,
          permission: role.permissions[i],
        };
        yield call(editRoleAddPermission, payload);
      }
    }

    yield put(actions.fetchAddRolesSuccessActionCreator(role));

    action.meta.resolve(role);
  } catch (err) {
    yield put(actions.fetchAddRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* fetchRoleSaga(): SagaIterator {
  try {
    const roles: types.Role[] = yield call(listRoles);

    for (const role of roles) {
      role.permissions = yield call(getPermissionsForRole, role);
    }

    yield put(actions.fetchListRolesSuccessActionCreator(roles));
  } catch (err) {
    yield put(actions.fetchListRolesErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 * @yields {SagaIterator}
 */
export function* getRoleSaga(
  action: FetchGetRequested<types.Role>,
): SagaIterator {
  try {
    const role: types.Role = yield call(getRole, action.payload);

    role.permissions = yield call(getPermissionsForRole, role);

    yield put(actions.fetchGetRolesSuccessActionCreator(role));

    action.meta.resolve(role);
  } catch (err) {
    yield put(actions.fetchGetRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editRoleNameSaga(
  action: FetchEditRequested<types.Role>,
): SagaIterator {
  try {
    yield call(editRoleName, action.payload);

    yield put(actions.fetchEditRolesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editRoleDescriptionSaga(
  action: FetchEditRequested<types.Role>,
): SagaIterator {
  try {
    yield call(editRoleDescription, action.payload);

    yield put(actions.fetchEditRolesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editRoleStyleSaga(
  action: FetchEditRequested<types.Role>,
): SagaIterator {
  try {
    yield call(editRoleStyle, action.payload);

    yield put(actions.fetchEditRolesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editRoleAddPermissionSaga(
  action: FetchEditRequested<{
    role: types.Role;
    permission: Permission | Permission[];
  }>,
): SagaIterator {
  try {
    if (Array.isArray(action.payload.permission)) {
      for (let i = 0; i < action.payload.permission.length; i++) {
        const payload = {
          role: action.payload.role,
          permission: action.payload.permission[i],
        };
        yield call(editRoleAddPermission, payload);
      }
    } else {
      const payload = {
        role: action.payload.role,
        permission: action.payload.permission,
      };
      yield call(editRoleAddPermission, payload);
    }

    yield put(actions.fetchEditRolesSuccessActionCreator(action.payload.role));

    action.meta.resolve(action.payload.role);
  } catch (err) {
    yield put(actions.fetchEditRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editRoleRemovePermissionSaga(
  action: FetchEditRequested<{
    role: types.Role;
    permission: Permission | Permission[];
  }>,
): SagaIterator {
  try {
    if (Array.isArray(action.payload.permission)) {
      for (let i = 0; i < action.payload.permission.length; i++) {
        const payload = {
          role: action.payload.role,
          permission: action.payload.permission[i],
        };
        yield call(editRoleRemovePermission, payload);
      }
    } else {
      const payload = {
        role: action.payload.role,
        permission: action.payload.permission,
      };
      yield call(editRoleRemovePermission, payload);
    }

    yield put(actions.fetchEditRolesSuccessActionCreator(action.payload.role));

    action.meta.resolve(action.payload.role);
  } catch (err) {
    yield put(actions.fetchEditRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 * @yields {SagaIterator}
 */
export function* deleteRoleSaga(
  action: FetchDeleteRequested<types.Role>,
): SagaIterator {
  try {
    yield call(deleteRole, action.payload);

    yield put(actions.fetchDeleteRolesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteRolesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* rolesSagas(): SagaIterator {
  yield takeEvery(getActionType('roles', 'ADD_REQUESTED'), addRoleSaga);
  yield takeEvery(getActionType('roles', 'LIST_REQUESTED'), fetchRoleSaga);
  yield takeEvery(getActionType('roles', 'GET_REQUESTED'), getRoleSaga);
  yield takeEvery(
    getActionType('roles', 'EDIT_NAME_REQUESTED'),
    editRoleNameSaga,
  );
  yield takeEvery(
    getActionType('roles', 'EDIT_DESCRIPTION_REQUESTED'),
    editRoleDescriptionSaga,
  );
  yield takeEvery(
    getActionType('roles', 'EDIT_STYLE_REQUESTED'),
    editRoleStyleSaga,
  );
  yield takeEvery(
    getActionType('roles', 'EDIT_ADD_PERMISSION_REQUESTED'),
    editRoleAddPermissionSaga,
  );
  yield takeEvery(
    getActionType('roles', 'EDIT_REMOVE_PERMISSION_REQUESTED'),
    editRoleRemovePermissionSaga,
  );
  yield takeEvery(getActionType('roles', 'DELETE_REQUESTED'), deleteRoleSaga);
}
