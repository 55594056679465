import React, { FunctionComponent, ReactElement } from 'react';
import 'antd/lib/tag/style';
import Tag from '../tag/Tag';
import PropTypes from 'prop-types';
import IncidentStatusUtils from './IncidentStatusUtils';
import './IncidentStatusTag.scss';

interface Props {
  status: string;
}

/**
 * Incident Status Tag component
 * @param {any} props empty props
 * @return {ReactElement} Col element
 */
const IncidentStatusTag: FunctionComponent<Props> = ({
  status,
}): ReactElement => (
  <Tag className={`incident-status ${status}`}>
    {IncidentStatusUtils.getLabel(status)}
  </Tag>
);

IncidentStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default IncidentStatusTag;
