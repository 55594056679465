/**
 * @param {Object[]} state
 * @param {Object[]} incomings
 * @return {Object[]}
 */
export function rehydrate<
  T extends {
    id: string;
  },
>(state: T[], incomings: T[]): T[] {
  return [
    ...state.map((stateItem: T): T => {
      let index = incomings.findIndex(
        (inc: T): boolean => inc.id === stateItem.id,
      );
      if (index >= 0) {
        const incomingItem = incomings[index];

        while (index >= 0) {
          incomings = [
            ...incomings.slice(0, index),
            ...incomings.slice(index + 1, incomings.length),
          ];

          index = incomings.findIndex(
            (inc: T): boolean => inc.id === stateItem.id,
          );
        }
        return {
          ...stateItem,
          ...incomingItem,
        };
      }
      return stateItem;
    }),
    ...incomings,
  ];
}

/**
 * @param {T} item
 * @return {T}
 */
export function flatItem<
  T extends {
    id: string;
    parents?: T[];
    parentId?: string | null;
    children?: T[];
  },
>(item: T): T {
  const returnValue = {
    ...item,
  };

  if (typeof item.parents !== 'undefined' && item.parents.length) {
    returnValue.parentId = item.parents[0].id;
  }

  delete returnValue.parents;
  delete returnValue.children;

  return returnValue;
}

/**
 * @param {T[]} items
 * @return {T[]}
 */
export function flatiItems<
  T extends {
    id: string;
    parents?: T[];
    parentId?: string | null;
    children?: T[];
  },
>(items: T[]): T[] {
  const refreshFamily = [] as T[];

  items.forEach((organization: T): void => {
    if (typeof organization.parents !== 'undefined') {
      organization.parents.forEach((parent: T): number =>
        refreshFamily.push(parent),
      );
    }

    if (typeof organization.children !== 'undefined') {
      organization.children.forEach((children: T): number =>
        refreshFamily.push({ ...children, parentId: organization.id }),
      );
    }
  });

  return rehydrate<T>(items.map<T>(flatItem), refreshFamily);
}
