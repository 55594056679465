import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useReducer,
} from 'react';
import {
  PageHeader,
  Button,
  DataWithFilters,
  Filter,
  InputSearch,
  OrganizationsTreeSelect,
  Table,
  PopoverActions,
  RestrictedToPermissions,
} from '../../base';
import { locationTypes } from '../../../../states/ducks/locations';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { organizationTypes } from '../../../../states/ducks/organizations';
import AddLocation from '../../../containers/modal/location/AddLocation';
import { alphabeticalSortBy } from '../../../../utils/SortUtils';
import { LocationType } from '../../../../states/ducks/locationtypes/types';
// @ts-ignore
import plural from 'pluralize-fr';

interface Props {
  locationTypeId?: LocationType['id'];
  locations: locationTypes.Location[];
  organizationsFromResults: organizationTypes.Organization[];
  locationTypeName: string;
  loadLocations: Function;
  descendantsIdByOrganizationId: Record<string, string[]>;
  deleteLocation: Function;
}

interface TableLocation {
  id: locationTypes.Location['id'];
  key: locationTypes.Location['id'];
  name: locationTypes.Location['name'];
  mainAddress: locationTypes.AddressComponents['formattedAddress'];
  additionalAddress: locationTypes.AddressComponents['additionalAddress'];
  organizationId: organizationTypes.Organization['id'];
  organizationName: organizationTypes.Organization['name'];
  action: object;
}

const address = (text: string, record: TableLocation): ReactElement => (
  <span>
    <div className="main-address">{record.mainAddress}</div>
    <div className="additional-address">{record.additionalAddress}</div>
  </span>
);

interface ReducerState {
  add: boolean;
  locationId: locationTypes.Location['id'] | null;
  link: boolean;
}
interface ReducerAction {
  type: 'OPEN' | 'CLOSE' | 'GO_TO_SITE';
  payload?: {
    id: locationTypes.Location['id'] | null;
  };
}

const initialState: ReducerState = {
  add: false,
  locationId: null,
  link: false,
};

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        add: true,
      };
    case 'CLOSE':
      return {
        ...state,
        add: false,
      };
    case 'GO_TO_SITE':
      return {
        ...state,
        locationId: action.payload ? action.payload.id : null,
        link: true,
      };
    default:
      throw new Error();
  }
};

const Locations: FunctionComponent<Props> = ({
  locationTypeId,
  locations,
  loadLocations,
  organizationsFromResults,
  locationTypeName,
  descendantsIdByOrganizationId,
  deleteLocation,
}): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect((): void => {
    loadLocations();
  }, [loadLocations]);

  if (state.link) {
    return (
      <Redirect
        to={{
          pathname: locationTypeId
            ? `/locations/type/${locationTypeId}/${state.locationId}`
            : `/locations/${state.locationId}`,
        }}
      />
    );
  }

  const columns: ColumnProps<TableLocation>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a: TableLocation, b: TableLocation): number =>
        alphabeticalSortBy(a.name, b.name),
    },
    {
      key: 'organizationName',
      title: 'Organisation',
      dataIndex: 'organizationName',
      sorter: (a: TableLocation, b: TableLocation): number =>
        alphabeticalSortBy(a.organizationName, b.organizationName),
    },
    {
      key: 'address',
      title: 'Adresse',
      dataIndex: 'mainAddress',
      render: address,
      sorter: (a: TableLocation, b: TableLocation): number =>
        alphabeticalSortBy(
          a.mainAddress || undefined,
          b.mainAddress || undefined,
        ),
    },
    {
      key: 'operation',
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableLocation): ReactElement => {
        return (
          <PopoverActions
            objectId={record.id}
            onDelete={deleteLocation}
            deleteConfirmMessage={
              `Supprimer le ${locationTypeName} ? ` +
              '(Attention les lieux rattachés ' +
              'à la structure seront supprimés)'
            }
            deleteDoneMessage={`Le ${locationTypeName} a bien été supprimé`}
            size="small"
          />
        );
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: TableLocation): any => ({
    onClick: (): void => {
      dispatch({ type: 'GO_TO_SITE', payload: { id: record.id } });
    },
  });

  const datas = locations.map(
    (location: locationTypes.Location): TableLocation => {
      const organizationName = organizationsFromResults.find(
        (organization: organizationTypes.Organization): boolean =>
          organization.id === location.organizationId || false,
      );

      return {
        id: location.id,
        key: location.id,
        name: location.code
          ? `[${location.code}] ${location.name}`
          : location.name,
        mainAddress: location.addressComponents?.formattedAddress || '',
        additionalAddress: location.addressComponents?.additionalAddress || '',
        organizationId: location.organizationId || '',
        organizationName: organizationName ? organizationName.name : '',
        action: {
          id: location.id,
          locationName: location ? location.name : '',
        },
      };
    },
  );

  const emptyText = (
    <Button
      type="link"
      onClick={(): void => {
        dispatch({ type: 'OPEN' });
      }}
    >
      Créer un {locationTypeName}
    </Button>
  );

  return (
    <RestrictedToPermissions subject={'location'} action={'list'}>
      <PageHeader title={`Liste des ${plural(locationTypeName)}`} />
      <DataWithFilters
        dataSource={datas}
        displayComponent={
          <Table columns={columns} onRow={onRow} locale={{ emptyText }} />
        }
        displayInCard
      >
        <Filter
          component={
            <InputSearch
              size="large"
              placeholder="Rechercher par nom, adresse, ..."
            />
          }
          filterName={'search'}
          filter={(
            value: string,
            record: { [key: string]: string },
          ): boolean => {
            const columns = ['name', 'mainAddress'];
            const fields: string[] = [];

            columns.forEach((fieldName: string): void => {
              fields.push(String(record[fieldName] || ''));
            });

            for (const field of fields) {
              if (field.search(new RegExp(value, 'i')) >= 0) {
                return true;
              }
            }

            return false;
          }}
          onChangeFormatValue={(e: ChangeEvent<HTMLInputElement>): string[] => [
            e.currentTarget.value,
          ]}
        />
        <Filter
          component={
            <OrganizationsTreeSelect size="large" placeholder="Organisation" />
          }
          filterName={'organization'}
          filter={(value: string[], record: TableLocation): boolean =>
            value.includes(record.organizationId)
          }
          onChangeFormatValue={(value: string): string[] => [
            value,
            ...(descendantsIdByOrganizationId[value] || []),
          ]}
        />
      </DataWithFilters>
      <AddLocation
        visible={state.add}
        hideModal={(): void => dispatch({ type: 'CLOSE' })}
      />
    </RestrictedToPermissions>
  );
};

Locations.propTypes = {
  locationTypeId: PropTypes.string,
  locations: PropTypes.arrayOf(locationTypes.PropTypesLocation.isRequired)
    .isRequired,
  organizationsFromResults: PropTypes.arrayOf(
    organizationTypes.PropTypesOrganization.isRequired,
  ).isRequired,
  locationTypeName: PropTypes.string.isRequired,
  loadLocations: PropTypes.func.isRequired,
  descendantsIdByOrganizationId: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  deleteLocation: PropTypes.func.isRequired,
};

export default Locations;
