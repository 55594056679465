import { createSelector } from 'reselect';
import { Tag, TagState } from './types';
import { State } from '../../types';
import { currentUserOrganizationRootSelector } from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {TagState}
 */
const tagStateSelector: (state: State) => TagState = (state) => state.tagsState;

/**
 * @param {State} state
 * @return {Tag[]}
 */
export const tagsSelector = createSelector(
  tagStateSelector,
  (applicationState) => applicationState.tags,
);

/**
 * @param {State} state
 * @return {Request[]}
 */
export const tagsFromOrganizationSelector = createSelector(
  tagsSelector,
  currentUserOrganizationRootSelector,
  (tags, organization) =>
    tags.filter((tags) => tags.organizationId === organization.id),
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const tagsIsGettingSelector = createSelector(
  tagStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const tagsIsListingSelector = createSelector(
  tagStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Tag|null}
 */
export const tagFromIdSelector = createSelector(
  tagsSelector,
  (_: State, id: Tag['id']) => id,
  (tags, id) => tags.find((tag) => tag.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Tag[]}
 */
export const tagsFromMultipleIdsSelector = createSelector(
  tagsSelector,
  (_: State, ids: Tag['id'][]) => ids,
  (tags, ids) => tags.filter((tag) => ids.includes(tag.id)),
);
