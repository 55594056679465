import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  addMessage,
  deleteMessage,
  editMessage,
  getMessage,
  listMessages,
  replyToMessage,
} from '../../../services/api';
import {
  FetchAddRequested,
  FetchEditRequested,
  FetchDeleteRequested,
  FetchGetRequested,
  FetchListAgnosticallyRequested,
} from '../common/actions';
import { getActionType } from '../common/types';
import * as actions from './actions';
import { Message } from './types';

/**
 * @param {FetchAddRequested} action
 * @yields {SagaIterator}
 */
export function* addMessageSaga(
  action: FetchAddRequested<Message>,
): SagaIterator {
  try {
    const id: Message['id'] = yield call(addMessage, action.payload);

    const message = {
      ...action.payload,
      id,
    } as Message;

    yield put(actions.fetchAddMessagesSuccessActionCreator(message));

    action.meta.resolve(message);
  } catch (err) {
    yield put(actions.fetchAddMessagesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchAddRequested} action
 * @yields {SagaIterator}
 */
export function* replyToMessageSaga(
  action: FetchAddRequested<Message>,
): SagaIterator {
  try {
    const id: Message['id'] = yield call(replyToMessage, action.payload);

    const message = {
      ...action.payload,
      id,
    } as Message;

    yield put(actions.fetchAddMessagesSuccessActionCreator(message));

    action.meta.resolve(message);
  } catch (err) {
    yield put(actions.fetchAddMessagesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchListAgnosticallyRequested} action
 */
export function* fetchMessageSaga(
  action: FetchListAgnosticallyRequested<Message>,
): SagaIterator {
  try {
    const response = yield call(listMessages, action.payload);

    if (
      typeof action.payload.start !== 'undefined' &&
      typeof action.payload.end !== 'undefined'
    ) {
      response.result.forEach((message: Message) => {
        message.objectId = action.payload.objectId;
        message.objectType = action.payload.objectType;
      });

      yield put(
        actions.fetchPaginatedListMessagesSuccessActionCreator(response),
      );
    } else {
      response.forEach((message: Message) => {
        message.objectId = action.payload.objectId;
        message.objectType = action.payload.objectType;
      });

      yield put(actions.fetchListMessagesSuccessActionCreator(response));
    }
  } catch (err) {
    yield put(actions.fetchListMessagesErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 * @yields {SagaIterator}
 */
export function* getMessageSaga(
  action: FetchGetRequested<Message>,
): SagaIterator {
  try {
    const message: Message = yield call(getMessage, action.payload);

    yield put(actions.fetchGetMessagesSuccessActionCreator(message));

    action.meta.resolve(message);
  } catch (err) {
    yield put(actions.fetchGetMessagesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 * @yields {SagaIterator}
 */
export function* deleteMessageSaga(
  action: FetchDeleteRequested<Message>,
): SagaIterator {
  try {
    yield call(deleteMessage, action.payload);

    yield put(actions.fetchDeleteMessagesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteMessagesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 * @yields {SagaIterator}
 */
export function* editMessageSaga(
  action: FetchEditRequested<Message>,
): SagaIterator {
  try {
    yield call(() => editMessage(action.payload.id, action.payload.content));

    yield put(actions.fetchEditMessagesSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload.content);
  } catch (err) {
    yield put(actions.fetchEditMessagesErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* messagesSagas(): SagaIterator {
  yield takeEvery(getActionType('messages', 'ADD_REQUESTED'), addMessageSaga);
  yield takeEvery(
    getActionType('messages', 'ADD_REPLY_REQUESTED'),
    replyToMessageSaga,
  );
  yield takeEvery(
    getActionType('messages', 'LIST_REQUESTED'),
    fetchMessageSaga,
  );
  yield takeEvery(getActionType('messages', 'GET_REQUESTED'), getMessageSaga);
  yield takeEvery(
    getActionType('messages', 'DELETE_REQUESTED'),
    deleteMessageSaga,
  );
  yield takeEvery(getActionType('messages', 'EDIT_REQUESTED'), editMessageSaga);
}
