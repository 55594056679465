import { combineReducers, Reducer, AnyAction, Action } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';
import { Pagination } from '../../../services/search/search';
import { flatiItems, rehydrate } from '../../helper';
import { Incident } from './types';

/**
 * @param {Incident[]} [state=[]] incidents
 * @param {AnyAction} action
 * @return {Incident[]}
 */
export const incidentReducer: Reducer<types.Incident[]> = (
  state: types.Incident[] = [],
  action: AnyAction,
): types.Incident[] => {
  if (action.type === getActionType('incidents', 'REPORT_SUCCEEDED')) {
    action.payload.status = 'reported';
  }

  if (action.type === getActionType('incidents', 'LIST_PAGINATED_SUCCEEDED')) {
    return rehydrate<Incident>([], flatiItems<Incident>(action.payload.result));
  }

  return modelReducer('incidents', state, action) as types.Incident[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const reportingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidents', 'REPORT_FAILED'):
    case getActionType('incidents', 'REPORT_SUCCEEDED'):
      return false;
    case getActionType('incidents', 'REPORT_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidents', 'LIST_FAILED'):
    case getActionType('incidents', 'LIST_SUCCEEDED'):
    case getActionType('incidents', 'LIST_PAGINATED_SUCCEEDED'):
      return false;
    case getActionType('incidents', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {Pagination} state
 * @param {Action} action
 * @return {boolean}
 */
export const paginationReducer: Reducer<Pagination | null> = (
  state = null,
  action: AnyAction,
): Pagination | null => {
  switch (action.type) {
    case getActionType('incidents', 'LIST_PAGINATED_SUCCEEDED'):
      return action.payload.pagination;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidents', 'GET_FAILED'):
    case getActionType('incidents', 'GET_SUCCEEDED'):
      return false;
    case getActionType('incidents', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidents', 'EDIT_FAILED'):
    case getActionType('incidents', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('incidents', 'ACCEPT_REQUESTED'):
    case getActionType('incidents', 'REJECT_REQUESTED'):
    case getActionType('incidents', 'RESOLVE_REQUESTED'):
    case getActionType('incidents', 'RETYPE_REQUESTED'):
    case getActionType('incidents', 'REMARK_REQUESTED'):
    case getActionType('incidents', 'RELOCATE_REQUESTED'):
    case getActionType('incidents', 'ADD_FILE_REQUESTED'):
    case getActionType('incidents', 'ADD_GED_FILE_REQUESTED'):
    case getActionType('incidents', 'DELETE_FILE_REQUESTED'):
    case getActionType('incidents', 'CHANGE_REPORTING_REQUESTED'):
    case getActionType('incidents', 'CHANGE_SERVICE_ORDER_NUMBER_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('incidents', 'DELETE_FAILED'):
    case getActionType('incidents', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('incidents', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.IncidentState> = combineReducers({
  incidents: incidentReducer,
  pagination: paginationReducer,
  status: combineReducers({
    reporting: reportingReducer,
    listing: listingReducer,
    getting: gettingReducer,
    editing: editingReducer,
  }),
});

export default reducers;
