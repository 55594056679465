import { createSelector } from 'reselect';
import { Permission, PermissionState } from './types';
import { State } from '../../types';

/**
 * @param {State} state
 * @return {PermissionState}
 */
const permissionStateSelector: (state: State) => PermissionState = (state) =>
  state.permissionsState;

/**
 * @param {State} state
 * @return {Permission[]}
 */
export const permissionsSelector = createSelector(
  permissionStateSelector,
  (applicationState) => applicationState.permissions,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const permissionsIsListingSelector = createSelector(
  permissionStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Permission|null}
 */
export const permissionFromIdSelector = createSelector(
  permissionsSelector,
  (_: State, id: Permission['id'] | null) => id,
  (permissions, id) =>
    permissions.find((permission) => permission.id === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Permission[]}
 */
export const permissionsFromMultipleIdsSelector = createSelector(
  permissionsSelector,
  (_: State, ids: Permission['id'][]) => ids,
  (permissions, ids) =>
    permissions.filter((permission) => ids.includes(permission.id)),
);

/**
 * @param {State} state
 * @return {{[key: string]: Permission[]}}
 */
export const permissionsBySubjectSelector = createSelector(
  permissionsSelector,
  (permissions) => {
    const permissionsBySubject: { [key: string]: Permission[] } = {};

    permissions.forEach((permission) => {
      if (!permissionsBySubject.hasOwnProperty(permission.subject)) {
        permissionsBySubject[permission.subject] = [];
      }

      permissionsBySubject[permission.subject].push(permission);
    });

    return permissionsBySubject;
  },
);
