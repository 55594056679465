import PropTypes from 'prop-types';
import { Model } from '../common/types';
import { Permission, PropTypesPermission } from '../permissions/types';

export interface User extends Model {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  organizationIds: string[];
  visibleOrganizationIds: string[];
  roleId?: string | null;
  resetPasswordToken?: string | null;
  locationIds?: string[] | null;
  visibleLocationIds?: string[] | null;
  permissions?: Permission[] | null;
}

export interface UserState {
  users: User[];
  status: {
    listing: boolean;
    adding: boolean;
    editing: boolean;
    getting: boolean;
  };
}

export const PropTypesUser = PropTypes.shape({
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  organizationIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  visibleOrganizationIds: PropTypes.arrayOf(PropTypes.string.isRequired)
    .isRequired,
  roleId: PropTypes.string,
  resetPasswordToken: PropTypes.string,
  locationIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  visibleLocationIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  permissions: PropTypes.arrayOf(PropTypesPermission.isRequired),
});
