import React, {
  FunctionComponent,
  ReactElement,
  useState,
  KeyboardEvent,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  FormItem,
  OrganizationsAdminTreeSelect,
  Row,
  Col,
  notification,
  Button,
  Text,
} from '../../../base';
import { Spin } from 'antd';
import { userTypes } from '../../../../../states/ducks/users';
import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../../states/ducks/currentUser/types';

interface FormChangeOrgaProps {
  currentUser?: CurrentUser;
  hideModal: Function;
  visible: boolean;
  editUsersAdminOrganizations: Function;
  organizationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
}

const ChangeAdminOrganizations: FunctionComponent<FormChangeOrgaProps> = ({
  currentUser,
  hideModal,
  visible,
  editUsersAdminOrganizations,
  organizationsAndTheirDescendantsIdsSelector,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleFinish = (values: Store): void => {
    setLoading(true);

    const editUser = {
      ...currentUser,
      organizationIds: [values.organizationId],
      visibleOrganizationIds: [values.organizationId]
        .map(
          (organizationId) =>
            organizationsAndTheirDescendantsIdsSelector[organizationId],
        )
        .reduce((a, b) => a.concat(b), []),
    };

    const promiseUser = new Promise<userTypes.User>((resolve, reject): void =>
      editUsersAdminOrganizations(editUser, { resolve, reject }),
    );

    promiseUser
      .then((): Promise<void> => {
        return new Promise(function (resolve) {
          setTimeout(() => resolve(), 2000);
        });
      })
      .then((): void => {
        window.location.reload();
      })
      .catch((message: string): void => {
        notification.error({ message });
        setLoading(false);
      });
  };

  const handleKeyup = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      form.submit();
    }
  };

  const onClose = (): void => {
    hideModal();
  };

  return (
    <Modal
      title="Changement d'organisation"
      onCancel={(): void => onClose()}
      visible={visible}
      footer={
        loading ? null : (
          <>
            <Button htmlType="button" size="large" onClick={onClose}>
              Annuler
            </Button>
            <Button
              form="organization-form"
              type={'primary'}
              htmlType="submit"
              size="large"
              loading={loading}
              disabled={disabled}
            >
              Changer
            </Button>
          </>
        )
      }
      {...props}
    >
      <Spin spinning={loading} tip="Rechargement des données...">
        <Form id="organization-form" onFinish={handleFinish}>
          <Row>
            <Col span={24}>
              <FormItem
                label="Sélectionnez l'organisation sur laquelle vous souhaitez vous connecter"
                name="organizationId"
                initialValue={
                  currentUser && currentUser.organizationIds
                    ? currentUser.organizationIds[0]
                    : ''
                }
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'Le nom est obligatoire',
                  },
                ]}
              >
                <OrganizationsAdminTreeSelect
                  onChange={(value): void => {
                    if (currentUser?.organizationIds[0] !== value) {
                      setDisabled(false);
                    } else {
                      setDisabled(true);
                    }
                  }}
                  onKeyUp={handleKeyup}
                />
              </FormItem>
              <Text className="app-markdown">
                En cas de déconnexion ou inactivité vous serez renvoyé à
                l&apos;organisation Twipi Group
              </Text>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

ChangeAdminOrganizations.propTypes = {
  currentUser: PropTypesCurrentUser.isRequired,
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  editUsersAdminOrganizations: PropTypes.func.isRequired,
  organizationsAndTheirDescendantsIdsSelector: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
};

export default ChangeAdminOrganizations;
