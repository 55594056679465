import { createSelector } from 'reselect';
import { State } from '../../types';
import { Profile, ProfileState } from './types';
import { currentUserSelector } from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {ProfileState}
 */
const profileStateSelector: (state: State) => ProfileState = (state) =>
  state.profilesState;

/**
 * @param {State} state
 * @return {Profile[]}
 */
export const profilesSelector = createSelector(
  profileStateSelector,
  (applicationState) => applicationState.profiles,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const profilesIsListingSelector = createSelector(
  profileStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const profilesIsGettingSelector = createSelector(
  profileStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Profile|null}
 */
export const profileFromIdSelector = createSelector(
  profilesSelector,
  (_: State, id: Profile['id']) => id,
  (profile, id) => profile.find((profile) => profile.id === id) || null,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Profile|null}
 */
export const profileFromUserIdSelector = createSelector(
  profilesSelector,
  (_: State, id: Profile['id']) => id,
  (profile, id) => profile.find((profile) => profile.userId === id) || null,
);

/**
 * @param {State} state
 * @param {string[]} ids
 * @return {Profile[]}
 */
export const profileFromMultipleUserIdsSelector = createSelector(
  profilesSelector,
  (_: State, ids: Profile['id'][]) => ids,
  (profiles, ids) => profiles.filter((profile) => ids.includes(profile.userId)),
);

/**
 * @param {State} state
 * @return {Profile[]}
 */
export const visibleProfilesSelector = createSelector(
  profilesSelector,
  (state) => currentUserSelector(state),
  (profiles, currentUser) =>
    profiles.filter(
      (profile) =>
        currentUser.visibleOrganizationIds.filter((id) =>
          profile.organizationIds.includes(id),
        ).length > 0,
    ),
);
