import { createSelector } from 'reselect';
import { State } from '../../types';
import { KpiState } from './types';

/**
 * @param {State} state
 * @return {KpiState}
 */
const kpiStateSelector: (state: State) => KpiState = (state) => state.kpisState;

/**
 * @param {State} state
 * @return {Kpi}
 */
export const incidentsByStatusSelector = createSelector(
  kpiStateSelector,
  (applicationState) =>
    applicationState.kpis.find((kpi) => kpi.name === 'incidentsByStatus') ||
    null,
);

/**
 * @param {State} state
 * @return {Kpi}
 */
export const incidentsReportedAndResolvedByMonthSelector = createSelector(
  kpiStateSelector,
  (applicationState) =>
    applicationState.kpis.find(
      (kpi) => kpi.name === 'incidentsReportedAndResolvedByMonth',
    ) || null,
);

/**
 * @param {State} state
 * @return {Kpi}
 */
export const incidentsByMainLocationsSelector = createSelector(
  kpiStateSelector,
  (applicationState) =>
    applicationState.kpis.find(
      (kpi) => kpi.name === 'incidentsByMainLocations',
    ) || null,
);

/**
 * @param {State} state
 * @return {Kpi}
 */
export const requestsByManagerSelector = createSelector(
  kpiStateSelector,
  (applicationState) =>
    applicationState.kpis.find((kpi) => kpi.name === 'requestsByManager') ||
    null,
);

/**
 * @param {State} state
 * @return {Kpi}
 */
export const requestsOpenedAndClosedByMonthSelector = createSelector(
  kpiStateSelector,
  (applicationState) =>
    applicationState.kpis.find(
      (kpi) => kpi.name === 'requestsOpenedAndClosedByMonth',
    ) || null,
);
