import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Redirect } from 'react-router';
import {
  RequestStatusTag,
  Card,
  Row,
  Col,
  PopoverActions,
  Skeleton,
  PageHeader,
  EditableField,
  OrganizationsTreeSelect,
  RequestOrganizationName,
  DatePicker,
  TimePicker,
  DateField,
  TextArea,
  ProfilesSelect,
  ProfileAvatarAndNameFromUserId,
  MessageHandler,
  Histories,
  Button,
  notification,
} from '../../base';
import { requestTypes as types } from '../../../../states/ducks/requests';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import UploadListField from '../../base/upload/UploadListField';
import { File } from '../../../../states/ducks/files/types';
import { convertFileToUploadFile } from '../../../../utils/FileUtils';

interface RequestInfoProps {
  closeRequest: Function;
  deleteRequest: Function;
  editRequest: Function;
  editCreatorRequest: Function;
  editManagerRequest: Function;
  editOrganizationRequest: Function;
  getRequest: Function;
  isGettingRequest: boolean;
  request: types.Request | null;
  requestId: types.Request['id'];
  files: File[];
  getFile: Function;
  addFileRequest: Function;
  addGEDFileRequest: Function;
  deleteFileRequest: Function;
}

const RequestInfo: FunctionComponent<RequestInfoProps> = ({
  closeRequest,
  deleteRequest,
  editRequest,
  editCreatorRequest,
  editManagerRequest,
  editOrganizationRequest,
  getRequest,
  isGettingRequest,
  request,
  requestId,
  files,
  getFile,
  addFileRequest,
  addGEDFileRequest,
  deleteFileRequest,
}): ReactElement => {
  const [activeTab, setActiveTab] = useState('replies');
  const [hasGotRequest, setHasGotRequest] = useState(false);
  const [reloadFilesNeeded, setReloadFilesNeeded] = useState(false);

  useEffect((): void => {
    if (isGettingRequest) {
      setHasGotRequest(true);
    }
  }, [isGettingRequest]);

  useEffect((): void => {
    if (requestId) {
      getRequest(requestId);
    }
  }, [getRequest, requestId]);

  // TODO: set files in another state to avoid conflicts with files in the GED modal
  useEffect((): void => {
    if (reloadFilesNeeded) {
      if (request && request.fileIds && request.fileIds.length > 0) {
        request.fileIds.forEach((id) => getFile(id));
      }
      setReloadFilesNeeded(false);
    }
  }, [getFile, request, reloadFilesNeeded]);

  if (!requestId || (hasGotRequest && !isGettingRequest && !request)) {
    return (
      <Redirect
        to={{
          pathname: '/requests/',
        }}
      />
    );
  }

  const requestStatus = request ? (
    <RequestStatusTag status={request.status} />
  ) : (
    <></>
  );

  moment.locale('fr');
  const subTitle = request
    ? 'Mis à jour le ' + moment(request.updatedAt).format('L [à] H[h]mm')
    : '';

  const objectField = request ? (
    <EditableField
      object={request}
      label="Objet"
      content={request.object}
      editedFieldsOptions={{
        field: 'object',
        label: 'Objet',
        required: true,
        content: <TextArea placeholder="Saisissez l'objet'" />,
        initialValue: request.object,
      }}
      onSubmit={editRequest}
      successMessage="La demande a bien été modifié"
    />
  ) : (
    ''
  );

  const bodyField = request ? (
    <EditableField
      object={request}
      label="Demande"
      content={request.body.replace('<br/>', '\n')}
      editedFieldsOptions={{
        field: 'body',
        label: 'Demande',
        required: true,
        content: (
          <TextArea
            placeholder="Saisissez la demande"
            autoSize={{ minRows: 4, maxRows: 10 }}
          />
        ),
        initialValue: request.body,
      }}
      onSubmit={editRequest}
      successMessage="La demande a bien été modifié"
    />
  ) : (
    ''
  );

  const creatorField = request ? (
    <EditableField
      object={request}
      label="Créateur"
      content={<ProfileAvatarAndNameFromUserId userId={request.creatorId} />}
      editedFieldsOptions={{
        field: 'creatorId',
        label: 'Créateur',
        content: <ProfilesSelect placeholder={'Créateur'} />,
        initialValue: request ? request.creatorId : '',
        required: true,
        invalidMessage: 'Sélectionnez un créateur',
      }}
      onSubmit={editCreatorRequest}
      successMessage="La demande a bien été modifiée"
    />
  ) : (
    ''
  );

  const managerField = request ? (
    <EditableField
      object={request}
      label="Responsable"
      content={
        request.managerId ? (
          <ProfileAvatarAndNameFromUserId userId={request.managerId} />
        ) : (
          <></>
        )
      }
      editedFieldsOptions={{
        field: 'managerId',
        label: 'Responsable',
        content: <ProfilesSelect placeholder={'Responsable'} />,
        initialValue: request ? request.managerId : '',
        required: true,
        invalidMessage: 'Sélectionnez un responsable',
      }}
      onSubmit={editManagerRequest}
      successMessage="La demande a bien été modifiée"
    />
  ) : (
    ''
  );

  const openedAtField = request ? (
    <EditableField
      object={request}
      label="Date de la demande"
      content={<DateField date={request.openedAt} />}
      editedFieldsOptions={[
        {
          field: 'openedAt',
          label: 'Date de la demande',
          content: <DatePicker onlyPast={true} inverted />,
          required: true,
          type: 'object',
          initialValue: moment(request.openedAt),
          colSize: 13,
        },
        {
          field: 'openedAt',
          label: 'Heure de la demande',
          content: <TimePicker />,
          required: true,
          type: 'object',
          initialValue: moment(request.openedAt),
          colSize: 11,
        },
      ]}
      onSubmit={editRequest}
      successMessage="La demande a bien été modifié"
    />
  ) : (
    ''
  );

  const organizationField = request ? (
    <EditableField
      object={request}
      label="Organisation"
      content={
        <RequestOrganizationName organizationId={request.organizationId} />
      }
      editedFieldsOptions={{
        field: 'organizationId',
        label: 'Organisation',
        content: (
          <OrganizationsTreeSelect placeholder="Sélectionnez une organisation" />
        ),
        initialValue: request ? request.organizationId : '',
        required: true,
        invalidMessage: 'Sélectionnez une organisation',
      }}
      onSubmit={editOrganizationRequest}
      successMessage="L'organisation a bien été modifiée"
    />
  ) : (
    ''
  );

  const filesField = request ? (
    <UploadListField
      displayLimit={6}
      fileList={files.map((file) => convertFileToUploadFile(file))}
      label="Documents"
      onGEDUpload={(fileIds, meta): void =>
        addGEDFileRequest(
          {
            fileIds: fileIds,
            request: request,
          },
          meta,
        )
      }
      onGEDClose={(): void => setReloadFilesNeeded(true)}
      onUpload={(file, meta): void =>
        addFileRequest(
          {
            file: file,
            request: request,
          },
          meta,
        )
      }
      onDelete={(file, meta): void => {
        deleteFileRequest(
          {
            fileId: file.uid,
            request: request,
          },
          meta,
        );
      }}
    />
  ) : (
    ''
  );

  const tabList = [
    {
      key: 'replies',
      tab: `Réponses`,
    },
    {
      key: 'histories',
      tab: 'Historique',
    },
  ];

  const contentList = {
    replies: (
      <MessageHandler
        objectType={'request'}
        objectId={requestId}
        addPlaceholder={'Votre réponse...'}
        addSuccessMessage={'La réponse a bien été ajoutée'}
        deleteConfirmationMessage={'Supprimer la réponse ?'}
        deleteSuccessMessage={'La réponse a bien été supprimée'}
        editSuccessMessage={'La réponse a bien été éditée'}
        emptyMessage={'Aucune réponse'}
        listingErrorMessage={
          "Une erreur s'est produite lors du chargement des réponses"
        }
      />
    ),
    histories: <Histories objectType={'request'} object={request} />,
  };

  return (
    <Skeleton loading={isGettingRequest}>
      <PageHeader
        title={request ? `Demande n°${request.number}` : ''}
        subTitle={subTitle}
        tags={requestStatus}
        extra={[
          <>
            {request && request.status !== 'closed' ? (
              <Button
                type="primary"
                onClick={(): void => {
                  request.status = 'closed';

                  closeRequest(request);

                  notification.success({
                    message: 'Demande fermée',
                  });
                }}
              >
                Fermer la demande
              </Button>
            ) : (
              <></>
            )}
            <PopoverActions
              key={requestId}
              objectId={requestId}
              onDelete={deleteRequest}
              deleteConfirmMessage="Supprimer la demande ?"
              deleteDoneMessage="La demande a bien été supprimé"
            />
          </>,
        ]}
      />
      <Row className="mt-40">
        <Col span={18}>
          <Card className="section">
            <Row>
              <Col span={24}>{objectField}</Col>
              <Col span={24}>{bodyField}</Col>
            </Row>
          </Card>
          <Card
            className="section"
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={(key): void => setActiveTab(key)}
          >
            {
              // @ts-ignore
              contentList[activeTab]
            }
          </Card>
        </Col>
        <Col span={6}>
          <Card className="section">
            <Row>
              <Col span={24}>{creatorField}</Col>
              <Col span={24}>{managerField}</Col>
              <Col span={24}>{openedAtField}</Col>
              <Col span={24}>{organizationField}</Col>
              <Col span={24}>{filesField}</Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Skeleton>
  );
};

RequestInfo.propTypes = {
  closeRequest: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  editRequest: PropTypes.func.isRequired,
  editCreatorRequest: PropTypes.func.isRequired,
  editManagerRequest: PropTypes.func.isRequired,
  editOrganizationRequest: PropTypes.func.isRequired,
  getRequest: PropTypes.func.isRequired,
  isGettingRequest: PropTypes.bool.isRequired,
  request: types.PropTypesRequest,
  requestId: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  getFile: PropTypes.func.isRequired,
  addFileRequest: PropTypes.func.isRequired,
  addGEDFileRequest: PropTypes.func.isRequired,
  deleteFileRequest: PropTypes.func.isRequired,
};

export default RequestInfo;
