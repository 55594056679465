import React, { FunctionComponent, ReactElement } from 'react';
import { Tag as TagAnt } from 'antd';
import 'antd/lib/tag/style';
import { TagProps } from 'antd/lib/tag';

/**
 * Tag component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Tag: FunctionComponent<TagProps> = ({ ...props }): ReactElement => (
  <TagAnt {...props} />
);

Tag.propTypes = {};

export default Tag;
