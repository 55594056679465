import React, { FunctionComponent, ReactElement } from 'react';
import { Empty as EmptyAnt } from 'antd';
import 'antd/lib/empty/style';
import { EmptyProps } from 'antd/lib/empty';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

interface Props extends EmptyProps {
  redirection?: string;
}
/**
 * Empty component
 * @param {string} redirection
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Empty: FunctionComponent<Props> = ({
  redirection,
  ...props
}): ReactElement => {
  if (redirection) {
    return (
      <Redirect
        to={{
          pathname: redirection,
        }}
      />
    );
  }

  props.description = props.description || 'Aucune données';

  return <EmptyAnt {...props} />;
};

Empty.propTypes = {
  redirection: PropTypes.string,
  description: PropTypes.node,
};

export default Empty;
