import React, { FunctionComponent, ReactElement, useState } from 'react';
import { Transfer as TransferAnt } from 'antd';
import 'antd/lib/transfer/style';
import {
  TransferProps,
  TransferItem,
  TransferDirection,
} from 'antd/lib/transfer';
import './Transfer.scss';
import PropTypes from 'prop-types';

/**
 * Transfer component
 * @param {any} props
 * @return {ReactElement}
 */
const Transfer: FunctionComponent<TransferProps<TransferItem>> = ({
  ...props
}): ReactElement => {
  const initialState: { data: TransferItem[]; targetKeys: string[] } = {
    data: props.dataSource,
    targetKeys: props.targetKeys || [],
  };

  const [state, setState] = useState(initialState);

  const filterOption = (inputValue: string, option: TransferItem): boolean => {
    if (option.title) {
      return option.title.toLowerCase().indexOf(inputValue) > -1;
    }

    return false;
  };

  const handleChange = (
    targetKeys: string[],
    direction: TransferDirection,
  ): void => {
    setState({
      data: state.data,
      targetKeys: targetKeys,
    });

    if (props.onChange) {
      props.onChange(targetKeys, direction, ['']);
    }
  };

  return (
    <TransferAnt
      {...props}
      showSearch
      filterOption={filterOption}
      onChange={handleChange}
      targetKeys={state.targetKeys}
      render={(item): string => item.title || ''}
    />
  );
};

Transfer.propTypes = {
  dataSource: PropTypes.any,
  targetKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func,
};

export default Transfer;
