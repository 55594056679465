import React, {
  ReactElement,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import {
  Empty,
  Skeleton,
  Row,
  Col,
  EditableField,
  TextArea,
  Input,
  OrganizationsTreeSelect,
  PlacesAutocompleteInput,
  Maps,
} from '../../base';
import { locationTypes } from '../../../../states/ducks/locations';
import { organizationTypes } from '../../../../states/ducks/organizations';
import PropTypes from 'prop-types';
import UploadListField from '../../base/upload/UploadListField';
import { convertFileToUploadFile } from '../../../../utils/FileUtils';
import { File } from '../../../../states/ducks/files/types';

export interface PropsLocationInfoGeneral {
  location: locationTypes.Location | null;
  locationLoading: boolean;
  organization: organizationTypes.Organization | null;
  organizationLoading: boolean;
  useLocationReducer: Function;
  editLocationDesignation: Function;
  editLocationOrganization: Function;
  editLocationTypeId: Function;
  editLocationAddress: Function;
  editLocationRemark: Function;
  files: File[];
  getFile: Function;
  addFileLocation: Function;
  addGEDFileLocation: Function;
  deleteFileLocation: Function;
}

const LocationInfoGeneral: FunctionComponent<PropsLocationInfoGeneral> = ({
  location,
  locationLoading,
  organization,
  organizationLoading,
  editLocationDesignation,
  editLocationOrganization,
  editLocationAddress,
  editLocationRemark,
  files,
  getFile,
  addFileLocation,
  addGEDFileLocation,
  deleteFileLocation,
}): ReactElement => {
  const [reloadFilesNeeded, setReloadFilesNeeded] = useState(false);

  // TODO: set files in another state to avoid conflicts with files in the GED modal
  useEffect((): void => {
    if (reloadFilesNeeded) {
      if (location && location.fileIds && location.fileIds.length > 0) {
        location.fileIds.forEach((id) => getFile(id));
      }
      setReloadFilesNeeded(false);
    }
  }, [getFile, location, reloadFilesNeeded]);

  const designationField = location ? (
    <EditableField
      object={location}
      label="Désignation"
      content={
        location.code ? `[${location.code}] ${location.name}` : location.name
      }
      editedFieldsOptions={[
        {
          field: 'name',
          label: 'Désignation',
          content: <Input />,
          required: true,
          initialValue: location.name,
          colSize: 12,
        },
        {
          field: 'code',
          label: 'Code',
          content: <Input />,
          required: true,
          initialValue: location.code,
          colSize: 12,
        },
      ]}
      onSubmit={editLocationDesignation}
      successMessage="La désignation a bien été modifiée"
    />
  ) : (
    ''
  );
  const organizationField = location ? (
    <EditableField
      object={location}
      label="Organisation"
      content={organization ? organization.name : ''}
      editedFieldsOptions={{
        field: 'organizationId',
        label: 'Organisation',
        content: <OrganizationsTreeSelect />,
        initialValue: location.organizationId,
        required: true,
      }}
      onSubmit={editLocationOrganization}
      successMessage="L'organisation a bien été modifié"
    />
  ) : (
    ''
  );
  const addressField = location ? (
    <EditableField
      object={location}
      label="Adresse"
      content={
        location.addressComponents?.formattedAddress ? (
          <>
            {location.addressComponents?.formattedAddress}
            <br />
            {location.addressComponents?.additionalAddress}
          </>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: ['addressComponents', 'formattedAddress'],
          label: 'Adresse',
          content: (
            <PlacesAutocompleteInput placeholder="Saisissez une adresse (n°, voie, cp, ville...)" />
          ),
          initialValue: location.addressComponents?.formattedAddress,
        },
        {
          field: ['addressComponents', 'additionalAddress'],
          label: 'Complément',
          content: <Input placeholder="Saisissez un complément d'adresse" />,
          initialValue: location.addressComponents?.additionalAddress,
        },
      ]}
      onSubmit={editLocationAddress}
      successMessage="L'adresse a bien été modifiée"
    />
  ) : (
    ''
  );
  const remarkField = location ? (
    <EditableField
      object={location}
      label="Commentaire"
      content={location.remark || undefined}
      editedFieldsOptions={{
        field: 'remark',
        label: 'Commentaire',
        content: <TextArea placeholder="Saisissez un commentaire" />,
        initialValue: location.remark,
      }}
      onSubmit={editLocationRemark}
      successMessage="Le commentaire a bien été modifié"
    />
  ) : (
    ''
  );

  const coords =
    location &&
    location.addressComponents?.latitude &&
    location.addressComponents?.longitude
      ? {
          lat: location.addressComponents?.latitude,
          lng: location.addressComponents?.longitude,
        }
      : undefined;

  const filesField = location ? (
    <UploadListField
      displayLimit={6}
      fileList={files.map((file) => convertFileToUploadFile(file))}
      label="Documents"
      onGEDUpload={(fileIds, meta): void =>
        addGEDFileLocation(
          {
            fileIds: fileIds,
            location: location,
          },
          meta,
        )
      }
      onGEDClose={(): void => setReloadFilesNeeded(true)}
      onUpload={(file, meta): void =>
        addFileLocation(
          {
            file: file,
            location: location,
          },
          meta,
        )
      }
      onDelete={(file, meta): void => {
        deleteFileLocation(
          {
            fileId: file.uid,
            location: location,
          },
          meta,
        );
      }}
    />
  ) : (
    ''
  );

  return (
    <>
      <Skeleton
        loading={
          (organizationLoading && organization === null) ||
          (locationLoading && location === null)
        }
      >
        {location !== null ? (
          <Row>
            <Col span={12}>
              <Row>
                <Col span={20}>{designationField}</Col>
              </Row>
              <Row>
                <Col span={20}>{organizationField}</Col>
              </Row>
              <Row>
                <Col span={20}>{addressField}</Col>
              </Row>
              <Row>
                <Col span={20}>{remarkField}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={20}>
                  <Maps
                    centerLatLng={coords}
                    markers={coords ? [coords] : []}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={20}>{filesField}</Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Empty />
        )}
      </Skeleton>
    </>
  );
};

LocationInfoGeneral.propTypes = {
  location: locationTypes.PropTypesLocation,
  locationLoading: PropTypes.bool.isRequired,
  organization: organizationTypes.PropTypesOrganization,
  organizationLoading: PropTypes.bool.isRequired,
  useLocationReducer: PropTypes.func.isRequired,
  editLocationDesignation: PropTypes.func.isRequired,
  editLocationOrganization: PropTypes.func.isRequired,
  editLocationTypeId: PropTypes.func.isRequired,
  editLocationAddress: PropTypes.func.isRequired,
  editLocationRemark: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  getFile: PropTypes.func.isRequired,
  addFileLocation: PropTypes.func.isRequired,
  addGEDFileLocation: PropTypes.func.isRequired,
  deleteFileLocation: PropTypes.func.isRequired,
};

export default LocationInfoGeneral;
