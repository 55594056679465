import React, { FunctionComponent, ReactElement } from 'react';
import { Form } from 'antd';
import 'antd/lib/form/style';
import './Form.scss';
import './FormItem.scss';
import { FormItemProps } from 'antd/lib/form';
import classNames from 'classnames';

import PropTypes from 'prop-types';

interface Props extends FormItemProps {
  className?: string;
}

/**
 * @param {any} props empty props
 * @return {ReactElement}
 */
const FormItem: FunctionComponent<Props> = ({ ...props }): ReactElement => {
  const initialClasses = props.className ? props.className : '';
  const classes = classNames('app-form-item', initialClasses);
  return <Form.Item className={classes} {...props} />;
};

FormItem.propTypes = {
  className: PropTypes.string,
};

export default FormItem;
