import React, { FunctionComponent, ReactElement } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import { State } from '../../../../states/types';
import { Avatar } from '../index';
import { AvatarProps } from './Avatar';
import { AnyAction } from 'redux';
import PropTypes from 'prop-types';

export interface CurrentUserProps extends AvatarProps {
  currentUser?: CurrentUser;
}

/**
 * @param {CurrentUser} currentUser
 * @param {DispatchProp<AnyAction>} dispatch
 * @param {AvatarProps} props
 * @return {ReactElement}
 */
const CurrentUserAvatar: FunctionComponent<
  CurrentUserProps & DispatchProp<AnyAction>
> = ({ currentUser, dispatch, ...props }): ReactElement => (
  <Avatar {...props} userId={currentUser ? currentUser.id : ''} />
);

CurrentUserAvatar.displayName = 'CurrentUserAvatar';

CurrentUserAvatar.propTypes = {
  currentUser: PropTypesCurrentUser.isRequired,
  dispatch: PropTypes.any.isRequired,
};

interface MapStateToProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  currentUser: currentUserSelector(state),
});

export default connect(mapStateToProps)(CurrentUserAvatar);
