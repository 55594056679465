import React, {
  FunctionComponent,
  ReactElement,
  useReducer,
  useState,
} from 'react';
import {
  PageHeader,
  Button,
  Card,
  Table,
  DateTooltip,
  PopoverActions,
  PermissionsTable,
  RestrictedToPermissions,
} from '../../base';
import AddRole from '../../../containers/modal/role/AddRole';
import GetRole from '../../../containers/modal/role/GetRole';
import { ColumnProps } from 'antd/lib/table';
import { roleTypes } from '../../../../states/ducks/roles';
import PropTypes from 'prop-types';
import {
  alphabeticalSortBy,
  chronologicalSortBy,
} from '../../../../utils/SortUtils';
import { PlusOutlined } from '@ant-design/icons';

const initialState = {
  modalAddRoleVisible: false,
  modalGetRoleVisible: false,
  roleId: null as null | roleTypes.Role['id'],
};

type ReducerState = typeof initialState;

interface ReducerAction {
  type: 'VISIBLE_ADD_ROLE' | 'VISIBLE_GET_ROLE' | 'HIDDEN';
  payload?: {
    roleId: roleTypes.Role['id'] | null;
  };
}

/**
 * state reducer
 * @param {ReducerState} state
 * @param {ReducerAction} action
 * @return {ReducerState}
 */
function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  const { roleId = null } = action.payload ? action.payload : {};

  switch (action.type) {
    case 'VISIBLE_ADD_ROLE':
      return {
        ...state,
        modalAddRoleVisible: true,
      };
    case 'VISIBLE_GET_ROLE':
      return {
        ...state,
        modalGetRoleVisible: true,
        roleId,
      };
    case 'HIDDEN':
      return {
        ...state,
        modalAddRoleVisible: false,
        modalGetRoleVisible: false,
      };
    default:
      throw new Error();
  }
}

interface Props {
  roles: roleTypes.Role[];
  deleteRole: Function;
}

interface TableRole {
  id: roleTypes.Role['id'];
  key: roleTypes.Role['id'];
  name: roleTypes.Role['name'];
  createdAt: roleTypes.Role['createdAt'];
  updatedAt: roleTypes.Role['updatedAt'];
  action: object;
}

const dateToolTip = (date: string): ReactElement => <DateTooltip date={date} />;
interface UseSidebar {
  showAddRoleModal: Function;
  showGetRoleModal: Function;
  hideModal: Function;
  state: ReducerState;
}

const useSidebar = (): UseSidebar => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showAddRoleModal = (): void => {
    dispatch({ type: 'VISIBLE_ADD_ROLE' });
  };

  const showGetRoleModal = (id: string): void => {
    dispatch({
      type: 'VISIBLE_GET_ROLE',
      payload: { roleId: id },
    });
  };

  const hideModal = (): void => {
    dispatch({ type: 'HIDDEN' });
  };

  return {
    showAddRoleModal,
    showGetRoleModal,
    hideModal,
    state,
  };
};

const User: FunctionComponent<Props> = ({
  roles,
  deleteRole,
}): ReactElement => {
  const { state, showAddRoleModal, showGetRoleModal, hideModal } = useSidebar();

  const [activeTab, setActiveTab] = useState('role');

  const datas = roles.map((role: roleTypes.Role): TableRole => {
    return {
      id: role.id,
      key: role.id,
      name: role.name,
      createdAt: role.createdAt,
      updatedAt: role.updatedAt,
      action: {
        id: role.id,
      },
    };
  });

  const columns: ColumnProps<TableRole>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a: TableRole, b: TableRole): number =>
        alphabeticalSortBy(a.name, b.name),
    },
    {
      key: 'createdAt',
      title: 'Créé',
      dataIndex: 'createdAt',
      render: dateToolTip,
      sorter: (a: TableRole, b: TableRole): number =>
        chronologicalSortBy(a.createdAt, b.createdAt),
    },
    {
      key: 'updatedAt',
      title: 'Modifié',
      dataIndex: 'updatedAt',
      render: dateToolTip,
      sorter: (a: TableRole, b: TableRole): number =>
        chronologicalSortBy(a.updatedAt, b.updatedAt),
    },
    {
      key: 'operation',
      title: (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={(): void => showAddRoleModal()}
        >
          Créer
        </Button>
      ),
      dataIndex: 'action',
      className: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: TableRole): ReactElement => {
        return (
          <PopoverActions
            objectId={record.id}
            onDelete={deleteRole}
            deleteConfirmMessage="Supprimer le rôle ?"
            deleteDoneMessage="Le rôle a bien été supprimé"
            size="small"
          />
        );
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: TableRole): any => ({
    onClick: (): void => {
      showGetRoleModal(record.id);
    },
  });

  const tabList = [
    {
      key: 'role',
      tab: 'Rôle',
    },
    {
      key: 'permissions',
      tab: 'Droits',
    },
  ];

  const contentList = {
    role: (
      <Table<TableRole> columns={columns} dataSource={datas} onRow={onRow} />
    ),
    permissions: <PermissionsTable />,
  };

  return (
    <RestrictedToPermissions subject={'administration'} action={'access'}>
      <PageHeader title="Rôle" />
      <Card
        className="section"
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={(key): void => setActiveTab(key)}
      >
        {
          // @ts-ignore
          contentList[activeTab]
        }
      </Card>
      <AddRole visible={state.modalAddRoleVisible} hideModal={hideModal} />
      <GetRole
        visible={state.modalGetRoleVisible}
        hideDrawer={hideModal}
        roleId={state.roleId || ''}
      />
    </RestrictedToPermissions>
  );
};

User.propTypes = {
  roles: PropTypes.arrayOf(roleTypes.PropTypesRole.isRequired).isRequired,
  deleteRole: PropTypes.func.isRequired,
};

export default User;
