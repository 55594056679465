import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import {
  fetchSubscribeActionCreator,
  fetchUnsubscribeActionCreator,
} from '../../../../states/ducks/subscriptions/actions';
import { State } from '../../../../states/types';
import { userFromIdSelector } from '../../../../states/ducks/users/selectors/usersSelectors';
import { PropTypesUser, User } from '../../../../states/ducks/users/types';
import { notification, Switch } from '../index';
import { SubscriptionData } from './Subscriptions';

interface Props {
  subscribe: Function;
  subscription: SubscriptionData;
  unsubscribe: Function;
  user: CurrentUser | User | null;
}
/**
 * @param {Function} subscribe
 * @param {SubscriptionData} subscription
 * @param {Function} unsubscribe
 * @param {User} user
 *
 * @return {ReactElement}
 */

const SubscriptionSwitch: FunctionComponent<Props> = ({
  subscribe,
  subscription,
  unsubscribe,
  user,
}): ReactElement => {
  const [pending, setPending] = useState(false);

  if (!user) {
    return <></>;
  }

  return (
    <Switch
      checked={subscription.id !== undefined}
      loading={pending}
      onChange={(isChecked: boolean): void => {
        setPending(true);

        setTimeout(() => {
          setPending(false);
        }, 2000);

        const promise = new Promise<void>((resolve, reject): void => {
          if (isChecked) {
            subscribe({
              notificationKeys: subscription.notificationKeys,
              userInformation: {
                userId: user.id,
                email: user.email,
              },
              conditions: subscription.conditions,
            });
            resolve();
          }

          if (!isChecked && subscription.id) {
            unsubscribe(subscription.id);
            resolve();
          }

          reject(Error);
        });

        promise
          .then((): void => {
            notification.success({
              message: 'Les subscriptions sont mises à jour',
            });
          })
          .catch((): void => {
            notification.error({
              message: 'La mise à jour à échouée',
            });
          });
      }}
    />
  );
};

SubscriptionSwitch.propTypes = {
  subscribe: PropTypes.func.isRequired,
  subscription: PropTypes.any.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([
    PropTypesCurrentUser.isRequired,
    PropTypesUser.isRequired,
  ]),
};

SubscriptionSwitch.displayName = 'SubscriptionSwitch';

interface MapStateToProps {
  user: CurrentUser | User | null;
}

interface MapDispatchToProps {
  subscribe: Function;
  unsubscribe: Function;
}

const mapStateToProps = (
  state: State,
  props: { userId?: User['id'] },
): MapStateToProps => ({
  user: props.userId
    ? userFromIdSelector(state, props.userId)
    : currentUserSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  subscribe: fetchSubscribeActionCreator,
  unsubscribe: fetchUnsubscribeActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSwitch);
