import React, { FunctionComponent, ReactElement } from 'react';
import { Button as ButtonAnt } from 'antd';
import 'antd/lib/button/style';
import './Button.scss';
import { ButtonProps } from 'antd/lib/button';
import classnames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Button component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Button: FunctionComponent<ButtonProps> = ({ ...props }): ReactElement => {
  const isPrimary =
    (typeof props.type !== 'undefined' &&
      ['primary', 'default'].includes(props.type)) ||
    typeof props.type === 'undefined';

  const classes = classnames({ 'app-btn': isPrimary }, props.className);
  return <ButtonAnt {...props} className={classes} />;
};

Button.propTypes = {
  type: PropTypes.oneOf(['ghost', 'link', 'default', 'primary', 'dashed']),
  className: PropTypes.string,
};

export default Button;
