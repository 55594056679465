import React, { ReactElement, ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Input } from 'antd';
import { TableProps } from 'antd/lib/table';
import {
  Key,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { ReloadOutlined } from '@ant-design/icons';
import {
  DEFAULT_PAGE_SIZE,
  Filter,
  Pagination,
  Search,
} from '../../../../services/search/search';
import { Button, Col, Row, Table, MultipleActionsButton, Card } from '../index';
import '../filter/DataWithFilters.scss';
import '../filter/Filter.scss';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export interface FilterableSearch extends Search {
  pagination: Pagination;
}

interface FilterComponentProps {
  display: ReactElement;
  onChange?: Function;
  onUpdate?: Function;
  size?: number;
}

type FilterType = Filter & FilterComponentProps;

export interface FilterableTableProps<T> {
  initialSearch: FilterableSearch;
  filters: FilterType[];
  extra?: FilterType[];
  onSearchChange: Function;
  search: Search;
  tableProps: TableProps<T>;
  onDelete?: Function;
  deleteConfirmationMessage?: string;
  deleteSuccessMessage?: string;
  onSelectedRowKeysChange?: Function;
}

export const initialSearch: FilterableSearch = {
  filters: [],
  pagination: { current: 1, pageSize: DEFAULT_PAGE_SIZE },
};

/**
 * @param {FilterableSearch} initialSearch
 * @param {FilterComponentProps[]} filters
 * @param {Function} onSearchChange
 * @param {Search} search
 * @param {TableProps} tableProps
 *
 * @return {ReactElement}
 */
function FilterableTable<T extends object>({
  initialSearch,
  filters,
  extra,
  onSearchChange,
  search,
  tableProps,
  onDelete,
  deleteConfirmationMessage,
  deleteSuccessMessage,
  onSelectedRowKeysChange,
}: FilterableTableProps<T>): ReactElement {
  const { lg } = useBreakpoint();
  const [expand, setExpand] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const showDrawer = (): void => setVisibleDrawer(true);
  const onClose = (): void => setVisibleDrawer(false);

  const onFilterChange = (filter: Filter): void => {
    const filters = search.filters.filter(
      (searchFilter) => searchFilter.key !== filter.key,
    );

    if (filter.value && 0 !== Object.values(filter.value).join().length) {
      filters.push(filter);
    }

    onSearchChange({
      ...search,
      filters: [...filters],
    });
  };

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ): void => {
    const newSearch: Omit<FilterableSearch, 'filters'> = {
      pagination: {
        current: pagination.current || 1,
        pageSize: pagination.pageSize || DEFAULT_PAGE_SIZE,
      },
    };

    if (!Array.isArray(sorter) && sorter && sorter.field && sorter.order) {
      const key = Array.isArray(sorter.field) ? sorter.field[0] : sorter.field;

      if (typeof key === 'string') {
        newSearch.sort = {
          field: key,
          order: sorter.order === 'descend' ? 'desc' : 'asc',
        };
      }
    } else {
      delete search.sort;
    }

    onSearchChange({
      ...search,
      ...newSearch,
    });
  };

  const onReset = (): void => {
    onSearchChange({
      ...search,
      ...initialSearch,
    });
  };

  const getFilterNode = (filter, index): ReactNode => {
    let value = search.filters.find(
      (searchFilter) => searchFilter.key === filter.key,
    )?.value;

    if (value && filter.onUpdate) {
      value = filter.onUpdate(value);
    }

    return React.cloneElement(filter.display, {
      className: 'filter',
      key: filter.key,
      value: value,
      onChange: (values: string[]): void => {
        filter.value = filter.onChange ? filter.onChange(values) : values;

        onFilterChange(filter);
      },
    });
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys): void =>
    setSelectedRowKeys(selectedRowKeys);

  const hasSelected = selectedRowKeys.length > 0;

  if (onSelectedRowKeysChange) {
    onSelectedRowKeysChange(selectedRowKeys);
  }

  return (
    <>
      {onDelete ? (
        <MultipleActionsButton
          visible={hasSelected}
          objectIds={selectedRowKeys}
          onDelete={onDelete}
          afterDelete={(): void => setSelectedRowKeys([])}
          title={deleteConfirmationMessage}
          deleteSuccessMessage={deleteSuccessMessage}
        />
      ) : (
        <></>
      )}
      <Row className={'filters-container'}>
        <Col flex={1}>
          {lg ? (
            <>
              {/* ToolBar top area */}
              {filters.length > 0 ? (
                <Input.Group className={'main-filters-container'} compact>
                  {Object.values(filters).map(getFilterNode)}
                  <Button
                    onClick={onReset}
                    className="reset-button"
                    type="primary"
                    size="large"
                  >
                    <ReloadOutlined />
                    Réinitialiser
                  </Button>
                </Input.Group>
              ) : (
                <></>
              )}

              {/* ToolBar extra filters area */}
              {extra?.length ? (
                <div
                  className={'extra-filters-container'}
                  style={{ display: 'flex' }}
                >
                  <Input.Group className={'content'}>
                    {Object.values(extra)
                      .filter((filter, index) =>
                        !expand ? index < 3 : index !== null,
                      )
                      .map(getFilterNode)}
                  </Input.Group>
                  <div className={'more'}>
                    {extra.length > 3 ? (
                      <Button
                        type="link"
                        onClick={(): void => {
                          setExpand(!expand);
                        }}
                      >
                        {!expand ? <DownOutlined /> : <UpOutlined />}
                        {!expand ? 'Plus de filtres' : 'Moins de filtres'}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Button onClick={showDrawer}>
              <FilterOutlined /> Filtres
            </Button>
          )}
        </Col>
      </Row>
      <Card>
        <Table<T>
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          onChange={onTableChange}
          pagination={{
            ...search.pagination,
            ...tableProps.pagination,
          }}
          {...tableProps}
        />
      </Card>

      <Drawer
        className="filters-drawer"
        title="Filtres"
        visible={visibleDrawer}
        closable
        onClose={(): void => onClose()}
        footer={
          <>
            <Button onClick={onReset} className="reset-button" size="large">
              <ReloadOutlined />
              Réinitialiser
            </Button>
            <Button
              onClick={onClose}
              className="reset-button"
              type="primary"
              size="large"
            >
              <EyeOutlined />
              Voir
            </Button>
          </>
        }
      >
        {Object.values(filters).map(getFilterNode)}
        {extra?.length ? (
          Object.values(extra)
            .filter((filter, index) => (!expand ? index < 3 : index !== null))
            .map(getFilterNode)
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
}

FilterableTable.propTypes = {
  initialSearch: PropTypes.any,
  filters: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  extra: PropTypes.arrayOf(PropTypes.object.isRequired),
  tableProps: PropTypes.object.isRequired,
  onDelete: PropTypes.any,
  deleteConfirmationMessage: PropTypes.string,
  deleteSuccessMessage: PropTypes.string,
  onSelectedRowKeysChange: PropTypes.func,
};

FilterableTable.defaultProps = {
  initialSearch: initialSearch,
};

export default FilterableTable;
