import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';
import { Message, MessageState } from './types';
import { flatiItems, rehydrate } from '../../helper';
import { Pagination } from '../../../services/search/search';

/**
 * @param {Message[]} state
 * @param {Action} action
 * @return {Message[]}
 */
export const messageReducer: Reducer<Message[]> = (
  state: Message[] = [],
  action: AnyAction,
) => {
  if (action.type === getActionType('messages', 'LIST_PAGINATED_SUCCEEDED')) {
    if (
      state.length > 0 &&
      action.payload.result.length > 0 &&
      state[0].objectId !== action.payload.result[0].objectId
    ) {
      state = [];
    }

    return rehydrate<Message>(
      state,
      flatiItems<Message>(action.payload.result),
    );
  }

  return modelReducer('messages', state, action) as Message[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const addingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
) => {
  switch (action.type) {
    case getActionType('messages', 'ADD_FAILED'):
    case getActionType('messages', 'ADD_SUCCEEDED'):
      return false;
    case getActionType('messages', 'ADD_REQUESTED'):
    case getActionType('messages', 'ADD_REPLY_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<number> = (
  state = 0,
  action: Action<string>,
) => {
  switch (action.type) {
    case getActionType('messages', 'LIST_FAILED'):
      return -1;
    case getActionType('messages', 'LIST_SUCCEEDED'):
    case getActionType('messages', 'LIST_PAGINATED_SUCCEEDED'):
      return 2;
    case getActionType('messages', 'LIST_REQUESTED'):
      return 1;
    default:
      return state;
  }
};

/**
 * @param {Pagination} state
 * @param {Action} action
 * @return {boolean}
 */
export const paginationReducer: Reducer<Pagination | null> = (
  state = null,
  action: AnyAction,
): Pagination | null => {
  switch (action.type) {
    case getActionType('messages', 'LIST_PAGINATED_SUCCEEDED'):
      return action.payload.pagination;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const gettingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
) => {
  switch (action.type) {
    case getActionType('messages', 'GET_FAILED'):
    case getActionType('messages', 'GET_SUCCEEDED'):
      return false;
    case getActionType('messages', 'GET_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const editingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
) => {
  switch (action.type) {
    case getActionType('messages', 'EDIT_FAILED'):
    case getActionType('messages', 'EDIT_SUCCEEDED'):
      return false;
    case getActionType('messages', 'EDIT_REQUESTED'):
      return true;
    default:
      return state;
  }
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const deletingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
) => {
  switch (action.type) {
    case getActionType('messages', 'DELETE_FAILED'):
    case getActionType('messages', 'DELETE_SUCCEEDED'):
      return false;
    case getActionType('messages', 'DELETE_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<MessageState> = combineReducers({
  messages: messageReducer,
  pagination: paginationReducer,
  status: combineReducers({
    adding: addingReducer,
    deleting: deletingReducer,
    editing: editingReducer,
    getting: gettingReducer,
    listing: listingReducer,
  }),
});

export default reducers;
