export const descriptions = {
  incident: {
    title: 'Incident',
    list: 'Accès à la liste des incidents',
    report: 'Peut signaler un incident',
    changeStatus: "Peut mettre à jour le signalement d'un incident",
    deleteComment: {
      main: 'Suppression des commentaires des autres utilisateurs',
      secondary:
        'L’utilisateur peut supprimer les commentaires des autres utilisateurs sur les incidents',
    },
    acceptOrReject: {
      main: 'Accepter/refuser les incidents',
      secondary:
        'Autorise l’utilisateur à accepter ou refuser des incidents signalés',
    },
    edit: {
      main: 'Édition des incidents',
      secondary:
        'L’utilisateur peut modifier et supprimer (quand c’est possible) les informations sur la fiche des incidents même si il n’est pas l’alerteur de base.',
    },
    getInterventions: {
      main: 'Consulter les interventions des prestataires',
      secondary:
        'Sur Tootwi l’utilisateur peut accéder aux informations de l’intervention prestataire.',
    },
  },
  location: {
    title: 'Lieu',
    list: 'Accès à la liste des lieux',
    create: 'Peut créer un lieu',
    delete: "Suppression d'un lieu",
  },
  contractor: {
    title: 'Prestataire',
    edit: {
      main: 'Éditer/Supprimer les prestataires',
      secondary: 'L’utilisateur peut éditer et supprimer les prestataires',
    },
  },
  user: {
    title: 'Utilisateur',
    list: {
      main: 'Accès à la liste des utilisateurs',
      secondary:
        "Permet à l'utilisateur d'accéder à la liste des utilisateurs, et donc de consulter tous ceux qu'il en a la visibilité",
    },
    edit: {
      main: 'Édition des infos utilisateur',
      secondary:
        'L’utilisateur peut éditer les informations des autres utilisateurs depuis la liste. Ce droit autorise également la suppression des utilisateurs',
    },
  },
  administration: {
    title: 'Administration',
    access: {
      main: 'Accès administration',
      secondary: 'L’utilisateur peut accéder aux interfaces d’administration',
    },
    changeMainLocationType: {
      main: 'Peut modifier le type de lieu principal',
      secondary:
        'Permet aux utilisateurs de modifier la valeur du switch "Type de lieu principal" sur la page de paramétrage des types de lieu',
    },
  },
};
