import {
  locationSelectors,
  locationActions,
} from './../../../../states/ducks/locations/';
import { State } from '../../../../states/types';
import { connect } from 'react-redux';
import InfoLocation from '../../../components/layout/modal/location/InfoLocation';

import { organizationActions } from '../../../../states/ducks/organizations';

import { organizationSelectors } from '../../../../states/ducks/organizations';
import { organizationTypes } from '../../../../states/ducks/organizations';
import { locationTypes } from '../../../../states/ducks/locations';
import {
  locationTypeSelectors,
  locationTypeTypes,
} from '../../../../states/ducks/locationtypes';
import { organizationsIsGettingSelector } from '../../../../states/ducks/organizations/selectors/base';

interface MapDispatchToProps {
  getOrganization: Function;
  getLocation: Function;
  getLocationTypes: Function;
  editLocationDesignation: Function;
  editLocationOrganization: Function;
  editLocationTypeId: Function;
  editLocationAddress: Function;
  editLocationRemark: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  getOrganization: organizationActions.fetchGetOrganizationsActionCreator,
  getLocation: locationActions.fetchGetLocationsActionCreator,
  getLocationTypes: locationActions.fetchGetLocationsActionCreator,
  editLocationDesignation:
    locationActions.fetchEditLocationsDesignationActionCreator,
  editLocationOrganization:
    locationActions.fetchEditLocationsOrganizationActionCreator,
  editLocationTypeId: locationActions.fetchEditLocationsTypeIdActionCreator,
  editLocationAddress: locationActions.fetchEditLocationsAddressActionCreator,
  editLocationRemark: locationActions.fetchEditLocationsRemarkActionCreator,
};

interface MapStateToProps {
  organization: organizationTypes.Organization | null;
  location: locationTypes.Location | null;
  isFetchingOrganization: boolean;
  isFetchingLocation: boolean;
  locationTypes: locationTypeTypes.LocationType[];
}

const mapStateToProps = (
  state: State,
  props: { locationId: string },
): MapStateToProps => {
  const location = locationSelectors.locationFromIdSelector(
    state,
    props.locationId,
  );

  const organization = location
    ? organizationSelectors.organizationFromIdSelector(
        state,
        location.organizationId,
      )
    : null;

  return {
    location,
    organization: organization,
    isFetchingOrganization: organizationsIsGettingSelector(state),
    isFetchingLocation: locationSelectors.locationsIsGettingSelector(state),
    locationTypes:
      locationTypeSelectors.locationTypesFromOrganizationSelector(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoLocation);
