import React, { ReactElement } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { State } from '../../../states/types';
import {
  userActions,
  userSelectors,
  userTypes,
} from '../../../states/ducks/users';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../states/ducks/profiles';
import {
  organizationActions,
  organizationSelectors,
  organizationTypes,
} from '../../../states/ducks/organizations';
import { roleSelectors, roleTypes } from '../../../states/ducks/roles';
import UserInfo from '../../components/layout/users/UserInfo';
import { usersIsGettingSelector } from '../../../states/ducks/users/selectors/base';
import { currentUserSelector } from '../../../states/ducks/currentUser/selectors';

interface MapStateToProps {
  userId: userTypes.User['id'];
  user: userTypes.User | null;
  profile: profileTypes.Profile | null;
  organization: organizationTypes.Organization | null;
  role: roleTypes.Role | null;
  isFetchingUser: boolean;
  isFetchingProfile: boolean;
}

interface MapDispatchToProps {
  getUser: Function;
  getProfile: Function;
  getOrganization: Function;
  editUserInformation: Function;
  editUserEmail: Function;
  editUserPassword: Function;
  editUserOrganizations: Function;
  editProfileInformation: Function;
  editProfileEmail: Function;
  editProfileOrganizations: Function;
}

const mapStateToProps = (state: State): MapStateToProps => {
  const userId = currentUserSelector(state).id;

  const user = userSelectors.userFromIdSelector(state, userId);

  const profile = profileSelectors.profileFromUserIdSelector(state, userId);
  const role = user
    ? roleSelectors.roleFromIdSelector(state, user.roleId || '')
    : null;

  const organization = profile
    ? organizationSelectors.organizationFromIdSelector(
        state,
        profile.organizationIds.length > 0 ? profile.organizationIds[0] : '',
      )
    : null;

  return {
    userId: userId,
    user: user,
    profile: profile,
    organization: organization,
    role: role,
    isFetchingUser: usersIsGettingSelector(state),
    isFetchingProfile: profileSelectors.profilesIsGettingSelector(state),
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getUser: userActions.fetchGetUsersActionCreator,
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
  getOrganization: organizationActions.fetchGetOrganizationsActionCreator,
  editUserInformation: userActions.fetchEditUsersInformationActionCreator,
  editUserEmail: userActions.fetchEditUsersEmailActionCreator,
  editUserPassword: userActions.fetchEditUsersPasswordActionCreator,
  editUserOrganizations: userActions.fetchEditUsersOrganizationsActionCreator,
  editProfileInformation:
    profileActions.fetchEditProfilesInformationActionCreator,
  editProfileEmail: profileActions.fetchEditProfilesEmailActionCreator,
  editProfileOrganizations:
    profileActions.fetchEditProfilesOrganizationsActionCreator,
};

const UserInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);

export default withRouter((): ReactElement => <UserInfoContainer />);
