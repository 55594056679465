import { createAction } from 'redux-actions';
import { PaginatedResult } from '../../../services/search/search';
import { defaultMeta, Meta } from '../common/actions';
import { getActionType } from '../common/types';
import { Message } from './types';

/**
 * @param {Message} message
 * @param {Meta} meta
 * @return {FetchAddRequested}
 */
export const fetchAddMessagesActionCreator = createAction(
  getActionType('messages', 'ADD_REQUESTED'),
  (message: Message) => message,
  (message, meta = defaultMeta) => meta,
);

/**
 * @param {Message} message
 * @param {Meta} meta
 * @return {FetchAddRequested}
 */
export const fetchAddReplyMessagesActionCreator = createAction(
  getActionType('messages', 'ADD_REPLY_REQUESTED'),
  (message: Message) => message,
  (message, meta = defaultMeta) => meta,
);

/**
 * @param {Message} message
 * @return {FetchAddSucceeded}
 */
export const fetchAddMessagesSuccessActionCreator = createAction(
  getActionType('messages', 'ADD_SUCCEEDED'),
  (message: Message) => message,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddMessagesErrorActionCreator = createAction(
  getActionType('messages', 'ADD_FAILED'),
  (message: string) => message,
);

/**
 * @return {FetchListAgnosticallyRequested}
 */
export const fetchListMessagesActionCreator = createAction(
  getActionType('messages', 'LIST_REQUESTED'),
  (
    objectType: string,
    objectId: string,
    start?: number,
    end?: number,
  ): {
    objectType: string;
    objectId: string;
    start?: number;
    end?: number;
  } => ({ objectType, objectId, start, end }),
  (_, meta: Meta = defaultMeta) => meta,
);

/**
 * @param {Message[]} messages
 * @return {FetchListSucceeded}
 */
export const fetchListMessagesSuccessActionCreator = createAction(
  getActionType('messages', 'LIST_SUCCEEDED'),
  (messages: Message[]) => messages,
);

/**
 * @param {PaginatedResult} paginatedResult
 * @return {FetchPaginatedListSucceeded}
 */
export const fetchPaginatedListMessagesSuccessActionCreator = createAction(
  getActionType('messages', 'LIST_PAGINATED_SUCCEEDED'),
  (paginatedResult: PaginatedResult): PaginatedResult => paginatedResult,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListMessagesErrorActionCreator = createAction(
  getActionType('messages', 'LIST_FAILED'),
  (message: string) => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetMessagesActionCreator = createAction(
  getActionType('messages', 'GET_REQUESTED'),
  (id: Message['id']) => id,
  (_, meta: Meta = defaultMeta) => meta,
);

/**
 * @param {Message} message
 * @return {FetchGetSucceeded}
 */
export const fetchGetMessagesSuccessActionCreator = createAction(
  getActionType('messages', 'GET_SUCCEEDED'),
  (message: Message) => message,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetMessagesErrorActionCreator = createAction(
  getActionType('messages', 'GET_FAILED'),
  (message: string) => message,
);

/**
 * @param {Message} message
 * @return {FetchEditSucceeded}
 */
export const fetchEditMessagesSuccessActionCreator = createAction(
  getActionType('messages', 'EDIT_SUCCEEDED'),
  (message: Message) => message,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditMessagesErrorActionCreator = createAction(
  getActionType('messages', 'EDIT_FAILED'),
  (message: string) => message,
);

/**
 * @param {Message} message
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditMessagesActionCreator = createAction(
  getActionType('messages', 'EDIT_REQUESTED'),
  (message: Message) => message,
  (message, meta: Meta = defaultMeta) => meta,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteMessagesActionCreator = createAction(
  getActionType('messages', 'DELETE_REQUESTED'),
  (id: Message['id']) => id,
  (message, meta: Meta = defaultMeta) => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteMessagesSuccessActionCreator = createAction(
  getActionType('messages', 'DELETE_SUCCEEDED'),
  (id: Message['id']) => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteMessagesErrorActionCreator = createAction(
  getActionType('messages', 'DELETE_FAILED'),
  (message: string) => message,
);
