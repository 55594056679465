import { withRouter } from 'react-router';
import LocationInfo from '../../components/layout/location/LocationInfo';
import React, { ReactElement } from 'react';
import {
  locationTypes,
  locationSelectors,
  locationActions,
} from '../../../states/ducks/locations';
import {
  organizationTypes,
  organizationSelectors,
  organizationActions,
} from '../../../states/ducks/organizations';
import {
  locationTypeSelectors,
  locationTypeTypes,
} from '../../../states/ducks/locationtypes';
import { State } from '../../../states/types';
import { connect } from 'react-redux';
import { organizationsIsGettingSelector } from '../../../states/ducks/organizations/selectors/base';
import { locationTypeFromIdSelector } from '../../../states/ducks/locationtypes/selectors';
import { LocationType } from '../../../states/ducks/locationtypes/types';
import { filesFromMultipleIdsSelector } from '../../../states/ducks/files/selectors';
import { chronologicalSortBy } from '../../../utils/SortUtils';
import { File } from '../../../states/ducks/files/types';
import { fetchGetFilesActionCreator } from '../../../states/ducks/files/actions';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface MapStateToProps {
  location: locationTypes.Location | null;
  organization: organizationTypes.Organization | null;
  organizationLoading: boolean;
  locationLoading: boolean;
  locations: locationTypes.Location[];
  locationTypes: locationTypeTypes.LocationType[];
  isGettingLocation: boolean;
  locationType: LocationType | null;
  files: File[];
}

interface MapDispatchToProps {
  getLocation: Function;
  getOrganization: Function;
  editLocationDesignation: Function;
  editLocationOrganization: Function;
  editLocationTypeId: Function;
  editLocationAddress: Function;
  editLocationRemark: Function;
  loadLocations: Function;
  deleteLocation: Function;
  getFile: Function;
  addFileLocation: Function;
  addGEDFileLocation: Function;
  deleteFileLocation: Function;
}

const mapStateToProps = (
  state: State,
  props: { locationId: string },
): MapStateToProps => {
  const location = locationSelectors.locationFromIdSelector(
    state,
    props.locationId,
  );

  const organization =
    location !== null && typeof location.organizationId !== 'undefined'
      ? organizationSelectors.organizationFromIdSelector(
          state,
          location.organizationId,
        )
      : null;

  const locations = location
    ? locationSelectors.locationsTreeSelector(state, location).children || []
    : [];

  const locationType =
    location && location.locationTypeId
      ? locationTypeFromIdSelector(state, location.locationTypeId)
      : null;

  const allFiles =
    location && location.fileIds
      ? filesFromMultipleIdsSelector(state, location.fileIds)
      : [];

  allFiles.sort((a, b) => chronologicalSortBy(a.uploadedAt, b.uploadedAt));

  const files = allFiles.filter(
    (file) => !file.fileMimeType?.includes('image/'),
  );

  return {
    organization,
    location,
    organizationLoading: organizationsIsGettingSelector(state),
    locationLoading: locationSelectors.locationsIsGettingSelector(state),
    locations: locations,
    locationTypes:
      locationTypeSelectors.locationTypesFromOrganizationSelector(state),
    isGettingLocation: locationSelectors.locationsIsGettingSelector(state),
    locationType: locationType,
    files: files,
    ...props,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getLocation: locationActions.fetchGetLocationsActionCreator,
  getOrganization: organizationActions.fetchGetOrganizationsActionCreator,
  editLocationDesignation:
    locationActions.fetchEditLocationsDesignationActionCreator,
  editLocationOrganization:
    locationActions.fetchEditLocationsOrganizationActionCreator,
  editLocationTypeId: locationActions.fetchEditLocationsTypeIdActionCreator,
  editLocationAddress: locationActions.fetchEditLocationsAddressActionCreator,
  editLocationRemark: locationActions.fetchEditLocationsRemarkActionCreator,
  loadLocations: locationActions.fetchListLocationsActionCreator,
  deleteLocation: locationActions.fetchDeleteLocationsActionCreator,
  getFile: fetchGetFilesActionCreator,
  addFileLocation: locationActions.fetchAddFileLocationsActionCreator,
  addGEDFileLocation: locationActions.fetchAddGEDFileLocationsActionCreator,
  deleteFileLocation: locationActions.fetchDeleteFileLocationsActionCreator,
};

const LocationInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationInfo);

export default withRouter(
  (props: Props): ReactElement => (
    <LocationInfoContainer locationId={props.match.params.id} />
  ),
);
