export const descriptions = {
  incident: {
    incidentAccepted: "a mis à jour le statut de l'incident",
    incidentFilesAdded: 'a ajouté {count-fileIds} pièce(s) jointe(s)',
    incidentFileRemoved: 'a supprimé 1 pièce jointe',
    incidentRejected: "a mis à jour le statut de l'incident",
    incidentRelocated: "a mis à jour la localisation de l'incident",
    incidentRemarked: "a mis a jour le commentaire de l'incident",
    incidentReportingChanged: "a mis a jour le signalement de l'incident",
    incidentResolved: "a mis à jour le statut de l'incident",
    incidentRetyped: "a mis a jour le type de l'incident",
  },
  request: {
    requestAssigned: 'a mis à jour le responsable de la demande',
    requestClosed: 'a mis à jour le statut de la demande',
    requestEdited: 'a mis à jour la demande',
    requestEditorCreated: 'a mis à jour le créateur de la demande',
    requestOrganizationChanged: "a mis à jour l'organisation de la demande",
  },
};
