import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import './Filter.scss';

export interface FilterProps {
  component: ReactElement;
  filterName: string;
  filter: Function;
  filterValue?: string[];
  onChange?: Function;
  onChangeFormatValue?: Function;
}

/**
 * @param {any} props
 * @return {ReactElement}
 */
const Filter: FunctionComponent<FilterProps> = ({ ...props }): ReactElement =>
  React.cloneElement(props.component, {
    className: 'filter',
    value: props.filterValue || [],
    onChange: props.onChange,
  });

Filter.propTypes = {
  component: PropTypes.element.isRequired,
  filterName: PropTypes.string.isRequired,
  filter: PropTypes.func.isRequired,
  filterValue: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func,
  onChangeFormatValue: PropTypes.func,
};

export default Filter;
