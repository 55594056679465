import { withRouter, RouteComponentProps, StaticContext } from 'react-router';
import Menu from '../../components/layout/container/sidebar/Sidebar';
import React, { ReactElement } from 'react';

export default withRouter(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: RouteComponentProps<any, StaticContext, any>): ReactElement => (
    <Menu {...props} />
  ),
);
