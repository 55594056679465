import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface LocationType extends Model {
  name: string;
  organizationId: string;
  isMain: boolean;
  isQuickAccess: boolean;
  createdAt: string;
  updatedAt: string;
}

/**
 * main state of application
 */

export interface LocationTypeState {
  locationTypes: LocationType[];
  status: {
    adding: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

export const PropTypesLocationType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  isMain: PropTypes.bool.isRequired,
  isQuickAccess: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});
