import React, { ReactElement, FunctionComponent, useState } from 'react';
import { Layout, Menu, Dropdown } from 'antd';
import 'antd/lib/layout/style';
import 'antd/lib/menu/style';
import 'antd/lib/dropdown/style';
import { CurrentUserAvatar, RestrictedToPermissions } from '../../../base';
import './Header.scss';
import { Authenticate } from '../../../../../services/authenticate/types';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { State } from '../../../../../states/types';
import { withAuthenticate } from '../../../../../services/authenticate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logoutActionCreator } from '../../../../../states/ducks/common/actions';
import ChangeAdminOrganizations from '../../../../containers/modal/organization/ChangeAdminOrganizations';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../../states/ducks/currentUser/types';
import {
  Profile,
  PropTypesProfile,
} from '../../../../../states/ducks/profiles/types';
import { profileFromUserIdSelector } from '../../../../../states/ducks/profiles/selectors';
import historyPropTypes from '../../../../../utils/propTypes/historyPropTypes';

interface Props extends RouteComponentProps {
  authenticate: Authenticate;
  currentUser: CurrentUser;
  profile: Profile | null;
  logout: Function;
}

const Header: FunctionComponent<Props> = ({
  currentUser,
  profile,
  logout,
  ...props
}): ReactElement => {
  const [
    changeAdminOrganizationsModalVisible,
    setChangeAdminOrganizationsModalVisible,
  ] = useState(false);

  const UserDropdown = (
    <Menu
      onClick={(menuInfo): void => {
        switch (menuInfo.key) {
          case 'myaccount':
            props.history.push('/myaccount');
            break;
          case 'organization-change':
            setChangeAdminOrganizationsModalVisible(
              menuInfo.key === 'organization-change',
            );
            break;
          case 'unauthenticate':
            props.authenticate.unauthentication().then(() => {
              logout();
              props.history.push('/login');
            });
            break;
        }
      }}
    >
      <Menu.Item key="myaccount">
        {profile ? `${profile.firstName} ${profile.lastName}` : ''}
      </Menu.Item>
      <RestrictedToPermissions
        subject={'admin'}
        action={'admin'}
        key="organization-change"
      >
        <Menu.Item>{"Changer d'organisation"}</Menu.Item>
      </RestrictedToPermissions>
      <Menu.Item key="unauthenticate">Déconnexion</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout.Header className="app-header">
        {process.env.REACT_APP_ENV !== 'production' ? (
          <span className="environment">Version test</span>
        ) : (
          <></>
        )}
        <Dropdown overlay={UserDropdown} placement="bottomRight">
          <CurrentUserAvatar size="large" />
        </Dropdown>
      </Layout.Header>

      <ChangeAdminOrganizations
        currentUser={currentUser}
        visible={changeAdminOrganizationsModalVisible}
        hideModal={(): void => setChangeAdminOrganizationsModalVisible(false)}
      />
    </>
  );
};

Header.propTypes = {
  authenticate: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    authentication: PropTypes.func.isRequired,
    unauthentication: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.exact(historyPropTypes).isRequired,
  currentUser: PropTypesCurrentUser.isRequired,
  profile: PropTypesProfile,
  logout: PropTypes.func.isRequired,
};

interface MapStateToProps {
  profile: Profile | null;
}

interface MapDispatchToProps {
  logout: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    currentUser: CurrentUser;
  },
): MapStateToProps => {
  return {
    profile: props.currentUser.id
      ? profileFromUserIdSelector(state, props.currentUser.id)
      : null,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  logout: logoutActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAuthenticate(withRouter(Header)));
