import PropTypes from 'prop-types';
import { Model, ModelState } from '../common/types';
import { Pagination } from '../../../services/search/search';

export interface Message extends Model {
  content: string;
  createdAt: string;
  objectId: string;
  objectType: string;
  organizationId: string;
  parentId?: string | null;
  updatedAt: string;
  userId: string;
}

export interface MessageState extends ModelState {
  messages: Message[];
  pagination: Pagination | null;
}

export const PropTypesMessage = PropTypes.shape({
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
});
