import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { connect, DispatchProp } from 'react-redux';
import PropTypes from 'prop-types';
import { currentUserSelector } from '../../../../states/ducks/currentUser/selectors';
import {
  CurrentUser,
  PropTypesCurrentUser,
} from '../../../../states/ducks/currentUser/types';
import { State } from '../../../../states/types';
import { AnyAction } from 'redux';

export interface RestrictedToPermissionsProps {
  action: string;
  currentUser?: CurrentUser | null;
  subject: string;
  unauthorizedText?: ReactElement | null;
}

/**
 * @param {string} action
 * @param {string} subject
 * @param {CurrentUser} currentUser
 * @param {ReactNode} children
 * @param {ReactNode} unauthorizedText
 * @param {DispatchProp<AnyAction>} dispatch
 * @param {any} props
 *
 * @return {ReactElement}
 */
const RestrictedToPermissions: FunctionComponent<
  PropsWithChildren<RestrictedToPermissionsProps & DispatchProp<AnyAction>>
> = ({
  action,
  subject,
  currentUser,
  children,
  unauthorizedText,
  dispatch,
  ...props
}): ReactElement => {
  if (
    !currentUser ||
    !currentUser.permissions ||
    0 === currentUser.permissions.length ||
    !children
  ) {
    return <></>;
  }

  const permission = currentUser.permissions.find(
    (permission) =>
      permission.action === action && permission.subject === subject,
  );

  if ('undefined' === typeof permission) {
    return unauthorizedText ? <>{unauthorizedText}</> : <></>;
  }

  if (React.isValidElement(children)) {
    return React.cloneElement(children, props);
  }

  return <>{children}</>;
};

const propTypes = {
  action: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  currentUser: PropTypesCurrentUser,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  dispatch: PropTypes.any,
  unauthorizedText: PropTypes.element,
};

export const PropTypesRestrictedToPermissions = PropTypes.shape(propTypes);

RestrictedToPermissions.propTypes = propTypes;

interface MapStateToProps {
  currentUser: CurrentUser | null;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  currentUser: currentUserSelector(state),
});

export default connect(mapStateToProps)(RestrictedToPermissions);
