import React, { FunctionComponent, ReactElement } from 'react';
import './Maps.scss';
import PropTypes from 'prop-types';
import GoogleMapReact, { Coords } from 'google-map-react';
import { EnvironmentFilled } from '@ant-design/icons';

interface Props {
  centerLatLng?: Coords;
  markers?: Coords[];
}

const Maps: FunctionComponent<Props> = ({
  centerLatLng,
  markers,
}): ReactElement => (
  <div className={'app-maps'}>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_API_KEY || '',
      }}
      center={centerLatLng}
      defaultZoom={13}
    >
      {markers ? (
        markers.map((marker, index): ReactElement => {
          const coordProps = {
            lat: marker.lat,
            lng: marker.lng,
          };

          return (
            <div key={'marker' + index} {...coordProps}>
              <EnvironmentFilled />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </GoogleMapReact>
  </div>
);

Maps.propTypes = {
  centerLatLng: PropTypes.any,
  markers: PropTypes.arrayOf(PropTypes.any),
};

export default Maps;
