import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  addContractor,
  listContractors,
  getContractor,
  editContractorName,
  editContractorTwimmLink,
  editContractorIncidentTypes,
  editContractorOrganization,
  deleteContractor,
  deleteFile,
  addFile,
  removeFileContractor,
  addFilesContractor,
  editContractorEmail,
} from '../../../services/api';
import { getCurrentUserId } from '../../../services/authenticate';
import {
  FetchAddRequested,
  FetchDeleteRequested,
  FetchEditRequested,
  FetchGetRequested,
} from '../common/actions';
import { getActionType } from '../common/types';
import * as actions from './actions';
import { Contractor } from './types';

/**
 * @param {FetchAddRequested} action
 */
export function* addContractorSaga(
  action: FetchAddRequested<Contractor>,
): SagaIterator {
  try {
    const id: Contractor['id'] = yield call(addContractor, action.payload);

    const contractor = {
      ...action.payload,
      id,
    } as Contractor;

    yield put(actions.fetchAddContractorsSuccessActionCreator(contractor));

    action.meta.resolve(contractor);
  } catch (err) {
    yield put(actions.fetchAddContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* fetchContractorSaga(): SagaIterator {
  try {
    const contractors: Contractor[] = yield call(listContractors);

    yield put(actions.fetchListContractorsSuccessActionCreator(contractors));
  } catch (err) {
    yield put(actions.fetchListContractorsErrorActionCreator(err.message));
  }
}

/**
 * @param {FetchGetRequested} action
 */
export function* getContractorSaga(
  action: FetchGetRequested<Contractor>,
): SagaIterator {
  try {
    const contractor: Contractor = yield call(getContractor, action.payload);

    yield put(actions.fetchGetContractorsSuccessActionCreator(contractor));

    action.meta.resolve(contractor);
  } catch (err) {
    yield put(actions.fetchGetContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editContractorNameSaga(
  action: FetchEditRequested<Contractor>,
): SagaIterator {
  try {
    yield call(editContractorName, action.payload);

    yield put(actions.fetchEditContractorsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editContractorEmailSaga(
  action: FetchEditRequested<Contractor>,
): SagaIterator {
  try {
    yield call(editContractorEmail, action.payload);

    yield put(actions.fetchEditContractorsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editContractorTwimmLinkSaga(
  action: FetchEditRequested<Contractor>,
): SagaIterator {
  try {
    yield call(editContractorTwimmLink, action.payload);

    yield put(actions.fetchEditContractorsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editContractorIncidentTypesSaga(
  action: FetchEditRequested<Contractor>,
): SagaIterator {
  try {
    yield call(editContractorIncidentTypes, action.payload);

    yield put(actions.fetchEditContractorsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchEditRequested} action
 */
export function* editContractorOrganizationSaga(
  action: FetchEditRequested<Contractor>,
): SagaIterator {
  try {
    yield call(editContractorOrganization, action.payload);

    yield put(actions.fetchEditContractorsSuccessActionCreator(action.payload));

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addFileContractorSaga(
  action: actions.FetchAddFileRequested,
): SagaIterator {
  try {
    const contractor = action.payload.contractor;
    const file = action.payload.file;

    const fileId = yield call(addFile, {
      file: file,
      organizationId: contractor.organizationId || '',
      userId: getCurrentUserId() || '',
      objectId: contractor.id || '',
      objectType: 'contractor',
    });

    yield call(addFilesContractor, contractor, [fileId]);

    if (!contractor.fileIds) {
      contractor.fileIds = [];
    }
    contractor.fileIds.push(fileId);

    yield put(
      actions.fetchEditContractorsSuccessActionCreator(
        action.payload.contractor,
      ),
    );

    action.meta.resolve(action.payload.file);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(action.payload.file);
  }
}

/**
 * @param {FetchAddFileRequested} action
 */
export function* addGEDFileContractorSaga(
  action: actions.FetchAddGEDFileRequested,
): SagaIterator {
  try {
    const contractor = action.payload.contractor;
    const fileIds = action.payload.fileIds;

    yield call(addFilesContractor, contractor, fileIds);

    if (!contractor.fileIds) {
      contractor.fileIds = [];
    }

    contractor.fileIds = contractor.fileIds.concat(fileIds);

    yield put(
      actions.fetchEditContractorsSuccessActionCreator(
        action.payload.contractor,
      ),
    );

    action.meta.resolve(action.payload.fileIds);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(action.payload.fileIds);
  }
}

/**
 * @param {FetchDeleteFileRequested} action
 */
export function* deleteFileContractorSaga(
  action: actions.FetchDeleteFileRequested,
): SagaIterator {
  try {
    const fileId = action.payload.fileId;
    const contractor = action.payload.contractor;

    yield call(deleteFile, fileId);

    yield call(removeFileContractor, contractor, fileId);

    if (!contractor.fileIds) {
      contractor.fileIds = [];
    }
    contractor.fileIds = contractor.fileIds.filter((id) => id !== fileId);

    yield put(
      actions.fetchEditContractorsSuccessActionCreator(
        action.payload.contractor,
      ),
    );

    action.meta.resolve(action.payload.contractor);
  } catch (err) {
    yield put(actions.fetchEditContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @param {FetchDeleteRequested} action
 */
export function* deleteContractorSaga(
  action: FetchDeleteRequested<Contractor>,
): SagaIterator {
  try {
    yield call(deleteContractor, action.payload);

    yield put(
      actions.fetchDeleteContractorsSuccessActionCreator(action.payload),
    );

    action.meta.resolve(action.payload);
  } catch (err) {
    yield put(actions.fetchDeleteContractorsErrorActionCreator(err.message));

    action.meta.reject(err.message);
  }
}

/**
 * @yields {SagaIterator}
 */
export function* contractorsSagas(): SagaIterator {
  yield takeEvery(
    getActionType('contractors', 'ADD_REQUESTED'),
    addContractorSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'LIST_REQUESTED'),
    fetchContractorSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'GET_REQUESTED'),
    getContractorSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'EDIT_NAME_REQUESTED'),
    editContractorNameSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'EDIT_EMAIL_REQUESTED'),
    editContractorEmailSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'EDIT_TWIMM_LINK_REQUESTED'),
    editContractorTwimmLinkSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'EDIT_INCIDENT_TYPES_REQUESTED'),
    editContractorIncidentTypesSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'EDIT_ORGANIZATIONS_REQUESTED'),
    editContractorOrganizationSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'ADD_GED_FILE_REQUESTED'),
    addGEDFileContractorSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'DELETE_FILE_REQUESTED'),
    deleteFileContractorSaga,
  );
  yield takeEvery(
    getActionType('contractors', 'DELETE_REQUESTED'),
    deleteContractorSaga,
  );
}
