import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  incidentTypes,
  incidentSelectors,
  incidentActions,
} from '../../../../states/ducks/incidents';
import {
  historyActions,
  historySelectors,
} from '../../../../states/ducks/histories';
import { userTypes } from '../../../../states/ducks/users';
import {
  profileTypes,
  profileActions,
  profileSelectors,
} from '../../../../states/ducks/profiles';
import { Alert } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import moment from 'moment';

interface IncidentRejectAlertProps {
  incidentId: incidentTypes.Incident['id'];
  incident: incidentTypes.Incident | null;
  rejectUserId: userTypes.User['id'] | null;
  rejectProfile: profileTypes.Profile | null;
  rejectDate: string | null;
  getIncident: Function;
  loadHistories: Function;
  getProfile: Function;
}

/**
 * @param {string} incidentId
 * @param {Incident} incident
 * @param {string} rejectUserId
 * @param {Profile} rejectProfile
 * @param {string} rejectDate
 * @param {Function} getIncident
 * @param {Function} loadHistories
 * @param {Function} getProfile
 *
 * @return {ReactElement}
 */
const IncidentRejectAlert: FunctionComponent<IncidentRejectAlertProps> = ({
  incidentId,
  incident,
  rejectUserId,
  rejectProfile,
  rejectDate,
  getIncident,
  loadHistories,
  getProfile,
}): ReactElement => {
  useEffect((): void => {
    if (getIncident && !incident) {
      getIncident(incidentId);
    }
  }, [getIncident, incidentId, incident]);

  useEffect((): void => {
    if (loadHistories && incident) {
      loadHistories('incident', incident.id);
    }
  }, [loadHistories, incident]);

  useEffect((): void => {
    if (getProfile && !rejectProfile && rejectUserId) {
      getProfile(rejectUserId);
    }
  }, [getProfile, rejectProfile, rejectUserId]);

  if (incident?.status !== 'rejected') {
    return <></>;
  }

  let message = '';
  if (rejectProfile) {
    message += `Incident refusé par ${rejectProfile?.firstName} ${rejectProfile?.lastName}`;
    if (rejectDate) {
      message += `, le ${rejectDate}`;
    }
    if (incident.rejectReason) {
      message += ` : "${incident?.rejectReason}"`;
    }
  }

  if (0 === message.length) {
    return <></>;
  }

  return (
    <Alert
      message={message}
      type="error"
      icon={<CloseCircleFilled />}
      showIcon
    />
  );
};

IncidentRejectAlert.propTypes = {
  incidentId: PropTypes.string.isRequired,
  incident: incidentTypes.PropTypesIncident,
  rejectUserId: PropTypes.string,
  rejectProfile: profileTypes.PropTypesProfile,
  rejectDate: PropTypes.string,
  getIncident: PropTypes.func.isRequired,
  loadHistories: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
};

interface MapStateToProps {
  incident: incidentTypes.Incident | null;
  rejectUserId: userTypes.User['id'] | null;
  rejectProfile: profileTypes.Profile | null;
  rejectDate: string | null;
}

interface MapDispatchToProps {
  getIncident: Function;
  loadHistories: Function;
  getProfile: Function;
}

const mapStateToProps = (
  state: State,
  props: {
    incidentId: string;
  },
): MapStateToProps => {
  const incident = incidentSelectors.incidentFromIdSelector(
    state,
    props.incidentId,
  );

  const histories =
    historySelectors.historiesFromIncidentIdSelector(state, props.incidentId) ||
    [];

  const rejectHistory = histories.find(
    (history) =>
      history.to.hasOwnProperty('status') && history.to.status === 'rejected',
  );

  const rejectUserId = rejectHistory ? rejectHistory.userId : null;
  const rejectDate =
    rejectHistory && rejectHistory.date
      ? moment(rejectHistory.date).format('L [à] H[h]mm')
      : null;

  const rejectProfile = rejectUserId
    ? profileSelectors.profileFromUserIdSelector(state, rejectUserId)
    : null;

  return {
    incident: incident,
    rejectUserId: rejectUserId,
    rejectProfile: rejectProfile,
    rejectDate: rejectDate,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  getIncident: incidentActions.fetchGetIncidentsActionCreator,
  loadHistories: historyActions.fetchListHistoriesActionCreator,
  getProfile: profileActions.fetchGetProfilesByUserIdActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentRejectAlert);
