import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { getActionType } from '../common/types';
import { UploadFile } from 'antd/lib/upload/interface';

/**
 * @return {FetchListRequested}
 */
export const fetchListLocationsActionCreator = createAction(
  getActionType('locations', 'LIST_REQUESTED'),
);

/**
 * @param {Location[]} locations
 * @return {FetchListSucceeded}
 */
export const fetchListLocationsSuccessActionCreator = createAction(
  getActionType('locations', 'LIST_SUCCEEDED'),
  (locations: types.Location[]): types.Location[] => locations,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListLocationsErrorActionCreator = createAction(
  getActionType('locations', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Location} location
 * @return {FetchAddRequested}
 */
export const fetchAddLocationsActionCreator = createAction(
  getActionType('locations', 'ADD_REQUESTED'),
  (location: types.Location): types.Location => location,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Location} location
 * @return {FetchAddSucceeded}
 */
export const fetchAddLocationsSuccessActionCreator = createAction(
  getActionType('locations', 'ADD_SUCCEEDED'),
  (location: types.Location): types.Location => location,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchAddLocationsErrorActionCreator = createAction(
  getActionType('locations', 'ADD_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditLocationsDesignationActionCreator = createAction(
  getActionType('locations', 'EDIT_DESIGNATION_REQUESTED'),
  (location: types.Location): types.Location => location,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditLocationsOrganizationActionCreator = createAction(
  getActionType('locations', 'EDIT_ORGANIZATIONS_REQUESTED'),
  (location: types.Location): types.Location => location,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditLocationsTypeIdActionCreator = createAction(
  getActionType('locations', 'EDIT_TYPE_ID_REQUESTED'),
  (location: types.Location): types.Location => location,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditLocationsAddressActionCreator = createAction(
  getActionType('locations', 'EDIT_ADDRESS_REQUESTED'),
  (location: types.Location): types.Location => location,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchEditLocationsRemarkActionCreator = createAction(
  getActionType('locations', 'EDIT_REMARK_REQUESTED'),
  (location: types.Location): types.Location => location,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Location} location
 * @return {FetchEditSucceeded}
 */
export const fetchEditLocationsSuccessActionCreator = createAction(
  getActionType('locations', 'EDIT_SUCCEEDED'),
  (location: types.Location): types.Location => location,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditLocationsErrorActionCreator = createAction(
  getActionType('locations', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {File} file
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchAddFileRequested}
 */
export const fetchAddFileLocationsActionCreator = createAction(
  getActionType('locations', 'ADD_FILE_REQUESTED'),
  (payload: {
    file: UploadFile;
    location: types.Location;
  }): {
    file: UploadFile;
    location: types.Location;
  } => payload,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {File} file
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchAddGEDFileRequested}
 */
export const fetchAddGEDFileLocationsActionCreator = createAction(
  getActionType('locations', 'ADD_GED_FILE_REQUESTED'),
  (payload: {
    fileIds: string[];
    location: types.Location;
  }): {
    fileIds: string[];
    location: types.Location;
  } => payload,
  (location, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} fileId
 * @param {Location} location
 * @param {Meta} meta
 * @return {FetchDeleteFileRequested}
 */
export const fetchDeleteFileLocationsActionCreator = createAction(
  getActionType('locations', 'DELETE_FILE_REQUESTED'),
  (payload: {
    fileId: string;
    location: types.Location;
  }): {
    fileId: string;
    location: types.Location;
  } => payload,
  (contractor, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetLocationsActionCreator = createAction(
  getActionType('locations', 'GET_REQUESTED'),
  (id: types.Location['id']): types.Location['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Location} location
 * @return {FetchGetSucceeded}
 */
export const fetchGetLocationsSuccessActionCreator = createAction(
  getActionType('locations', 'GET_SUCCEEDED'),
  (location: types.Location): types.Location => location,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetLocationsErrorActionCreator = createAction(
  getActionType('locations', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteLocationsActionCreator = createAction(
  getActionType('locations', 'DELETE_REQUESTED'),
  (id: types.Location['id']): types.Location['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteLocationsSuccessActionCreator = createAction(
  getActionType('locations', 'DELETE_SUCCEEDED'),
  (id: types.Location['id']): types.Location['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteLocationsErrorActionCreator = createAction(
  getActionType('locations', 'DELETE_FAILED'),
  (message: string): string => message,
);

export type FetchAddFileRequested = ReturnType<
  typeof fetchAddFileLocationsActionCreator
>;

export type FetchAddGEDFileRequested = ReturnType<
  typeof fetchAddGEDFileLocationsActionCreator
>;

export type FetchDeleteFileRequested = ReturnType<
  typeof fetchDeleteFileLocationsActionCreator
>;
