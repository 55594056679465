import React, { ReactElement } from 'react';
import {
  FileGifOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileUnknownOutlined,
  FileZipOutlined,
  FileTextOutlined,
  FileImageOutlined,
  FilePptOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

/**
 * FileTypeUtils
 */
export default class FileTypeUtils {
  /**
   * @param {string} extension
   * @return {ReactElement}
   */
  static getIcon(extension: string): ReactElement {
    switch (extension) {
      case 'bmp':
      case 'jpe':
      case 'jpeg':
      case 'jpg':
      case 'png':
        return <FileImageOutlined />;
      case 'doc':
      case 'docx':
        return <FileWordOutlined />;
      case 'gif':
        return <FileGifOutlined />;
      case 'gz':
      case 'zip':
      case 'rar':
        return <FileZipOutlined />;
      case 'odp':
      case 'pps':
      case 'ppt':
        return <FilePptOutlined />;
      case 'odt':
      case 'rtf':
      case 'txt':
        return <FileTextOutlined />;
      case 'ods':
      case 'xls':
      case 'xlsx':
        return <FileExcelOutlined />;
      case 'pdf':
        return <FilePdfOutlined />;
      default:
        return <FileUnknownOutlined />;
    }
  }
}
