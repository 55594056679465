import PropTypes from 'prop-types';
import { Model } from '../common/types';
import { Permission, PropTypesPermission } from '../permissions/types';

export interface Role extends Model {
  name: string;
  style: string;
  organizationId: string;
  permissions: Permission[];
  createdAt: string;
  updatedAt: string;
  description?: string | null;
}

export interface RoleState {
  roles: Role[];
  status: {
    adding: boolean;
    listing: boolean;
    getting: boolean;
    editing: boolean;
    deleting: boolean;
  };
}

export const PropTypesRole = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypesPermission.isRequired).isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  description: PropTypes.string,
});
