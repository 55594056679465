import React, { FunctionComponent, ReactElement } from 'react';
import { PageHeader as PageHeaderAnt } from 'antd';
import 'antd/lib/page-header/style';
import { PageHeaderProps } from 'antd/lib/page-header';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './PageHeader.scss';

/**
 * @param {Props} props
 *
 * @return {ReactElement}
 */
const PageHeader: FunctionComponent<PageHeaderProps> = ({
  ...props
}): ReactElement => (
  <PageHeaderAnt
    className={classNames('app-page-header', props.className)}
    {...props}
  />
);

PageHeader.propTypes = {
  className: PropTypes.string,
};

export default PageHeader;
