import React, { FunctionComponent, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Button, notification } from '../index';
import { Modal } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { ButtonSize } from 'antd/lib/button';
import { EllipsisOutlined, WarningOutlined } from '@ant-design/icons';
import { getDownloadPath } from '../../../../services/api';

interface PopoverActionsProps extends PopoverProps {
  objectId: string;
  onDelete?: Function;
  deleteConfirmMessage?: string;
  deleteDoneMessage?: string;
  size?: ButtonSize;
  deleteText?: string;
  redirect?: Function;
  download?: boolean;
}

/**
 * @param {string} objectId
 * @param {Function} onDelete
 * @param {string} deleteConfirmMessage
 * @param {string} deleteDoneMessage
 * @param {string} size
 * @param {boolean} download
 * @param {PopoverProps} popoverProps
 *
 * @return {ReactElement}
 */
const PopoverActions: FunctionComponent<PopoverActionsProps> = ({
  objectId,
  onDelete,
  deleteConfirmMessage,
  deleteDoneMessage,
  size,
  deleteText,
  redirect,
  download,
  ...popoverProps
}): ReactElement => {
  const [btnVisible, setbtnVisible] = useState('');
  const { confirm } = Modal;

  const showDeleteConfirm = (id: string): void => {
    confirm({
      className: 'app-modal-delete',
      title: deleteConfirmMessage,
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      icon: <WarningOutlined />,
      maskClosable: true,
      onOk(): void {
        if (onDelete) {
          const promise = new Promise<void>((resolve, reject): void =>
            onDelete(id, { resolve, reject }),
          );

          promise
            .then((): void => {
              notification.success({
                message: deleteDoneMessage,
              });
            })
            .then(() => {
              if (redirect !== undefined) {
                return redirect();
              }
              return;
            })
            .catch((message: string): void => {
              notification.error({ message });
            });
        }
      },
    });
  };

  const deleteOption = onDelete ? (
    <div
      className="app-item-popover delete"
      onClick={(e): void => {
        e.stopPropagation();
        showDeleteConfirm(objectId);
        setbtnVisible('');
      }}
    >
      {deleteText}
    </div>
  ) : (
    ''
  );

  return (
    <Popover
      content={
        <>
          {download ? (
            <a
              className="app-item-popover"
              href={getDownloadPath(objectId)}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Télécharger
            </a>
          ) : (
            <></>
          )}
          {deleteOption}
        </>
      }
      trigger="click"
      placement={popoverProps.placement}
      onVisibleChange={(visible): void => {
        setbtnVisible(visible ? '1' : '');
      }}
    >
      <Button
        icon={<EllipsisOutlined />}
        size={size}
        onClick={(e): void => {
          e.stopPropagation();
        }}
        style={{ opacity: btnVisible }}
      />
    </Popover>
  );
};

PopoverActions.propTypes = {
  objectId: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  deleteConfirmMessage: PropTypes.string,
  deleteDoneMessage: PropTypes.string,
  placement: PropTypes.oneOf([
    'top',
    'left',
    'right',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'leftTop',
    'leftBottom',
    'rightTop',
    'rightBottom',
  ]),
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  deleteText: PropTypes.string,
  redirect: PropTypes.func,
  download: PropTypes.bool,
};

PopoverActions.defaultProps = {
  deleteConfirmMessage: `Supprimer l'objet ?`,
  deleteDoneMessage: `L'objet a bien été supprimé`,
  placement: 'bottom',
  size: 'middle',
  deleteText: 'Supprimer',
  download: false,
};

export default PopoverActions;
