import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import 'antd/lib/select/style';
import { SelectProps, SelectValue } from 'antd/lib/select';
import PropTypes from 'prop-types';
import { InputSearch, Select } from '../index';
import './FilterableSelect.scss';
import OutsideClickHandler from 'react-outside-click-handler';

/**
 * @param {SelectProps} props
 * @return {ReactElement}
 */
const FilterableSelect: FunctionComponent<
  PropsWithChildren<SelectProps<SelectValue>>
> = ({ ...props }): ReactElement => {
  const initialSelectedValues: SelectValue[] = [];
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  if (props.children && Array.isArray(props.children)) {
    props.children = props.children
      .filter((child: ReactNode): boolean => {
        if (!React.isValidElement(child)) {
          return false;
        }

        return (
          typeof child.props.title === 'string' &&
          child.props.title.toLowerCase().includes(search)
        );
      })
      .sort((a: ReactNode, b: ReactNode): number => {
        if (React.isValidElement(a) && selectedValues.includes(a.props.value)) {
          return -1;
        }
        if (React.isValidElement(b) && selectedValues.includes(b.props.value)) {
          return 1;
        }

        return 0;
      });
  }

  return (
    <div className="outside-click-handler-container">
      <OutsideClickHandler onOutsideClick={(): void => setIsOpen(false)}>
        <div
          onClick={(e): void => {
            if (
              e.target instanceof HTMLElement &&
              e.target.className.includes('ant-select-selection')
            ) {
              setIsOpen(!isOpen);
            }
          }}
        >
          <Select
            {...props}
            showSearch
            className={'filter filterable-select'}
            mode={'multiple'}
            showArrow
            maxTagCount={1}
            maxTagPlaceholder={(value): string => `+${value.length}`}
            onInputKeyDown={(e): void => {
              e.preventDefault();
            }}
            dropdownRender={(menu): React.ReactElement => (
              <>
                <div className={'search-container'}>
                  <InputSearch
                    size="small"
                    placeholder={'Recherche'}
                    onChange={(e): void =>
                      setSearch(e.target.value.toLowerCase())
                    }
                  />
                </div>
                {menu}
              </>
            )}
            onSelect={(value: SelectValue): void => {
              setSelectedValues([value, ...selectedValues]);
            }}
            onDeselect={(value: SelectValue): void => {
              setSelectedValues(
                selectedValues.filter(
                  (selectedValue): boolean => selectedValue !== value,
                ),
              );
            }}
            open={isOpen}
          />
        </div>
      </OutsideClickHandler>
    </div>
  );
};

FilterableSelect.propTypes = {
  mode: PropTypes.oneOf(['multiple', 'tags']),
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default FilterableSelect;
