import React, { FunctionComponent, ReactElement } from 'react';
import { Tooltip as TooltipAnt } from 'antd';
import 'antd/lib/icon/style';
import { TooltipProps } from 'antd/lib/tooltip';
import './Tooltip.scss';

/**
 * Tooltip component
 * @param {any} props
 * @return {ReactElement}
 */
const Tooltip: FunctionComponent<TooltipProps> = ({
  ...props
}): ReactElement => <TooltipAnt {...props} overlayClassName="app-tooltip" />;

Tooltip.propTypes = {};

export default Tooltip;
