import { connect } from 'react-redux';
import { State } from '../../../../states/types';
import ChangeAdminOrganizations from '../../../components/layout/modal/organization/ChangeAdminOrganizations';
import { userActions } from '../../../../states/ducks/users';
import { organizationSelectors } from '../../../../states/ducks/organizations';
import { CurrentUser } from '../../../../states/ducks/currentUser/types';

interface MapStateToProps {
  organizationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
}

const mapStateToProps = (
  state: State,
  props: {
    currentUser: CurrentUser;
  },
): MapStateToProps => {
  const organizationsAndTheirDescendantsIdsSelector =
    organizationSelectors.organizationsAndTheirDescendantsIdsSelector(state);

  return {
    organizationsAndTheirDescendantsIdsSelector,
  };
};

interface MapDispatchToProps {
  editUsersAdminOrganizations: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  editUsersAdminOrganizations:
    userActions.fetchEditUsersAdminOrganizationsActionCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeAdminOrganizations);
