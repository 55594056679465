import React, { FunctionComponent, ReactElement } from 'react';
import 'antd/lib/tag/style';
import PropTypes from 'prop-types';
import {
  FilterableSelect,
  IncidentStatusTag,
  Select,
  SelectOption,
} from '../index';
import IncidentStatusUtils from './IncidentStatusUtils';
import { SelectProps } from '../form/Select';

interface Props extends SelectProps {
  status?: string;
}

/**
 * Incident Status Select component
 * @param {string} status
 * @param {any} props empty props
 * @return {ReactElement} Col element
 */
const IncidentStatusSelect: FunctionComponent<Props> = ({
  status,
  ...props
}): ReactElement => {
  const options = IncidentStatusUtils.getSelectableIncidentStatus(
    status || null,
  ).filter((item): boolean => item !== status);
  if (status) {
    options.unshift(status);
  }

  const selectOptions = options.map((incidentStatus): ReactElement => {
    return (
      <SelectOption
        key={incidentStatus}
        value={incidentStatus}
        title={IncidentStatusUtils.getLabel(incidentStatus)}
      >
        {<IncidentStatusTag status={incidentStatus} />}
      </SelectOption>
    );
  });

  return props.isFilter ? (
    <FilterableSelect {...props}>{selectOptions}</FilterableSelect>
  ) : (
    <Select {...props}>{selectOptions}</Select>
  );
};

IncidentStatusSelect.propTypes = {
  status: PropTypes.string,
  isFilter: PropTypes.bool,
};

export default IncidentStatusSelect;
