import { WarningOutlined } from '@ant-design/icons';
import { Modal as AntModal } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import 'antd/lib/upload/style';
import PropTypes from 'prop-types';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Meta } from '../../../../states/ducks/common/actions';
import { Button, notification, Dropdown } from '../index';
import { Menu } from 'antd';
import InfoFile from './InfoFile';
import Upload, { UploadProps } from './Upload';
import './UploadList.scss';
import GetGED from './GetGED';
import { UploadOutlined } from '@ant-design/icons';

const { confirm } = AntModal;

export interface UploadListProps extends UploadProps {
  deleteErrorMessage?: string;
  deleteMessage?: string;
  deleteSuccessMessage?: string;
  displayLimit?: number;
  onDelete?: (file: UploadFile, meta: Meta) => void;
  limit?: number;
  onGEDUpload?: Function;
  onGEDClose?: Function;
}

/**
 * @param {string} deleteErrorMessage
 * @param {string} deleteMessage
 * @param {string} deleteSuccessMessage
 * @param {number} displayLimit
 * @param {Function} onDelete
 * @param {number} limit
 * @param {UploadProps} props
 * @return {ReactElement}
 */
const UploadList: FunctionComponent<UploadListProps> = ({
  deleteErrorMessage,
  deleteMessage,
  deleteSuccessMessage,
  displayLimit,
  onDelete,
  limit,
  onUpload,
  onGEDUpload,
  onGEDClose,
  ...props
}): ReactElement => {
  const [fileIdDisplayed, setFileIdDisplayed] = useState(
    undefined as string | undefined,
  );
  const [isDisplayLimited, setDisplayLimited] = useState(false);
  const [modalGEDVisible, setModalGEDVisible] = useState(false);

  useEffect((): void => {
    if (
      displayLimit &&
      props.fileList &&
      props.fileList.length > displayLimit
    ) {
      setDisplayLimited(true);
    }
  }, [displayLimit, props.fileList]);

  const onClick = (file: UploadFile): void => {
    setFileIdDisplayed(file.uid);
  };

  const onRemove = (file: UploadFile): boolean => {
    if (onDelete) {
      confirm({
        className: 'app-modal-delete',
        title: deleteMessage,
        okText: 'Supprimer',
        okType: 'danger',
        cancelText: 'Annuler',
        icon: <WarningOutlined />,
        maskClosable: true,
        onOk(): void {
          const promise = new Promise<void>((resolve, reject): void => {
            onDelete(file, { resolve, reject });
          });

          promise
            .then((): void => {
              notification.success({
                message: deleteSuccessMessage,
              });
            })
            .catch((): void => {
              notification.error({
                message: deleteErrorMessage,
              });
            });
        },
      });
    }

    return false;
  };

  if (limit && props.fileList && props.fileList.length > limit) {
    props.addButton = <></>;
  }

  const UploadButton = (
    <Upload
      addButton={<Button type="link">Importer depuis mes fichiers</Button>}
      showUploadList={false}
      onUpload={onUpload}
      {...props}
    />
  );

  const menu = (
    <Menu className="dropdown-menu-upload">
      <Menu.Item key="1">{UploadButton}</Menu.Item>
      <Menu.Item key="2">
        <Button type="link" onClick={(): void => setModalGEDVisible(true)}>
          Importer depuis la GED intwi
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {props.accept !== 'image/*' ? (
        <Upload
          addButton={
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary" icon={<UploadOutlined />}>
                Uploader
              </Button>
            </Dropdown>
          }
          openFileDialogOnClick={false}
          className={`upload-list ${isDisplayLimited ? 'limited' : ''}`}
          showUploadList={true}
          itemRender={(originItem, file): ReactElement => (
            <div
              style={{ height: '100%' }}
              onClick={(e): void => {
                // To prevent downloading file
                e.preventDefault();

                // @ts-ignore (to allow click on remove)
                const classNames = e.target.classList.value;
                if (!classNames.includes('ant-upload-list-item-name')) {
                  return;
                }

                onClick(file);
              }}
            >
              {originItem}
            </div>
          )}
          onDownload={onClick}
          onRemove={onRemove}
          {...props}
        />
      ) : (
        UploadButton
      )}
      {displayLimit &&
      props.fileList &&
      props.fileList.length > displayLimit ? (
        <Button
          type={'link'}
          className={'see-more'}
          onClick={(): void => setDisplayLimited(!isDisplayLimited)}
        >
          Voir {isDisplayLimited ? 'plus' : 'moins'}
        </Button>
      ) : (
        <></>
      )}
      <InfoFile
        visible={fileIdDisplayed !== undefined}
        hideDrawer={(): void => setFileIdDisplayed(undefined)}
        fileId={fileIdDisplayed}
      />
      <InfoFile
        visible={fileIdDisplayed !== undefined}
        hideDrawer={(): void => setFileIdDisplayed(undefined)}
        fileId={fileIdDisplayed}
      />
      <GetGED
        visible={modalGEDVisible}
        hideModal={(): void => {
          setModalGEDVisible(false);
          if (onGEDClose) {
            onGEDClose();
          }
        }}
        onGEDUpload={onGEDUpload}
        fileListUploaded={props.fileList}
      />
    </>
  );
};

UploadList.defaultProps = {
  deleteErrorMessage: "Le fichier n'a pu être supprimé",
  deleteMessage: 'Voulez-vous supprimer ce fichier ?',
  deleteSuccessMessage: 'Le fichier a été supprimé',
};

UploadList.propTypes = {
  addButton: PropTypes.node,
  deleteMessage: PropTypes.string.isRequired,
  deleteErrorMessage: PropTypes.string.isRequired,
  deleteSuccessMessage: PropTypes.string.isRequired,
  displayLimit: PropTypes.number,
  fileList: PropTypes.arrayOf(PropTypes.any.isRequired),
  onDelete: PropTypes.func,
  limit: PropTypes.number,
  accept: PropTypes.string,
  onUpload: PropTypes.func,
  onGEDUpload: PropTypes.func,
  onGEDClose: PropTypes.func,
};

UploadList.displayName = 'UploadList';

export default UploadList;
