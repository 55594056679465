import { useReducer, useEffect } from 'react';
import { notification } from '../../base';
interface ReducerState {
  get: boolean;
  notified: boolean;
  init: boolean;
}

interface ReducerAction {
  type: string;
}

const initialState: ReducerState = {
  get: false,
  notified: false,
  init: false,
};

/**
 * reducer component
 * @param {ReducerState} state
 * @param {ReducerAction} action
 * @return {ReducerState}
 */
function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'GET':
      return {
        ...state,
        get: true,
        notified: false,
      };
    case 'NOTIFIED':
      return {
        ...state,
        get: false,
        notified: true,
      };
    case 'INIT':
      return {
        ...state,
        init: true,
      };
    default:
      throw new Error();
  }
}

export const useGet = <T>({
  visible,
  id,
  apiGet,
  isFetching,
  object,
  description,
  message = 'Une erreur est survenue',
}: {
  visible: boolean;
  id: string | null;
  apiGet: Function;
  isFetching: boolean;
  object: T | null;
  description: string;
  message?: string;
}): void => {
  const [{ get, notified, init }, dispatch] = useReducer(reducer, initialState);

  useEffect((): void => {
    if (visible === true && id !== null) {
      dispatch({ type: 'GET' });
      apiGet(id);
    }
  }, [apiGet, id, visible]);

  useEffect((): void => {
    if (
      notified === false &&
      get === true &&
      isFetching === false &&
      visible === true &&
      object === null &&
      id !== null &&
      init === true
    ) {
      notification.error({
        message,
        description,
      });
      dispatch({ type: 'NOTIFIED' });
    }
  }, [
    init,
    id,
    notified,
    get,
    visible,
    object,
    isFetching,
    description,
    message,
  ]);
  if (!init) {
    dispatch({ type: 'INIT' });
  }
};
