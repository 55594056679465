import { Store } from 'redux';
import { connect } from 'react-redux';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { profileActions } from '../../../../../states/ducks/profiles';
import { Profile } from '../../../../../states/ducks/profiles/types';
import { userActions, userTypes } from '../../../../../states/ducks/users';
import { User } from '../../../../../states/ducks/users/types';
import { Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import {
  Drawer,
  Input,
  Row,
  Col,
  notification,
  Button,
  InputPassword,
  RolesSelect,
  OrganizationsTreeSelect,
  FormItem,
  LocationsTreeSelect,
} from '../../../base';
import './AddUser.scss';
import { State } from '../../../../../states/types';
import { roleSelectors, roleTypes } from '../../../../../states/ducks/roles';
import { Role } from '../../../../../states/ducks/roles/types';
import {
  locationActions,
  locationSelectors,
} from '../../../../../states/ducks/locations';
import { organizationTypes } from '../../../../../states/ducks/organizations';
import { organizationsSelector } from '../../../../../states/ducks/organizations/selectors/base';
import { Organization } from '../../../../../states/ducks/organizations/types';
import { organizationsAndTheirDescendantsIdsSelector } from '../../../../../states/ducks/organizations/selectors/organizationsSelectors';

interface FormAddUserProps {
  hideModal: Function;
  visible: boolean;
  addProfile: Function;
  addUser: Function;
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  loadLocations: Function;
  occupierRole: Role | null;
  organizations: organizationTypes.Organization[] | null;
  organizationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
}

const FormAddUser: FunctionComponent<FormAddUserProps> = ({
  hideModal,
  visible,
  addProfile,
  addUser,
  locationsAndTheirDescendantsIdsSelector,
  loadLocations,
  occupierRole,
  organizations,
  organizationsAndTheirDescendantsIdsSelector,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(
    undefined as Organization | undefined,
  );

  useEffect((): void => {
    loadLocations();
  }, [loadLocations]);

  const handleFinish = (
    values: Partial<
      User & Profile & { passwordConfirm: string; organizationId: string }
    >,
  ): void => {
    setLoading(true);
    setOrganization(undefined);

    delete values['passwordConfirm'];

    const organizationIds = [values.organizationId || ''];

    const visibleOrganizationIds = organizationIds
      .map(
        (organizationId) =>
          organizationsAndTheirDescendantsIdsSelector[organizationId],
      )
      .reduce((a, b) => a.concat(b), []);

    if (values.locationIds) {
      values.visibleLocationIds = values.locationIds
        .map(
          (locationId) => locationsAndTheirDescendantsIdsSelector[locationId],
        )
        .reduce((a, b) => a.concat(b), []);
    }

    const newProfile = {
      firstName: values.firstName,
      lastName: values.lastName,
      organizationIds: organizationIds,
      email: values.email,
      phoneNumber: values.phoneNumber,
      address: values.address,
      address2: values.address2,
      userId: '',
    };

    const newUser = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: values.roleId,
      organizationIds: organizationIds,
      visibleOrganizationIds: visibleOrganizationIds,
      locationIds: values.locationIds,
      visibleLocationIds: values.visibleLocationIds,
      email: values.email,
      password: values.password,
    };

    const promiseUser = new Promise<userTypes.User>((resolve, reject): void =>
      addUser(newUser, { resolve, reject }),
    );

    promiseUser
      .then((user: userTypes.User): string => user.id)
      .then((userId): Promise<Profile> => {
        newProfile.userId = userId;

        return new Promise<Profile>((resolve, reject): void =>
          addProfile(newProfile, { resolve, reject }),
        );
      })
      .then((profile: Profile): void => {
        form.resetFields();
        hideModal();
        const message = `${profile.firstName} ${profile.lastName} 
                        a été ajouté`;
        notification.success({ message });
      })
      .catch((message: string): void => {
        notification.error({ message });
      })
      .finally((): void => setLoading(false));
  };

  const firstNameInput = (
    <FormItem
      label="Prénom"
      name="firstName"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner un prénom',
        },
      ]}
    >
      <Input placeholder="Saisissez un prénom" />
    </FormItem>
  );

  const lastNameInput = (
    <FormItem
      label="Nom"
      name="lastName"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner un nom',
        },
      ]}
    >
      <Input placeholder="Saisissez un nom" />
    </FormItem>
  );

  const roleInput = (
    <FormItem
      label="Rôle"
      name="roleId"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez sélectionner un rôle',
        },
      ]}
    >
      <RolesSelect placeholder="Saisissez un rôle" />
    </FormItem>
  );

  const organizationInput = (
    <FormItem
      label="Organisation"
      name="organizationId"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez sélectionner une organisation',
        },
      ]}
    >
      <OrganizationsTreeSelect
        value={organization ? organization.id : ''}
        onChange={(value): void => {
          if (!Array.isArray(value) && organizations) {
            setOrganization(
              organizations.find((organization) => organization.id === value),
            );
            form.resetFields(['locationIds']);
          }
        }}
        placeholder="Saisissez une organisation"
      />
    </FormItem>
  );

  const locationsInput = organization ? (
    <FormItem
      label="Localisation"
      name="locationIds"
      rules={[
        {
          type: 'array',
          message: 'Veuillez sélectionner un lieu',
        },
      ]}
    >
      <LocationsTreeSelect
        placeholder="Sélectionnez un lieu"
        multiple={true}
        organization={organization}
      />
    </FormItem>
  ) : (
    <></>
  );

  const emailInput = (
    <FormItem
      label="Email"
      name="email"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner un email',
        },
      ]}
    >
      <Input placeholder="Saisissez un email" />
    </FormItem>
  );

  const regex = new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/);
  const phoneInput = (
    <FormItem
      label="Téléphone"
      name="phoneNumber"
      rules={[
        {
          type: 'string',
          message: 'Numéro de téléphone incorrect',
          pattern: regex,
        },
      ]}
    >
      <Input placeholder="Saisissez un numéro" />
    </FormItem>
  );

  const address1Input = (
    <FormItem
      label="Adresse"
      name="address"
      rules={[
        {
          type: 'string',
        },
      ]}
    >
      <Input placeholder="Saisissez une adresse (n°, voie, cp, ville...)" />
    </FormItem>
  );

  const address2Input = (
    <FormItem
      name="address2"
      rules={[
        {
          type: 'string',
        },
      ]}
    >
      <Input placeholder="Saisissez un complément d'adresse" />
    </FormItem>
  );

  const passwordInput = (
    <FormItem
      label="Mot de passe"
      name="password"
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez renseigner un mot de passe',
        },
      ]}
      hasFeedback
    >
      <InputPassword placeholder="Saisissez un mot de passe" />
    </FormItem>
  );

  const passwordConfirmInput = (
    <FormItem
      label="Confirmation"
      name="passwordConfirm"
      dependencies={['password']}
      rules={[
        {
          type: 'string',
          required: true,
          message: 'Veuillez confirmer le mot de passe',
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ getFieldValue }): any => ({
          validator(rule: RuleObject, value: Store): Promise<void> {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }

            return Promise.reject(
              new Error('Les mots de passe ne correspondent pas'),
            );
          },
        }),
      ]}
      hasFeedback
    >
      <InputPassword placeholder="Confirmez le mot de passe" />
    </FormItem>
  );

  return (
    <Drawer
      title="Nouvel utilisateur"
      onClose={(): void => {
        setOrganization(undefined);
        hideModal();
      }}
      visible={visible}
      footer={
        <>
          <Button
            htmlType="button"
            onClick={(): void => {
              hideModal();
            }}
            size="large"
          >
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
            onClick={form.submit}
          >
            Ajouter
          </Button>
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={12}>{firstNameInput}</Col>
          <Col span={12}>{lastNameInput}</Col>
        </Row>
        <Row>
          <Col span={24}>{roleInput}</Col>
        </Row>
        <Row>
          <Col span={24}>{organizationInput}</Col>
        </Row>
        <Row>
          <Col span={24}>{locationsInput}</Col>
        </Row>
        <Row>
          <Col span={12}>{emailInput}</Col>
          <Col span={12}>{phoneInput}</Col>
        </Row>
        <Row>
          <Col span={24}>
            {address1Input}
            {address2Input}
          </Col>
        </Row>
        <Row>
          <Col span={12}>{passwordInput}</Col>
          <Col span={12}>{passwordConfirmInput}</Col>
        </Row>
      </Form>
    </Drawer>
  );
};

FormAddUser.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  addProfile: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  occupierRole: roleTypes.PropTypesRole,
  locationsAndTheirDescendantsIdsSelector: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  organizations: PropTypes.arrayOf(
    organizationTypes.PropTypesOrganization.isRequired,
  ),
  organizationsAndTheirDescendantsIdsSelector: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
};

interface MapStateToProps {
  occupierRole: Role | null;
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  organizations: organizationTypes.Organization[] | null;
  organizationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  occupierRole: roleSelectors.occupierRoleSelector(state),
  locationsAndTheirDescendantsIdsSelector:
    locationSelectors.locationsAndTheirDescendantsIdsSelector(state),
  organizations: organizationsSelector(state),
  organizationsAndTheirDescendantsIdsSelector:
    organizationsAndTheirDescendantsIdsSelector(state),
});

interface MapDispatchToProps {
  addProfile: Function;
  addUser: Function;
  loadLocations: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  addProfile: profileActions.fetchAddProfilesActionCreator,
  addUser: userActions.fetchAddUsersActionCreator,
  loadLocations: locationActions.fetchListLocationsActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAddUser);
