import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import {
  incidentTypeSelectors,
  incidentTypeTypes,
} from '../../../../states/ducks/incidenttypes';
import { FilterableSelect, Select, SelectOption } from '../index';
import { SelectProps } from './Select';

interface IncidentTypesSelectProps extends SelectProps {
  incidentTypes: incidentTypeTypes.IncidentType[] | null;
}

/**
 * @param {IncidentType[]|null} incidentTypes
 * @param {SelectProps} props
 *
 * @return {ReactElement}
 */
const IncidentTypesSelect: FunctionComponent<IncidentTypesSelectProps> = ({
  incidentTypes,
  ...props
}): ReactElement => {
  const selectOptions = incidentTypes ? (
    incidentTypes.map(
      (incidentType, i): ReactElement => (
        <SelectOption value={incidentType.id} key={i} title={incidentType.name}>
          {incidentType.name}
        </SelectOption>
      ),
    )
  ) : (
    <></>
  );
  return props.isFilter ? (
    <FilterableSelect {...props}>{selectOptions}</FilterableSelect>
  ) : (
    <Select {...props}>{selectOptions}</Select>
  );
};

IncidentTypesSelect.propTypes = {
  incidentTypes: PropTypes.arrayOf(
    incidentTypeTypes.PropTypesIncidentType.isRequired,
  ),
  isFilter: PropTypes.bool,
};

interface MapStateToProps {
  incidentTypes: incidentTypeTypes.IncidentType[] | null;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  incidentTypes:
    incidentTypeSelectors.incidentTypesFromOrganizationSelector(state),
});

export default connect(mapStateToProps)(IncidentTypesSelect);
