import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useReducer,
} from 'react';
import {
  Card,
  PageHeader,
  Skeleton,
  List,
  ListItem,
  CardMeta,
  Avatar,
  Empty,
  DataWithFilters,
  Filter,
  InputSearch,
  RoleTag,
  RestrictedToPermissions,
} from '../../base';
import { profileTypes } from '../../../../states/ducks/profiles';
import PropTypes from 'prop-types';
import './Users.scss';
import { MailOutlined, PhoneOutlined, HomeOutlined } from '@ant-design/icons';
import { userTypes } from '../../../../states/ducks/users';
import InfoUser from '../modal/user/InfoUser';

interface Props {
  profiles: profileTypes.Profile[];
  users: userTypes.User[];
  loading: boolean;
  loadProfiles: Function;
  loadUsers: Function;
  deleteProfile: Function;
}

interface ListProfiles {
  id: profileTypes.Profile['id'];
  key: profileTypes.Profile['id'];
  firstName: profileTypes.Profile['firstName'];
  lastName: profileTypes.Profile['lastName'];
  email?: profileTypes.Profile['email'];
  phoneNumber?: profileTypes.Profile['phoneNumber'];
  address?: profileTypes.Profile['address'];
  avatarId?: profileTypes.Profile['avatarId'];
  roleId?: userTypes.User['roleId'];
}

const initialState = {
  visibleDrawerInfo: false,
  profileId: null as null | profileTypes.Profile['id'],
};

type ProfileReducerState = typeof initialState;

interface ProfileReducerAction {
  type: 'VISIBLE_DRAWER_INFO' | 'HIDDEN_DRAWER';
  payload?: {
    profileId?: null | profileTypes.Profile['id'];
  };
}

const reducer = (
  state: ProfileReducerState,
  action: ProfileReducerAction,
): ProfileReducerState => {
  const { profileId = null } = action.payload ? action.payload : {};
  switch (action.type) {
    case 'VISIBLE_DRAWER_INFO':
      return {
        ...state,
        visibleDrawerInfo: true,
        profileId,
      };
    case 'HIDDEN_DRAWER':
      return {
        ...state,
        visibleDrawerInfo: false,
      };
    default:
      throw new Error();
  }
};

/**
 * @return {object}
 */
function useProfileReducer(): {
  showDrawerInfo: Function;
  hideDrawer: Function;
  state: ProfileReducerState;
} {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showDrawerInfo = (profileId: profileTypes.Profile['id']): void => {
    dispatch({
      type: 'VISIBLE_DRAWER_INFO',
      payload: { profileId },
    });
  };

  const hideDrawer = (): void => {
    dispatch({
      type: 'HIDDEN_DRAWER',
    });
  };

  return {
    showDrawerInfo,
    hideDrawer,
    state,
  };
}

const Users: FunctionComponent<Props> = ({
  profiles,
  users,
  loading,
  loadProfiles,
  loadUsers,
}): ReactElement => {
  const { showDrawerInfo, hideDrawer, state } = useProfileReducer();

  useEffect((): void => {
    loadProfiles();
  }, [loadProfiles]);

  useEffect((): void => {
    loadUsers();
  }, [loadUsers]);

  const listData = profiles.map(
    (profile: profileTypes.Profile): ListProfiles => {
      const user = users.find(
        (user: userTypes.User): boolean => user.id === profile.userId || false,
      );

      return {
        id: profile.id,
        key: profile.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phoneNumber: profile.phoneNumber,
        address: profile.address,
        avatarId: profile.avatarId,
        roleId: user ? user.roleId : '',
      };
    },
  );

  const description = (item: ListProfiles): ReactElement => {
    const email = item.email ? (
      <div>
        <MailOutlined />
        {item.email}
      </div>
    ) : (
      ''
    );
    const phoneNumber = item.phoneNumber ? (
      <div>
        <PhoneOutlined />
        {item.phoneNumber}
      </div>
    ) : (
      ''
    );
    const address = item.address ? (
      <div>
        <HomeOutlined />
        {item.address}
      </div>
    ) : (
      ''
    );

    return (
      <>
        {email}
        {phoneNumber}
        {address}
      </>
    );
  };

  const renderItem = (item: ListProfiles): ReactElement => {
    return (
      <ListItem className="app-users-list-item">
        <Card
          className="app-users-card"
          hoverable
          onClick={(): void => showDrawerInfo(item.id)}
        >
          <CardMeta
            avatar={<Avatar profileId={item.id} size={80} />}
            title={
              <>
                <div>
                  {item.firstName} {item.lastName}
                </div>
                <div>
                  {item && item.roleId ? <RoleTag roleId={item.roleId} /> : ''}
                </div>
              </>
            }
            description={description(item)}
          />
        </Card>
      </ListItem>
    );
  };

  return (
    <RestrictedToPermissions subject={'user'} action={'list'}>
      <PageHeader title="Utilisateurs" />
      <Skeleton
        className="app-users-skeleton"
        loading={loading && !profiles.length}
        active
        title={false}
        avatar={true}
        paragraph={{
          rows: 4,
          width: '100%',
        }}
      >
        <DataWithFilters
          dataSource={listData}
          displayComponent={
            <List<ListProfiles>
              className="app-users-list"
              grid={{
                xxl: 3,
                xl: 2,
                lg: 2,
                md: 1,
                sm: 1,
                xs: 1,
                gutter: 16,
              }}
              pagination={{ pageSize: 15 }}
              locale={{ emptyText: <Empty /> }}
              renderItem={renderItem}
            />
          }
        >
          <Filter
            component={
              <InputSearch
                size="large"
                placeholder="Rechercher par nom, prénom, mail ..."
              />
            }
            filterName={'search'}
            filter={(
              value: string,
              record: { [key: string]: string },
            ): boolean => {
              const columns = ['firstName', 'lastName', 'email'];
              const fields: string[] = [];

              columns.forEach((fieldName: string): void => {
                fields.push(String(record[fieldName] || ''));
              });

              for (const field of fields) {
                if (field.search(new RegExp(value, 'i')) >= 0) {
                  return true;
                }
              }

              return false;
            }}
            onChangeFormatValue={(
              e: ChangeEvent<HTMLInputElement>,
            ): string[] => [e.currentTarget.value]}
          />
        </DataWithFilters>
      </Skeleton>
      <InfoUser
        visible={state.visibleDrawerInfo}
        hideDrawer={hideDrawer}
        profileId={state.profileId || ''}
      />
    </RestrictedToPermissions>
  );
};

Users.propTypes = {
  profiles: PropTypes.arrayOf(profileTypes.PropTypesProfile.isRequired)
    .isRequired,
  users: PropTypes.arrayOf(userTypes.PropTypesUser.isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  loadProfiles: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
};

export default Users;
