import React, { FunctionComponent, ReactElement } from 'react';
import { Card as CardAnt } from 'antd';
import 'antd/lib/card/style';
import { CardMetaProps } from 'antd/lib/card';

/**
 * Col component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const CardMeta: FunctionComponent<CardMetaProps> = ({
  ...props
}): ReactElement => <CardAnt.Meta {...props} />;

CardMeta.propTypes = {};

export default CardMeta;
