import { combineReducers, Reducer, Action, AnyAction } from 'redux';
import * as types from './types';
import { getActionType } from '../common/types';
import { modelReducer } from '../common/reducers';

/**
 * @param {History[]} [state=[]] histories
 * @param {AnyAction} action
 * @return {History[]}
 */
export const historyReducer: Reducer<types.History[]> = (
  state: types.History[] = [],
  action: AnyAction,
): types.History[] => {
  return modelReducer('histories', state, action) as types.History[];
};

/**
 * @param {boolean} state
 * @param {Action} action
 * @return {boolean}
 */
export const listingReducer: Reducer<boolean> = (
  state = false,
  action: Action<string>,
): boolean => {
  switch (action.type) {
    case getActionType('histories', 'LIST_FAILED'):
    case getActionType('histories', 'LIST_SUCCEEDED'):
      return false;
    case getActionType('histories', 'LIST_REQUESTED'):
      return true;
    default:
      return state;
  }
};

const reducers: Reducer<types.HistoryState> = combineReducers({
  histories: historyReducer,
  status: combineReducers({
    listing: listingReducer,
  }),
});

export default reducers;
