import React, { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Button,
  Skeleton,
  Empty,
  Input,
  EditableField,
  DateField,
  RoleStyleSelect,
  Tag,
} from '../../../base';
import { roleTypes } from '../../../../../states/ducks/roles';
import { DrawerProps } from 'antd/lib/drawer';

interface GetRoleProps extends DrawerProps {
  hideDrawer: Function;
  role: roleTypes.Role | null;
  roleId: roleTypes.Role['id'] | null;
  isFetching: boolean;
  editRoleName: Function;
  editRoleDescription: Function;
  editRoleStyle: Function;
}

const GetRole: FunctionComponent<GetRoleProps> = ({
  hideDrawer,
  visible = false,
  role,
  roleId,
  isFetching,
  editRoleName,
  editRoleDescription,
  editRoleStyle,
}): ReactElement => {
  const nameField = role ? (
    <EditableField
      object={role}
      label="Nom"
      content={role.name}
      editedFieldsOptions={[
        {
          field: 'name',
          label: 'Nom',
          content: <Input />,
          required: true,
          initialValue: role.name,
        },
      ]}
      onSubmit={editRoleName}
      successMessage="Le rôle a bien été modifié"
    />
  ) : (
    ''
  );

  const descriptionField = role ? (
    <EditableField
      object={role}
      label="Description"
      content={role.description || ''}
      editedFieldsOptions={[
        {
          field: 'description',
          label: 'Description',
          content: <Input />,
          required: false,
          initialValue: role.description,
        },
      ]}
      onSubmit={editRoleDescription}
      successMessage="Le rôle a bien été modifié"
    />
  ) : (
    ''
  );

  const styleField =
    role && role.style !== 'gold' ? (
      <EditableField
        object={role}
        label="Style"
        content={<Tag color={role.style}>{role.style}</Tag>}
        editedFieldsOptions={{
          field: 'style',
          label: 'Style',
          content: <RoleStyleSelect placeholder="Sélectionnez une couleur" />,
          initialValue: role.style,
          required: true,
        }}
        onSubmit={editRoleStyle}
        successMessage="Le style a bien été modifié"
      />
    ) : (
      ''
    );

  return (
    <Drawer
      title="Détail du rôle"
      visible={visible}
      onClose={(): void => hideDrawer()}
      footer={
        <Button onClick={(): void => hideDrawer()} type="primary" size="large">
          Fermer
        </Button>
      }
    >
      <Skeleton loading={isFetching}>
        {role !== null ? (
          <>
            {nameField}
            {descriptionField}
            {styleField}
            <DateField label="Créé le" date={role.createdAt} />
            <DateField label="Modifié le" date={role.updatedAt} />
          </>
        ) : (
          <Empty />
        )}
      </Skeleton>
    </Drawer>
  );
};

GetRole.propTypes = {
  visible: PropTypes.bool,
  hideDrawer: PropTypes.func.isRequired,
  role: roleTypes.PropTypesRole,
  roleId: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  editRoleName: PropTypes.func.isRequired,
  editRoleDescription: PropTypes.func.isRequired,
  editRoleStyle: PropTypes.func.isRequired,
};

export default GetRole;
