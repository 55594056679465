import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../states/types';
import { roleSelectors, roleTypes } from '../../../../states/ducks/roles';
import {
  Button,
  Empty,
  FilterableSelect,
  RoleTag,
  Select,
  SelectOption,
} from '../index';
import { SelectProps } from './Select';
import { RouteComponentProps, withRouter } from 'react-router';
import historyPropTypes from '../../../../utils/propTypes/historyPropTypes';

interface RolesSelectProps extends SelectProps, RouteComponentProps {
  roles: roleTypes.Role[] | null;
}

/**
 * @param {object[]} roles
 * @param {SelectProps&RouteComponentProps} props
 *
 * @return {ReactElement}
 */
const RolesSelect: FunctionComponent<RolesSelectProps> = ({
  roles,
  ...props
}): ReactElement => {
  const selectOptions = roles
    ? roles.map(
        (role): ReactElement => (
          <SelectOption key={role.id} value={role.id} title={role.name}>
            <RoleTag roleId={role.id} />
          </SelectOption>
        ),
      )
    : [];

  return props.isFilter ? (
    <FilterableSelect {...props}>{selectOptions}</FilterableSelect>
  ) : (
    <Select
      {...props}
      notFoundContent={
        <Empty description={'Aucun rôle'}>
          <Button
            onClick={(): void => props.history.push('/administration/user')}
            type="link"
          >
            Voir les rôles
          </Button>
        </Empty>
      }
    >
      {selectOptions}
    </Select>
  );
};

RolesSelect.propTypes = {
  history: PropTypes.exact(historyPropTypes).isRequired,
  roles: PropTypes.arrayOf(roleTypes.PropTypesRole.isRequired),
  isFilter: PropTypes.bool,
};

interface MapStateToProps {
  roles: roleTypes.Role[] | null;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  roles: roleSelectors.rolesFromOrganizationSelector(state),
});

export default connect(mapStateToProps)(withRouter(RolesSelect));
