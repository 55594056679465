// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Action, ActionMeta, createAction, BaseAction } from 'redux-actions';
import { getActionType, Model } from './types';
import { PaginatedResult, Search } from '../../../services/search/search';

export interface Meta {
  resolve: Function;
  reject: Function;
}

export type FetchFailed = Action<string>;
export type FetchAddRequested<T> = ActionMeta<T, Meta>;
export type FetchAddSucceeded<T> = Action<T>;
export type FetchListRequested = ActionMeta<Search, Meta>;
export type FetchListByDependencyRequested<T extends Model> = ActionMeta<
  T['id'],
  Meta
>;
export type FetchListAgnosticallyRequested<T extends Model> = ActionMeta<
  { objectType: string; objectId: T['id']; start?: number; end?: number },
  Meta
>;
export type FetchListSucceeded<T> = Action<T[]>;
export type FetchPaginatedListSucceeded<T> = Action<PaginatedResult>;
export type FetchGetRequested<T extends Model> = ActionMeta<T['id'], Meta>;
export type FetchGetSucceeded<T> = Action<T>;
export type FetchEditRequested<T> = ActionMeta<T, Meta>;
export type FetchEditSucceeded<T> = Action<T>;
export type FetchDeleteRequested<T extends Model> = ActionMeta<T['id'], Meta>;
export type FetchDeleteSucceeded<T extends Model> = Action<T['id']>;

export const defaultMeta = {
  resolve(): void {
    return;
  },
  reject(): void {
    return;
  },
};

/**
 * @return {BaseAction}
 */
export const logoutActionCreator = createAction(
  getActionType('auth', 'LOGOUT_REQUESTED'),
);
