import { createSelector } from 'reselect';
import { Role, RoleState } from './types';
import { State } from '../../types';
import { currentUserOrganizationRootSelector } from '../currentUser/selectors';

/**
 * @param {State} state
 * @return {RoleState}
 */
const roleStateSelector: (state: State) => RoleState = (state) =>
  state.rolesState;

/**
 * @param {State} state
 * @return {Role[]}
 */
export const rolesSelector = createSelector(
  roleStateSelector,
  (applicationState) => applicationState.roles,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const rolesIsGettingSelector = createSelector(
  roleStateSelector,
  (applicationState) => applicationState.status.getting,
);

/**
 * @param {State} state
 * @return {boolean}
 */
export const rolesIsListingSelector = createSelector(
  roleStateSelector,
  (applicationState) => applicationState.status.listing,
);

/**
 * @param {State} state
 * @param {string} id
 * @return {Role|null}
 */
export const roleFromIdSelector = createSelector(
  rolesSelector,
  (_: State, id: Role['id']) => id,
  (roles, id) => roles.find((role) => role.id === id) || null,
);

/**
 * @param {State} state
 * @return {Role[]}
 */
export const rolesFromOrganizationSelector = createSelector(
  rolesSelector,
  currentUserOrganizationRootSelector,
  (roles, organization) =>
    roles.filter((roles) => roles.organizationId === organization.id),
);

/**
 * @param {State} state
 * @param {Role|null}
 */
export const occupierRoleSelector = createSelector(
  rolesFromOrganizationSelector,
  (roles) => roles.find((role) => role.name === 'Occupant') || null,
);
