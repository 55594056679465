import { createAction } from 'redux-actions';
import { defaultMeta, Meta } from '../common/actions';
import * as types from './types';
import { UploadFile } from 'antd/lib/upload/interface';
import { getActionType } from '../common/types';
import { PaginatedResult, Search } from '../../../services/search/search';
import { Incident } from './types';

/**
 * @param {Incident} incident
 * @return {FetchAddRequested}
 */
export const fetchReportIncidentsActionCreator = createAction(
  getActionType('incidents', 'REPORT_REQUESTED'),
  (incident: Incident): Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @return {FetchAddSucceeded}
 */
export const fetchReportIncidentsSuccessActionCreator = createAction(
  getActionType('incidents', 'REPORT_SUCCEEDED'),
  (incident: types.Incident): types.Incident => incident,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchReportIncidentsErrorActionCreator = createAction(
  getActionType('incidents', 'REPORT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Search} search
 * @param {Meta} meta
 * @return {FetchListRequested}
 */
export const fetchListIncidentsActionCreator = createAction(
  getActionType('incidents', 'LIST_REQUESTED'),
  (search?: Search): Search | undefined => search,
  (_, meta?: Meta): Meta | undefined => meta,
);

/**
 * @param {Incident[]} incidents
 * @return {FetchListSucceeded}
 */
export const fetchListIncidentsSuccessActionCreator = createAction(
  getActionType('incidents', 'LIST_SUCCEEDED'),
  (incidents: types.Incident[]): types.Incident[] => incidents,
);

/**
 * @param {PaginatedResult} paginatedResult
 * @return {FetchPaginatedListSucceeded}
 */
export const fetchPaginatedListIncidentsSuccessActionCreator = createAction(
  getActionType('incidents', 'LIST_PAGINATED_SUCCEEDED'),
  (paginatedResult: PaginatedResult): PaginatedResult => paginatedResult,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchListIncidentsErrorActionCreator = createAction(
  getActionType('incidents', 'LIST_FAILED'),
  (message: string): string => message,
);

/**
 * @param {string} id
 * @param {Meta} meta
 * @return {FetchGetRequested}
 */
export const fetchGetIncidentsActionCreator = createAction(
  getActionType('incidents', 'GET_REQUESTED'),
  (id: types.Incident['id']): types.Incident['id'] => id,
  (_, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @return {FetchGetSucceeded}
 */
export const fetchGetIncidentsSuccessActionCreator = createAction(
  getActionType('incidents', 'GET_SUCCEEDED'),
  (incident: types.Incident): types.Incident => incident,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchGetIncidentsErrorActionCreator = createAction(
  getActionType('incidents', 'GET_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Incident} incident
 * @return {FetchEditSucceeded}
 */
export const fetchEditIncidentsSuccessActionCreator = createAction(
  getActionType('incidents', 'EDIT_SUCCEEDED'),
  (incident: types.Incident): types.Incident => incident,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchEditIncidentsErrorActionCreator = createAction(
  getActionType('incidents', 'EDIT_FAILED'),
  (message: string): string => message,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchAcceptIncidentsActionCreator = createAction(
  getActionType('incidents', 'ACCEPT_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchRejectIncidentsActionCreator = createAction(
  getActionType('incidents', 'REJECT_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchResolveIncidentsActionCreator = createAction(
  getActionType('incidents', 'RESOLVE_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchRetypeIncidentsActionCreator = createAction(
  getActionType('incidents', 'RETYPE_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchRemarkIncidentsActionCreator = createAction(
  getActionType('incidents', 'REMARK_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchRelocateIncidentsActionCreator = createAction(
  getActionType('incidents', 'RELOCATE_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {File} file
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchAddFileRequested}
 */
export const fetchAddFileIncidentsActionCreator = createAction(
  getActionType('incidents', 'ADD_FILE_REQUESTED'),
  (payload: {
    file: UploadFile;
    incident: types.Incident;
  }): {
    file: UploadFile;
    incident: types.Incident;
  } => payload,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {File} file
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchAddGEDFileRequested}
 */
export const fetchAddGEDFileIncidentsActionCreator = createAction(
  getActionType('incidents', 'ADD_GED_FILE_REQUESTED'),
  (payload: {
    fileIds: string[];
    incident: types.Incident;
  }): {
    fileIds: string[];
    incident: types.Incident;
  } => payload,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} fileId
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchDeleteFileRequested}
 */
export const fetchDeleteFileIncidentsActionCreator = createAction(
  getActionType('incidents', 'DELETE_FILE_REQUESTED'),
  (payload: {
    fileId: string;
    incident: types.Incident;
  }): {
    fileId: string;
    incident: types.Incident;
  } => payload,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchChangeReportingIncidentsActionCreator = createAction(
  getActionType('incidents', 'CHANGE_REPORTING_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {Incident} incident
 * @param {Meta} meta
 * @return {FetchEditRequested}
 */
export const fetchChangeServiceOrderNumberIncidentsActionCreator = createAction(
  getActionType('incidents', 'CHANGE_SERVICE_ORDER_NUMBER_REQUESTED'),
  (incident: types.Incident): types.Incident => incident,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteRequested}
 */
export const fetchDeleteIncidentsActionCreator = createAction(
  getActionType('incidents', 'DELETE_REQUESTED'),
  (id: types.Incident['id']): types.Incident['id'] => id,
  (incident, meta: Meta = defaultMeta): Meta => meta,
);

/**
 * @param {string} id
 * @return {FetchDeleteSucceeded}
 */
export const fetchDeleteIncidentsSuccessActionCreator = createAction(
  getActionType('incidents', 'DELETE_SUCCEEDED'),
  (id: types.Incident['id']): types.Incident['id'] => id,
);

/**
 * @param {string} message
 * @return {FetchFailed}
 */
export const fetchDeleteIncidentsErrorActionCreator = createAction(
  getActionType('incidents', 'DELETE_FAILED'),
  (message: string): string => message,
);

export type FetchAddFileRequested = ReturnType<
  typeof fetchAddFileIncidentsActionCreator
>;

export type FetchAddGEDFileRequested = ReturnType<
  typeof fetchAddGEDFileIncidentsActionCreator
>;

export type FetchDeleteFileRequested = ReturnType<
  typeof fetchDeleteFileIncidentsActionCreator
>;
