import React, {
  FunctionComponent,
  ReactElement,
  useState,
  KeyboardEvent,
} from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  FormItem,
  Input,
  Row,
  Col,
  notification,
  Button,
} from '../../../base';
import { organizationTypes } from '../../../../../states/ducks/organizations';
import { Form } from 'antd';
import { Organization } from '../../../../../states/ducks/organizations/types';

interface FormAddOrgaProps {
  hideDrawer: Function;
  visible: boolean;
  addOrganization: Function;
  parentId: organizationTypes.Organization['id'] | null;
}

const AddOrganization: FunctionComponent<FormAddOrgaProps> = ({
  hideDrawer,
  visible,
  addOrganization,
  parentId = null,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = (values: Partial<Organization>): void => {
    setLoading(true);

    const organisation = {
      ...values,
    };

    if (parentId) {
      organisation.parentId = parentId;
    }

    const promise = new Promise<organizationTypes.Organization>(
      (resolve, reject): void =>
        addOrganization(organisation, { resolve, reject }),
    );

    promise
      .then((organisation: organizationTypes.Organization): void => {
        form.resetFields();
        hideDrawer();
        notification.success({
          message: `${organisation.name} a bien été ajoutée`,
        });
      })
      .catch((message: string): void => {
        notification.error({
          message,
        });
      })
      .finally((): void => setLoading(false));
  };

  const handleKeyup = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      form.submit();
    }
  };

  const onClose = (): void => {
    hideDrawer();
  };

  return (
    <Drawer
      title="Ajouter une organisation"
      onClose={onClose}
      visible={visible}
      footer={
        <>
          <Button htmlType="button" size="large" onClick={onClose}>
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            onClick={form.submit}
          >
            Ajouter
          </Button>
        </>
      }
      {...props}
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={12}>
            <FormItem
              label="Nom de l'organisation"
              name="name"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'Le nom est obligatoire',
                },
              ]}
            >
              <Input onKeyUp={handleKeyup} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

AddOrganization.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideDrawer: PropTypes.func.isRequired,
  addOrganization: PropTypes.func.isRequired,
  parentId: PropTypes.string,
};

export default AddOrganization;
