import React, { FunctionComponent, ReactElement } from 'react';
import { Skeleton as SkeletonAnt } from 'antd';
import 'antd/lib/skeleton/style';
import { SkeletonProps } from 'antd/lib/skeleton';
import PropTypes from 'prop-types';

/**
 * Modal component
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Skeleton: FunctionComponent<SkeletonProps> = ({
  active = true,
  ...props
}): ReactElement => <SkeletonAnt active={active} {...props} />;

Skeleton.propTypes = {
  active: PropTypes.bool,
};

export default Skeleton;
