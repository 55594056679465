import PropTypes from 'prop-types';
import { Model } from '../common/types';

export interface Subscription extends Model {
  userId: string;
  notificationKeys: string[];
  conditions: Condition[];
  createdAt: string;
  updatedAt: string;
}

export interface Condition {
  field: string;
  operator: string;
  value: string | string[];
}

export interface SubscriptionState {
  subscriptions: Subscription[];
  status: {
    subscribing: boolean;
    listing: boolean;
    unsubscribing: boolean;
  };
}

export const PropTypesSubscription = PropTypes.shape({
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  notificationKeys: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      operator: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      ]).isRequired,
    }).isRequired,
  ).isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});
