import React, { FunctionComponent, ReactElement } from 'react';
import { Breadcrumb as BreadcrumbAnt } from 'antd';
import 'antd/lib/breadcrumb/style';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import './Breadcrumb.scss';

/**
 * @param {any} props empty props
 * @return {ReactElement}
 */
const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  ...props
}): ReactElement => <BreadcrumbAnt {...props} />;

Breadcrumb.propTypes = {};

export default Breadcrumb;
