import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { State } from '../../../../../states/types';
import {
  profileActions,
  profileSelectors,
  profileTypes,
} from '../../../../../states/ducks/profiles';
import {
  userActions,
  userSelectors,
  userTypes,
} from '../../../../../states/ducks/users';
import {
  organizationActions,
  organizationSelectors,
  organizationTypes,
} from '../../../../../states/ducks/organizations';
import { roleSelectors, roleTypes } from '../../../../../states/ducks/roles';
import {
  Drawer,
  Button,
  Skeleton,
  Empty,
  EditableAvatar,
  Input,
  InputPassword,
  EditableField,
  OrganizationsTreeSelect,
  Row,
  Col,
  PopoverActions,
  RolesSelect,
  RoleTag,
  LocationsTreeSelect,
  RestrictedToPermissions,
  Tabs,
  TabPane,
} from '../../../base';
import passwordsComparison from '../../../base/field/PasswordsComparison';
import './InfoUser.scss';
import {
  locationActions,
  locationSelectors,
} from '../../../../../states/ducks/locations';
import { User } from '../../../../../states/ducks/users/types';
import { usersIsGettingSelector } from '../../../../../states/ducks/users/selectors/base';
import { Profile } from '../../../../../states/ducks/profiles/types';
import { Meta } from '../../../../../states/ducks/common/actions';
import {
  Location,
  PropTypesLocation,
} from '../../../../../states/ducks/locations/types';
import { locationFromMultipleIdsSelector } from '../../../../../states/ducks/locations/selectors';

interface InfoUserProps {
  visible: boolean;
  hideDrawer: Function;
  profileId: profileTypes.Profile['id'];
  profile: profileTypes.Profile | null;
  user: userTypes.User | null;
  organization: organizationTypes.Organization | null;
  locationsPaths: string[];
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  organizationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  role: roleTypes.Role | null;
  visibleOrganizations: organizationTypes.Organization[];
  visibleLocations: Location[];
  getProfile: Function;
  getUser: Function;
  getOrganization: Function;
  loadLocations: Function;
  isFetchingProfile: boolean;
  isFetchingUser: boolean;
  editProfileInformation: Function;
  editProfileEmail: Function;
  editProfileOrganizations: Function;
  editUserInformation: Function;
  editUserEmail: Function;
  editUserPassword: Function;
  editUserOrganizations: Function;
  editUserVisibleOrganizations: Function;
  editUserLocations: Function;
  editUserVisibleLocations: Function;
  editUserRole: Function;
  deleteProfile: Function;
}

const InfoUser: FunctionComponent<InfoUserProps> = ({
  hideDrawer,
  visible,
  profileId,
  profile,
  user,
  organization,
  locationsPaths,
  locationsAndTheirDescendantsIdsSelector,
  organizationsAndTheirDescendantsIdsSelector,
  role,
  visibleOrganizations,
  visibleLocations,
  getProfile,
  getUser,
  getOrganization,
  loadLocations,
  isFetchingProfile,
  isFetchingUser,
  editProfileInformation,
  editProfileEmail,
  editProfileOrganizations,
  editUserInformation,
  editUserEmail,
  editUserPassword,
  editUserOrganizations,
  editUserVisibleOrganizations,
  editUserLocations,
  editUserVisibleLocations,
  editUserRole,
  deleteProfile,
}): ReactElement => {
  const { compareToFirstPassword, validateToNextPassword } =
    passwordsComparison();

  useEffect((): void => {
    if (getProfile && profileId && !profile) {
      getProfile(profileId);
    }
  }, [getProfile, profile, profileId]);

  useEffect((): void => {
    if (getUser && profile && profile.userId && !user) {
      getUser(profile.userId);
    }
  }, [getUser, profile, user]);

  useEffect((): void => {
    if (
      getOrganization &&
      profile &&
      profile.organizationIds.length > 0 &&
      !organization
    ) {
      getOrganization(profile.organizationIds[0]);
    }
  }, [getOrganization, organization, profile]);

  useEffect((): void => {
    if (loadLocations) {
      loadLocations();
    }
  }, [loadLocations]);

  const avatar = <EditableAvatar size={120} profileId={profileId} />;

  const fullNameField = profile ? (
    <EditableField
      object={[profile, user]}
      content={`${profile.firstName} ${profile.lastName}`}
      editedFieldsOptions={[
        {
          field: 'firstName',
          content: (
            <Input
              placeholder="Saisissez un prénom"
              className="ant-drawer-title"
            />
          ),
          required: true,
          initialValue: profile.firstName,
          colSize: 12,
        },
        {
          field: 'lastName',
          content: (
            <Input
              placeholder="Saisissez un nom"
              className="ant-drawer-title"
            />
          ),
          required: true,
          initialValue: profile.lastName,
          colSize: 12,
        },
      ]}
      onSubmit={[editProfileInformation, editUserInformation]}
      successMessage="Le nom a bien été modifié"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const emailField =
    profile && user ? (
      <EditableField
        object={[profile, user]}
        label="Email"
        content={user.email}
        editedFieldsOptions={[
          {
            field: 'email',
            label: 'Email',
            content: <Input placeholder="Saisissez un e-mail" />,
            required: true,
            initialValue: profile.email,
            colSize: 24,
            type: 'email',
            invalidMessage: "L'e-mail n'est pas valide",
          },
        ]}
        onSubmit={[editProfileEmail, editUserEmail]}
        successMessage="L'email a bien été modifié"
        restrictedToPermissions={{
          subject: 'user',
          action: 'edit',
        }}
      />
    ) : (
      ''
    );

  const passwordField = user ? (
    <EditableField
      object={user}
      label="Mot de passe"
      content={`************`}
      editedFieldsOptions={[
        {
          field: 'password',
          label: 'Mot de passe',
          content: <InputPassword placeholder="Saisissez un mot de passe" />,
          required: true,
          colSize: 12,
          validator: validateToNextPassword,
        },
        {
          field: 'passwordConfirm',
          label: 'Confirmation',
          content: <InputPassword placeholder="Confirmez le mot de passe" />,
          required: true,
          colSize: 12,
          validator: compareToFirstPassword,
        },
      ]}
      onSubmit={editUserPassword}
      successMessage="Le mot de passe a bien été modifié"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const organizationField = profile ? (
    <EditableField
      object={[profile, user]}
      label="Rattaché à"
      content={organization ? organization.name : ''}
      editedFieldsOptions={[
        {
          field: 'organizationId',
          label: 'Rattaché à',
          content: <OrganizationsTreeSelect />,
          required: true,
          initialValue:
            profile.organizationIds.length > 0
              ? profile.organizationIds[0]
              : '',
          colSize: 24,
        },
      ]}
      onSubmit={[
        (values: Profile & { organizationId: string }, meta: Meta): void => {
          values.organizationIds = [values.organizationId];
          editProfileOrganizations(values);
          meta.resolve();
        },
        (values: User & { organizationId: string }, meta: Meta): void => {
          values.organizationIds = [values.organizationId];
          editUserOrganizations(values);
          meta.resolve();
        },
      ]}
      successMessage="L'organisation a bien été modifiée"
      confirmTitle="Le rattachement sera supprimé !"
      confirmCondition={(values: { organizationId: string }): boolean =>
        null !== organization &&
        null !== user &&
        'undefined' !== typeof user.locationIds &&
        null !== user.locationIds &&
        user.locationIds.length > 0 &&
        values.organizationId !== organization.id
      }
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const visibleOrganizationField = user ? (
    <EditableField
      object={user}
      label="Visibilité sur"
      content={
        0 < user.visibleOrganizationIds.length ? (
          <ul>
            {user.visibleOrganizationIds.map((id) => (
              <li key={id}>
                {
                  visibleOrganizations.find(
                    (organization) => organization.id === id,
                  )?.name
                }
              </li>
            ))}
          </ul>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: 'visibleOrganizationIds',
          type: 'array',
          label: 'Visibilité sur',
          content: (
            <OrganizationsTreeSelect
              multiple={true}
              treeCheckable={true}
              showCheckedStrategy={'SHOW_PARENT'}
              className={'dropdown-in-flow'}
            />
          ),
          initialValue: user.visibleOrganizationIds,
          colSize: 24,
        },
      ]}
      onSubmit={(user: User): void => {
        user.visibleOrganizationIds = Array.from(
          new Set(
            user.visibleOrganizationIds
              .map(
                (organizationId) =>
                  organizationsAndTheirDescendantsIdsSelector[organizationId],
              )
              .reduce((a, b) => a.concat(b), []),
          ),
        );

        editUserVisibleOrganizations(user);
      }}
      successMessage="La visibilité a bien été modifiée"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const locationsField = user ? (
    <EditableField
      object={[user, user]}
      label="Rattaché à"
      content={
        0 < locationsPaths.length ? (
          <ul>
            {locationsPaths.map((path, number) => (
              <li key={`location-path-${number}`}>{path}</li>
            ))}
          </ul>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: 'locationIds',
          type: 'array',
          label: 'Rattaché à',
          content: (
            <LocationsTreeSelect organization={organization} multiple={true} />
          ),
          initialValue: user.locationIds,
          colSize: 24,
        },
      ]}
      onSubmit={[
        editUserLocations,
        (user: User): void => {
          user.visibleLocationIds = user.locationIds
            ? user.locationIds
                .map(
                  (locationId) =>
                    locationsAndTheirDescendantsIdsSelector[locationId],
                )
                .reduce((a, b) => a.concat(b), [])
            : [];
          editUserVisibleLocations(user);
        },
      ]}
      successMessage="La localisation a bien été modifiée"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const visibleLocationField = user ? (
    <EditableField
      object={user}
      label="Visibilité sur"
      content={
        user.visibleLocationIds && user.visibleLocationIds.length > 0 ? (
          <ul>
            {user.visibleLocationIds.map((id) => (
              <li key={id}>
                {visibleLocations.find((location) => location.id === id)?.name}
              </li>
            ))}
          </ul>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: 'visibleLocationIds',
          type: 'array',
          label: 'Visibilité sur',
          content: (
            <LocationsTreeSelect
              multiple={true}
              treeCheckable={true}
              showCheckedStrategy={'SHOW_PARENT'}
              className={'dropdown-in-flow'}
            />
          ),
          initialValue: user.visibleLocationIds,
          colSize: 24,
        },
      ]}
      onSubmit={(user: User): void => {
        user.visibleLocationIds = user.visibleLocationIds
          ? Array.from(
              new Set(
                user.visibleLocationIds
                  .map(
                    (locationId) =>
                      locationsAndTheirDescendantsIdsSelector[locationId],
                  )
                  .reduce((a, b) => a.concat(b), []),
              ),
            )
          : [];

        editUserVisibleLocations(user);
      }}
      successMessage="La visibilité a bien été modifiée"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const roleField = user ? (
    <EditableField
      object={user}
      content={role ? <RoleTag roleId={role.id} /> : ''}
      editedFieldsOptions={[
        {
          field: 'roleId',
          content: <RolesSelect />,
          required: true,
          initialValue: user.roleId,
          colSize: 24,
        },
      ]}
      onSubmit={editUserRole}
      successMessage="Le rôle a bien été modifié"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  const phoneField = profile ? (
    <EditableField
      object={profile}
      label="Téléphone"
      content={profile.phoneNumber || ''}
      editedFieldsOptions={[
        {
          field: 'phoneNumber',
          label: 'Téléphone',
          content: <Input placeholder="Saisissez un numéro" />,
          initialValue: profile.phoneNumber,
          colSize: 24,
          invalidMessage: 'Numéro de téléphone incorrect',
          pattern: new RegExp(regex),
        },
      ]}
      onSubmit={editProfileInformation}
      successMessage="Le numéro a bien été modifié"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  const addressField = profile ? (
    <EditableField
      object={profile}
      label="Adresse"
      content={
        profile.address || profile.address2 ? (
          <>
            {profile.address}
            <br />
            {profile.address2}
          </>
        ) : (
          ''
        )
      }
      editedFieldsOptions={[
        {
          field: 'address',
          label: 'Adresse',
          content: (
            <Input placeholder="Saisissez une adresse (n°, voie, cp, ville...)" />
          ),
          initialValue: profile.address,
        },
        {
          field: 'address2',
          label: 'Complément',
          content: <Input placeholder="Saisissez un complément d'adresse" />,
          initialValue: profile.address2,
        },
      ]}
      onSubmit={editProfileInformation}
      successMessage="L'adresse a bien été modifiée"
      restrictedToPermissions={{
        subject: 'user',
        action: 'edit',
      }}
    />
  ) : (
    ''
  );

  return (
    <Drawer
      className="app-info-profile"
      title={
        <>
          <Row>
            <Col span={18}>{fullNameField}</Col>
            <Col span={5} offset={1}>
              <RestrictedToPermissions subject={'user'} action={'edit'}>
                <PopoverActions
                  objectId={profile ? profile.id : ''}
                  onDelete={(id: string): void => {
                    deleteProfile(id);
                    hideDrawer();
                  }}
                  deleteConfirmMessage="Supprimer l'utilisateur ?"
                  deleteDoneMessage="L'utilisateur a bien été supprimé"
                />
              </RestrictedToPermissions>
            </Col>
          </Row>
        </>
      }
      visible={visible}
      onClose={(): void => hideDrawer()}
      footer={
        <Button onClick={(): void => hideDrawer()} type="primary" size="large">
          Fermer
        </Button>
      }
    >
      <Skeleton loading={isFetchingProfile || isFetchingUser}>
        {profile !== null ? (
          <>
            <Row>
              <Col span={12}>{roleField}</Col>
              <Col span={7} offset={5}>
                {avatar}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Informations" key="1">
                    <Row>
                      <Col span={12}>{emailField}</Col>
                    </Row>
                    <Row>
                      <Col span={12}>{phoneField}</Col>
                      <Col span={12}>{addressField}</Col>
                    </Row>
                    <Row>
                      <Col span={24}>{passwordField}</Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Rattachements & Visibilités" key="2">
                    <Row>
                      <Col span={24}>
                        <span className="app-label">Organisations</span>
                      </Col>
                      <Col span={12} className={'app-smallable-label'}>
                        {organizationField}
                      </Col>
                      <Col span={12} className={'app-smallable-label'}>
                        {visibleOrganizationField}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <span className="app-label">Lieux</span>
                      </Col>
                      <Col span={12} className={'app-smallable-label'}>
                        {locationsField}
                      </Col>
                      <Col span={12} className={'app-smallable-label'}>
                        {visibleLocationField}
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </>
        ) : (
          <Empty />
        )}
      </Skeleton>
    </Drawer>
  );
};

InfoUser.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideDrawer: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
  profile: profileTypes.PropTypesProfile,
  user: userTypes.PropTypesUser,
  organization: organizationTypes.PropTypesOrganization,
  locationsPaths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  locationsAndTheirDescendantsIdsSelector: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  organizationsAndTheirDescendantsIdsSelector: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ).isRequired,
  role: roleTypes.PropTypesRole,
  visibleOrganizations: PropTypes.arrayOf(
    organizationTypes.PropTypesOrganization.isRequired,
  ).isRequired,
  visibleLocations: PropTypes.arrayOf(PropTypesLocation.isRequired).isRequired,
  isFetchingProfile: PropTypes.bool.isRequired,
  isFetchingUser: PropTypes.bool.isRequired,
  getProfile: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  editProfileInformation: PropTypes.func.isRequired,
  editProfileEmail: PropTypes.func.isRequired,
  editProfileOrganizations: PropTypes.func.isRequired,
  editUserInformation: PropTypes.func.isRequired,
  editUserEmail: PropTypes.func.isRequired,
  editUserPassword: PropTypes.func.isRequired,
  editUserOrganizations: PropTypes.func.isRequired,
  editUserVisibleOrganizations: PropTypes.func.isRequired,
  editUserLocations: PropTypes.func.isRequired,
  editUserVisibleLocations: PropTypes.func.isRequired,
  editUserRole: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
};

interface MapStateToProps {
  profile: profileTypes.Profile | null;
  user: userTypes.User | null;
  organization: organizationTypes.Organization | null;
  locationsPaths: string[];
  locationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  organizationsAndTheirDescendantsIdsSelector: Record<string, string[]>;
  role: roleTypes.Role | null;
  isFetchingProfile: boolean;
  isFetchingUser: boolean;
  visibleOrganizations: organizationTypes.Organization[];
  visibleLocations: Location[];
}

const mapStateToProps = (
  state: State,
  props: { profileId: profileTypes.Profile['id'] },
): MapStateToProps => {
  const profile = profileSelectors.profileFromIdSelector(
    state,
    props.profileId,
  );

  const organization =
    profile && profile.organizationIds.length > 0
      ? organizationSelectors.organizationFromIdSelector(
          state,
          profile.organizationIds[0],
        )
      : null;

  const user = profile
    ? userSelectors.userFromIdSelector(state, profile.userId)
    : null;

  const role =
    user && user.roleId
      ? roleSelectors.roleFromIdSelector(state, user.roleId)
      : null;

  const locations =
    user && user.locationIds
      ? locationSelectors.locationFromMultipleIdsSelector(
          state,
          user.locationIds,
        )
      : [];

  const locationsAndTheirDescendantsIdsSelector =
    locationSelectors.locationsAndTheirDescendantsIdsSelector(state);

  const organizationsAndTheirDescendantsIdsSelector =
    organizationSelectors.organizationsAndTheirDescendantsIdsSelector(state);

  const visibleOrganizations = user
    ? organizationSelectors.organizationFromMultipleIdsSelector(
        state,
        user.visibleOrganizationIds,
      )
    : [];

  const visibleLocations =
    user && user.visibleLocationIds
      ? locationFromMultipleIdsSelector(state, user.visibleLocationIds)
      : [];

  return {
    profile: profile,
    user: user,
    role: role,
    organization: organization,
    locationsPaths: locations.map((location) =>
      locationSelectors.locationPathSelector(state, location),
    ),
    locationsAndTheirDescendantsIdsSelector,
    organizationsAndTheirDescendantsIdsSelector,
    isFetchingProfile: profileSelectors.profilesIsGettingSelector(state),
    isFetchingUser: usersIsGettingSelector(state),
    visibleOrganizations: visibleOrganizations,
    visibleLocations: visibleLocations,
  };
};

interface MapDispatchToProps {
  getProfile: Function;
  getUser: Function;
  getOrganization: Function;
  loadLocations: Function;
  editProfileInformation: Function;
  editProfileEmail: Function;
  editProfileOrganizations: Function;
  editUserInformation: Function;
  editUserEmail: Function;
  editUserPassword: Function;
  editUserOrganizations: Function;
  editUserVisibleOrganizations: Function;
  editUserLocations: Function;
  editUserVisibleLocations: Function;
  editUserRole: Function;
  deleteProfile: Function;
}

const mapDispatchToProps: MapDispatchToProps = {
  getProfile: profileActions.fetchGetProfilesActionCreator,
  getUser: userActions.fetchGetUsersActionCreator,
  getOrganization: organizationActions.fetchGetOrganizationsActionCreator,
  loadLocations: locationActions.fetchListLocationsActionCreator,
  editProfileInformation:
    profileActions.fetchEditProfilesInformationActionCreator,
  editProfileEmail: profileActions.fetchEditProfilesEmailActionCreator,
  editProfileOrganizations:
    profileActions.fetchEditProfilesOrganizationsActionCreator,
  editUserInformation: userActions.fetchEditUsersInformationActionCreator,
  editUserEmail: userActions.fetchEditUsersEmailActionCreator,
  editUserPassword: userActions.fetchEditUsersPasswordActionCreator,
  editUserOrganizations: userActions.fetchEditUsersOrganizationsActionCreator,
  editUserVisibleOrganizations:
    userActions.fetchEditUsersVisibleOrganizationsActionCreator,
  editUserLocations: userActions.fetchEditUsersLocationsActionCreator,
  editUserVisibleLocations:
    userActions.fetchEditUsersVisibleLocationsActionCreator,
  editUserRole: userActions.fetchEditUsersRoleActionCreator,
  deleteProfile: profileActions.fetchDeleteProfilesActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoUser);
