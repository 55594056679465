const incidentStatus: { [key: string]: string } = {
  reported: 'Signalé',
  rejected: 'Refusé',
  ongoing: 'En cours',
  resolved: 'Résolu',
};

// Used to set the colors in dashboard charts
const incidentStatusColor: { [key: string]: string } = {
  reported: '#ffbe34',
  rejected: '#ff3434',
  ongoing: '#22dadb',
  resolved: '#49d540',
};

/**
 * @param {O} obj
 * @param {string} key
 * @return {boolean}
 */
function hasKey<O>(obj: O, key: string): boolean {
  return key in obj;
}

/**
 * IncidentStatusUtils
 */
export default class IncidentStatusUtils {
  /**
   * @param {string|null} fromStatus
   * @return {object}
   */
  static getSelectableIncidentStatus(fromStatus: string | null): string[] {
    if (fromStatus === 'reported') {
      return ['rejected', 'ongoing'];
    }

    return Object.keys(incidentStatus);
  }

  /**
   * @param {string} status
   * @return {string}
   */
  static getLabel(status: string): string {
    if (!hasKey(incidentStatus, status)) {
      throw new Error('Invalid status');
    }

    return incidentStatus[status];
  }

  /**
   * @param {string} status
   * @return {string}
   */
  static getColor(status: string): string {
    if (!hasKey(incidentStatusColor, status)) {
      throw new Error('Invalid status');
    }

    return incidentStatusColor[status];
  }
}
